import { useContext,  useLayoutEffect, useRef } from "react";
import ReactDOM from 'react-dom'
import { useHistory, useLocation } from "react-router-dom";
import { ComboBox, IComboBoxOption, IComboBoxStyles, Icon } from "@fluentui/react"
import TableContext, { ITableContext } from "@/shared/components/Table/context";

import './PhaseDropdownFilter.scss'

export const PHASE_QUERY_PARAM = 'phase'

const PhaseDropdownFilter = () => {
  const history = useHistory()
  const loc = useLocation()
  const queryParams = new URLSearchParams(loc.search)
  const selectedKey = queryParams.get(PHASE_QUERY_PARAM)
  const { setValue, ...tableContext }: ITableContext = useContext(TableContext)
  const dropdownRef = useRef(null)

  useLayoutEffect(() => {
    if (dropdownRef) {
      const container = dropdownRef.current as HTMLElement
      
      if (container) {
        const baseContainer = container.querySelector('.ms-ComboBox') as HTMLElement
        
        if (baseContainer) {
          const input = container.querySelector('input') as HTMLElement
          
          if (input) {
            input.setAttribute('disabled', 'disabled')
          }

          let closeIcon = container.querySelector('.close-icon') as HTMLElement

          if (!closeIcon) {
            // creates close icon
            closeIcon = document.createElement('div')
            closeIcon.classList.add('close-icon')
            closeIcon.style.display = selectedKey ? '' : 'none'
            ReactDOM.render(
              (
                <Icon
                  iconName="Cancel"
                  styles={{
                    root: {
                      color: "rgb(96, 94, 92)",
                      paddingRight: ".7em",
                      "&:hover": {
                        fontWeight: 800
                      }
                    }
                  }}
                  onClick={(event) => {
                    event.stopPropagation();
                    onPhaseDropdownChange()
                  }}
                />
              ),
              closeIcon,
            )

            const caretDownButton =  baseContainer.querySelector('.ms-ComboBox-CaretDown-button') as HTMLElement

            if (caretDownButton) {
              const buttonContainer = caretDownButton.querySelector('.ms-Button-flexContainer') as HTMLElement
              
              if (buttonContainer) {
                caretDownButton.insertBefore(closeIcon, buttonContainer)
              }
            }
          } else {
            closeIcon.style.display = selectedKey ? '' : 'none'
          }
        }
      }
    }
  },[selectedKey])

  const phaseDropdownStyles: Partial<IComboBoxStyles> = { 
    root: {
      width: 150,
      outline: 'none',
      lineHeight: 'normal',
    },
    optionsContainerWrapper: {
      maxHeight: 216,
      width: 150
    },
  };

  const phaseDropdownOptions = [
    {
      key: 'Active',
      text: 'Active'
    },
    {
      key: 'Inactive',
      text: 'Inactive'
    },
    {
      key: 'Onboarding',
      text: 'Onboarding'
    },
  ]

  const onPhaseDropdownChange = (option?: IComboBoxOption) => {
    const selectedOption = (option?.key || '') as string
    const lowerCaseText = (selectedOption).toLowerCase()
    const queryParams = new URLSearchParams(history.location.search)
    const searchFields = tableContext.searchFields || []
    const phaseSearchField = searchFields.find(s => s.key === PHASE_QUERY_PARAM)

    if (phaseSearchField) {
      phaseSearchField.value = lowerCaseText
    } else {
      searchFields.push({
        key: PHASE_QUERY_PARAM,
        value: lowerCaseText,
      })
    }

    setValue({ 
      ...tableContext,
      searchFields,
      page: { key: tableContext.page.key, value: 1 },
    })    

    history.replace({
      ...history.location,
      search: (() => {
        if (!queryParams){
          return ''
        }

        queryParams.delete(tableContext.page.key)
        
        if (selectedOption) {
          queryParams.set(PHASE_QUERY_PARAM, selectedOption)
        } else {
          queryParams.delete(PHASE_QUERY_PARAM)
        }

        return `?${queryParams.toString()}`
      })()
    })
  }

  return (
    <ComboBox
      ref={dropdownRef}
      className={`PhaseDropdownFilter ${selectedKey ?  'has-value' : ''}`}
      selectedKey={selectedKey}
      options={phaseDropdownOptions}
      styles={phaseDropdownStyles}
      autoComplete='off'
      allowFreeform={false}
      useComboBoxAsMenuWidth={true}
      placeholder='Phase'
      onChange={(event, option) => onPhaseDropdownChange(option)}
    />
  )
}

export default PhaseDropdownFilter