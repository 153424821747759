import { IMAGE_BUCKET_BASE_PATH } from "@/environment/environment.constants"

export const getImageSrc = (image) => {
  if (!image) return ''
  return image.includes('/') ? image : `${process.env.PUBLIC_URL}/img/${image}`
}

export const getImageSrcForBucket = (image, folder) => {
  if (!image) {
    return ''
  }
  return image.includes('/') ? image : `${IMAGE_BUCKET_BASE_PATH}${folder}${image}`
}
