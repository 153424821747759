import { DefaultButton, Icon, Link, PrimaryButton } from '@fluentui/react'
import { useTranslation } from 'react-i18next'
import cn from 'classnames'
import { IClientContactViewModel, InviteStateEnum } from '@/shared/types/swagger'
import { useSendInviteMutation } from '@/shared/api/services/crmService'
import Confirmation from '@/shared/components/Confirmation'
import { useState, useCallback, useRef, useEffect } from 'react'
import { CONFRIM_TITLE_MAP, getConfirmDescription } from '@/features/client/constants/invite'
import getName from '@/features/client/helpers/getName'
import './ActionCell.scss'
import { QueryStatus } from '@reduxjs/toolkit/dist/query/react'

type ActionCellProps = {
  contact: IClientContactViewModel
}

export default function ActionCell({ contact }: ActionCellProps) {
  const { t } = useTranslation('contacts')
  const inviteStateRef = useRef<InviteStateEnum>()
  const [sendInvite, result] = useSendInviteMutation()
  const [isDialogShown, setIsDialogShown] = useState<boolean>(false)

  const active = 
    contact.inviteState === InviteStateEnum.InviteSentAndAccepted ||
    contact.inviteState === InviteStateEnum.InviteSentNotAccepted
  const handleSendInvite = useCallback(() => sendInvite(contact.caid), [contact.caid, sendInvite])

  useEffect(() => {
    inviteStateRef.current = contact.inviteState
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDialogShown])

  const name = getName(contact)
  const renderDefault = () => <DefaultButton onClick={() => setIsDialogShown(false)} text={result.status === QueryStatus.fulfilled ? 'Close Window' : 'Cancel'}/>
  const renderPrimary = () => {
    if (result.status === QueryStatus.fulfilled) return null
    return (
      <PrimaryButton text={result.status === QueryStatus.rejected ? 'Try Again' : 'Confirm'} disabled={result.status === QueryStatus.pending} onClick={handleSendInvite}/>
    )
  }

  return (
    <>
      <Link 
        className={cn('--cell-center')} 
        onClick={() => setIsDialogShown(true)}
      >
        {!active ? t('invite') : t('reinvite')}
      </Link>
      <Confirmation 
        hidden={!isDialogShown}
        title={CONFRIM_TITLE_MAP?.[inviteStateRef.current]?.[result.status]}
        subText={<>
          {result.status === QueryStatus.fulfilled && <Icon iconName='CompletedSolid' className='--color-blue-light' />}
          {result.status === QueryStatus.rejected && <Icon iconName='AlertSolid' className='--color-form-error' />}
          <span dangerouslySetInnerHTML={{ __html: getConfirmDescription(inviteStateRef.current, result.status, name) }} />
        </>}
        renderDefault={renderDefault}
        renderPrimary={renderPrimary}
        handleDismiss={() => setIsDialogShown(false)}
        modalClassName="c-invite-confirm-modal"
      />
    </>
  )
}
