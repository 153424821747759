import { sanitize } from 'dompurify'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { MessageBar } from '@fluentui/react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { UIMessage as IUIMessage } from '@/features/uimessages/shared/types'
import { deleteUIMessage } from '@/features/uimessages/redux/operations'

import './UIMessage.scss'

interface Props extends IUIMessage {
  content: string,
  id: string,
  message: IUIMessage,
}

export default function UIMessage(props: Props) {
  const dispatch = useDispatch()
  const [lastActive, setLastActive] = useState(document.activeElement as HTMLElement)
  const { content, id, message, ...uimessageProps } = props

  useEffect(() => {
    return () => {
      lastActive?.focus?.()
    }
  }, [lastActive])

  const onCloseClick = () => {
    dispatch(deleteUIMessage(message))
  }

  return (
    <MessageBar 
      {...uimessageProps} 
      className="c-uimessages__message"
    >
      <FontAwesomeIcon icon='xmark' className="close-icon" title="Close" onClick={onCloseClick} />
      <span className='message-content' dangerouslySetInnerHTML={{ __html: sanitize(content) }} />
    </MessageBar>
  )
}
