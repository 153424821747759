import { Chart, ChartType, ChartTypeRegistry, TooltipModel } from 'chart.js'
import { darkerHexColor, getChartPlugin } from '../../utils/ChartJS.helper'
import { GroupBarChartSeriesItem } from '../GroupBarChart'

export interface GroupBarChartTooltipPluginOptions {
  series: GroupBarChartSeriesItem[]
}

export const getOrCreateTooltip = (chart: Chart, seriesItem?: GroupBarChartSeriesItem) => {

  let tooltipEl = chart.canvas.parentNode.querySelector('div')

  if (!tooltipEl) {
    tooltipEl = document.createElement('div')
    tooltipEl.style.borderRadius = '3px'
    tooltipEl.style.color = 'white'
    tooltipEl.style.opacity = '1'
    tooltipEl.style.pointerEvents = 'none'
    tooltipEl.style.position = 'absolute'
    tooltipEl.style.transform = 'translate(-50%, 0)'
    tooltipEl.style.transition = 'all .1s ease'

    const table = document.createElement('table')
    table.style.margin = '0px'

    tooltipEl.appendChild(table)
    chart.canvas.parentNode.appendChild(tooltipEl)
  }

  tooltipEl.style.background = darkerHexColor(seriesItem?.color, 25) 

  return tooltipEl
}

const externalTooltipHandler = (chart: Chart, displayTooltip: boolean, seriesItem?: GroupBarChartSeriesItem) => {
  // Tooltip Element
  const { tooltip } = chart
  const tooltipEl = getOrCreateTooltip(chart, seriesItem)
  // // Hide if no tooltip
  if (!displayTooltip) {
    tooltipEl.style.opacity = '0'
    return
  }
  // Set Text

  if (seriesItem) {
    const tableHead = document.createElement('thead')

    const tr = document.createElement('tr')
    tr.style.borderWidth = '0'

    const th = document.createElement('th')
    th.style.borderWidth = '0'
    const text = document.createTextNode(seriesItem.name)

    th.appendChild(text)
    tr.appendChild(th)
    tableHead.appendChild(tr)

    const tableRoot = tooltipEl.querySelector('table')

    // Remove old children
    while (tableRoot.firstChild) {
      tableRoot.firstChild.remove()
    }

    // Add new children
    tableRoot.appendChild(tableHead)
  }
  const { offsetLeft: positionX, offsetTop: positionY } = chart.canvas
  document.querySelector('.GroupBarChartContainer').addEventListener('mousemove', (event) => {
    tooltipEl.style.opacity = '1'
    tooltipEl.style.left = event.pageX + 'px'
    tooltipEl.style.top = event.pageY + 'px'
    tooltipEl.style.padding = tooltip.options.padding + 'px ' + tooltip.options.padding + 'px'
  })
}


export const GroupBarChartTooltipPlugin = {
  id: 'GroupBarChartTooltipPlugin',
  beforeEvent: (chart: Chart, args, pluginOptions) => {
    const plugin = getChartPlugin(chart, 'GroupBarChartTooltipPlugin') as GroupBarChartTooltipPluginOptions
    if (!plugin) {
      return
    }

    const _chart = chart as any
    const elements = _chart._active || []
    const event = args.event
    if (event.type === 'mousemove') {
      if (elements.length) {
        const { x: mouseX, y: mouseY } = event
        const actualElement = elements.find(e => {
          const { element } = e
          if (element) {
            const barElement = element as any
            return (mouseX >= element.x - barElement.width / 2) && (mouseX <= element.x + barElement.width / 2)
          }
          return false
        })

        if (actualElement) {
          const seriesItem = plugin.series[actualElement.datasetIndex]
          const selectedSeriesItem = {
            ...seriesItem,
            selectedValue: seriesItem.values[actualElement.index],
            selectedIndex: actualElement.index,
            selectedDataSetIndex: actualElement.datasetIndex
          } as GroupBarChartSeriesItem
          externalTooltipHandler(chart, true, selectedSeriesItem)
        } else {
          externalTooltipHandler(chart, false)
        }
      } else {
        externalTooltipHandler(chart, false)
      }
    } else if (event.type === 'mouseout') {
      externalTooltipHandler(chart, false)
    }
  }
}

