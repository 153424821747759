type PageHeaderProps = {
  label: string;
  action?: React.ReactNode;
  className?: string
}

const PageHeader = ({ label, action, className }: PageHeaderProps) => {
  return (
    <header className='c-page-header'>
      <h1 className={`c-page-header__label ${className ? className : ''}`}>{label}</h1>
      {action && <div className="c-page-header__action">{action}</div>}
    </header>
  )
}

export default PageHeader