import { selectIsCAEmployee } from '@/features/auth/redux/authSelectors';
import useQueryParamSubscription from '@/shared/hooks/useQueryParamSubscription';
import { useSelector } from 'react-redux';
import { Redirect, RouteComponentProps } from 'react-router-dom';

export const getSharedDocumentLink = (accountId: string, folderId: string, fileId?: string) => {
  const { location } = window
  const { origin } = location
  const sharedDocumentLink = `${origin}/sharedDocumentLink/${accountId}?folderId=${folderId}` + (fileId ? `&fileId=${fileId}` : '')
  return sharedDocumentLink;
}

export default function SharedDocumentLink({ match }: RouteComponentProps<{accountId: string}>) {
  const { accountId } = match.params
  const [folderId] = useQueryParamSubscription('folderId')
  const [fileId] = useQueryParamSubscription('fileId')
  const isCaEmployee = useSelector(selectIsCAEmployee)
  let documentRoute = ''

  if (isCaEmployee) {
    documentRoute = `/clients/${accountId}?folderId=${folderId}&tab=docs` + (fileId ? `&fileId=${fileId}` : '')
  } else {
    if (fileId) {
      documentRoute = `/documents/${fileId}`
    } else {
      documentRoute = `/documents?folderId=${folderId}`
    }
  }

  return (
    <Redirect to={documentRoute} />
  )

}
