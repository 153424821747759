import { SearchBox } from '@fluentui/react'
import debounce from 'lodash/debounce'
import { useContext, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import TableContext, { ITableContext } from '../context'

export default function TableSearchBox() {
  const history = useHistory()
  const { setValue, ...tableContext }: ITableContext = useContext(TableContext)
  const { t } = useTranslation()

  const onChangeText = useMemo(() => debounce((_, text: string): void => {
    const lcText = (text || '').toLowerCase()
    const queryParams = new URLSearchParams(history.location.search)

    setValue({ 
      ...tableContext, 
      search: { key: tableContext.search.key, value: lcText },
      page: { key: tableContext.page.key, value: 1 },
      searchFields: tableContext.searchFields || [],
    })    

    history.replace({
      ...history.location,
      search: (() => {
        if (!queryParams) return ''

        queryParams.delete(tableContext.page.key)
        if (lcText) {
          queryParams.set(tableContext.search.key, lcText)
        } else {
          queryParams.delete(tableContext.search.key)
        }

        return `?${queryParams.toString()}`
      })()
    })
  }, 200), [history, tableContext, setValue])

  return (
    <SearchBox 
      onChange={onChangeText} 
      placeholder={t('common:search')}
      defaultValue={tableContext.search.value as string}
      styles={{ root: { width: 240 } }}
    />
  )
}