import LocalizationProvider from './LocalizationProvider'
import {
  LocalizationConfig,
  LocalizationProviderProps
} from './LocalizationProvider/types'

import TranslatedText from './TranslatedText'
import TranslatedTextProps from './TranslatedText/types'

// components
export { LocalizationProvider, TranslatedText }

// types
export type { TranslatedTextProps, LocalizationConfig, LocalizationProviderProps }
