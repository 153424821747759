import React, { useRef } from 'react'
import ReactDatePicker from 'react-datepicker'
import { addMonths, subMonths, isLastDayOfMonth } from 'date-fns'
import {
  DEFAULT_DATE_FORMAT,
  TODAY_PLACEHOLDER,
} from '../../../services/date/DateService'
import styles from './DatePicker.module.scss'
import { Overlay } from 'react-bootstrap'
import UtilService from '../../../services/util/UtilService'

const DatePicker = ({
  value,
  dateFormat,
  onValueChange,
  disabled,
  isClearable,
  readOnly,
  showTimeInput,
  required,
  className,
  minimumDate,
  maximumDate,
  id,
  calendarPosition = 'bottom-start',
  isCalendarDateValid,
}: {
  value: Date | null | undefined
  dateFormat?: string
  onValueChange?: (value: Date) => void
  disabled?: boolean
  isClearable?: boolean
  readOnly?: boolean
  showTimeInput?: boolean
  required?: boolean
  className?: string
  minimumDate?: Date
  maximumDate?: Date
  id?: string
  calendarPosition?: 'top-end' | 'top-start' | 'bottom-start' | 'bottom-end'
  isCalendarDateValid?: (date: Date) => boolean
}) => {
  const target = useRef(null)
  const format = dateFormat || DEFAULT_DATE_FORMAT
  const componentId = UtilService.getId(id)

  const onChange = (newValue: Date, event: React.SyntheticEvent<any>) => {
    if (onValueChange) {
      onValueChange(newValue)
    }
  }

  const popperContainer = ({ children }: { children: React.ReactNode[] }) => {
    return (
      <Overlay target={target.current} show={true}>
        {({ placement, arrowProps, show: _show, popper, ...props }) => (
          <div>{children}</div>
        )}
      </Overlay>
    )
  }

  const refreshCalendarPopper = (monthDate: Date) => {
    if (isCalendarDateValid) {
      setTimeout(() => {
        const monthContainer = document.querySelector('.react-datepicker__month-container') as HTMLElement
        const todayContainer = document.querySelector('.react-datepicker__today-button') as HTMLElement
        const monthStart = new Date(monthDate.getFullYear(), monthDate.getMonth(), 1)
        const nextMonthStart = addMonths(monthStart, 1)
        const prevMonthStart = subMonths(monthStart, 1)
        const disableDateElement = (dateElement: HTMLElement) => {
          dateElement.classList.add('react-datepicker__day--disabled')
          dateElement.classList.add('react-datepicker__day--excluded')
          dateElement.style.pointerEvents = 'none'
        }

        if (todayContainer) {
          if (!isCalendarDateValid(new Date())) {
            disableDateElement(todayContainer)
          }
        }

        if (monthContainer) {
          const dateContainerList = monthContainer.querySelectorAll('.react-datepicker__day')

          if (dateContainerList) {
            let isPrevMonth = undefined
            let isCurrentMonth = undefined

            for (let index = 0, count = dateContainerList.length; index < count; index++) {
              const dateElement = dateContainerList[index] as HTMLElement
              const day = Number(dateElement.innerText.trim())

              if (day >= 25) {
                if (isPrevMonth === undefined) {
                  isPrevMonth = true
                }

                if (isPrevMonth) {
                  if (!isCalendarDateValid(new Date(prevMonthStart.getFullYear(), prevMonthStart.getMonth(), day))) {
                    disableDateElement(dateElement)
                  }
                } else {
                  const currentMonthDate = new Date(monthStart.getFullYear(), monthStart.getMonth(), day)
                  
                  if (!isCalendarDateValid(currentMonthDate)) {
                    disableDateElement(dateElement)
                  }

                  if (isLastDayOfMonth(currentMonthDate)) {
                    isCurrentMonth = false
                  }
                }
              } else {
                isPrevMonth = false

                if (isCurrentMonth === undefined) {
                  isCurrentMonth = true
                }

                if (isCurrentMonth) {
                  if (!isCalendarDateValid(new Date(monthStart.getFullYear(), monthStart.getMonth(), day))) {
                    disableDateElement(dateElement)
                  }
                } else {
                  if (!isCalendarDateValid(new Date(nextMonthStart.getFullYear(), nextMonthStart.getMonth(), day))) {
                    disableDateElement(dateElement)
                  }
                }
              }
            }
          }
        }
      })
    }
  }

  const onCalendarOpen = () => {
    refreshCalendarPopper(value || new Date())
  }

  const onMonthChange = (date: Date) => {
    refreshCalendarPopper(date)
  }

  const onYearChange = (date: Date) => {
    refreshCalendarPopper(date)
  }
  
  return (
    <ReactDatePicker
      readOnly={readOnly}
      className={`${styles.datePicker} ${className || ''} ${
        required && !value ? styles.required : ''
      }`}
      isClearable={isClearable}
      showYearDropdown={true}
      showMonthDropdown={true}
      dateFormat={format}
      placeholderText={format}
      todayButton={TODAY_PLACEHOLDER}
      selected={value}
      onChange={onChange}
      disabled={disabled}
      showTimeInput={showTimeInput}
      required={required}
      minDate={minimumDate || null}
      maxDate={maximumDate || null}
      popperContainer={popperContainer}
      id={componentId}
      popperPlacement={calendarPosition}
      onCalendarOpen={onCalendarOpen}
      onMonthChange={onMonthChange}
      onYearChange={onYearChange}
      popperModifiers={{
        offset: {
          enabled: true,
          offset: '5px, 10px'
        },
        preventOverflow: {
          enabled: true,
          escapeWithReference: false,
          boundariesElement: 'viewport'
        }
      }}
    />
  )
}

export default DatePicker