export default function tryAppendEllipsis(accumulator: number, parent: ChildNode, maxChars: number) {
  const children = parent.childNodes
  let _accumulator = accumulator

  for (const child of children) {
    const childIsParent = Boolean(child.childNodes.length)

    if (_accumulator > maxChars) return
    if (childIsParent && _accumulator) {
      tryAppendEllipsis(_accumulator, child, maxChars)
    } else {
      const contentLn: number = child.textContent.length
      _accumulator += contentLn
      if (_accumulator > maxChars) {
        child.textContent = `${child.textContent.slice(0, maxChars - accumulator)}...`
        break
      } else {
        continue
      }
    }
  }
}