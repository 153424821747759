import cn from 'classnames'
import { ReactNode } from 'react'
import './TabPanel.scss'

interface ITabPanelProps {
  id: string;
  tabId: string;
  isActive: boolean;
  children: ReactNode
}

export default function TabPanel(props: ITabPanelProps) {
  const { id, tabId, isActive, children } = props

  return (
    <div 
      role="tabpanel"
      id={id}
      aria-labelledby={tabId}
      className={cn('c-tabpanel', { '--active': isActive })}
    >
      {children}
    </div>
  )
}