import { GroupBarChart } from '@/lib/common/components/charts/group-bar-chart/GroupBarChart'
import { Card, GeneralError, Section } from '@/shared/components'
import { BENCHMARK_DISPLAY_TYPE, IPortfolioPerformanceSummaryFieldSetting, NET_OF_FEE_DISPLAY_TYPE, POLICY_BENCHMARK_NODE_TYPE, PerformanceRawData, SLEEVE_DISPLAY_TYPE, VALUE_ADDED_DISPLAY_TYPE, PERFORMANCE_MEDIAN_KEYS, useGetPortfolioSettingsByIdQuery, useGetStatsPerformanceQuery, exportGridData, getPerformanceRawValue, getPerformanceFiscalYearEndField, getPerformanceFiscalYearEndShortMonth, IPortfolioSettings } from '@/shared/api/services/portfolioService'
import SkeletonClientPortfolioPerformance from '../Skeletons/SkeletonClientPortfolioPerformance'
import PerformanceSummaryGrid from './PerformanceSummaryGrid/PerformanceSummaryGrid'
import { formatDate, parseFormattedDate } from '@/lib/common/services/date/DateService'
import useIsMobile from '@/shared/hooks/useIsMobile'
import PerformanceSummaryChartLegend from './PerformanceSummaryChart/PerformanceSummaryChartLegend'
import { useEffect, useState } from 'react'
import { ColDef, GridApi, ProcessCellForExportParams } from 'ag-grid-community'
import { DefaultButton } from '@fluentui/react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { CURRENCY_COLUMN_CELL_CLASS, DATE_COLUMN_CELL_CLASS, DATE_COLUMN_FORMAT, DATE_TIME_COLUMN_CELL_CLASS, DATE_TIME_COLUMN_FORMAT, PERCENTAGE_COLUMN_CELL_CLASS } from '@/lib/common/components/grid/GridExport'
import ReturnToTotalPortfolioButton from '../TotalPortfolioButton/ReturnToTotalPortfolio'
import { PERFORMANCE_SLEEVE_COLUMN_CELL_CLASS } from '../../Components/Grid/Column/PerformanceSleeveColumn'

import './PerformanceSummary.scss'

export interface PerformanceSummaryProps {
  portfolioSettings: IPortfolioSettings,
  portfolioId: string, 
  asOfDate: string,
  sleeveId?: string,
  showTotalPortfolioButton?: boolean,
  previewAccountId?: string,
}

const getWindowDimensions = () => {
  const { innerWidth: width, innerHeight: height } = window
  return {
    width,
    height
  }
}

export default function PerformanceSummary({ 
  portfolioSettings,
  portfolioId, 
  asOfDate,
  sleeveId,
  showTotalPortfolioButton,
  previewAccountId,
}: PerformanceSummaryProps) {
  const isMobile = useIsMobile('xs')
  //CP-507 - Change performance sleeve drill down for Portfolio widgets
  // const parsedSleeveId = !!sleeveId ? Number(sleeveId) : 0
  const parsedSleeveId = 0
  const { data, isFetching, isError, refetch } = useGetStatsPerformanceQuery({ portfolioId, asOfDate, accountId: previewAccountId })
  const parsedAsOfDate = parseFormattedDate(asOfDate)
  const subtitle = `As of ${formatDate(parsedAsOfDate, portfolioSettings?.headerDateFormat || 'MMM dd, yyyy')}`
  const performanceSummarySettings = portfolioSettings && portfolioSettings?.performanceSummary
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions())
  const [ gridApi, setGridApi ] = useState(null as GridApi)
  useEffect(() => {
    const handleResize = () => {
      setWindowDimensions(getWindowDimensions())
    }

    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  if (isError) {
    return (
      <Card className='card-center c-card-performance-chart c-card-performance c-card-error' ariaLabel='Welcome'>
        <Section className='c-portfolio-section' title='Performance (%)' subtitle={subtitle}>
          <GeneralError title='Failed to load Portfolio Performance' onClick={refetch} />
        </Section>
      </Card>
    )
  }

  const result = (data || [])
  const totalAssets = result.find(p => p.DisplayPLIType === 'Sleeve' && p.Lvl === 0)     
  
  // parent sleeve node
  const sleeve = result.find(p => {
    if (parsedSleeveId) {
      return (p.PLISk === parsedSleeveId) && (p.DisplayPLIType === SLEEVE_DISPLAY_TYPE)
    }
      return (p.Lvl === 0) && (p.DisplayPLIType === SLEEVE_DISPLAY_TYPE)
  })

  // policy benchmark node
  let policyBenchmark = result.find(p => {
    return (p.GroupPLISk === sleeve?.GroupPLISk) && (p.NodeType === POLICY_BENCHMARK_NODE_TYPE)
  })

  if (!policyBenchmark) {
    // sets first benchmark node if no policy benchmnark node found
    policyBenchmark = result.find(p => {
      return (p.GroupPLISk === sleeve?.GroupPLISk) && (p.DisplayPLIType === BENCHMARK_DISPLAY_TYPE)
    })
  }

  // value add node
  const valueAdd = result.find(p => {
    return (p.GroupPLISk === sleeve?.GroupPLISk) && (p.DisplayPLIType === VALUE_ADDED_DISPLAY_TYPE)
  })

  // net of fee node
  let netOfFee

  if (performanceSummarySettings?.displayPerformanceSummaryNetOfFees) {
    // sets first with NetOfCAFees display type
    netOfFee = result.find(p => {
      return (p.GroupPLISk === sleeve?.GroupPLISk) && (p.DisplayPLIType === NET_OF_FEE_DISPLAY_TYPE)
    })
  }

  const getChartPeriods = () => {
    if (!performanceSummarySettings) {
      return []
    }

    const periods = []

    if (performanceSummarySettings.displayPerformanceSumaryMtd) {
      periods.push((isMobile || (windowDimensions.width < 1810)) ? ['Month To', 'Date'] : ['Month To Date'])
    }

    if (performanceSummarySettings.displayPerformanceSumaryQtd) {
      periods.push((isMobile || (windowDimensions.width < 1810)) ? ['Quarter To', 'Date'] : ['Quarter To Date'])
    }

    if (performanceSummarySettings.displayPerformanceSumaryFiscalYtd) {
      const shortMonth = getPerformanceFiscalYearEndShortMonth(portfolioSettings)
      periods.push((isMobile || (windowDimensions.width < 1810)) ? ['Fiscal Year', `To Date ${shortMonth}`] : ['Fiscal Year', `To Date ${shortMonth}`])
    }

    if (performanceSummarySettings.displayPerformanceSumaryYtd) {
      periods.push((isMobile || (windowDimensions.width < 1810)) ?  ['Calendar Year To', 'Date'] : ['Calendar Year To Date'])
    }

    if (performanceSummarySettings.displayPerformanceSumary1Year) {
      periods.push((isMobile || (windowDimensions.width < 1810)) ? ['Cumulative', 'Trailing 1 Year'] : ['Cumulative Trailing', '1 Year'])
    }

    if (performanceSummarySettings.displayPerformanceSumary2Year) {
      periods.push((isMobile || (windowDimensions.width < 1810)) ? ['Annualized', 'Trailing 2 Year'] : ['Annualized Trailing', '2 Year'])
    }

    if (performanceSummarySettings.displayPerformanceSumary3Year) {
      periods.push((isMobile || (windowDimensions.width < 1810)) ? ['Annualized', 'Trailing 3 Year'] : ['Annualized Trailing', '3 Year'])
    }

    if (performanceSummarySettings.displayPerformanceSumary4Year) {
      periods.push((isMobile || (windowDimensions.width < 1810)) ? ['Annualized', 'Trailing 4 Year'] : ['Annualized Trailing', '4 Year'])
    }

    if (performanceSummarySettings.displayPerformanceSumary5Year) {
      periods.push((isMobile || (windowDimensions.width < 1810)) ? ['Annualized', 'Trailing 5 Year'] : ['Annualized Trailing', '5 Year'])
    }

    if (performanceSummarySettings.displayPerformanceSumary6Year) {
      periods.push((isMobile || (windowDimensions.width < 1810)) ? ['Annualized', 'Trailing 6 Year'] : ['Annualized Trailing', '6 Year'])
    }

    if (performanceSummarySettings.displayPerformanceSumary7Year) {
      periods.push((isMobile || (windowDimensions.width < 1810)) ? ['Annualized', 'Trailing 7 Year'] : ['Annualized Trailing', '7 Year'])
    }

    if (performanceSummarySettings.displayPerformanceSumary8Year) {
      periods.push((isMobile || (windowDimensions.width < 1810)) ? ['Annualized', 'Trailing 8 Year'] : ['Annualized Trailing', '8 Year'])
    }

    if (performanceSummarySettings.displayPerformanceSumary9Year) {
      periods.push((isMobile || (windowDimensions.width < 1810)) ? ['Annualized', 'Trailing 9 Year'] : ['Annualized Trailing', '9 Year'])
    }

    if (performanceSummarySettings.displayPerformanceSumary10Year) {
      periods.push((isMobile || (windowDimensions.width < 1810)) ? ['Annualized', 'Trailing 10 Year'] : ['Annualized Trailing', '10 Year'])
    }

    if (performanceSummarySettings.displayPerformanceSumary11Year) {
      periods.push((isMobile || (windowDimensions.width < 1810)) ? ['Annualized', 'Trailing 11 Year'] : ['Annualized Trailing', '11 Year'])
    }

    if (performanceSummarySettings.displayPerformanceSumary12Year) {
      periods.push((isMobile || (windowDimensions.width < 1810)) ? ['Annualized', 'Trailing 12 Year'] : ['Annualized Trailing', '12 Year'])
    }

    if (performanceSummarySettings.displayPerformanceSumary13Year) {
      periods.push((isMobile || (windowDimensions.width < 1810)) ? ['Annualized', 'Trailing 13 Year'] : ['Annualized Trailing', '13 Year'])
    }

    if (performanceSummarySettings.displayPerformanceSumary14Year) {
      periods.push((isMobile || (windowDimensions.width < 1810)) ? ['Annualized', 'Trailing 14 Year'] : ['Annualized Trailing', '14 Year'])
    }

    if (performanceSummarySettings.displayPerformanceSumary15Year) {
      periods.push((isMobile || (windowDimensions.width < 1810)) ? ['Annualized', 'Trailing 15 Year'] : ['Annualized Trailing', '15 Year'])
    }

    if (performanceSummarySettings.displayPerformanceSumaryCustomPeriod) {
      periods.push((isMobile || (windowDimensions.width < 1810)) ? ['Annualized Since', `${formatDate(portfolioSettings && portfolioSettings.inceptionDate, portfolioSettings && portfolioSettings.fullDateFormat)}`]
        : ['Annualized Since', `${formatDate(portfolioSettings && portfolioSettings.inceptionDate, portfolioSettings && portfolioSettings.fullDateFormat)}`])
    }

    if (performanceSummarySettings.displayPerformanceSumarySinceFirstFullMonth) {
      periods.push((isMobile || (windowDimensions.width < 1810)) ? ['Annualized Since', 'First Full Month'] : ['Annualized Since', 'First Full Month'])
    }

    if (performanceSummarySettings.displayPerformanceSumarySinceFirstFullQuarter) {
      periods.push((isMobile || (windowDimensions.width < 1810)) ? ['Annualized Since', 'First Full Quarter'] : ['Annualized Since', 'First Full Quarter'])
    }

    if (performanceSummarySettings.displayPerformanceSumaryAnnualizedSinceInception) {
      periods.push((isMobile || (windowDimensions.width < 1810)) ? ['Annualized', 'Since Inception'] : ['Annualized', 'Since Inception'])
    }

    if (performanceSummarySettings.displayPerformanceSumaryCumulative) {
      periods.push((isMobile || (windowDimensions.width < 1810)) ? ['Cumulative', 'Since Inception'] : ['Cumulative', 'Since Inception'])
    }

    return periods
  }

  const getChartValues = (value: PerformanceRawData) => {
    if (!performanceSummarySettings) {
      return []
    }

    const values = []

    if (performanceSummarySettings.displayPerformanceSumaryMtd) {
      values.push(value['TwrMtd'])
    }

    if (performanceSummarySettings.displayPerformanceSumaryQtd) {
      values.push(value['TwrQtd'])
    }

    if (performanceSummarySettings.displayPerformanceSumaryFiscalYtd) {
      values.push(value[getPerformanceFiscalYearEndField(portfolioSettings)])
    }

    if (performanceSummarySettings.displayPerformanceSumaryYtd) {
      values.push(value['TwrCytd'])
    }

    if (performanceSummarySettings.displayPerformanceSumary1Year) {
      values.push(value['TwrTr1Y'])
    }

    if (performanceSummarySettings.displayPerformanceSumary2Year) {
      values.push(value['TwrTr2Y'])
    }

    if (performanceSummarySettings.displayPerformanceSumary3Year) {
      values.push(value['TwrTr3Y'])
    }

    if (performanceSummarySettings.displayPerformanceSumary4Year) {
      values.push(value['TwrTr4Y'])
    }

    if (performanceSummarySettings.displayPerformanceSumary5Year) {
      values.push(value['TwrTr5Y'])
    }

    if (performanceSummarySettings.displayPerformanceSumary6Year) {
      values.push(value['TwrTr6Y'])
    }

    if (performanceSummarySettings.displayPerformanceSumary7Year) {
      values.push(value['TwrTr7Y'])
    }

    if (performanceSummarySettings.displayPerformanceSumary8Year) {
      values.push(value['TwrTr8Y'])
    }

    if (performanceSummarySettings.displayPerformanceSumary9Year) {
      values.push(value['TwrTr9Y'])
    }

    if (performanceSummarySettings.displayPerformanceSumary10Year) {
      values.push(value['TwrTr10Y'])
    }

    if (performanceSummarySettings.displayPerformanceSumary11Year) {
      values.push(value['TwrTr11Y'])
    }

    if (performanceSummarySettings.displayPerformanceSumary12Year) {
      values.push(value['TwrTr12Y'])
    }

    if (performanceSummarySettings.displayPerformanceSumary13Year) {
      values.push(value['TwrTr13Y'])
    }

    if (performanceSummarySettings.displayPerformanceSumary14Year) {
      values.push(value['TwrTr14Y'])
    }

    if (performanceSummarySettings.displayPerformanceSumary15Year) {
      values.push(value['TwrTr15Y'])
    }

    if (performanceSummarySettings.displayPerformanceSumaryCustomPeriod) {
      values.push(value['TwrCust'])
    }

    if (performanceSummarySettings.displayPerformanceSumarySinceFirstFullMonth) {
      values.push(value['TwrS1FM'])
    }

    if (performanceSummarySettings.displayPerformanceSumarySinceFirstFullQuarter) {
      values.push(value['TwrS1FQ'])
    }

    if (performanceSummarySettings.displayPerformanceSumaryAnnualizedSinceInception) {
      values.push(value['TwrItd'])
    }

    if (performanceSummarySettings.displayPerformanceSumaryCumulative) {
      values.push(value['TwrCumItd'])
    }

    return values
  }
  

  // filtered performance options
  let options = [
    sleeve,
    policyBenchmark,
    valueAdd,
    netOfFee,
  ].filter(option => !!option) as PerformanceRawData[]

  if (performanceSummarySettings) {
    const { Lvl: sleeveLevel } = sleeve || {} as PerformanceRawData

    // checks for non-root sleeve
    if (sleeveLevel > 0) {
      // removes data that is not allowed from Vermilion settings
      const removeData = ((sleeveLevel == 1) && (!performanceSummarySettings.displayPerformanceSumaryLevel1))
        || ((sleeveLevel === 2) && (!performanceSummarySettings.displayPerformanceSumaryLevel2))
        || ((sleeveLevel === 3) && (!performanceSummarySettings.displayPerformanceSumaryLevel3))
        || ((sleeveLevel === 4) && (!performanceSummarySettings.displayPerformanceSumaryLevel4))
        || ((sleeveLevel === 5) && (!performanceSummarySettings.displayPerformanceSumaryLevel5))
        || (sleeveLevel > 5)

      if (removeData) {
        options = []
      }
    }
  }

  const seriesChart = options.filter(op => op !== netOfFee).map((op, index) => {
    let color = op.Color
    if (op === policyBenchmark) {
      color = '#cccccc'
    } else if (op === valueAdd) {
      color = '#0c3980'
    }
    return {
      name: op.Name,
      values: getChartValues(op),
      color,
      ...op
    } 
  })

  const legendOptions = options.filter(op => op !== netOfFee).map(op => {
    if (op === policyBenchmark) {
      return {
        ...op,
        Color: '#cccccc',
      }
    } else if (op === valueAdd) {
      return {
        ...op,
        Color: '#0c3980',
      }
    }
    return { ...op }
  })
  
  const handleExcelDownloadClick = () => {
    exportGridData({
      gridApi,
      excelTabName: 'Performance',
      fileName: `performance-summary-export-investment-pool-${portfolioSettings?.investmentPoolName}-${portfolioSettings?.investmentPoolId}-as-of-date-${asOfDate}`,
      processCellCallback: (cellCallbackParams: ProcessCellForExportParams) => {
        const { value, column, node } = cellCallbackParams
        const { colDef: columnDef } = column as any
        const colDef = columnDef as ColDef
        const { cellClass } = colDef
        const { originalCellClass } = colDef as any
        const data = node && node.data
        const field = colDef && colDef.field
        const className = (originalCellClass && (typeof originalCellClass === 'string')) ? originalCellClass : cellClass
    
        if (value && cellClass) {
          const hasCellClass = (cellClassName: any, expectedCellClass: string) => {
            if (Array.isArray(cellClassName)) {
              return cellClassName.includes(expectedCellClass)
            }
            return `${cellClassName || ''}`.includes(expectedCellClass)
          }
    
          if (hasCellClass(className, DATE_COLUMN_CELL_CLASS)) {
            return formatDate(value, DATE_COLUMN_FORMAT)
          } else if (hasCellClass(className, DATE_TIME_COLUMN_CELL_CLASS)) {
            return formatDate(value, DATE_TIME_COLUMN_FORMAT)
          } else if (hasCellClass(className, CURRENCY_COLUMN_CELL_CLASS)) {
            if (data && field) {
              try {
                const currencyValue = Number(data[field] || value)?.toFixed(4)
                return currencyValue
              } catch (error) {
                console.error('Error while parsing currency column value.', error)
              }
            }
          } else if (hasCellClass(className, PERCENTAGE_COLUMN_CELL_CLASS)) {
            try {
              const percentageValue = getPerformanceRawValue(data, field)
              return percentageValue?.toFixed(6)
            } catch (error) {
              console.error('Error while parsing percentage column value.', error)
            }
          }
          else if (hasCellClass(className, PERFORMANCE_SLEEVE_COLUMN_CELL_CLASS)) {
            const { StatusAggregate, footNoteDataFlags  } = data || {}
            let dataFlags = ''
    
            if (StatusAggregate) {
              if (StatusAggregate === 'Preliminary') {
                dataFlags += ' * '
              } else if (StatusAggregate === 'PROXY') {
                dataFlags += ' † '
              }
            }
            
            if (footNoteDataFlags && footNoteDataFlags.length) {
              dataFlags += ' '  + footNoteDataFlags.join(' ')
            }

            return value + dataFlags
          }
        }
        return value
      }
    })
  }

  const getFormattedValue = (value, data) => {
    if (value && (PERFORMANCE_MEDIAN_KEYS && (data.DisplayPLIType).toLowerCase().indexOf('median') === -1)) {
      if (value < 0) {
        return ((parseFloat(value)*100).toFixed(1))
      }
      return (parseFloat(value)*100).toFixed(1)
    }

    if (value && PERFORMANCE_MEDIAN_KEYS && (data.DisplayPLIType).toLowerCase().indexOf('median') >= 0 && PERFORMANCE_MEDIAN_KEYS[1] !== data.Name && PERFORMANCE_MEDIAN_KEYS[0] !== data.Name) {
      if(value < 0){
        return (parseFloat(value)).toFixed(1) 
      }

      return parseFloat(value).toFixed(1)
    }
    if (value && PERFORMANCE_MEDIAN_KEYS && (data.DisplayPLIType).toLowerCase().indexOf('median') >= 0 && (PERFORMANCE_MEDIAN_KEYS[1] === data.Name || PERFORMANCE_MEDIAN_KEYS[0] === data.Name)) {
      return (parseFloat(value)).toFixed(0)
    }
    if (value === 0 && PERFORMANCE_MEDIAN_KEYS && (data.DisplayPLIType).toLowerCase().indexOf('median') >= 0 && (PERFORMANCE_MEDIAN_KEYS[1] === data.Name || PERFORMANCE_MEDIAN_KEYS[0] === data.Name)) {
      return value
    }
    return value
  }


  const getSumPeriodColumns = () => {
    if (!performanceSummarySettings) {
      return 0
    }

    const performanceSummaryKeys: string[] = [
      "displayPerformanceSumaryMtd",
      "displayPerformanceSumaryQtd",
      "displayPerformanceSumaryFiscalYtd",
      "displayPerformanceSumaryYtd",
      "displayPerformanceSumary1Year",
      "displayPerformanceSumary2Year",
      "displayPerformanceSumary3Year",
      "displayPerformanceSumary4Year",
      "displayPerformanceSumary5Year",
      "displayPerformanceSumary6Year",
      "displayPerformanceSumary7Year",
      "displayPerformanceSumary8Year",
      "displayPerformanceSumary9Year",
      "displayPerformanceSumary10Year",
      "displayPerformanceSumary11Year",
      "displayPerformanceSumary12Year",
      "displayPerformanceSumary13Year",
      "displayPerformanceSumary14Year",
      "displayPerformanceSumary15Year",
      "displayPerformanceSumaryCustomPeriod",
      "displayPerformanceSumarySinceFirstFullQuarter",
      "displayPerformanceSumarySinceFirstFullMonth",
      "displayPerformanceSumaryAnnualizedSinceInception",
      "displayPerformanceSumaryCumulative",
    ]

    return performanceSummaryKeys.reduce((acc, value) => {
      if (performanceSummarySettings[value]) {
        return acc + 1
      }
      return acc
    }, 0)
  }

  const showTotalAssetsButton = () => {
    const { id } = totalAssets || {} as any
    if (sleeveId && totalAssets?.id?.toString() !== sleeveId) {
      return (
        <div key={0}>
          <ReturnToTotalPortfolioButton portfolioId={portfolioId} asOfDate={asOfDate} id={id} />
        </div>
      )
    }
    return null
  }

  return (
    <Card className='card-center-performance PerformanceWidget' ariaLabel='Welcome'>
      <Section className='c-portfolio-section' title='Total Portfolio Performance (%)' subtitle={subtitle} actions={[
        <div className='button-container' key={0}>
          { showTotalPortfolioButton && showTotalAssetsButton() }
          <div key='downloadBtn' className='download-button-container'>
            <DefaultButton className='download-button' onClick={handleExcelDownloadClick}>
              <FontAwesomeIcon icon='download' className='c-sidebar-section__heading-icon' />
            </DefaultButton>
          </div>
        </div>,]}>
        {
          isFetching ? < SkeletonClientPortfolioPerformance /> : (
            <div className={`c-section-line ${getSumPeriodColumns() > 5 ? 'multicolumns' : ''}`}>
              <PerformanceSummaryGrid portfolioSettings={portfolioSettings} rows={options} sendGridApi={setGridApi}/>
              <div className='c-performance-summary-chart'>
                <GroupBarChart
                  multipleColumn={getSumPeriodColumns() > 5}
                  legend={{
                    position: 'right',
                    style: 'italic'
                  }}
                  fontOptions={{
                    font: {
                      size: 16,
                      style: 'italic'
                    }
                  }}
                  valueFormatter={{ getFormattedValue }}
                  periods={getChartPeriods()}
                  series={seriesChart}
                  xAxis={{ labelPadding: isMobile ? 0 : 30 }}
                  onSeriesClick={(seriesItem) => seriesItem}
                  onSeriesHover={(seriesItem) => seriesItem}
                />
              </div>
              <PerformanceSummaryChartLegend multipleColumns={getSumPeriodColumns() > 5} options={legendOptions}/>
            </div> )
        }
      </Section>
    </Card>
  )
}
