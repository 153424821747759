import withPermission from '@/features/auth/components/withPermission'
import { EUserCan } from '@/features/auth/types'
import { IconButton, TooltipHost } from '@fluentui/react'
import { ChangeEvent } from 'react'

type Props = {
  id?: string,
  accept: string,
  style?: { right: string | number },
  handleUpload: (file: File) => void
}

const FileUpload = ({ id, accept, style, handleUpload }: Props) => {

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      handleUpload(e.target.files[0]);
    }
  }

  return (
    <>
      <TooltipHost content="Upload Image" calloutProps={{
        gapSpace: 0,
        target: `#CP-${id}`
      }}>
        <IconButton
          id={`CP-${id}`}
          style={style}
          className="ca-browse"
          iconProps={{ iconName: 'Upload' }}
          ariaLabel="Upload Image"
          onClick={() => { document.getElementById(`${id}`).click() }}
        />
      </TooltipHost>
      <input id={`${id}`} type="file" accept={accept} style={{ visibility: 'hidden', position: 'absolute' }} onChange={handleFileChange} />
    </>
  )

}

export default withPermission(EUserCan.Edit_Content)(FileUpload)
