import { Table } from '@/shared/components'
import cn from 'classnames'
import { ColumnActionsMode, IColumn, IDetailsListProps, IShimmeredDetailsListProps } from '@fluentui/react'
import { useTranslation } from 'react-i18next'
import DateCell from './components/DateCell'
import { IArchivedMessage, IMessage } from '../../shared/types'
import SubjectCell from './components/SubjectCell'
import ActionCell from './components/ActionCell'
import { SectionProps } from '@/shared/components/Section'
import './MessagesTable.scss'
import { ITableColumn } from '@/shared/components/Table/types'
import { IMessageVersionedContent } from '@/shared/types/swagger'

interface Props extends Omit<IShimmeredDetailsListProps, 'items'> {
  extraColumns?: Record<string, IColumn>;
  extraStartColumns?: Record<string, IColumn>
  data: Array<IMessageVersionedContent> | Array<IArchivedMessage>;
  headingTKey: string;
  sectionProps?: SectionProps
  isActiveTable?: boolean,
  tableProps?: Partial<IDetailsListProps>
  renderCard?: boolean
  renderLimit?: number
}

export default function MessagesTable(props: Props) {
  const { 
    data, 
    extraColumns = {}, 
    headingTKey, 
    sectionProps, 
    extraStartColumns = {}, 
    tableProps = {}, 
    renderCard = true, 
    renderLimit
  } = props
  
  const { t } = useTranslation('messages')

  let renderData = []
  if (renderLimit) renderData = data.slice(0, renderLimit)
  else renderData = data

  // Used to allow MessagesTableActive and MessagesTableArchive to override default columns by key
  const keyedColumns: Record<string, ITableColumn> = {
    ...extraStartColumns,
    date: {
      className: 'c-messages-table__date',
      key: 'date',
      name: t('date'),
      fieldName: 'createdAt',
      minWidth: 70,
      maxWidth: 80,
      onRender: DateCell,
      mobileId: 'date',
      columnActionsMode: ColumnActionsMode.disabled,
    },
    sender: {
      className: 'c-messages-table__from',
      key: 'sender',
      name: t('from'),
      fieldName: 'sender',
      isMultiline: true,
      minWidth: 80,
      maxWidth: 100,
      columnActionsMode: ColumnActionsMode.disabled,
      hideOnMobile: true
    },
    recipients: {
      className: 'c-messages-table__recipients',
      key: 'recipients',
      name: t('to'),
      fieldName: 'recipients',
      isMultiline: true,
      minWidth: 80, 
      maxWidth: 100,
      onRender: ({ recipients }: IMessage) => recipients.map((recipient) => recipient.recipientName).join(',\n'),
      columnActionsMode: ColumnActionsMode.disabled,
      hideOnMobile: true
    },
    subject: {
      className: 'c-messages-table__subject',
      key: 'subject',
      name: t('subject'),
      fieldName: 'subject',
      minWidth: 90,
      maxWidth: 130,
      onRender: SubjectCell,
      columnActionsMode: ColumnActionsMode.disabled,
      mobileId: 'subject'
    },
    action: {
      key: 'action',
      name: t('action'),
      fieldName: 'actionId',
      minWidth: 128,
      maxWidth: 148,
      isMultiline: true,
      onRender: ActionCell,
      columnActionsMode: ColumnActionsMode.disabled,
      mobileId: 'subject'
    },
    notes: {
      className: 'c-messages-table__notes',
      key: 'notes',
      name: t('notes'),
      fieldName: 'notes',
      isMultiline: true,
      flexGrow: 1,
      columnActionsMode: ColumnActionsMode.disabled,
      minWidth: 200
    },
    ...extraColumns
  }

  const columns = Object.values(keyedColumns)

  return (
    <Table 
      sectionProps={{ ...sectionProps, className: cn('c-messages-table', sectionProps?.className) }} 
      columns={columns}
      data={renderData}
      useCard={renderCard}
      cardProps={{
        ariaLabel: t(headingTKey),
        size: 'extraSmall',
      }}
      selectionPreservedOnEmptyClick={true}
      additionalTableProps={{ ...tableProps }}
    />
  )
}
