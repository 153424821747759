import { Shimmer, ShimmerElementType,  } from '@fluentui/react'

const ShimmerRow = () => {
  return (
    <Shimmer
      shimmerElements={[
      { type: ShimmerElementType.line, height: 30 }
    ]}
    />
  )
}

export default ShimmerRow