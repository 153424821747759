import withPermission from '@/features/auth/components/withPermission'
import { EUserCan } from '@/features/auth/types'
import { ButtonModal } from '@/shared/components'
import { useDispatch } from 'react-redux'
import faqSlice from '../../redux/faqSlice'
import FAQTable from './components/FAQTable'

const ManageFAQ = () => {
  const dispatch = useDispatch()

  return (
    <ButtonModal
      openButtonLabel='Manage FAQ'
      primaryLabel='Save'
      onPrimary={() => ({})}
      modalTitle='Manage Frequently Asked Questions'    
      closeAriaLabel='Close manage FAQ'
      openButtonAriaLabel='Manage FAQ'
      paramName='ManageFAQ'
      hidePrimary={true}
      secondaryLabel='Close'
      className="c-manage-faq"
      onClose={() => {dispatch(faqSlice.actions.setQuestion(null))}}
    >
      <FAQTable />
    </ButtonModal>
  )
}

export default withPermission(EUserCan.Edit_Content)(ManageFAQ)