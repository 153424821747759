import { memo, SVGAttributes } from 'react'

type Props = SVGAttributes<SVGElement>

function RightArrow(props: Props) {
  return (
    <svg 
      viewBox="0 0 20 20" 
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path 
        fillRule="evenodd" 
        clipRule="evenodd" 
        fill="currentColor"
        d="M18.7296 9.97321C18.7283 9.47108 18.5123 8.99347 18.1361 8.66087L9.98577 1.48751C9.54817 1.12928 8.90546 1.18204 8.53214 1.60684C8.15882 2.03164 8.18906 2.67581 8.60053 3.06377L14.8976 8.60474C14.9541 8.6549 14.9738 8.73475 14.9471 8.80546C14.9204 8.87617 14.8528 8.92306 14.7773 8.92334H2.29987C1.72004 8.92334 1.25 9.39338 1.25 9.97321C1.25 10.553 1.72004 11.0231 2.29987 11.0231H14.7751C14.8506 11.0238 14.9179 11.0709 14.9446 11.1416C14.9712 11.2122 14.9516 11.292 14.8954 11.3424L8.59834 16.8834C8.29841 17.1256 8.1554 17.5128 8.22582 17.8919C8.29624 18.2709 8.56877 18.5809 8.9357 18.6993C9.30264 18.8176 9.70493 18.7254 9.98359 18.4589L18.1354 11.2855C18.512 10.9532 18.7283 10.4755 18.7296 9.97321Z"
      />
    </svg>

  )
}

export default memo(RightArrow)
