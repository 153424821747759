import { IStackTokens, PersonaSize, Stack } from '@fluentui/react'
import { useTranslation } from 'react-i18next'
import { Card, TeamMemberDetail, TeamMemberDetailSkeleton } from '@/shared/components'
import constructSkeletons from '@/shared/helpers/constructSkeletons'
import { IYourTeam, useGetYourTeamQuery } from '@/shared/api/services/teamService'

const stackToken: IStackTokens = { childrenGap: 16 }

export interface TeamCardProps {
  accountId: string;
  label?: string;
}

const getProfileImage = (teamMember: IYourTeam) => {
  if (teamMember) {
    const { image, imageContentType } = teamMember
    if (image && imageContentType) {
      return {
        url: `data:${imageContentType};base64,${image}`,
        alt: '',
      }
    }
  }
}

const TeamCard = ({ accountId, label }: TeamCardProps) => {
  const { t } = useTranslation('onboarding')
  const { data, isUninitialized, isLoading, isError } = useGetYourTeamQuery(accountId, {
    skip: !accountId
  })

  // widget not render in case no account id or API errors
  if (!accountId || isError) {
    return null
  }

  const renderContent = () => {
    if (isUninitialized || isLoading) {
      const skeletons = constructSkeletons(3, <TeamMemberDetailSkeleton />)
      return skeletons
    }
    if (!data.length) {
      return <p data-automationid="noteammsg">You have no team members assigned</p>
    }
    return data.map((teamMember) => (
      <TeamMemberDetail 
        key={`${teamMember.fullName}-${teamMember.email}`}
        data={{
          fullName: teamMember.fullName,
          jobTitle: teamMember.jobTitle,
          email: teamMember.email,
          profileImg: getProfileImage(teamMember) 
        }}
        size={PersonaSize.size100}
        coinSize={50}
      />
    ))
  }

  return (
    <Card size="large" className='c-team-card' ariaLabelledBy='onboardingTeam'>
      <h3 className='c-team-card__header' id='onboardingTeam'>{ label || t('yourTeam')}</h3>
      <Stack tokens={stackToken} className='c-team-card__members'>
        {renderContent()}
      </Stack>
    </Card>
  )
}

export default TeamCard