import { BarElement, CategoryScale, Chart, Legend, LinearScale, Title, Tooltip } from 'chart.js'
import { CSSProperties } from 'react'
import { Bar } from 'react-chartjs-2'
import { GroupBarChartBarValuePlugin, GroupBarChartBarValuePluginOptions } from './plugins/GroupBarChartBarValuePlugin'
import { GroupBarChartTooltipPlugin } from './plugins/GroupBarChartTooltipPlugin'
import { GroupBarChartBlurPlugin } from './plugins/GroupBarChartBlurPlugin'
import './GroupBarChart.scss'

// register required settings for Bar chart.js
Chart.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  GroupBarChartBarValuePlugin,
  GroupBarChartTooltipPlugin,
  GroupBarChartBlurPlugin,
)

export interface GroupBarChartFontOptions {
  font?: {
    size: number,
    family?: string,
    color?: string,
    style?: string
  }
}

export interface GroupBarChartValueFormater {
  getFormattedValue: (value: number, data: any) => number 
}

export interface GroupBarChartSeriesItem {
  name: string,
  values: number[],
  color: string,
  selectedValue?: number,
  selectedIndex?: number,
  selectedDataSetIndex?: number
}

export interface GroupBarChartProps {
  multipleColumn?: boolean,
  title?: string,
  periods: any,
  series: GroupBarChartSeriesItem[],
  responsive?: boolean,
  legend?: {
    position?: 'left' | 'top' | 'right' | 'bottom' | 'center',
    horizontalAlign?: 'start' | 'center' | 'end',
    style?: 'italic' | 'inherit'
  },
  fontOptions?: GroupBarChartFontOptions,
  valueFormatter?: GroupBarChartValueFormater,
  height?: number,
  width?: number,
  xAxis?: {
    labelPadding?: number
  }
  onSeriesHover?: (seriesItem: GroupBarChartSeriesItem) => void,
  onSeriesClick?: (seriesItem: GroupBarChartSeriesItem) => void,
}


export const GroupBarChart = (props: GroupBarChartProps) => {
  const data = {
    labels: props.periods,
    datasets: props.series.map(s => ({
      label: s.name,
      data: s.values,
      backgroundColor: s.color,
      ...s
    }))
  }

  const getAdditionalPlugins = () => {
    const additionalPlugins = {} as any
    additionalPlugins.GroupBarChartBarValuePlugin = {
      ...props.fontOptions,
      ...props.valueFormatter
    }
    additionalPlugins.GroupBarChartTooltipPlugin = {
      series: props.series || []
    }
    additionalPlugins.GroupBarChartBlurPlugin = {
      series: props.series,
      onSeriesHover: props.onSeriesHover,
    }
    return additionalPlugins
  }
  

  const getStyle = () => {
    const style = {} as CSSProperties
    if (props.height) {
      style.height = `${props.height}px`
    }
    if (props.width) {
      style.width = `${props.width}px`
    }
    return style
  }

  const externalTooltipHandler = (context) => {
    const { tooltip } = context
  }

  return (
    <div className={`GroupBarChartContainer ${ props?.multipleColumn ? 'ResizeWideChart' : ''}`} style={getStyle()}>
      <Bar data={data} options={ {
        font: {
          size: props?.fontOptions?.font?.size
        },
        plugins: {
          title: {
            display: false,
            text: props.title,
          },
          tooltip: {
            external: externalTooltipHandler,
            enabled: false,
            usePointStyle: true
          },
          legend: {
            display: false,
            position: props?.legend?.position,
            align: props?.legend?.horizontalAlign,
            labels: {
              font: {
                  size: props?.fontOptions?.font?.size,
                  style: 'italic',
              },
              boxWidth: 16            
            }
          },
          ...getAdditionalPlugins(),
        }, 
        responsive: props.responsive,
        maintainAspectRatio: false,
        interaction: {
          mode: 'index' as const,
          intersect: false,
        },
        layout: {
          padding: {
            left: 20,
            top: 20,
            bottom: 20,
            right: 20,
          }
        },
        scales: {
          x: {
            border: {
              display: false
            },
            grid: {
              drawTicks: false,
              drawOnChartArea: false,
            },
            ticks: {
              font: {
                size: props?.fontOptions?.font?.size
              },
              padding: props?.xAxis?.labelPadding || 30
            }
          },
          y: {
            border: {
              display: false
            },
            grid: {
              drawTicks: false,
              color: (ctx, options) => {
                const { tick } = ctx
                if (tick.value === 0) {
                  return 'rgba(0, 0, 0, 0.1)'
                } 
                return 'rgb(255, 255, 255)'
              },
            },
            ticks: {
              display: false,
              crossAlign:'near',
              backdropColor: (ctx, options) => {
                return 'rgb(0, 0, 0)'
              },
            }
          }
        },
        onHover(event, elements, chart) {
          if (elements.length) {
            const { x: mouseX, y: mouseY } = event
            const actualElement = elements.find(e => {
              const { element } = e
              if (element) {
                const barElement = element as any
                return (mouseX >= element.x - barElement.width / 2) && (mouseX <= element.x  + barElement.width / 2)
              }
              return false
            })

            if (actualElement) {
              chart.data.datasets.forEach((ds, datasetIndex) => {
                if (datasetIndex === actualElement.datasetIndex) {
                  ds.backgroundColor = props.series[datasetIndex].color
                } else {
                  ds.backgroundColor = props.series[datasetIndex].color + '1F'  
                }
              })
            } else {
              chart.data.datasets.forEach((ds, datasetIndex) => {
                ds.backgroundColor = props.series[datasetIndex].color
              })
            }  
            chart.update()
            
            if (props.onSeriesHover) {
              if (actualElement) {
                const seriesItem = props.series[actualElement.datasetIndex]
                const selectedSeriesItem = {
                  ...seriesItem,
                  selectedValue: seriesItem.values[actualElement.index],
                  selectedIndex: actualElement.index,
                } as GroupBarChartSeriesItem
                props.onSeriesHover(selectedSeriesItem)
              } else {
                props.onSeriesHover(undefined)
              }
            }
          } else {
            chart.data.datasets.forEach((ds, datasetIndex) => {
              ds.backgroundColor = props.series[datasetIndex].color
            })
            chart.update()
            
            if (props.onSeriesHover) {
              props.onSeriesHover(undefined)
            }
          }
        },
        onClick(event, elements, chart) {
          if (elements.length) {
            const { x: mouseX, y: mouseY } = event
            const actualElement = elements.find(e => {
              const { element } = e
              if (element) {
                const barElement = element as any
                return (mouseX >= element.x - barElement.width / 2) && (mouseX <= element.x  + barElement.width / 2)
              }
              return false
            })

            
            if (props.onSeriesClick) {
              if (actualElement) {
                const seriesItem = props.series[actualElement.datasetIndex]
                const selectedSeriesItem = {
                  ...seriesItem,
                  selectedValue: seriesItem.values[actualElement.index],
                  selectedIndex: actualElement.index,
                } as GroupBarChartSeriesItem
                props.onSeriesClick(selectedSeriesItem)
              } else {
                props.onSeriesClick(undefined)
              }
            }
          } else {
            if (props.onSeriesClick) {
              props.onSeriesClick(undefined)
            }
          }
        },
      }} />
    </div>
  )
}