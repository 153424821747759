import React, { ReactElement } from 'react'
import styles from './Container.module.scss'
import cn from 'classnames'

interface Props {
  children: React.ReactNode;
  className?: string
}

export default function Container({ children, className }: Props): ReactElement {
  return (
    <div className={cn(styles.container, className)}>
      {children}
    </div>
  )
}
