import { memo } from 'react'
import cn from 'classnames'

type ArchiveTableCellProps = {
  children: JSX.Element | JSX.Element[] | string;
  className?: string
}

const ArchiveTableCell = ({ children, className }: ArchiveTableCellProps) => {
  return (
    <td className={cn('c-message-table-archived__table__body__row__cell', className)}>
      {children}
    </td>
  )
}

export default memo(ArchiveTableCell)