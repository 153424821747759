import { Person } from '@/shared/types/person'
import { Persona, PersonaSize, PersonaPresence } from '@fluentui/react/lib/Persona'

interface Props {
  person?: Person;
}

export default function UserActionsIcon({ person }: Props) {
  const { firstName, lastName, initials } = person || {}
  
  return (
    <Persona 
      className="c-useractions"
      size={PersonaSize.size24}
      onRenderInitials={({ imageInitials }) => <span data-automationid="initials">{imageInitials}</span>}
      imageAlt={`${firstName} ${lastName}`}
      imageInitials={initials}
      presence={PersonaPresence.online}
    />
  )
}
