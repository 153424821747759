import { useMemo } from 'react'
import { INavLinkGroup } from '@fluentui/react'
import { useTranslation } from 'react-i18next'
import { getNavMenuItemURL, navMenuItems } from '@/shared/constants/navMenuItems'
import { NavMenuItem } from '../components/NavMenu/types'
import useHandleSelectionWithCSR from './useHandleSelectionWithCSR'
import { useSelector } from 'react-redux'
import { selectIsCAEmployee } from '@/features/auth/redux/authSelectors'

export default function useNavLinkGroups(items: Array<NavMenuItem>): INavLinkGroup[] {
  const { t } = useTranslation('common')
  const isCAEmployee = useSelector(selectIsCAEmployee)
  const handleSelectionWithCSR = useHandleSelectionWithCSR()

  const navLinkGroups: INavLinkGroup[] = useMemo<INavLinkGroup[]>(() => items.map((item) => {
    const hasChildren = Boolean(item.itemIds)
    const itemName = t(`nav:${item.titleKey}`)
    const itemURL = getNavMenuItemURL(item, isCAEmployee)
    let navLinkGroup: INavLinkGroup

    if (hasChildren) {
      navLinkGroup = {
        collapseByDefault: true,
        name: itemName,
        links: item.itemIds.map((childItemId) => {
          const childItem = navMenuItems[childItemId]
          const childItemURL = getNavMenuItemURL(childItem, isCAEmployee)
          return {
            key: childItem.id.toString(),
            name: t(`nav:${childItem.titleKey}`),
            url: childItemURL,
            disabled: childItem.disabled,
            target: childItem.target,
            onClick: itemURL?.startsWith?.('/') ? handleSelectionWithCSR(item) : undefined
          }
        })
      }
    } else {
      navLinkGroup = { 
        links: [
          {
            key: item.id.toString(),
            name: itemName,
            disabled: item.disabled,
            url: itemURL,
            target: item.target,
            onClick: itemURL?.startsWith?.('/') ? handleSelectionWithCSR(item) : undefined
          }
        ] 
      }
    }

    // TODO: refactor to be recursive
    return navLinkGroup
  }), [handleSelectionWithCSR, items, t, isCAEmployee])

  return navLinkGroups
}