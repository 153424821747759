import dayjs from '@/bootstrap/dayjs'
import { IAccount } from '@/shared/types/swagger'

export default function LastActiveCell(client: IAccount) {
  const { updatedAt } = client
  const formattedDate = dayjs(updatedAt).format('LL')

  return (
    <time className="c-clients-table__cell-lastactive" dateTime={formattedDate}>{formattedDate}</time>
  )
}
