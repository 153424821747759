import ManageInsightFilters from '@/features/insight/shared/components/InsightModal/components/InsightFilters'
import { TableFilter } from '../../ManageInsightModal'
import { ModalManagement } from '@/shared/components'
import { useTranslation } from 'react-i18next'

type FeaturedInsightDescriptionProps = {
  userIsManagingInsights: boolean
  setUserIsManagingInsights: (value: boolean) => void
  filters: TableFilter,
  setFilters: (updatedFilters: TableFilter) => void,
  isInsightList: boolean
}

const ManageInsightDescription = ({ 
  userIsManagingInsights, 
  setUserIsManagingInsights, 
  filters, 
  setFilters, 
  isInsightList
 }: FeaturedInsightDescriptionProps) => {
  const { t } = useTranslation('insight')
  return (
    <>
      <ModalManagement
        userIsManaging={userIsManagingInsights}
        setUserIsManaging={setUserIsManagingInsights}
        autoManageLabel={isInsightList ? t('featuredAutoManageInsightLabel') : t('autoManageInsightLabel')}
        manuallyManageLabel={isInsightList ? t('featuredManualManageInsightLabel') : t('manualManageInsightLabel')}
      />
      <ManageInsightFilters 
        filters={filters}
        setFilters={setFilters}
      />
    </>
  )
}

export default ManageInsightDescription