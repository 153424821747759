import { createContext } from 'react'

export type FilterSortParam<ValueType> = {
  key: string,
  value: ValueType
}

export interface ITableContext {
  id?: string,
  search?: FilterSortParam<string>
  page?: FilterSortParam<number>
  sortField?: FilterSortParam<string>
  sortOrder?: FilterSortParam<string>
  searchFields?: FilterSortParam<string>[]
  setValue?: (ctx: ITableContext) => void
}

const defaultValue: ITableContext = {}

const TableContext = createContext(defaultValue)
export default TableContext