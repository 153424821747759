import { INotificationViewModel } from '@/shared/types/swagger'
import { mainService } from '.'

type GetAllNotificationsReturn = {
  unread: Array<INotificationViewModel>
  read: Array<INotificationViewModel>
}

const notificationWithTags = mainService.enhanceEndpoints({ addTagTypes: ['Notifications'],  })

const notificationServices = notificationWithTags.injectEndpoints({
  endpoints(build) {
    return {
      getBellNotifications: build.query<Array<INotificationViewModel>, void>({
        query: () => ({ url: '/Notifications', method: 'GET' }),
        providesTags: ['Notifications']
      }),
      getAllNotifications: build.query<GetAllNotificationsReturn, void>({
        query: () => ({ url: '/Notifications/All', method: 'GET' }),
        providesTags: ['Notifications'],
        transformResponse: (response: Array<INotificationViewModel> = [] , meta, arg) => {
          const unread = []
          const read = []
          response.forEach((notification) => {
            if (notification.hasBeenRead) read.push(notification)
            else unread.push(notification)
          })
          return { read, unread }
        }
      }),
      putNotificationStatus: build.mutation<void, { notificationId: string, isRead: boolean }>({
        query: (data) => ({
          url: `/Notifications/MarkAsRead/${data.notificationId}`,
          method: 'PUT',
          data: {
            read: data.isRead
          }
        }),
        invalidatesTags: ['Notifications'],
        onQueryStarted: async ({ notificationId, isRead }, { dispatch, queryFulfilled }) => {
          const putResult = dispatch(
            notificationServices.util.updateQueryData('getAllNotifications', undefined, draft => {
              // if we're saying a notification is read - we want to pull it out of unread -> read and vice versa.
              if (isRead) { 
                const nIndex = draft.unread.findIndex((notification) => notification.id === notificationId)
                const notification = draft.unread.splice(nIndex, 1)[0]
                draft.read.push(notification)
              } else {
                const nIndex = draft.read.findIndex((notification) => notification.id === notificationId)
                const notification = draft.read.splice(nIndex, 1)[0]
                draft.unread.push(notification)
              }
            })
          )
          try {
            await queryFulfilled
          } catch {
            putResult.undo()
          }
        }
      })
    }
  },
})

export const { useGetBellNotificationsQuery, useGetAllNotificationsQuery, usePutNotificationStatusMutation  } = notificationServices
