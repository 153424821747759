import { Spinner, SpinnerSize } from '@fluentui/react'
import { GeneralError } from '@/shared/components'
import { useGetMessagesByRecipientIdQuery } from '@/shared/api/services/messageService'
import Message from './components/Message'
import './NotificationList.scss'
import { nanoid } from '@reduxjs/toolkit'
import { useSelector } from 'react-redux'
import { selectClaims } from '@/features/auth/redux/authSelectors'

const MessagesList = () => {
  const account = useSelector(selectClaims)
  const { data, isError, isSuccess, refetch, isLoading, isUninitialized } = useGetMessagesByRecipientIdQuery(account?.contactId)

  const renderContent = () => {
    if (isError) { 
      return ( 
        <div className='c-notification-list__content-spacer'>
          <GeneralError title='Failed to get messages' onClick={refetch} /> 
        </div>
      )
    }
    if (isLoading || isUninitialized) {
      return (
        <div className='c-notification-list__content-spacer'>
          <Spinner size={SpinnerSize.large} /> 
        </div>
      )
    }
    if (isSuccess && !data.length) {
      return (
        <div className='c-notification-list__content-spacer'>
          <p>You have no messages.</p> 
        </div>
      )
    }
    return data.map((message) => {
      return (
        <Message  
          key={nanoid(5)}
          author={message.sender} 
          time={message.createdAt || message.updatedAt}
          title={message.subject}
          description={message.notes}
          cta={message.documentLink}
        />  
      )
    })
  }

  return (
    <ul className='c-notification-list'>
      {renderContent()}
      <li className='c-notification-list__more'>
        <a href='/messages'>See all messages</a>
      </li>
    </ul>
  )
}

export default MessagesList