import { memo } from 'react'
import { CustomProps } from '../TextField'

type Props = Pick<CustomProps, 'description'>

function TextFieldDescription({ description }: Props) {
  return (
    <span className="c-textfield-desc">
      {description}
    </span>
  )
}

export default memo(TextFieldDescription)
