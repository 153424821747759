import { memo, SVGAttributes } from 'react'

type Props = SVGAttributes<SVGElement>

function VirtualEvent(props: Props) {
  return (
    <svg 
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path 
        d="M8.25444 8.00266C9.22093 8.00266 10.0044 8.78616 10.0044 9.75266V19.2528C10.0044 20.2193 9.22093 21.0028 8.25444 21.0028H3.75C2.7835 21.0028 2 20.2193 2 19.2528V9.75266C2 8.78616 2.7835 8.00266 3.75 8.00266H8.25444ZM8.25444 9.50266H3.75C3.61193 9.50266 3.5 9.61459 3.5 9.75266V19.2528C3.5 19.3909 3.61193 19.5028 3.75 19.5028H8.25444C8.39251 19.5028 8.50444 19.3909 8.50444 19.2528V9.75266C8.50444 9.61459 8.39251 9.50266 8.25444 9.50266ZM6.25222 16.9997C6.66643 16.9997 7.00222 17.3354 7.00222 17.7497C7.00222 18.1294 6.72006 18.4431 6.35399 18.4928L6.25222 18.4997H5.75222C5.338 18.4997 5.00222 18.1639 5.00222 17.7497C5.00222 17.37 5.28437 17.0562 5.65045 17.0065L5.75222 16.9997H6.25222ZM21.2494 16.4995C21.6636 16.4995 21.9994 16.8353 21.9994 17.2495C21.9994 17.6292 21.7172 17.943 21.3511 17.9927L21.2494 17.9995L11 17.999V16.499L21.2494 16.4995ZM18.25 5C19.2165 5 20 5.7835 20 6.75V14.2503C20 15.2168 19.2165 16.0003 18.25 16.0003L11 16V14.5L18.25 14.5003C18.3881 14.5003 18.5 14.3884 18.5 14.2503V6.75C18.5 6.61193 18.3881 6.5 18.25 6.5H5.75C5.61193 6.5 5.5 6.61193 5.5 6.75L5.499 6.999H4V6.75C4 5.7835 4.7835 5 5.75 5H18.25Z" 
        fill="#0078D4"
      />
    </svg>

  )
}

export default memo(VirtualEvent)
