import usePermission from '@/features/auth/hooks/usePermission'
import { selectIsCAEmployee } from '@/features/auth/redux/authSelectors'
import { EUserCan } from '@/features/auth/types'
import { useSelector } from 'react-redux'

export default function useCanAccessAdminToolbar(): boolean {
  const isCaEmployee = useSelector(selectIsCAEmployee)
  const canAccessClients = usePermission(EUserCan.Access_Clients)

  // This would ideally be bound to another boolean but since the toolbar only 
  // has one link it will only show when that one link is available
  return isCaEmployee && canAccessClients
}