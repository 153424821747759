import Skeleton from 'react-loading-skeleton'
import constructSkeletons from '@/shared/helpers/constructSkeletons'

export type Section = {
  label: string,
  value: string
}

type TextSectionProps = {
  title: string
  isLoading?: boolean
  sections: Array<Section>
}

const TextSectionSkeleton = () => (
  <div className='c-profile-text-section__list__content --skeleton'>
    <Skeleton className='c-profile-text-section__list__content__title' />
    <Skeleton className='c-profile-text-section__list__content__detail' />
  </div>
)

const TextSection = ({ sections, title, isLoading = false }: TextSectionProps) => {

  const renderContent = () => {
    if (isLoading) {
      const skeletons = constructSkeletons(5, <TextSectionSkeleton />)
      return skeletons
    }
    return sections.map((section) => (
      <div className='c-profile-text-section__list__content' key={`${section.label}-${section.value}`}>
        <dt className='c-profile-text-section__list__content__title'>{section.label}</dt>
        <dd className='c-profile-text-section__list__content__detail'>{section.value}</dd>
      </div>
    ))
  }
  
  return (
    <section className='c-profile-text-section'>
      <header className='c-profile-text-section__header'>{title}</header>
      <dl className='c-profile-text-section__list'>
        {renderContent()}
      </dl>
    </section>
  )
}

export default TextSection