import { formatDate, parseFormattedDate } from '@/lib/common/services/date/DateService'
import { IPortfolioSettings, PerformanceRawData, exportGridData, getPerformanceGridProcessCellCallback } from '@/shared/api/services/portfolioService'
import { Card, GeneralError, Section } from '@/shared/components'
import { DefaultButton } from '@fluentui/react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useState } from 'react'
import SkeletonClientPortfolioPerformance from '../SkeletonClientPortfolioPerformance'
import PerformanceGrid, { PerformanceGridInitializeEvent } from '../grid/PerformanceGrid'
import PanelOptions from './PanelOptions/PanelOptions'
import { GridApi } from 'ag-grid-community'
import PerformanceGridFootNote from '../../Components/Grid/FootNote/PerformanceGridFootNote'

import './ClientPortfolioPerformanceMobile.scss'

export interface ClientPortfolioPerformanceMobileProps {
  isFetching: boolean,
  isError: boolean,
  isFootNoteHidden: boolean,
  asOfDate: string,
  rows: PerformanceRawData[],
  portfolioSettings: IPortfolioSettings,
  refetch: () => void,
}

export default function ClientPortfolioPerformanceMobile({ 
  isFetching,
  isError,
  isFootNoteHidden,
  asOfDate,
  rows,
  portfolioSettings,
  refetch,
}: ClientPortfolioPerformanceMobileProps) {
  const [ isBenchmarkValueAddedSelected, setBencharmkValueAddedSelected ] = useState<boolean>(true)
  const [ isSleevesExpanded, setSleevesExpanded ] = useState<boolean>(true)
  const [ isFullScreen, setIsFullScreen ] = useState<boolean>(false)
  const parsedAsOfDate = parseFormattedDate(asOfDate)
  const subtitle = `As of ${formatDate(parsedAsOfDate, 'MMM dd, yyyy')}`
  const [ gridApi, setGridApi ] = useState(null as GridApi)
  
  if (isError) {
    return (
      <Card className='card-center card-ag-grid-wrapper card-ag-grid-performance' ariaLabel='Welcome'>
        <Section className='c-portfolio-section-chart' title='Performance' subtitle={subtitle}>
          <GeneralError title='Failed to load Portfolio Performance' onClick={refetch} />
        </Section>
      </Card>
    )
  }
  
  const handleFullScreenMode = () => {
    setIsFullScreen(!isFullScreen)
  }

  const handleGridInitialize = (event: PerformanceGridInitializeEvent) => {
    setGridApi(event.gridApi)
  }

  const handleDownloadClick = () => {
    exportGridData({
      gridApi: gridApi,
      excelTabName: 'Performance',
      fileName: `performance-export-investment-pool-${portfolioSettings?.investmentPoolName}-${portfolioSettings?.investmentPoolId}-as-of-date-${asOfDate}`,
      processCellCallback: getPerformanceGridProcessCellCallback(),
    })
  }
 
  return (
    <Card className={`card-center card-ag-grid-wrapper card-ag-grid-performance ${isFullScreen ? 'full-screen-mode' : ''}`} ariaLabel='Welcome'>
      {
        isFetching ? <SkeletonClientPortfolioPerformance /> :
        <Section className='c-portfolio-section-chart-mobile' title='' subtitle={subtitle} actions={[
          <DefaultButton 
            key={0}
            className='fullSizeButton'
            onClick={handleFullScreenMode}
          >
            <FontAwesomeIcon className='icons-mb' icon={['fas', `${ isFullScreen ? 'down-left-and-up-right-to-center' : 'up-right-and-down-left-from-center' }`]} />
          </DefaultButton>,
          <PanelOptions 
            key={1}
            isBenchmarkValueAddedFiltered={isBenchmarkValueAddedSelected}
            onChangeBenchValueAddedFilter={(value) => {
              setBencharmkValueAddedSelected(value)
            }}
            isExpanded={isSleevesExpanded}
            onChangeExpand={(value) => {
              setSleevesExpanded(value)
            }}
          ></PanelOptions>,
          <div  key='downloadBtn' className='download-button-container'>
            <DefaultButton className='download-button'  onClick={handleDownloadClick}>
              <FontAwesomeIcon icon='download' className='c-sidebar-section__heading-icon' />
            </DefaultButton>
          </div>
        ]}>
          { !isFetching ? <PerformanceGrid portfolioSettings={portfolioSettings} isFullScreen={false}  isFullScreenMode={isFullScreen}
            rows={rows} isSleevesExpanded={isSleevesExpanded} isEmptyCollumnHidden={true} onGridInitialize={handleGridInitialize} /> : null }
          <PerformanceGridFootNote hidden={isFootNoteHidden} showTitle={true} />
        </Section>
      }
    </Card>
  )
}
