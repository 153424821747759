import { RouteComponentProps } from 'react-router'
import { ContentExplorer } from 'box-ui-elements/es/elements'
import { IntlProvider } from 'react-intl'
import { useGetBoxTokenQuery } from '@/shared/api/services/boxServices'

export default function BoxScreen(props: RouteComponentProps) {
  const { data, isLoading, isFetching } = useGetBoxTokenQuery(null)

  if (isLoading || isFetching) return null
  return (
    <IntlProvider locale="en">
      <div className="bue-test">
        <div className="bue-test__section">
          <h2>Content Explorer</h2>
          <ContentExplorer token={data.accessToken} />
        </div>
      </div>
    </IntlProvider>
  )
}
