import dayjs from '@/bootstrap/dayjs'
import getName from '@/features/client/helpers/getName'
import { useGetCollaboratorsQuery } from '@/shared/api/services/boxServices'
import { useGetContactsByAccountIdQuery } from '@/shared/api/services/crmService'
import { EDateFormat } from '@/shared/types/date'
import { useMemo } from 'react'
import { Collaborator } from '../types'

const getUpCharAt = (i: number, parts: Array<string>) => parts?.[i]?.[0]?.toUpperCase?.() ?? ''
const getInitials = (name: string) => {
  if (!name) return ''
  const parts = name.split(' ')
  return `${getUpCharAt(0, parts)}${getUpCharAt(1, parts)}`
}

type HookReturn = { 
  data: Array<Collaborator>;
  isLoading: boolean; 
  isSuccess: boolean;
  isError: boolean;
}

export default function useGetContactCollaborators(accountid: string, ...args: Parameters<typeof useGetCollaboratorsQuery>): HookReturn {
  const [folderId, options] = args
  const { data: collaborations, ...collaborationsQS } = useGetCollaboratorsQuery(folderId, { skip: options.skip })
  const { data: contacts, ...contactsQS } = useGetContactsByAccountIdQuery(accountid)
  const isLoading = !accountid 
    || contactsQS.isLoading 
    || contactsQS.isFetching
    || contactsQS.isUninitialized 
    || collaborationsQS.isLoading 
    || collaborationsQS.isFetching
    || collaborationsQS.isUninitialized
  const isSuccess = contactsQS.isSuccess && collaborationsQS.isSuccess
  const isError = contactsQS.isError || collaborationsQS.isError

  const collaborators = useMemo(() => {
    return (contacts || []).map((contact) => {
      const name = getName(contact)
      const collaborationData = collaborations?.[contact.boxId]
      return {
        id: collaborationData?.id,
        boxId: contact.boxId,
        name,
        initials: getInitials(name),
        email: contact?.email1,
        access: collaborationData?.role ?? 'noaccess',
        createdAt: collaborationData?.createdAt ? dayjs(collaborationData?.createdAt).format(EDateFormat.short) : ''
      } as Collaborator
  })
  }, [collaborations, contacts])

  return {
    data: collaborators,
    isLoading,
    isSuccess,
    isError
  }
}