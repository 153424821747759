import faqSlice, { selectQuestionBeingEdited } from '@/features/support/redux/faqSlice'
import { FAQ } from '@/features/support/types/faq'
import { FieldErrorMessage } from '@/shared/components'
import Editor from '@/shared/components/Editor'
import { useDispatch, useSelector } from 'react-redux'
import getTextFromHTML from '../../../helpers/getTextFromHTML'
import textIsLongerThan from '../../../helpers/textIsLongerThan'

type FAQTableAnswerProps = {
  item: FAQ
  beingEdited: boolean
}

const FAQTableAnswer = ({ item, beingEdited }: FAQTableAnswerProps) => {
  const dispatch = useDispatch()
  const faqQuestion = useSelector(selectQuestionBeingEdited)

  const handleTextChange = (newValue: string) => {
    dispatch(faqSlice.actions.setQuestionData({
      key: 'questionResponse',
      newValue
    }))
  }

  const justWords = getTextFromHTML(faqQuestion?.questionResponse)
  const errorMessage: string = (() => {
    if (justWords.length === 0) return 'Answer is required'
    if (textIsLongerThan(justWords, 25)) return 'Answer cannot be more than 25 words'
    return ''
  })()

  const responseHtml = item.questionResponse
  if (beingEdited) {
    return (
      <div>
        <Editor 
          canResize={false}
          height={120}
          onChange={handleTextChange}
          initialValue={responseHtml}
        />
        <FieldErrorMessage message={errorMessage} />
      </div>
    )
  }

  return (
    <div style={{ paddingTop: '8px' }} dangerouslySetInnerHTML={{ __html: responseHtml }} />
  )
}

export default FAQTableAnswer