import cn from 'classnames'
import { Dialog, DialogFooter, IDialogProps } from '@fluentui/react'

interface CADialogProps extends IDialogProps {
  children: JSX.Element | JSX.Element[]
}

const CADialog = (props: CADialogProps) => {
  return (
    <Dialog
      {...props}
      modalProps={{
        ...props?.modalProps,
        className: cn('c-dialog-modal', props?.modalProps?.className)
      }}
    >
      <DialogFooter>
        {props.children}
      </DialogFooter>
    </Dialog>
  )
}

export default CADialog