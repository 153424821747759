import dayjs, { Dayjs } from 'dayjs'
import { timePattern } from '../constants/validation'

export type DTFormattingSharedArgs = [date: Date, time: string, meridiem: string, tz?: string]

export function getDayjsDateFromParts(...args: DTFormattingSharedArgs): Dayjs {
  const [date, time, meridiem, tz = dayjs.tz.guess()] = args
  if (!date || !time || !meridiem) return null
  if (!timePattern.test(time)) return null
  // eslint-disable-next-line prefer-const
  let [hours, minutes] = time.split(':').map(Number) // xx:yy - xx = hours / yy = minutes
  if (meridiem.toLowerCase() === 'pm' && hours !== 12) hours += 12 // Add 12 hours to set past AM
  const updatedDay = dayjs(date).set('hour', hours).set('minutes', minutes).set('seconds', 0)
  return tz ? updatedDay.tz(tz) : updatedDay
}

export default function getFormattedTime(...args: DTFormattingSharedArgs): string {
  try {
    const formattedTime =  getDayjsDateFromParts(...args).format()
    if (!dayjs(formattedTime).isValid) throw new Error('Invalid date')
    return dayjs.utc(formattedTime).local().tz(args[3] ?? dayjs.tz.guess()).format()
  } catch (error) {
    console.error('Error formatting Datetime', (error as Error)?.message)
    return undefined
  }
}