import { ColumnActionsMode, IColumn } from '@fluentui/react'
import { useTranslation } from 'react-i18next'
import LastActiveCell from './components/LastActiveCell'
import ActionsCell from './components/ActionsCell'
import NameCell from './components/NameCell'
import NetworkPaginatedTable from '@/shared/components/Table/components/NetworkPaginatedTable'
import { GeneralError } from '@/shared/components'
import { useGetClientsQuery } from '@/shared/api/services/adminServices'
import { useLocation } from 'react-router-dom'
import { useState } from 'react'
import TableContext, { FilterSortParam, ITableContext } from '@/shared/components/Table/context'
import { nanoid } from '@reduxjs/toolkit'
import PhaseDropdownFilter, { PHASE_QUERY_PARAM } from './components/PhaseDropdownFilter'

export default function ClientsTable() {
  const loc = useLocation()
  const queryParams = new URLSearchParams(loc.search)
  const { t } = useTranslation('clients')
  const id = nanoid(4)

  const getSearchFields = (searchKeys: string[]) => {
    return (searchKeys || []).reduce((acc, currentKey) => {
      const currentValue = queryParams.get(currentKey)
      if (currentValue) {
        acc.push({
          key: currentKey,
          value: currentValue,
        })
      }
      return acc
    }, [] as FilterSortParam<string>[])
  }

  const [contextValue, setContextValue] = useState<ITableContext>({ 
    id, 
    search: { key: 'q', value: queryParams.get('q') || '' },
    searchFields: getSearchFields([PHASE_QUERY_PARAM]),
    page: { key: 'page', value: Number(queryParams.get('page')) || 1 },
    sortField: { key: 'sf', value: queryParams.get('sf') ?? '' },
    sortOrder: { key: 'so', value: queryParams.get('so') ?? '' }
  })

  const getEqualsValues = () => {
    const phase = queryParams.get(PHASE_QUERY_PARAM);

    if (phase) {
      if (phase.toLowerCase() === 'onboarding') {
        return {
          columnequals: 'isOnboardingNotified,clientStatus',
          valueequals: '1,inactive'
        };
      }
      return {
        columnequals: 'clientStatus',
        valueequals: phase,
      }
    }
    return {
      columnequals: '',
      valueequals: '',
    }
  }

  const getSortOptions = () => {
    let column = contextValue?.sortField?.value
    let order = contextValue?.sortOrder?.value

    if (column === 'clientStatus') {
      column = 'clientStatus,isOnboardingNotified'

      if (order === 'desc') {
        order = 'desc,desc'
      } else {
        order = 'asc,asc'
      }
    }

    return {
      column,
      order,
    }
  }

  const getNotEqualsValues = () => {
    const equalsValues = getEqualsValues()

    if ((equalsValues.columnequals === 'clientStatus') && (equalsValues.valueequals === 'Inactive')) {
      return {
        columnnotequals: 'isOnboardingNotified',
        valuenotequals: '1',
      }
    }

    return {
      columnnotequals: '',
      valuenotequals: '',
    }
  }

  const equalsValues = getEqualsValues()
  const sortOptions = getSortOptions()
  const notEqualsValues = getNotEqualsValues()

  const { isError, isLoading, isUninitialized, data, refetch, isFetching } = useGetClientsQuery({ 
    page: contextValue.page.value as number, 
    contains: contextValue.search.value as string,
    columnsort: sortOptions?.column,
    dirsort: sortOptions?.order,
    columncontains: 'accountName',
    columnequals: equalsValues.columnequals,
    valueequals: equalsValues.valueequals,
    columnnotequals: notEqualsValues.columnnotequals,
    valuenotequals: notEqualsValues.valuenotequals,
  })

  const columns: Array<IColumn> = [
    {
      key: 'accountName',
      name: t('clientgroup').toUpperCase(),
      fieldName: 'accountName',
      minWidth: 100,
      onRender: NameCell,
    },
    {
      key: 'clientStatus',
      name: t('phase').toUpperCase(),
      fieldName: 'clientStatus',
      minWidth: 140,
    },
    {
      key: 'updatedAt',
      name: t('lastactive').toUpperCase(),
      fieldName: 'updatedAt',
      minWidth: 100,
      onRender: LastActiveCell,
    },
    {
      key: 'actions',
      name: '',
      fieldName: 'actions',
      ariaLabel: t('clientActions'),
      minWidth: 150,
      onRender: ActionsCell,
      isPadded: true,
      columnActionsMode: ColumnActionsMode.disabled,
    }
  ]

  const phaseDropdownFilter = (
    <PhaseDropdownFilter />
  )

  if (isError) return <GeneralError title='Failed to load accounts' onClick={refetch} />

  return (
    <TableContext.Provider value={{ ...contextValue, setValue: setContextValue }}>
      <NetworkPaginatedTable 
        data={data?.accounts ?? []}
        columns={columns}
        useCard
        sectionProps={{ title: t('sectionTitle'), className: 'c-clients-table', actions: [phaseDropdownFilter] }}
        cardProps={{ ariaLabel: t('tableTitle') }}
        listCount={data?.totalItems ?? 0}
        shimmerIsEnabled={isLoading || isUninitialized || isFetching}
        shimmerLineCount={10}
        pageSize={10}
      />
    </TableContext.Provider>
  )
}
