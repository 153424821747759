import { emmaApiService } from '.'

export interface CaOffice {
  caOfficeId: string;
  imgRequest: {
    src: string;
    alt: string;
  };
  city: string;
  region: string;
  addressParts: string[];
  phone: string;
  officeUrl: string;
  isSelected?: boolean;
}

export interface AccountOfficeRequest {
  accountId: string;
  caOfficeId: string;
}

export interface AccountOffice {
  accountOfficeId: number;
  accountId: string;
  caOfficeId: number;
  IsActive: boolean;
  createdOn: Date;
  modifiedOn: Date;
}

const caOfficeService = emmaApiService
  .enhanceEndpoints({ addTagTypes: ['CAOffices'] })
  .injectEndpoints({
    endpoints(build) {
      return {
        getAllCAOffices: build.query<CaOffice[], void>({
          query: () => ({
            url: '/CaOffice/getAll',
            method: 'GET'
          }),
          providesTags: ['CAOffices']
        }),
        getAllOfficesByAccountId: build.query<CaOffice[], string>({
          query: (accountId) => ({
            url: `/CaOffice/getAll/${accountId}`,
            method: 'GET'
          }),
          providesTags: ['CAOffices']
        }),
        saveAccountOffice: build.mutation<AccountOffice, AccountOfficeRequest>({
          query: (postData) => (
            {
              url: '/CaOffice/saveAccountOffice',
              method: 'POST',
              data: postData
            }
          ),
          invalidatesTags: ['CAOffices']
        }),
      }
    },
  })

export const {
  useGetAllCAOfficesQuery,
  useGetAllOfficesByAccountIdQuery,
  useSaveAccountOfficeMutation,
} = caOfficeService;
