import { TableProps } from '@/shared/components/Table'
import { ColumnActionsMode, Link } from '@fluentui/react'
import dayjs from 'dayjs'
import StatusCell from '../components/NotificationTable/components/StatusCell'

type notificationTableColumnsProps = (
  isReadTable: boolean,
  setHasError: (value: boolean) => void
) => TableProps['columns']

const notificationTableColumns: notificationTableColumnsProps  = (isReadTable, setHasError) => {
  return [
    {
      key: 'dateColumn',
      name: 'Date',
      fieldName: 'date',
      minWidth: 70,
      maxWidth: 90,
      onRender: ({ date }: { date: string }) => {
        return (
          <span className='c-notification-table__cell__date'>{dayjs(date).format('L')}</span>
        )
      }
    },
    {
      key: 'fromColumn',
      name: 'From',
      fieldName: 'author',
      minWidth: 100,
      maxWidth: 150,
      columnActionsMode: ColumnActionsMode.disabled,
      onRender: ({ author }: { author: string }) => {
        return (
          <>
            {/* <Persona
              imageInitials={getUserInitials(senderName)}   
            />
              Currently un-needed as MVP doesn't have images but will be needed in the future!
            */}
            <span className='c-notification-table__cell__sender' >{author}</span>
          </>
        )
      }
    },
    {
      key: 'notificationColumn',
      name: 'Notification',
      fieldName: 'title',
      minWidth: 200,
      flexGrow: 1,
      columnActionsMode: ColumnActionsMode.disabled,
      onRender: ({ title, description }: { title: string, description: string}, index) => {
        return (
          <>
            <h3 className='c-notification-table__cell__title'>{title}</h3>
            <p className='c-notification-table__cell__message'>{description}</p>
          </>
        )
      }
    },
    {
      key: 'actionColumn',
      name: 'Action',
      fieldName: 'action',
      minWidth: 100,
      columnActionsMode: ColumnActionsMode.disabled,
      onRender: ({ docLink }: { docLink: string}, index) => {
        return (
          <Link className='c-notification-table__cell__action' href={docLink}>View</Link>
        )
      }
    },
    {
      key: 'statusColumn',
      name: 'Status',
      fieldName: 'status',
      minWidth: 100,
      columnActionsMode: ColumnActionsMode.disabled,
      onRender: ({ id }: { id: string }, index) => {
        return  (
          <StatusCell 
            notificationId={id}
            isReadTable={isReadTable} 
            setHasError={setHasError}
          />
        )
      }
    }
  ]
}

export default notificationTableColumns