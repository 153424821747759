import { useTranslation } from 'react-i18next'

export default function useDateParts(datetime: Date) {
  const { i18n } = useTranslation()

  const month = new Intl.DateTimeFormat([i18n.language, ...navigator.languages], { month: 'long' }).format(datetime)
  const year = new Intl.DateTimeFormat([i18n.language, ...navigator.languages], { year: 'numeric' }).format(datetime)
  const day = new Intl.DateTimeFormat([i18n.language, ...navigator.languages], { day: '2-digit' }).format(datetime)

  return { month, year, day }
}