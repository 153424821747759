import type { TableProps } from '@/shared/components/Table'
import { ColumnActionsMode } from '@fluentui/react'
import { nanoid } from '@reduxjs/toolkit'
import { ModalCheckbox, TableLinkCell } from '@/shared/components'
import dayjs from 'dayjs'
import { InsightsPostViewWithDisplayed } from '../components/ManageInsightModal/ManageInsightModal'
import { IInsightsPostViewWithAlt } from '../shared/types'

// Data is reliant on implementation inside of FeaturedInsightModal!
type TableOptionsReturn = (
  isBeingManuallyManaged: boolean, 
  onDisplayedClick: (item: unknown, index: number) => void,
  onFeaturedClick: (item: unknown, index: number) => void,
  ) => TableProps['columns']

const tableOptions: TableOptionsReturn = (
  isBeingManuallyManaged, 
  onDisplayedClick, 
  onFeaturedClick,
) => [
    {
      key: 'insightColumn',
      name:'Insight',
      fieldName: 'title',
      minWidth: 100,
      maxWidth: 361,
      onRender: (item: IInsightsPostViewWithAlt, index, column) => { 
        return (
          <TableLinkCell label={item.title} pageUrl={item.link} />
        )
      }
    },
    {
      key: 'categoryColumn',
      name: 'Category',
      fieldName: 'category',
      minWidth: 100,
      maxWidth: 175,
      columnActionsMode: ColumnActionsMode.disabled,
      onRender: (item: IInsightsPostViewWithAlt) => (
        <span>{item.categories.join(', ')}</span>
      )
    },
    {
      key: 'tagsColumn',
      name: 'Tags',
      fieldName: 'tags',
      minWidth: 100,
      columnActionsMode: ColumnActionsMode.disabled,
      onRender: (item: IInsightsPostViewWithAlt) => (
        <span>{Object.entries(item.tags).map(([key, value], k) => { return (<span key={key} id={key} >{value}{(k != (Object.keys(item.tags).length - 1)) && ", "}</span>) })}</span>
      )
    },
    {
      key: 'publishedColumn',
      name: 'Published',
      fieldName: 'createdAt',
      minWidth: 120,
      onRender: (item: IInsightsPostViewWithAlt) => (
        <span>{dayjs(item.createdAt).format('L')}</span>
      )
    },
    {
      key: 'displayedColumn',
      name: 'Displayed',
      fieldName: 'isDisplayed', 
      minWidth: 80,
      maxWidth: 100,
      columnActionsMode: ColumnActionsMode.disabled,
      onRender: (item: InsightsPostViewWithDisplayed, index, column) => {
        return (
          <ModalCheckbox 
            isChecked={item.isDisplayed || item.isFeatured}
            index={index}
            onChange={onDisplayedClick}
            item={item}
            disabled={!isBeingManuallyManaged || item.isFeatured}
          />
        )
      }
    },
    {
      key: 'featuredColumn',
      name: 'Featured',
      fieldName: 'isFeatured', 
      minWidth: 80,
      maxWidth: 100,
      columnActionsMode: ColumnActionsMode.disabled,
      onRender: (item: { isFeatured: boolean }, index) => {
        return (
          <ModalCheckbox 
            key={nanoid(6)} // We have to reset the key otherwise this component does not re-render when we sort the columns.
            isChecked={item.isFeatured}
            index={index}
            onChange={onFeaturedClick} 
            item={item}
            disabled={!isBeingManuallyManaged}
            isRounded
          />
        )
      }
    }
  ]

export default tableOptions