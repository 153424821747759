import { forwardRef, Ref, useCallback, useEffect, useMemo, useState } from 'react'
import { ChoiceGroup, IStackTokens, Label, Stack, TextField } from '@fluentui/react'
import { useTranslation } from 'react-i18next'
import { useForm, Controller } from 'react-hook-form'
import { ErrorMessage, FieldErrorMessage } from '@/shared/components'
import { nanoid } from '@reduxjs/toolkit'
import { useDispatch } from 'react-redux'
import modalSlice from '@/shared/redux/modalSlice'
import { FeaturedMediaType, IFeaturedMediaViewModel } from '@/shared/types/swagger'
import { useUploadDigestMutation } from '@/shared/api/services/sharedServices'
import Loader from '@/shared/components/Loader'
import { formatBytes } from '../../helper'
import { AUDIO_MAX_SIZE, IMAGE_MAX_SIZE, VIDEO_MAX_UPLOAD_SIZE } from '@/environment/environment.constants'

const stackToken: IStackTokens = { childrenGap: 16, maxWidth: 420, padding: '0px 0px 48px' }

enum eFormFields {
  IntroTitle = 'introTitle',
  MainTitle = 'mainTitle',
  MediaType = 'mediaType',
  URL = 'url',
  Caption = 'caption',
  LinkUrl = 'linkUrl',
}

type MediaFormProps = {
  digest: IFeaturedMediaViewModel
}

const MediaForm = forwardRef(({ digest }: MediaFormProps, ref: Ref<HTMLFormElement>) => {
  const { t } = useTranslation('dashboard')
  const { handleSubmit, control, watch, setValue, formState, register, getValues } = useForm()
  const dispatch = useDispatch()
  const [randomKey, setRandomKey] = useState(nanoid(6))
  const [error, setError] = useState(null)
  const [updateDigest] = useUploadDigestMutation({ fixedCacheKey: 'digest-media' })
  const mediaTypeWatch = watch(eFormFields.MediaType)
  const mediaText = useMemo(() => {
    if (mediaTypeWatch === FeaturedMediaType.Image.toString()) return t('labelImage')
    if (mediaTypeWatch === FeaturedMediaType.Audio.toString()) return t('labelAudio')
    if (mediaTypeWatch === FeaturedMediaType.Video.toString()) return t('labelVideo')
    return t('video')
  }, [mediaTypeWatch, t])
  const label: Record<eFormFields, string> = useMemo(() => ({
    [eFormFields.IntroTitle]: t('introTitle'),
    [eFormFields.MainTitle]: t('mainTitle'),
    [eFormFields.MediaType]: t('mediaType'),
    [eFormFields.URL]: t('url', { mediaType: mediaText }),
    [eFormFields.Caption]: t(FeaturedMediaType.Image === mediaTypeWatch ? 'alt' : 'caption'),
    [eFormFields.LinkUrl]: t('linkUrl'),
  }), [mediaText, mediaTypeWatch, t])
  const getRequiredText = useCallback((fieldName: eFormFields) => t('common:isRequired', { fieldName: label[fieldName] }) as string, [label, t])
  const validateLinkUrl = (value) => {
    if (!value) {
      // as Link URL is an optional field, then it accepts empty values
      return true
    }

    const errorMessage =  t('invalidLinkUrl') || 'Invalid Link URL'

    try {
      const url = new URL(value)
      const protocol = `${url.protocol || ''}`.toLowerCase().trim()
      const isValidURL = ((protocol === 'http:') || (protocol === 'https:'))
      return isValidURL || errorMessage
    } catch (error) {
      return errorMessage
    }
  };

  const { errors } = formState;
  // media types for accept attribute
  const MEDIA_TYPES = {
    video: ["video/*"],
    audio: ["audio/*"],
    image: ["image/*"]
  }
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    if (digest) {
      setValue(eFormFields.IntroTitle, digest.sectionName)
      setValue(eFormFields.MainTitle, digest.title)
      setValue(eFormFields.MediaType, digest.contentType.toString())
      setValue(eFormFields.URL, digest.contentLink)
      setValue(eFormFields.Caption, digest.caption)
      setValue(eFormFields.LinkUrl, digest.linkUrl)
      // Setting the value for Media Type doesn't enforce a re-render on the ChoiceGroup for some reason.
      setRandomKey(nanoid(5))
    }
  }, [digest, setValue])

  const handleFormSubmit = handleSubmit((data) => {
    if (error) setError(null)
    setIsLoading(true)
    const formData = new FormData();
    delete data[eFormFields.URL]
    formData.append('sectionName', data[eFormFields.IntroTitle]);
    formData.append('title', data[eFormFields.MainTitle]);
    formData.append('contentType', data[eFormFields.MediaType]);
    formData.append('caption', data[eFormFields.Caption]);
    formData.append('linkUrl', data[eFormFields.LinkUrl]);
    if (data['mediaUpload'].length) {
      formData.append('mediaFile', data['mediaUpload'][0]);
    }

    updateDigest(formData)
      .unwrap()
      .then(() => { dispatch(modalSlice.actions.setModalStatus({ paramName: 'mediaModal', value: false })); setIsLoading(false) })
      .catch((error) => { setError('Failed to update digest.'); setIsLoading(false) })
  }, (error) => {
    console.error(error)
    setError('Please fix all validation errors and try again.')
    setIsLoading(false)
  })

  const handleFileValidation = (file: FileList) => {
    if (mediaText.toLowerCase() === 'video') {
      if (file[0]?.size >= VIDEO_MAX_UPLOAD_SIZE) {
        return `Maximum size of ${formatBytes(VIDEO_MAX_UPLOAD_SIZE)} Exceeded`;
      }
    }
    if (mediaText.toLowerCase() === 'audio') {
      if (file[0]?.size >= AUDIO_MAX_SIZE) {
        return `Maximum size of ${formatBytes(AUDIO_MAX_SIZE)} Exceeded`;
      }
    }
    if (mediaText.toLowerCase() === 'image') {
      if (file[0]?.size >= IMAGE_MAX_SIZE) {
        return `Maximum size of ${formatBytes(IMAGE_MAX_SIZE)} Exceeded`;
      }
    }
    return true;
  };



  return (
    <>
      <ErrorMessage
        showAlert={Boolean(error)}
        setShowAlert={setError}
        message={error}
      />
      <form noValidate ref={ref} onSubmit={handleFormSubmit} className="c-media-form">
        <Stack tokens={stackToken}>
          <Stack.Item>
            <Controller
              control={control}
              name={eFormFields.IntroTitle}
              rules={{ required: getRequiredText(eFormFields.IntroTitle) }}
              render={({ field, fieldState }) => (
                <>
                  <TextField
                    label={label[eFormFields.IntroTitle]}
                    name={eFormFields.IntroTitle}
                    required
                    aria-required={true}
                    invalid={Boolean(fieldState.error?.message)}
                    {...field}
                  />
                  <FieldErrorMessage message={fieldState.error?.message} />
                </>
              )}
            />
          </Stack.Item>
          <Stack.Item>
            <Controller
              control={control}
              name={eFormFields.MainTitle}
              rules={{ required: getRequiredText(eFormFields.MainTitle) }}
              render={({ field, fieldState }) => (
                <>
                  <TextField
                    label={label[eFormFields.MainTitle]}
                    required
                    aria-required={true}
                    invalid={Boolean(fieldState.error?.message)}
                    name={eFormFields.MainTitle}
                    {...field}
                  />
                  <FieldErrorMessage message={fieldState.error?.message} />
                </>
              )}
            />
          </Stack.Item>
          <Stack.Item>
            <Controller
              control={control}
              name={eFormFields.MediaType}
              render={({ field }) => (
                <ChoiceGroup
                  key={randomKey}
                  label={label[eFormFields.MediaType]}
                  required
                  {...field}
                  selectedKey={mediaTypeWatch}
                  onChange={(ev, option) => { field.onChange(option.key) }}
                  options={[
                    { key: FeaturedMediaType.Video.toString(), text: t('video') },
                    { key: FeaturedMediaType.Audio.toString(), text: t('audio') },
                    { key: FeaturedMediaType.Image.toString(), text: t('image') },
                  ]}
                />
              )}
            />
          </Stack.Item>

          <Stack.Item>
            <Label htmlFor="mediaUpload">{label[eFormFields.URL]}</Label>
            <input id="mediaUpload" className="ca-browse-video" type="file"
              accept={MEDIA_TYPES[mediaText.toLowerCase()]?.toString()}
              name="fileupload"
              {...register("mediaUpload", {
                validate: handleFileValidation
              })}
            />
            <FieldErrorMessage message={errors.mediaUpload?.message as string} />
          </Stack.Item>

          <Stack.Item>
            <Controller
              control={control}
              name={eFormFields.Caption}
              rules={{ required: getRequiredText(eFormFields.Caption) }}
              render={({ field, fieldState }) => (
                <>
                  <TextField
                    label={label[eFormFields.Caption]}
                    required
                    aria-required={true}
                    invalid={Boolean(fieldState.error?.message)}
                    name={eFormFields.URL}
                    {...field}
                  />
                  <FieldErrorMessage message={fieldState.error?.message} />
                </>
              )}
            />
          </Stack.Item>

          <Stack.Item>
            <Controller
              control={control}
              name={eFormFields.LinkUrl}
              rules={{ validate: validateLinkUrl }}
              render={({ field, fieldState }) => (
                <>
                  <TextField
                    label={label[eFormFields.LinkUrl]}
                    invalid={Boolean(fieldState.error?.message)}
                    name={eFormFields.LinkUrl}
                    {...field}
                  />
                  <FieldErrorMessage message={fieldState.error?.message} />
                </>
              )}
            />
          </Stack.Item>
        </Stack>
      </form>
      {isLoading && <Loader />}
    </>
  )
})

MediaForm.displayName = 'MediaFormWithForwardRef'

export default MediaForm