import { Chart, ChartConfiguration, ChartOptions, TooltipModel } from 'chart.js'

export const getChartPlugins = (chart: Chart) => {
  const { config } = chart
  const { options } = config || {} as ChartConfiguration
  const { plugins } = options || {} as ChartOptions
  return plugins
}

export const getChartPlugin = (chart: Chart, pluginName: string) => {
  const plugins = getChartPlugins(chart)
  return plugins && plugins[pluginName]
}

export const getRGBColor = (color: [
  r: number,
  g: number,
  b: number
]) => {
  return color.reduce((acc, value) => {
    if (acc === 'rgb(') {
      return acc + value
    }
    return `${acc},${value}`
  }, 'rgb(') + ')'
}

export const getRGBAColor = (color: [
  r: number,
  g: number,
  b: number
], alpha: number) => {
  return color.reduce((acc, value) => {
    if (acc === 'rgba(') {
      return acc + value
    }
    return `${acc},${value}`
  }, 'rgba(') + `,${alpha})`
}

//for darker hex colors
export const darkerHexColor = (hex, percentage) => {
  if(hex) {
    hex = hex.replace(/^#/, '')
    let r = parseInt(hex.substring(0, 2), 16)
    let g = parseInt(hex.substring(2, 4), 16)
    let b = parseInt(hex.substring(4, 6), 16)
    const factor = 1 - percentage / 100
    r = Math.floor(r * factor)
    g = Math.floor(g * factor)
    b = Math.floor(b * factor)
    return `#${(r < 16 ? '0' : '') + r.toString(16)}${(g < 16 ? '0' : '') + g.toString(16)}${(b < 16 ? '0' : '') + b.toString(16)}`
  }
  return hex
}

// for custom tooltips
export const getOrCreateTooltip = (chart: Chart, tooltip: TooltipModel<any>) => {
  let tooltipEl = chart.canvas.parentNode.querySelector('div')

  if (!tooltipEl) {
    tooltipEl = document.createElement('div')
    tooltipEl.style.borderRadius = '3px'
    tooltipEl.style.color = 'white'
    tooltipEl.style.opacity = '1'
    tooltipEl.style.pointerEvents = 'none'
    tooltipEl.style.position = 'absolute'
    tooltipEl.style.transform = 'translate(-50%, 0)'
    tooltipEl.style.transition = 'all .1s ease'
    tooltipEl.style.zIndex = '2147483647'

    const table = document.createElement('table')
    table.style.margin = '0px'

    tooltipEl.appendChild(table)
    chart.canvas.parentNode.appendChild(tooltipEl)
  }

  if (tooltip?.labelColors?.length) {
    tooltipEl.style.background =  darkerHexColor(tooltip?.labelColors[0]?.backgroundColor, 20)
  }

  return tooltipEl
}
