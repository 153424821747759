// You probably don't need to import this directly. Use Table.tsx!
// Used for when FE is handling most functionality of the table.
// Has built in functionality for sorting and searching through the table.

import { useState, useRef, useEffect, useCallback, useMemo, useContext } from 'react'
import isEmpty from 'lodash/isEmpty'
import Section, { SectionProps } from '@/shared/components/Section'
import Card from '@/shared/components/Card'
import Pagination from '@/shared/components/Pagination'
import { SelectionMode, ShimmeredDetailsList } from '@fluentui/react'
import { useHistory } from 'react-router-dom'
import sortBy from 'lodash/sortBy'
import cn from 'classnames'
import ConditionalWrapper from '../../ConditionalWrapper'
import ShimmerRow from './ShimmerRow'
import { DetailsListPropsExtra, ISharedProps } from '../types'
import copyAndSort from '../helpers/copyAndSort'
import usePaginatedTableColumns from '../hooks/usePaginatedTableColumns'
import TableSearchBox from './TableSearchBox'
import detailsListStyles from '../helpers/detailsListStyles'
import TableContext, { ITableContext } from '../context'
import { nanoid } from '@reduxjs/toolkit'

export type Props = DetailsListPropsExtra & ISharedProps & {
  hideSearch?: boolean;
  searchFieldName?: string;
  searchParamName?: string;
  pageParamName?: string;
  sortFieldParamName?: string;
  sortOrderParamName?: string;
  handlePageChange?: (newPage: number) => void;
  handleSearchChange?: (text: string) => void;
}

export function LocalPaginatedTable(props: Props) {
  const { 
    columns: _columns, 
    data,
    hideSearch = true,
    pageSize = 25,
    sectionProps,
    cardProps,
    useCard = false,
    compact = false,
    additionalTableProps = {},
    shimmerIsEnabled = false,
    shimmerLineCount = 12,
    handlePageChange,
    handleSearchChange,
    className: classNameInput
  } = props

  const { setValue, ...tableContext }: ITableContext = useContext(TableContext)
  
  const filterDataByTerm = useCallback((term: string) => { 
    return term 
    ? data.filter(i => (i?.[tableContext.search.key] as string)?.toLowerCase?.()?.includes?.(term)) 
    : data
  }, [data, tableContext.search.key])  

  const [page, setPage] = useState<number>(tableContext.page.value as number)
  const searchTerm = useRef<string>(tableContext.search.value)
  const sortField = tableContext.sortField.value
  const sortOrder = tableContext.sortOrder.value

  const [items, setItems] = useState<Props['data']>([])
  const [columns, settings] = usePaginatedTableColumns(_columns)

  useEffect(() => {
    // anytime data changes we want to make sure our items is up-to-date.
    if (!isEmpty(settings)) {
      const columnKey = Object.keys(settings)[0] // Only one key is ever present
      const filteredData = copyAndSort(
        (!hideSearch && !handleSearchChange ? filterDataByTerm(searchTerm.current) : data) as Array<Record<string, unknown>>, 
        settings[columnKey].fieldName,
        settings[columnKey].isSortedDescending,
        settings[columnKey].sortKey,
        columns?.find(c => c.key === columnKey)?.getValue,
      )
      setItems(filteredData)
    }
    else {
      const filteredData = !hideSearch && searchTerm.current && !handleSearchChange
        ? filterDataByTerm(searchTerm.current)
        : data
      const sortedData = sortField ? sortBy(filteredData, [sortField], [sortOrder]) : filteredData
      setItems(sortedData)
    }
  }, [data, filterDataByTerm, hideSearch, sortField, sortOrder, settings, handleSearchChange, columns])

  const onPageChange = useCallback((newPage) => {
    setPage(newPage)
    if (handlePageChange) handlePageChange(newPage)
  }, [handlePageChange])

  const mergedSectionProps: SectionProps = useMemo(() => {
    const { 
      actions = [], 
      className, 
      ..._sectionProps 
    } = (sectionProps || {})

    const _sectionActions = [...actions]
    return {
      ..._sectionProps,
      className: cn('c-table', className, classNameInput),
      actions: hideSearch ? _sectionActions : _sectionActions.concat([
        <TableSearchBox key="table-box" />
      ])
    }
  }, [sectionProps, hideSearch])

  return (
    <Section {...mergedSectionProps}>
      <ConditionalWrapper
        condition={useCard}
        wrapper={(children) => <Card {...cardProps}>{children}</Card>}
      >
        <>
          <ShimmeredDetailsList 
            compact={compact}
            columns={columns} 
            items={items.slice((page - 1) * pageSize, pageSize * page)}
            selectionMode={SelectionMode.none}
            styles={detailsListStyles}
            onRenderCustomPlaceholder={ShimmerRow}
            enableShimmer={shimmerIsEnabled}
            shimmerLines={shimmerLineCount}
            removeFadingOverlay
            {...additionalTableProps}
          />

          <footer className="c-table__footer">
            <Pagination 
              maxShown={pageSize}
              listCount={data?.length || 0}
              onPageChange={onPageChange}
            />
          </footer>
        </>
      </ConditionalWrapper>
    </Section>
  )
}

const ContextWrappedLocalPaginatedTable = (props: Props) => {
  const history = useHistory()
  const queryParams = new URLSearchParams(history.location.search)
  const id = nanoid(4)

  const {
    searchParamName,
    pageParamName,
    sortFieldParamName,
    sortOrderParamName
  } = props

  const [contextValue, setContextValue] = useState<ITableContext>({ 
    id, 
    search: { key: searchParamName || 'search', value: queryParams.get(searchParamName || 'search') || '' },
    page: { key: pageParamName || 'page', value: Number(queryParams.get(pageParamName || 'page')) || 1 },
    sortField: { key: sortFieldParamName || 'sf', value: queryParams.get(sortFieldParamName || 'sf') ?? '' },
    sortOrder: { key: sortOrderParamName || 'so', value: queryParams.get(sortOrderParamName || 'so') ?? '' }
  })

  return (
    <TableContext.Provider value={{ ...contextValue, setValue: setContextValue }}>
      <LocalPaginatedTable {...props} />
    </TableContext.Provider>
  )
}

export default ContextWrappedLocalPaginatedTable