import { useGetAllCAOfficesQuery } from '@/shared/api/services/caOffficeService'
import { LocationCard } from '@/shared/components'

const Locations = () => {
  const { data: locations = [] } = useGetAllCAOfficesQuery() 
  return (
    <section className='c-support-locations'>
      <h2 className='c-support-locations__header'>Our Locations</h2>
      <p className='c-support-locations__body'>Headquartered in Boston, Massachusetts, we have six regional offices in North America that are dedicated to serving a variety of distinguished institutions and families across the US and Canada.</p>
      <div className='c-support-locations__container'>
        {locations.map((location) => (
          <div className='c-support-locations__container__wrapper' key={location.city}>
            <LocationCard {...location} />
          </div>
        ))}
      </div>
    </section>
  )
}

export default Locations