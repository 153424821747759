import dayjs from '@/bootstrap/dayjs'
import { EDateFormat } from '../types/date'

const getDMY = (date: Date) => `${date.getFullYear()}${date.getMonth()}${date.getDate()}`

/**
 * Format 2 dates into a hyphenated range using dayjs. If there is no end date or the two
 * dates are the same calendar date, no hyphenated concatenation is returned.
 * 
 * @param format must contain a comma since this is the insertion point for the Date range
 * 
 * @example August 14-15, 2023
 */
export default function formatDateRange(format: EDateFormat, start: Date, end?: Date) {
  const formattedDT = dayjs(start).format(format)

  if (end && getDMY(start) !== getDMY(end)) {
    const formattedEndDT = dayjs(end).format(format)
    const commaIndex = (formattedDT.indexOf(',') ?? 0) || 0

    if (format === EDateFormat['long-tz']) {
      return `${formattedDT.slice(0, commaIndex)}-${dayjs(end).format('dddd')}${formattedDT.slice(commaIndex)}`
    }

    if ((start.getFullYear() !== end.getFullYear()) || (start.getMonth() !== end.getMonth())) {
      return `${formattedDT} - ${formattedEndDT}`
    }

    return `${formattedDT.slice(0, commaIndex)}-${end.getDate()}${formattedDT.slice(commaIndex)}`
  }

  return formattedDT
}