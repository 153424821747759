import { forwardRef, memo, ReactNode, Ref } from 'react'
import cn from 'classnames'

export interface Props {
  title?: string;
  subtitle?: string;
  children?: ReactNode;
  actions?: Array<ReactNode>;
  noHeadingWrapMobile?: boolean;
  titleId?: string;
  subtitleId?: string;
  className?: string;
}

function Section(props: Props, ref: Ref<HTMLElement>) {
  const { title, subtitle, titleId, subtitleId, actions = [], children, className, noHeadingWrapMobile = false } = props

  return (
    <section ref={ref} className={cn('c-section', className)}>
      {title || actions.length ? (
        <header className={cn('c-section__header', { '--no-wrap-mobile': noHeadingWrapMobile })}>
          <div className="c-section__header-title-wrapper">
            { title ? <h2 className="c-section__header-title" id={titleId}>{title}</h2> : null }
            { subtitle ? <h3 className="c-section__header-subtitle" id={subtitleId}>{subtitle}</h3> : null }
          </div>
          {
            actions.length ? (
              <div className="c-section__header-actions">
                {actions}
              </div>
            ) : null
          }
        </header>
      ) : null}
      <div className="c-section__content">
        {children}
      </div>
    </section>
  )
}

export default memo(forwardRef<HTMLElement, Props>(Section))
