import { NavMenuItem } from '../types'

function hasEmptyNest(item: NavMenuItem): boolean {
  const hasChildren = Boolean(item.itemIds)
  if (hasChildren && item.itemIds.length === 0) return true
  return false
}

export function filterEmptyNest(item: NavMenuItem): boolean {
  const empty = hasEmptyNest(item)
  return !empty
}

export function disableEmptyNest(item: NavMenuItem): NavMenuItem {
  if (hasEmptyNest(item)) {
    item.disabled = true
  }
  return item
}