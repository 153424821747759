import { useRef, useState } from 'react'
import { ITextField, PrimaryButton, TextField } from '@fluentui/react'
import { useHistory } from 'react-router'

type SearchProps = {
  handleSearch: (query : string) => void
}

function Search({ handleSearch } : SearchProps) {
  const [value, setValue] = useState(new URLSearchParams(window.location.search).get('s') || '')
  const search = e => {
    e.preventDefault()
    handleSearch(query?.current?.value)
  }
  const updateValue = e => {
    setValue(e.target.value)
  }
  const history = useHistory()
  history.listen((search, action) => {
    if (action === 'PUSH' && search.search === '') setValue('')
  })
  const query = useRef<ITextField>()
  const iconProps = { iconName: 'Search' }

  return (
    <form className='c-search' onSubmit={search}>
      <TextField
        ariaLabel='Search Insights'
        className='c-search__input'
        type='text'
        componentRef={(input) => { query.current = input }}
        value={value}
        onChange={updateValue}
        iconProps={iconProps}
        placeholder='Search Insights'
      />
      <PrimaryButton text='Search' className='c-search__submit' type='Submit' />
    </form>
  )
}

export default Search