import React from 'react'
import ReactDOM from 'react-dom'
import './shared/styles/reset.scss'
import App from './App'
import * as serviceWorkerRegistration from './serviceWorkerRegistration'
import reportWebVitals from './reportWebVitals'
import { initializeIcons } from '@fluentui/font-icons-mdl2'
import './index.css'
import { MsalProvider } from '@azure/msal-react'
import { msalInstance } from './bootstrap/msal'
import { FontAwesomeProvider } from './bootstrap/fontAwesome/FontAwesomeProvider'
import AgGridProvider from './lib/common/components/grid/AgGridProvider'
import { AG_GRID_LICENSE } from './environment/environment.constants'

initializeIcons()

ReactDOM.render(
  <AgGridProvider base64License={AG_GRID_LICENSE}>
    <FontAwesomeProvider>
      <MsalProvider instance={msalInstance}>
        <React.StrictMode>
          <App />
        </React.StrictMode>
      </MsalProvider>
    </FontAwesomeProvider>
  </AgGridProvider>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister()

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
