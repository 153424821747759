import { eventImages, LocalEventImage } from '../../../../../constants/eventImages'
import './EventImageSelection.scss'
import cn from 'classnames'
import { getImageSrc } from '@/features/events/helpers/getImageSrc'
import FileUpload from '@/shared/components/FileUpload'

type EventImageSelectionProps = {
  handleSelection: (file: any, isCustomImgSelected: boolean) => void
  selection: LocalEventImage['name']
}

export default function EventImageSelection(props: EventImageSelectionProps) {
  const { selection, handleSelection } = props

  const handleUpload = (file: any) => {
    handleSelection(file, !!file.type);
  }

  return (
    <div className='c-event-image-selection__container'>
      <h4 id="image-selection-label" className="c-event-image-selection__label">Choose an Image</h4>
      <div className="c-event-image-selection">
        {eventImages.map((eventImage) => (
          <div aria-labelledby='image-selection-label' key={eventImage.id}>
            <img 
              alt={eventImage.alt} 
              src={getImageSrc(eventImage.name)}
              className={cn('c-event-image-selection__img', { '--selected': selection === eventImage.name })}
              tabIndex={0}
              onKeyUp={(e) => { if (e.keyCode === 13) { handleUpload(eventImage)} }}
              onClick={() => handleUpload(eventImage)}
            />
          </div>
        ))}
      </div>
      <h4 id="image-selection-label" className="c-event-image-selection__label">Choose Custom Image</h4>
      <FileUpload handleUpload={handleUpload} accept="image/*"/>
    </div>
  )
}