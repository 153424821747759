import { mainService } from '.'

const rssServices = mainService.injectEndpoints({
  endpoints(build) {
    return {
      getUnseenUpside: build.query<string, void>({
        query: () => ({
          url: '/rssproxy',
          method: 'GET',
          headers: {
            'Content-Type': 'text/plain'
          }
        })
      })
    }
  }
})

export const { useGetUnseenUpsideQuery } = rssServices