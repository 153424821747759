import ActiveMessages from "./components/ActiveMessages"
import ArchivedMessages from "./components/ArchivedMessages"

type Props = {
  accountid: string
}

const MessagesTable = ({ accountid } : Props ) => {
  return(
    <>
      <ActiveMessages accountid={accountid} />
      <ArchivedMessages accountid={accountid} />
    </>
  )
}

export default MessagesTable
