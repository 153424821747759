import * as Screens from '@/screens'
import { RouteComponentProps } from "react-router-dom"

const EditableOnboardingDashboard = (props: RouteComponentProps<{ accountId: string }>) => {
  const { match } = props
  const { accountId } = match.params
  return (
    <Screens.Onboarding accountId={accountId} isEditable={true} />
  )
}

export default EditableOnboardingDashboard