import TextSection, { Section } from '@/features/profile/components/TextSection'
import { GeneralError, HelpPrompt, MainLayout, SplitLayout } from '@/shared/components'
import { Stack } from '@fluentui/react'
import { useGetProfileQuery } from '@/shared/api/services/profileServices'
import { useMemo } from 'react'
import ProfileAvatar from '@/features/profile/components/ProfileAvatar'
import { useSelector } from 'react-redux'
import { selectAccountId } from '@/features/auth/redux/authSelectors'

const Profile = () => {
  const reduxAccountId = useSelector(selectAccountId);
  const { data, isError, isUninitialized, isLoading, refetch } = useGetProfileQuery({ accountId: reduxAccountId })
  const textSections: Array<Section> = useMemo(() => { 
    if (!data) return []
    const sections = [
      { label: 'First Name', value: data.firstName },
      { label: 'Last Name', value: data.lastName },
      { label: 'Account', value: data.clientName },
      { label: 'Email', value: data.email },
      { label: 'Phone', value: data.phoneNumber }
    ]
    // Only return sections that have a value
    return sections.filter((section) => section.value)
   }, [data])

  return (
    <MainLayout>
      <SplitLayout 
        className='c-profile'
        renderHeader={false}
        leftContent={
          <Stack className='c-profile__content__container__left'>
            <h2 className='c-profile__header'>My Profile</h2>
            {
              isError
              ? (
                <GeneralError onClick={refetch} title='Failed to get profile info' />
              )
              : (
                <>
                  <ProfileAvatar />
                  <TextSection 
                    isLoading={isUninitialized || isLoading}
                    title='Name & contact information' 
                    sections={textSections}
                  />
                </>
              )
            }
          </Stack>
          }
        rightContent={
          <Stack className='c-profile__content__container__right'>
            {/* <Stack.Item>
              <TeamCard />
            </Stack.Item> */}
            {/* <OfficeCard /> */}
            <Stack.Item>
              <HelpPrompt />
            </Stack.Item>
          </Stack>
          }
      />
    </MainLayout>
  )
}

export default Profile