import { IInsightTag } from "../../types";

type Props = {
  tags: string[]
  classNames: string
  setTags?: (tags: IInsightTag) => void
  style?: { cursor: string }
}

const InsightsTags = ({ tags, classNames, setTags, style }: Props) => {
  const handleClick = (e, tag: IInsightTag) => {
    e.preventDefault()
    setTags(tag)
  }
  const len: number = Object.keys(tags).length - 1;
  return tags && <p className={`insight-tags ${classNames}`}>{Object.entries(tags).map(([key, value], k) => { return (<><span key={key} id={key} style={style} onClick={(e) => handleClick(e, { id: key, name: value })}>{value}</span>{(k != len) && ", "}</>) })}</p>
}

export default InsightsTags
