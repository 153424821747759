export type LocalEventImage = {
  id: number;
  alt: string;
  name: string;
  size?: number;
}

export const eventImagesGroup: Record<string, LocalEventImage> = {
  '1': { id: 1, alt: 'river', name: 'event-image-1.jpg' },
  '2': { id: 2, alt: 'mountain lake', name: 'event-image-2.jpg' },
  '3': { id: 3, alt: 'close up on a leaf', name: 'event-image-3.jpg' },
  '4': { id: 4, alt: 'colorful rolls of fabric', name: 'event-image-4.jpg' },
  '5': { id: 5, alt: 'solar panels in water', name: 'event-image-5.jpg' },
  '6': { id: 6, alt: 'colorful glowing tents at night', name: 'event-image-6.jpg' },
  '7': { id: 7, alt: 'decorative ceiling', name: 'event-image-7.jpg' },
  '8': { id: 8, alt: 'rocks and sky', name: 'event-image-8.jpg' },
  '9': { id: 9, alt: 'trees and grass', name: 'event-image-9.jpg' },
  '10': { id: 10, alt: 'bridge to a beach at sunrise', name: 'event-image-10.jpg' },
}

export const eventImages: Array<LocalEventImage> = Object.values(eventImagesGroup)