import { Chart } from 'chart.js'
import { getChartPlugin } from '../../utils/ChartJS.helper'
import { GroupBarChartFontOptions, GroupBarChartValueFormater } from '../GroupBarChart'

export interface GroupBarChartBarValuePluginOptions extends GroupBarChartFontOptions, GroupBarChartValueFormater{}

export const GroupBarChartBarValuePlugin = {
  id: 'GroupBarChartBarValuePlugin',
  afterDraw: (chart: Chart, args, options) => {
    const plugin = getChartPlugin(chart, 'GroupBarChartBarValuePlugin') as GroupBarChartBarValuePluginOptions

    if (plugin) {
      const chartInstance = chart
      const ctx = chartInstance.ctx
      const data = chart.config.data

      ctx.textAlign = 'center'
      ctx.textBaseline = 'bottom'

      data.datasets.forEach((dataset, i) => {
        const meta = chartInstance.getDatasetMeta(i)

        if (!meta.hidden) {
          const hasOnlyNegativeValues = meta.data.every((dt, index) => {
            const value = dataset.data[index] as number
            return !value || value <= 0
          })
          meta.data.forEach((bar, index) => {
            const value = plugin.getFormattedValue(dataset.data[index] as number, dataset)
            const barElement = bar as any
            const { font } = plugin
            if (font) {
              ctx.font = `${font.size} ${font.family}`
              if (font.color) {
                ctx.fillStyle = font.color
              }
            }
            // adds bar value label to the bar
            if (value) {
              if (hasOnlyNegativeValues) {
                ctx.fillText(`${value < 0 ? '(' : ''}${value}${value < 0 ? ')' : ''}`.replace('-', ''), bar.x, (value > 0) ? bar.y - 5 : ((bar.y + barElement.height) / 2) + 25)
              } else {
                ctx.fillText(`${value < 0 ? '(' : ''}${value}${value < 0 ? ')' : ''}`.replace('-', ''), bar.x, (value > 0) ? bar.y - 5 : bar.y + 20 )
              }
            }
          })
        }
      })
    }
  },
}
