import { memo } from 'react'
import cn from 'classnames'
import Table, { TableProps } from '@/shared/components/Table'
import ButtonModal, { ButtonModalProps } from './components/ButtonModal'
import { nanoid } from '@reduxjs/toolkit'

export interface PostModalProps extends Omit<ButtonModalProps, 'openButtonAriaLabel' | 'closeAriaLabel' | 'children' | 'onPrimary'> {
  tableProps?: TableProps
  shimmerIsEnabled?: boolean
  ariaLabels: {
    openButton: string,
    closeButton: string
  }
  onConfirmClick: () => void,
  body?: JSX.Element | JSX.Element[]
  description?: JSX.Element | JSX.Element[]
  footer?: JSX.Element | JSX.Element[]
  className?: string;
  modalClassName?: string;
  openButtonId?: string;
}

const PostModal = ({ 
  openButtonLabel, 
  ariaLabels, 
  modalTitle, 
  tableProps, 
  onConfirmClick, 
  onClose, 
  onOpen, 
  primaryLabel, 
  shimmerIsEnabled = false, 
  description, 
  body,
  footer, 
  className, 
  paramName,
  primaryIsDisabled,
  modalClassName,
  openButtonId,
}: PostModalProps) => {
  const primaryButtonId = nanoid()

  return (
    <ButtonModal 
      onOpen={onOpen}
      onClose={onClose}
      onPrimary={onConfirmClick} 
      primaryLabel={primaryLabel}
      openButtonLabel={openButtonLabel}
      openButtonId={openButtonId}
      modalTitle={modalTitle}
      openButtonAriaLabel={ariaLabels.openButton}
      closeAriaLabel={ariaLabels.closeButton}
      paramName={paramName}
      primaryIsDisabled={primaryIsDisabled}
      primaryButtonId={primaryButtonId}
      modalClassName={modalClassName}
    >
      <div className={cn('c-post-modal-body', className)}>
        {description}
        {tableProps ? <div className="c-post-modal-table" data-is-scrollable>
          <Table
            compact={true}
            columns={tableProps.columns}
            data={tableProps.data}
            shimmerIsEnabled={shimmerIsEnabled}
            sectionProps={tableProps.sectionProps}
            dragDropEvents={tableProps.dragDropEvents}
            useCard
            cardProps={{ ariaLabelledBy: primaryButtonId }}
          />
          {body}
        </div> : null}
        {footer}
      </div>
    </ButtonModal>
  )
}

export default memo(PostModal)