import { ReactElement } from 'react'
import dayjs from '@/bootstrap/dayjs'

export default function DateCell(message: {
  createddatetime?: string
  createdAt?: string
}): ReactElement {
  const { createddatetime, createdAt } = message
  const createdOn = createddatetime || createdAt
  const formattedDate = dayjs(createdOn).format('L')
  return (
    <time dateTime={formattedDate}>{formattedDate}</time>
  )
}
