import { useState } from 'react'
import { nanoid } from '@reduxjs/toolkit'
import Insight, { SkeletonInsight } from '@/features/insight/components/Insight'
import { ConditionalWrapper, GeneralError, Pagination, Section } from '@/shared/components'
import ManageInsightModal from '../ManageInsightModal'
import useInsightList from '../../hooks/useInsightList'
import { useGetInsightArticleDataQuery } from '@/shared/api/services/insightService'
import { IInsightsPostViewWithAlt, IInsightTag } from '../../shared/types'
import ResultsHeader from '../ResultsHeader'
interface Props {
  isDashboard: boolean
  search?: string
  setTags?: (tags: IInsightTag) => void,
  tags?: string,
  deleteTag?: (tagId) => void
}

export default function InsightList({ isDashboard, search = '', setTags, tags, deleteTag }: Props) {
  const [pageNumber, setPageNumber] = useState(Number(new URLSearchParams(window.location.search).get('page')) || 1)
  const artcleDataQuery = useGetInsightArticleDataQuery()
  const insightTags = ((tags && JSON.parse(tags)) || []).map(e => e.id);
  const { data, isError, isUninitialized, isLoading, refetch } = useInsightList(isDashboard, pageNumber, search, insightTags)

  const handlePageChange = (newPagenumber: number) => { setPageNumber(newPagenumber) }
  const inProgress = isUninitialized || isLoading

  const updateTags = (tags: IInsightTag) => {
    setTags(tags);
  }

  const deleteTagFromQuery = (tagId) => {
    deleteTag(tagId)
  }

  const renderContent = () => {
    if (inProgress || artcleDataQuery.isLoading) {
      const pageSize = isDashboard ? 5 : 10
      const skeletons: Array<JSX.Element> = []
      for (let i = 0; i < pageSize; i++) {
        const uniqueId = nanoid(4)
        skeletons.push(<SkeletonInsight key={uniqueId} />)
      }
      return skeletons
    }
    return (data.items as Array<IInsightsPostViewWithAlt>)
      .filter((insight) => isDashboard ? true : artcleDataQuery?.data?.featuredInsight?.id !== insight.id)
      .map((insight) => (
        <li key={insight.id}>
          <Insight {...insight} setTags={updateTags} />
        </li>
      ))
  }

  if (isError || artcleDataQuery.isError) return <GeneralError onClick={refetch} title='The list of insights could not be loaded' />

  return (
    <ConditionalWrapper
      condition={isDashboard}
      wrapper={(children) => (
        <Section title='Insights' actions={!inProgress ? [<ManageInsightModal key="manage-insight-modal" />] : []}>
          {children}
        </Section>
      )}
    >
      <>
        {search !== '' || insightTags.length ? <ResultsHeader searchQuery={search} searchCount={data?.count} tagsQuery={tags} deleteTag={deleteTagFromQuery}/> : null}
        <ol aria-live='polite' aria-busy={inProgress} className="c-insight-list">
          {renderContent()}
        </ol>
        {!isDashboard && !inProgress && data?.count !== 0
          ? <Pagination maxShown={10} listCount={data.count} paramName='page' className='c-insight-list__pagination' onPageChange={handlePageChange} />
          : null
        }
      </>
    </ConditionalWrapper>
  )
}
