import React from 'react'
import { useSelector } from 'react-redux'
import { selectPermissions } from '../redux/authSelectors'
import { Permission } from '../types'

export type WithPermissionProps = Record<string, unknown>

/**
 * HOC for restricting rendering of components based on Permissions
 * 
 * @example
 *  withPermission(EUserCan.AccessEvents)(Events)
 */
const withPermission = (permission: Permission) =>
  <P extends WithPermissionProps>(Component: React.ComponentType<P>) => (
    function ShouldRender(props: P) {
      const userPermissions: Array<Permission> = useSelector(selectPermissions) 

      if (!userPermissions.includes(permission)) {
        return null
      }
      
      return <Component {...props} />
    }
  )

export default withPermission