import { selectIsALaCarte } from '@/features/auth/redux/authSelectors'
import { useGetDisplayRulesQuery } from '@/shared/api/services/legacyService'
import { InstType } from '@/shared/types/Claims'
import { ELinks, ILinkContentWithLink, ISurveyConfig } from '@/shared/types/Links'
import { useCallback } from 'react'
import { useSelector } from 'react-redux'
import { ExternalAppsLayoutKey, InstTypeSurveyKeyMap, linkLayout } from '../constants/linkContent'
import moreLinksDisplayMap from '../constants/moreLinksDisplay'
import mapLinkKeyToContent from '../helpers/mapLinkKeyToContent'
import useSurveyConfig from './useSurveyConfig'

type GroupKey = string
type LinkGroupsWithContent = [GroupKey, Array<ILinkContentWithLink>][]
type ExtAppFetchingMeta = { 
  isLoading: boolean;
  isError: boolean;
  isSuccess: boolean;
  hasLinks: boolean;
  refetch: () => void;
}

/**
 * Checks against the users permissions (display rules) for all external applications.
 * For surveys the survey config file is checked as well. The survey config controls 
 * whether annual surveys or the quarterly survey should display, overriding permissions.
 * A La Carte users have some additional restrictions.
 * 
 * Once permissions are determined this hook maps the accessible external apps with content
 * and urls in their respective categories (see ExternalAppsLayout)
 */
export default function useExtApps(): [LinkGroupsWithContent, ExtAppFetchingMeta] {
  const { data, isLoading, isError, isSuccess, isFetching, refetch } = useGetDisplayRulesQuery()
  const surveyConfig: ISurveyConfig = useSurveyConfig()
  const isALaCarte = useSelector(selectIsALaCarte)

  const filterLinks = useCallback((linkId: ELinks): boolean => {
    if (isLoading || isError || !data) return false

    // These external applications are never allow for a la carte users
    if (isALaCarte) {
      if (linkId === ELinks.PRSnapshot) return false
      if (linkId === ELinks.IndexSnapshots) return false
      if (linkId === ELinks.ExhibitFinder) return false
      if (linkId === ELinks.AnnualInvestmentPRSurvey) return false
    }

    // IPRQS (quarterly survey) display override via surveyconfig
    if (linkId === ELinks.InvestmentPRQuarterlySurvey && !surveyConfig.iprqs) return false

    // only optica links are not mapped to display rules. These are
    // not included in linkLayout so this only serves as a precaution
    // should anyone add them. This can potentially be removed.
    const isOpticaLink = !(linkId in moreLinksDisplayMap)
    if (isOpticaLink) return true
    
    const displayKey = moreLinksDisplayMap[linkId]
    // Annual surveys -> linkId === ELinks.AnnualInvestmentPRSurvey
    // there are many types of annual surveys which must be checked against
    // the user's permissions (DisplayRules -> clientInstitutionTypes) and
    // surveyconfig (all values except iprqs)
    if (displayKey === 'clientInstitutionTypes') {
      const instTypes = data[displayKey] ?? [] as Array<InstType>
      if (!instTypes) return false
      return instTypes.some((instType) => surveyConfig[InstTypeSurveyKeyMap[instType]]) 
    }

    // not a survey
    return data[displayKey]
  }, [data, isALaCarte, isError, isLoading, surveyConfig])

  const links: LinkGroupsWithContent = Object.entries(linkLayout).map(([key, linkKeys]: [ExternalAppsLayoutKey, Array<ELinks>], i) => {
    return [key, linkKeys.filter(filterLinks).map(mapLinkKeyToContent)]
  })

  const hasLinks = links.some(([_, links]) => Boolean(links.length))

  return [links, { hasLinks, isLoading: isLoading || isFetching, isError, isSuccess, refetch }]
}