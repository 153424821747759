export default function LogoFull(props: React.SVGAttributes<SVGElement>) {
  return (
    <svg viewBox="0 0 190.5 81.7" xmlSpace="preserve" fill="#0C3980" x={0} y={0} {...props}>
      <title>Access CA logo</title>
      <rect x="124.8" y="16" fill="#A7A9AC" width="49.7" height="49.7" />
      <g>
        <path
          fill="#FFFFFF"
          d="M133.7,46.6c-1.9-1.7-2.4-4-2.4-5.9c0-1.4,0.4-3.7,2.3-5.5c1.4-1.3,3.2-2.1,6.1-2.1c1.2,0,1.9,0.1,2.8,0.2
        c0.7,0.1,1.4,0.3,1.9,0.3c0.2,0,0.3,0.1,0.3,0.2c0,0.2-0.1,0.4-0.1,1.1c0,0.7,0,1.8-0.1,2.2c0,0.3-0.1,0.5-0.2,0.5
        c-0.2,0-0.2-0.2-0.2-0.4c0-0.6-0.3-1.3-0.8-1.8c-0.6-0.7-2.1-1.2-3.9-1.2c-1.7,0-2.8,0.4-3.6,1.2c-1.4,1.3-1.8,3.3-1.8,5.2
        c0,4.8,3.5,7.1,6.1,7.1c1.7,0,2.8-0.2,3.5-1.1c0.3-0.4,0.6-1,0.7-1.3c0.1-0.3,0.1-0.4,0.3-0.4c0.1,0,0.2,0.1,0.2,0.3
        c0,0.2-0.2,2-0.4,2.7c-0.1,0.3-0.2,0.4-0.5,0.6c-0.8,0.3-2.2,0.5-3.5,0.5C137.7,48.8,135.5,48.2,133.7,46.6"
        />
        <path
          fill="#FFFFFF"
          d="M162.3,42.3c0.1,0,0.1-0.1,0.1-0.1l-1.8-5c0-0.1,0-0.2-0.1-0.2c-0.1,0-0.1,0.1-0.1,0.2l-1.8,4.9
        c0,0.1,0,0.1,0.1,0.1H162.3z M158.2,43.3c-0.1,0-0.1,0-0.2,0.2l-1.1,2.9c-0.2,0.5-0.3,1-0.3,1.2c0,0.4,0.2,0.6,0.8,0.6h0.3
        c0.2,0,0.3,0,0.3,0.2c0,0.2-0.1,0.2-0.3,0.2c-0.6,0-1.5-0.1-2.1-0.1c-0.2,0-1.3,0.1-2.4,0.1c-0.3,0-0.4,0-0.4-0.2
        c0-0.1,0.1-0.2,0.2-0.2c0.2,0,0.5,0,0.6,0c0.9-0.1,1.3-0.8,1.7-1.8l5-12.6c0.2-0.6,0.4-0.8,0.6-0.8c0.2,0,0.3,0.2,0.5,0.6
        c0.5,1.1,3.7,9.3,5,12.4c0.8,1.8,1.3,2.1,1.8,2.2c0.3,0.1,0.6,0.1,0.8,0.1c0.2,0,0.3,0,0.3,0.2c0,0.2-0.2,0.2-0.9,0.2
        c-0.7,0-2.2,0-3.8,0c-0.4,0-0.6,0-0.6-0.2c0-0.1,0.1-0.2,0.3-0.2c0.1,0,0.3-0.2,0.2-0.5l-1.6-4.3c0-0.1-0.1-0.1-0.2-0.1H158.2z"
        />
      </g>
      <line
        fill="none"
        stroke="#0C3980"
        strokeWidth="0.824"
        strokeMiterlimit="10"
        x1="149.6"
        y1="25.3"
        x2="149.6"
        y2="56.4"
      />
      <g>
        <path d="M29,48.9l-1.4-3.6h-8l-1.4,3.6h-2.3l6.5-16h2.5l6.4,16H29z M23.6,34.9l-3.4,8.6H27L23.6,34.9z" />
        <path
          d="M34.4,40.9c0-4.9,3.6-8.3,8.2-8.3c2.8,0,4.8,1.3,6.1,3.2L47,36.7c-0.9-1.4-2.6-2.3-4.4-2.3
        c-3.5,0-6.2,2.7-6.2,6.5c0,3.8,2.7,6.5,6.2,6.5c1.8,0,3.5-1,4.4-2.3l1.7,0.9c-1.3,1.8-3.3,3.2-6.1,3.2C38,49.1,34.4,45.8,34.4,40.9
        z"
        />
        <path
          d="M52.5,40.9c0-4.9,3.6-8.3,8.2-8.3c2.8,0,4.8,1.3,6.1,3.2l-1.7,0.9c-0.9-1.4-2.6-2.3-4.4-2.3
        c-3.5,0-6.2,2.7-6.2,6.5c0,3.8,2.7,6.5,6.2,6.5c1.8,0,3.5-1,4.4-2.3l1.7,0.9c-1.3,1.8-3.3,3.2-6.1,3.2
        C56.2,49.1,52.5,45.8,52.5,40.9z"
        />
        <path d="M71.8,48.9v-16h10.5v1.8h-8.5v5.2h8.3v1.8h-8.3v5.5h8.5v1.8H71.8z" />
        <path
          d="M86.8,46.6l1.2-1.5c1,1.2,2.8,2.3,5,2.3c2.9,0,3.8-1.5,3.8-2.8c0-4.1-9.5-1.8-9.5-7.5c0-2.6,2.4-4.5,5.5-4.5
        c2.4,0,4.3,0.8,5.7,2.3l-1.2,1.5c-1.2-1.3-2.9-1.9-4.6-1.9c-1.9,0-3.3,1-3.3,2.5c0,3.6,9.5,1.5,9.5,7.5c0,2.3-1.6,4.7-5.9,4.7
        C90.2,49.1,88.1,48.1,86.8,46.6z"
        />
        <path
          d="M103.2,46.6l1.2-1.5c1,1.2,2.8,2.3,5,2.3c2.9,0,3.8-1.5,3.8-2.8c0-4.1-9.5-1.8-9.5-7.5c0-2.6,2.4-4.5,5.5-4.5
        c2.4,0,4.3,0.8,5.7,2.3l-1.2,1.5c-1.2-1.3-2.9-1.9-4.6-1.9c-1.9,0-3.3,1-3.3,2.5c0,3.6,9.5,1.5,9.5,7.5c0,2.3-1.6,4.7-5.9,4.7
        C106.6,49.1,104.5,48.1,103.2,46.6z"
        />
      </g>
      <line
        fill="none"
        stroke="#A7A9AC"
        strokeWidth="0.824"
        strokeMiterlimit="10"
        x1="16"
        y1="16.4"
        x2="116.4"
        y2="16.4"
      />
      <line
        fill="none"
        stroke="#A7A9AC"
        strokeWidth="0.824"
        strokeMiterlimit="10"
        x1="16"
        y1="65.3"
        x2="116.4"
        y2="65.3"
      />
    </svg>
  )
}
