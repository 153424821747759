import { DefaultButton, IIconProps, Modal, Stack, Toggle } from '@fluentui/react'
import { useBoolean } from '@fluentui/react-hooks'
import { Radio, RadioGroup } from '@fluentui/react-radio'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import './PanelOptions.scss'

export interface IPanelOptionsProps {
  isExpanded: boolean
  onChangeExpand: (isExpanded: boolean) => void
  isBenchmarkValueAddedFiltered: boolean
  onChangeBenchValueAddedFilter: (isBenchmarkValueAddedFiltered: boolean) => void
}

const PanelOptions = ({
  isExpanded,
  isBenchmarkValueAddedFiltered,
  onChangeExpand: onChangeExpand,
  onChangeBenchValueAddedFilter: onChangeBenchValueAddedFilter
}: IPanelOptionsProps) => {
  const [isModalOpen, { setTrue: showModal, setFalse: hideModal }] = useBoolean(false)
  const cancelIcon: IIconProps = { iconName: 'Cancel' }
  const exportIcon: IIconProps = { iconName: 'FileSymLink' }

  return (
    <div className='PanelOptions'>
      <DefaultButton className='panel-options-button' onClick={showModal}>
        <FontAwesomeIcon className='icons-mb' icon={['fas','ellipsis']} />
      </DefaultButton>
      <Modal
        titleAriaId='Settings'
        isOpen={isModalOpen}
        onDismiss={hideModal}
        isBlocking={false}
        className='ModalSettings'
      >
        <div className='ms-container'>
          <div className='ms-header'>
            <span></span>
            <span className='title-header'>Settings</span>
            <FontAwesomeIcon className='icons-mb' icon={['fas','times']} onClick={hideModal} />
          </div>
          <div className='ms-body'>
            <RadioGroup defaultValue={`${isExpanded ? 'expand' : 'collapse'}`} onChange={(e) => onChangeExpand(!isExpanded)}>
              <div className='option'>
                <span className='option-label'>Expand All</span>
                <Radio value='expand' label='' />
              </div>
              <div className='option'>
                <span className='option-label'>Collapse All</span>
                <Radio value='collapse' label='' />
              </div>
            </RadioGroup>
            <div className='option-benchmark'>
              <span className='option-label'>Benchmark & Value Add</span>
              <Stack>
                <Toggle label='' inlineLabel checked={isBenchmarkValueAddedFiltered} onChange={() => onChangeBenchValueAddedFilter(!isBenchmarkValueAddedFiltered)} />
              </Stack>
            </div>
            <div className='option-export'>
              <span className='option-label'>
                <FontAwesomeIcon className='icons-mb-file-export' icon={['fas','file-export']} onClick={hideModal} />
                Export PDF to Files
              </span>
            </div>

          </div>
        </div>
      </Modal>
    </div>
  )
} 
export default PanelOptions