import { ISurveyConfigNew } from '@/shared/types/Links'
import { emmaApiService } from '.'

const surveyService = emmaApiService
  .enhanceEndpoints({ addTagTypes: ['SurveyLinks'] })
  .injectEndpoints({
    endpoints(build) {
      return {
        putSurveyConfig: build.mutation<Array<ISurveyConfigNew>, Array<ISurveyConfigNew>>({
          query: (putData) => ({
            url: '/surveyConfig',
            method: 'PUT',
            data: putData
          }),
          invalidatesTags: ['SurveyLinks']
        }),
        getSurveyConfig: build.query<any, void>({
          query: () => ({
            url: '/surveyConfig',
            method: 'GET'
          }),
          providesTags: ['SurveyLinks']
        })
      }
    }
  })

export const { useGetSurveyConfigQuery, usePutSurveyConfigMutation } = surveyService
