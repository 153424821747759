import { appDispatch } from "@/bootstrap/redux";
import withPermission, { WithPermissionProps } from "@/features/auth/components/withPermission";
import { EUserCan } from "@/features/auth/types";
import { createUIMessage } from "@/features/uimessages/redux/operations";
import { useGetWelcomeMessagesByAccountIdQuery, useUpdateMessageTemplateMutation, useUpdateWelcomeMessageMutation } from "@/shared/api/services/welcomeMessageService";
import { GeneralError } from "@/shared/components";
import Confirmation from "@/shared/components/Confirmation";
import Loader from "@/shared/components/Loader";
import useIsMobile from "@/shared/hooks/useIsMobile";
import modalSlice from "@/shared/redux/modalSlice";
import { IStackItemStyles, IStackTokens, Stack, ComboBox, IComboBoxStyles, IComboBox, IComboBoxOption, MessageBarType, PrimaryButton } from "@fluentui/react";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

export interface ManageOnboardingTemplateProps extends WithPermissionProps {
  accountId: string;
}

const MODAL_PARAM_NAME = 'manageWelcomeMessageModal'
const UI_MESSAGE_DISMISS_TIMEOUT = 3000

const ManageOnboardingTemplate = ({ accountId }: ManageOnboardingTemplateProps) => {
  const dispatch = useDispatch<appDispatch>()
  const isMobile = useIsMobile('md')
  const { data: data, isFetching, isLoading, refetch, isError, isUninitialized } = useGetWelcomeMessagesByAccountIdQuery(accountId)
  const [updateMessageTemplate] = useUpdateMessageTemplateMutation()
  const inProgress = isUninitialized || isFetching || isLoading
  const stackToken: IStackTokens = { childrenGap: 16 }
  const [selectedTemplate, setSelectedTemplate] = useState(null)
  const [templateList, setTemplateList] = useState([])
  const [isEdited, setIsEdited] = useState(false)
  const [isDialogShown, setIsDialogShown] = useState(false)
  const [changedTemplate, setChangedTemplate] = useState(null)
  const [isReset, setIsReset] = useState(false)

  useEffect(() => {
    if (!inProgress && data) {
      const templates = data.templates;
      const selectedTemplate = templates.find(m => m.isSelected === true) || templates[0];
      const allTemplates = templates.map(o => ({
        key: `${o.templateId}`,
        text: `${o.templateName}`,
        ...o
      }))
      setSelectedTemplate(`${selectedTemplate && selectedTemplate.templateId || ''}`)
      setTemplateList(allTemplates)
      setIsEdited(selectedTemplate.isEdited)
    }
  }, [isUninitialized, isFetching, isLoading, data]);

  const comboBoxStackStyles: IStackItemStyles = {
    root: {
      width: isMobile ? '100%' : '80%'
    },
  }

  const comboBoxStyles: Partial<IComboBoxStyles> = {
    root: {
      width: 250,
      outline: 'none',
    },
    optionsContainerWrapper: {
      maxHeight: 216,
      width: 250
    },
  };
  const changeTemplate = () => {
    if (isDialogShown)
      callUpdateApi(templateList.find(x => x.key == changedTemplate.key))
    else
      callUpdateApi(templateList.find(x => x.key == selectedTemplate))
  }

  const handleClick = () => {
    setIsReset(true)
  }

  const callUpdateApi = (template) => {
    updateMessageTemplate({
      accountId, template
    }).unwrap().then(o => {
      setChangedTemplate(template.templateId)
      dispatch(createUIMessage({
        key: 'saveOnboardingTextSuccess',
        content: 'Changed the template successfully.',
        messageBarType: MessageBarType.success,
        autoDismissAfter: UI_MESSAGE_DISMISS_TIMEOUT,
      }));
      dispatch(modalSlice.actions.setModalStatus({ paramName: MODAL_PARAM_NAME, value: false }))
    }).catch(error => {
      dispatch(createUIMessage({
        key: 'saveOnboardingTextFailure',
        content: 'Failed to update template.',
        messageBarType: MessageBarType.error,
        autoDismissAfter: UI_MESSAGE_DISMISS_TIMEOUT,
      }));
    })
  }

  const handleDropdownChange = (event: React.FormEvent<IComboBox>, option?: IComboBoxOption) => {
    const selectedTemp = templateList.find(m => m.key === option.key)
    setChangedTemplate(selectedTemp)
    setIsDialogShown(true)
  }
  if (isError) {
    return (<GeneralError onClick={refetch} title='Manage onboarding Template.' />)
  }

  if (inProgress) {
    return (<Loader />)
  }
  return (
    <div style={{ alignItems: 'flex-end', display: 'flex', marginLeft: 'auto' }}>
      {isEdited &&
        <>
          <PrimaryButton onClick={handleClick} style={{ marginRight: '24px' }}>Reset to default</PrimaryButton>
          <Confirmation
            hidden={!isReset}
            title='Are you sure you want to reset the template'
            subText='All the changes to the template will be lost.'
            handleDismiss={() => setIsReset(false)}
            handleNo={() => setIsReset(false)}
            handleYes={changeTemplate} />
        </>
      }

      <Stack className='onboarding-template-dropdown-wrapper' tokens={stackToken} horizontal={!isMobile}>
        <Stack.Item styles={comboBoxStackStyles}>
          <ComboBox
            label="Select a Message Template"
            selectedKey={selectedTemplate}
            options={templateList}
            styles={comboBoxStyles}
            autoComplete='off'
            allowFreeform={false}
            useComboBoxAsMenuWidth={true}
            onChange={handleDropdownChange}
          />
          <Confirmation
            hidden={!isDialogShown}
            title='Are you sure you want to change the template?'
            subText={`Are you sure you want to change this template to ${changedTemplate?.text || ''}?`} handleDismiss={() => setIsDialogShown(false)}
            handleNo={() => setIsDialogShown(false)}
            handleYes={changeTemplate}
          />
        </Stack.Item>
      </Stack>
    </div>
  )
}

export default withPermission(EUserCan.Edit_OnboardingDashboard)(ManageOnboardingTemplate)