import OnboardingBox from '@/features/onboarding/components/OnboardingBox'
import OnboardingNav from '@/features/onboarding/components/OnboardingNav'
import OnboardingTextCard, { OnboardingTextCardContent } from '@/features/onboarding/shared/components/OnboardingTextCard'
import { MainLayout } from '@/shared/components'
import PageHeader from '@/shared/components/PageHeader'
import { useTranslation } from 'react-i18next'

const OnboardingDocuments = () => {
  const { t } = useTranslation()
  return (
    <MainLayout>
      <OnboardingNav />
      <PageHeader label={t('onboardingDocuments', { ns: 'common' })} />
      <div className='c-documents-wrapper'>
        <OnboardingTextCard header='Required Onboarding Documents'>
          <OnboardingTextCardContent>Below is a list of onboarding documents with a brief description and status of documentation requests.</OnboardingTextCardContent>
          <OnboardingTextCardContent>
            {'To facilitate document collection, we have set up a secure, encrypted '}
            <a 
              className='bold-link' 
              href='https://support.box.com/hc/en-us/articles/360044194533-Data-Encryption-at-Box'
              target="_blank"
              rel="noreferrer"
            >
              online Box folder
            </a> 
            {' (see below on this page) to share documents. The folder will allow you to both upload and save documents. Please provide us with contact details of anyone who would require access.'}
          </OnboardingTextCardContent>
          <OnboardingTextCardContent>
            {'Learn more about '}
            <a 
              href="https://support.box.com/hc/en-us/articles/360043693854-Enhanced-Security"
              target="_blank"
              rel="noreferrer"
            >
              Box and security
            </a>
            {' here.'}
          </OnboardingTextCardContent>
        </OnboardingTextCard>
        <OnboardingBox />
      </div>
    </MainLayout>
  )
}

export default OnboardingDocuments