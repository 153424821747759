import React from 'react'
import { useSelector } from 'react-redux'
import { selectPermissions } from '../redux/authSelectors'
import { Permission } from '../types'

export type WithPermissionsProps = Record<string, unknown>

/**
 * HOC for restricting rendering of components based on Permissions
 * 
 * @example
 *  withPermission(EUserCan.AccessEvents)(Events)
 */
const withPermissions = (permissions: Permission[]) =>
  <P extends WithPermissionsProps>(Component: React.ComponentType<P>) => (
    function ShouldRender(props: P) {
      const userPermissions: Array<Permission> = useSelector(selectPermissions)
      const isUserGranted = permissions.some(p => userPermissions.includes(p))

      if (!isUserGranted) {
        return null
      }
      
      return <Component {...props} />
    }
  )

export default withPermissions