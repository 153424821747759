import { IBasePickerSuggestionsProps, IPersonaProps, NormalPeoplePicker, } from '@fluentui/react'
import { useTranslation } from 'react-i18next'
import { IYourTeam, useSearchTeamMemberMutation } from '@/shared/api/services/teamService'

export interface TeamPickerProps {
  maxTeamMembers: number;
  selectedTeamMembers?: IPersonaProps[];
  onTeamMembersFilter?: (sourceData: TeamPickerMember[]) => TeamPickerMember[];
  onTeamMembersSelected: (teamMembers: TeamPickerMember[]) => void;
}

export interface TeamPickerMember extends IPersonaProps {
  sourceMember: IYourTeam;
}

export default function TeamPicker({ maxTeamMembers, selectedTeamMembers, onTeamMembersFilter, onTeamMembersSelected }: TeamPickerProps) {
  const { t } = useTranslation('messages')
  const [getTeamMembers] = useSearchTeamMemberMutation()

  const suggestionProps: IBasePickerSuggestionsProps = {
    suggestionsHeaderText: 'Suggested People',
    noResultsFoundText: t('pickerNoResultsFoundText'),
    loadingText: t('pickerLoadingText'),
    suggestionsAvailableAlertText: t('pickerSuggestionsAvailableAlertText'),
    suggestionsContainerAriaLabel: 'Suggested People'
  }

  const onFilterChanged = async (filterText: string, selectedItems: IPersonaProps[]): Promise<TeamPickerMember[]> => {
    if (filterText && filterText.length >= 3) {
      const data = await getTeamMembers(filterText).unwrap()
      const sourceData = data.map((user) => ({
        text: user.fullName,
        secondaryText: user.jobTitle,
        itemID: user.peopleId.toString(),
        sourceMember: user,
      }))
      
      if (onTeamMembersFilter) {
        return onTeamMembersFilter(sourceData)
      }

      return sourceData
    } else {
      return [];
    }
  }

  const onChange = (users: TeamPickerMember[]) => {
    onTeamMembersSelected(users);
  }

  return (
    <NormalPeoplePicker
      selectedItems={selectedTeamMembers}
      onResolveSuggestions={onFilterChanged}
      pickerSuggestionsProps={suggestionProps}
      onChange={onChange}
      selectionAriaLabel={t('pickerSelectedContacts')}
      removeButtonAriaLabel={t('pickerRemove')}
      itemLimit={maxTeamMembers}
    />
  )
}
