import manageColumns from '@/features/support/constants/manageColumns'
import { useGetFAQQuery } from '@/shared/api/services/supportServices'
import { Table, ErrorMessage, GeneralError } from '@/shared/components'
import { DetailsRow, IDetailsRowProps, PrimaryButton } from '@fluentui/react'
import faqSlice, { selectQuestionBeingEdited, selectQuestionError } from '@/features/support/redux/faqSlice'
import { nanoid } from '@reduxjs/toolkit'
import { useState, memo, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { FAQ } from '../../../../types/faq'

const FAQTable = () => {
  const dispatch = useDispatch()
  const { data, isFetching, isLoading, isError, refetch } = useGetFAQQuery()
  const questionBeingEdited = useSelector(selectQuestionBeingEdited)
  const editingFAQHasData = Object.keys(questionBeingEdited || {}).length !== 0
  const [questionBeingAdded, setQuestionBeingAdded] = useState<FAQ>(editingFAQHasData && !questionBeingEdited?.createdAt ? questionBeingEdited : null)
  const questionError = useSelector(selectQuestionError)
  const columns = manageColumns(questionBeingEdited?.id, setQuestionBeingAdded)
  
  useEffect(() => {
    if (questionBeingEdited === null) { 
      setQuestionBeingAdded(null)
    }
  }, [questionBeingEdited])

  const handleAddNewQuestion = () => {
    const newQuestion: FAQ = {
      createdAt: '',
      updatedAt: '',
      id: nanoid(5),
      questionResponse: '',
      questionText: '',
      isVisible: true,
    }
    setQuestionBeingAdded(newQuestion)
    dispatch(faqSlice.actions.setQuestion(newQuestion))
  }

  let modifiedData = data
  if (questionBeingAdded) {
    modifiedData = [questionBeingAdded].concat(data)
  }

  const handleErrorToggle = (newStatus: boolean) => {
    // ErrorMessage will try and set a false boolean to hide error.
    if (newStatus === false) {
      dispatch(faqSlice.actions.setQuestionError(''))
    }
  }

  if (isError) { 
    return ( 
      <div className='c-faq-table__container'>
        <GeneralError onClick={refetch} title='Failed to get questions.' />
      </div>
    )
  }

  return (
    <div className='c-faq-table__container'>
      <ErrorMessage setShowAlert={handleErrorToggle} showAlert={Boolean(questionError)} shouldCloseAfterTimeout={true} timeout={10000} message={questionError} />
      <PrimaryButton disabled={isLoading || isFetching || editingFAQHasData} className='c-faq-table__add' onClick={handleAddNewQuestion}>Add a new question</PrimaryButton>
      <Table 
        shimmerIsEnabled={isLoading || isFetching}
        columns={columns}
        useCard
        cardProps={{
          ariaLabel: 'Frequently Asked Questions Table'
        }}
        data={modifiedData || []}
        shimmerLineCount={6}
        additionalTableProps={{
          className: 'c-faq-table'
        }}
      />
    </div>
  )
}

export default memo(FAQTable)