import { Chart } from 'chart.js'
import { getChartPlugin } from '../../utils/ChartJS.helper'
import { DonutChartSeriesItem } from '../DonutChart'

export interface DonutChartSelectedItemPluginOptions {
  selectedItem: DonutChartSeriesItem,
  series: DonutChartSeriesItem[],
}

export const DonutChartSelectedItemPlugin = {
  id: 'DonutChartSelectedItemPlugin',
  afterDraw: (chart: Chart, args, options) => {
    const plugin = getChartPlugin(chart, 'DonutChartSelectedItemPlugin') as DonutChartSelectedItemPluginOptions

    if (!plugin) {
      return
    }

    if (plugin.selectedItem) {
      chart.data.datasets[0].backgroundColor = plugin.series.map((s, index) => {
        if ((s.value === plugin.selectedItem.value) && (s.name === plugin.selectedItem.name)) {
          return s.color
        }
        return s.color + '1F'
      })
      chart.update()
    }
  }
}
