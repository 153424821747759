import { ReactElement, useEffect, useState } from 'react'
import { DefaultButton, DialogType, Link, PrimaryButton } from '@fluentui/react'
import { useTranslation } from 'react-i18next'
import { Dialog } from '@/shared/components'
import { IMessageViewRecord } from '@/shared/types/swagger'
import { useArchiveMessageByIdMutation } from '@/shared/api/services/messageService'

export default function ArchiveCell({ id }: IMessageViewRecord): ReactElement {
  const { t } = useTranslation()
  const [isHidden, setIsHidden] = useState<boolean>(true)
  const [archiveMessageById, { isLoading, isUninitialized, isError, isSuccess }] = useArchiveMessageByIdMutation()

  const handleLinkClick = () => {
    setIsHidden(false)
  }

  const handleCancelClick = () => {
    setIsHidden(true)
  }

  const handleConfirmClick = () => {
    archiveMessageById(id)
    
  }

  useEffect(() => {
    if (isSuccess) {
      setIsHidden(true)
      // handle success message
    }
  }, [isLoading, isUninitialized, isError, isSuccess])

  useEffect(() => {
    if (isError) {
      setIsHidden(true)
      // handle error
    }
  }, [isError])

  return (
    <>
      <Link onClick={handleLinkClick}>{t('messages:archive')}</Link>
      <Dialog 
        dialogContentProps={{
          type: DialogType.normal,
          title: 'Archive Message',
          subText: 'Please press the confirm button to archive this message.'
        }} 
        modalProps={{
          isBlocking: true
        }}
        hidden={isHidden}
        onDismiss={handleCancelClick}
      >
        <>
          <DefaultButton disabled={isLoading} onClick={handleCancelClick}>Cancel</DefaultButton>
          <PrimaryButton disabled={isLoading} onClick={handleConfirmClick}>Confirm</PrimaryButton>
        </>
      </Dialog>
    </>
  )
}
