import { IAccount } from '@/shared/types/swagger'
import { AxiosResponse } from 'axios'
import { mainService } from '.'

type PageAndFilterParams = {
  page?: number
  pagesize?: number
  dirsort?: string
  columnsort?: string
  columncontains?: string
  contains?: string
  columnequals?: string
  valueequals?: string
  columnnotequals?: string
  valuenotequals?: string
}

type PaginationData = {
  page: number
  pageSize: number
  totalItems: number
  totalPage
}

const defaultPageAndFilterParams: PageAndFilterParams = {
  page: 1,
  pagesize: 10,
  dirsort: null,
  columnsort: '',
  columncontains: '',
  contains: ''
}

const adminServices = mainService.injectEndpoints({
  endpoints: (build) => ({
    getClients: build.query<{accounts: Array<IAccount>} & PaginationData, PageAndFilterParams>({
      query: (config = {}) => {
        const params = Object.entries({ ...defaultPageAndFilterParams, ...config })
          .map(([key, val]) => `${key}=${val ?? ''}`)
          .join('&')
        return { 
          url: `/CRM/account/getall?${params}`, 
          method: 'GET',
        }
      },
      transformResponse: (accounts: Array<IAccount>, meta: Omit<AxiosResponse, 'data'>) => {
        const paginationData: PaginationData = JSON.parse(meta.headers['x-pagination'])
        return { accounts, ...paginationData }
      },
    })
  })
})

export const { useGetClientsQuery } = adminServices