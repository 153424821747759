import { useSelector } from "react-redux"
import { selectAccountId } from "@/features/auth/redux/authSelectors"
import * as Screens from '@/screens'

const ClientOnboardingDashboard = () => {
  const accountId = useSelector(selectAccountId)
  return (
    <Screens.Onboarding accountId={accountId} isEditable={false} />
  )
}

export default ClientOnboardingDashboard