import withPermission from '@/features/auth/components/withPermission'
import { EUserCan } from '@/features/auth/types'
import { useGetFAQQuery } from '@/shared/api/services/supportServices'
import { Accordion, GeneralError } from '@/shared/components'
import Skeleton from 'react-loading-skeleton'

const FAQ = () => {
  const { data, isLoading, refetch, isError, isFetching } = useGetFAQQuery()
  if (isError) return <GeneralError title='Frequently asked questions could not be loaded' onClick={refetch} />
  if (!data && (isLoading || isFetching)) return <Skeleton height={308} />

  const items = data
    .filter(faq => faq.isVisible)
    .map((faq) => ({ 
      title: faq.questionText,
      id: faq.id,
      content: faq.questionResponse
    }))
  
  return <Accordion ariaLabelledBy="faq" items={items}/>
}

export default withPermission(EUserCan.Access_Additional_Info)(FAQ)
