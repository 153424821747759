import { RootState } from '@/bootstrap/redux'
import { Message } from '@/features/messages/shared/types'
import { CheckListItem } from '@/shared/components/CheckList'
import { TeamMember } from '@/shared/types/teamMember'

export const selectOnboardingItems = (state: RootState): Array<CheckListItem> => [
  {
    title: 'Customer Due Diligence',
    description: 'Regulatory requirement to identify customer and/or beneficial owners’ identity (AML/KYC). A list of acceptable documentation will be provided to you for reference.',
    checked: true,
  },
  {
    title: 'Historical Performance Data request ',
    description: 'Historical Data on existing investments that will be included as “Legacy Assets” in the contract, to set up historical data of current investments for future reporting.',
    checked: false
  }
]

export const selectTeam = (state: RootState): Array<TeamMember> => [
  {
    id: 1,
    fullName: 'Kristin Patterson',
    jobTitle: 'Investment Managing Director',
    phone: '+1 (617) 454-5454',
    email: 'mona.kane@cambridgeassociates.com',
    departmentId: 1,
    profileImg: { alt: '', url: 'https://st3.depositphotos.com/15648834/17930/v/600/depositphotos_179308454-stock-illustration-unknown-person-silhouette-glasses-profile.jpg' }
  },
  {
    id: 2,
    fullName: 'Paul Torrance',
    jobTitle: 'Investment Managing Director',
    phone: '+1 (617) 454-5454',
    email: 'paul.torrance@cambridgeassociates.com',
    departmentId: 1,
    profileImg: { alt: '', url: 'https://st3.depositphotos.com/15648834/17930/v/600/depositphotos_179308454-stock-illustration-unknown-person-silhouette-glasses-profile.jpg' }
  },
  {
    id: 3,
    fullName: 'Elvia Atkins',
    jobTitle: 'Investment Managing Director ',
    phone: '+1 (617) 454-5454',
    email: 'example@cambridgeassociates.com',
    departmentId: 2,
    profileImg: { alt: '', url: 'https://st3.depositphotos.com/15648834/17930/v/600/depositphotos_179308454-stock-illustration-unknown-person-silhouette-glasses-profile.jpg' }
  },
  {
    id: 4,
    fullName: 'Erik Nason',
    jobTitle: 'Investment Managing Director',
    phone: '+1 (617) 454-5454',
    email: 'example@cambridgeassociates.com',
    departmentId: 2,
    profileImg: { alt: '', url: 'https://st3.depositphotos.com/15648834/17930/v/600/depositphotos_179308454-stock-illustration-unknown-person-silhouette-glasses-profile.jpg' }
  },
  {
    id: 5,
    fullName: 'Mona Kane',
    jobTitle: 'Investment Managing Director',
    phone: '+1 (617) 454-5454',
    email: 'example@cambridgeassociates.com',
    departmentId: 3,
    profileImg: { alt: '', url: 'https://st3.depositphotos.com/15648834/17930/v/600/depositphotos_179308454-stock-illustration-unknown-person-silhouette-glasses-profile.jpg' }
  },
  {
    id: 6,
    fullName: 'Wanda Howard',
    jobTitle: 'Investment Managing Director',
    phone: '+1 (617) 454-5454',
    email: 'example@cambridgeassociates.com',
    departmentId: 4,
    profileImg: { alt: '', url: 'https://st3.depositphotos.com/15648834/17930/v/600/depositphotos_179308454-stock-illustration-unknown-person-silhouette-glasses-profile.jpg' }
  },
]

export const selectContactMessages = (state: RootState): Array<Message> => [
  {
    id: 1,
    versionid: 1,
    createddatetime: '2021-11-18T14:39:00.687Z',
    sender: 'Michael King',
    recipients: ['Michael Evans'],
    subject: 'Client Information Form',
    action: 'Signature Required',
    actionid: 1,
    notes: 'Questionnaire required for CA to fill out investment paperwork on behalf of our clients.'
  }
]