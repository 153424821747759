import cn from 'classnames'
import Card from '../Card'
import { nanoid } from '@reduxjs/toolkit'
import { memo, useEffect, useMemo, useRef } from 'react'
import Skeleton from 'react-loading-skeleton'
import { FeaturedMediaType } from '@/shared/types/swagger'

type DigestAudio = {
  type: FeaturedMediaType.Audio,
  src: string,
  mediaId?: string,
}

type DigestImage = {
  type: FeaturedMediaType.Image,
  src: string,
  mediaId?: string,
}

type DigestVideo = {
  type: FeaturedMediaType.Video,
  src: string,
  mediaId?: string,
}

type DigestContent = DigestAudio | DigestImage | DigestVideo

type DigestProps = {
  introTitle: string,
  mainTitle: string,
  content: DigestContent,
  caption: string,
  linkUrl?: string,
}

export const DigestSkeleton = () => {
  return (
    <div role='presentation'>
      <Card className='c-digest-skeleton' ariaLabel="">
        <Skeleton width={100} height={16} />
        <Skeleton width="75%" height={28} />
        <Skeleton height={200} />
      </Card>
    </div>
  )
}

const Digest = ({ introTitle, mainTitle, content, caption, linkUrl }: DigestProps) => {
  const uniqueId = useMemo(() => nanoid(6), [])
  const containerRef = useRef(null)
  const videoRef = useRef(null)

  const getContainerId = () => {
    return `${content?.mediaId}-${content?.type}-container`
  }

  const getContentId = () => {
    return `${content?.mediaId}-${content?.type}`
  }

  const isContentPictureInPicture = () => {
    if (!content) {
      return false
    }

    
    if (!document?.pictureInPictureElement) {
      return false
    }

    const playerElement = document?.pictureInPictureElement
    const sourceElement = playerElement?.firstChild as HTMLSourceElement
    
    return (playerElement?.id === getContentId()) && (sourceElement?.src === content?.src)
  }

  const leavePictureInPictureEvent = (event) => {
    const videoElement = event?.target as HTMLVideoElement
    const container = document.querySelector(`#${getContainerId()}`) as HTMLElement
    
    if (videoElement) {
      if (container) {
        // adds video back to original container
        container.innerHTML = ''
        container.appendChild(videoElement)
      } else {
        try {
          // stops video from running when no container element
          videoElement.currentTime = 0
          videoElement.pause()
        } catch (error) {
          console.error('Error while stoping video.', error)
        }
      }
    }
  }

  useEffect(() => {
    if (videoRef) {
      const videoElement = videoRef.current as HTMLVideoElement

      if (videoElement) {
        videoElement.removeEventListener('leavepictureinpicture', leavePictureInPictureEvent)
        videoElement.addEventListener('leavepictureinpicture', leavePictureInPictureEvent)
      }
    }
  }, [videoRef])

  useEffect(() => {
    const containerElement = containerRef?.current as HTMLElement
    if (containerElement) {
      if (!containerElement.hasChildNodes() && isContentPictureInPicture()) {
        containerElement.innerHTML = ''
        containerElement.appendChild(document?.pictureInPictureElement)  
      }
    }
  }, [containerRef, content])

  if (!content) {
    return null
  }

  const renderContent = (): JSX.Element => {
    if (isContentPictureInPicture()) {
      return null
    }

    const contentType = content.type

    switch (contentType) {
      case FeaturedMediaType.Image: {
        return (
          <img id={getContentId()} src={content.src} alt={caption} />
        )
      }
      case FeaturedMediaType.Audio: {
        return (
          <audio id={getContentId()} controls title={caption}>
            <source src={content.src} />
            Your browser does not support audio playback.
          </audio>
        )
      }
      case FeaturedMediaType.Video: {
        return (
          <video ref={videoRef} id={getContentId()} key={content.src} controls title={caption} controlsList="nodownload">
            <source src={content.src} type="video/mp4"/>
            Your browser does not support video playback.
          </video>
        )
      }
      default: {
        return null
      }
    }
  }

  return (
    <Card size="large" className='c-digest' ariaLabelledBy={uniqueId}>
      <p className='c-digest__type' id={uniqueId}>{introTitle}</p> 
      <h3 className='c-digest__title'>{mainTitle}</h3>
      <div ref={containerRef} id={getContainerId()}
        className={cn(
        'c-digest__content', 
        { '--image': content.type === FeaturedMediaType.Image },
        { '--video': content.type === FeaturedMediaType.Video }
        )}
      >
        { linkUrl ? (
          <a href={linkUrl} className='digest-link' target='_blank' rel="noopener noreferrer">
            {renderContent()}
          </a>
        ) : (
          renderContent()
        )}
      </div>
    </Card>
  )
}

export default memo(Digest)