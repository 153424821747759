import { useTranslation } from 'react-i18next'
import { Link, MessageBarType } from '@fluentui/react'
import InsightBody from '@/features/insight/shared/components/InsightBody'
import InsightDateTime from '@/features/insight/shared/components/InsightDateTime'
import { IInsightsPostViewWithAlt, IInsightTag } from '../../shared/types'
import InsightsTags from '../../shared/components/InsightsTags'
import FileUpload from '@/shared/components/FileUpload'
import { useState } from 'react'
import { useUploadImageMutation } from '@/shared/api/services/insightService'
import Loader from '@/shared/components/Loader'
import { getImageSrcForBucket } from '@/features/events/helpers/getImageSrc'
import { appDispatch } from '@/bootstrap/redux'
import { useDispatch } from 'react-redux'
import { createUIMessage } from '@/features/uimessages/redux/operations'
import { trackInsightDownloadAction, trackInsightViewAction } from '@/utils/components/datadog/DatadogSessionProvider'

const UPLOAD_ERROR_MESSAGE = 'Image file upload has exceeded the max limit of 5MB'

export default function Insight(props: IInsightsPostViewWithAlt) {
  const dispatch = useDispatch<appDispatch>()
  const { id, title, description, link, featuredImageLink, featuredImageAlt, createdAt, tags, setTags, downloadLink } = props
  const { t } = useTranslation('insight')
  const [file, setFile] = useState<string>(featuredImageLink);
  const [isLoading, setLoader] = useState<boolean>(false);
  const [uploadImage] = useUploadImageMutation();
  const updateTags = (tags: IInsightTag) =>{
    if (setTags)
      setTags(tags)
  }
  const handleUpload = async (file: File) => {
    // skips upload if no files selected
    if (!file) {
      return;
    }

    try {
      // creates upload model 
      const formData = new FormData();
      formData.append('Id', id.toString());
      formData.append('file', file);

      // uploads insight file and awaits for file url
      // unwrapping to rely on return and not on tag state
      setLoader(true);
      const insightUploadImage = await uploadImage(formData).unwrap();

      // updates file if successful upload
      if (insightUploadImage) {
        setFile(getImageSrcForBucket(insightUploadImage.URL,'insights/'));
      }
    } catch (error) {
      const { exceptionType } = error || {} as any
      if (exceptionType === 'MAX_UPLOAD_SIZE_EXCEPTION') {
        dispatch(createUIMessage({ 
          key: 'uploadSizeError', 
          content: UPLOAD_ERROR_MESSAGE,
          messageBarType: MessageBarType.error,
          autoDismissAfter: 3000
        }))
        setFile(featuredImageLink)
      }
    } finally {
      setLoader(false);
    }
  }

  const onInsightLinkClick = () => {
    trackInsightViewAction(props)
  }

  const onInsightDownloadClick = () => {
    trackInsightDownloadAction(props)
  }

  return (
    <article className="c-insight">
      <aside className="c-insight__rhs">
        { isLoading && <Loader /> }
        <FileUpload id={id.toString()} handleUpload={handleUpload} accept="image/*" style={{ right: 0 }}/>
        <img 
          // In this case, the image doesn't provide any useful accessible context
          role="presentation" 
          className="c-insight__rhs-img" 
          width={260} 
          height={140} 
          src={file}
          alt={featuredImageAlt}
        />
      </aside>
      <div className='c-insight__content'>
        <h2 className="c-insight__heading">
          <Link href={link} target="_blank" onClick={onInsightLinkClick} >{title}</Link>
        </h2>
        <InsightDateTime dt={createdAt} />
        {tags?.length !== 0 && <InsightsTags tags={tags} classNames="c-insights-tags c-insights-tags--blue" setTags={updateTags} />}
        {description ? <InsightBody text={description} /> : null}
        <div className="c-insight__link-container">
          <Link 
            className="c-insight__link" 
            href={link}
            target='_blank'
            title={`${t('moreof')} ${title}`}
            onClick={onInsightLinkClick}
          >
            {t('more')}
          </Link>
          {downloadLink && <>
            <span className="divider">|</span>
            <Link
              className="c-insight__link"
              href={downloadLink}
              target='_blank'
              title="Download PDF"
              onClick={onInsightDownloadClick}
            >
              Download
            </Link>
          </>}
        </div>
      </div>
      
    </article>
  )
}
