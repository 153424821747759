import React, { useRef, useState } from 'react'
import {
  ColDef,
  GridApi,
  GridOptions,
  GridReadyEvent,
  RowDataChangedEvent,
  SelectionChangedEvent,
  ValueGetterParams,
  GetContextMenuItemsParams,
  ExcelExportParams,
  ColumnApi,
  ExcelStyle,
  RowClickedEvent,
  CellMouseOverEvent,
  CellMouseOutEvent,
  ColumnVisibleEvent,
} from 'ag-grid-community'
import { AgGridReact } from 'ag-grid-react'
import { CellChangedEvent } from 'ag-grid-community/dist/lib/entities/rowNode'
import { debounce } from 'lodash'
import {
  IDataGridColumnProps,
  MasterDetailColumn,
  IDataGridMasterDetailColumnProps,
  AutoGroupColumn,
} from './Columns'
import { CELL_STYLES, WrapExportDataAsExcel, GridExport, CURRENCY_COLUMN_CELL_CLASS } from './GridExport'
import { DateFloatingFilter } from './floatingFilter/DateFloatingFilter'
import { HIERARCHY_PATH, GridHierarchyService } from './GridHierarchyService'
import { IContextMenu, IContextMenuRoute } from '../../models/context/IContextMenu'
import { BaseRoute } from '../../models/route/BaseRoute'
import UtilService from '../../services/util/UtilService'

// ag grid base styles
import 'ag-grid-community/dist/styles/ag-grid.css'
import 'ag-grid-community/dist/styles/ag-theme-balham.css'

// grid component style
import './styles/Grid.scss'

const AGGRID_SIDEBAR = {
  toolPanels: [
    {
      id: 'filters',
      labelDefault: 'Filters',
      labelKey: 'filters',
      iconKey: 'filter',
      toolPanel: 'agFiltersToolPanel',
    },
    {
      id: 'columns',
      labelDefault: 'Columns',
      labelKey: 'columns',
      iconKey: 'columns',
      toolPanel: 'agColumnsToolPanel',
      toolPanelParams: {
        suppressRowGroups: true,
        suppressValues: true,
        suppressPivots: true,
        suppressPivotMode: true,
        suppressColumnFilter: true,
      },
    },
  ],
}

const mapToAgGridColDef = (col: any, gridId: string) => {
  if (!col) {
    return null
  }
  const type = col.type
  const colProps = col.props as IDataGridColumnProps
  const {
    checkboxSelection,
    editable,
    field,
    flex,
    headerCheckboxSelection,
    headerCheckboxSelectionFilteredOnly,
    pinned,
    sort,
    sortAt,
    title,
    width,
    hide = false,
    lockPosition = false,
    lockPinned = false,
    cellStyle,
    valueGetter,
  } = colProps
  const typeColumnDefs =
    typeof type.columnDefs === 'function'
      ? type.columnDefs(col.props)
      : type.columnDefs
  const typeValueGetter = valueGetter
    ? (params: ValueGetterParams) => valueGetter(params.data)
    : null
  const { disableWidth = false } = typeColumnDefs || {};
  return {
    // default properties
    ...typeColumnDefs,

    // initialized props
    editable,
    field,
    flex,
    hide,
    lockPinned,
    lockPosition,
    pinned,
    sort,

    // conditional props
    checkboxSelection: checkboxSelection || false,
    colId: UtilService.getId(`${title || field}_${gridId}_column`),
    headerName: title,
    headerCheckboxSelection: headerCheckboxSelection || false,
    headerCheckboxSelectionFilteredOnly: headerCheckboxSelectionFilteredOnly || false,
    sortedAt: sortAt,
    suppressSizeToFit: disableWidth || !!width,
    width: disableWidth ? undefined : width,

    // getters and setters
    cellStyle,
    valueGetter: typeValueGetter,
  } as ColDef
}

export const DEFAULT_PAGE_SIZE = 10
export const DEFAULT_PAGE_SIZE_OPTIONS = [DEFAULT_PAGE_SIZE, 25, 50, 100]

export interface DataGridInitEvent {
  gridExport: GridExport
  columnApi: ColumnApi
  gridApi: GridApi
}

export interface IDataGridRowStyleParams {
  data: any
}

export interface IDataGridProps<T> {
  // required fields
  children: any
  rows: T[]
  showToolBar: boolean

  // optional fields
  availablePageSizeOptions?: number[]
  className?: string
  contextMenuItems?: IContextMenu<any>[]
  defaultOpenGroupDataLevel?: number
  domLayout?: 'autoHeight' | 'normal' | 'print'
  enableExportContextMenu?: boolean
  enablePagination?: boolean
  enableTreeData?: boolean
  excelStyles?: ExcelStyle[]
  exportColumns?: any[]
  headerHight?: number
  id?: string
  router?: BaseRoute
  rowHeight?: number
  rowsPerPage?: number
  symbol?: string
  tooltipShowDelay?: number
  treeDataColumnId?: string
  treeDataParentColumnId?: string

  // optional getters
  getTreeDataHierarchyPath?: (row: any) => any[]
  getRowStyle?: (params: IDataGridRowStyleParams) => any

  // optional events
  onContextMenuProcess?: () => void
  onCellChange?: (row: T, field: string, newValue: any) => void
  onColumnVisible?: (event: ColumnVisibleEvent) => void 
  onInit?: (event: DataGridInitEvent) => void
  onRowClick?: (event: RowClickedEvent) => void
  onRowHover?: (event: CellMouseOverEvent) => void
  onRowMouseOut?: (event: CellMouseOutEvent) => void
  onSelection?: (rows: T[]) => void,
}

export function DataGrid<T>(props: IDataGridProps<T>) {
  const {
    // required fields
    showToolBar,

    // optional fields
    availablePageSizeOptions = DEFAULT_PAGE_SIZE_OPTIONS,
    className = '',
    contextMenuItems = [],
    defaultOpenGroupDataLevel = 0,
    domLayout = 'autoHeight',
    enableExportContextMenu = false,
    enableTreeData = false,
    excelStyles = [],
    exportColumns = [],
    headerHight = 45,
    id = '',
    router,
    rowsPerPage = DEFAULT_PAGE_SIZE,
    rowHeight = 45,
    symbol = '$',
    tooltipShowDelay = 0,
    treeDataColumnId = '',
    treeDataParentColumnId = '',


    // optional getters
    getRowStyle,
    getTreeDataHierarchyPath,

    // optional events
    onCellChange,
    onContextMenuProcess,
    onColumnVisible,
    onInit,
    onRowClick,
    onRowHover,
    onRowMouseOut,
    onSelection,
  } = props
  const sourceRows = props.rows || []
  const rows = enableTreeData ? (getTreeDataHierarchyPath ? getTreeDataHierarchyPath(sourceRows) : GridHierarchyService.addHierarchyPath(sourceRows, treeDataColumnId, treeDataParentColumnId)) : sourceRows

  const gridId = UtilService.getId(id || `${UtilService.generateId()}_grid`)
  const elementReference = useRef(null)
  const [pageSizeSelector, setPageSizeSelector] = useState(
    {} as HTMLSelectElement
  )

  const enablePagination =
    props.enablePagination === undefined
      ? rows && rows.length > DEFAULT_PAGE_SIZE
        ? true
        : false
      : props.enablePagination

  const children = (Array.isArray(props.children)
    ? props.children
    : [props.children]
  ).filter((child) => !!child)
  const masterDetailProps = (children || []).find(
    (child) => child.type === MasterDetailColumn
  )?.props
  const gridOptions: GridOptions = {
    pagination: enablePagination,
    suppressRowClickSelection: true,
    rowSelection: 'multiple',
    paginationPageSize: rowsPerPage,
    onSelectionChanged: (event: SelectionChangedEvent) => {
      if (onSelection) {
        const rows = event.api
          .getSelectedNodes()
          .map((rowNode) => rowNode.data)
        onSelection(rows)
      }
    },
    onCellValueChanged: (event: CellChangedEvent) => {
      if (onCellChange) {
        onCellChange(
          event.node.data,
          event.column.getColDef().field || '',
          event.newValue
        )
      }
    },
    excelStyles: [
      ...CELL_STYLES,
      ...[
        {
          id: CURRENCY_COLUMN_CELL_CLASS,
          numberFormat: {
            format: symbol + '#,##0.00_);[Red](' + symbol + '#,##0.00)',
          },
          font: {
            size: 10,
          },
        },
      ],
      ...(excelStyles || [])
    ],
    ...mayCreateMasterDetail({
      ...masterDetailProps,
      id: gridId,
    }),
  }

  let autoGroupColumnDef
  const autoGroupColumn = (children || []).find((col) => col.type === AutoGroupColumn)
  const components = {} as any

  const columnDefs = (children || []).filter((col) => col !== autoGroupColumn)
    .map((col) => mapToAgGridColDef(col, gridId))
    .filter((col) => !!col)
  const handleResizeColumns = debounce(({ api }: { api: any }) => {
    api.sizeColumnsToFit()
  })

  if (autoGroupColumn) {
    const autoGroupColDef = mapToAgGridColDef(autoGroupColumn, gridId) as any
    components.autoGroupCellRenderer = autoGroupColumn.props.autoGroupCellRenderer
    autoGroupColumnDef = {
      ...autoGroupColDef,
      originalCellClass: autoGroupColumn && autoGroupColumn.props && autoGroupColumn.props.cellClass,
      cellClass: getIndentClass,
      cellRendererParams: {
        ...autoGroupColDef.cellRendererParams,
        innerRenderer: 'autoGroupCellRenderer',
      },
    } as ColDef
  }

  function getIndentClass(params: any) {
    let indent = 0
    let node = params.node
    while (node && node.parent) {
      indent++
      node = node.parent
    }
    return 'indent-' + (indent - 1)
  }


  // TODO - need to find out a better way to get grid count/all rows as this is expensive
  const getAllRows = (gridApi: GridApi) => {
    const allRows = [] as T[]

    if (gridApi) {
      gridApi.forEachNode((rowNode, index) => {
        allRows.push(rowNode.data)
      })
    }

    return allRows
  }

  const refreshPageSizeSelector = (rows: T[]) => {
    if (pageSizeSelector) {
      pageSizeSelector.disabled = !rows || !rows.length
    }
  }

  const handleColumnVisible = (event: ColumnVisibleEvent) => {
    handleTitleToColumnHeaders()
    if (onColumnVisible) {
      onColumnVisible(event)
    }
  }

  const handleTitleToColumnHeaders = () => {
    const element = elementReference.current as HTMLElement | null

    if (element) {
      const headerLabels = element.querySelectorAll(
        '.ag-header-cell .ag-header-cell-label'
      )
      if (headerLabels && headerLabels.length) {
        for (let index = 0, size = headerLabels.length; index < size; index++) {
          const headerLabel = headerLabels[index] as HTMLElement
          const headerText = headerLabel.querySelector(
            '.ag-header-cell-text'
          ) as HTMLElement
          if (headerText) {
            headerLabel.title = headerText.innerText
          }
        }
      }
    }
  }

  const initPageSizeSelector = (gridApi: GridApi) => {
    const { enablePagination } = props
    if (enablePagination) {
      const element = elementReference.current as HTMLElement | null

      if (element) {
        const selectorLabel = document.createElement('span')
        selectorLabel.innerText = 'Page Size'
        selectorLabel.style.marginRight = '10px'

        const selector = document.createElement('select')
        selector.disabled = true
        selector.innerHTML = (availablePageSizeOptions || DEFAULT_PAGE_SIZE_OPTIONS).reduce((acct, option) => {
          const selected = acct === '' ? `selected='selecte` : ''
          const html = `<option ${selected} value='${option}'>${option}</option>`
          return acct + html
        }, '') + `<option value='all'>All</option>`
        selector.addEventListener('change', (event) => {
          const value = selector.value

          if (value === 'all') {
            const allRows = getAllRows(gridApi)
            gridApi.paginationSetPageSize(
              (allRows && allRows.length) ||
              gridOptions.paginationPageSize ||
              DEFAULT_PAGE_SIZE
            )
          } else {
            gridApi.paginationSetPageSize(Number(value))
          }

          gridApi.paginationGoToPage(0)
        })

        const selectorPanel = document.createElement('span')
        selectorPanel.className =
          'ag-paging-row-summary-panel page-size-selector'
        selectorPanel.appendChild(selectorLabel)
        selectorPanel.appendChild(selector)

        const pagingPanel = element.querySelector('.ag-paging-panel')

        if (pagingPanel) {
          const firstPagingPanelComponent = pagingPanel.querySelectorAll(
            '.ag-paging-row-summary-panel'
          )[0]

          pagingPanel.insertBefore(selectorPanel, firstPagingPanelComponent)
        }

        setPageSizeSelector(selector)
      }
    }
  }

  const getContextMenuItems = (params: GetContextMenuItemsParams) => {
    const { api, node } = params

    const mapContextMenu = (contextMenuItem: any) => {
      const { title, items, route, onClick } = contextMenuItem
      const context = {
        name: title,
        action: () => {
          const { data } = node

          if (onContextMenuProcess) {
            onContextMenuProcess()
          }

          if (onClick) {
            return onClick(data)
          }

          if (route && router) {
            const actionRoute = route(router) as IContextMenuRoute<any>
            const routeParams = actionRoute.entityToRouteParams(data)
            return actionRoute.route.redirect(routeParams)
          }
        },
      } as any

      if (items && items.length) {
        context.subMenu = (items || []).map((item: any) =>
          mapContextMenu(item)
        )
      }

      return context
    }

    const contextMenu = (contextMenuItems || [])
      .map((contextMenuItem) => {
        return mapContextMenu(contextMenuItem)
      })
      .filter((contextMenuItem) => !!contextMenuItem) as any[]

    if (contextMenu.length) {
      contextMenu.push('separator')
    }

    if (enableExportContextMenu) {
      contextMenu.push({
        name: 'Export',
        action: () =>
          api &&
          api.exportDataAsExcel({
            columnKeys: exportColumns,
          }),
      })
    }

    return contextMenu
  }

  const getSideBar = () => {
    const sideBar = { ...AGGRID_SIDEBAR }
    const hasFilters = !!columnDefs.find((columnDef) => !!columnDef.filter)

    if (!hasFilters) {
      sideBar.toolPanels = sideBar.toolPanels.filter(
        (toolPanel) => toolPanel.id !== 'filters'
      )
    }

    return sideBar
  }

  const getTreeDataHierarchy = (row: any) => {
    return row[HIERARCHY_PATH]
  }

  const handleGridReady = (event: GridReadyEvent) => {
    const { api: gridApi, columnApi: columnApi } = event

    // adds title to column headers
    handleTitleToColumnHeaders()

    // inits page size selector
    initPageSizeSelector(gridApi)

    // overrrides grid excel export
    WrapExportDataAsExcel(gridApi)

    // emmits onInit event
    if (onInit) {
      onInit({
        gridExport: {
          onExport: (params = {}) => {
            gridApi.exportDataAsExcel({
              ...params,
              columnKeys: params.columnKeys || exportColumns,
            } as ExcelExportParams)
          },
        },
        columnApi: columnApi,
        gridApi: gridApi
      })
    }
  }

  const handleRowDataChanged = (event: RowDataChangedEvent) => {
    const { api } = event
    refreshPageSizeSelector(getAllRows(api))
  }

  const handleGridColumnsChanged = (event: any) => {
    handleResizeColumns(event)
    handleTitleToColumnHeaders()
  }

  if (pageSizeSelector) {
    refreshPageSizeSelector(rows)
  }

  
  return (
    <div
      className={`ca-grid ag-theme-balham ca_grid ${className || ''}`}
      ref={elementReference}
      id={gridId}
    >
      <AgGridReact
        autoGroupColumnDef={autoGroupColumnDef}
        columnDefs={columnDefs}
        components={components}
        domLayout={domLayout}
        frameworkComponents={{
          dateFloatingFilter: DateFloatingFilter,
        }}
        gridOptions={gridOptions}
        groupDefaultExpanded={defaultOpenGroupDataLevel}
        headerHeight={headerHight}
        getDataPath={getTreeDataHierarchy}
        getContextMenuItems={getContextMenuItems}
        getRowStyle={getRowStyle}
        onCellMouseOver={onRowHover}
        onCellMouseOut={onRowMouseOut}
        onColumnMoved={handleTitleToColumnHeaders}
        onColumnVisible={handleColumnVisible}
        onColumnPinned={handleTitleToColumnHeaders}
        onGridColumnsChanged={handleGridColumnsChanged}
        onGridReady={handleGridReady}
        onGridSizeChanged={handleResizeColumns}
        onModelUpdated={handleResizeColumns}
        onRowClicked={onRowClick}
        onRowDataChanged={handleRowDataChanged}
        onToolPanelVisibleChanged={handleResizeColumns}
        popupParent={document.querySelector('body') as HTMLElement}
        rowHeight={rowHeight}
        rowData={rows}
        sideBar={showToolBar ? getSideBar() : undefined}
        suppressRowHoverHighlight={false}
        suppressCellFocus={true}
        treeData={enableTreeData}
        tooltipShowDelay={tooltipShowDelay}
      ></AgGridReact>
    </div>
  )
}

function mayCreateMasterDetail<T>(
  props?: IDataGridMasterDetailColumnProps<T>
): GridOptions | undefined {
  if (!props) {
    return {}
  }

  const columnDefs = (props.children || []).map((col) =>
    mapToAgGridColDef(col, `${props.id || ''}_detail`)
  )

  return {
    masterDetail: true,
    detailCellRendererParams: {
      detailGridOptions: {
        columnDefs,
      },
      async getDetailRowData(params: any) {
        const { data, successCallback, node } = params
        const { id } = node
        const rows = await props.fetchMasterData(data as T)
        successCallback(rows)
        setTimeout(() => {
          const detailWrapper = document.querySelector(`[row-id='${id}']`)

          if (detailWrapper) {
            const detailGrid = detailWrapper.querySelector(
              `[ref='eDetailGrid']`
            )

            if (detailGrid) {
              detailGrid.setAttribute('id', `${props.id}_${id}`)
            }
          }
        })
      },
    },
  }
}

export default DataGrid
