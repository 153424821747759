/*
 A lot of inspiration and code comes from 
 https://github.com/mui-org/material-ui/blob/master/packages/mui-material/src/usePagination/usePagination.js
*/

import useIsMobile from '@/shared/hooks/useIsMobile'

// This 👇                          👇 - how many to show
//   <- [1] ... [12] [13] [14] ... [30] ->
// A value of 2 would show
//   <- [1] [2] ... [12] [13] [14] ... [29] [30] ->
const BOUNDARY_COUNT = 1

// This         👇 --------👇 - how many to show around the current
//   <- [1] ... [12] [13] [14] ... [30] ->
const SIBLING_COUNT = 1

const range = (start: number, end: number): Array<number> => {
  const length = end - start + 1
  return Array.from({ length }, (_, i) => start + i)
}

export default function usePagination(totalPages: number, current: number): Array<string | number> {
  const startPages = range(1, Math.min(BOUNDARY_COUNT, totalPages))
  const endPages = range(Math.max(totalPages - BOUNDARY_COUNT + 1, BOUNDARY_COUNT + 1), totalPages)
  const isMobile = useIsMobile('xs')

  const siblingsStart = Math.max(
    Math.min(
      // Natural start
      current - SIBLING_COUNT,
      // Lower boundary when page is high
      totalPages - BOUNDARY_COUNT - SIBLING_COUNT * 2 - 1,
    ),
    // Greater than startPages
    BOUNDARY_COUNT + 2,
  )

  const siblingsEnd = Math.min(
    Math.max(
      // Natural end
      current + SIBLING_COUNT,
      // Upper boundary when page is low
      BOUNDARY_COUNT + SIBLING_COUNT * 2 + 2,
    ),
    // Less than endPages
    endPages.length > 0 ? endPages[0] - 2 : totalPages - 1,
  )

  const firstEllipsis: Array<string | number> = (() => {
    if (siblingsStart > BOUNDARY_COUNT + 2) return ['start-ellipsis']
    if (BOUNDARY_COUNT + 1 < totalPages - BOUNDARY_COUNT) return [BOUNDARY_COUNT + 1]
    return []
  })()

  const endEllipsis: Array<string | number> = (() => {
    if (siblingsEnd < totalPages - BOUNDARY_COUNT - 1) return ['end-ellipsis']
    if (totalPages - BOUNDARY_COUNT > BOUNDARY_COUNT) return [totalPages - BOUNDARY_COUNT]
    return []
  })()

  return [
    'first',
    ...(isMobile ? [] : ['previous']),
    ...startPages,
    ...firstEllipsis,
    ...range(siblingsStart, siblingsEnd),
    ...endEllipsis,
    ...endPages,
    ...(isMobile ? [] : ['next']),
    'last'
  ]
}