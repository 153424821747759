import dayjs from '@/bootstrap/dayjs'
import { useGetFeaturedEventQuery, useGetUpcomingEventsQuery } from '@/shared/api/services/eventServices'
import { IEvent } from '@/shared/types/swagger'

type AllUpcomingReturn = {
  data: Array<IEvent>
  isUninitialized: boolean;
  isLoading: boolean;
  isSuccess: boolean;
  isError: boolean;
}

export default function useGetAllUpcomingEventsQuery(): AllUpcomingReturn {
  const upcoming = useGetUpcomingEventsQuery()
  const featured = useGetFeaturedEventQuery()

  const data = [...(upcoming?.data || []), featured?.data as IEvent]
    .filter(Boolean)
    .sort((a, b) => (dayjs(a.startEventDate).isAfter(dayjs(b.startEventDate)) ? -1 : 1))

  return {
    data,
    isUninitialized: upcoming.isUninitialized || featured.isUninitialized,
    isLoading: upcoming.isLoading || featured.isLoading,
    isSuccess: upcoming.isSuccess && featured.isSuccess,
    isError: upcoming.isError || featured.isError
  }
}