import { useRef, CSSProperties, forwardRef, useEffect } from 'react'
import { ArcElement, Chart, Legend, Tooltip, TooltipModel } from 'chart.js'
import { Doughnut } from 'react-chartjs-2'
import { DonutChartCenterTextPlugin, DonutChartCenterTextPluginOptions } from './plugins/DonutChartCenterTextPlugin'
import { DonutChartSelectedItemPlugin } from './plugins/DonutChartSelectedItemPlugin'

import './DonutChart.scss'

// register required settings for Doughnut chart.js
Chart.register(ArcElement, Tooltip, Legend, DonutChartCenterTextPlugin, DonutChartSelectedItemPlugin)

export interface DonutChartSeriesItem {
  name: string,
  value: number,
  color: string,
}

export interface DonutChartProps {
  series: DonutChartSeriesItem[],
  title?: string,
  responsive?: boolean,
  height?: number,
  width?: number,
  legend?: {
    position?: 'left' | 'top' | 'right' | 'bottom' | 'center',
    horizontalAlign?: 'start' | 'center' | 'end',
  },
  centerTextOptions?: DonutChartCenterTextPluginOptions,
  selectedItem?: DonutChartSeriesItem,
  customTooltipHandler?: (chart: Chart, tooltip: TooltipModel<any>, series: DonutChartSeriesItem[]) => void,
  onSeriesHover?: (seriesItem: DonutChartSeriesItem) => void,
  onSeriesClick?: (seriesItem: DonutChartSeriesItem) => void,
  onSeriesLeave?: () => void,
}

const DonutChart = forwardRef<Chart<"doughnut">, DonutChartProps>((props, ref) => {
  const chartRef = useRef(null)
  const colors = props.series.map(s => {
    return s.color
  })

  const data = {
    labels: props.series.map(s => s.name),
    datasets: [{
      label: props.title,
      data: props.series.map(s => s.value),
      backgroundColor: colors,
      hoverOffset: 4
    }]
  }

  const getAdditionalPlugins = () => {
    const additionalPlugins = {} as any
    if (props.centerTextOptions) {
      additionalPlugins.DonutChartCenterTextPlugin = {
        ...props.centerTextOptions,
      }
    }
    if (props.selectedItem) {
      additionalPlugins.DonutChartSelectedItemPlugin = {
        selectedItem: props.selectedItem,
        series: props.series
      }
    }
    return additionalPlugins
  }

  const getStyle = () => {
    const style = {} as CSSProperties
    if (props.height) {
      style.height = `${props.height}rem`
    }
    if (props.width) {
      style.width = `${props.width}rem`
    }
    return style
  }

  const onChartMouseleave = () => {
    setTimeout(() => {
      const chartInstance = chartRef?.current as Chart<"doughnut">

      if (chartInstance) {
        chartInstance.data.datasets[0].backgroundColor = colors
        chartInstance.update()
      }

      if (props.onSeriesLeave) {
        props.onSeriesLeave()
      }
    }, 500)
  }

  useEffect(() => {
    if (chartRef) {
      const chartInstance = chartRef.current as Chart<"doughnut">
      
      if (ref) {
        (ref as React.MutableRefObject<Chart<"doughnut">>).current = chartInstance
      }
    }
  }, [chartRef, ref])

  return (
    <div className='DonutChartContainer' style={getStyle()} onMouseLeave={onChartMouseleave}>
      <Doughnut ref={chartRef} data={data} options={{
        responsive: props.responsive,
        plugins: {
          tooltip: {
            enabled: false,
            position: 'nearest',
            external: props.customTooltipHandler ? (context) => {
              props.customTooltipHandler(context.chart, context.tooltip, props.series)
            } : undefined
          },
          legend: {
            display: false,
            position: props?.legend?.position,
            align: props?.legend?.horizontalAlign,
          },
          title: {
            display: false,
            text: props.title,
          },
          ...getAdditionalPlugins(),
        },
        aspectRatio: 1,
        cutout: '65%',
        layout: {
          padding: {
            left: 1,
            top: 1,
            bottom: 1,
            right: 1,
          }
        },
        onHover(event, elements, chart) {
          if (elements.length) {
            chart.data.datasets[0].backgroundColor = props.series.map((s, index) => {
              if (index === elements[0].index) {
                return s.color
              }
              return s.color + '1F'
            })
            chart.update()
            if (props.onSeriesHover) {
              props.onSeriesHover(props.series[elements[0].index])
            }
          } else {
            chart.data.datasets[0].backgroundColor = colors
            chart.update()
            if (props.onSeriesHover) {
              props.onSeriesHover(undefined)
            }
          }
        },
        onClick(event, elements, chart) {
          if (elements.length) {
            if (props.onSeriesClick) {
              props.onSeriesClick(props.series[elements[0].index])
            }
          } else {
            if (props.onSeriesClick) {
              props.onSeriesClick(undefined)
            }
          }
        },
      }} />
    </div>
  )
})

DonutChart.displayName = 'DonutChart'

export default DonutChart
