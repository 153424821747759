import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { UIMessage, UIMessagesState } from '../shared/types'
import { createUIMessage } from './operations'

export const initialState: UIMessagesState = {
  messages: {},
  archive: {}
}

const DEFAULT_UIMESSAGE: Omit<UIMessage, 'key' | 'content'> = {
  autoDismiss: true,
  autoDismissAfter: 8000,
}

const UIMessagesSlice = createSlice({
  name: 'uimessages',
  initialState,
  reducers: {
    PUSH_UIMESSAGE(state, action: PayloadAction<UIMessage>) {
      const { key } = action.payload
      state.messages[key] = {
        ...DEFAULT_UIMESSAGE,
        ...action.payload
      }
    },
    POP_UIMESSAGE(state, action: PayloadAction<{key: string}>) {
      const { key } = action.payload
      if (key in state.messages) {
        state.archive[key] = state.messages[key]
        delete state.messages[key]
      }
    },
    MODIFY_UIMESSAGE(state, action: PayloadAction<UIMessage>) {
      const { key } = action.payload
      if (key in state) {
        state.messages[key] = {
          ...state[key],
          ...action.payload
        }
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(createUIMessage.fulfilled, (state, action: PayloadAction<UIMessage>) => {
      if (!action || !action.payload) {
        return
      }
      const { key } = action.payload
      state.archive[key] = state.messages[key]
      delete state.messages[key]
    })
  }
})

export const { PUSH_UIMESSAGE, POP_UIMESSAGE, MODIFY_UIMESSAGE } = UIMessagesSlice.actions
export default UIMessagesSlice.reducer