import { useGetFeaturedEventQuery } from '@/shared/api/services/eventServices'
import { Feature, SkeletonFeature, GeneralError } from '@/shared/components'
import { getImageSrc, getImageSrcForBucket } from '../../helpers/getImageSrc'
import defaultFeaturedImage from '@/shared/assets/img/blue-globes.png'
import { getHTMLContentWithEllipses } from '@/features/insight/helpers'
import dayjs from '@/bootstrap/dayjs'

import './FeaturedEvent.scss'

const MAX_DESC_CHAR_COUNT = 210

const FeaturedEvent = () => {
  const { data, isFetching, isLoading, refetch, isError } = useGetFeaturedEventQuery()

  if (isError) return <GeneralError title='The featured event could not be loaded' onClick={refetch} />
  if (isLoading || isFetching) return <SkeletonFeature />

  const body = getHTMLContentWithEllipses(data?.description, MAX_DESC_CHAR_COUNT)

  return (
    <Feature additionalClassName='FeaturedEvent'
      body={body}
      heading={data.title} 
      date={dayjs.utc(data.startEventDate).tz(data.timezoneName).toDate()}
      endDate={dayjs.utc(data.endEventDate).tz(data.timezoneName).toDate()}
      href={data.eventUrl}
      image={{ src: getImageSrcForBucket(data.imageUrl,'events/') || defaultFeaturedImage,  alt: data.imageAlt }}
      location={data.location}
    />
  )
}

export default FeaturedEvent