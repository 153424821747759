import { DelayedRender } from '@fluentui/react'
import './FieldErrorMessage.scss'

type Props = {
  message: string
}

export default function FieldErrorMessage({ message }: Props) {
  const hasErrorMessage = Boolean(message)
  if (!hasErrorMessage) return null

  return (
    <div role="alert">
      <DelayedRender>
        <p className="errorMessage">
          <span data-automation-id="error-message">{message}</span>
        </p>
      </DelayedRender>
    </div>
  )
}