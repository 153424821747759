import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AccountInfo, AuthenticationResult } from '@azure/msal-browser'
import { IClaims } from '@/shared/types/Claims'
import { PURGE } from 'redux-persist'

export type AccountClaims = Omit<AccountInfo, 'idTokenClaims'> & {idTokenClaims: IClaims}
export type AuthenticationResultWithClaims = Omit<AuthenticationResult, 'account'> & { account: AccountClaims }

export interface IAuthState {
  idToken?: string;
  account?: AccountClaims;
  uniqueId?: string;
}

export const initialState: IAuthState = {
  idToken: '',
  account: null,
  uniqueId: '',
}

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    loginSuccess(state, action: PayloadAction<AuthenticationResultWithClaims>) {
      state.idToken = action.payload?.idToken
      state.account = action.payload?.account
      state.uniqueId = action.payload?.uniqueId
    },
    logout(state) {
      state.idToken = initialState.idToken
      state.account = initialState.account
      state.uniqueId = initialState.uniqueId
    }
  },
  extraReducers: (builder) => {
    builder.addCase(PURGE, (state) => {
      state.idToken = initialState.idToken
      state.account = initialState.account
      state.uniqueId = initialState.uniqueId
    })
  }
})

export const { loginSuccess, logout } = authSlice.actions
export default authSlice.reducer