import * as React from 'react'
import { Nav, INavLink, INavStyles, INavLinkGroup, Stack, PrimaryButton, Link, ChoiceGroup, CommandBar, DirectionalHint } from '@fluentui/react'
import { ContentExplorer } from 'box-ui-elements/es/elements'
import { IntlProvider } from 'react-intl'
import i18n from 'i18next'
import TextField from '@/shared/components/TextField'
import Card from '@/shared/components/Card'
import Dropdown from '@/shared/components/Dropdown'
import TeamMemberDetail from '@/shared/components/TeamMemberDetail'
import DocumentBreadcrumbs from '@/features/document/components/DocumentBreadcrumbs'
import Section from '@/shared/components/Section'
import LedeText from '@/shared/components/LedeText'
import DataGrid from '@/shared/components/DataGrid'
import Article from '@/shared/components/Article'
import CreateMessageModal from '@/features/messages/components/CreateMessageModal'
import onboardingDataGrid from '@/features/onboarding/constants/onboardingDataGrid'
import NotificationList from '@/shared/components/NavBar/components/NotificationList'
import RecentDocuments from '@/features/dashboard/components/RecentDocuments'
import MessagesTableActive from '@/features/messages/components/MessagesTable/MessagesTableActive'
import MessagesTableArchive from '@/features/messages/components/MessagesTable/MessagesTableArchive'
import NotificationIcon from '@/shared/components/NavBar/components/NotificationIcon'
import NotificationTable from '@/features/notifications/components/NotificationTable'

const DEV_TOKEN_LENGTH = 32

const navStyle: Partial<INavStyles> = {
  groupContent: { marginBottom: 0 },
}

const groups: Array<INavLinkGroup> = [
  {
    name: 'Document Sharing',
    links: [
      {
        name: 'Recent Documents',
        url: '#doc-recent'
      },
      {
        name: 'Content Explorer',
        url: '#doc-explorer'
      },
      {
        name: 'Document Breadcrumbs',
        url: '#doc-breadcrumbs'
      },
    ]
  },
  {
    name: 'Messaging',
    collapseByDefault: true,
    links: [
      {
        name: 'Messages Tables',
        url: '#messages-table'
      },
      {
        name: 'Message Modal',
        url: '#messages-modal'
      }
    ]
  },
  {
    name: 'Notifications',
    collapseByDefault: true,
    links: [
      {
        name: 'Notifications Menu',
        url: '#notifications-menu'
      },
      {
        name: 'Notifications Table Unread',
        url: '#notifications-table-unread'
      },
      {
        name: 'Notifications Table Read',
        url: '#notifications-table-read'
      }
    ]
  },
]

function ComponentsGuide(): React.ReactElement {
  const [selectedKey, setSelectedKey] = React.useState<string>(null)
  const [boxDevToken, setBoxDevToken] = React.useState<string>('')
  const [isOpen, setIsOpen] = React.useState<boolean>(false)

  function handleLinkClick(e?: React.MouseEvent<HTMLElement>, item?: INavLink) {
    if (item?.key) {
      setSelectedKey(item.key)
    }
  }

  function handleTokenChange(e: React.ChangeEvent<HTMLInputElement>) {
    const value = e?.target?.value
    if (value && value.length === DEV_TOKEN_LENGTH) {
      setBoxDevToken(e?.target?.value)
    }
  }

  return (
    <div className="c-guide">
      <header className="c-guide__header">
        <h1>Component Guide</h1>
      </header>
      <aside className="c-guide__side">
        <Nav 
          onLinkClick={handleLinkClick}
          selectedKey={selectedKey}
          groups={groups}
          styles={navStyle}
        />
      </aside>
      <main className="c-guide__container">
        <section className="c-guide-section">
          <h2 id="doc">Document Sharing</h2>

          <Stack className="c-guide-component">
            <h3 id="doc-recent">Recent Documents</h3>

            <RecentDocuments />
          </Stack>

          <Stack className="c-guide-component">
            <h3 id="doc-explorer">Content Explorer</h3>
            <TextField 
              label="Paste Dev Token"
              onChange={handleTokenChange} 
              description={<span>This component wont load without a developer token: a {DEV_TOKEN_LENGTH} character string you can <a href="https://app.box.com/developers/console">generate here</a></span>}
            />
            
            {boxDevToken?.length === DEV_TOKEN_LENGTH ? (
              <IntlProvider locale="en">
                <ContentExplorer 
                  key={boxDevToken} 
                  token={boxDevToken}
                  onSelect={(selectedItems) => {
                    const [file] = selectedItems
                  }}
                />
              </IntlProvider>
            ) : null}
          </Stack>

          <Stack className="c-guide-component">
            <h3 id="doc-breadcrumbs">Document Breadcrumbs</h3>

            <DocumentBreadcrumbs 
              items={[{
                key: 'foodoc',
                text: 'Document Name.docx',
              }]}
            />
          </Stack>

        </section>

        <section className="c-guide-section">
          <h2 id="messages">Messaging</h2>

          <Stack className="c-guide-component">
            <h3 id="messages-table">MessagesTable</h3>
          
            <MessagesTableActive />
            <MessagesTableArchive />
          </Stack>

          <Stack className="c-guide-component">
            <h3 id="clients-message-modal">Messages Modal</h3>

            <PrimaryButton onClick={() => setIsOpen(true)}>Open Create Message Modal</PrimaryButton>
            <CreateMessageModal isOpen={isOpen} setIsOpen={setIsOpen} accountid="foo" boxAccess={{ token: '', folder: '' }} />
          </Stack>

        </section>

        <section>
          <h2 id="notifications">Notifications</h2>

          <Stack className="c-guide-component">
            <h3 id="notifications-menu">Notifications Menu</h3>
            <CommandBar
              items={[{
              key: 'notifications',
              text: 'Show Menu',
              onRenderIcon: () => <NotificationIcon />,
              subMenuProps: {
                isBeakVisible: true,
                beakWidth: 16,
                directionalHint: DirectionalHint.bottomRightEdge,
                items: [
                  { 
                    key: 'notificationList',
                    onRender: () => <NotificationList />,
                    data: { testing: 'test' }
                  }
                ]
              }
            }]}
            />
          </Stack>

          <Stack className="c-guide-component">
            <h3 id="notifications-table-unread">Notifications Table Unread</h3>

            <NotificationTable isReadTable={false} />
          </Stack>

          <Stack className="c-guide-component">
            <h3 id="notifications-table-read">Notifications Table Read</h3>

            <NotificationTable isReadTable />
          </Stack>
        </section>
      </main>
    </div>
  )
}

export default ComponentsGuide
