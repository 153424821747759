import React from 'react'
import { ICellRendererParams } from 'ag-grid-community'

import './TextCellRenderer.scss'

export const TextCellRenderer = (props: ICellRendererParams) => {
  const { value } = props
  const formattedValue = value || ''
  return (
    <div className={`TextCellRenderer ${formattedValue.replace(' ', '')}`}>
      {formattedValue}
    </div>
  )
}
