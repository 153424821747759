import dayjs from '@/bootstrap/dayjs'
import { useGetMaintenanceDataQuery, useUpdateMaintenanceDataMutation } from '@/shared/api/services/portalService'
import modalSlice from '@/shared/redux/modalSlice'
import { IMaintenanceBannerSettings } from '@/shared/types/swagger'
import { IStackTokens, Stack, TextField } from '@fluentui/react'
import { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import FieldErrorMessage from '../../FieldErrorMessage'
import ButtonModal from '../../PostModal/components/ButtonModal'
import dtValueIsNull from '../helpers/dtValueIsNull'
import MaintenanceBannerDateTimeFields from './MaintenanceBannerDateTimeFields'

const TITLE = 'Manage Maintenance Banner'
const PARAM_NAME = 'maintenance' as const
const stackToken: IStackTokens = { childrenGap: 16, padding: '8px 0px 32px' }

export default function MaintenanceBannerManagement() {
  const form = useForm<IMaintenanceBannerSettings>()
  const { data, isSuccess } = useGetMaintenanceDataQuery()
  const [updateMaintenanceData, { isLoading }] = useUpdateMaintenanceDataMutation()
  const [errorMsg, setErrorMsg] = useState<string>(null)
  const dispatch = useDispatch()
  
  const handleSave = form.handleSubmit((_data) => {
    // if one datetime has a value and the other doesn't don't save.
    const startIsNull = dtValueIsNull(_data.maintenanceBegin)
    const endIsNull = dtValueIsNull(_data.maintenanceEnd)
    if (startIsNull !== endIsNull) {
      setErrorMsg('One datetime has validation errors. Please fix and save again.')
      return
    }
    if (dayjs(_data.maintenanceEnd).isBefore(dayjs(_data.maintenanceBegin))) {
      setErrorMsg('The end datetime is before the begin datetime. Please fix and save again.')
      return
    }
    if (!_data.bannerText) {
      setErrorMsg('The banner text is required. Please fill it in and save again.')
    }
    updateMaintenanceData(_data)
    setErrorMsg(null)
    dispatch(modalSlice.actions.setModalStatus({ paramName: PARAM_NAME, value: false }))
  })

  useEffect(() => {
    if (isSuccess) {
      form.setValue('bannerText', data.bannerText)
      form.setValue('maintenanceBegin', data.maintenanceBegin ?? undefined)
      form.setValue('maintenanceEnd', data.maintenanceEnd ?? undefined)
    }
  }, [isSuccess])

  return (
    <ButtonModal 
      openButtonLabel={TITLE}
      openButtonAriaLabel={TITLE}
      paramName={PARAM_NAME}
      modalTitle={TITLE}
      closeAriaLabel={`Close ${TITLE} Modal`}
      primaryLabel='Save'
      onPrimary={handleSave}
      primaryIsDisabled={isLoading}
      modalClassName='c-maint-modal'
      className='c-maint-mng'
    >
      <form noValidate onSubmit={handleSave}>
        <Stack tokens={stackToken}>
          <Stack.Item>
            <Controller 
              control={form.control}
              name='bannerText'
              rules={{ required: 'Banner Text is required.' }}
              render={({ field, fieldState }) => (
                <>
                  <TextField 
                    label='Banner Text'
                    required
                    defaultValue={data?.bannerText}
                    aria-required={true}
                    invalid={Boolean(fieldState.error?.message)}
                    {...field}
                  />
                  <FieldErrorMessage message={fieldState.error?.message} />
                </>
              )}
            />
          </Stack.Item>
          <Stack.Item>
            <Controller 
              control={form.control}
              name="maintenanceBegin"
              render={({ field, fieldState }) => (
                <MaintenanceBannerDateTimeFields 
                  type="start"
                  title='Maintenance'
                  defaultValue={data?.maintenanceBegin}
                  otherDatetime={form.watch('maintenanceEnd')}
                  value={field.value}
                  onChange={(value) => field.onChange(value)}
                />
              )}
            />
          </Stack.Item>
          <Stack.Item>
            <Controller 
              control={form.control}
              name="maintenanceEnd"
              render={({ field, fieldState }) => (
                <MaintenanceBannerDateTimeFields 
                  type='end'
                  title='Maintenance'
                  defaultValue={data?.maintenanceEnd}
                  otherDatetime={form.watch('maintenanceBegin')}
                  value={field.value}
                  onChange={(value) => field.onChange(value)}
                />
              )}
            />
          </Stack.Item>
          {errorMsg ? (
            <Stack.Item>
              <FieldErrorMessage message={errorMsg} />
            </Stack.Item>
          ) : null}
          <Stack.Item>
            <p style={{ lineHeight: '1.5' }}>Your timezone is used for the start and end times: <b>{dayjs.tz.guess()}</b></p>
          </Stack.Item>

        </Stack>
      </form>
    </ButtonModal>
  )
}