import { appDispatch } from '@/bootstrap/redux'
import { IntlProvider } from 'react-intl'
import { Card, Section } from '@/shared/components'
import { DefaultButton, MessageBarType, PrimaryButton, Toggle } from "@fluentui/react";
import { useEffect, useState } from "react";
import { getDefaultClientSettings } from "./ClientSettings.model";
import { useGetClientSettingsByAccountIdQuery } from '@/shared/api/services/legacyService';
import { useSaveClientSettingsMutation } from '@/shared/api/services/legacyService';
import { useDispatch } from 'react-redux';
import { createUIMessage } from '@/features/uimessages/redux/operations';
import ManageDashboard from './components/ManageDashboard';
import YourTeam from '@/shared/components/YourTeam';

export interface ClientSettingsProps {
  accountId: string;
  headerLabel: string;
}

export default function ClientSettings({ accountId, headerLabel }: ClientSettingsProps) {
  const dispatch = useDispatch<appDispatch>()
  const [ isFormDirty, setFormDirty ] = useState(false);
  const [clientSettings, setClientSettings] = useState(getDefaultClientSettings(accountId));
  const [ isDashboardInvalid, setDashboardInvalid ] = useState(false);
  const { data: clientSettingsModel } = useGetClientSettingsByAccountIdQuery(accountId);
  const [ saveSettings ] = useSaveClientSettingsMutation();

  useEffect(() => {
    if (clientSettingsModel) {
      setClientSettings({
        ...clientSettingsModel,
      });
      setDashboardInvalid(!clientSettingsModel.enableMainInvestmentDashboard && !clientSettingsModel.enableClientOnboardingDashboard)
    }
  }, [clientSettingsModel]);

  const onEnableClientContactDocumentTab = (event: React.FormEvent<HTMLElement | HTMLInputElement>, checked: boolean) => {
    setClientSettings({
      ...clientSettings,
      enableClientContactDocumentTab: checked || false,
    });
    setFormDirty(true);
  }

  const onEnableEmployeeDocumentTab = (event: React.FormEvent<HTMLElement | HTMLInputElement>, checked: boolean) => {
    setClientSettings({
      ...clientSettings,
      enableEmployeeDocumentTab: checked || false,
    });
    setFormDirty(true);
  }

  const onEnableMainInvestmentDashboard = (event: React.FormEvent<HTMLElement | HTMLInputElement>, checked: boolean) => {
    const updatedSettings = {
      ...clientSettings,
      enableMainInvestmentDashboard: checked || false,
    }
    setClientSettings(updatedSettings);
    setFormDirty(true);
    setDashboardInvalid(!updatedSettings.enableMainInvestmentDashboard && !updatedSettings.enableClientOnboardingDashboard);
  }

  const onEnableClientOnboardingDashboard = (event: React.FormEvent<HTMLElement | HTMLInputElement>, checked: boolean) => {
    const updatedSettings = {
      ...clientSettings,
      enableClientOnboardingDashboard: checked || false,
    }
    setClientSettings(updatedSettings);
    setFormDirty(true);
    setDashboardInvalid(!updatedSettings.enableMainInvestmentDashboard && !updatedSettings.enableClientOnboardingDashboard);
  }

  const onEnableClientDashboardPortfolioList = (event: React.FormEvent<HTMLElement | HTMLInputElement>, checked: boolean) => {
    setClientSettings({
      ...clientSettings,
      enableClientDashboardPortfolioList: checked || false,
    });
    setFormDirty(true);
  }

  const onEnableClientDashboardRecentTrades = (event: React.FormEvent<HTMLElement | HTMLInputElement>, checked: boolean) => {
    setClientSettings({
      ...clientSettings,
      enableClientDashboardRecentTrades: checked || false,
    });
    setFormDirty(true);
  }

  const onEnableClientDashboardMyCambridgeAssociatesTeam = (event: React.FormEvent<HTMLElement | HTMLInputElement>, checked: boolean) => {
    setClientSettings({
      ...clientSettings,
      enableClientDashboardMyCambridgeAssociatesTeam: checked || false,
    });
    setFormDirty(true);
  }

  const onEnableClientDashboardRecentDocuments = (event: React.FormEvent<HTMLElement | HTMLInputElement>, checked: boolean) => {
    setClientSettings({
      ...clientSettings,
      enableClientDashboardRecentDocuments: checked || false,
    });
    setFormDirty(true);
  }

  const onEnableClientDashboardFeaturedMedia = (event: React.FormEvent<HTMLElement | HTMLInputElement>, checked: boolean) => {
    setClientSettings({
      ...clientSettings,
      enableClientDashboardFeaturedMedia: checked || false,
    });
    setFormDirty(true);
  }

  const onEnableClientDashboardInsightList = (event: React.FormEvent<HTMLElement | HTMLInputElement>, checked: boolean) => {
    setClientSettings({
      ...clientSettings,
      enableClientDashboardInsightList: checked || false,
    });
    setFormDirty(true);
  }

  const onEnableClientDashboardEventList = (event: React.FormEvent<HTMLElement | HTMLInputElement>, checked: boolean) => {
    setClientSettings({
      ...clientSettings,
      enableClientDashboardEventList: checked || false,
    });
    setFormDirty(true);
  }

  const onEnablePortfolioDashboardMarketValue = (event: React.FormEvent<HTMLElement | HTMLInputElement>, checked: boolean) => {
    setClientSettings({
      ...clientSettings,
      enablePortfolioDashboardMarketValue: checked || false,
    });
    setFormDirty(true);
  }

  const onEnablePortfolioDashboardAllocation = (event: React.FormEvent<HTMLElement | HTMLInputElement>, checked: boolean) => {
    setClientSettings({
      ...clientSettings,
      enablePortfolioDashboardAllocation: checked || false,
    });
    setFormDirty(true);
  }

  const onEnablePortfolioDashboardPerformance = (event: React.FormEvent<HTMLElement | HTMLInputElement>, checked: boolean) => {
    setClientSettings({
      ...clientSettings,
      enablePortfolioDashboardPerformance: checked || false,
    });
    setFormDirty(true);
  }

  const onEnablePortfolioDashboardRecentTrades = (event: React.FormEvent<HTMLElement | HTMLInputElement>, checked: boolean) => {
    setClientSettings({
      ...clientSettings,
      enablePortfolioDashboardRecentTrades: checked || false,
    });
    setFormDirty(true);
  }

  const onEnablePortfolioDashboardTopHoldings = (event: React.FormEvent<HTMLElement | HTMLInputElement>, checked: boolean) => {
    setClientSettings({
      ...clientSettings,
      enablePortfolioDashboardTopHoldings: checked || false,
    });
    setFormDirty(true);
  }

  const onEnablePortfolioDashboardPerformanceTab = (event: React.FormEvent<HTMLElement | HTMLInputElement>, checked: boolean) => {
    setClientSettings({
      ...clientSettings,
      enablePortfolioDashboardPerformanceTab: checked || false,
    });
    setFormDirty(true);
  }

  const onEnablePortfolioDashboardDisclosuresTab = (event: React.FormEvent<HTMLElement | HTMLInputElement>, checked: boolean) => {
    setClientSettings({
      ...clientSettings,
      enablePortfolioDashboardDisclosuresTab: checked || false,
    });
    setFormDirty(true);
  }

  const onEnablePortfolioDashboardTradesTab = (event: React.FormEvent<HTMLElement | HTMLInputElement>, checked: boolean) => {
    setClientSettings({
      ...clientSettings,
      enablePortfolioDashboardTradesTab: checked || false,
    });
    setFormDirty(true);
  }

  const onResetClientSettings = () => {
    const originalSettings = {
      ...(clientSettingsModel || getDefaultClientSettings(accountId)),
    }
    setClientSettings(originalSettings);
    setFormDirty(false);
    setDashboardInvalid(!originalSettings.enableMainInvestmentDashboard && !originalSettings.enableClientOnboardingDashboard);
  }

  const onSaveClientSettings = () => {
    saveSettings(clientSettings).unwrap()
      .then(updatedClientSettings => {
        setClientSettings({
          ...updatedClientSettings,
        });
        setFormDirty(false);
        setDashboardInvalid(!updatedClientSettings.enableMainInvestmentDashboard && !updatedClientSettings.enableClientOnboardingDashboard);
        dispatch(createUIMessage({ 
          key: 'saveClientSettingsSuccess', 
          content: 'Saved Client Settings successfully.',
          messageBarType: MessageBarType.success,
        }));
      })
      .catch(error => {
        dispatch(createUIMessage({ 
          key: 'saveClientSettingsError', 
          content: 'There was an error saving Client Settings.',
          messageBarType: MessageBarType.error,
        }));
      });
  }

  return (
    <IntlProvider locale='en'>
      <Section className='client-configuration'>
        <Card ariaLabel={headerLabel} heading={headerLabel} className='client-configuration-card'>
          <ManageDashboard accountId={accountId} ></ManageDashboard>
          <div className='document-sharing'>
            <h3 className='header'>Document Sharing</h3>
            <div className='toggle-controller'>
              <Toggle label='Set Documents Tab visible for Client Contacts' inlineLabel checked={clientSettings.enableClientContactDocumentTab}
                onChange={onEnableClientContactDocumentTab} />
            </div>
            <div className='toggle-controller'>
              <Toggle label='Set Documents Tab visible for CA Employee' inlineLabel checked={clientSettings.enableEmployeeDocumentTab}
                onChange={onEnableEmployeeDocumentTab} />
            </div>
          </div>
          <div className={`dashboard-settings ${isDashboardInvalid ? 'error' : ''}`}>
            <h3 className='header'>Dashboard Settings</h3>
            <div className='toggle-controller'>
              <Toggle label='Enable Main Investment Dashboard' inlineLabel checked={clientSettings.enableMainInvestmentDashboard}
                onChange={onEnableMainInvestmentDashboard} />
            </div>
            <div className='toggle-controller'>
              <Toggle label='Enable Client Onboarding Dashboard' inlineLabel checked={clientSettings.enableClientOnboardingDashboard}
                onChange={onEnableClientOnboardingDashboard} />
            </div>
            {isDashboardInvalid ? (<div className='dashboard-settings-error'>Dashboard Settings required.</div>) : null}
          </div>
          <div className="main-investment-dashboard-settings">
            <h3 className='header'>Main Investment Dashboard Settings</h3>
            <div className="client-dashboard-settings">
              <h3 className='header'>Client Dashboard Settings</h3>
              <div className='toggle-controller'>
                <Toggle label='Enable My Portfolios Widget' inlineLabel checked={clientSettings.enableClientDashboardPortfolioList}
                  onChange={onEnableClientDashboardPortfolioList} />
              </div>
              <div className='toggle-controller'>
                <Toggle label='Enable Recent Trades Widget' disabled={true} inlineLabel checked={clientSettings.enableClientDashboardRecentTrades}
                  onChange={onEnableClientDashboardRecentTrades} />
              </div>
              <div className='toggle-controller'>
                <Toggle label='Enable My Cambridge Associates Team Widget' inlineLabel checked={clientSettings.enableClientDashboardMyCambridgeAssociatesTeam}
                  onChange={onEnableClientDashboardMyCambridgeAssociatesTeam} />
              </div>
              <div className='toggle-controller'>
                <Toggle label='Enable Recent Documents Widget' inlineLabel checked={clientSettings.enableClientDashboardRecentDocuments}
                  onChange={onEnableClientDashboardRecentDocuments} />
              </div>
              <div className='toggle-controller'>
                <Toggle label='Enable Featured Media Widget' inlineLabel checked={clientSettings.enableClientDashboardFeaturedMedia}
                  onChange={onEnableClientDashboardFeaturedMedia} />
              </div>
              <div className='toggle-controller'>
                <Toggle label='Enable Insights' inlineLabel checked={clientSettings.enableClientDashboardInsightList}
                  onChange={onEnableClientDashboardInsightList} />
              </div>
              <div className='toggle-controller'>
                <Toggle label='Enable Events and Recordings' inlineLabel checked={clientSettings.enableClientDashboardEventList}
                  onChange={onEnableClientDashboardEventList} />
              </div>
            </div>
            <div className="portfolio-dashboard-settings">
              <h3 className='header'>Portfolio Dashboard Settings</h3>
              <div className='toggle-controller'>
                <Toggle label='Enable Market Value Widget' inlineLabel checked={clientSettings.enablePortfolioDashboardMarketValue}
                  onChange={onEnablePortfolioDashboardMarketValue} />
              </div>
              <div className='toggle-controller'>
                <Toggle label='Enable Allocation Widget' inlineLabel checked={clientSettings.enablePortfolioDashboardAllocation}
                  onChange={onEnablePortfolioDashboardAllocation} />
              </div>
              <div className='toggle-controller'>
                <Toggle label='Enable Performance Widget' inlineLabel checked={clientSettings.enablePortfolioDashboardPerformance}
                  onChange={onEnablePortfolioDashboardPerformance} />
              </div>
              <div className='toggle-controller'>
                <Toggle label='Enable Recent Trades Widget' disabled={true} inlineLabel checked={clientSettings.enablePortfolioDashboardRecentTrades}
                  onChange={onEnablePortfolioDashboardRecentTrades} />
              </div>
              <div className='toggle-controller'>
                <Toggle label='Enable Top 5 Total Portfolio Holdings Widget' inlineLabel checked={clientSettings.enablePortfolioDashboardTopHoldings}
                  onChange={onEnablePortfolioDashboardTopHoldings} />
              </div>
              <div className='toggle-controller'>
                <Toggle label='Enable Performance Tab' inlineLabel checked={clientSettings.enablePortfolioDashboardPerformanceTab}
                  onChange={onEnablePortfolioDashboardPerformanceTab} />
              </div>
              <div className='toggle-controller'>
                <Toggle label='Enable Disclosures Tab' inlineLabel checked={clientSettings.enablePortfolioDashboardDisclosuresTab}
                  onChange={onEnablePortfolioDashboardDisclosuresTab} />
              </div>
              <div className='toggle-controller'>
                <Toggle label='Enable Trades Tab' disabled={true} inlineLabel checked={clientSettings.enablePortfolioDashboardTradesTab}
                  onChange={onEnablePortfolioDashboardTradesTab} />
              </div>
            </div>
          </div>
          <div className='action-buttons'>
            <div className='edit-cambridge-associates-team'>
              <YourTeam accountId={accountId} buttonLabel='Edit Cambridge Associates Team' modalLabel='Edit Cambridge Associates Team'  />
            </div>
            <div className='form-buttons'>
              <DefaultButton className='cancel-button' onClick={onResetClientSettings}>Cancel</DefaultButton>
              <PrimaryButton className='save-button' disabled={isDashboardInvalid || !isFormDirty} onClick={onSaveClientSettings}>Save</PrimaryButton>
            </div>
          </div>
        </Card>
      </Section>
    </IntlProvider>
  );
}
