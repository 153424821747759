import { nanoid } from '@reduxjs/toolkit'
import { useGetEvents, useGetFeaturedEventQuery } from '@/shared/api/services/eventServices'
import EventSideBarSectionItem from './components/EventSideBarSectionItem/EventSideBarSectionItem'
import { GeneralError, Pagination, SkeletonSideBarSectionItem } from '@/shared/components'
import { useState } from 'react'
import dayjs from '@/bootstrap/dayjs'

const PAGE_SIZE = 10
const PAGINATION_PARAM = 'eventList'

const EventList = () => {
  const [currentPage, setCurrentPage] = useState(Number(new URLSearchParams(window.location.search).get(PAGINATION_PARAM)) || 1)
  const getFeaturedState = useGetFeaturedEventQuery()
  const eventsQuery = useGetEvents({
    page: currentPage,
    pageSize: PAGE_SIZE
  })

  const handlePageChange = (newPage: number) => { setCurrentPage(newPage) }

  const renderUpcomingEventsContent = () => {
    const isLoading = (eventsQuery && eventsQuery.isRecentEventsFetching) || false
    const data = (eventsQuery && eventsQuery.data && eventsQuery.data.recentEvents && eventsQuery.data.recentEvents.items) || []
    const filteredData = (data && data.filter((event) => event.id !== getFeaturedState?.data?.id)) || []

    return (
      <>
        <h3 className='c-events__list__title' id='upcoming-events-title'>Upcoming</h3>
        <ol aria-live='polite' aria-busy={isLoading} className='c-events__list' aria-labelledby='upcoming-events-title'>
          {isLoading ? (
            [...Array(10).keys()].map(k => {
              return (
                <SkeletonSideBarSectionItem renderThirdRow key={nanoid(4)} />
              )
            })
          ) : (
            filteredData && filteredData.length ? filteredData.map((event) => (
              <EventSideBarSectionItem 
                key={event.id}
                title={event.title}
                datetime={dayjs.utc(event.startEventDate).tz(event.timezoneName).toDate()}
                endDatetime={dayjs.utc(event.endEventDate).tz(event.timezoneName).toDate()}
                eventType={event.eventType as unknown as number}
                href={event.eventUrl}
              />
            )) : (
              <div className='c-events__list__not_found'>There are no upcoming events at this time.</div>
            )
          )}
        </ol>
      </>
    )
  }

  const renderHistoricalEventsContent = () => {
    const isLoading = (eventsQuery && eventsQuery.isHistoricalEventsFetching) || false
    const data = (eventsQuery && eventsQuery.data && eventsQuery.data.historicalEvents && eventsQuery.data.historicalEvents.items) || []
    const filteredData = (data && data.filter((event) => event.id !== getFeaturedState?.data?.id)) || []

    return (
      <>
        <h3 className='c-events__list__title' id='historical-events-title'>Historical</h3>
        <ol aria-live='polite' aria-busy={isLoading} className='c-events__list' aria-labelledby='historical-events-title'>
          {isLoading ? (
            [...Array(10).keys()].map(k => {
              return (
                <SkeletonSideBarSectionItem renderThirdRow key={nanoid(4)} />
              )
            })
          ) : (
            filteredData && filteredData.length ? filteredData.map((event) => (
              <EventSideBarSectionItem 
                key={event.id}
                title={event.title}
                datetime={dayjs.utc(event.startEventDate).tz(event.timezoneName).toDate()}
                endDatetime={dayjs.utc(event.endEventDate).tz(event.timezoneName).toDate()}
                eventType={event.eventType as unknown as number}
                href={event.eventUrl}
              />
            )) : (
              <div className='c-events__list__not_found'>There are no historical events at this time.</div>
            )
          )}
        </ol>
      </>
    )
  }

  if (eventsQuery.isError) {
    return (
      <GeneralError onClick={eventsQuery.refetch} title='The list of events could not be loaded' />
    )
  }

  return (
    <>
      <>
        {renderUpcomingEventsContent()}
      </>
      <>
        {renderHistoricalEventsContent()}
      </>
      { !eventsQuery.isLoading ? <Pagination paramName={PAGINATION_PARAM} onPageChange={handlePageChange} maxShown={PAGE_SIZE} listCount={eventsQuery?.data?.historicalEvents?.count} /> : null }
    </>
  )
}

export default EventList