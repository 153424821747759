import { IEvent } from '@/shared/types/swagger'
import { EEventType } from '../../types/event'

type EventTypeCellProps = {
  event: IEvent
}

const EventTypeCell = ({ event }: EventTypeCellProps) => {
  return (
    <span>{EEventType[event.eventType]}</span>
  )
}

export default EventTypeCell