import { DEFAULT_FULL_DATE_FORMAT, formatDate } from '@/lib/common/services/date/DateService'

export interface DateItemProps {
  item: string,
}

export default function DateItem({ item }: DateItemProps) {
  const date = new Date(item)
  const datetime = formatDate(date, DEFAULT_FULL_DATE_FORMAT)
  return (<>{datetime}</>)
}
