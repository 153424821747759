import { sanitize } from "dompurify";
import { useMemo } from "react";

export interface DynamicHtmlContainerProps {
  html: string;
}

const dynamicHtmlSanitize = (htmlValue: string) => {
  return sanitize(htmlValue || '', {
    ADD_ATTR: [
      'target'
    ]
  })
}

const DynamicHtmlContainer = ({ html }: DynamicHtmlContainerProps) => {
  const safeSourceHtml = dynamicHtmlSanitize(html)

  const getMobileTableData = (table: HTMLTableElement) => {
    const ths = table.querySelectorAll('th')
    const headData = Array.from(ths).map((th) => th.innerText)
    const trs: NodeListOf<HTMLTableRowElement> = table.querySelectorAll('tbody > tr')
    const rowCount = trs.length
    const columnCount = ths.length
    const bodyData: Array<{key: string, cells: Array<string>}> = 
      [...Array(rowCount)]
        .map(() => ({ key: '', cells: [...Array(columnCount)] }))

    for (let i = 0; i < bodyData.length; i++) {
      const rowData = bodyData[i].cells
      const rowCells = trs[i].querySelectorAll('td')

      for (let j = 0; j < rowCells.length; j++) {
        const cellElement = rowCells[j]
        const cellDataIndex = rowData.findIndex((_cellData) => _cellData === undefined)

        if (j === 0 && !bodyData[i].key) {
          bodyData[i].key = cellElement.textContent.replace(/\s/g, '-').toLowerCase()
        }

        const cellRowspan = cellElement.rowSpan || 1
        if (cellRowspan > 1) {
          for (let rowIndex = i + 1; rowIndex < cellRowspan + i; rowIndex++) {
            bodyData[rowIndex].cells[cellDataIndex] = cellElement.innerHTML || ''
          }
        }

        if (!bodyData[i][cellDataIndex]) {
          bodyData[i].cells[cellDataIndex] = cellElement.innerHTML
        }
      }
    }

    return {
      head: headData,
      body: bodyData
    }
  }

  const appendMobileTable = (table: HTMLTableElement, tableContainer: HTMLElement) => {
    const mobileTableData = getMobileTableData(table)

    if (mobileTableData) {
      const tableBody = mobileTableData.body || []
      const tableHead = mobileTableData.head || []

      tableBody.forEach((b, i) => {
        const cells = b.cells || []

        const rowContainer = document.createElement('div')
        rowContainer.classList.add('c-datagrid-mobile__row')

        cells.forEach((c, j) => {
          const cellContainer = document.createElement('div')
          cellContainer.classList.add('c-datagrid-mobile__cell')

          const cellHead = document.createElement('h5')
          cellHead.classList.add('c-datagrid-mobile__cell-heading')
          cellHead.innerHTML = tableHead[j] || ''

          const cellValue = document.createElement('span')
          cellValue.innerHTML = c || ''

          cellContainer.appendChild(cellHead)
          cellContainer.appendChild(cellValue)

          rowContainer.appendChild(cellContainer)
        })

        tableContainer.appendChild(rowContainer)
      })
    }
  }

  const processedHtml = useMemo(() => {
    const container = document.createElement('DIV')
    container.innerHTML = safeSourceHtml
    
    const tables = container.querySelectorAll('table')

    if (tables && tables.length) {
      for (let index = 0; index < tables.length; index++) {
        const table = tables.item(index)

        if (table) {
          const parentContainer = table.parentElement

          if (parentContainer) {
            const desktopTableContainer = document.createElement('div')
            const mobileTableContainer = document.createElement('div')

            desktopTableContainer.classList.add('c-datagrid')
            mobileTableContainer.classList.add('c-datagrid-mobile')

            // adds table containers to parent container
            parentContainer.insertBefore(desktopTableContainer, table)
            parentContainer.insertBefore(mobileTableContainer, desktopTableContainer)

            // adds dektop table version
            desktopTableContainer.appendChild(table)

            // adds mobile table version
            appendMobileTable(table, mobileTableContainer)
          }
        }
      }
    }

    return container.innerHTML
  }, [safeSourceHtml])

  return (
    <div className="dynamic-html-container" dangerouslySetInnerHTML={{ __html: dynamicHtmlSanitize(processedHtml) }} />
  )
}

export default DynamicHtmlContainer
