import * as Screens from '@/screens'
import { RouteComponentProps } from "react-router-dom"

const PreviewDashboard = (props: RouteComponentProps<{ accountId: string }>) => {
  const { match } = props
  const { accountId } = match.params
  return (
    <Screens.Dashboard previewAccountId={accountId} />
  )
}

export default PreviewDashboard