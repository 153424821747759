export const PROD_CONSTANTS = {
    DATA_DOG_SERVICE: 'client-portal-prod',
    DATA_DOG_APP_ID: 'ZWNmZDFlMDQtZWZiZS00OTgwLWIyY2UtNzZlYzk4OGZkYWFk',
    DATA_DOG_CLIENT_TOKEN: 'cHViOTBmODlkMTExZDZjYTcyODBmODY1NWZkYjY2OWM5YTM=',
    MSAL_TENANT: 'caclientportal',
    MSAL_CLIENT_ID: '40753c05-e14b-4b8f-b30b-2ea36809d93b',
    MSAL_REDIRECT_URL: 'https://clientportal.cambridgeassociates.com',
    API_BASE_URL: 'https://api.clientportal.cambridgeassociates.com/api/exp',
    EMMA_API_BASE_URL: 'https://api.clientportal.cambridgeassociates.com/api/emma',
    IMAGE_BUCKET_BASE_PATH: 'https://clientportal.cambridgeassociates.com/img/',
    VIDEO_MAX_UPLOAD_SIZE: 10737418240,
    ILEVEL_CA_EMPLOYEE_URL: 'https://launcher.myapps.microsoft.com/api/signin/9cfaddc9-f0b0-4858-b30f-a4459b99c205?tenantId=4b1c6b54-4031-481e-b9c8-9000b4e3b2c2&relaystate=https://clients.ilevelsolutions.com?idp=sam',
    ILEVEL_CLIENT_CONTACT_URL: 'https://CAClientPortal.b2clogin.com/CAClientPortal.onmicrosoft.com/B2C_1A_SAML2_IdP_Init_signup_signin/generic/login?EntityId=https://sam.ihsmarkit.com/sso/Cambridge_Associates_External_PROD-SCP01l5nNa&RelayState=https://clients.ilevelsolutions.com?idp=sam',
    PW_CA_EMPLOYEE_URL: 'https://prod-f.pw.cambridgeassociates.cloud/',
    PW_CLIENT_CONTACT_LINK_URL: 'https://prod-f.pw.cambridgeassociates.cloud/',
    UPLOAD_NOTIFICATION_EMAIL_DELAY_IN_MINUTES: 5,
    MORE_LINKS: {
        'PRSnapshot': 'https://members.cambridgeassociates.com/memberprofile/poolretsnapshot/snapsessionsetter.aspx?ID=93247',
        'AnnualManagerReport': 'https://members.cambridgeassociates.com/managers/managernotifications/reports.asp',
        'IndexSnapshots': 'https://members.cambridgeassociates.com/Markets/Members_Markets_Current.asp',
        'ManagerNotices': 'https://members.cambridgeassociates.com/managers/managernotifications/alerts.asp',
        'ExhibitFinder': 'https://members.cambridgeassociates.com/Markets/Members_Markets_Exhibit.asp',
        'MMOS': 'https://members.cambridgeassociates.com/managers/onestop/external/OneStop_External.asp?cmd=keywordsearch&Section=MMOS',
        'PreliminaryPerformanceBulletin': 'https://members.cambridgeassociates.com/MemberProfile/instbulletin.asp?intPage=0',
        'PIMOS': 'https://members.cambridgeassociates.com/memberindex.asp?Type=Grant&App=SSPIUHF09239F7RS',
        'MarketIndexDescriptions': 'https://members.cambridgeassociates.com/Markets/Indexdefs.asp',
        'ProprietaryPIB': 'https://www.cambridgeassociates.com/benchmarks/',
        'AnnualInvestmentPRSurvey': 'https://members.cambridgeassociates.com/FoundationSurvey.asp',
        'HistoricalPIB': 'https://members.cambridgeassociates.com/memberprofile/historicalbenchmark/',
        'InvestmentPRQuarterlySurvey': 'https://members.cambridgeassociates.com/memberprofile/members_profile_questionnaire.asp',
        'ClientSnapshot': 'https://members.cambridgeassociates.com/MemberProfile/MemberOneStop/Shared/client_snapshot.asp',
        'MPME': 'https://clients.cambridgeassociates.com/#/mpme',
        'OpticaResearchPrivate': 'https://researchnavigator.cambridgeassociates.com/RN/private-investments',
        'OpticaResearchPublic': 'https://researchnavigator.cambridgeassociates.com/RN/public-investments',
        'OpticaBenchmark': 'https://pibenchmarkingca.cambridgeassociates.com',
        'OpticaPeers': 'https://members.cambridgeassociates.com/MemberProfile/MemberOneStop/External/onestop_external.asp',
    },
};
