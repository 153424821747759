/* eslint-disable @typescript-eslint/no-unused-vars */
import { memo } from 'react'
import { useAddEventMutation, useUpdateEventMutation } from '@/shared/api/services/eventServices'
import { Stack, DefaultButton, PrimaryButton } from '@fluentui/react'

type CreateEventModalButtonsProps = {
  isEditing: boolean
  goBack: () => void,
}

const CreateEventModalButtons = ({ isEditing, goBack }: CreateEventModalButtonsProps) => {
    const [, { isLoading: addIsLoading }] = useAddEventMutation({ fixedCacheKey: 'add-event-post' })
    const [, { isLoading: updateIsLoading }] = useUpdateEventMutation({ fixedCacheKey: 'update-event-post' })
    return (
      <Stack tokens={{ childrenGap: 16 }} horizontal horizontalAlign='end'>    
        <DefaultButton onClick={goBack}>Cancel</DefaultButton>
        <PrimaryButton disabled={addIsLoading || updateIsLoading} type="submit">Save</PrimaryButton>
      </Stack>
  )
}
export default memo(CreateEventModalButtons)