import dayjs from "dayjs";

export enum EDateFormat {
  /** @example 'Aug 16, 2018' */
  'short' = 'll', 
  /** @example 'Sunday, 8:02 PM EST' */
  'long-tz' = 'dddd, LT z',
  /** @example 'August 16, 2018' */
  'long' = 'LL',
  /** @example 'August 16, 2018 - 8:02 PM EST' */
  'full-tz' = 'LL - LT z',
}

export const getUTCTZDatetime = (dt: string) => dayjs.utc(dt).tz(dayjs.tz.guess())
