interface Department {
  id: number;
  titleKey: string;
}

const departments: Record<number, Department> = {
  1: {
    id: 1,
    titleKey: 'globalbizdev'
  },
  2: {
    id: 2,
    titleKey: 'investment'
  },
  3: {
    id: 3,
    titleKey: 'clientServices'
  },
  4: {
    id: 4,
    titleKey: 'onboardingCoordinator'
  }
}

export default departments