import { Checkbox } from '@fluentui/react'

type Item = unknown

type ListCheckboxProps = {
  item: Item,
  index: number
  onChange: (item: unknown, index: number) => void
  isChecked: boolean
  disabled?: boolean
  isRounded?: boolean
}

const ModalCheckbox = ({ 
  item,
  index,
  onChange,
  isChecked,
  disabled,
  isRounded
  }: ListCheckboxProps) => {

  const handleToggle = () => { onChange(item, index) }

  return (
    <Checkbox
      styles={{
        root: {
          '& .ms-Checkbox-label': {
            '& .ms-Checkbox-checkbox': {
              ...isRounded && { borderRadius: '50%' }
            }
          }
        }
      }}
      onChange={handleToggle}
      checked={isChecked}
      disabled={disabled}
    />
  )  
}

export default ModalCheckbox