import { useGetAccountByIdQuery } from '@/shared/api/services/crmService'
import { IAccount } from '@/shared/types/swagger'
import { useSelector } from 'react-redux'
import { selectAccountId } from '../redux/authSelectors'

export default function useAccountName(_accountid?: string): { accountName: string, isLoading: boolean } {
  const accountid = useSelector(selectAccountId)
  const skip = !accountid && !_accountid
  const { data, isLoading } = useGetAccountByIdQuery(_accountid || accountid, { skip })
  const { accountName } = (data ?? {}) as IAccount

  return { 
    accountName, 
    isLoading: isLoading && !skip 
  }
}