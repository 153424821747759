import cn from 'classnames'
import { useEffect, useState } from 'react'
import { getHTMLContentWithEllipses } from '../../helpers'
interface Props {
  /** html string - sanitized before render */
  text: string;
  className?: string;
}

export default function InsightBody({ text, className }: Props) {
  const [body, setBody] = useState<string>('')

  useEffect(() => {
    setBody(getHTMLContentWithEllipses(text))
  }, [text])

  return (
    <p 
      className={cn('c-insight__body', className)}
      dangerouslySetInnerHTML={{ __html: body }}
    />
  )
}
