import { ReactElement, memo, HTMLAttributeAnchorTarget } from 'react'
import dayjs from '@/bootstrap/dayjs'
import cn from 'classnames'
import useDateParts from './hooks/useDateParts'
import SideBarSectionItemDateLHS from './components/SideBarSectionItemDateLHS'
import formatDateRange from '@/shared/helpers/formatDateRange'
import { EDateFormat } from '@/shared/types/date'

export interface SideBarSectionItemProps {
  datetime: Date;
  renderLHS?: () => ReactElement;
  title: string;
  href: string;
  target?: HTMLAttributeAnchorTarget;
  children?: ReactElement | ReactElement[];
  className?: string;
  endDatetime?: Date;
  dateFormat: EDateFormat;
}

function SideBarSectionItem(props: SideBarSectionItemProps) {
  const { href, title, target, dateFormat = EDateFormat.long, datetime, renderLHS, children, className, endDatetime } = props

  const dtDisplay = formatDateRange(dateFormat, datetime, endDatetime)

  return (
    <a 
      className={cn('c-ssi', className) }
      href={href} 
      target={target}
      aria-label={/*TODO: add more descriptive info for label*/`${title}`}
    >
      <span className={cn('c-ssi__LHS', { '--custom': Boolean(renderLHS) })}>
        {renderLHS ? renderLHS() : <SideBarSectionItemDateLHS datetime={datetime} /> }
      </span>
      <span className="c-ssi__RHS">
        <h3 className="c-ssi__RHS-title">{title}</h3>
        <time className="c-ssi__RHS-dt" dateTime={dtDisplay}>{dtDisplay}</time>
        {children}
      </span>
    </a>
  )
}

export default memo(SideBarSectionItem)