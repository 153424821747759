import { RouteComponentProps } from 'react-router'
import ClientsTable from '@/features/client/components/ClientsTable'
import MainLayout from '@/shared/components/Layout/MainLayout'
import './clientCard.scss'

export default function Clients(props: RouteComponentProps) {
  return (
    <MainLayout id="clients" className='client-card'>
      <ClientsTable />
    </MainLayout>
  )
}
