import { memo, useState, useCallback } from 'react'
import { Icon, AnimationClassNames } from '@fluentui/react'
import cn from 'classnames'
import useIsMobile from '@/shared/hooks/useIsMobile';

interface AccordionItem {
  title: string;
  id: string | number;
  /** html string */
  content: string;
}

interface Props {
  items: Array<AccordionItem>;
  ariaLabelledBy: string;
}

function Accordion(props: Props) {
  const { items, ariaLabelledBy } = props
  const [openId, setOpenId] = useState<string>(null)
  const isMobile = useIsMobile('xs')

  const handleExpandClick: React.MouseEventHandler<HTMLButtonElement> = useCallback(
    (e) => {
      const id = e.currentTarget.getAttribute('data-collapseid')
      const shouldCloseAll = id === openId
      setOpenId(shouldCloseAll ? null : id)
    }, [openId, setOpenId]
  )

  return (
    <div aria-labelledby={ariaLabelledBy}
      className={cn('c-accordion', { '--mobile': isMobile })}>
      {items.map(({ id, title, content }) => {
        const expanded = openId === id
        const controlId = `accordion-control-${id}`
        const panelId = `accordion-panel-${id}`

        return (
          <div className={cn('c-accordion__item', { '--expanded': expanded })} key={`accordionitem-${id}`}>
            <button
              className="c-accordion__item-header"
              onClick={handleExpandClick}
              data-collapseid={id}
              aria-expanded={expanded}
              aria-controls={panelId}
              id={controlId}
            >
              <Icon
                className="c-accordion__item-expand"
                iconName={expanded ? 'ChevronDown' : 'ChevronRight'}
              />
              <h3 className="c-accordion__item-title">{title}</h3>
            </button>
            {id === openId ? (
              <div
                role="region"
                id={panelId}
                aria-labelledby={controlId}
                className={cn('c-accordion__item-content', AnimationClassNames.slideDownIn20)}
                dangerouslySetInnerHTML={{ __html: content }}
              />
            ) : null}
          </div>
        )
      })}
    </div>
  )
}

export default memo(Accordion)