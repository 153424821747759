import { IAccount } from '@/shared/types/swagger'
import { Stack, Link } from '@fluentui/react'
import { useTranslation } from 'react-i18next'
import { Link as RRLink } from 'react-router-dom'

export default function ActionsCell(client: IAccount) {
  const { t } = useTranslation()
  const { accountId } = client
  return (
    <Stack horizontal horizontalAlign="end" className="c-clients-table__actions" margin-left='50px'>
      <Link as={RRLink} to={`/clients/${accountId}`}>{t('common:view')}</Link>
    </Stack>
  )
}
