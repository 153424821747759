import { UAT_CONSTANTS } from './environment.constants.uat';
import { PROD_CONSTANTS } from './environment.constants.prod';
import { DEV_CONSTANTS } from './environment.constants.dev';

// environment properties from hostname
const { location  } = window;
const { hostname  } = location;
const clientPortalHostname = 'clientportal.cambridgeassociates.com';
const isProd = hostname === clientPortalHostname;
const isSandbox = !isProd && ['localhost', '127.0.0.1'].includes(hostname);
const environment = isProd ? 'prod' : (isSandbox ? 'dev' : hostname.split(`.${clientPortalHostname}`)[0]);


// gets specific environment property
const getEnvironmentProperty = (targetEnvironment: 'dev' | 'uat' | 'prod', property: string): any => {
    const environmentMap = {
        dev: DEV_CONSTANTS,
        uat: UAT_CONSTANTS,
        prod: PROD_CONSTANTS,
    };
    return environmentMap[targetEnvironment][property] || '';
};

// sandbox environment flag
export const IS_SANDBOX_ENVIRONMENT = isSandbox;


// deployed env
export const DEPLOYED_ENV = environment as 'dev' | 'uat' | 'prod';


// lower environment flag
export const IS_LOWER_ENVIRONMENT = environment !== 'prod';

// DATA DOG properties
export const DATA_DOG_SITE = 'datadoghq.com';
export const DATA_DOG_VERSION = '1.0';
export const DATA_DOG_SERVICE = getEnvironmentProperty(DEPLOYED_ENV, 'DATA_DOG_SERVICE');
export const DATA_DOG_APP_ID = atob(getEnvironmentProperty(DEPLOYED_ENV, 'DATA_DOG_APP_ID'));
export const DATA_DOG_CLIENT_TOKEN = atob(getEnvironmentProperty(DEPLOYED_ENV, 'DATA_DOG_CLIENT_TOKEN'));


// MSAL properties
export const MSAL_TENANT = getEnvironmentProperty(DEPLOYED_ENV, 'MSAL_TENANT');
export const MSAL_CLIENT_ID = getEnvironmentProperty(DEPLOYED_ENV, 'MSAL_CLIENT_ID');
export const MSAL_REDIRECT_URL = isSandbox ? 'http://localhost:3000' : getEnvironmentProperty(DEPLOYED_ENV, 'MSAL_REDIRECT_URL');


// API properties
export const API_BASE_URL = isSandbox ? 'http://localhost:5000/api/exp' : getEnvironmentProperty(DEPLOYED_ENV, 'API_BASE_URL');
export const EMMA_API_BASE_URL = isSandbox ? 'http://localhost:8080/api/emma' : getEnvironmentProperty(DEPLOYED_ENV, 'EMMA_API_BASE_URL');


// iLevel links
export const ILEVEL_CA_EMPLOYEE_URL = getEnvironmentProperty(DEPLOYED_ENV, 'ILEVEL_CA_EMPLOYEE_URL');
export const ILEVEL_CLIENT_CONTACT_URL = getEnvironmentProperty(DEPLOYED_ENV, 'ILEVEL_CLIENT_CONTACT_URL');


// PW links
export const PW_CA_EMPLOYEE_URL = getEnvironmentProperty(DEPLOYED_ENV, 'PW_CA_EMPLOYEE_URL');
export const PW_CLIENT_CONTACT_LINK_URL = getEnvironmentProperty(DEPLOYED_ENV, 'PW_CLIENT_CONTACT_LINK_URL');


// more links
export const MORE_LINKS = getEnvironmentProperty(DEPLOYED_ENV, 'MORE_LINKS');


// document upload notification delay in minutes
export const UPLOAD_NOTIFICATION_EMAIL_DELAY_IN_MINUTES = getEnvironmentProperty(DEPLOYED_ENV, 'UPLOAD_NOTIFICATION_EMAIL_DELAY_IN_MINUTES');


// image bucket properties
export const IMAGE_BUCKET_BASE_PATH = isSandbox ? 'https://dev.clientportal.cambridgeassociates.com/img/'
    : getEnvironmentProperty(DEPLOYED_ENV, 'IMAGE_BUCKET_BASE_PATH');


// media content upload limits
export const VIDEO_MAX_UPLOAD_SIZE = getEnvironmentProperty(DEPLOYED_ENV, 'VIDEO_MAX_UPLOAD_SIZE');
export const IMAGE_MAX_SIZE = 5242880;
export const AUDIO_MAX_SIZE = 104857600;

export const AG_GRID_LICENSE = 'VXNpbmdfdGhpc197QUdfR3JpZH1fRW50ZXJwcmlzZV9rZXlfe0FHLTA1NTEzN31faW5fZXhjZXNzX29'
    + 'mX3RoZV9saWNlbmNlX2dyYW50ZWRfaXNfbm90X3Blcm1pdHRlZF9fX1BsZWFzZV9yZXBvcnRfbWlzdXNlX3RvX2xlZ2FsQGFnLWdyaWQuY29'
    + 'tX19fRm9yX2hlbHBfd2l0aF9jaGFuZ2luZ190aGlzX2tleV9wbGVhc2VfY29udGFjdF9pbmZvQGFnLWdyaWQuY29tX19fe0NhbWJyaWRnZV9'
    + 'Bc3NvY2lhdGVzLF9MTEN9X2lzX2dyYW50ZWRfYV97U2luZ2xlX0FwcGxpY2F0aW9ufV9EZXZlbG9wZXJfTGljZW5zZV9mb3JfdGhlX2FwcGx'
    + 'pY2F0aW9uX3tjYW1icmlkZ2VfYXNzb2NpYXRlc19hY2VfYXBwbGljYXRpb25zfV9vbmx5X2Zvcl97OH1fRnJvbnQtRW5kX0phdmFTY3JpcHR'
    + 'fZGV2ZWxvcGVyc19fX0FsbF9Gcm9udC1FbmRfSmF2YVNjcmlwdF9kZXZlbG9wZXJzX3dvcmtpbmdfb25fe2NhbWJyaWRnZV9hc3NvY2lhdGV'
    + 'zX2FjZV9hcHBsaWNhdGlvbnN9X25lZWRfdG9fYmVfbGljZW5zZWRfX197Y2FtYnJpZGdlX2Fzc29jaWF0ZXNfYWNlX2FwcGxpY2F0aW9uc31'
    + 'faGFzX25vdF9iZWVuX2dyYW50ZWRfYV9EZXBsb3ltZW50X0xpY2Vuc2VfQWRkLW9uX19fVGhpc19rZXlfd29ya3Nfd2l0aF97QUdfR3JpZH1'
    + 'fRW50ZXJwcmlzZV92ZXJzaW9uc19yZWxlYXNlZF9iZWZvcmVfezE2X01hcmNoXzIwMjV9X19fX1t2M11fWzAxXV9NVGMwTWpBNE16SXdNREF'
    + '3TUE9PTQ3NjNlOWQwNWM2YmJlMTM4NzMxNjYxNjdhMDEzMWFj'
