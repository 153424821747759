import { memo } from 'react'
import cn from 'classnames'
import Search from '@/features/insight/components/Search'

type SharedSplitLayoutProps = {
  headerTitle: string
  hasSearch?: boolean
  handleSearch?: (query : string) => void
  leftContent: JSX.Element | JSX.Element[]
  rightContent: JSX.Element | JSX.Element[]
  className?: string
}

interface SplitLayoutWithHeader extends SharedSplitLayoutProps {
  renderHeader?: true
}

interface SplitLayoutWithoutHeader extends Omit<SharedSplitLayoutProps, 'headerTitle'> {
  renderHeader: false
  headerTitle?: never
}

const SplitLayout = ({ 
  headerTitle, 
  hasSearch = false, 
  handleSearch, 
  leftContent, 
  rightContent, 
  className,
  renderHeader = true 
}: SplitLayoutWithHeader | SplitLayoutWithoutHeader) => {
  return (
    <div className={cn('c-split-layout', className)}>
      {
        renderHeader ? (
          <header className={`c-split-layout__header ${hasSearch && 'c-split-layout__header--split'}`}>
            <h1 className='c-split-layout__header__title'>{headerTitle}</h1>
            {hasSearch && <Search handleSearch={handleSearch} />}
          </header>
        ) : null
      }
      <div className='c-split-layout__content'>
        <section className='c-split-layout__content__left'>{leftContent}</section>
        <section className='c-split-layout__content__right'>{rightContent}</section>
      </div>
    </div>
  )
}

export default memo(SplitLayout)