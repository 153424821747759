import { useGetAllNotificationsQuery } from '@/shared/api/services/notificationService'
import { ErrorMessage, GeneralError, Table } from '@/shared/components'
import { nanoid } from '@reduxjs/toolkit'
import { memo, useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import notificationTableColumns from '../../constants/notificationTableColumns'

type NotificationTableProps = {
  isReadTable: boolean
}

const mockNotifications = [
  {
    author: 'John Doe',
    description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
    hasBeenRead: false,
    id: '1',
    time: '2022-07-29T14:15:18.905Z',
    title: 'Lorem Ipsum',
  },
  {
    author: 'John Dont',
    description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
    hasBeenRead: true,
    id: '2',
    time: '2022-07-29T14:15:18.905Z',
    title: 'Lorem Ipsum',
  }
]

const NotificationTable = ({ isReadTable }: NotificationTableProps) => {
  const [hasError, setHasError] = useState(false)
  const { t } = useTranslation('notification')
  const { data, isLoading, isUninitialized, isError, refetch } = useGetAllNotificationsQuery(null, {
    selectFromResult: (result) => {
      if (isReadTable) return { ...result,  data: mockNotifications.filter(n => n.hasBeenRead) }
      else return { ...result, data: mockNotifications.filter(n => !n.hasBeenRead) }
    }
  })

  const setErrorStatus = useCallback((value: boolean) => {
   if (value !== hasError) { setHasError(value) }
  }, [hasError])

  const columns = useMemo(() => notificationTableColumns(isReadTable, setErrorStatus), [isReadTable, setErrorStatus])
  const title = t(isReadTable ? 'readNotifications' : 'unreadNotifications')

  if (isError) return <GeneralError title={`Failed to retrieve ${isReadTable ? 'read' : 'unread'} notifications`} onClick={refetch} />
  return (
    <>
      {hasError ? (
        <ErrorMessage 
          showAlert={hasError}
          shouldCloseAfterTimeout
          timeout={10000}
          setShowAlert={setHasError}
          message="Failed to update notification status"
        />
      ) : null}
      <Table 
        shimmerLineCount={5}
        shimmerIsEnabled={isLoading || isUninitialized}
        sectionProps={{ 
          title: title,
          actions: isReadTable ? [<span key={nanoid(6)}>Read Notifications can be accessed for up to 90 days</span>] : [],
          className: 'c-notification-table'
        }}
        compact={true}
        cardProps={{ 
          ariaLabel: title,
          className: 'c-notification-table__card',
          size: 'extraSmall'
        }}  
        useCard
        columns={columns}
        data={data || []}
      />
    </>
  )  
}

export default memo(NotificationTable)