import { EActionId } from '@/features/messages/shared/types'
import { Icon, Link } from '@fluentui/react'
import dayjs from 'dayjs'
import { memo, useState } from 'react'
import ArchiveTableCell from './ArchiveTableCell'
import ArchiveTableRow from './ArchiveTableRow'
import cn from 'classnames'
import './ArchiveTableBody.scss'
import { IMessageFullViewRecord, IMessageVersionedContent } from '@/shared/types/swagger'

type ArchiveTableBodyProps = {
  message: IMessageFullViewRecord
}

const ArchiveTableBody = ({ message }: ArchiveTableBodyProps) => {
  const [isHidden, setIsHidden] = useState<boolean>(true)

  const handleClick = () => {
    setIsHidden((prevHidden) => !prevHidden)
  }

  const renderVersion = (versionLength: number, index: number, isChildRow: boolean) => {
    if (!isChildRow) { 
      return (
        <span className='c-archive-table-cell__version__label'>
          <p className='c-archive-table-cell__version__label__text'>
            {isHidden ? `Version (${versionLength})` : `Version ${versionLength - index}`}
          </p>
          <Icon iconName={isHidden ? 'ChevronRightMed' : 'ChevronDownMed' } />
        </span>
      )
  }
    return `Version ${versionLength - index}`
  }

  const renderCells = (message: IMessageFullViewRecord, versionedContent: IMessageVersionedContent, index: number, isChildRow: boolean) => (
    <>
      <ArchiveTableCell className='c-archive-table-cell__date'>{dayjs(versionedContent.createdAt).format('L')}</ArchiveTableCell>
      <ArchiveTableCell className='c-archive-table-cell__sender'>{message.sender}</ArchiveTableCell>
      <ArchiveTableCell className='c-archive-table-cell__recipient'>{message.recipients.map((recipient) => recipient.recipientName).join(', ')}</ArchiveTableCell>
      <ArchiveTableCell className='c-archive-table-cell__subject'>{versionedContent.subject}</ArchiveTableCell>
      <ArchiveTableCell className='c-archive-table-cell__action'><Link role='button' disabled tabIndex={-1}>{EActionId[versionedContent.actionId]}</Link></ArchiveTableCell>
      <ArchiveTableCell className='c-archive-table-cell__notes'>{versionedContent.notes}</ArchiveTableCell>
      <ArchiveTableCell 
        className={cn(
          'c-archive-table-cell__version',
          { 'c-archive-table-cell__version--parent' : !isChildRow },
          { 'c-archive-table-cell__version--parent--expanded' : !isChildRow && !isHidden },
          )}
      >
        {renderVersion(message.versionedContents.length, index, isChildRow)}
      </ArchiveTableCell>
    </>
  )

  const renderBodyContents = () => {
    return message.versionedContents.map((versionedContent, index) => {
      if (index === 0) {
        // Primary row that renders
        return (
          <ArchiveTableRow 
            className={cn('c-archive-table-row', { 'c-archive-table-row--active': !isHidden })}
            isHidden={false}
            onClick={handleClick}
            key={`${message.id}-${versionedContent.versionId}`}
          >
            {renderCells(message, versionedContent, index, false)}
          </ArchiveTableRow>
        )
      }
      // Every other versioned content that is initially hidden
      return (
        <ArchiveTableRow 
          className={cn(
            'c-archive-table-row',
            'c-archive-table-row--child',
            { 'c-archive-table-row--hidden': isHidden },
            { 'c-archive-table-row--active': !isHidden }
          )} 
          isHidden={isHidden} 
          key={`${message.id}-${versionedContent.versionId}`}
        >
          {renderCells(message, versionedContent, index, true)}
        </ArchiveTableRow>
      )
    })
  }

  return <>{renderBodyContents()}</>
}

export default memo(ArchiveTableBody)