import { emmaApiService, mainService } from '.'
import { IInsightsPostView, IManagedInsightsInfo_GET, IManagedInsightsInfo_PUT } from '@/shared/types/swagger'
import { eventImages } from '@/features/events/constants/eventImages'
import { getImageSrc, getImageSrcForBucket } from '@/features/events/helpers/getImageSrc'
import { IInsightsPostViewWithAlt, IManagedInsightsInfoWithAlt_GET } from '@/features/insight/shared/types'

const replaceHTMLEntities = (post: IInsightsPostViewWithAlt): IInsightsPostViewWithAlt => {
  const container = document.createElement('div')
  container.innerHTML = post.title
  return {
    ...post,
    title: container.textContent
  }
}
const filterNotFeatured = (post: IInsightsPostViewWithAlt): boolean => !post.isFeatured
const addDefaultfeaturedImage = (post: IInsightsPostViewWithAlt): IInsightsPostViewWithAlt => ({
  ...post,
  featuredImageLink: getImageSrcForBucket(post.featuredImageLink, 'insights/') || getImageSrc(eventImages[(post?.id ?? 0) % 10].name),
  featuredImageAlt: post.featuredImageAlt || eventImages[(post?.id ?? 0) % 10].alt || '',
})
const transformInsightGet = (data: GetPostReturn) => ({
  ...data,
  items: data.items.filter(filterNotFeatured).map(replaceHTMLEntities).map(addDefaultfeaturedImage)
})

type GetPostsParams = {
  tagFilter?: Array<string>;
  categoryFilter?: Array<string>;
  titleFilter?: string;
  descriptionFilter?: string;
  orderByField?: string;
  orderDirection?: string;
  page: number;
  pageSize?: number;
}

type GetPostReturn = {
  items: Array<IInsightsPostViewWithAlt>
  count: number
}

type GetInsightFiltersReturn = {
  categories: Array<{
    id: number
    name: string
  }>
  tags: Array<{
    id: number
    name: string
  }>
}

type GetInsightUploadImage = {
  URL: string
}

const insightService = emmaApiService
  .enhanceEndpoints({ addTagTypes: ['ArticleData', 'Insights'] })
  .injectEndpoints({
    endpoints(build) {
      return {
        getInsights: build.query<GetPostReturn, GetPostsParams>({
          query: ({
            tagFilter = [],
            categoryFilter = [],
            titleFilter = '',
            descriptionFilter = '',
            orderByField = '',
            orderDirection = '',
            page,
            pageSize
          }) => {
            const validParams = {
              ...tagFilter && tagFilter.length && { tagFilter: tagFilter.join(',') },
              ...categoryFilter && categoryFilter.length && { categoryFilter: categoryFilter.join(',') },
              ...titleFilter && { titleFilter },
              ...descriptionFilter && { descriptionFilter },
              ...orderByField && { orderByField },
              ...orderDirection && { orderDirection }
            }
            const paramKeys = Object.keys(validParams)
            let constructedUrl = `/Insights/Posts?pageSize=${pageSize}&page=${page}`
            if (paramKeys.length) {
              constructedUrl += paramKeys.map((key) => `&${key}=${validParams[key]}`).join('')
            }
            return {
              url: constructedUrl,
              method: 'GET'
            }
          },
          providesTags: ['Insights'],
          transformResponse: transformInsightGet,
        }),
        getInsightArticleData: build.query<IManagedInsightsInfoWithAlt_GET, void>({
          query: () => ({
            url: '/Insights/ArticleData',
            method: 'GET'
          }),
          transformResponse: (data: IManagedInsightsInfoWithAlt_GET) => ({
            ...data,
            featuredInsight: addDefaultfeaturedImage(replaceHTMLEntities(data.featuredInsight)),
            displayedInsights: data.displayedInsights.filter(filterNotFeatured).map(replaceHTMLEntities).map(addDefaultfeaturedImage)
          }),
          providesTags: ['ArticleData']
        }),
        putInsightArticleData: build.mutation<IManagedInsightsInfoWithAlt_GET, IManagedInsightsInfo_PUT>({
          query: (putData) => ({
            url: '/Insights/SetArticleData',
            method: 'PUT',
            data: putData
          }),
          invalidatesTags: ['ArticleData', 'Insights']
        }),
        getRecentInsights: build.query<GetPostReturn, void>({
          query: () => ({
            url: '/Insights/Posts?pageSize=5&page=1',
            method: 'GET'
          }),
          transformResponse: transformInsightGet,
        }),
        getInsightFilters: build.query<GetInsightFiltersReturn, void>({
          query: () => ({ url: '/Insights/Metadata', method: 'GET' }),
          keepUnusedDataFor: 3600
        }),
        getKeyResources: build.query<Array<IInsightsPostViewWithAlt>, void>({
          query: () => ({
            url: '/Insights/KeyResources',
            method: 'GET'
          }),
          transformResponse: (data: Array<IInsightsPostViewWithAlt>) => data.map(replaceHTMLEntities).map(addDefaultfeaturedImage)
        }),
        uploadImage: build.mutation<GetInsightUploadImage, FormData>({
          query: (postData) => (
            {
              url: '/Insights/uploadImage',
              method: 'POST',
              data: postData
            }
          ),
          invalidatesTags: ['ArticleData', 'Insights']
        }),
      }
    },
  })

export const {
  useGetInsightsQuery,
  useGetRecentInsightsQuery,
  useGetInsightArticleDataQuery,
  useGetInsightFiltersQuery,
  usePutInsightArticleDataMutation,
  useGetKeyResourcesQuery,
  useUploadImageMutation
} = insightService
