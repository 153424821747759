import { GridApi } from 'ag-grid-community'
import { useEffect, useRef, useState } from 'react'
import { PerformanceRawData, getPerformanceValue, PERFORMANCE_MEDIAN_KEYS, IPortfolioSettings, getGridExcelStyles, getPerformanceFiscalYearEndField, getPerformanceFiscalYearEndShortMonth } from '@/shared/api/services/portfolioService'
import useIsMobile from '@/shared/hooks/useIsMobile'
import { IDataGridCellStyleParams, PercentageColumn, TextColumn } from '@/lib/common/components/grid/Columns'
import DataGrid, { DataGridInitEvent } from '@/lib/common/components/grid/Grid'
import { PerformanceSleeveColumn } from '@/screens/ClientPortfolio/Components/Grid/Column/PerformanceSleeveColumn'
import PerformanceGridFootNote from '@/screens/ClientPortfolio/Components/Grid/FootNote/PerformanceGridFootNote'
import { formatDate } from '@/lib/common/services/date/DateService'

import './PerformanceSummaryGrid.scss'

const getWindowDimensions = () => {
  const { innerWidth: width, innerHeight: height } = window
  return {
    width,
    height
  }
}

export interface PerformanceSummaryGridProps {
  portfolioSettings: IPortfolioSettings,
  rows: PerformanceRawData[],
  sendGridApi: (gridApi: GridApi) => void,
}

const PerformanceSummaryGrid = ({
  portfolioSettings,
  rows,
  sendGridApi
}: PerformanceSummaryGridProps) => {
  const performanceSummarySettings = portfolioSettings?.performanceSummary
  const fiscalYearEndField = getPerformanceFiscalYearEndField(portfolioSettings)
  const fiscalYearEndFieldShortMonth = getPerformanceFiscalYearEndShortMonth(portfolioSettings)
  const gridContainerRef = useRef(null)
  const [ isEmptyCollumnHidden, setEmptyColumnHidden ] = useState(true)
  const isMobile = useIsMobile('xs')
  const isLargeMobile = useIsMobile('mm')
  const isMediumMobile = useIsMobile('md')
  const isVerticalTablet = useIsMobile('sm')
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions())
  const [columnSize, setcolumnSize] = useState()
  const sizesMap = {
    375: 170,
    430: 225,
    480: 270,
    768: 175,
    1024: 415,
    1440: 180
  }
  const isFootNoteHidden = !(rows || []).some(r => !!r.StatusAggregate)

  useEffect(() => {
    const handleResize = () => {
      setWindowDimensions(getWindowDimensions())
      setcolumnSize(sizesMap[windowDimensions.width])
    }
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])
  
  const getRowStyle = (params: IDataGridCellStyleParams) => {
    const data = params?.data || {}
    const styles = {} as any
    if (params.rowIndex <= 0) {
      styles['background-color'] = `${data.Color}1F`
    }
    if (data.DisplayPLIType === 'ValueAdded' || data.DisplayPLIType === 'Benchmark') {
      styles['font-style'] = 'italic'
    }
    if (data?.Name === 'Total Assets') {
      styles['font-weight'] = '600'
    }
    return styles
  }

  const onGridInit = (event: DataGridInitEvent) => {
    const { gridApi } = event
    sendGridApi(gridApi)

    if (gridContainerRef) {
      const gridContainer = gridContainerRef.current as HTMLElement
      if (gridContainer) {
        const gridElement = gridContainer.querySelector('.ca-grid') as HTMLElement

        if (gridElement) {
          const pinneLeftColContainer = gridElement.querySelector('.ag-pinned-left-cols-container') as HTMLElement
          const centColContainer = gridElement.querySelector('.ag-center-cols-clipper .ag-center-cols-container') as HTMLElement
          const pinnedRightColContainer = gridElement.querySelector('.ag-pinned-right-cols-container') as HTMLElement          
          const gridContainerWidth = gridContainer.clientWidth
          const gridElementWidth = ((pinneLeftColContainer && pinneLeftColContainer.offsetWidth) || 0)
            + ((centColContainer && centColContainer.offsetWidth) || 0)
            + ((pinnedRightColContainer && pinnedRightColContainer.offsetWidth) || 0)

          if (gridContainerWidth > gridElementWidth) {
            setEmptyColumnHidden(false)
          }
        }    
      }
    }
  }

  return (
    <div className='PerformanceSummaryGrid' ref={gridContainerRef}>
      <DataGrid
        showToolBar={false}
        rows={rows}
        enablePagination={false}
        domLayout='autoHeight'
        className='PerformanceGrid'
        onInit={onGridInit}
        excelStyles={getGridExcelStyles({
          currencySymbol: portfolioSettings?.currency?.symbol,
          dateFormat: portfolioSettings?.dateFormat,
        })}
      >
        <PerformanceSleeveColumn pinned='left' lockPinned={true} disableFilterColumn={true} title=' ' width={250} resizable={false} headerName='nameColumn' field='Name' checkboxSelection={false} cellStyle={getRowStyle} />
        <PercentageColumn hide={!performanceSummarySettings?.displayPerformanceSumaryMtd} customFilter={PERFORMANCE_MEDIAN_KEYS} headerClass='three-text-break-right mtd'
          title={`${isMobile ? 'MTD' : 'Month To Date'}`} disableFilterColumn={true} field='TwrMtd' cellStyle={getRowStyle} width={isMobile ? 50 : 110} valueGetter={value => {
          return getPerformanceValue(value, 'TwrMtd')
        }} />
        <PercentageColumn hide={!performanceSummarySettings?.displayPerformanceSumaryQtd} customFilter={PERFORMANCE_MEDIAN_KEYS} headerClass='long-text-break-right mtd'
          title={`${isMobile ? 'QTD' : 'Quarter To Date'}`} disableFilterColumn={true} field='TwrQtd' cellStyle={getRowStyle} width={isMobile ? 50 : 110} valueGetter={value => {
          return getPerformanceValue(value, 'TwrQtd')
        }} />
        <PercentageColumn hide={!performanceSummarySettings?.displayPerformanceSumaryFiscalYtd} customFilter={PERFORMANCE_MEDIAN_KEYS} headerClass='long-text-break-right mtd'
          title={`${isMobile ? 'Fiscal' : `Fiscal Year To Date ${fiscalYearEndFieldShortMonth}`}`} disableFilterColumn={true} field='TwrFytdDec' cellStyle={getRowStyle} width={isMobile ? 50 : 110} valueGetter={value => {
          return getPerformanceValue(value, fiscalYearEndField)
        }} />
        <PercentageColumn hide={!performanceSummarySettings?.displayPerformanceSumaryYtd} customFilter={PERFORMANCE_MEDIAN_KEYS} headerClass='long-text-break-right mtd'
          title={`${isMobile ? 'YTD' : 'Calendar Year To Date'}`} disableFilterColumn={true} field='TwrCytd' cellStyle={getRowStyle} width={isMobile ? 50 : 110} valueGetter={value => {
          return getPerformanceValue(value, 'TwrCytd')
        }} />
        <PercentageColumn hide={!performanceSummarySettings?.displayPerformanceSumary1Year} customFilter={PERFORMANCE_MEDIAN_KEYS} headerClass='long-text-break-right one-year'
          title={`${isMobile ? '1Y' : 'Cumulative Trailing 1 Year'}`} disableFilterColumn={true} field='TwrTr1Y' cellStyle={getRowStyle} width={isMobile ? 50 : 120} valueGetter={value => {
          return getPerformanceValue(value, 'TwrTr1Y')
        }} />
        <PercentageColumn hide={!performanceSummarySettings?.displayPerformanceSumary2Year} customFilter={PERFORMANCE_MEDIAN_KEYS} headerClass='four-text-break-right one-year'
          title={`${isMobile ? '2Y' : 'Annualized Trailing 2 Year'}`} disableFilterColumn={true} field='TwrTr2Y' cellStyle={getRowStyle} width={isMobile ? 50 : 120} valueGetter={value => {
          return getPerformanceValue(value, 'TwrTr2Y')
        }} />
        <PercentageColumn hide={!performanceSummarySettings?.displayPerformanceSumary3Year} customFilter={PERFORMANCE_MEDIAN_KEYS} headerClass='four-text-break-right one-year'
          title={`${isMobile ? '3Y' : 'Annualized Trailing 3 Year'}`} disableFilterColumn={true} field='TwrTr3Y' cellStyle={getRowStyle} width={isMobile ? 50 : 120} valueGetter={value => {
          return getPerformanceValue(value, 'TwrTr3Y')
        }} />
        <PercentageColumn hide={!performanceSummarySettings?.displayPerformanceSumary4Year} customFilter={PERFORMANCE_MEDIAN_KEYS} headerClass='four-text-break-right one-year'
          title={`${isMobile ? '4Y' : 'Annualized Trailing 4 Year'}`} disableFilterColumn={true} field='TwrTr4Y' cellStyle={getRowStyle} width={isMobile ? 50 : 120} valueGetter={value => {
          return getPerformanceValue(value, 'TwrTr4Y')
        }} />
        <PercentageColumn hide={!performanceSummarySettings?.displayPerformanceSumary5Year} customFilter={PERFORMANCE_MEDIAN_KEYS} headerClass='four-text-break-right one-year'
          title={`${isMobile ? '5Y' : 'Annualized Trailing 5 Year'}`} disableFilterColumn={true} field='TwrTr5Y' cellStyle={getRowStyle} width={isMobile ? 50 : 120} valueGetter={value => {
          return getPerformanceValue(value, 'TwrTr5Y')
        }} />
        <PercentageColumn hide={!performanceSummarySettings?.displayPerformanceSumary6Year} customFilter={PERFORMANCE_MEDIAN_KEYS} headerClass='four-text-break-right one-year'
          title={`${isMobile ? '6Y' : 'Annualized Trailing 6 Year'}`} disableFilterColumn={true} field='TwrTr6Y' cellStyle={getRowStyle} width={isMobile ? 50 : 120} valueGetter={value => {
          return getPerformanceValue(value, 'TwrTr6Y')
        }} />
        <PercentageColumn hide={!performanceSummarySettings?.displayPerformanceSumary7Year} customFilter={PERFORMANCE_MEDIAN_KEYS} headerClass='four-text-break-right one-year'
          title={`${isMobile ? '7Y' : 'Annualized Trailing 7 Year'}`} disableFilterColumn={true} field='TwrTr7Y' cellStyle={getRowStyle} width={isMobile ? 50 : 120} valueGetter={value => {
          return getPerformanceValue(value, 'TwrTr7Y')
        }} />
        <PercentageColumn hide={!performanceSummarySettings?.displayPerformanceSumary8Year} customFilter={PERFORMANCE_MEDIAN_KEYS} headerClass='four-text-break-right one-year'
          title={`${isMobile ? '8Y' : 'Annualized Trailing 8 Year'}`} disableFilterColumn={true} field='TwrTr8Y' cellStyle={getRowStyle} width={isMobile ? 50 : 120} valueGetter={value => {
          return getPerformanceValue(value, 'TwrTr8Y')
        }} />
        <PercentageColumn hide={!performanceSummarySettings?.displayPerformanceSumary9Year} customFilter={PERFORMANCE_MEDIAN_KEYS} headerClass='four-text-break-right one-year'
          title={`${isMobile ? '9Y' : 'Annualized Trailing 9 Year'}`} disableFilterColumn={true} field='TwrTr9Y' cellStyle={getRowStyle} width={isMobile ? 50 : 120} valueGetter={value => {
          return getPerformanceValue(value, 'TwrTr9Y')
        }} />
        <PercentageColumn hide={!performanceSummarySettings?.displayPerformanceSumary10Year} customFilter={PERFORMANCE_MEDIAN_KEYS} headerClass='long-text-break-right one-year'
          title={`${isMobile ? '10Y' : 'Annualized Trailing 10 Year'}`} disableFilterColumn={true} field='TwrTr10Y' cellStyle={getRowStyle} width={isMobile ? 50 : 120} valueGetter={value => {
          return getPerformanceValue(value, 'TwrTr10Y')
        }} />
        <PercentageColumn hide={!performanceSummarySettings?.displayPerformanceSumary11Year} customFilter={PERFORMANCE_MEDIAN_KEYS} headerClass='long-text-break-right one-year'
          title={`${isMobile ? '11Y' : 'Annualized Trailing 11 Year'}`} disableFilterColumn={true} field='TwrTr11Y' cellStyle={getRowStyle} width={isMobile ? 50 : 120} valueGetter={value => {
          return getPerformanceValue(value, 'TwrTr11Y')
        }} />
        <PercentageColumn hide={!performanceSummarySettings?.displayPerformanceSumary12Year} customFilter={PERFORMANCE_MEDIAN_KEYS} headerClass='long-text-break-right one-year'
          title={`${isMobile ? '12Y' : 'Annualized Trailing 12 Year'}`} disableFilterColumn={true} field='TwrTr12Y' cellStyle={getRowStyle} width={isMobile ? 50 : 120} valueGetter={value => {
          return getPerformanceValue(value, 'TwrTr12Y')
        }} />
        <PercentageColumn hide={!performanceSummarySettings?.displayPerformanceSumary13Year} customFilter={PERFORMANCE_MEDIAN_KEYS} headerClass='long-text-break-right one-year'
          title={`${isMobile ? '13Y' : 'Annualized Trailing 13 Year'}`} disableFilterColumn={true} field='TwrTr13Y' cellStyle={getRowStyle} width={isMobile ? 50 : 120} valueGetter={value => {
          return getPerformanceValue(value, 'TwrTr13Y')
        }} />
        <PercentageColumn hide={!performanceSummarySettings?.displayPerformanceSumary14Year} customFilter={PERFORMANCE_MEDIAN_KEYS} headerClass='long-text-break-right one-year'
          title={`${isMobile ? '14Y' : 'Annualized Trailing 14 Year'}`} disableFilterColumn={true} field='TwrTr14Y' cellStyle={getRowStyle} width={isMobile ? 50 : 120} valueGetter={value => {
          return getPerformanceValue(value, 'TwrTr14Y')
        }} />
        <PercentageColumn hide={!performanceSummarySettings?.displayPerformanceSumary15Year} customFilter={PERFORMANCE_MEDIAN_KEYS} headerClass='long-text-break-right one-year'
          title={`${isMobile ? '15Y' : 'Annualized Trailing 15 Year'}`} disableFilterColumn={true} field='TwrTr15Y' cellStyle={getRowStyle} width={isMobile ? 50 : 120} valueGetter={value => {
          return getPerformanceValue(value, 'TwrTr15Y')
        }} />
        <PercentageColumn hide={!performanceSummarySettings?.displayPerformanceSumaryCustomPeriod} customFilter={PERFORMANCE_MEDIAN_KEYS} headerClass='long-text-break-right mtd'
          title={`Annualized Since ${portfolioSettings && formatDate(portfolioSettings.inceptionDate, portfolioSettings.fullDateFormat)}`} disableFilterColumn={true}
          field='TwrCust' cellStyle={getRowStyle} width={isMobile ? 50 : 110} valueGetter={value => {
          return getPerformanceValue(value, 'TwrCust')
        }} />
        <PercentageColumn hide={!performanceSummarySettings?.displayPerformanceSumarySinceFirstFullMonth} customFilter={PERFORMANCE_MEDIAN_KEYS} headerClass='long-text-break-right mtd'
          title='Annualized Since First Full Month' disableFilterColumn={true}
          field='TwrS1FM' cellStyle={getRowStyle} width={isMobile ? 50 : 110} valueGetter={value => {
          return getPerformanceValue(value, 'TwrS1FM')
        }} />
        <PercentageColumn hide={!performanceSummarySettings?.displayPerformanceSumarySinceFirstFullQuarter} customFilter={PERFORMANCE_MEDIAN_KEYS} headerClass='long-text-break-right mtd'
          title='Annualized Since First Full Quarter' disableFilterColumn={true}
          field='TwrS1FQ' cellStyle={getRowStyle} width={isMobile ? 60 : 120} valueGetter={value => {
          return getPerformanceValue(value, 'TwrS1FQ')
        }} />
        <PercentageColumn hide={!performanceSummarySettings?.displayPerformanceSumaryAnnualizedSinceInception} customFilter={PERFORMANCE_MEDIAN_KEYS} headerClass='long-text-break-right mtd'
          title='Annualized Since Inception' disableFilterColumn={true}
          field='TwrItd' cellStyle={getRowStyle} width={isMobile ? 60 : 120} valueGetter={value => {
          return getPerformanceValue(value, 'TwrItd')
        }} />
        <PercentageColumn hide={!performanceSummarySettings?.displayPerformanceSumaryCumulative} customFilter={PERFORMANCE_MEDIAN_KEYS} headerClass='long-text-break-right mtd'
          title='Cumulative Since Inception' disableFilterColumn={true}
          field='TwrCumItd' cellStyle={getRowStyle} width={isMobile ? 60 : 120} valueGetter={value => {
          return getPerformanceValue(value, 'TwrCumItd')
        }} />
        <TextColumn hide={isEmptyCollumnHidden} flex={1} title='' field='' disableFilterColumn={true} checkboxSelection={false} cellStyle={getRowStyle} />
      </DataGrid>
      <PerformanceGridFootNote additionalClassName='PerformanceSummaryFootNote' hidden={isFootNoteHidden} showTitle={false} />
    </div>
  )
}

export default PerformanceSummaryGrid