import React, { useState, ChangeEvent, useEffect, forwardRef, useImperativeHandle } from 'react'
import DatePicker from '../../date/datepicker/DatePicker'
import { format } from 'date-fns'
import { DEFAULT_DATE_FORMAT } from '../../../services/date/DateService'
import styles from './DateFloatingFilter.module.scss'
import UtilService from '../../../services/util/UtilService'

export const DateFloatingFilter = forwardRef(function DateFloatingFilter(props: any, ref?) {
    const { showFilterOptions, showTimeInput } = props
    const [dateValue, setDateValue] = useState(null as Date | null)
    const [filterOption, setFilterOption] = useState('equals')
    const { column } = props
    const { colDef } = column
    const { field } = colDef
    const componentId = UtilService.getId(`${colDef.colId}_date_floating_filter`)

    useImperativeHandle(ref, () => {
        return {
             isFilterActive() {
                return !!dateValue
            },
            doesFilterPass: (params: {data: any}) => {
                const getDate = (date: Date) => {
                    if (date && !showTimeInput) {
                        return new Date(date.getFullYear(), date.getMonth(), date.getDate())
                    }

                    return date
                }

                if (dateValue) {
                    const { data } = params
                    const date = data[field]
                    const dateFilter = getDate(dateValue)
                    const dateRow = getDate(date)

                    if (showFilterOptions) {
                        switch(filterOption) {
                            case 'equals':
                                return dateFilter && dateRow && dateFilter.getTime() === dateRow.getTime()

                            case 'greaterThan':
                                return dateFilter && dateRow && dateFilter.getTime() > dateRow.getTime()

                            case 'lessThan': 
                                return dateFilter && dateRow && dateFilter.getTime() < dateRow.getTime()

                            case 'notEquals': 
                                return dateFilter && dateRow && dateFilter.getTime() !== dateRow.getTime()
                        }
                    }

                    return dateFilter && dateRow && dateFilter.getTime() === dateRow.getTime() 
                }

                return true
            },
        }
    })

    useEffect(() => {
        props.parentFilterInstance(function(instance: any) {
            instance.onFloatingFilterChanged(filterOption || 'equals', dateValue ? format(dateValue, 'yyyy-MM-dd HH:mm:ss') : null)
        })
    }, [props, dateValue, filterOption])

    const handleDateChange = (date: Date | null) => {
        setDateValue(date)
    }

    const handleFilterOptionChange = (
        event: ChangeEvent<HTMLSelectElement>
    ) => {
        const { currentTarget } = event
        const { value } = currentTarget
        setFilterOption(value)
    }

    return (
      <div className={`${styles.floating_filter}`} id={componentId}>
        <DatePicker
          className={styles.date_picker}
          value={dateValue}
          dateFormat={DEFAULT_DATE_FORMAT}
          showTimeInput={showTimeInput}
          onValueChange={handleDateChange}
          id={UtilService.getId(`${componentId}_date_picker`)}
        />

        {showFilterOptions ? (
          <select
            className={styles.select}
            value={filterOption}
            onChange={handleFilterOptionChange}
            id={UtilService.getId(`${componentId}_range_selector`)}
          >
            <option value='equals'>Equals</option>
            <option value='greaterThan'>Greater Than</option>
            <option value='lessThan'>Less Than</option>
            <option value='notEquals'>Not Equals</option>
          </select>
        ) : null}
      </div>
    )
})