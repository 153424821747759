import { MainLayout } from '@/shared/components'
import PageHeader from '@/shared/components/PageHeader'
import { useSelector } from 'react-redux'
import { selectClaims } from '@/features/auth/redux/authSelectors'
import MessagesTableActive from '@/features/messages/components/MessagesTable/MessagesTableActive'
import MessagesTableArchive from '@/features/messages/components/MessagesTable/MessagesTableArchive'

const Messages = () => {
  const account = useSelector(selectClaims)
  return (
    <MainLayout>
      <PageHeader label='Messages' />
      <div className='c-messages'>
        <MessagesTableActive accountid={account.contactId} />
        <MessagesTableArchive accountid={account.contactId} />
      </div>
    </MainLayout>
  )
}

export default Messages
