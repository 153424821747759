import { nanoid } from '@reduxjs/toolkit'
import { cloneElement } from 'react'

const constructSkeletons = (countToRender: number, skeletonElement: JSX.Element): JSX.Element[] => {
  const skeletons: Array<JSX.Element> = []
  for (let index = 0; index < countToRender; index++) {
    const key = nanoid(5)
    skeletons.push(cloneElement(skeletonElement, { key }))
  }
  return skeletons
}

export default constructSkeletons