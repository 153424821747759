import { Dropdown } from '@/shared/components'
import { IStackTokens, SearchBox, Stack, IDropdownOption, IStackItemStyles, ResponsiveMode } from '@fluentui/react'
import { useGetInsightFiltersQuery } from '@/shared/api/services/insightService'
import { TableFilter, eFilterOptions } from '@/features/insight/components/ManageInsightModal/ManageInsightModal'
import './ManageInsightFilters.scss'
import { useMemo } from 'react'
import useQueryParamSubscription from '@/shared/hooks/useQueryParamSubscription'
import { getQPArrayValue } from '@/features/insight/helpers'
import debounce from 'lodash/debounce'
import useIsMobile from '@/shared/hooks/useIsMobile'

const stackToken: IStackTokens = { childrenGap: 16 }

type FeaturedInsightFiltersProps = {
  filters: TableFilter
  setFilters: (updatedFilters: TableFilter) => void
}

const ManageInsightFilters = ({ filters, setFilters }: FeaturedInsightFiltersProps) => {
  const { data, isUninitialized, isLoading } = useGetInsightFiltersQuery()
  const inProgress = isUninitialized || isLoading
  const isMobile = useIsMobile('md')

  const stackItemStyles: IStackItemStyles = {
    root: {
      width: isMobile ? '100%' : '33%'
    },
  }

  const handleFilterDropdown = (item: IDropdownOption, filterOption: eFilterOptions.Category | eFilterOptions.Tags) => {
    if (!item) return
    const newValue = item.selected 
      ? [...filters[filterOption], item.key as string]
      : filters[filterOption].filter((tag) => tag !== item.key)

    setFilters({ ...filters, [filterOption]: newValue })
  }

  const handleCategoryChange = (event: React.FormEvent<HTMLDivElement>, item: IDropdownOption) => {
    handleFilterDropdown(item, eFilterOptions.Category)
  }

  const handleTagChange = (event: React.FormEvent<HTMLDivElement>, item: IDropdownOption) => {
    handleFilterDropdown(item, eFilterOptions.Tags)
  }

  const handleSearch = debounce((_, value: string) => {
    setFilters({
      ...filters,
      [eFilterOptions.Label]: value
    })
  }, 400)

  const categories = useMemo(() => data?.categories.map((category) => ({ key: category.id.toString(), text: category.name })), [data?.categories])
  const tags = useMemo(() => data?.tags.map((tag) => ({ key: tag.id.toString(), text: tag.name })), [data?.tags])

  return (
    <Stack className="c-manage-insight-filter-container" tokens={stackToken} horizontal={!isMobile}>
      <Stack.Item styles={stackItemStyles}>
        <SearchBox 
          onChange={handleSearch} 
          placeholder="Search Insights"
        />
      </Stack.Item>
      <Stack.Item styles={stackItemStyles}>  
        <Dropdown
          multiSelect
          onChange={handleCategoryChange}
          placeholder='All Categories'
          disabled={inProgress}
          options={categories}
        />
      </Stack.Item>
      <Stack.Item styles={stackItemStyles}>        
        <Dropdown 
          multiSelect
          onChange={handleTagChange}
          placeholder='All Tags'
          disabled={inProgress}
          options={tags}
          responsiveMode={ResponsiveMode.unknown}
        />
      </Stack.Item>
    </Stack>
  )
}

export default ManageInsightFilters