/* Type generated via scripts/generateTSSchemas.js */

export interface IAccount {
  accountId: string;
  accountName?: string;
  accountRegion?: string;
  accountSecurityLevel?: string;
  accountSegment?: string;
  clientId?: string;
  clientStatus?: string;
  createdAt: string;
  createdOn?: string;
  endowedStatus?: string;
  inceptionDate?: string;
  institutionType?: string;
  isDeleted: boolean;
  modifiedOn?: string;
  soC1Client?: string;
  terminationDate?: string;
  updatedAt?: string;
}

export interface IArchiveMessageModel {
  archive: boolean;
}

export interface IBoxClassification {
  color?: string;
  definition?: string;
  name?: string;
}

export interface IBoxCollaboration {
  accessibleBy: IBoxEntity;
  acknowledgedAt?: string;
  canViewPath?: boolean;
  createdAt?: string;
  createdBy: IBoxUser;
  expiresAt?: string;
  id?: string;
  inviteEmail?: string;
  item: IBoxItem;
  modifiedAt?: string;
  role?: string;
  status?: string;
  type?: string;
}

export interface IBoxEmail {
  acesss?: string;
  address?: string;
}

export interface IBoxEnterprise {
  id?: string;
  name?: string;
  type?: string;
}

export interface IBoxEntity {
  id?: string;
  type?: string;
}

export interface IBoxFolder {
  allowedInviteeRoles?: Array<string>;
  allowedSharedLinkAccessLevels?: Array<string>;
  canNonOwnersInvite?: boolean;
  classification: IBoxClassification;
  contentCreatedAt?: string;
  contentModifiedAt?: string;
  createdAt?: string;
  createdBy: IBoxUser;
  description?: string;
  eTag?: string;
  expiresAt?: string;
  folderUploadEmail: IBoxEmail;
  hasCollaborations?: boolean;
  id?: string;
  isCollaborationRestrictedToEnterprise?: boolean;
  isExternallyOwned?: boolean;
  itemCollection: IBoxItemBoxCollection;
  itemStatus?: string;
  metadata?: undefined;
  modifiedAt?: string;
  modifiedBy: IBoxUser;
  name?: string;
  ownedBy: IBoxUser;
  parent: IBoxFolder;
  pathCollection: IBoxFolderBoxCollection;
  permissions: IBoxFolderPermission;
  purgedAt?: string;
  sequenceId?: string;
  sharedLink: IBoxSharedLink;
  size?: number;
  syncState?: string;
  tags?: Array<string>;
  trashedAt?: string;
  type?: string;
  watermarkInfo: IBoxWatermarkInfo;
}

export interface IBoxFolderBoxCollection {
  entries?: Array<IBoxFolder>;
  limit: number;
  offset: number;
  order?: Array<IBoxSortOrder>;
  totalCount: number;
}

export interface IBoxFolderPermission {
  canComment: boolean;
  canDelete: boolean;
  canDownload: boolean;
  canInviteCollaborator: boolean;
  canRename: boolean;
  canSetShareAccess: boolean;
  canShare: boolean;
  canUpload: boolean;
}

export interface IBoxFolderTreeItem {
  item: IBoxItem;
  path?: string;
}

export interface IBoxItem {
  createdAt?: string;
  createdBy: IBoxUser;
  description?: string;
  eTag?: string;
  id?: string;
  itemStatus?: string;
  modifiedAt?: string;
  modifiedBy: IBoxUser;
  name?: string;
  ownedBy: IBoxUser;
  parent: IBoxFolder;
  pathCollection: IBoxFolderBoxCollection;
  sequenceId?: string;
  sharedLink: IBoxSharedLink;
  size?: number;
  tags?: Array<string>;
  trashedAt?: string;
  type?: string;
}

export interface IBoxItemBoxCollection {
  entries?: Array<IBoxItem>;
  limit: number;
  offset: number;
  order?: Array<IBoxSortOrder>;
  totalCount: number;
}

export interface IBoxNotificationEmail {
  email?: string;
  isConfirmed?: boolean;
}

export interface IBoxPermission {
  canDownload: boolean;
  canPreview: boolean;
}

export interface IBoxSharedLink {
  access: BoxSharedLinkAccessType;
  downloadCount: number;
  downloadUrl?: string;
  isPasswordEnabled: boolean;
  permissions: IBoxPermission;
  previewCount: number;
  unsharedAt?: string;
  url?: string;
  vanityUrl?: string;
}

export enum BoxSharedLinkAccessType {
  open,
  company,
  collaborators,
}

export enum BoxSortBy {
  Type,
  Name,
  file_version_id,
  Id,
  policy_name,
  retention_policy_id,
  retention_policy_object_id,
  retention_policy_set_id,
  interacted_at,
}

export enum BoxSortDirection {
  ASC,
  DESC,
}

export interface IBoxSortOrder {
  by: BoxSortBy;
  direction: BoxSortDirection;
  sort?: string;
}

export interface IBoxTrackingCode {
  name?: string;
  type?: string;
  value?: string;
}

export interface IBoxUser {
  address?: string;
  avatarUrl?: string;
  canSeeManagedUsers?: boolean;
  createdAt?: string;
  enterprise: IBoxEnterprise;
  externalAppUserId?: string;
  hostname?: string;
  id?: string;
  isExemptFromDeviceLimits: boolean;
  isExemptFromLoginVerification: boolean;
  isExternalCollabRestricted?: boolean;
  isPlatformAccessOnly?: boolean;
  isSyncEnabled?: boolean;
  jobTitle?: string;
  language?: string;
  login?: string;
  maxUploadSize?: number;
  modifiedAt?: string;
  name?: string;
  notificationEmail: IBoxNotificationEmail;
  phone?: string;
  role?: string;
  spaceAmount?: number;
  spaceUsed?: number;
  status?: string;
  tags?: Array<string>;
  timezone?: string;
  trackingCodes?: Array<IBoxTrackingCode>;
  type?: string;
}

export interface IBoxWatermarkInfo {
  isWatermarked: boolean;
}

export interface IContactTermsOfServiceHistory {
  contactId: string,
  termsOfServiceAcceptedDate: string,
  termsOfServiceLastResetDate: string,
  termsOfServiceDateHistory: string[],
}

export interface IClientContactViewModel {
  boxId?: string;
  caid?: number;
  contactId: string;
  contactKey?: number;
  email1?: string;
  email2?: string;
  firstName?: string;
  inviteState: InviteStateEnum;
  jobTitle?: string;
  lastName?: string;
  middleName?: string;
  preferredName?: string;
  salutation?: string;
  termsOfServiceHistory?: IContactTermsOfServiceHistory;
}

export interface IContact {
  caid?: number;
  contactId: string;
  contactKey?: number;
  createdAt: string;
  createdOn?: string;
  doNotAllowBulkEmails?: boolean;
  doNotAllowEmails?: boolean;
  email1?: string;
  email2?: string;
  firstName?: string;
  isActive?: boolean;
  isDeleted?: boolean;
  jobTitle?: string;
  lastName?: string;
  middleName?: string;
  modifiedOn?: string;
  preferredName?: string;
  salutation?: string;
  updatedAt?: string;
  versionNumber: number;
  publicOpticaResearch: string;
  privateOpticaResearch: string;
  opticaBenchmark: boolean;
  opticaPeers: boolean;
}

export interface IContactAddress {
  addressId: string;
  addressLine1?: string;
  addressLine2?: string;
  addressLine3?: string;
  addressName?: string;
  city?: string;
  contactId: string;
  contactKey?: number;
  countryName?: string;
  createdAt: string;
  createdOn?: string;
  isDeleted: boolean;
  isMailing: boolean;
  isOffice: boolean;
  isOther: boolean;
  isOvernight: boolean;
  isPrimary: boolean;
  iso2CountryCode?: string;
  isoStateCode?: string;
  isresidence: boolean;
  isseasonal: boolean;
  modifiedOn?: string;
  postalCode?: string;
  stateName?: string;
  updatedAt?: string;
  versionNumber: number;
}

export interface ICountry {
  countryAbbreviation?: string;
  countryName?: string;
  countryid: string;
  createdAt: string;
  iso3code?: string;
  updatedAt?: string;
}

export interface IEmployeeAdminClientNavbarDisplayResult {
  documents: boolean;
  settings: boolean;
  admin: boolean;
}

export interface IEvent {
  cost?: number;
  createdAt: string;
  createdBy?: string;
  description?: string;
  endEventDate?: string;
  eventType: EventTypes;
  eventUrl?: string;
  id: string;
  imageAlt?: string;
  imageUrl?: string;
  isDeleted: boolean;
  isFeatured: boolean;
  location?: string;
  modifiedBy?: string;
  publishedDate?: string;
  startEventDate: string;
  status?: string;
  timezoneName?: string;
  title?: string;
  updatedAt?: string;
  imageFile?: File;
  imageName?: string;
}

export enum EventTypes {
  Virtual,
  InPerson,
}

export interface IFaq {
  createdAt: string;
  id: string;
  isVisible: boolean;
  questionResponse?: string;
  questionText?: string;
  updatedAt?: string;
}

export interface IFaqResponseModel {
  createdAt: string;
  id: string;
  isVisible: boolean;
  questionResponse?: string;
  questionText?: string;
  updatedAt?: string;
}

export interface IFeaturedEventViewModel {
  cost?: number;
  createdAt: string;
  createdBy?: string;
  description?: string;
  endEventDate?: string;
  eventType: number;
  eventUrl?: string;
  id: string;
  imageAlt?: string;
  imageUrl?: string;
  isDeleted: boolean;
  isFeatured: boolean;
  isManuallyFeatured: boolean;
  location?: string;
  modifiedBy?: string;
  publishedDate?: string;
  startEventDate: string;
  status?: string;
  timezoneName?: string;
  title?: string;
  updatedAt?: string;
}

export enum FeaturedMediaType {
  Video,
  Audio,
  Image,
}

export interface IFeaturedMediaViewModel {
  caption?: string;
  contentLink?: string;
  contentType: FeaturedMediaType;
  sectionName?: string;
  title?: string;
  linkUrl?: string;
}

export interface IInsightsPostView {
  categories?: Array<string>;
  createdAt: string;
  description?: string;
  featuredImageLink?: string;
  hasBeenManuallySelected: boolean;
  id: number;
  isFeatured: boolean;
  link?: string;
  tags?: Array<string>;
  title?: string;
  updatedAt?: string;
  downloadLink?: string;
}

export enum InviteStateEnum {
  InviteNotSent,
  InviteSentNotAccepted,
  InviteSentAndAccepted,
}

export interface ILegacyAppDisplayRuleResult {
  annualInvestmentPoolReturnsSurvey: boolean;
  annualManagerReport: boolean;
  cAmPME: boolean;
  clientInstitutionTypes?: Array<string>;
  clientSnapshot: boolean;
  exhibitFinder: boolean;
  historicalPrivateInvestmentBenchmarks: boolean;
  indexSnapshots: boolean;
  investmentPoolReturnsQuarterlySurvey: boolean;
  managerNotices: boolean;
  mmos: boolean;
  pios: boolean;
  poolReturnsSnapshot: boolean;
  preliminaryPerformanceBulletin: boolean;
  proprietaryPrivateInvestmentBenchmarks: boolean;
}

export interface IMaintenanceBannerSettings {
  bannerText?: string;
  maintenanceBegin: string;
  maintenanceEnd: string;
}

export interface IManagedInsightsInfo_GET {
  displayedInsights?: Array<IInsightsPostView>;
  featuredInsight: IInsightsPostView;
  isBeingManaged: boolean;
}

export interface IManagedInsightsInfo_PUT {
  displayedInsights?: Array<number>;
  featuredInsight?: number;
  isBeingManaged: boolean;
}

export enum MessageAction {
  PleaseReview,
  CompleteTask,
  CommentAndReply,
  SignatureRequired,
}

export interface IMessageCreateModel {
  accountId: number | string
  actionId: MessageAction;
  documentLink?: string;
  notes?: string;
  recipients?: Array<IMessageRecipientView>;
  sender?: string;
  senderId: number;
  subject?: string;
}

export interface IMessageFullViewRecord {
  createdAt: string;
  id: string;
  recipients?: Array<IMessageRecipientView>;
  sender?: string;
  senderId: number;
  updatedAt?: string;
  versionId: number;
  versionedContents?: Array<IMessageVersionedContent>;
}

export interface IMessageRecipientView {
  recipientId: string;
  recipientName?: string;
  recipientType: RecipientType;
}

export interface IMessageUpdateModel {
  actionId: MessageAction;
  documentLink?: string;
  notes?: string;
  subject?: string;
}

export interface IMessageVersionedContent {
  id: string;
  actionId: MessageAction;
  createdAt: string;
  documentLink?: string;
  isArchived: boolean;
  notes?: string;
  subject?: string;
  updatedAt?: string;
  versionId: number;
}

export interface IMessageViewRecord {
  actionId: MessageAction;
  createdAt: string;
  documentLink?: string;
  id: string;
  isArchived: boolean;
  notes?: string;
  recipients?: Array<IMessageRecipientView>;
  sender?: string;
  senderId: number;
  subject?: string;
  updatedAt?: string;
  versionId: number;
}

export interface INavbarNavigationDisplayResult {
  documents: boolean;
  iLevel: boolean;
  opticaBenchmark: boolean;
  opticaPeers: boolean;
  opticaResearchPrivate: boolean;
  opticaResearchPublic: boolean;
  portfolioWorkbench: boolean;
}

export interface INotificationMarkAsReadModel {
  read: boolean;
}

export interface INotificationViewModel {
  author?: string;
  cta?: string;
  description?: string;
  hasBeenRead: boolean;
  id: string;
  time: string;
  title?: string;
}

export enum RecipientType {
  To,
  CC,
  BCC,
}

export interface IServiceAssignment {
  accountId: string;
  accountName?: string;
  accountServiceId: string;
  assignmentEndDate?: string;
  assignmentRoleName?: string;
  assignmentRoleid: string;
  assignmentStartDate?: string;
  assignmentTypeId: string;
  assignmentTypeName?: string;
  clientId?: string;
  createdAt: string;
  createdOn: string;
  isBackup?: boolean;
  isCoLeadDirector?: boolean;
  isDeleted?: boolean;
  isInternalOnly?: boolean;
  isLeadDirector?: boolean;
  lastName?: string;
  modifiedOn: string;
  peopleId?: number;
  preferredName?: string;
  serviceAssignmentId: string;
  serviceDescription?: string;
  updatedAt?: string;
}

export interface IState {
  countryid?: string;
  createdAt: string;
  isoStateCode?: string;
  stateAbbreviation?: string;
  stateId: string;
  stateName?: string;
  updatedAt?: string;
}

export interface ISubscription {
  caSubscriptionName?: string;
  createdAt: string;
  description?: string;
  legacySubscriptionTypeId?: number;
  subscriptionTypeId: string;
  updatedAt?: string;
}

export interface ITeamMemberModel {
  assignmentRoleName?: string;
  email?: string;
  firstName?: string;
  lastName?: string;
  phoneNumber?: string;
  profileUrl?: string;
  serviceDescription?: string;
}

export interface IUserProfileModel {
  clientName?: string;
  email?: string;
  firstName?: string;
  lastName?: string;
  phoneNumber?: string;
  preferredName?: string;
  role?: string;
  title?: string;
  hasMultipleAccounts?: boolean;
}
