import { Fragment, memo } from 'react'
import cn from 'classnames'
import { useTranslation } from 'react-i18next'
import RightArrow from '@/shared/components/RightArrow'
import PublicLink from '@/shared/components/PublicLink'
import { CaOffice } from '@/shared/api/services/caOffficeService'

export interface LocationCardProps extends CaOffice {
  className?: string;
}

function LocationCard(props: LocationCardProps) {
  const { imgRequest: img, addressParts, phone, city, region, officeUrl: url, className = '' } = props
  const { t } = useTranslation('common')

  return (
    <div className={cn('c-location-card', className)}>
      <img className="c-location-card__img" src={img?.src} alt={img?.alt} />
      <div className='c-location-card--content'>
        <h3 className="c-location-card__title">{city}{region ? `, ${region}` : ''}</h3>
        <address className="c-location-card__address">
          {addressParts.map((part, i) => (
            <Fragment key={`addr-${part}${i}`}>
              <span className="c-location-card__address-part">{part}</span><br />
            </Fragment>
        ))}
          {phone ? (
            <PublicLink href={`tel:${phone}`}>{phone}</PublicLink>
        ) : null}
        </address>
        {url ? (
          <p className="c-location-card__link-container">
            <PublicLink className="c-location-card__link" href={url} noUnderline>
              <RightArrow /> {t('moreabout')} {city}
            </PublicLink>
          </p>
      ) : null}
      </div>
    </div>
  )
}

export default memo(LocationCard)
