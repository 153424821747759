import { LinkProps } from 'react-router-dom'
import cn from 'classnames'
import { Link } from 'react-router-dom'
import { Icon } from '@fluentui/react'

interface Props {
  linkTo: LinkProps['to'];
  isCurrent: boolean;
  page: number | string;
  disabled?: boolean;
  onClick?: (pageNum: number | string) => void
}

const IconMap = {
  first: 'ChevronLeftEnd6',
  last: 'ChevronRightEnd6',
  previous: 'ChevronLeftSmall',
  next: 'ChevronRightSmall',
}

export default function PaginationItem(props: Props) {
  const { linkTo, isCurrent, page, onClick, disabled = false } = props
  const useSpan = disabled || isCurrent
  const disableProp = disabled ? { 'aria-disabled': true, tabIndex: -1 } : {}
  const renderNumber = typeof page === 'number'
  const currentAttr = isCurrent ? { 'aria-current': true } : {}

  if (useSpan) return (
    <li className={cn('c-pagination__item', { '--disabled': disabled, '--current': isCurrent })}>
      <span className="c-pagination__item-link --disabled" {...(renderNumber ? currentAttr : {})} {...disableProp}>
        {renderNumber ? page : <Icon iconName={IconMap[page]}/>}
      </span>
    </li>
  )

  // Normal page pagination items
  if (typeof page === 'number') {
    return (
      <li className={cn('c-pagination__item', { '--current': isCurrent })}>
        <Link 
          onClick={() => onClick(page)}
          className="c-pagination__item-link" 
          to={linkTo}
          role="button"
          aria-label={`page ${page}`}
        >{page}</Link>
      </li>
    )
  }

  // First, Last, Back, Next pagination items
  return (
    <li className={cn('c-pagination__item', { '--disabled': disabled })}>
      <Link 
        className="c-pagination__item-link" 
        onClick={() => onClick(page)}
        to={linkTo}
        role="button"
        aria-label={`Go to ${page} page`}
      >
        <Icon iconName={IconMap[page]}/>
      </Link>
    </li>
  )
}
