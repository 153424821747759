import { useGetDashboardViewQuery } from '@/shared/api/services/legacyService'
import { MAIN_INVESTMENT_DASHBOARD } from '@/features/client/components/ClientSettings/ClientSettings.model'

export default function useGetDashboardView(): { dashboardView: string, isLoading: boolean } {
  const { isLoading, isFetching, isUninitialized, isError, data } = useGetDashboardViewQuery()
  const isLoadingAPI = isLoading || isFetching || isUninitialized
  let dashboardView: string
  
  // empty dashboard while loading API
  if (isLoadingAPI) {
    dashboardView = undefined
  }

  // main investment dashboard in case of API errors
  if (isError) {
    dashboardView = MAIN_INVESTMENT_DASHBOARD
  }

  // investment dashboard from API having main investment dashboard in case of empty value
  if (data) {
    dashboardView = data.selectedDashboard || MAIN_INVESTMENT_DASHBOARD
  }

  return {
    dashboardView,
    isLoading: isLoadingAPI,
  }
}