import { Link } from '@fluentui/react'
import { Link as RRLink } from 'react-router-dom'

interface Props {
  onExpand?: () => void;
  expandTitle: string;
  href?: string
}

export default function ListExpand({ onExpand, expandTitle, href }: Props) {
  return (
    <Link 
      className="c-list-expand" 
      onClick={onExpand}
      as={href ? RRLink : undefined}
      to={href}
    >
      {expandTitle}
    </Link>
  )
}
