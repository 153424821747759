import { useMemo, memo, ReactNode } from 'react'
import { nanoid } from '@reduxjs/toolkit'

import { Card } from '@/shared/components'
import './OnboardingTextCard.scss'

type OnboardingTextCardProps = {
  header: string,
  children: JSX.Element | JSX.Element[]
}

export const OnboardingTextCardContent = ({ children }: { children: ReactNode }) => <p className="c-onboarding-text-card__body">{children}</p>

const OnboardingTextCard = ({ header, children }: OnboardingTextCardProps) => {
  const uniqueId = useMemo(() => nanoid(6), [])

  return (
    <Card className='c-onboarding-text-card' ariaLabelledBy={uniqueId}>
      <h3 className='c-onboarding-text-card__header' id={uniqueId}>{header}</h3>
      {children}
    </Card>
  )
}

export default memo(OnboardingTextCard)
