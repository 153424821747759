import { GridApi, ExcelExportParams, ProcessCellForExportParams, ColDef, ExcelStyle } from 'ag-grid-community'
import { formatDate } from '../../services/date/DateService'

export const FILE_NAME_SUFFIX_FORMAT = 'yyyy-MM-dd.hh.mm.ss'
export const HEADER_CELL_CLASS = 'header'
export const COLUMN_CELL_CLASS = 'BaseColumn'
export const DATE_COLUMN_CELL_CLASS = 'DateColumn'
export const CURRENCY_COLUMN_CELL_CLASS = 'CurrencyColumn'
export const PERCENTAGE_COLUMN_CELL_CLASS = 'PercentageColumn'
export const DATE_COLUMN_FORMAT = 'yyyy-MM-dd'
export const DATE_TIME_COLUMN_CELL_CLASS = 'DateTimeColumn'
export const DATE_TIME_COLUMN_FORMAT = 'yyyy-MM-dd HH:mm'
export const HTML_CELL_CLASS = 'HtmlCell'
export const FONT = {
    size: 10,
}
export const CELL_STYLES: ExcelStyle[] = [
    {
        id: HEADER_CELL_CLASS,
        interior: {
            color: '#7a7a7a',
            pattern: 'Solid',
        },
        font: {
            ...FONT,
            color: '#ffffff',
        },
    },
    {
        id: COLUMN_CELL_CLASS,
        font: FONT,
    },
    {
        id: DATE_COLUMN_CELL_CLASS,
        dataType: 'DateTime',
        numberFormat: {
            format: 'mm/dd/yyyy;@',
        },
        font: FONT,
    },
    {
        id: DATE_TIME_COLUMN_CELL_CLASS,
        dataType: 'DateTime',
        numberFormat: {
            format: 'mm/dd/yyyy HH:mm;@',
        },
        font: FONT,
    },
    {
        id: HTML_CELL_CLASS,
        font: FONT,
    },
    {
        id: CURRENCY_COLUMN_CELL_CLASS,
        numberFormat: {
            format: '#,##0.00_);[Red](#,##0.00)'
        },
        font: FONT,
    },
    {

        id: 'indent-0',
        font: FONT,
    },
    {

        id: 'indent-1',
        alignment: { indent: 1 },
        font: FONT,
    },
    {
        id: 'indent-2',
        alignment: { indent: 2 },
        font: FONT,
    },
    {
        id: 'indent-3',
        alignment: { indent: 3 },
        font: FONT,
    },
    {
        id: 'indent-4',
        alignment: { indent: 4 },
        font: FONT,
    },
    {
        id: 'indent-5',
        alignment: { indent: 5 },
        font: FONT,
    },
    {
        id: 'indent-6',
        alignment: { indent: 6 },
        font: FONT,
    },
    {
        id: 'indent-7',
        alignment: { indent: 7 },
        font: FONT,
    },
    {
        id: 'indent-8',
        alignment: { indent: 9 },
        font: FONT,
    },
    {
        id: 'darkFont',
        font: { bold: true },
    }
]

let sourceExportDataAsExcel: (params?: ExcelExportParams) => void

export interface GridExportEntity {
    sheetName?: string
    fileName?: string
    namePrefix?: string
    columnKeys?: string[]
    processCellCallback?: (cellCallbackParams: ProcessCellForExportParams) => void
}

export interface GridExport {
    onExport: (entity: GridExportEntity) => void
}

export const WrapExportDataAsExcel = (gridApi: GridApi) => {
    if (!sourceExportDataAsExcel) {
        sourceExportDataAsExcel = gridApi.exportDataAsExcel
    }

    const exportDataAsExcel = function(sourceParams: any = {}) {
        let { processCellCallback, sheetName, fileName, columnKeys = [] } = sourceParams
        const { namePrefix = 'GridExport' } = sourceParams
        const parameters = arguments as any
        const instance = this

        if (!sheetName) {
            sheetName = namePrefix
        }

        if (!fileName) {
            fileName = namePrefix + '-' + formatDate(new Date(), FILE_NAME_SUFFIX_FORMAT) + '.xlsx'
        }

        if (fileName) {
            fileName = `${fileName || ''}`.replace(/(\s|\r|\n)/g, '-')
            if (!`${fileName}`.endsWith('.xlsx')) {
                fileName += '.xlsx'
            }
        }

        if (!processCellCallback) {
            processCellCallback = (cellCallbackParams: ProcessCellForExportParams) => {
                const { value, column, node } = cellCallbackParams
                const { colDef: columnDef } = column as any
                const colDef = columnDef as ColDef
                const { cellClass } = colDef

                if (value && cellClass) {
                    const hasCellClass = (cellClass: any, expectedCellClass: string) => {
                        if (Array.isArray(cellClass)) {
                            return cellClass.includes(expectedCellClass)
                        }
                        return `${cellClass || ''}`.includes(expectedCellClass)
                    }

                    if (hasCellClass(cellClass, DATE_COLUMN_CELL_CLASS)) {
                        return formatDate(value, DATE_COLUMN_FORMAT)
                    } else if (hasCellClass(cellClass, DATE_TIME_COLUMN_CELL_CLASS)) {
                        return formatDate(value, DATE_TIME_COLUMN_FORMAT)
                    } else if (hasCellClass(cellClass, CURRENCY_COLUMN_CELL_CLASS)) {
                        const data = node && node.data
                        const field = colDef && colDef.field

                        if (data && field) {
                            try {
                                const currencyValue = Number(data[field] || value)?.toFixed(4)
                                return currencyValue
                            } catch (error) {
                                console.error('Error while parsing currency column value.', error)
                            }
                        }
                    } else if (hasCellClass(cellClass, PERCENTAGE_COLUMN_CELL_CLASS)) {
                        return value
                    } else if (hasCellClass(cellClass, HTML_CELL_CLASS)) {
                        const htmlEntities = {
                            '&amp': '&',
                            '&lt': '<',
                            '&gt': '>',
                            '&quot': `'`,
                            '&apos': '`',
                            '&nbsp': ' ',
                        } as any
                        let nonHtmlValue = `${value}`.replace(/(<([^>]+)>)/gi, ' ')

                        for (const htmlEntity in htmlEntities) {
                            const replacement = htmlEntities[htmlEntity]
                            const pattern = new RegExp(htmlEntity, 'g')
                            nonHtmlValue = nonHtmlValue.replace(pattern, replacement)
                        }

                        return nonHtmlValue
                    }
                }

                return value
            }
        }

        if (columnKeys && columnKeys.length) {
            const getColumnKey = (columnKey: any) => {
                if (typeof columnKey === 'string') {
                    if (instance) {
                        const { columnController } = instance
                        if (columnController && columnController.getAllGridColumns) {
                            return (columnController.getAllGridColumns() || []).find((column) => column && column.colDef && column.colDef.field === columnKey)
                        }
                    }
                }

                return columnKey
            }
            columnKeys = columnKeys.map((columnKey: any) => getColumnKey(columnKey))
        }

        parameters[0] = {
            ...sourceParams,
            sheetName,
            fileName,
            columnKeys,
            processCellCallback,
        }

        sourceExportDataAsExcel.apply(instance, parameters)
    }

    gridApi.exportDataAsExcel = exportDataAsExcel.bind(gridApi)
}
