import { Nav, INavStyles } from '@fluentui/react'
import useNavLinkGroups from '@/shared/hooks/useNavLinkGroups'
import { useTranslation } from 'react-i18next'
import { NavMenuItems, navMenuItems } from '@/shared/constants/navMenuItems'
import { NavMenuItem } from './types'
import useNavItemsFiltering from './hooks/useNavItemsFiltering'
import useCanAccessAdminToolbar from './hooks/useCanAccessAdminToolbar'
import useHandleSelectionWithCSR from '@/shared/hooks/useHandleSelectionWithCSR'

const navStyle: Partial<INavStyles> = {
  groupContent: { marginBottom: 0 },
}

interface Props {
  ids: Array<NavMenuItems>
}

export default function NavMenuMobile({ ids }: Props) {
  const { t } = useTranslation('common')
  const items = ids.map((id) => navMenuItems[id] as NavMenuItem)
  const filteredItems = useNavItemsFiltering(items)
  const navLinkGroups = useNavLinkGroups(filteredItems)
  const canAccessToolbar = useCanAccessAdminToolbar()
  const handleSelectionWithCSR = useHandleSelectionWithCSR()

  if (canAccessToolbar) {
    navLinkGroups.push({
      collapseByDefault: true,
      links: [{
        key: 'clients',
        name: t('nav:clients'),
        url: '/clients',
        onClick: handleSelectionWithCSR({ url: '/clients' })
      }, {
        key: 'surveyLinks',
        name: t('nav:surveyLinks'),
        url: '/surveyLinks',
        onClick: handleSelectionWithCSR({ url: '/surveyLinks' })
      }],
      name: t('nav:adminToolbar')
    })
  }

  return (
    <Nav 
      ariaLabel={t('nav:title')}
      groups={navLinkGroups}
      className="c-navmenu-mobile"
      styles={navStyle}
      onRenderGroupHeader={(groupProps, defaultRender) => (
        <div className="c-navmenu__group --mobile" title={groupProps.name}>{defaultRender(groupProps)}</div>
      )}
    />
  )
}
