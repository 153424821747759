import { memo, ReactNode } from 'react'
import cn from 'classnames'

import NavBar from '@/shared/components/NavBar'
import Footer from '../Footer'
import Container from '@/shared/components/Container'
import NavMenu from '../NavMenu'
import { useGetNavMenuData } from '@/shared/constants/navMenuItems'
import { NavMenuProps } from '../NavMenu/NavMenu'
import { useSelector } from 'react-redux'
import { selectIsImpersonating, } from '@/features/auth/redux/authSelectors'
import MaintenanceBanner from '../MaintenanceBanner'

interface Props {
  children: ReactNode;
  id?: string;
  className?: string;
  navMenuProps?: NavMenuProps
}

function MainLayout({ id, children, className, navMenuProps = {} }: Props) {
  const isImpersonating = useSelector(selectIsImpersonating)
  const itemIds = useGetNavMenuData()

  return <>
    <NavBar />
    <main id={id} className={cn('c-layout-main', className, { '--impersonation-banner-offset-mobile': isImpersonating })}>
      <Container>
        <MaintenanceBanner />
        {/* need to hide for prospect or nav rules based?  */}
        {/* <NavMenu itemIds={itemIds} {...navMenuProps} /> */}
        {children}
      </Container>
    </main>
    <Footer />
  </>
}

export default memo(MainLayout)