import { useState } from 'react'
import { GridApi } from 'ag-grid-community'
import { formatDate, getMaxAsOfDate, getMinAsOfDate, parseFormattedDate } from '@/lib/common/services/date/DateService'
import { DefaultButton } from '@fluentui/react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Card, Section } from '@/shared/components'
import useIsMobile from '@/shared/hooks/useIsMobile'
import { useGetAccountByIdQuery } from '@/shared/api/services/crmService'
import MonthEndSelector from '@/lib/common/components/date/monthend/MonthEndSelector'
import PortfolioListMobile from './PortfolioList/PortfolioListMobile/PortfolioListMobile'
import PortfolioList from './PortfolioList/grid/PortfolioList'
import { exportGridData, getPerformanceGridProcessCellCallback, isQuarterlyReportingPeriodicity, useGetStatsSummaryQuery } from '@/shared/api/services/portfolioService'

export interface PortfolioCardProps {
  asOfDate: string,
  accountId: string,
  isDashboardPreview?: boolean,
  onAsOfDateChange: (asOfDate: string) => void,
}

export default function PortfolioCard({
  asOfDate,
  accountId,
  isDashboardPreview = false,
  onAsOfDateChange,
}: PortfolioCardProps) {
  const isMobile = useIsMobile('xs')
  const isLargeMobile = useIsMobile('sm')
  const { data: account } = useGetAccountByIdQuery(accountId, { skip: !accountId })
  const accountName = account && account.accountName
  const parsedAsOfDate = parseFormattedDate(asOfDate)
  const subtitle = `As of ${formatDate(parsedAsOfDate, 'MMM dd, yyyy')}`
  const [ portfolioGridApi, setPortfolioGridApi ] = useState(null as GridApi)
  const { data: portfolios } = useGetStatsSummaryQuery({ accountId, asOfDate })
  const hasQuarterlyReportingPortfolio = portfolios && portfolios.length && portfolios.some(p => isQuarterlyReportingPeriodicity(p))
  const calendarType = hasQuarterlyReportingPortfolio ? 'QUARTERLY' : 'MONTHLY'

  const handlePortfolioDownloadClick = () => {
    exportGridData({
      gridApi: portfolioGridApi,
      excelTabName: 'Portfolios',
      fileName: `client-portfolios-export-client-${accountName}-as-of-date-${asOfDate}`,
      processCellCallback: getPerformanceGridProcessCellCallback('dashboard'),
    })
  }

  return (
    <Card className='dashboard-card c-onboarding-welcome__left__card' ariaLabel='My Portfolios'>
      <Section className='c-dashboard-section portfolio-list-container' title='My Portfolios' subtitle={subtitle} actions={[
        (<MonthEndSelector key='MonthEndSelector'
          minDate={getMinAsOfDate()}
          maxDate={getMaxAsOfDate()}
          value={parsedAsOfDate}
          calendarType={calendarType}
          onValueChange={(date) => onAsOfDateChange(formatDate(date))}
        />),
        !isMobile ? (<div  key='downloadBtn' className='download-button-container'>
          <DefaultButton className='download-button'  onClick={handlePortfolioDownloadClick}>
            <FontAwesomeIcon icon='download' className='c-sidebar-section__heading-icon' />
          </DefaultButton>
        </div>) : null
      ].filter(c => !!c)}>
        { isLargeMobile ? (
          <PortfolioListMobile isDashboardPreview={isDashboardPreview} asOfDate={asOfDate} accountId={accountId} />
        ) : (
          <PortfolioList isDashboardPreview={isDashboardPreview} asOfDate={asOfDate} accountId={accountId} sendGridApi={setPortfolioGridApi} />
        ) }
      </Section>
    </Card>
  )
}
