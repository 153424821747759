import { CaOffice } from '@/shared/api/services/caOffficeService'
import LocationCard from '@/shared/components/LocationCard'

type OnboardingLocationProps = {
  locations: Array<CaOffice>
}

const OnboardingLocation = ({ locations }: OnboardingLocationProps) => {
  return (
    <section className='c-onboarding-location'>
      <h3 className='c-onboarding-location__header'>Our locations</h3>
      <p className='c-onboarding-location__description'>Headquartered in Boston, Massachusetts, we have six regional offices in North America that are dedicated to serving a variety of distinguished institutions and families across the US and Canada.</p>
      <div className='c-onboarding-location__cards'>
        {
        locations.map((location, index) => (
          <div 
            key={`${location.city}-${index}`} // TODO: Placeholder key. Change to ID later
            className='c-onboarding-location__cards--wrapper'
          > 
            <LocationCard 
              {...location} 
              className='c-onboarding-location__cards__card'
            />
          </div>
        ))
      }
      </div>
    </section>
  )
}

export default OnboardingLocation