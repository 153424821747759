export default function copyAndSort<T extends Record<string, unknown>>(items: T[], columnKey: string, isSortedDescending?: boolean, sortKey?: string, getValue?: (item: T) => unknown): T[] {
  const key = columnKey as keyof T

  const comparingObject = (item: T) => typeof item[key] === 'object' && sortKey
  const hasCustomGetter = Boolean(getValue) && typeof getValue === 'function'
  const _getValue = (item: T) => {
    let _value = item[key] ?? '' as any
    if (hasCustomGetter) { _value = (getValue(item) ?? '') }
    if (comparingObject(item)) { _value = item[key][sortKey] }
    if (_value instanceof Date) {
      return _value
    }
    _value = typeof _value === 'boolean' ? _value ? 'Yes' : 'No' : _value
    return `${_value || ''}`.toLowerCase()
  }

  const sortedItems = items  
    .slice(0)
    .sort((a: T, b: T) => (isSortedDescending ? _getValue(a) < _getValue(b) : _getValue(a) > _getValue(b)) ? 1 : -1)
    .map((item) => ({ ...item, sortedBy: key }))
  
  return sortedItems
}