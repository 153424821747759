import { IYourTeam } from '@/shared/api/services/teamService'
import { IButtonStyles, IconButton, Stack } from '@fluentui/react'

type YourTeamModalActionsProps = {
  handleEdit: (event: IYourTeam) => void,
  handleDelete: (event: IYourTeam) => void,
  teamMember: IYourTeam,
}

const iconButtonStyles: IButtonStyles = {
  root: {
    height: 20,
    margin: 0,
  },
  icon: {
    fontSize: 20
  }
}

const YourTeamModalActions = ({ teamMember, handleEdit, handleDelete }: YourTeamModalActionsProps) => {
  const onDeleteClick = () => {
    handleDelete(teamMember)
  }

  return (
    <>
      <Stack tokens={{ childrenGap: 16 }} horizontal>
        <IconButton 
          styles={iconButtonStyles}
          iconProps={{ iconName: 'Delete' }}
          ariaLabel='Delete Event'
          onClick={() => onDeleteClick()}
        />
      </Stack>
    </>
  )
}

export default YourTeamModalActions