import { persistStore, persistReducer, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from 'redux-persist'
import { persistConfig, uimessagesPersistConfig } from './persist'
import { middleware } from './middleware'
import { configureStore, combineReducers } from '@reduxjs/toolkit'
import { setupListeners } from '@reduxjs/toolkit/dist/query'
import { IS_LOWER_ENVIRONMENT } from '@/environment/environment.constants'

//#region reducers
import auth from '@/features/auth/redux/authSlice'
import uimessages from '@/features/uimessages/redux'
import messages from '@/features/messages/redux'
import { emmaApiService, boxService, localService, mainService } from '@/shared/api/services'
import modalSlice from '@/shared/redux/modalSlice'
import faqSlice from '@/features/support/redux/faqSlice'
//#endregion reducers

const useDevTools = (() => {
  return IS_LOWER_ENVIRONMENT
})()

const rootReducer = combineReducers({
  auth,
  messages,
  uimessages: persistReducer(uimessagesPersistConfig, uimessages),

  // Slices
  [modalSlice.name]: modalSlice.reducer,
  [faqSlice.name]: faqSlice.reducer,

  // Toolkit API reducer
  [mainService.reducerPath]: mainService.reducer,
  [boxService.reducerPath]: boxService.reducer,
  [localService.reducerPath]: localService.reducer,
  [emmaApiService.reducerPath]: emmaApiService.reducer
})

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const persistedReducer = persistReducer<any, any>(persistConfig, rootReducer)
const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: {
    ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER]
  } }).concat(middleware),
  devTools: useDevTools
})

setupListeners(store.dispatch)

export type RootState = ReturnType<typeof rootReducer>
export const persistor = persistStore(store)
setupListeners(store.dispatch)
export type appDispatch = typeof store.dispatch
export default store
