import { RootState } from '@/bootstrap/redux'
import { createDraftSafeSelector } from '@reduxjs/toolkit'
import { Person } from '@/shared/types/person'
import { IAuthState } from './authSlice'
import { IClaims } from '@/shared/types/Claims'
import { getPermissions } from '../helpers'
import { isClientContactDashboardOnlyPermission } from '../constants'

const selectAuth = (state: RootState) => state.auth as IAuthState

export const selectClaims = createDraftSafeSelector(
  selectAuth,
  (auth) => auth?.account?.idTokenClaims
)

export const selectHasToken = createDraftSafeSelector(
  selectAuth,
  (auth) => Boolean(auth?.idToken)
)

export const getIsImpersonating = (claims: IClaims) => claims?.isImpersonating ?? false
export const selectIsImpersonating = createDraftSafeSelector(
  selectClaims,
  getIsImpersonating
)

export const selectAccountId = createDraftSafeSelector(
  selectClaims,
  (claims) => claims?.acctId
)

export const selectName = createDraftSafeSelector(
  selectClaims,
  selectIsImpersonating,
  (claims, isImpersonating) => isImpersonating ? claims?.ImpName : claims?.name
)

export const selectCAID = createDraftSafeSelector(
  selectClaims,
  selectIsImpersonating,
  (claims, isImpersonating) => isImpersonating ? claims?.ImpCAID : claims?.CAID
)

export const selectPerson = createDraftSafeSelector(
  selectAccountId,
  selectName,
  (accountId, name) => {
    if (!name) return null
    const parts = name.replace(/\(.*\)/g, '').trim().split(' ') ?? []
    const person: Person = {
      id: accountId,
      firstName: parts?.[0],
      lastName: parts?.[parts.length - 1],
      initials: '',
    }
    const firstInitial = person.firstName?.[0]?.toUpperCase?.() ?? ''
    const lastInitial = person.lastName?.[0]?.toUpperCase?.() ?? ''
    person.initials = `${firstInitial}${lastInitial}`
    return person
  }
)

export const selectIsALaCarte = createDraftSafeSelector(
  selectClaims,
  (claims) => claims?.isALaCarte
)

export const getIsClientUser = (claims: IClaims) => !claims?.CAEmployee

export const getIsCAEmployee = (claims: IClaims, isImpersonating: boolean) => isImpersonating ? false : claims?.CAEmployee ?? false

export const getIsProspectClient = (claims: IClaims) => claims?.CAEmployee ? (claims?.isImpersonating ? claims?.IsProspectClient : false) : claims?.IsProspectClient;

export const selectIsClientUser = createDraftSafeSelector(
  selectClaims,
  getIsClientUser,
)

export const selectIsCAEmployee = createDraftSafeSelector(
  selectClaims,
  selectIsImpersonating,
  getIsCAEmployee
)

export const selectIsProspectClient = createDraftSafeSelector(
  selectClaims,
  getIsProspectClient,
)

export const selectPermissions = createDraftSafeSelector(
  [
    selectClaims,
    selectIsCAEmployee,
    selectIsImpersonating,
    selectIsALaCarte,
    selectIsProspectClient,
  ],
  getPermissions
)

export const selectSubscriptions = createDraftSafeSelector(
  selectClaims,
  (claims) => claims?.isImpersonating ? claims?.ImpCASubs : claims?.CASubs
)

export const selectImpersonatingUserName = createDraftSafeSelector(
  selectClaims,
  selectIsImpersonating,
  (claims, isImpersonating) => isImpersonating ? `${claims?.ImpFirstName} ${claims?.ImpLastName}` : null
)

export const selectIsClientContactDocumentViewOnly = createDraftSafeSelector(
  selectClaims,
  (claims) => !claims?.CAEmployee ? (claims?.SecurityGroups?.some((sg: any) => isClientContactDashboardOnlyPermission(sg))) : false,
)

export const selectSecurityRoles  = createDraftSafeSelector(
  selectClaims,
  (claims) => ({
    isCAEmployee: !!claims?.CAEmployee,
    isImpersonating: !!claims?.isImpersonating,
    roles: (claims?.SecurityGroups as any[] || []) as string[],
  })
)
