import { FieldError, FieldErrors } from 'react-hook-form'

type FormErrorProps = {
  errors: Record<string, FieldError> | FieldErrors<any>,
  name: string,
  describedBy?: string
}

const FormError = ({ errors, name, describedBy }: FormErrorProps) => {
  const displayedError = errors?.[name]
  if (!displayedError?.message) return null
  return (
    <p aria-describedby={describedBy} role='alert' className='c-form-error'>{displayedError.message}</p>
  )
}

export default FormError