import { Icon, Link } from "@fluentui/react"

type Props = {
  handleSearch : (search : string) => void
  handleTag: (tag: string) => void
}

function Breadcrumbs({ handleSearch, handleTag } : Props) {

  const handleClick = e => {
    e.preventDefault()
    handleSearch('')
    handleTag('[]')
  }

  return (
    <ul className='c-breadcrumbs'>
      <li><Link onClick={handleClick}>Insights</Link></li>
      <li><Icon iconName="ChevronRightMed" /></li>
      <li>Search Results</li>
    </ul>
  )
}

export default Breadcrumbs
