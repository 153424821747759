import { memo } from 'react'
import { ChoiceGroup, IChoiceGroupOption } from '@fluentui/react'
import './ModalManagement.scss'

import UnreachableCaseError from '@/shared/types/UnreachableCaseError'

const enum eManagementOptions {
  AutomaticallyManaged = 'AutomaticallyManaged',
  ManuallyManaged = 'ManuallyManaged'
}

interface IModalManagement extends Omit<IChoiceGroupOption, 'key'> {
  key: eManagementOptions
}

type ManageInsightComboProps = {
  userIsManaging: boolean
  setUserIsManaging: (value: boolean) => void,
  autoManageLabel: string,
  manuallyManageLabel: string,
}

const ModalManagement = ({ 
  userIsManaging, 
  setUserIsManaging,
  autoManageLabel,
  manuallyManageLabel,
 }: ManageInsightComboProps) => {

  const options: IModalManagement[] = [
    {
      key: eManagementOptions.AutomaticallyManaged,
      text: autoManageLabel
    },
    {
      key: eManagementOptions.ManuallyManaged,
      text: manuallyManageLabel
    }
  ]

  const handleChange = (ev: React.FormEvent<HTMLInputElement>, option: IModalManagement) => {
    const selectedOption: eManagementOptions = option.key
    switch (selectedOption) {
      case eManagementOptions.AutomaticallyManaged: {
        setUserIsManaging(false)
        break
      }
      case eManagementOptions.ManuallyManaged: {
        setUserIsManaging(true)
        break
      }
      default: throw new UnreachableCaseError(selectedOption)
    }
  }

  return (
    <ChoiceGroup 
      className="c-modal-management"
      selectedKey={userIsManaging ? eManagementOptions.ManuallyManaged : eManagementOptions.AutomaticallyManaged}
      options={options}
      onChange={handleChange}
    />
  )
}

export default memo(ModalManagement, (prevProps, nextProps) => {
  return prevProps.userIsManaging === nextProps.userIsManaging
})