import cn from 'classnames'
import { ReactElement } from 'react'
import { useSelector } from 'react-redux'
import { selectIsCAEmployee, selectIsImpersonating } from '@/features/auth/redux/authSelectors'
import { useGetMaintenanceDataQuery } from '@/shared/api/services/portalService'
import { selectUIMessages } from '../../redux/selectors'
import UIMessage from './components/UIMessage'
import dayjs from 'dayjs'
import { MAINT_BANNER_LS_KEY } from '@/shared/components/MaintenanceBanner/MaintenanceBanner'
import { getUTCTZDatetime } from '@/shared/types/date'
import usePermission from '@/features/auth/hooks/usePermission'
import { EUserCan } from '@/features/auth/types'

export interface UIMessages {
  source: 'main' | 'modal' 
}

export default function UIMessages({ source }: UIMessages): ReactElement {
  const UIMessages = useSelector(selectUIMessages)
  const isImpersonating = useSelector(selectIsImpersonating)
  const isCAEmployee = useSelector(selectIsCAEmployee)
  const isMaintenanceBannerAdmin = usePermission(EUserCan.Edit_Maintenance_Data)
  const { data } = useGetMaintenanceDataQuery()
  const today = dayjs()
  const maintenanceBannerSetting = Boolean(JSON.parse(localStorage.getItem(MAINT_BANNER_LS_KEY)) ?? true)
  const showMaintenanceBanner = maintenanceBannerSetting && data && today.isAfter(getUTCTZDatetime(data.maintenanceBegin)) && today.isBefore(getUTCTZDatetime(data.maintenanceEnd))
  
  return (<>
    {source === 'main' ? (<div className={cn('c-uimessages', { '--offset': isImpersonating, 'show-maintenance-banner': showMaintenanceBanner, 'is-maintenance-banner-admin': isMaintenanceBannerAdmin })}>
      {Object.values(UIMessages).filter(uimes => !uimes.source || uimes.source === 'main').map((uimes) => {
        const { content, key, autoDismiss, autoDismissAfter, ...rest } = uimes
        return <UIMessage key={key} id={key} content={content} message={uimes} {...rest} />
      })}
    </div>)
    : null}
    {source === 'modal' ? (<div className={cn('c-uimessages', { '--offset': isImpersonating })}>
      {Object.values(UIMessages).filter(uimes => uimes.source === 'modal').map((uimes) => {
        const { content, key, autoDismiss, autoDismissAfter, ...rest } = uimes
        return <UIMessage key={key} id={key} content={content} message={uimes} {...rest} />
      })}
    </div>)
    : null}
  </>)
}
