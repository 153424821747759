import { useState, useEffect, ReactElement } from 'react'
import { I18nextProvider } from 'react-i18next'
import { i18n } from 'i18next'

import { LocalizationProviderProps } from './types'
import setup from '../../bootstrap/i18n'
import './LocalizationProvider.scss'

/**
 * Used to initialize I18N as provided by the ADK-Localization package.
 * Implement this component towards the top level of your code to initiate I18N and localization.
 */
const LocalizationProvider = ({
  loadingComponent,
  children,
  localizationConfig,
  onReadyCallBack,
  shouldWaitOn
}: LocalizationProviderProps): ReactElement => {
  const [isReady, setIsReady] = useState(false)
  const [i18n, setI18n] = useState<i18n>()

  const setupI18n = async (): Promise<void> => {
    let readyi18n
    if (localizationConfig && localizationConfig.i18n) { readyi18n = await setup(localizationConfig.i18n) } else readyi18n = await setup({})
    if (onReadyCallBack) onReadyCallBack(i18n)
    setI18n(readyi18n)
  }

  useEffect(() => {
    // i18n setup.
    setupI18n()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    // Logic for controlling when localization is ready.
    if (i18n) {
      if (shouldWaitOn === undefined) setIsReady(true)
      else if (shouldWaitOn) setIsReady(true)
    }
  }, [shouldWaitOn, i18n])

  const renderLoadingTranslations = () => {
    if (loadingComponent) return loadingComponent
    else {
      return (
        <div className="localizationLoadingContainer">
          <p className="localizationLoadingText">
            Loading translations...
          </p>
        </div>
      )
    }
  }

  if (!isReady || !i18n) return renderLoadingTranslations()
  return <I18nextProvider i18n={i18n}>{children}</I18nextProvider>
}

export default LocalizationProvider
