import { selectTeam } from '../../redux/selectors'
import { useSelector } from 'react-redux'
import { Card, Section, TeamMemberDetail } from '@/shared/components'
import { useTranslation } from 'react-i18next'
import departments from '../../constants/departments'
import { TeamMember } from '@/shared/types/teamMember'
import { PersonaSize, Stack } from '@fluentui/react'

type DepartmentMap = Array<{id: number, team: Array<TeamMember>}>

export default function TeamMembers() {
  const team = useSelector(selectTeam)
  const { t } = useTranslation('clients')

  const departmentMap: DepartmentMap =          
    team
      .sort((a, b) => a.departmentId - b.departmentId)
      .reduce((acc, cur) => {
        const existingIndex = acc.findIndex((dept) => dept.id === cur.departmentId)
        if (existingIndex > -1) {
          acc[existingIndex].team.push(cur)
        } else {
          acc.push({
            id: cur.departmentId,
            team: [cur]
          })
        }
        return acc
      }, [] as DepartmentMap)

  return (
    <Section>
      <Card ariaLabel={t('teamSectionTitle')} className="c-team-members">
        {departmentMap.map((dept) => (
          <Stack key={dept.id} className="c-team-members__dept">
            <h2 className="c-team-members__dept-heading">{t(`departments:${departments[dept.id].titleKey}`)}</h2>
            <Stack horizontal className="c-team-members__dept-team" wrap>
              {dept.team.map((member) => (
                <div className="c-team-member__container" key={member.id}>
                  <TeamMemberDetail 
                    data={member} 
                    size={PersonaSize.size120}
                    optionalText={member.phone}
                  />
                </div>
            ))}
            </Stack>
          </Stack>))}
      </Card>
    </Section>
  )
}
