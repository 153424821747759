import { ILinkContentWithLink } from '@/shared/types/Links'
import { nanoid } from '@reduxjs/toolkit'
import { Fragment } from 'react'
import iconMap from '../constants/iconMap'
import { externalAppGroupTitleMap } from '../constants/linkContent'
import MorePageLink from './MorePageLink'

type ExtAppLinkGroupProps = {
  groupKey: string;
  links: Array<ILinkContentWithLink>;
}

export default function ExtAppLinkGroup({ groupKey, links }: ExtAppLinkGroupProps) {
  const Icon = iconMap[groupKey]

  return (
    <Fragment>
      <hr />
      <section className='c-more__section'>
        <header className='c-more__section-header'>
          <Icon />
          <h3>{externalAppGroupTitleMap[groupKey]}</h3>
        </header>
        <div className='c-more__section-links'>
          {links.map((content) => <MorePageLink key={nanoid(5)} {...content} />)}
        </div>
      </section>
    </Fragment>
  )
}