import { useHistory } from 'react-router-dom'

type QueryValue = string
type UpdateQuery = (value: string) => void
type QuerySubscription = [QueryValue, UpdateQuery]

export default function useQueryParamSubscription<V extends string>(paramName: string, defaultValue?: V): QuerySubscription {
  const history = useHistory()
  const queryParams = new URLSearchParams(history.location.search)
  const queryValue = queryParams.get(paramName) ?? defaultValue ?? undefined
  
  function updateQuery(value: string): void {
    history.replace({
      ...history.location,
      search: (() => {
        const _queryParams = new URLSearchParams(history.location.search)
        if (!value) {
          _queryParams.delete(paramName)
        } else {
          _queryParams.set(paramName, value)
        }

        return `?${_queryParams.toString()}`
      })()
    })
  }

  return [queryValue, updateQuery]
}