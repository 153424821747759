import { IClaims } from '@/shared/types/Claims'
import { clientContactPermissionGroups, defaultALaCartePermissions, defaultCAEmployeePermissions, defaultContactPermissions, isClientContactDashboardOnlyPermission, permissionGroups } from '../constants'
import { EAccountType, Permission } from '../types'
import logAccess from './logAccess'

export default function getPermissions(claims: IClaims, isCAEmployee: boolean, isImpersonating: boolean, isALaCarte?: boolean) {
  const SecurityGroups = claims?.SecurityGroups?.length ? claims?.SecurityGroups : []
  const isCAEmployeeUser = claims?.CAEmployee || isCAEmployee
  let permissions: Array<Permission> = isALaCarte ? defaultALaCartePermissions : isCAEmployee ? defaultCAEmployeePermissions : defaultContactPermissions

  try {
    if (SecurityGroups.length) {
      if (isCAEmployeeUser) {
        if (!isImpersonating) {
          // map and dedup permissions based on SecurityGroup(s)
          const groupBasedPermissions = (SecurityGroups).map((acctType) => permissionGroups[EAccountType[acctType]] as Array<Permission>).filter(Boolean)
          permissions = [...(new Set(
            ...groupBasedPermissions.concat([permissions])
          ))] as Array<Permission>
        }
      } else {
        const isClientContactDocumentViewOnly = SecurityGroups.some((sg: any) => isClientContactDashboardOnlyPermission(sg))
        if (isClientContactDocumentViewOnly) {
          permissions = clientContactPermissionGroups['DocumentOnlyDasboardPermission']
        } else {
          // regular groups
          const groupBasedPermissions = (SecurityGroups).map((acctType) => permissionGroups[EAccountType[acctType]] as Array<Permission>).filter(Boolean)
          // client contact groups
          const clientContactBasedPermissions = (SecurityGroups).map((acctType) => clientContactPermissionGroups[EAccountType[acctType]] as Array<Permission>).filter(Boolean)
          permissions = [...(new Set(
            ...groupBasedPermissions.concat(clientContactBasedPermissions).concat([permissions])
          ))] as Array<Permission>
        }
      }
    }
  } catch (error) {
    // Error parsing permissions
  }

  logAccess(permissions)

  return permissions
}