import { msalInstance } from '@/bootstrap/msal'
import clearPersistStorage from '@/bootstrap/redux/clearPersistStorage'
import { selectImpersonatingUserName } from '@/features/auth/redux/authSelectors'
import { PrimaryButton, Stack } from '@fluentui/react'
import { useCallback } from 'react'
import { useSelector } from 'react-redux'

export default function ImpersonationBanner() {
  const impersonatingUserName = useSelector(selectImpersonatingUserName)
  
  const handleExit = useCallback(async () => {
    await clearPersistStorage()
    msalInstance.logoutRedirect()
  }, [])

  if (!impersonatingUserName) return null

  return (
    <div className='c-impersonation-banner'>
      <Stack 
        tokens={{ childrenGap: 16 }}
        horizontal
        horizontalAlign='center'
        verticalAlign='center'
        className='c-impersonation-banner__stack'
      >
        <Stack.Item className='c-impersonation-banner__stack-item --text'>
          <span className='c-impersonation-banner-name'>Impersonating: <span title={impersonatingUserName} className='c-impersonation-banner-name-title'>{impersonatingUserName}</span></span>
        </Stack.Item>
        <Stack.Item>
          <PrimaryButton iconProps={{ iconName: 'Back' }} onClick={handleExit}>Exit</PrimaryButton>
        </Stack.Item>
      </Stack>
    </div>
  )
}