import {
  LocalizationProvider,
  TranslatedText,
  TranslatedTextProps,
  LocalizationConfig,
  LocalizationProviderProps
} from './components'
import setupI18n from './bootstrap/i18n'

export {
  LocalizationProvider,
  TranslatedText,
  setupI18n
}

export type {
  TranslatedTextProps,
  LocalizationProviderProps,
  LocalizationConfig
}
