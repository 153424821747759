import { Card, SideBarSection, SideBarSectionItem, SkeletonSideBarSectionItem } from '@/shared/components'
import { useGetRecentInsightsQuery } from '@/shared/api/services/insightService'
import dayjs from 'dayjs'
import { Link } from '@fluentui/react'
import constructSkeletons from '@/shared/helpers/constructSkeletons'
import { Link as RRLink } from 'react-router-dom'
import { EDateFormat } from '@/shared/types/date'

const RecentInsights = () => {
  const { data, isError, isFetching, isLoading } = useGetRecentInsightsQuery()
  
  if (isError) return null

  const renderContent = () => {
    if (isFetching || isLoading) {
      const skeletons = constructSkeletons(5, <SkeletonSideBarSectionItem />)
      return ( 
        <div role='presentation'>
          {skeletons}
        </div>
      )
    }
    return data.items.map((insight) => (
      <SideBarSectionItem 
        key={insight.id}
        datetime={dayjs(insight.createdAt).toDate()} 
        dateFormat={EDateFormat.short}
        title={insight.title}
        href={insight.link}
        target="_blank"
        renderLHS={() => <img role='presentation' src={insight.featuredImageLink} alt={insight.featuredImageAlt}/>}
      />
    ))
  }

  return (
    <Card size="large" ariaLabelledBy="recent-insights">
      <SideBarSection expandLink={<Link as={RRLink} to="/insights">View all Insights</Link>} headingText='Recent Insights'>
        {renderContent()}
      </SideBarSection>
    </Card>
  )
}

export default RecentInsights