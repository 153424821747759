import { useGetNavbarRulesQuery } from '@/shared/api/services/legacyService'
import { useSelector } from 'react-redux'
import { selectCAID, selectHasToken } from '../redux/authSelectors'

export default function useAccessRulesQuery() {
  const hasToken = useSelector(selectHasToken)
  const CAID = useSelector(selectCAID)
  const { data, isLoading, isFetching, isError, isSuccess } = useGetNavbarRulesQuery(CAID, { skip: !hasToken })

  return {
    data,
    isLoading: isLoading || isFetching,
    isError,
    hasToken,
    isSuccess,
  }
}