import { useEffect, useState } from 'react'
import { PostModal, GeneralError } from '@/shared/components'
import { EUserCan } from '@/features/auth/types'
import { IYourTeam, useGetYourTeamQuery, useSaveTeamMembersMutation } from '@/shared/api/services/teamService'
import TeamPicker, { TeamPickerMember } from './components/TeamPicker'
import { IDragDropContext, IStackItemStyles, IStackTokens, Label, MessageBarType, PrimaryButton, Stack } from '@fluentui/react'
import useIsMobile from '@/shared/hooks/useIsMobile'
import modalSlice from '@/shared/redux/modalSlice'
import { useDispatch } from 'react-redux'
import { appDispatch } from '@/bootstrap/redux'
import { createUIMessage } from '@/features/uimessages/redux/operations'
import withPermissions, { WithPermissionsProps } from '@/features/auth/components/withPermissions'
import yourTeamModalColumns from './components/YourTeamModalColumns'


export interface YourTeamProps extends WithPermissionsProps {
  accountId: string;
  buttonLabel?: string;
  modalLabel?: string;
}

const MODAL_PARAM_NAME = 'yourTeamModal'
const UI_MESSAGE_DISMISS_TIMEOUT = 3000
const stackToken: IStackTokens = { childrenGap: 16 }

const YourTeam = ({ accountId, buttonLabel, modalLabel }: YourTeamProps) => {
  const dispatch = useDispatch<appDispatch>()
  const [selectedTeamMembers, setSelectedTeamMembers] = useState([] as TeamPickerMember[])
  const { data, isFetching, isLoading, refetch, isError, isUninitialized } = useGetYourTeamQuery(accountId)
  const [saveTeamMembers] = useSaveTeamMembersMutation()
  const inProgress = isUninitialized || isFetching || isLoading
  const isMobile = useIsMobile('md')
  const [ teamMemberList, setTeamMemberList ] = useState([] as IYourTeam[])

  useEffect(() => {
    if (data && data.length) {
      setTeamMemberList([...(data || []).map((t, index) => ({
        ...t,
        sortIndex: (t.sortIndex || (index + 1)),
      }))])
    }
  }, [data])

  const searchItemStyles: IStackItemStyles = {
    root: {
      width: isMobile ? '100%' : '80%'
    },
  }

  const buttonItemStyles: IStackItemStyles = {
    root: {
      width: isMobile ? '100%' : '20%'
    },
  }

  const hasChanges = () => {
    if ((teamMemberList && !data) || (!teamMemberList && data)) {
      return true
    }

    if (teamMemberList && data) {
      if (teamMemberList.length !== data.length) {
        return true
      }

      for (let index = 0, length = teamMemberList.length; index < length; index++) {
        const editedData = teamMemberList[index]
        const sourceData = data[index]
        if ((editedData.peopleId !== sourceData.peopleId) || (editedData.sortIndex !== sourceData.sortIndex)) {
          return true
        }
      }
    }

    return false
  }

  const handleTeamMembersFilter = (teamMembers: TeamPickerMember[]) => {
    const teamMembersListIds = new Set(teamMemberList.map(m => m.peopleId))
    return (teamMembers || []).filter(t => t && t.sourceMember && !teamMembersListIds.has(t.sourceMember.peopleId))
  }

  const handleTeamMembersSelected = (teamMembers: TeamPickerMember[]) => {
    setSelectedTeamMembers(teamMembers)
  }

  const handleAddMember = () => {
    const teamMembersListIds = new Set(teamMemberList.map(m => m.peopleId))
    const newMembers = selectedTeamMembers
      .map(m => m.sourceMember)
      .filter(member => 
        !teamMembersListIds.has(member.peopleId)
      );
  
    setTeamMemberList([
      ...teamMemberList,
      ...newMembers,
    ].map((t, index) => ({
      ...t,
      sortIndex: index + 1,
    })))
    setSelectedTeamMembers([])
  };

  const handleCloseClick = () => {
    if (data && data.length) {
      setTeamMemberList([...(data || []).map((t, index) => ({
        ...t,
        sortIndex: (t.sortIndex || (index + 1)),
      }))])
    }
    refetch()
    setSelectedTeamMembers([])
  }

  const handleEdit = (event: IYourTeam) => {
    // to do
  }

  const handleDelete = (event: IYourTeam) => {
    setTeamMemberList([
      ...teamMemberList.filter(member => member.peopleId !== event.peopleId)
    ].map((t, index) => ({
      ...t,
      sortIndex: index + 1,
    })))
  }

  const handleConfirmClick = () => {
    // Check if there are no changes to save
    if (!hasChanges()) {
      setSelectedTeamMembers([])
      dispatch(createUIMessage({
        key: 'noChangesToSave',
        content: 'No changes to save.',
        messageBarType: MessageBarType.info,
        autoDismissAfter: UI_MESSAGE_DISMISS_TIMEOUT,
      }));
      dispatch(modalSlice.actions.setModalStatus({ paramName: MODAL_PARAM_NAME, value: false }))
      return;
    }

    saveTeamMembers({
      accountId,
      teamMembers: teamMemberList.map(a => ({
        accountId,
        peopleId: a.peopleId,
        sortIndex: a.sortIndex,
      })),
    }).unwrap().then(o => {
      setSelectedTeamMembers([])
      dispatch(createUIMessage({ 
        key: 'saveTeamMembersSuccess', 
        content: 'Saved Team Members successfully.',
        messageBarType: MessageBarType.success,
        autoDismissAfter: UI_MESSAGE_DISMISS_TIMEOUT,
      }));
      dispatch(modalSlice.actions.setModalStatus({ paramName: MODAL_PARAM_NAME, value: false }))
    }).catch(error => {
      dispatch(createUIMessage({ 
        key: 'saveTeamMembersFailure', 
        content: 'Failed to save Team Members.',
        messageBarType: MessageBarType.error,
        autoDismissAfter: UI_MESSAGE_DISMISS_TIMEOUT,
      }));
    })
  }

  const getDragDropEvents = () => {
    return {
      canDrop: (dropContext?: IDragDropContext, dragContext?: IDragDropContext) => {
        return true;
      },
      canDrag: (IYourTeam?: any) => {
        return true;
      },
      onDrop: (item?: IYourTeam, event?: DragEvent) => {
        try {
          const draggedItemKey = Number(event.dataTransfer.getData('sourcePeopleId'));
          const draggedItemIndex = Number(event.dataTransfer.getData('sourceIndex'));
          const targetItem = item
          const targetItemIndex = teamMemberList.findIndex((i) => i.peopleId === targetItem.peopleId);
          const newItems = [...teamMemberList];
          const [removedElement] = newItems.splice(draggedItemIndex, 1);
          newItems.splice(targetItemIndex, 0, removedElement);
          setTeamMemberList(newItems.map((t, index) => ({
            ...t,
            sortIndex: index + 1,
          })));
        } catch (error) {
          console.error('Error while rearranging Team Members.', error)
        }
      },
      onDragStart: (item?: IYourTeam, itemIndex?: number, selectedItems?: any[], event?: DragEvent) => {
        event.dataTransfer.setData('sourcePeopleId', `${item && item.peopleId}`);
        event.dataTransfer.setData('sourceIndex', `${itemIndex}`);
      },
    };
  }

  const renderDescription = () => {
    return (
      <>
        <Stack className='team-picker' style={{ alignItems: 'flex-end' }} tokens={stackToken} horizontal={!isMobile}>
          <Stack.Item styles={searchItemStyles}>
            <Label>Search Team Member</Label>
            <TeamPicker maxTeamMembers={1} selectedTeamMembers={selectedTeamMembers} onTeamMembersFilter={handleTeamMembersFilter} onTeamMembersSelected={handleTeamMembersSelected} />
          </Stack.Item>
          <Stack.Item styles={buttonItemStyles}>
            <PrimaryButton onClick={handleAddMember} disabled={!selectedTeamMembers || !selectedTeamMembers.length}>Add Member</PrimaryButton>
          </Stack.Item>
        </Stack>
      </>
    )
  }

  if (isError) {
    return (<GeneralError onClick={refetch} title='The manage teams modal could not be loaded.' />)
  }

  const columns = yourTeamModalColumns(handleEdit, handleDelete)
  const isSaveButtonDisabled = !hasChanges()

  return (
    <>
      <PostModal
        className='c-team-modal'
        onClose={handleCloseClick}
        onConfirmClick={handleConfirmClick}
        primaryLabel="Save"
        openButtonLabel={buttonLabel || "Manage Your Team"}
        modalTitle={modalLabel || "Manage Your Team"}
        openButtonId="manage-your-team-button"
        shimmerIsEnabled={inProgress}
        paramName={MODAL_PARAM_NAME}
        primaryIsDisabled={isSaveButtonDisabled}
        ariaLabels={{
          openButton: 'Open',
          closeButton: 'Close'
        }}
        footer={<div style={{ marginTop: '24px' }}></div>}
        description={renderDescription()}
        tableProps={{
          columns,
          data: teamMemberList,
          dragDropEvents: getDragDropEvents(),
        }}
      />
    </>
  )
}

export default withPermissions([EUserCan.Edit_OnboardingDashboard, EUserCan.Edit_Account_Settings])(YourTeam)