import { ReactNode } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconProp } from '@fortawesome/fontawesome-svg-core'

interface Props {
  headingIcon?: IconProp
  headingText?: string;
  headingCTA?;
  children: ReactNode;
  expandLink?
}

export default function SideBarSection(props: Props) {
  const { headingIcon, headingText, headingCTA, children, expandLink = null } = props

  return (
    <div className="c-sidebar-section">
      <header className="c-sidebar-section__heading">
        {headingIcon ? <FontAwesomeIcon icon={headingIcon} className="c-sidebar-section__heading-icon" /> : null}
        {headingText ? (
          <h3 className="c-sidebar-section__heading-text">{headingText}</h3>
        ) : null}
        {headingCTA ? (
          <span className="c-sidebar-section__heading-cta">{headingCTA}</span>
        ) : null}
      </header>
      <section className="c-sidebar-section__content">
        {children}
      </section>
      <footer className="c-sidebar-section__footer">
        {expandLink}
      </footer>
    </div>
  )
}
