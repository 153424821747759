import { LicenseManager } from 'ag-grid-enterprise'

export interface AgGridProviderPros {
  base64License: string
  children: any
}

export function AgGridProvider({ base64License, children }: AgGridProviderPros) {
  // ag-grid license
  LicenseManager.setLicenseKey(
    atob(base64License)
  )

  return children
}

export default AgGridProvider
