import { appDispatch } from '@/bootstrap/redux'
import { createUIMessage } from '@/features/uimessages/redux/operations'
import { CollaborationEditData, useAddCollaborationMutation, useDeleteCollaborationMutation, useEditCollaborationMutation } from '@/shared/api/services/boxServices'
import { DetailsList, Dropdown, IColumn, IDropdownOption, MessageBarType, Persona, PersonaPresence, PersonaSize, SelectionMode } from '@fluentui/react'
import { useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Collaborator, CollaboratorAccess } from '../types'
import './ClientDocumentsFolderCollaborators.scss'
import SingleSelectionDropdown from '@/shared/components/SingleSelectionDropdown/SingleSelectionDropdown'

interface IClientDocumentsFolderCollaboratorsProps {
  items: Array<Collaborator>;
  folderId: string;
}

export default function ClientDocumentsFolderCollaborators({ items, folderId }: IClientDocumentsFolderCollaboratorsProps) {
  const dispatch = useDispatch<appDispatch>()
  const [editCollaboration, editMS] = useEditCollaborationMutation()
  const [deleteCollaboration, deleteMS] = useDeleteCollaborationMutation()
  const [addCollaboration, addMS] = useAddCollaborationMutation()
  const isFetchingUpdates = editMS.isLoading || deleteMS.isLoading || addMS.isLoading

  const columns = useMemo(() => {
    return generateColumns(
      async ({ permissionLevel, collaborator }: Parameters<AccessChangeHandler>[0]) => {
        try {
          if (!collaborator?.id && permissionLevel !== 'noaccess') {
            await addCollaboration({ permissionLevel, folderId: folderId, boxId: collaborator.boxId }).unwrap()
          } else if (permissionLevel === 'noaccess') {
            await deleteCollaboration({ collaborationId: collaborator.id }).unwrap()
          } else {
            await editCollaboration({ permissionLevel, collaborationId: collaborator.id }).unwrap()
          }

          dispatch(createUIMessage({ 
            key: 'boxaccesserror', 
            content: 'Your changes have been saved',
            messageBarType: MessageBarType.success,
          }))
        } catch (error) {
          dispatch(createUIMessage({ 
            key: 'boxaccesserror', 
            content: 'There was an error saving your changes',
            messageBarType: MessageBarType.error,
          }))
        }
      },
      isFetchingUpdates
    )
  }, [addCollaboration, deleteCollaboration, dispatch, editCollaboration, folderId, isFetchingUpdates])

  const [ statusFilter, setStatusFilter ] = useState('')
  const statusDropdownOptions = [
    {
      key: 'access',
      text: 'Access',
    },
    {
      key: 'previewer',
      text: 'Previewer',
    },
    {
      key: 'viewer',
      text: 'Viewer',
    },
    {
      key: 'viewer uploader',
      text: 'Viewer Uploader',
    },
    {
      key: 'noaccess',
      text: 'No Access',
    }
  ]

  const statusFilterOption = statusDropdownOptions.find(o => o.key === statusFilter)?.text || ''

  const filteredItems = !statusFilter ? items : (items || []).filter(item => {
    const itemAccess = `${item.access || ''}`.trim()
    if (statusFilter === 'access') {
      return  itemAccess !== 'noaccess'
    }
    return itemAccess === statusFilter
  })

  return (
    <div className='ClientDocumentsFolderCollaborators'>
      <div className='filter'>
        <SingleSelectionDropdown
          options={statusDropdownOptions}
          selectedKey={statusFilter}
          width={180}
          showClearButton={true}
          placeholder='Access Filter'
          onValueChange={(statusOption) => setStatusFilter(statusOption)}
        />
        <span className='filter-description'>
          { filteredItems.length ? `${filteredItems.length} collaborator${filteredItems.length > 1 ? 's' : ''}` : '' }  &nbsp;
          { filteredItems.length && statusFilterOption ? `${filteredItems.length > 1 ? 'have' : 'has'} ${statusFilterOption.toLowerCase()} ${!statusFilter.includes('access') ? 'permission' : ''}`  :  ''}
        </span>
      </div>
      <DetailsList 
        columns={columns}
        selectionMode={SelectionMode.none}
        items={filteredItems}
        className="c-client-documents__collab-table"
      />
    </div>
  )
}

const ACCESS_OPTIONS: Array<Omit<IDropdownOption,'key'> & { key: CollaboratorAccess }> = [
  { key: 'previewer', text: 'Previewer' },
  { key: 'viewer', text: 'Viewer' },
  { key: 'viewer uploader', text: 'Viewer Uploader' },
  { key: 'noaccess', text: 'No Access' }
]
type AccessChangeHandler = (d: { permissionLevel: CollaborationEditData['permissionLevel'], collaborator: Collaborator }) => void
const generateColumns = (handleAccessChange: AccessChangeHandler, disableInteraction: boolean): Array<IColumn> => ([
  {
    key: 'name',
    name: 'Name',
    minWidth: 220,
    maxWidth: 220,
    onRender(collaborator: Collaborator) {
      return (
        <Persona 
          size={PersonaSize.size32}
          onRenderPrimaryText={() => <p>{collaborator.name}</p>}
          onRenderInitials={({ imageInitials }) => <span data-automationid="initials">{imageInitials}</span>}
          imageAlt={collaborator.name}
          imageInitials={collaborator.initials}
          presence={PersonaPresence.none}
        />
      )
    },
  },
  {
    key: 'email',
    name: 'Email Address',
    fieldName: 'email',
    minWidth: 280,
    maxWidth: 280,
  },
  {
    key: 'access',
    name: 'Access',
    onRender(collaborator: Collaborator, i, col) {
      return (
        <Dropdown 
          options={ACCESS_OPTIONS} 
          selectedKey={collaborator.access ?? 'noaccess'} 
          disabled={disableInteraction}
          onChange={(_, option) => {
            handleAccessChange({ 
              permissionLevel: option.key as CollaboratorAccess, 
              collaborator 
            })
          }}
        />
      )
    },
    minWidth: 180
  },
  {
    key: 'createdAt',
    name: 'Date Added',
    fieldName: 'createdAt',
    minWidth: 130,
    maxWidth: 130
  }
])