import dayjs from '@/bootstrap/dayjs'
import { IFeedData, IPodcast } from '../shared/types'

export default function parseRSS(rawData: string): IFeedData {
  const parent = document.createElement('DIV')
  parent.innerHTML = rawData.replace(/<link>([^<]+)<\/link>/g, '<a class="link">$1</a>')
  const allItems = parent.querySelectorAll('item')
  let feedData: IFeedData
  
  try {
    feedData = {
      title: parent.querySelector('title').textContent,
      url: parent.querySelector('.link')?.textContent?.replace?.('\n', '')?.trim?.() || '',
      items: Array.from(allItems).slice(0, 3).map((item): IPodcast => ({
        title: item.querySelector('title').textContent,
        datetime: dayjs(item.querySelector('pubDate').textContent).toDate(),
        href: item.querySelector('.link').textContent,
        img: {
          src: item.querySelector('itunes\\3Aimage').getAttribute('href'),
          alt: 'Unseen Upside Episode Thumbnail'
        }
      }))
    }
  } catch (error) {
    throw new Error('RSS data failed parsing, likely due to formatting or a bad response.')
  }

  return feedData
}