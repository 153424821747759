import { ColumnActionsMode, ConstrainMode } from '@fluentui/react'
import React, { forwardRef, memo, useRef, useState, Ref, useEffect } from 'react'
import ArchiveCell from './components/ArchiveCell'
import EditCell from './components/EditCell'
import MessagesTable from './MessagesTable'
import './MessagesTableActive.scss'
import CreateMessageModal, { CreateMessageModalHandle } from '../CreateMessageModal'
import { useGetMessagesByRecipientIdQuery } from '@/shared/api/services/messageService'
import { IMessageViewRecord } from '@/shared/types/swagger'
import { GeneralError } from '@/shared/components'
import Skeleton from 'react-loading-skeleton'
import { useSelector } from 'react-redux'
import { selectAccountId } from '@/features/auth/redux/authSelectors'
import { useGetBoxTokenQuery, useGetBoxClientRootFolderQuery } from '@/shared/api/services/boxServices'
import { BoxStateType } from '@/shared/types/box'
import { useGetContactsByAccountIdQuery } from '@/shared/api/services/crmService'

interface Props {
  hasActions?: boolean
  accountid?: string
  isClientView?: boolean
}

const EditMessageModal = forwardRef(({ accountid }: Pick<Props, 'accountid'>, ref: Ref<CreateMessageModalHandle>) => {
  const [isOpen, setIsOpen] = useState<boolean>(false)

  // Essential that MessagesTableActive also renders the EditMessageModal so that users can edit their message!
  const accountId = useSelector(selectAccountId)
  const contactsQueryState = useGetContactsByAccountIdQuery(accountid)
  const { data, isLoading, isUninitialized, isError, refetch } = useGetBoxTokenQuery(accountid)
  const { data: rootFolderId } = useGetBoxClientRootFolderQuery(accountid)
  const [boxAccess, setBoxAccess] = useState<BoxStateType>({ token: '', folder: '' })
  useEffect(() => {
    if (!isLoading || !isUninitialized) {
      setBoxAccess({ token: data?.accessToken, folder: rootFolderId })
    }
  }, [isLoading, isUninitialized])
  return (
    <CreateMessageModal ref={ref} isOpen={isOpen} setIsOpen={setIsOpen} accountid={accountId} boxAccess={boxAccess} contacts={contactsQueryState} />
  )
})

EditMessageModal.displayName = 'EditMessageModal'

function MessagesTableActive({ accountid, hasActions = true, isClientView= false }: Props) {
  const ref = useRef<CreateMessageModalHandle>(null)
  const { data, isLoading, isUninitialized, isError, refetch } = useGetMessagesByRecipientIdQuery(accountid)

  const renderEditCellWithFunction = (d: IMessageViewRecord) => (
    <EditCell message={d} onClick={ref.current?.setValuesAndOpen} />
  )

  if (isError) return <GeneralError title='Failed to load recent documents' onClick={refetch} />
  // TODO: add loading to table
  if (isLoading || isUninitialized) return <Skeleton height={200} width="100%" />

  return (
    <>
      { data?.length === 0 ?
        <p className='c-messages__no-messages'>You have no active messages</p>
        :
        <MessagesTable
          sectionProps={{
            title: 'Active Messages',
            className: 'c-active-table'
          }}
          headingTKey="activeMessages"
          // extraStartColumns={{
          //   unread: {
          //     key: 'unread',
          //     name: '',
          //     className: 'c-active-table__unread__column',
          //     fieldName: 'isArchived',
          //     minWidth: 1,
          //     maxWidth: 10,
          //     onRender: UnreadCircleCell,
          //     columnActionsMode: ColumnActionsMode.disabled,
          //   }
          // }}
          data={data}
          constrainMode={ConstrainMode.unconstrained}
        />
      }
      <EditMessageModal ref={ref} accountid={accountid} />
    </>
  )
}

export default memo(MessagesTableActive)
