import { RootState } from '@/bootstrap/redux'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export type ValidModalParamNames = keyof InitialState['modalStatus']

type InitialState = {
  modalStatus: {
    eventModal: boolean,
    manageInsight: boolean,
    mediaModal: boolean,
    maintenance: boolean,
    ManageFAQ: boolean
    yourTeamModal: boolean,
    manageRegionalOfficeModal: boolean,
    manageWelcomeMessageModal: boolean,
    manageOnboardingTextModal: boolean,
  }
}

export const initialState: InitialState = {
  modalStatus: {
    eventModal: false,
    manageInsight: false,
    mediaModal: false,
    maintenance: false,
    ManageFAQ: false,
    yourTeamModal: false,
    manageRegionalOfficeModal: false,
    manageWelcomeMessageModal: false,
    manageOnboardingTextModal: false,
  }
}

export default createSlice({
  name: 'modals',
  initialState,
  reducers: {
    toggleModalStatus: (state, action: PayloadAction<string>) => {
      state.modalStatus[action.payload] = !state.modalStatus[action.payload]
    },
    setModalStatus: (state, action: PayloadAction<{ paramName: ValidModalParamNames , value: boolean }>) => {
      state.modalStatus[action.payload.paramName] = action.payload.value
    }
  },
})

export const selectModalStatuses = (state: RootState) => state.modals.modalStatus