import { FocusZone, FocusZoneDirection } from '@fluentui/react'
import { ReactNode } from 'react'
import './TabList.scss'

interface ITabListProps {
  children: ReactNode;
}

export default function TabList({ children }: ITabListProps) {
  return (
    <FocusZone direction={FocusZoneDirection.horizontal}>
      <div role="tablist" className='c-tablist'>
        {children}
      </div>
    </FocusZone>
  )
}