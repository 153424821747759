import { ModalCheckbox, TableLinkCell } from '@/shared/components'
import { TableProps } from '@/shared/components/Table'
import { ColumnActionsMode } from '@fluentui/react'
import { nanoid } from '@reduxjs/toolkit'
import EventModalActions from '../components/EventModal/components/EventModalActions'
import EventTypeCell from '../shared/components/EventTypeCell'
import dayjs from 'dayjs'
import { IEvent } from '@/shared/types/swagger'
// keys: event: { label, url }, type, published, isFeatured

type EventModalColumnsProps = (
  isBeingManuallyManaged: boolean, 
  onFeaturedClick: (item: unknown, index: number) => void,
  handleError: (status: boolean, message: string) => void,
  handleEdit: (event: IEvent) => void
  ) => TableProps['columns']

const eventModalColumns: EventModalColumnsProps = (isBeingManuallyManaged, onFeaturedClick, handleError, handleEdit) => [
  {
    key: 'eventColumn',
    name:'Event',
    fieldName: 'event',
    columnActionsMode: ColumnActionsMode.disabled,
    sortKey: 'label',
    flexGrow: 3,
    minWidth: 100,
    onRender: (item: IEvent, index, column) => { 
      return (
        <TableLinkCell label={item.title} pageUrl={item.eventUrl} />
      )
    }
  },
  {
    key: 'typeColumn',
    name: 'Type',
    fieldName: 'status',
    columnActionsMode: ColumnActionsMode.disabled,
    minWidth: 75,
    maxWidth: 125,
    onRender: (item: IEvent) => (
      <EventTypeCell event={item} />
    )
  },
  {
    key: 'publishedColumn',
    name: 'Published',
    fieldName: 'publishedDate',
    columnActionsMode: ColumnActionsMode.disabled,
    minWidth: 100,
    onRender: (item: IEvent) => <span>{dayjs.utc(item.startEventDate).format('L')}</span>
  },
  {
    key: 'featuredColumn',
    name: 'Featured',
    fieldName: 'isFeatured', 
    minWidth: 100,
    columnActionsMode: ColumnActionsMode.disabled,
    onRender: (item, index) => {
      return (
        <ModalCheckbox 
          key={nanoid(6)} // We have to reset the key otherwise this component does not re-render when we sort the columns.
          isChecked={item.isFeatured}
          index={index}
          onChange={onFeaturedClick} 
          item={item}
          disabled={!isBeingManuallyManaged}
          isRounded
        />
      )
    }
  },
  {
    key: 'actionsColumn',
    name: 'Actions',
    fieldName: 'actions',
    minWidth: 100,
    columnActionsMode: ColumnActionsMode.disabled,
    onRender: (item: IEvent) => {
      return (
        <EventModalActions 
          handleEdit={handleEdit}
          setGeneralError={handleError}
          event={item}
        />
      )
    }
  },
]

export default eventModalColumns