import { Section, Card } from '@/shared/components'
import { ContentExplorer } from 'box-ui-elements/es/elements'
import { IntlProvider } from 'react-intl'

const DEV_TOKEN = ''

const OnboardingBox = () => {

  return (
    <IntlProvider locale="en">
      <Section className='c-onboarding-box'>
        <Card 
          ariaLabelledBy='onboarding-box-header'
        >
          <h3 id="onboarding-box-header" className='c-onboarding-box__header'>My Documents</h3>
          <p className='c-onboarding-box__body'>Onboarding Documents</p>
          <ContentExplorer 
            token={DEV_TOKEN}
            canUpload={false}
          />
        </Card>
      </Section>
    </IntlProvider>
  )
}

export default OnboardingBox