import { useGetInsightsQuery, useGetInsightArticleDataQuery } from '@/shared/api/services/insightService'
import { IInsightsPostViewWithAlt, IInsightTag } from '../shared/types'

type GetInsightHookReturn = ReturnType<typeof useGetInsightsQuery>
interface GetInsightArticleHookReturn extends Omit<ReturnType<typeof useGetInsightArticleDataQuery>, 'data'> {
  data: { items: Array<IInsightsPostViewWithAlt> }
}

type UseInsightListReturn = GetInsightHookReturn | GetInsightArticleHookReturn
function useInsightList<B extends boolean>(isDashboard: B, pageNumber?: number, searchQuery?: string, tags?: Array<string>): B extends true ? GetInsightArticleHookReturn : GetInsightHookReturn
function useInsightList(isDashboard: boolean, pageNumber?: number, searchQuery?: string, tags?: Array<string>): UseInsightListReturn {
  const insightData = useGetInsightsQuery({ pageSize: 10, page: pageNumber, titleFilter: searchQuery, descriptionFilter: searchQuery, tagFilter: tags }, { skip: isDashboard })
  const articleData = useGetInsightArticleDataQuery(null, { skip: !isDashboard })
  if (isDashboard) {
    return {
      ...articleData,
      // Setting up data layout as the same as useGetInsightsQuery
      data: articleData?.data ? { items: [articleData.data.featuredInsight].concat(articleData.data.displayedInsights) } : null
    }
  }
  return insightData
}

export default useInsightList