import FAQ from '@/features/support/components/FAQ'
import { OfficeCard } from '@/shared/components'

const OnboardingQuestions = () => {
  return (
    <section className='c-onboarding-questions'>
      <h3 className='c-onboarding-questions__header' id="faq">Frequently asked questions</h3>
      <div className='c-onboarding-questions__content'>
        <FAQ />
        <OfficeCard />
      </div>
    </section>
  )
}

export default OnboardingQuestions