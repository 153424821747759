export const DEV_CONSTANTS = {
    DATA_DOG_SERVICE: 'client-portal-dev',
    DATA_DOG_APP_ID: 'NzYyMWEwODEtNTI0Yi00NGE3LThkZGUtYzVjMjQyNzdhMzdj',
    DATA_DOG_CLIENT_TOKEN: 'cHViNjg5YjAyOGFmOWY5ODUyYzc0Mzc3MWQ5NDg0YmJjOTg=',
    MSAL_TENANT: 'caclientportaldev',
    MSAL_CLIENT_ID: 'a122e68e-22f8-4c0d-b168-8740a2163d1f',
    MSAL_REDIRECT_URL: 'https://dev.clientportal.cambridgeassociates.com',
    API_BASE_URL: 'https://dev.api.clientportal.cambridgeassociates.com/api/exp',
    EMMA_API_BASE_URL: 'https://dev.api.clientportal.cambridgeassociates.com/api/emma',
    IMAGE_BUCKET_BASE_PATH: 'https://dev.clientportal.cambridgeassociates.com/img/',
    VIDEO_MAX_UPLOAD_SIZE: 524288000,
    ILEVEL_CA_EMPLOYEE_URL: 'https://launcher.myapps.microsoft.com/api/signin/9cfaddc9-f0b0-4858-b30f-a4459b99c205?tenantId=4b1c6b54-4031-481e-b9c8-9000b4e3b2c2&relaystate=https://sand.ilevelsolutions.com?idp=sam',
    ILEVEL_CLIENT_CONTACT_URL: 'https://caclientportaldev.b2clogin.com/CAClientPortalDev.onmicrosoft.com/B2C_1A_SAML2_IdP_Init_signup_signin/generic/login?EntityId=https://sam.ihsmarkit.com/sso/Cambridge_Associates_External_DEV-B2HuT7b7XI&RelayState=https://sand.ilevelsolutions.com?idp=sam',
    PW_CA_EMPLOYEE_URL: 'https://dev-f.pw.dev.cambridgeassociates.cloud/',
    PW_CLIENT_CONTACT_LINK_URL: 'https://dev-f.pw.dev.cambridgeassociates.cloud/',
    UPLOAD_NOTIFICATION_EMAIL_DELAY_IN_MINUTES: 15,
    MORE_LINKS: {
        'PRSnapshot': 'http://membersx.cambridgeassociates.com/memberprofile/poolretsnapshot/snapsessionsetter.aspx?ID=93247',
        'AnnualManagerReport': 'http://membersx.cambridgeassociates.com/managers/managernotifications/reports.asp',
        'IndexSnapshots': 'http://membersx.cambridgeassociates.com/Markets/Members_Markets_Current.asp',
        'ManagerNotices': 'http://membersx.cambridgeassociates.com/managers/managernotifications/alerts.asp',
        'ExhibitFinder': 'http://membersx.cambridgeassociates.com/Markets/Members_Markets_Exhibit.asp',
        'MMOS': 'http://membersx.cambridgeassociates.com/managers/onestop/external/OneStop_External.asp?cmd=keywordsearch&Section=MMOS',
        'PreliminaryPerformanceBulletin': 'http://membersx.cambridgeassociates.com/MemberProfile/instbulletin.asp?intPage=0',
        'PIMOS': 'http://membersx.cambridgeassociates.com/memberindex.asp?Type=Grant&App=SSPIUHF09239F7RS',
        'MarketIndexDescriptions': 'http://membersx.cambridgeassociates.com/Markets/Indexdefs.asp',
        'ProprietaryPIB': 'https://www.cambridgeassociates.com/benchmarks/',
        'AnnualInvestmentPRSurvey': 'http://membersx.cambridgeassociates.com/FoundationSurvey.asp',
        'HistoricalPIB': 'http://membersx.cambridgeassociates.com/memberprofile/historicalbenchmark/',
        'InvestmentPRQuarterlySurvey': 'http://membersx.cambridgeassociates.com/memberprofile/members_profile_questionnaire.asp',
        'ClientSnapshot': 'http://membersx.cambridgeassociates.com/MemberProfile/MemberOneStop/Shared/client_snapshot.asp',
        'MPME': 'https://clients-dev.cambridgeassociates.com/#/mpme',
        'OpticaResearchPrivate': 'http://membersx.cambridgeassociates.com/managers/rn-current/private-investments',
        'OpticaResearchPublic': 'http://membersx.cambridgeassociates.com/managers/rn-current/public-investments',
        'OpticaBenchmark': 'https://pibenchmarkingca-dev.cambridgeassociates.com/#/funds',
        'OpticaPeers': 'http://membersx.cambridgeassociates.com/MemberProfile/MemberOneStop/External/onestop_external.asp'
    },
};
