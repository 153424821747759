import { useGetAllOfficesByAccountIdQuery } from "@/shared/api/services/caOffficeService";
import { OfficeCard } from "@/shared/components"

export interface OnboardingOfficeProps {
  accountId: string;
}

const OnboardingOffice = ({ accountId }: OnboardingOfficeProps) => {
  const { data } = useGetAllOfficesByAccountIdQuery(accountId)
  const caOffice = (data || []).find(o => !!o.isSelected)
  return (
    <>
      <OfficeCard caOffice={caOffice} />
    </>
  )
}

export default OnboardingOffice