import { TableProps } from '@/shared/components'
import { ColumnActionsMode, Icon, IconButton, Stack } from '@fluentui/react'
import FAQTableActions from '../components/ManageFAQ/components/FAQTable/components/FAQTableActions'
import FAQTableAnswer from '../components/ManageFAQ/components/FAQTable/components/FAQTableAnswer'
import FAQTableQuestion from '../components/ManageFAQ/components/FAQTable/components/FAQTableQuestion'
import FAQTableVisible from '../components/ManageFAQ/components/FAQTable/components/FAQTableVisible'
import { FAQ } from '../types/faq'

type TableOptionsReturn = (
  editingId: string,
  setQuestionBeingAdded: (newValue: FAQ) => void,
) => TableProps['columns']

const manageColumns: TableOptionsReturn = (editingId, setQuestionBeingAdded) => [
  {
    key: 'questionCategory',
    name: 'Question',
    fieldName: 'questionText',
    minWidth: 100,
    maxWidth: 250,
    flexGrow: 1,
    columnActionsMode: ColumnActionsMode.disabled,
    onRender: (item: FAQ) => {
      return (
        <FAQTableQuestion beingEdited={item?.id === editingId} item={item} />
      )
    }
  },
  {
    key: 'answerCategory',
    name: 'Answer',
    fieldName: 'questionResponse',
    minWidth: 200,
    flexGrow: 2,
    columnActionsMode: ColumnActionsMode.disabled,
    onRender: (item: FAQ) => (
      <FAQTableAnswer beingEdited={item?.id === editingId} item={item} />
    )
  },
  {
    key: 'visibleCategory',
    name: 'Visible',
    fieldName: 'isVisible',
    columnActionsMode: ColumnActionsMode.disabled,
    minWidth: 100,
    onRender: (item: FAQ) => (
      <FAQTableVisible beingEdited={item?.id === editingId} item={item} />
    )
  },
  {
    key: 'edit',
    columnActionsMode: ColumnActionsMode.disabled,
    name: '',
    minWidth: 90,
    onRender: (item: FAQ) => (
      <FAQTableActions setQuestionBeingAdded={setQuestionBeingAdded} faq={item} editingId={editingId} />
    )
  }
]

export default manageColumns