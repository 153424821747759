import { mainService } from '.'

const cookieService = mainService
  .enhanceEndpoints({ addTagTypes: ['AppCookie', 'IdCookie'] })
  .injectEndpoints({
    endpoints(build) {
      return {
        getApplicationCookies: build.query<unknown, string>({
          query: (CAID) => ({ url: '/Cookie/applications', method: 'GET' }),
          providesTags: (_, err, arg) => ([{ type: 'AppCookie', id: arg }])
        }),
        getIdentityCookies: build.query<unknown, string>({
          query: (CAID) => ({ url: '/Cookie/identity', method: 'GET' }),
          providesTags: (_, err, arg) => ([{ type: 'IdCookie', id: arg }])
        }),
        getPublishedResearchCookie: build.query<unknown, string>({
          query: () => ({ url: '/Cookie/applications', method: 'GET' }),
          async onQueryStarted(_, { queryFulfilled }) {
            await queryFulfilled
            window.location.href = 'https://publishedresearch.cambridgeassociates.com/'
          }
        })
      }
    },
  })

export const { useLazyGetApplicationCookiesQuery, useLazyGetIdentityCookiesQuery, useGetPublishedResearchCookieQuery } = cookieService
