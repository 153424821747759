import { IMaintenanceBannerSettings } from '@/shared/types/swagger'
import { mainService } from '.'

const portalService = mainService
  .enhanceEndpoints({ addTagTypes: ['Maintenance'] })
  .injectEndpoints({
    endpoints: (build) => ({
      getMaintenanceData: build.query<IMaintenanceBannerSettings, void>({
        query: () => ({ url: '/Portal/Maintenance', method: 'GET' }),
        providesTags: ['Maintenance'],
      }),
      updateMaintenanceData: build.mutation<IMaintenanceBannerSettings, Partial<IMaintenanceBannerSettings>>({
        query: (data) => ({ url: '/Portal/Maintenance', method: 'POST', data }),
        invalidatesTags: ['Maintenance']
      })
    }),
  })

export const { useGetMaintenanceDataQuery, useUpdateMaintenanceDataMutation } = portalService