import { useTranslation, Trans } from 'react-i18next'

const SUPPORT_EMAIL = 'AccessCA@cambridgeassociates.com'

const HelpPrompt = () => {
  const { t } = useTranslation('onboarding')
  return (
    <div className='c-onboarding-assistance-prompt'>
      <h3 className='c-onboarding-assistance-prompt__header'>{t('needAssistance')}</h3>
      <dl className='c-onboarding-assistance-prompt__list'>
        <dt className='c-onboarding-assistance-prompt__list__title'>{t('email')}</dt>
        <dd className='c-onboarding-assistance-prompt__list__detail'>
          <Trans i18nKey='emailPrompt' t={t}>
            <a href={`mailto:${SUPPORT_EMAIL}`}>
              {{ email: SUPPORT_EMAIL }}
            </a>
          </Trans>
        </dd>
      </dl>
    </div>
  )
}

export default HelpPrompt