import { memo } from 'react'
import { ModalManagement } from '@/shared/components'
import EventModalFilters from './components/EventModalFilters'

type EventModalDescriptionProps = {
  userIsManagingEvents: boolean,
  setUserIsManagingEvents: (value: boolean) => void
  handleNewEvent: () => void
}

const EventModalDescription = ({ 
  userIsManagingEvents, 
  setUserIsManagingEvents,
  handleNewEvent
}: EventModalDescriptionProps) => {
  return (
    <>
      <ModalManagement 
        userIsManaging={userIsManagingEvents} 
        setUserIsManaging={setUserIsManagingEvents} 
        manuallyManageLabel='Let me choose which event to feature'
        autoManageLabel='Feature the most recent event'
      />
      <EventModalFilters handleNewEvent={handleNewEvent} />
    </>
  )
}

export default memo(EventModalDescription)