import { FAQ } from '@/features/support/types/faq'
import { Checkbox, Stack } from '@fluentui/react'
import { useDispatch, useSelector } from 'react-redux'
import faqSlice, { selectQuestionBeingEdited } from '@/features/support/redux/faqSlice'

type FAQTableVisibleProps = {
  item: FAQ
  beingEdited: boolean,
}

const FAQTableVisible = ({ item, beingEdited }: FAQTableVisibleProps) => {
  const dispatch = useDispatch()
  const faqQuestion = useSelector(selectQuestionBeingEdited)
  
  const onCheckClick = (ev, isChecked: boolean) => {
    dispatch(faqSlice.actions.setQuestionData({ 
      key: 'isVisible',
      newValue: isChecked
    }))
  }

  if (beingEdited) {
    return (
      <Stack tokens={{ childrenGap: 4 }} verticalAlign="space-between" style={{ paddingTop: '8px' }}>
        <Checkbox 
          onChange={onCheckClick}
          ariaLabel="Toggle question visibility" 
          checked={faqQuestion.isVisible}
        />
      </Stack>
    )
  }

    return (
      <div style={{ paddingTop: '8px' }}>
        <Checkbox 
          ariaLabel="Toggle question visibility" 
          disabled={!beingEdited}
          checked={item.isVisible}
        />
      </div>
  )
}

export default FAQTableVisible