import { TeamMember } from '@/shared/types/teamMember'
import { IPersonaProps, Persona, PersonaSize } from '@fluentui/react'
import cn from 'classnames'
import Skeleton from 'react-loading-skeleton'

interface Props extends IPersonaProps {
  data?: TeamMember; 
}

export const TeamMemberDetailSkeleton = () => (
  <div role='presentation' className='c-team-member-skeleton'>
    <Skeleton circle width={32} height={32} />
    <div className='c-team-member-skeleton__content'>
      <div className='c-team-member-skeleton__content__title'>
        <Skeleton width={100} height={14} />
      </div>
      <div className='c-team-member-skeleton__content__text'>
        <Skeleton width={200} height={12} />
      </div>
      <div className='c-team-member-skeleton__content__text'>
        <Skeleton width={250} height={12} />
      </div>
      <div className='c-team-member-skeleton__content__text'>
        <Skeleton width={125} height={12} />
      </div>
    </div>
  </div>
)

export default function TeamMemberDetail(props: Props) {
  const { data, size: _size, ...restProps } = props
  const { fullName, jobTitle, email, profileImg, phone } = data
  const size = _size || PersonaSize.size72

  const initials: string = fullName[0].toUpperCase()

  const renderTertiaryText = () => (
    <>
      <a className="c-team-member__tertiary-text" href={`mailto:${email}`}>
        {email}
      </a>
      {
        phone ? <p className='c-team-member__tertiary-text--phone'>{phone}</p> : null
      }
    </>
  )

  return (
    <Persona 
      imageUrl={profileImg ? profileImg?.url : undefined}
      imageAlt={profileImg ? profileImg?.alt : undefined}
      imageInitials={initials}
      text={`${fullName}`}
      secondaryText={jobTitle}
      tertiaryText={email}
      onRenderTertiaryText={renderTertiaryText}
      size={size}
      className={cn('c-team-member', {
        '--small': size === PersonaSize.size72,
        '--large': size === PersonaSize.size120
      })}
      hidePersonaDetails={false}
      imageShouldFadeIn
      showInitialsUntilImageLoads
      {...restProps}
    />
  )
}
