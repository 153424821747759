import { Persona, PersonaSize } from '@fluentui/react'

type LocationDetailProps = {
  locationImage?: {
    url: string,
    alt: string
  }
  locationName: string
  addressLineOne: string,
  addressLineTwo: string,
  phone: string
}

const LocationDetail = ({ locationImage, locationName,  addressLineOne, addressLineTwo, phone }: LocationDetailProps) => {
  return (
    <Persona 
      className='c-location-detail'
      imageUrl={locationImage ? locationImage?.url : undefined}
      imageAlt={locationImage ? locationImage?.alt : undefined}
      size={PersonaSize.size120}
      coinSize={32}
      showInitialsUntilImageLoads
      text={locationName}
      secondaryText={addressLineOne}
      tertiaryText={addressLineTwo}
      optionalText={phone}
    />
  )
}

export default LocationDetail