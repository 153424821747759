import { DefaultButton, Dialog, DialogFooter, DialogType, PrimaryButton } from '@fluentui/react'
import cn from 'classnames'
import { ReactNode } from 'react'

type ConfirmationProps = {
  handleDismiss: () => void;
  handleNo?: () => void;
  handleYes?: () => void;
  hidden: boolean;
  title: string;
  subText: string | ReactNode;
  modalClassName?: string;
  renderDefault?: () => ReactNode; 
  renderPrimary?: () => ReactNode;
}

export default function Confirmation(props: ConfirmationProps) {
  const { handleDismiss, handleNo, handleYes, hidden, title, subText, modalClassName, renderDefault, renderPrimary } = props

  return (
    <Dialog
      hidden={hidden}
      dialogContentProps={{
        type: DialogType.close,
        title: title,
        // @ts-expect-error overriding functionality to allow jsx children
        subText: subText
      }}
      modalProps={{ className: cn('c-confirmation', modalClassName) }}
      onDismiss={handleDismiss}
    >
      <DialogFooter>
        {renderDefault ? renderDefault() : <DefaultButton onClick={handleNo}>No</DefaultButton>}
        {renderPrimary ? renderPrimary() : <PrimaryButton onClick={handleYes}>Yes</PrimaryButton>}
      </DialogFooter>
    </Dialog>
  )
}