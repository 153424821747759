import { memo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, MessageBarType } from '@fluentui/react'
import { sanitize } from 'dompurify'
import formatDateRange from '@/shared/helpers/formatDateRange'
import { EDateFormat } from '@/shared/types/date'
import InsightsTags from '@/features/insight/shared/components/InsightsTags'
import FileUpload from '../FileUpload'
import { useUploadImageMutation } from '@/shared/api/services/insightService'
import { IInsightTag } from '@/features/insight/shared/types'
import { getImageSrcForBucket } from '@/features/events/helpers/getImageSrc'
import { appDispatch } from '@/bootstrap/redux'
import { useDispatch } from 'react-redux'
import { createUIMessage } from '@/features/uimessages/redux/operations'

type FeatureProps = {
  additionalClassName?: string,
  id?: number,
  heading: string,
  body?: string,
  href: string,
  date: Date,
  endDate?: Date,
  image: {
    src: string
    alt: string
  }
  tags?: string[]
  location?: string
  setTags?: (tags: IInsightTag) => void
  onFeatureLinkClick?: () => void
}

const UPLOAD_ERROR_MESSAGE = 'Image file upload has exceeded the max limit of 5MB'

const Feature = ({ id, additionalClassName, heading, body, href, date, image, location, endDate, tags, setTags, onFeatureLinkClick }: FeatureProps) => {
  const dispatch = useDispatch<appDispatch>()
  const { t } = useTranslation('common')
  const formattedDate = formatDateRange(EDateFormat.long, date, endDate)
  const [file, setFile] = useState<string>(image.src);
  const [uploadImage] = useUploadImageMutation();
  const updateTags = (tags: IInsightTag) =>{
    if(setTags)
      setTags(tags)
  }
  const handleUpload = async (file: File) => {
    // skips upload if no files selected
    if (!file) {
      return;
    }

    try {
      // creates upload model 
      const formData = new FormData();
      formData.append('Id', id.toString());
      formData.append('file', file);

      // uploads insight file and awaits for file url
      // unwrapping to rely on return and not on tag state
      const insightUploadImage = await uploadImage(formData).unwrap();

      // updates file if successful upload
      if (insightUploadImage) {
        setFile(getImageSrcForBucket(insightUploadImage.URL,'insights/'));
      }
    } catch (error) {
      const { exceptionType } = error || {} as any
      if (exceptionType === 'MAX_UPLOAD_SIZE_EXCEPTION') {
        dispatch(createUIMessage({ 
          key: 'uploadSizeError', 
          content: UPLOAD_ERROR_MESSAGE,
          messageBarType: MessageBarType.error,
          autoDismissAfter: 3000
        }))
        setFile(image.src)
      }
    }
  }

  const onLinkClick = () => {
    if (onFeatureLinkClick) {
      onFeatureLinkClick()
    }
  }

  return (
    <div className={`c-featured ${additionalClassName || ''}`}>
      <article className="c-featured__content">
        <span className="c-featured__supheading">{t('featured')}</span>
        <h2 className="c-featured__heading">
          <Link href={href} target="_blank" onClick={onFeatureLinkClick}>{heading}</Link>
        </h2>
        { body ? <p className='c-featured__body' dangerouslySetInnerHTML={{ __html: sanitize(body) }}/> : null }
        <time className="c-featured__dt" dateTime={formattedDate}>{formattedDate}</time>
        {(tags && tags.length !== 0) && <InsightsTags tags={tags} classNames="c-insights-tags c-insights-tags--white" setTags={updateTags} style={{ cursor: 'pointer' }}/>}
        { location && <address className='c-featured__location'>{location}</address> }
      </article>
      <div className="c-featured__bg">
        { typeof id === 'number' && <FileUpload id={id.toString()} handleUpload={handleUpload} accept="image/*" style={{ right: 0 }}/> }
        <img className="c-featured__bg-img" src={file} alt={image.alt} />
      </div>
    </div>
  )
}

export default memo(Feature)