import { CurrencyColumn, TextColumn } from '@/lib/common/components/grid/Columns'
import DataGrid, { DataGridInitEvent } from '@/lib/common/components/grid/Grid'
import useIsMobile from '@/shared/hooks/useIsMobile'
import { GridApi } from 'ag-grid-community'
import { IPortfolioSettings, getGridExcelStyles } from '@/shared/api/services/portfolioService'
import { PerformanceSleeveColumn } from '@/screens/ClientPortfolio/Components/Grid/Column/PerformanceSleeveColumn'

import './MarketValueDetails.scss'

export interface MarketValueProps {
  marketValueDataReport: any,
  portfolioSettings: IPortfolioSettings
  sendGridApi: (gridApi: GridApi) => void
}

export default function MarketValueDetails({
  marketValueDataReport,
  portfolioSettings,
  sendGridApi
}: MarketValueProps) {
  const isMobile = useIsMobile('xs')
  const isMdMobile = useIsMobile('md')
  const getStatusAggregate = (sleevName) => {
    const { StatusAggregate } = sleevName || {}
    let disName = ''

    if (StatusAggregate) {
      if (StatusAggregate === 'Preliminary') {
        disName = " * "
      } else if (StatusAggregate === 'PROXY') {
        disName = " + "
      }
    }

    return `${sleevName.title}${disName}`
  }

  const getCurrencySymbol = (data) => {
    return data?.currencySymbol
  }

  const onGridInit = (event: DataGridInitEvent) => {
    const { gridApi } = event
    sendGridApi(gridApi)
  }
  return (
    <DataGrid
      showToolBar={false}
      rows={marketValueDataReport}
      enablePagination={false}
      domLayout='normal'
      className='MarketValueGrid'
      onInit={onGridInit}
      excelStyles={getGridExcelStyles({
        currencySymbol: portfolioSettings?.currency?.symbol,
        dateFormat: portfolioSettings?.dateFormat,
      })}
    >
      {/* <PerformanceSleeveColumn cellClass={() => 'c-mv-tab'} disableFilterColumn={true} title='' field='title' checkboxSelection={false} /> */}
      <TextColumn cellClass='c-mv-tab' disableFilterColumn={true} title='' field='title' checkboxSelection={false}  />
      <CurrencyColumn title='' decimalScale={0} disableFilterColumn={true} field='value' width={(isMdMobile ? 140 : (isMobile ? 150 : 130))} getCurrencySymbol={getCurrencySymbol} />
    </DataGrid>
  )
}
