import { useGetInsightArticleDataQuery } from '@/shared/api/services/insightService'
import { Feature, SkeletonFeature, GeneralError } from '@/shared/components'
import { IInsightTag } from '../../shared/types';
import { trackFeaturedInsightViewAction } from '@/utils/components/datadog/DatadogSessionProvider';

export type FeaturedInsightProps = {
  setTags?: (tags: IInsightTag) => void
} 

const FeaturedInsight = ({ setTags }: FeaturedInsightProps) => {
  const updateTags = (tags: IInsightTag) =>{
    if (setTags)
      setTags(tags)
  }
  const { data, isFetching, isLoading, refetch, isError } = useGetInsightArticleDataQuery()

  if (isError) {
    return <GeneralError title='The featured insight could not be loaded' onClick={refetch} />
  }

  if (isLoading || isFetching) {
    return <SkeletonFeature className='c-insight-skeleton-feature' />
  }

  const onFeatureLinkClick = () => {
    if (data && data.featuredInsight) {
      trackFeaturedInsightViewAction(data.featuredInsight)
    }
  }

  return (
    <Feature 
      id={data.featuredInsight.id}
      heading={data.featuredInsight.title} 
      date={new Date(data.featuredInsight.createdAt)}
      href={data.featuredInsight.link}
      image={{ 
        src: data.featuredInsight.featuredImageLink,  
        alt: data.featuredInsight.featuredImageAlt,
      }}
      tags={data.featuredInsight.tags}
      setTags={updateTags}
      onFeatureLinkClick={onFeatureLinkClick}
    />
  )
}

export default FeaturedInsight