import { useGetDashboardSettingsByAccountIdQuery } from '@/shared/api/services/legacyService'
import { Link } from '@fluentui/react'
import { useTranslation } from 'react-i18next'
import { Link as RRLink } from 'react-router-dom'
import { isPortfolioDashboardSettingsEnabled } from '../ClientSettings.model'

import './ManageDashboard.scss'

export interface ActionsCellProps {
  accountId: string;
}

export default function ManageDashboard({ accountId }: ActionsCellProps) {
  const { t } = useTranslation()
  const { data } = useGetDashboardSettingsByAccountIdQuery(accountId)
  return (
    <div className='ManageDashboard'>
      {data && data.enableClientOnboardingDashboard ? (
        <Link className='ManageDashboardLink' as={RRLink} to={`/onboarding/${accountId}`}>{t('clients:manageDashboard')}</Link>
      ) : null}
      {isPortfolioDashboardSettingsEnabled(data) ? (
        <Link className='ManageDashboardLink'  as={RRLink} to={`/preview/dashboard/${accountId}`}>{t('clients:previewPortfolioViews')}</Link>
      ) : null}
    </div>
  )
}
