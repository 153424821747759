import Skeleton from 'react-loading-skeleton'

const SkeletonClientPortfolioPerformance = () => {
  return (
    <div className='c-insight-skeleton' role='presentation'>
      <div className='client-portfolio-skeleton__left'>
        <div className='c-insight-skeleton__left__title'>
          <Skeleton width='100%' count={2} />
        </div>
        <Skeleton width={80} />
        <div className='c-insight-skeleton__left__description'>
          <Skeleton width='100%' count={3} />
        </div>
        <Skeleton width={70} />
        <Skeleton width='100%' height='100px' />
      </div>
    </div>
  )
}

export default SkeletonClientPortfolioPerformance