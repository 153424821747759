import { routes } from '@/bootstrap/router'
import { linkContent } from '@/features/more/constants/linkContent'
import { Card, MainLayout, Section } from '@/shared/components'
import { ILegacyAppDisplayRuleResult } from '@/shared/types/swagger'
import { Breadcrumb, IBreadcrumbItem, Icon, Link, Shimmer, ShimmerElementType, } from '@fluentui/react'
import { Redirect, RouteComponentProps } from 'react-router-dom'
import moreChildContent from './constants/content'
import { Link as RRLink } from 'react-router-dom'
import useExtAppAccess from '@/features/more/hooks/useExtAppAccess'
import { displayKeyLinkMap } from '@/features/more/constants/moreLinksDisplay'

export default function MoreChild(props: RouteComponentProps<{application: keyof ILegacyAppDisplayRuleResult}>) {
  const { match } = props
  const { application } = match.params
  const appId = displayKeyLinkMap[application]
  const { title } = linkContent[appId]
  const content = moreChildContent[application]
  const [hasAccess, accessLoading] = useExtAppAccess(application)

  const breadcrumbs: Array<IBreadcrumbItem> = [
    {
      key: 'more',
      text: 'External Applications',
      href: routes.more.path
    },
    {
      key: 'current',
      text: title
    }
  ]

  if (!hasAccess && !accessLoading) {
    return <Redirect to='/more' />
  }

  return (
    <MainLayout>
      {accessLoading ? (
        <div className="c-morechild-breadcrumb">
          <Shimmer 
            shimmerColors={{
              shimmer: '#ffffff',
              shimmerWave: '#f3f2f1'
            }}
            shimmerElements={[
              { type: ShimmerElementType.line, height: 16, width: 200 },
              { type: ShimmerElementType.gap, width: 16 },
              { type: ShimmerElementType.line, height: 16, width: 200 },
              { type: ShimmerElementType.gap, width: '100%' }
            ]}
          />
        </div>
      ) : (
        <Breadcrumb 
          items={breadcrumbs}
          className="c-morechild-breadcrumb"
          maxDisplayedItems={2}
          onRenderItem={(_props, defaultRender) => {
            const { text, href, key } = _props
            // return defaultRender(_props)
            return href ? (
              <Link key={key} as={RRLink} to={href} className="c-morechild-breadcrumb__item --link">{text}</Link>
            ) : (
              <span key={key} className="c-morechild-breadcrumb__item">{text}</span>
            )
          }}
        />
      )}
      <Section className="c-morechild">
        <Card heading={title} headingClassName="c-morechild__heading">
          {accessLoading ? (
            <>
              <Shimmer width="22%" height={16} style={{ marginBottom: '32px' }} />

              <Shimmer width="100%" height={16} />
              <Shimmer width="75%" height={16} />

              <Shimmer width="25%" height={16} style={{ marginBottom: '24px', marginTop: '32px' }}/>
              {[0, 1, 2].map((i) => (
                <div key={i} style={{ marginBottom: '32px' }}>
                  <Shimmer width="30%" />
                  <Shimmer width="100%" height={16} />
                  <Shimmer width="75%" height={16} />
                </div>
              ))}
              
            </>
          ) : (
            <>
              <p className="c-morechild__desc">{content.description}</p>
              <h4 className="c-morechild__subheading">Associated Files</h4>
              <div>
                {content.files.map((file) => (
                  <div className="c-morechild-file" key={file.title}>
                    <Link className="c-morechild-file__link" href={file.url} target="_blank">
                      <Icon className="c-morechild-file__link-icon" iconName={file.iconName} />
                      <h6 className="c-morechild-file__link-title">{file.title}</h6>
                    </Link>
                    <p className="c-morechild__desc">{file.description}</p>
                  </div>
                ))}
              </div>
            </>
          )}
        </Card>
      </Section>
    </MainLayout>

  )
}