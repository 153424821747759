import { usePutNotificationStatusMutation } from '@/shared/api/services/notificationService'
import { Link } from '@fluentui/react'
import { useState } from 'react'

type StatusCellProps = {
  isReadTable: boolean
  notificationId: string
  setHasError: (value: boolean) => void
}

const StatusCell = ({ isReadTable, notificationId, setHasError }: StatusCellProps) => {
  const [isLoading, setIsLoading] = useState(false)
  const [putStatus] = usePutNotificationStatusMutation()

  const handleStatusClick = () => {
    setIsLoading(true)
    setHasError(false)
    putStatus({ 
      notificationId: notificationId,
      isRead: !isReadTable  
    })
    .unwrap()
    .then(() => { setIsLoading(false) })
    .catch((error) => { 
      setHasError(true)
     })
  }

  return (
    <Link 
      disabled={isLoading} 
      onClick={handleStatusClick}
    >
      { isReadTable ? 'Mark as unread' : 'Mark as read' } 
    </Link>
  )
}

export default StatusCell