import { Modal, Dropdown, IDropdownOption, IconButton, Label, DefaultButton, Stack, PrimaryButton } from '@fluentui/react'
import { useForm, Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import dayjs from '@/bootstrap/dayjs'
import ContactPicker from './components/ContactPicker'
import { FieldErrorMessage, TextField } from '@/shared/components'
import { IntlProvider } from 'react-intl'
import { ContentPicker } from 'box-ui-elements/es/elements'
import { useState, forwardRef, memo, useImperativeHandle, Ref, useEffect } from 'react'
import { EActionId } from '../../shared/types'
import { IMessageCreateModel, IMessageUpdateModel, IMessageVersionedContent, RecipientType } from '@/shared/types/swagger'
import cn from 'classnames'
import { useCreateMessageMutation, useUpdateMessageMutation } from '@/shared/api/services/messageService'
import { useSelector } from 'react-redux'
import { selectCAID, selectName } from '@/features/auth/redux/authSelectors'
import { BoxStateType } from '@/shared/types/box'

interface Props {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  accountid: string
  boxAccess?: BoxStateType
  contacts?: any
}

export type CreateMessageModalHandle = {
  setValuesAndOpen: (message: IMessageVersionedContent) => void
}

const validEnumKeys = Object.keys(EActionId).filter((key) => {
  const number = parseInt(key, 10)
  if (number) return false
  return true
})

const actionOptions: Array<IDropdownOption> = validEnumKeys.map((actionText) => ({
  key: EActionId[actionText],
  text: actionText
}))

const siteRoot = window.location.host
const boxId = '50240239840137'

const CreateMessageModal = forwardRef((props: Props, ref: Ref<CreateMessageModalHandle>) => {
  const { isOpen, setIsOpen, accountid, boxAccess, contacts } = props
  const [ isEdit, setIsEdit ] = useState<boolean>(false)
  const senderName = useSelector(selectName)
  const senderID = parseInt(useSelector(selectCAID))
  const [createMessage] = useCreateMessageMutation()
  const [editMessage] = useUpdateMessageMutation()
  const form = useForm<IMessageCreateModel>({ defaultValues: { accountId: accountid, sender: senderName, senderId: senderID, recipients: [] } })
  const { t } = useTranslation('messages')
  const [showBoxPicker, setShowBoxPicker] = useState<boolean>(false)

  const formattedDate = dayjs().format('L')

  const [updatedMessageId, seUpdatedMessageId] = useState<string>()

  // Used by ImperativeHandle to allow for parent components to open and set values by ref.
  const setFormValuesAndOpen = (message: IMessageVersionedContent) => {
    const { notes, subject, actionId, documentLink, id } = message
    form.setValue('notes', notes, { shouldDirty: true })
    form.setValue('subject', subject, { shouldDirty: true })
    form.setValue('actionId', actionId, { shouldDirty: true })
    form.setValue('documentLink', documentLink, { shouldDirty: true })
    seUpdatedMessageId(id)
    setIsEdit(true)
    setIsOpen(true)
  }

  const clearFormValues = () => {
    // clear form values
    form.setValue('recipients', [], { shouldDirty: false })
    form.setValue('subject', undefined, { shouldDirty: false })
    form.setValue('actionId', undefined, { shouldDirty: false })
    form.setValue('documentLink', undefined, { shouldDirty: false })
    form.setValue('notes', undefined, { shouldDirty: false })

    // remove validation errors
    form.clearErrors()
  }

  useImperativeHandle(ref, () => ({
    setValuesAndOpen: setFormValuesAndOpen
  }))

  const handleSubmit = form.handleSubmit((data) => {
    if (isEdit) {
      editMessage({ id: updatedMessageId, payload: data })
    } else {
      createMessage(data)
    }
    clearFormValues()
    setIsEdit(false)
    setIsOpen(false)
  }, (error) => {
    console.error({ error })
  })

  const hideModal = () => {
    clearFormValues()
    setIsOpen(false)
    setIsEdit(false)
  }

  const handleContactPickerChange = (contactIds: Array<string>) => {
    form.setValue('recipients', contactIds.map(id => {
      const contact = contacts.data.find(r => r.contactId === id)
      return { 
        recipientId: id, 
        recipientName: `${contact.firstName.trim()} ${contact.lastName.trim()}`, 
        recipientType: 1 as unknown as RecipientType 
      }
    }))
    form.trigger('recipients')
  }

  const handleOnBlurChanges = () => {
    form.trigger('recipients')
    return true
  }

  const contentPicker = <ContentPicker
    token={boxAccess.token}
    rootFolderId={boxAccess.folder}
    onCancel={() => setShowBoxPicker(false)}
    maxSelectable={1}
    clearSelectedItemsOnNavigation={true}
    onChoose={(...args) => {
      if (args[0]) form.setValue('documentLink', args[0][0].id)
      setShowBoxPicker(false)
    }}
  />

  return (
    <Modal 
      isOpen={isOpen}
      onDismiss={hideModal}
      className="c-create-msg-modal"
    >
      <form onSubmit={handleSubmit}>
        <header>
          <div>
            <h2 className="c-create-msg-modal__title">{t('newMessage')}</h2>
            <time dateTime={formattedDate}>{formattedDate}</time>
          </div>
          <IconButton
            iconProps={{ iconName: 'Cancel' }}
            ariaLabel="Close popup modal"
            onClick={hideModal}
          />
        </header>
        <fieldset className="c-create-msg-modal__fs">
          <Controller 
            control={form.control}
            name="recipients"
            rules={{ required: 'To Recipient is required.' }}
            render={({ fieldState: { error, invalid, isTouched, isDirty }, field }) => (
              <>
                <div className={cn('c-create-msg-modal__addr', { '--error': error?.message })}>
                  <Label required htmlFor="msg-to" className='c-create-msg-modal__picker-label'>{t('to')}:</Label>
                  <ContactPicker 
                    {...field}
                    handleChange={handleContactPickerChange}
                    id="msg-to" 
                    contacts={contacts.data} 
                    loading={contacts.isFetching || contacts.isUninitialized}
                    handleBlur={handleOnBlurChanges}
                  />
                </div>
                <FieldErrorMessage message={error?.message} />
              </>
            )}
          />
          
          {/* <ContactPicker contacts={[]} /> */}
          {/* <TextField underlined label="CC" /> */}
        </fieldset>
        <fieldset className="c-create-msg-modal__fs">
          <Controller 
            control={form.control}
            rules={{ required: 'Subject is required.' }}
            name="subject"
            render={({ field, fieldState: { error } }) => (
              <TextField 
                {...field}
                label={t('subject')} 
                placeholder={t('enterSubject')} 
                required
                defaultValue={field.value}
                errorMessage={error?.message}
                onBlur={() => form.trigger('subject')}
              />
            )}
          />

        </fieldset>
        <fieldset className="c-create-msg-modal__fs">
          <Controller 
            control={form.control}
            rules={{ required: 'Action is required.' }}
            name="actionId"
            render={({ field: { onChange, ...field }, fieldState: { error } }) => (
              <Dropdown 
                {...field}
                label={t('action')} 
                options={actionOptions} 
                className="c-create-msg-modal__dd"
                onChange={(_e, option) => {
                  onChange(option.key)
                }}
                placeholder={t('selectAction')}
                errorMessage={error?.message}
                required
                selectedKey={field.value}
                onBlur={() => form.trigger('actionId')}
              />  
            )}
          />

          <Stack horizontal tokens={{ childrenGap: 12 }}>
            <Stack.Item>
              <Label htmlFor="box-cp-btn">{t('browseLabel')}</Label>
              <DefaultButton iconProps={{ iconName: 'OpenFolderHorizontal' }} id="box-cp-btn" onClick={() => setShowBoxPicker(true)}>{t('browse')}</DefaultButton>
              <Modal isOpen={showBoxPicker} onDismiss={() => setShowBoxPicker(false)} className="c-content-picker-modal">
                <IntlProvider>
                  { contentPicker }
                </IntlProvider>
              </Modal>
            </Stack.Item>
            <Stack.Item grow>
              <Label>URL</Label>
              <Controller 
                control={form.control}
                name="documentLink"
                render={({ field, fieldState: { error } }) => (
                  <TextField 
                    disabled
                    readOnly
                    borderless
                    value={field.value}
                    errorMessage={error?.message}
                  />
                )}
              />

            </Stack.Item>
          </Stack>
        </fieldset>
        <fieldset className="c-create-msg-modal__fs">
          <Controller 
            control={form.control}
            name="notes"
            render={({ field }) => (
              <TextField 
                label={t('notes')} 
                multiline 
                rows={3} 
                autoAdjustHeight 
                placeholder={t('notePlaceholder')}
                defaultValue={field.value}
                {...field}
              />
            )}
          />
          
        </fieldset>
        <footer>
          <Stack horizontal horizontalAlign="end" tokens={{ childrenGap: 12 }}>
            <DefaultButton onClick={hideModal}>Cancel</DefaultButton>
            <PrimaryButton onClick={handleSubmit}>Send</PrimaryButton>
          </Stack>
        </footer>
      </form>
    </Modal>
  )
})

CreateMessageModal.displayName = 'CreateMessageModal'

export default memo(CreateMessageModal)