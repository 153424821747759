import { library } from '@fortawesome/fontawesome-svg-core'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { fas } from '@fortawesome/free-solid-svg-icons'

export const FontAwesomeProvider = ({ children }: {
  children: any
})  => {
  // fab and fas icon pack setup
  library.add(fab, fas)
  return children
}
