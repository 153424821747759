import { emmaApiBaseQuery, axiosBaseQuery, boxBaseQuery } from '@/bootstrap/axios'
import { createApi, BaseQueryFn } from '@reduxjs/toolkit/query/react'
import axios, { AxiosRequestConfig, AxiosError } from 'axios'

export const mainService = createApi({
  baseQuery: axiosBaseQuery(),
  endpoints: () => ({}),
});

export const boxService = createApi({
  reducerPath: 'boxApi',
  baseQuery: boxBaseQuery(),
  endpoints: () => ({})
});

export const emmaApiService = createApi({
  reducerPath: 'emma',
  baseQuery: emmaApiBaseQuery(),
  endpoints: () => ({}),
});

const localAxiosBaseQuery = (): BaseQueryFn<
  {
    url: string
    method: AxiosRequestConfig['method']
    data?: AxiosRequestConfig['data']
  },
  unknown,
  unknown
> =>
  async ({ url, method, data }) => {
    try {
      const result = await axios({ url: process.env.PUBLIC_URL + url, method, data })
      return { data: result.data }
    } catch (axiosError) {
      const err = axiosError as AxiosError
      return {
        error: { status: err.response?.status, data: err.response?.data },
      }
    }
  }

export const localService = createApi({
  baseQuery: localAxiosBaseQuery(),
  endpoints: () => ({}),
  reducerPath: 'local'
})