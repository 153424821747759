import { useEffect, useMemo, useState } from 'react'
import { useGetInsightsQuery, useGetInsightArticleDataQuery, usePutInsightArticleDataMutation } from '@/shared/api/services/insightService'
import InsightModal from '../../shared/components/InsightModal'
import ManageInsightTableOptions from '../../constants/ManageInsightTableOptions'
import ManageInsightDescription from './components/ManageInsightDescription'
import ManageInsightLoading from './components/ManageInsightLoading'
import { useDispatch, useSelector } from 'react-redux'
import { Pagination, ErrorMessage } from '@/shared/components'
import modalSlice, { selectModalStatuses } from '@/shared/redux/modalSlice'
import withPermission from '@/features/auth/components/withPermission'
import { EUserCan } from '@/features/auth/types'
import { useHistory } from 'react-router-dom'
import { nanoid } from '@reduxjs/toolkit'
import TableContext, { ITableContext } from '@/shared/components/Table/context'
import { IInsightsPostViewWithAlt } from '../../shared/types'
import { Icon } from '@fluentui/react'

export const enum eFilterOptions {
  Tags = 'tags',
  Category = 'category',
  Label = 'label',
}

export type TableFilter = {
  [eFilterOptions.Tags]: Array<string>;
  [eFilterOptions.Category]: Array<string>;
  [eFilterOptions.Label]: string;
}

export type InsightsPostViewWithDisplayed = IInsightsPostViewWithAlt & { isDisplayed?: boolean }

const PAGINATION_PARAM = 'manageInsight'

type ManageInsightModalProps = {
  isInsightList?: boolean
}

const FILTERS_INITIAL_STATE = {
  tags: [], 
  category: [],
  label: null
}
const TABLE_CONTEXT_INITIAL_VALUE = { 
  search: { key: 'q', value: '' },
  page: { key: 'manageInsightPage', value: 1 },
  sortField: { key: 'sf', value: '' },
  sortOrder: { key: 'so', value: '' }
}

const ManageInsightModal = ({ isInsightList = false }: ManageInsightModalProps) => {
  const [putInsightArticleData, { isLoading: putIsLoading }] = usePutInsightArticleDataMutation()
  const dispatch = useDispatch()
  const [hasError, setHasError] = useState(false)
  const [enabledDisplays, setEnabledDisplays] = useState<Array<number>>([]) 
  const history = useHistory()
  const [filters, setFilters] = useState<TableFilter>(FILTERS_INITIAL_STATE)
  const modalOpen = useSelector(selectModalStatuses)?.manageInsight
  const id = nanoid(4)
  const [contextValue, setContextValue] = useState<ITableContext>({ ...TABLE_CONTEXT_INITIAL_VALUE, id })
  const { data, isLoading, isUninitialized, isFetching, isError } = useGetInsightsQuery({ 
    tagFilter: filters.tags,
    categoryFilter: filters.category,
    titleFilter: filters.label,
    orderByField: contextValue.sortField.value,
    orderDirection: contextValue.sortOrder.value,
    page: contextValue.page.value,
    pageSize: 12
  })
  const { 
    data: modalData, 
    isLoading: modalIsLoading, 
    isUninitialized: modalIsUninitialized, 
    isSuccess, 
    isFetching: modalIsFetching, 
    isError: modalIsError 
  } = useGetInsightArticleDataQuery()
  const [enabledFeature, setEnabledFeature] = useState<number>(modalData?.featuredInsight?.id ?? null) 
  const [userIsManagingInsights, setUserIsManagingInsights] = useState<boolean>(modalData?.isBeingManaged ?? null)

  useEffect(() => {
    if (isSuccess) {
      if (!enabledDisplays.length) setEnabledDisplays(modalData.displayedInsights.map((insight) => insight.id))
      if (userIsManagingInsights === null) {
        setUserIsManagingInsights(modalData.isBeingManaged)
      }
    }
  }, [isSuccess, modalData, enabledDisplays, userIsManagingInsights])

  useEffect(() => {
    if (isSuccess) {
      if ((!enabledFeature || !modalIsFetching) && modalData?.featuredInsight?.id) {
        setEnabledFeature(modalData.featuredInsight.id)
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess, modalData?.featuredInsight?.id, modalIsFetching])

  useEffect(() => {
    if (!modalOpen) setEnabledFeature(modalData?.featuredInsight?.id ?? null)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalOpen])
  
  const handleDisplayedClick = (item: { id: number }) => {
    if (enabledFeature === item.id) return // We can't have a feature that isn't displayed!
    if (enabledDisplays.includes(item.id)) {
      setEnabledDisplays((prevDisplays) => prevDisplays.filter((id) => id !== item.id))
    } else {
      setEnabledDisplays((prevDisplays) => prevDisplays.concat([item.id]))
    }
  }

  const handleFeaturedClick = (item: { id: number }) => {
    setEnabledFeature(item.id)
  }

  const handleOnConfirmClick = () => {
    if (hasError) setHasError(false)
    putInsightArticleData({
      isBeingManaged: userIsManagingInsights,
      featuredInsight: userIsManagingInsights ? enabledFeature : null,
      displayedInsights: userIsManagingInsights ? enabledDisplays : []
    })
      .unwrap()
      .then(() => { 
        dispatch(modalSlice.actions.setModalStatus({ paramName: PAGINATION_PARAM, value: false }))
      })
      .catch((error) => { 
        console.error({ error })
        setHasError(true)
      })
  }

  const handleNewPage = (newPage: number) => {
    setContextValue({ ...contextValue, page: { ...contextValue.page, value: newPage } })
  }
  
  let columns = ManageInsightTableOptions(
    userIsManagingInsights, 
    handleDisplayedClick, 
    handleFeaturedClick,
  )
  if (isInsightList) columns = columns.filter((column) => column.key !== 'displayedColumn')

  const modifiedData = useMemo(() => {
    if (userIsManagingInsights) {
      return (data?.items || []).map((insight) => {
        const newData: InsightsPostViewWithDisplayed = { ...insight }
        if (enabledFeature === insight.id) newData.isFeatured = true
        newData.isDisplayed = (enabledDisplays && enabledDisplays.includes(insight.id))
        return newData
      })
    } else {
      return (data?.items || []).map((insight) => {
        const newData: InsightsPostViewWithDisplayed = { ...insight }
        if (modalData?.featuredInsight?.id === insight.id) newData.isFeatured = true
        if (modalData?.displayedInsights.findIndex((displayedInsight) => displayedInsight.id === insight.id) !== -1) newData.isDisplayed = true
        else newData.isDisplayed = false
        return newData
      })
    }
  }, [data?.items, enabledDisplays, enabledFeature, modalData?.displayedInsights, modalData?.featuredInsight?.id, userIsManagingInsights])

  // If modal isn't completely set up. We hide the button.
  if (isLoading || isUninitialized || modalIsLoading || modalIsUninitialized || isError || modalIsError) return null
  const title = isInsightList ? 'Manage Featured Insight' : 'Manage Insights on Dashboard'

  const resetFormState = () => {
    const queryParams = new URLSearchParams(history.location.search)
    const paramNames = Object.values(TABLE_CONTEXT_INITIAL_VALUE).map(sortOrFilter => sortOrFilter.key)
    paramNames.forEach(paramName => queryParams.delete(paramName))

    history.replace({
      ...history.location,
      search: `?${queryParams.toString()}`
    })
    
    setFilters(FILTERS_INITIAL_STATE)
    setContextValue({ ...TABLE_CONTEXT_INITIAL_VALUE, id })
    setEnabledDisplays(modalData.displayedInsights.map((insight) => insight.id))
    setUserIsManagingInsights(modalData.isBeingManaged)
  }

  const areFiltersUsed = filters.label || filters.category.length || filters.tags.length 
  
  return (
    <TableContext.Provider value={{ ...contextValue, setValue: setContextValue }}>
      <InsightModal 
        className='c-manage-insights-modal'
        ariaLabels={{
          openButton: title,
          closeButton: `Close ${title}`
        }}
        primaryIsDisabled={putIsLoading}
        modalTitle={title}
        openButtonLabel={title}
        onOpen={resetFormState}
        onClose={resetFormState}
        description={
          <>
            <ManageInsightDescription 
              isInsightList={isInsightList}
              setUserIsManagingInsights={setUserIsManagingInsights}
              userIsManagingInsights={userIsManagingInsights}
              filters={filters}
              setFilters={setFilters}
            />
            <ErrorMessage message='Failed to save Insights' showAlert={hasError} setShowAlert={setHasError}  />
          </>
        }
        onConfirmClick={handleOnConfirmClick}
        tableProps={{
          columns: columns,
          data: modifiedData
        }}
        body={<ManageInsightLoading isFetching={isFetching} />}
        footer={
          <div className="c-manage-insights-modal__footer">
            <Pagination
              onPageChange={handleNewPage}
              listCount={data.count}
              maxShown={12}
              ignoreQueryParams
            />
            <div aria-live='polite' aria-relevant='additions'>
              {areFiltersUsed ? (
                <p className="c-manage-insights-modal__filter-warning">
                  <Icon iconName='Info' />
                  <span>Some selected items may not be visible due to filters</span>
                </p>
              ) : null}
            </div>
          </div>
        }
      />
    </TableContext.Provider>
  )
}

export default withPermission(EUserCan.Edit_Content)(ManageInsightModal)