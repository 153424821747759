import { MSAL_CLIENT_ID, MSAL_REDIRECT_URL, MSAL_TENANT } from '@/environment/environment.constants'
import { PublicClientApplication, Configuration, BrowserCacheLocation } from '@azure/msal-browser'

const TENANT = MSAL_TENANT;
const CLIENT_ID = MSAL_CLIENT_ID
const REDIRECT_URL = MSAL_REDIRECT_URL

export const b2cUrlBase = `https://${TENANT}.b2clogin.com/${TENANT}.onmicrosoft.com/`
export const b2cCustomPolicyName = 'B2C_1A_IMPERSONATION'

export const b2cPolicies = {
  authorities: {
    signUpSignIn: `${b2cUrlBase}${b2cCustomPolicyName}`
  },
  authorityDomain: `${TENANT}.b2clogin.com`
}

const config: Configuration = {
  auth: {
    clientId: CLIENT_ID,
    authority: b2cPolicies.authorities.signUpSignIn,
    redirectUri: REDIRECT_URL, // Redirect will need to change per env
    knownAuthorities: [b2cPolicies.authorityDomain]
  },
  cache: {
    cacheLocation: BrowserCacheLocation.SessionStorage,
    storeAuthStateInCookie: false,
  }
}

export const msalInstance = new PublicClientApplication(config)