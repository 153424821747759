import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Message, MessagesState } from '../shared/types'
import { keyMessagesById } from '../helpers'

export const initialState: MessagesState = {
  messages: {}
}

const messagesSlice = createSlice({
  name: 'messages',
  initialState,
  reducers: {
    setMessages(state, action: PayloadAction<Array<Message>>) {
      const keyedMessages = keyMessagesById(action.payload)
      state.messages = keyedMessages
    },
    pushMessage(state, action: PayloadAction<Message>) {
      state.messages[action.payload.id] = action.payload
    }
  }
})

export const { setMessages, pushMessage } = messagesSlice.actions
export default messagesSlice.reducer