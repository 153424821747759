import { IAccount } from '@/shared/types/swagger'
import { Link } from '@fluentui/react'
import { Link as RRLink } from 'react-router-dom'

export default function NameCell(client: IAccount) {
  return (
    <Link as={RRLink} className="c-table__cell-link-primary" to={`/clients/${client.accountId}`} data-selection-invoke={true}>
      {client.accountName}
    </Link>
  )
}
