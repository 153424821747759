import { useGetBoxRecentItemsQuery, useGetBoxTokenQuery } from '@/shared/api/services/boxServices'
import { Card, Section, List } from '@/shared/components'
import dayjs from 'dayjs'
import { useMemo } from 'react'
import { getFileExtension  } from 'box-ui-elements/es/utils/file'
import useAccessRulesQuery from '@/features/auth/hooks/useAccessRulesQuery'

const RecentDocuments = () => {
  const { data: boxTokenResponse } = useGetBoxTokenQuery(null)
  const navAccessState = useAccessRulesQuery()
  const skipRecentFetch: boolean = !boxTokenResponse?.accessToken || navAccessState?.data?.documents === false
  const recentItemsState = useGetBoxRecentItemsQuery(null, { skip: skipRecentFetch })
  // { data, isSuccess, isLoading, isError, refetch }
  const isLoading = navAccessState.isLoading || recentItemsState.isLoading || recentItemsState.isUninitialized
  
  const listItems = useMemo(() => {
    if (!recentItemsState.data) return []
    return recentItemsState.data.map(({ item }) => ({ 
      id: item.id,
      date: dayjs(item.modifiedAt ?? item.createdAt).toDate(),
      title: item.name,
      href: `/documents/${item.id}`,
      actionTitle: '',
      actionHref: `/documents/${item.id}`,
      actionIconName: getFileExtension(item.name)
    }))
  }, [recentItemsState.data])

  const Content = useMemo(() => {
    return (
      <div>
        {
          recentItemsState.isSuccess && !recentItemsState.data?.length 
          ? <p className='c-recent-documents__empty'>You don&#39;t have any documents at the moment.</p>
          : <List useBoxIcon items={listItems} href='/documents' expandTitle="View all Documents" />
        }
      </div>
    )
  }, [listItems, recentItemsState.data?.length, recentItemsState.isSuccess])

  if (recentItemsState?.isError || navAccessState?.isError || !navAccessState?.data?.documents || isLoading) {
    return null
  }

  return (
    <Section className='c-recent-documents' title="Recent Documents">
      {Content}
    </Section>
  )
}

export default RecentDocuments