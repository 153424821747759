import { API_BASE_URL, EMMA_API_BASE_URL } from '@/environment/environment.constants'
import { AuthenticationResultWithClaims, loginSuccess } from '@/features/auth/redux/authSlice'
import { IPublicClientApplication, InteractionRequiredAuthError, AccountInfo } from '@azure/msal-browser'
import { BaseQueryFn } from '@reduxjs/toolkit/dist/query'
import axios, { Method, AxiosRequestConfig, AxiosInstance, AxiosError } from 'axios'
import { b2cPolicies, msalInstance } from '../msal'
import store, { RootState } from '../redux'

type CustomAxiosRequestConfig = {
  url: string;
  method: AxiosRequestConfig['method'];
  data?: AxiosRequestConfig['data'];
  headers?: AxiosRequestConfig['headers'];
}

interface ApiError extends AxiosError {
  authentication?: AuthenticationResultWithClaims;
}

let storedBoxAxiosInstance: AxiosInstance = null
let storedApiAxiosInstance: AxiosInstance = null
let storedEmmaApiInstance: AxiosInstance = null

const API_TIMEOUT_MS = 250000 as const

export const setupApiClient = (_msalInstance: IPublicClientApplication) => {
  const axiosApiInstance = axios.create({
    baseURL: API_BASE_URL,
    headers: {
      'Cache-Control': 'private, max-age=60'
    },
    timeout: API_TIMEOUT_MS,
  })

  axiosApiInstance.interceptors.request.use(async (config) => {
    const { auth } = store.getState() as RootState
    const token = auth?.idToken ? auth.idToken : (await getAuthenticationResult())?.idToken
    config.headers.Authorization = `Bearer ${token}`
    return config
  })

  axiosApiInstance.interceptors.response.use((res) => res, async (err: Omit<AxiosError, 'config'> & {config?: AxiosError['config'] & {refetching?: boolean} }) => {
    const config = { ...(err?.config || {}) }
    const is401 = err?.response?.status === 401
    const refetching = config?.refetching
    if (is401 && !refetching) {
      config.refetching = true
      try {
        const authResult = await getAuthenticationResult()
        await store.dispatch(loginSuccess(authResult))
        axiosApiInstance.defaults.headers['Authorization'] = `Bearer ${authResult?.idToken}`
        return axiosApiInstance(config)
      } catch (error) {
        if (error.response && error.response.data) {
          return Promise.reject(error.response.data)
        }
        return Promise.reject(error)
      }
    }

    let authentication
    try {
      authentication = await getAuthenticationResult()
    } catch (error) {
      authentication = undefined
    }
    return Promise.reject({
      ...err,
      authentication
    })
  })

  // emma api axios instance
  const emmaApiAxiosInstance = axios.create({
    baseURL: EMMA_API_BASE_URL,
    headers: {
      'Cache-Control': 'private, max-age=60'
    },
    timeout: API_TIMEOUT_MS,
  })

  emmaApiAxiosInstance.interceptors.request.use(async (config) => {
    const { auth } = store.getState() as RootState
    const token = auth?.idToken ? auth.idToken : (await getAuthenticationResult())?.idToken
    config.headers.Authorization = `Bearer ${token}`
    return config
  })

  emmaApiAxiosInstance.interceptors.response.use((res) => res, async (err: Omit<AxiosError, 'config'> & {config?: AxiosError['config'] & {refetching?: boolean} }) => {
    const config = { ...(err?.config || {}) }
    const is401 = err?.response?.status === 401
    const refetching = config?.refetching
    if (is401 && !refetching) {
      config.refetching = true
      try {
        const authResult = await getAuthenticationResult()
        await store.dispatch(loginSuccess(authResult))
        emmaApiAxiosInstance.defaults.headers['Authorization'] = `Bearer ${authResult?.idToken}`
        return emmaApiAxiosInstance(config)
      } catch (error) {
        if (error.response && error.response.data) {
          return Promise.reject(error.response.data)
        }
        return Promise.reject(error)
      }
    }
    
    let authentication
    try {
      authentication = await getAuthenticationResult()
    } catch (error) {
      authentication = undefined
    }
    return Promise.reject({
      ...err,
      authentication
    })
  })

  storedApiAxiosInstance = axiosApiInstance
  storedEmmaApiInstance = emmaApiAxiosInstance

  // box api axios instance
  const boxInstance = axios.create({
    baseURL: 'https://api.box.com/2.0',
    timeout: API_TIMEOUT_MS,
  })

  storedBoxAxiosInstance = boxInstance

}

const getAccessTokenFromB2C = async (account: AccountInfo) => {
  const token = await msalInstance.acquireTokenSilent({
    authority: b2cPolicies.authorities.signUpSignIn,
    scopes: [],
    account
  })
  return token as unknown as AuthenticationResultWithClaims
}

export const getAuthenticationResult = async (): Promise<AuthenticationResultWithClaims> => {
  if (!msalInstance) {
    const error = new Error('No stored Msal Instance to use for token!')
    Promise.reject(error)
  }
  try { 
    const accounts = msalInstance.getAllAccounts()
    if (!accounts || !accounts.length) {
      const error = new Error('No valid accounts found for access token')
      Promise.reject(error)
    }
    const authResult = await getAccessTokenFromB2C(accounts[0])
    return authResult
  } catch (error) {
    if (error instanceof InteractionRequiredAuthError) {
      await msalInstance.acquireTokenRedirect({
        scopes: [],
        authority: b2cPolicies.authorities.signUpSignIn
      })
      return getAuthenticationResult()
    }
    Promise.reject(error)
  }
}

export const boxBaseQuery = (): BaseQueryFn<CustomAxiosRequestConfig, unknown, unknown> => 
  async ({ url, method, data, headers }) => {
    try {
      const result = await storedBoxAxiosInstance.request({ url: `https://api.box.com/2.0${url}`, method, data, headers })
      return { data: result.data }
    } catch (axiosError) {
      const err = axiosError as AxiosError
      return {
        error: { status: err.response?.status, data: err.response?.data },
      }
    }
  }

export const axiosBaseQuery = (): BaseQueryFn<CustomAxiosRequestConfig, unknown, unknown> =>
  async ({ url, method, data, headers }, api) => {
    return storedApiAxiosInstance.request({ url: `${API_BASE_URL}${url}`, method, data, headers, withCredentials: true })
    .then(response => {
      if (response) {
        const {
          data: _data, ...rest } = response
        return {
          data: _data,
          meta: rest, 
        }
      }
      return {
        data: {},
        meta: {},
      }
    })
    .catch((error: ApiError) => {
      return {
        error: error?.response?.data || {},
      }
    })
  }

  export const emmaApiBaseQuery = (): BaseQueryFn<CustomAxiosRequestConfig, unknown, unknown> =>
    async ({ url, method, data, headers }, api, extraOptions) => {
      // Create a cancel token source
      const source = axios.CancelToken.source();

      // Record the request start time
      const startTime = Date.now()

      const request = () => storedEmmaApiInstance.request({
        url: `${EMMA_API_BASE_URL}${url}`,
        method,
        data,
        headers,
        withCredentials: true,
        cancelToken: source.token,
      })
  
      try {
        const response = await request()
        
        if (response) {
          const { data: _data, ...rest } = response
          return {
            data: _data,
            meta: rest,
          };
        }

        return {
          data: {},
          meta: {},
        }
      } catch (error) {
        const err = error as ApiError
        const requestTimeInSeconds = Math.ceil((Date.now() - startTime) / 1000)
        const requestTimeoutInSeconds = Math.floor(API_TIMEOUT_MS / 1000)
        const isRequestTimeout = ((error.code === 'ECONNABORTED') || axios.isCancel(error)) && (method !== 'OPTIONS') && (requestTimeInSeconds >= requestTimeoutInSeconds)

        // check if the error status is 400 or 500 or timeout request and retry the request once if true
        if ((err.response && (err.response.status === 400 || err.response.status === 500)) || isRequestTimeout) {
          try {
            // retry the request
            const response = await request()
            if (response) {
              const { data: _data, ...rest } = response
              return {
                data: _data,
                meta: rest,
              };
            }

            return {
              data: {},
              meta: {},
            }
          } catch (retryError) {
            const retryErr = retryError as ApiError

            return {
              error: retryErr.response?.data || {},
            };
          }
        } else {
          return {
            error: err.response?.data || {},
          };
        }
      }
    }

export const buildRequestConfig = (
  endpoint: string,
  method: Method,
): AxiosRequestConfig => {
  const req = {
    url: `/${endpoint}`,
    method,
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
    },
  }
  return req
}
