import { persistor } from '.'
import { persistConfig } from './persist'

export default async function clearPersistStorage(): Promise<boolean> {
  try {
    const storage = persistConfig.storage as LocalForage
    await persistor.purge()
    await storage.clear()
    return true
  } catch (error) {
    return false
  }
}