 import NavMenu from '@/shared/components/NavMenu'
import { NavMenuItems } from '@/shared/constants/navMenuItems'
 
 const OnboardingNav = () => (
   <NavMenu 
     className="c-client-mainnav"
     itemIds={[NavMenuItems.OnboardingWelcome, NavMenuItems.OnboardingDocuments, NavMenuItems.OnboardingTimeline, NavMenuItems.OnboardingSupport]}
   />
 )
 
 export default OnboardingNav
