import { memo } from 'react'
import { Icon } from '@fluentui/react'

export interface CheckListItem {
  title: string;
  description: string;
  checked: boolean;
}

interface Props {
  items: Array<CheckListItem>;
}

export default memo(function CheckList({ items }: Props) {
  return (
    <ul className="c-checklist">
      {items.map((item, i) => (
        <li key={`checklist-item-${i}`} className="c-checklist__item">
          <div className="c-checklist__item-icon" title={item.checked ? 'Complete' : 'Incomplete'}>
            <Icon 
              iconName={item.checked ? 'SkypeCircleCheck' : 'CircleRing'}
              className={item.checked ? '--checked' : ''}
            />
          </div>
          <div className="c-checklist__item-title"><strong>{item.title}</strong></div>
          <div className="c-checklist__item-desc"><p>{item.description}</p></div>
        </li>
      ))}
    </ul>
  )
})
