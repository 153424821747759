import { DEFAULT_COLUMNDEFS, IDataGridColumnProps, PLACEHOLDER } from "@/lib/common/components/grid/Columns";
import { ColDef, ICellRendererParams } from 'ag-grid-community';

// component styles
import './TradeInvestmentColumn.scss';

export const TRADE_INVESTMENT_COLUMN_CELL_CLASS = "TradeInvestmentCell";

export const TradeInvestmentColumnrenderer = (props: ICellRendererParams) => {
    const { data, value } = props
    return (
      <div className='TradeInvestmentColumn'>
        <div className={`TradeInvestmentValue ${value.replace(/\t|\s|\n/g, '')}`}>
          { value }
        </div>
        <small className="TradeInvestmentValueSubname">
          { data.portfolioName }
        </small>
      </div>
    );
};

export function TradeInvestmentColumn(props: IDataGridColumnProps) {
  return PLACEHOLDER;
}
TradeInvestmentColumn.columnDefs = <TRow extends any>(
  props: IDataGridColumnProps
): ColDef =>
  ({
    ...DEFAULT_COLUMNDEFS,
    autoHeight: true,
    filter: !props.disableFilterColumn ? "agTextColumnFilter" : false,
    floatingFilter: !props.disableFilterColumn,
    cellRendererFramework: TradeInvestmentColumnrenderer,
    cellClass: TRADE_INVESTMENT_COLUMN_CELL_CLASS,
    disableWidth: false,
    width: props?.width,
    headerClass: "text-left"
  } as ColDef);