import { IEvent, IFeaturedEventViewModel } from '@/shared/types/swagger'
import { mainService, emmaApiService } from '.'
import { useMemo } from 'react'

type GetEventsReturn = {
  items: Array<IEvent>
  count: number
}

type GetEventsParams = {
  page: number,
  pageSize: number
}

const serviceWithTags = emmaApiService.enhanceEndpoints({ addTagTypes: ['Events', 'FeaturedEvent', 'RecentEvents', 'HistoricalEvents'],  })

const eventServices = serviceWithTags.injectEndpoints({
  endpoints(build) {
    return {
      getEvents: build.query<GetEventsReturn, GetEventsParams>({
        query: ({ page, pageSize }) => (
          { 
            url: `/Event/getall?page=${page}&pageSize=${pageSize}`,
            method: 'GET'
          }
        ),
        providesTags: ['Events']
      }),
      getHistoricalEvents: build.query<GetEventsReturn, GetEventsParams>({
        query: ({ page, pageSize }) => (
          { 
            url: `/Event/getall/byPeriod?page=${page}&pageSize=${pageSize}&timingFilter=HISTORICAL`,
            method: 'GET'
          }
        ),
        providesTags: ['HistoricalEvents']
      }),
      getRecentEvents: build.query<GetEventsReturn, GetEventsParams>({
        query: ({ page, pageSize }) => (
          { 
            url: `/Event/getall/byPeriod?page=${page}&pageSize=${pageSize}&timingFilter=UPCOMING&skipPagination=true`,
            method: 'GET'
          }
        ),
        providesTags: ['RecentEvents']
      }),
      getFeaturedEvent: build.query<IFeaturedEventViewModel, void>({
        query: () => (
          { 
            url: '/Event/getfeatured',
            method: 'GET'
          }
        ),
        providesTags: ['FeaturedEvent']
      }),
      getUpcomingEvents: build.query<Array<IEvent>, void>({
        query: () => (
          { 
            url: '/Event/getall?page=1&pageSize=3',
            method: 'GET'
          }
        ),
        transformResponse: (response: { items: Array<IEvent>, count: number }) => response.items
      }),
      addEvent: build.mutation<IEvent, FormData>({
        query: (postEvent) => (
          { 
            url: '/Event/add',
            method: 'POST',
            data: postEvent
          }
        ),
        invalidatesTags: ['Events', 'FeaturedEvent', 'RecentEvents', 'HistoricalEvents']
      }),
      updateEvent: build.mutation<IEvent, FormData>({
        query: (event) => (
          { 
            url: `/Event/update`,
            method: 'POST',
            data: event
          }
        ),
        invalidatesTags: ['Events', 'FeaturedEvent', 'RecentEvents', 'HistoricalEvents']
      }),
      updateFeatureControl: build.mutation<void, void>({
        query: () => ({
          url: '/Event/SetAutomatically',
          method: 'POST',
        }),
        invalidatesTags: ['Events', 'FeaturedEvent', 'RecentEvents', 'HistoricalEvents']
      }),
      deleteEvent: build.mutation<IEvent, IEvent['id']>({
        query: (eventId) => (
          { 
            url: `/Event/delete/${eventId}`,
            method: 'DELETE',
            data: eventId
          }
        ),
        invalidatesTags: ['Events', 'FeaturedEvent', 'RecentEvents', 'HistoricalEvents']
      }),
    }
  },
})

export const {
  useGetEventsQuery,
  useGetUpcomingEventsQuery,
  useGetFeaturedEventQuery,
  useDeleteEventMutation,
  useAddEventMutation,
  useUpdateEventMutation,
  useUpdateFeatureControlMutation,
  useGetHistoricalEventsQuery,
  useGetRecentEventsQuery,
} = eventServices

export function useGetEvents({ page, pageSize }) {
  // recent events does not have pagination, pagination events required but has a skip pagination parameter
  const recentEvents = useGetRecentEventsQuery({
    page: 1,
    pageSize: 50
  })
  const historicalEvents = useGetHistoricalEventsQuery({
    page,
    pageSize,
  }, {
    skip: !recentEvents.isSuccess 
  })

  const combinedData = useMemo(() => {
    if (recentEvents.isError || historicalEvents.isError) {
      return {};
    }

    return {
      recentEvents: {
        items: recentEvents?.data?.items,
        count: recentEvents?.data?.count,
      },
      historicalEvents: {
        items: historicalEvents?.data?.items,
        count: historicalEvents?.data?.count,
      }
    }
  }, [recentEvents, historicalEvents])

  return {
    ...historicalEvents,
    isFetching: recentEvents.isFetching || historicalEvents.isFetching,
    isLoading: recentEvents.isLoading || historicalEvents.isLoading,
    isError: recentEvents.isError || historicalEvents.isError,
    isRecentEventsLoading: recentEvents.isLoading,
    isRecentEventsFetching: recentEvents.isFetching,
    isHistoricalEventsLoading: historicalEvents.isLoading,
    isHistoricalEventsFetching: historicalEvents.isFetching,
    data: combinedData,
  }
}
