import { Dropdown as FluentDropdown, IDropdownProps } from '@fluentui/react'
import cn from 'classnames'

export default function Dropdown(props: IDropdownProps) {
  const { className, ...restProps } = props

  return (
    <FluentDropdown {...restProps} className={cn('c-dropdown', className)} />
  )
}
