import * as Screens from '@/screens'
import { CLIENT_ONBOARDING_DASHBOARD } from "@/features/client/components/ClientSettings/ClientSettings.model"
import useGetDashboardView from '@/features/auth/hooks/useGetDashaboardView'

const Home = () => {
  const { dashboardView } = useGetDashboardView()

  if (!dashboardView) {
    return null
  }

  if (dashboardView === CLIENT_ONBOARDING_DASHBOARD) {
    return (
      <Screens.ClientOnboardingDashboard />
    )
  }

  return (
    <Screens.Dashboard />
  )
}

export default Home