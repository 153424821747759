import { useRef, useState, forwardRef, useImperativeHandle, Ref, memo } from 'react'
import { Editor as EditorType } from 'tinymce'
import { IAllProps, Editor as TinyEditor, } from '@tinymce/tinymce-react'
import Skeleton from 'react-loading-skeleton'

export type EditorProps = {
  height?: number
  onChange?: (content: string, editor?: EditorType) => void,
  value?: string,
  initialValue?: string
  canResize?: boolean
  id?: string
  plugins?: string[]
  toolbar?: string
  blockFormats?: string
}

export type EditorRefHandle = {
  getHTML: () => string
}

const Editor = forwardRef(({ height = 210, onChange, canResize = true, initialValue = '', plugins = ['link', 'paste'], toolbar = 'undo redo | bold italic | link', blockFormats,  id }: EditorProps, ref: Ref<EditorRefHandle>) => {
  const editorRef = useRef<EditorType>(null)
  const [isLoading, setIsLoading] = useState(true)

  const getHTML = (): string => {
    return editorRef.current.getContent()
  }

  useImperativeHandle(ref, () => ({
    getHTML: getHTML
  }))

  const handleEditorInit: IAllProps['onInit'] = (evt, editor) => {
    setIsLoading(false)
    editorRef.current = editor
  }

  const handleEditorChange = (text: string, editor: EditorType) => {
    if (onChange) {
      const currentEditor = (editorRef && editorRef.current) || editor
      const htmlString = currentEditor.getContent({ format: 'html' })
      onChange(htmlString, currentEditor)
    }
  }

  return (
    <div className='c-editor'>
      {
        isLoading && (
        <div className='c-editor__loader'>
          <Skeleton height={height} />
        </div>
        )
      }
      <TinyEditor 
        id={id}
        onEditorChange={handleEditorChange}
        init={{
          resize: canResize,
          menubar: false,
          height: height,
          paste_as_text: true,
          block_formats: blockFormats,
          content_style: `thead th,
            table:not([style*='background-color']) tr:first-of-type td {
              font-weight: 600 !important;
              text-align: center !important;
            }`
        }}
        onInit={handleEditorInit}
        initialValue={initialValue}
        apiKey={process.env.REACT_APP_TINY_MCE_API_KEY}
        plugins={plugins}
        toolbar={toolbar}
      />
    </div>
  )
})

Editor.displayName = 'Editor'

export default Editor