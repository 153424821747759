import { CommandBarButton } from '@fluentui/react'
import { useTranslation } from 'react-i18next'

import { Card } from '@/shared/components'
import LocationDetail from '@/shared/components/LocationDetail'
import { RightSmallArrow } from '@/shared/components/Icons'
import { CaOffice, useGetAllCAOfficesQuery } from '@/shared/api/services/caOffficeService'

export interface OfficeCardProps {
  caOffice?: CaOffice
}

const getAddressFragment = (address: string[] = [], index: 1 | 2) => {
  if (index === 1) {
    return address && address[0] || ''
  }
  const addressCopy = [...address]
  addressCopy.shift()
  return addressCopy.join(' ')
}

const OfficeCard = ({ caOffice }: OfficeCardProps) => {
  const { t } = useTranslation('onboarding')
  const { data: caOffices } = useGetAllCAOfficesQuery()
  const renderIcon = (): JSX.Element => (<RightSmallArrow />)
  const selectedOffice = caOffice || (caOffices || []).find(o => o.city === 'Boston')

  if (!selectedOffice) {
    return null
  }

  const addressLineOne = getAddressFragment(selectedOffice.addressParts, 1)
  const addressLineTwo = getAddressFragment(selectedOffice.addressParts, 2)

  return (
    <Card className='c-onboarding-office-card' ariaLabelledBy='onboardingTeam'>
      <h3 className='c-onboarding-office-card__header' id='onboardingOffice'>{t('yourOffice')}</h3>
      <LocationDetail 
        locationName={selectedOffice.city}
        addressLineOne={addressLineOne}
        addressLineTwo={addressLineTwo}
        phone={selectedOffice.phone}
      />
      <CommandBarButton target='_blank'  href={selectedOffice.officeUrl} className='c-onboarding-office-card__button' onRenderIcon={renderIcon} primary text={t('moreInformation')} />
    </Card>
  )
}

export default OfficeCard