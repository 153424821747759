import { useState, memo, useEffect } from 'react'
import { useDispatch } from 'react-redux'

import modalSlice from '@/shared/redux/modalSlice'
import { PostModal, GeneralError, ErrorMessage } from '@/shared/components'
import CreateEventModal from '../CreateEventModal'
import EventModalDescription from './components/EventModalDescription'
import eventModalColumns from '../../constants/EventModalColumns'
import { useGetEventsQuery, useGetFeaturedEventQuery, useUpdateEventMutation, useUpdateFeatureControlMutation } from '@/shared/api/services/eventServices'
import EventModalDescriptionSkeleton from './components/EventModalDescription/EventModalDescriptionSkeleton'
import EventModalPagination from './components/EventModalPagination'
import { IEvent } from '@/shared/types/swagger'
import withPermission from '@/features/auth/components/withPermission'
import { EUserCan } from '@/features/auth/types'
// keys: event: { label, url }, type, published, isFeatured

type ErrorInfo = {
  message: string,
  status: boolean
}

const MODAL_PARAM_NAME = 'eventModal'

const EventModal = () => {
  
  const dispatch = useDispatch()
  const [currentPage, setCurrentPage] = useState(1)
  const [selectedEvent, setSelectedEvent] = useState<IEvent>(null)
  const [createModalIsOpen, setCreateModalIsOpen] = useState(false)
  const [generalErrorInfo, setGeneralErrorInfo] = useState<ErrorInfo>({ message: '', status: false })
  const { data, isFetching, isLoading, refetch, isError, isUninitialized } = useGetEventsQuery({
    page: currentPage,
    pageSize: 12
  })
  const [newFeaturedId, setNewFeaturedId] = useState(null)
  const [updateEvent, updateEventState] = useUpdateEventMutation()
  const [updateControl] = useUpdateFeatureControlMutation()
  const getFeaturedState = useGetFeaturedEventQuery()
  const [userIsManagingEvents, setUserIsManagingEvents] = useState(getFeaturedState?.data?.isManuallyFeatured)
  const inProgress = isUninitialized || isFetching || isLoading || updateEventState.isLoading || getFeaturedState.isLoading || getFeaturedState.isFetching || getFeaturedState.isUninitialized

  useEffect(() => {
    setUserIsManagingEvents(getFeaturedState.data?.isManuallyFeatured)
  }, [getFeaturedState.data?.isManuallyFeatured])

  const handlePageChange = (newPage: number) => {
    setCurrentPage(newPage)
  }

  const handleConfirmClick = async () => {
    try {
      if (newFeaturedId && userIsManagingEvents) {
        await updateControl()
        const event = data.items.find((event: IEvent) => event.id === newFeaturedId)
        const formData = new FormData();
        Object.keys(event).forEach((val, property) => {
          formData.append(val, event[val]);
        })
        const isFeatured = event.isFeatured ? false : true;
        formData.append('featured', isFeatured.toString());
        await updateEvent(formData);
      } else if (!userIsManagingEvents) {
        await updateControl()
      }

      setNewFeaturedId(null)
      dispatch(modalSlice.actions.setModalStatus({ paramName: MODAL_PARAM_NAME, value: false }))
    } catch (error) {
      handleError(true, 'Error saving the changes.')
    }
  }

  const handleFeaturedClick = (item, index) => {
    setNewFeaturedId(item.id)
  }

  const handleError = (status: boolean, message: string) => {
    setGeneralErrorInfo({
      status, 
      message
    })
  }

  const handleErrorMessageReset = (status: boolean) => {
    setGeneralErrorInfo({
      status: false,
      message: ''
    })
  }

  const handleNewEvent = () => {
    setSelectedEvent(null)
    setCreateModalIsOpen(true)
  }

  const renderDescription = () => {
    if (isLoading) return <EventModalDescriptionSkeleton />
    return (
      <>
        <EventModalDescription 
          userIsManagingEvents={userIsManagingEvents} 
          setUserIsManagingEvents={setUserIsManagingEvents}
          handleNewEvent={handleNewEvent}
        />
        <ErrorMessage 
          setShowAlert={handleErrorMessageReset}
          showAlert={generalErrorInfo.status}
          message={generalErrorInfo.message}
          timeout={10000}
          shouldCloseAfterTimeout={true}
        />
      </>
    )
  }

  const handleEdit = (event: IEvent) => {
    setSelectedEvent(event)
    setCreateModalIsOpen(true)
  }

  const handleCreateModalBack = () => {
    setSelectedEvent(null)
    setCreateModalIsOpen(false)
  }

  const handleCreateModalClose = () => {
    dispatch(modalSlice.actions.setModalStatus({ paramName: MODAL_PARAM_NAME, value: false }))
    setSelectedEvent(null)
    setCreateModalIsOpen(false)
  }

  if (isError) return <GeneralError onClick={refetch} title='The manage events modal could not be loaded.' />

  const columns = eventModalColumns(userIsManagingEvents, handleFeaturedClick, handleError, handleEdit)
  const tableData: Array<IEvent> = (() => {
    if (inProgress) return []
    const featured = getFeaturedState?.data as IEvent
    const notFeatured = (data?.items || []).filter((event) => event.id !== featured?.id)
    const _tableData: Array<IEvent> = currentPage === 1 
      ? [...[featured].filter(Boolean), ...notFeatured] 
      : notFeatured
    return _tableData.map((event: IEvent) => ({
      ...event,
      isFeatured: newFeaturedId ? newFeaturedId === event.id : event.isFeatured
    }))
  })()

  return (
    <>
      <PostModal 
        className='c-event-modal'
        onConfirmClick={handleConfirmClick} 
        primaryLabel="Save"
        openButtonLabel="Manage Events"
        modalTitle="Manage Events"
        shimmerIsEnabled={inProgress}
        paramName={MODAL_PARAM_NAME}
        ariaLabels={{
          openButton: 'Open',
          closeButton: 'Close' 
        }}
        onClose={() => {
          setSelectedEvent(null)
          setNewFeaturedId(null)
          setCurrentPage(1)
          setUserIsManagingEvents(getFeaturedState.data?.isManuallyFeatured)
        }}
        footer={isLoading ? null : <EventModalPagination count={data?.count} onPageChange={handlePageChange} />}
        description={renderDescription()}
        tableProps={{
          columns,
          data: tableData
        }}
      />
      <CreateEventModal
        isOpen={createModalIsOpen}
        onClose={handleCreateModalClose}
        goBack={handleCreateModalBack}
        goBackAriaLabel='Go back'
        selectedEvent={selectedEvent}
      />
    </>
  )
}

export default withPermission(EUserCan.Manage_Events)(EventModal)