import { emmaApiService } from ".";

export interface WelcomeMessageModel {
  id: string;
  accountId: string;
  title: string;
  sectionId: number;
  message: string;
  isActive: boolean;
  createdAt: Date;
  modifiedAt: Date;
  templateId: number;
  templateName: string;
  isSelected: boolean;
}

export interface TemplateModel {
  templateId: number;
  templateName: string;
  isSelected: boolean;
  isEdited: boolean
}
export interface WelcomeMessageResponse {
  messages: WelcomeMessageModel[];
  templates: TemplateModel[];
}

const welcomeMessageService = emmaApiService
  .enhanceEndpoints({ addTagTypes: ['WelcomeMessages'] })
  .injectEndpoints({
    endpoints(build) {
      return {
        getWelcomeMessagesByAccountId: build.query<WelcomeMessageResponse, string>({
          query: (accountId) => ({
            url: `/welcomeMessage/${accountId}`,
            method: 'GET'
          }),
          providesTags: ['WelcomeMessages']
        }),
        updateWelcomeMessage: build.mutation<WelcomeMessageResponse, WelcomeMessageModel>({
          query: (postData) => (
            {
              url: '/welcomeMessage',
              method: 'PUT',
              data: postData
            }
          ),
          invalidatesTags: ['WelcomeMessages']
        }),
        updateMessageTemplate: build.mutation<WelcomeMessageResponse, { accountId: string, template: TemplateModel }>({
          query: ({ accountId, template }) => (
            {
              url: `/welcomeMessage/updateTemplate/${accountId}`,
              method: 'PUT',
              data: template
            }
          ),
          invalidatesTags: ['WelcomeMessages']
        }),
      }
    },
  })

export const {
  useGetWelcomeMessagesByAccountIdQuery,
  useUpdateWelcomeMessageMutation,
  useUpdateMessageTemplateMutation,
} = welcomeMessageService
