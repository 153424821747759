import * as ReactDOM from 'react-dom'
import { RouteComponentProps } from 'react-router'
import { ContentExplorer } from 'box-ui-elements/es/elements'
import SecurityBlockedState from 'box-ui-elements/es/icons/states/SecurityBlockedState'
import { IntlProvider } from 'react-intl'
import { IBoxFileDownload, IBoxFolderCollection, IBoxFolderContentExplorerState, downloadBoxFile, useGetBoxTokenQuery } from '@/shared/api/services/boxServices'
import { MainLayout, GeneralError } from '@/shared/components'
import PageHeader from '@/shared/components/PageHeader'
import Skeleton from 'react-loading-skeleton'
import { contentSidebarProps, FOLDER_QUERY_KEY } from '@/features/document/constants'
import { BoxFile } from '@/shared/types/box'
import debounce from 'lodash/debounce'
import useQueryParamSubscription from '@/shared/hooks/useQueryParamSubscription'
import { useCallback, useEffect, useRef, useState } from 'react'
import { Checkbox, DefaultButton, MessageBarType, TooltipHost } from '@fluentui/react'
import { useDispatch, useSelector } from 'react-redux'
import { appDispatch } from '@/bootstrap/redux'
import { createUIMessage } from '@/features/uimessages/redux/operations'
import { selectAccountId } from '@/features/auth/redux/authSelectors'
import { getSharedDocumentLink } from '../SharedDocumentLink/SharedDocumentLink'
import { AxiosResponse } from 'axios'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import useAccountName from '@/features/auth/hooks/useAccountName'
import useClientId from '@/features/auth/hooks/useClientId'
import { trackDocumentDownloadFileAction, trackDocumentViewFolderAction } from '@/utils/components/datadog/DatadogSessionProvider'

export default function Documents({ history, location }: RouteComponentProps) {
  const accountId = useSelector(selectAccountId)
  const { accountName } = useAccountName()
  const { clientId } = useClientId(accountId)
  const { data, isLoading, isUninitialized, isError, refetch } = useGetBoxTokenQuery(accountId) // Refetch every 15 minutes.
  const [folderId, setFolderId] = useQueryParamSubscription(FOLDER_QUERY_KEY)
  const [folderName, setFolderName] = useState('')
  const [canPreview, setCanPreview] = useState(true)
  const [pageKey, setPageKey] = useState(location.key)
  const dispatch = useDispatch<appDispatch>()
  const [hasPermission, setHasPermission] = useState(true)

  // element reference for Box context explorer
  const contentExplorerRef = useRef(null)

  // state models for Box content explorer response and selected files
  const [ boxState, setBoxState ] = useState({} as IBoxFolderContentExplorerState)

  useEffect(() => {
    if (boxState?.boxFolder) {
      refreshContentExplorer(boxState?.boxResponse || boxState?.boxFolder)
    }
  }, [boxState])

  // custom function to override Context Explorer built-in 404 error elements
  const renderContentExplorerForbiddenError = () => {
    // creates forbidden SVG react element
    const forbiddenSvg = (<SecurityBlockedState />)

    // creates custom div to wrap forbidden SVG react element
    const customDiv = document.createElement('div')

    // adds forbidden SVG react element to custom div
    ReactDOM.render(forbiddenSvg, customDiv)

    // gets forbidden SVG html
    const forbiddenSvgHTML = customDiv.innerHTML || ''

    // error element
    const errorElement = document.querySelector('.bce-content .be-empty') as HTMLElement

    // replaces error element with forbidden security message
    if (errorElement) {
      errorElement.innerHTML = `<div class="client-contact-documents-forbidden-error">
        ${forbiddenSvgHTML}
        <div class="bcpr-PreviewError-message">
          <span>Your access to this content is restricted due to a security policy.</span>
        </div>
      </div>` 
    }
  }

  const hasSelectedFiles = () => {
    // checkes if user selected any files from current folder on Box content explorer using checkbox selection
    const fileIds = boxState?.boxFolder?.item_collection?.entries?.filter(file => (typeof file === 'string') ? `${file}`.startsWith('file_') : file?.type === 'file')
      ?.map(file =>  (typeof file === 'string') ? `${file}`.replace('file_', '') : file?.id) || []
    return fileIds.some(fileId => boxState?.selectedFiles[fileId])
  }

  const isAllFilesSelected = () => {
    // checkes if user selected all files from current folder on Box content explorer using checkbox selection
    const fileIds = boxState?.boxFolder?.item_collection?.entries?.filter(file => (typeof file === 'string') ? `${file}`.startsWith('file_') : file?.type === 'file')
      ?.map(file =>  (typeof file === 'string') ? `${file}`.replace('file_', '') : file?.id) || []
    return fileIds.reduce((acc, fileId) => {
      return acc && !!boxState?.selectedFiles[fileId]
    }, true)
  }

  const isFileSelected = (fileId: string) => {
    // checkes if user selected the file in the parameter from current folder on Box content explorer
    return boxState?.selectedFiles[fileId]
  }

  const onSelectAllFiles = (event: React.FormEvent<HTMLElement | HTMLInputElement>, checked: boolean) => {
    //  selects all files from current folder on Box content explorer
    const fileIds = boxState?.boxFolder?.item_collection?.entries?.filter(file => (typeof file === 'string') ? `${file}`.startsWith('file_') : file?.type === 'file')
      ?.map(file =>  (typeof file === 'string') ? `${file}`.replace('file_', '') : file?.id) || []
    const files = checked ? fileIds.reduce((acc, fileId) => {
      acc[fileId] = true
      return acc
    }, {} as { [fileId: string]: boolean }) : {}
    setBoxState({
      ...boxState,
      selectedFiles: {
        ...files,
      },
    })
  }

  const onSelectFile = (fileId: string, checked: boolean) => {
    // toggles file selection from current folder on Box content explorer
    setBoxState({
      ...boxState,
      selectedFiles: {
        ...boxState.selectedFiles,
        [fileId]: checked,
      },
    })
  }

  const onContentExplorerScroll = (event: MouseEvent ) => {
    const currentFolderId = window?.location?.href?.match(/folderId\=([0-9]+)/)?.[1]
    const boxStateFolderId = boxState?.boxFolder?.id

    if (currentFolderId && boxStateFolderId && (currentFolderId !== boxStateFolderId)) {
      return
    }

    // refreshes current folder checkbox setup on Box content explorer due to table virtualization handled internally on box component
    refreshContentExplorerFiles(contentExplorerRef?.current?.innerElement, boxState?.boxResponse || boxState?.boxFolder)
  }

  const onDownloadSelectedFiles = async () => {
    // gets file selection from current folder on Box content explorer and trigger bulk download
    const sourceFiles = (boxState?.boxResponse || boxState?.boxFolder)?.item_collection?.entries?.filter(file => (typeof file === 'string') ? `${file}`.startsWith('file_') : file?.type === 'file')
    const selectedFiles = sourceFiles?.filter(file =>  {
      const fileId = (typeof file === 'string') ? `${file}`.replace('file_', '') : file?.id
      return boxState?.selectedFiles[fileId]
    }) || []

    // sorts files to start downloading files by size ASC
    selectedFiles?.sort((a, b) => {
      const aSize = (typeof a !== 'string') ? (a?.size || 0) : 0
      const bSize = (typeof b !== 'string') ? (b?.size || 0) : 0
      return aSize - bSize
    })

    if (selectedFiles?.length) {
      try {
        dispatch(createUIMessage({ 
          key: 'bulkDownloadStartMessage', 
          content: `Starting Bulk Download for selected files.`,
          messageBarType: MessageBarType.success,
          autoDismissAfter: 3000
        }))

        const downloadPromises = [] as Promise<IBoxFileDownload>[]

        for (const selectedFile of selectedFiles) {
          const fileId = (typeof selectedFile === 'string') ? `${selectedFile}`.replace('file_', '') : selectedFile?.id
          const fileName = (typeof selectedFile === 'string') ? `${selectedFile}` : selectedFile?.name
            
            if (fileId) {
              downloadPromises.push(downloadBoxFile(folderId, folderName, fileId, fileName, data.accessToken))
              trackDocumentDownloadFileAction({
                accountId,
                accountName,
                clientId,
                folderId,
                folderName,
                fileId,
                fileName,
              })
            }
        }

        Promise.all(downloadPromises).then(downloadedFiles => {
          const downloadErrrors = (downloadedFiles || []).filter(d => !d?.success)

          if (downloadErrrors?.length) {
            if (downloadErrrors?.length === downloadedFiles?.length) {
              dispatch(createUIMessage({ 
                key: 'bulkDownloadErrorMessage', 
                content: `Error while processing Bulk Download for selected files.`,
                messageBarType: MessageBarType.error,
                autoDismissAfter: 3000
              }))
            } else {
              dispatch(createUIMessage({ 
                key: 'bulkDownloadCompleteMessageWithFailures', 
                content: `Completed Bulk Download for selected files. \nCould not download the following files: `
                  + downloadErrrors?.map(d => d?.fileName)?.filter(f => !!f)?.join(', ')
                  + '.',
                messageBarType: MessageBarType.success,
                autoDismissAfter: 3000
              }))  
            }
          } else {
            dispatch(createUIMessage({ 
              key: 'bulkDownloadCompleteMessage', 
              content: `Completed Bulk Download for selected files.`,
              messageBarType: MessageBarType.success,
              autoDismissAfter: 3000
            }))
          }
        }).catch(bulkDownloadPromiseError => {
          dispatch(createUIMessage({ 
            key: 'bulkDownloadErrorMessage', 
            content: `Error while processing Bulk Download for selected files.`,
            messageBarType: MessageBarType.error,
            autoDismissAfter: 3000
          }))
          console.error('Error while processing bulk download.', bulkDownloadPromiseError)
        })
      } catch (bulkDownloadError) {
        dispatch(createUIMessage({ 
          key: 'bulkDownloadErrorMessage', 
          content: `Error while processing Bulk Download for selected files.`,
          messageBarType: MessageBarType.error,
          autoDismissAfter: 3000
        }))
        console.error('Error while processing bulk download.', bulkDownloadError)
      }
    }
  }

  const onSwitchModeViewClick = (event: MouseEvent) => {
    refreshContentExplorer(boxState?.boxResponse || boxState?.boxFolder, 2000)
  }

  const refreshContentExplorerButtonGroup = (contentExplorerElement: HTMLElement, currentBoxFolder: IBoxFolderCollection) => {
    // adds checkbox selection on Box content explorer header
    const hasFiles = currentBoxFolder?.item_collection?.entries?.some(file => (typeof file === 'string') ? `${file}`.startsWith('file_') : file?.type === 'file')
    const subHeader = contentExplorerElement.querySelector('.be-sub-header-right') as HTMLElement

    if (subHeader) {
      const switchModeViewButton = subHeader.querySelector('.bdl-ViewModeChangeButton') as HTMLElement

      if (switchModeViewButton) {
        switchModeViewButton.onclick = onSwitchModeViewClick
      }

      let downloadButtonContainer = subHeader.querySelector('.download-button-container') as HTMLElement
      
      if (downloadButtonContainer) {
        // removes existing checkbox header container
        downloadButtonContainer.remove()
      }

      if (hasFiles) {
        // creates download button container
        downloadButtonContainer = document.createElement('div')
        downloadButtonContainer.classList.add('download-button-container')
        subHeader.appendChild(downloadButtonContainer)

        // creates download button
        ReactDOM.render((
          <TooltipHost
            aria-disabled={ !hasSelectedFiles() }
            content='Download selected files'
            tooltipProps={{
              styles: {
                content: {
                  color: 'rgba(255, 255, 255, 0.9)',
                },
              }
            }}
            styles={{
              root: { 
                display: 'inline-block',
              },
            }}
            calloutProps={{
              gapSpace: 0,
              styles: {
                beak: {
                  background: '#000000',
                },
                beakCurtain: {
                  background: '#000000',
                },
                calloutMain: {
                  background: '#000000',
                },
                container: {
                  opacity:  0.8,
                },
              }
            }}
          >
            <DefaultButton className='download-button'  disabled={ !hasSelectedFiles() } onClick={onDownloadSelectedFiles}>
              <FontAwesomeIcon icon='download' className="c-sidebar-section__heading-icon" />
            </DefaultButton>
          </TooltipHost>
        ), downloadButtonContainer)
      }
    }
  }

  const refreshContentExplorerHeader = (contentExplorerElement: HTMLElement, currentBoxFolder: IBoxFolderCollection) => {
    // adds checkbox selection on Box content explorer header
    const hasFiles = currentBoxFolder?.item_collection?.entries?.some(file => (typeof file === 'string') ? `${file}`.startsWith('file_') : file?.type === 'file')
    const listViewContainer = contentExplorerElement.querySelector('.bce-item-grid') as HTMLElement
    const gridViewContainer = contentExplorerElement.querySelector('.resize-triggers') as HTMLElement
    let checkboxHeaderContainer = contentExplorerElement.querySelector('.download-checkbox-header') as HTMLElement

    const refreshListViewContainer = () => {
      const tableHeader = contentExplorerElement.querySelector('.ReactVirtualized__Table__headerRow') as HTMLElement
    
      if (tableHeader) {
        if (hasFiles) {
          // creates checkbox header container
          checkboxHeaderContainer = document.createElement('div')
          checkboxHeaderContainer.classList.add('download-checkbox-header')
          checkboxHeaderContainer.classList.add('ReactVirtualized__Table__headerColumn')

          // appends checkbox header container
          tableHeader.prepend(checkboxHeaderContainer)

          // creates react checkbox header
          ReactDOM.render((
            <Checkbox checked={isAllFilesSelected()} onChange={onSelectAllFiles} className='checkbox-header-component' />
          ), checkboxHeaderContainer)
        }
      }
    }

    const refreshGridViewContainer = () => {
      const subHeader = contentExplorerElement.querySelector('.be-sub-header .be-breadcrumbs') as HTMLElement
    
      if (subHeader) {
        if (hasFiles) {
          // creates checkbox header container
          checkboxHeaderContainer = document.createElement('div')
          checkboxHeaderContainer.classList.add('download-checkbox-header')

          // appends checkbox header container
          subHeader.prepend(checkboxHeaderContainer)

          // creates react checkbox header
          ReactDOM.render((
            <Checkbox checked={isAllFilesSelected()} onChange={onSelectAllFiles} className='checkbox-header-component' />
          ), checkboxHeaderContainer)
        }
      }
    }

    if (checkboxHeaderContainer) {
      // removes existing checkbox header container
      checkboxHeaderContainer.remove()
    }

    if (listViewContainer) {
      refreshListViewContainer()
    } else if (gridViewContainer) {
      refreshGridViewContainer()
    }
  }

  const refreshContentExplorerFiles = (contentExplorerElement: HTMLElement, currentBoxFolder: IBoxFolderCollection) => {
    // adds checkbox selection on Box content explorer files rows
    const hasFiles = currentBoxFolder?.item_collection?.entries?.some(file => (typeof file === 'string') ? `${file}`.startsWith('file_') : file?.type === 'file')
    const listViewContainer = contentExplorerElement.querySelector('.bce-item-grid') as HTMLElement
    const gridViewContainer = contentExplorerElement.querySelector('.resize-triggers') as HTMLElement

    const refreshListViewContainer = () => {
      const tableRows = contentExplorerElement.querySelectorAll('.ReactVirtualized__Table__row')

      if (tableRows && tableRows.length) {
        // removes existing checkbox components
        for (let i = 0; i < tableRows.length; i++) {
          const tableRow = tableRows.item(i) as HTMLElement
          
          if (tableRow) {
            const checkboxRowContainer = tableRow.querySelector('.download-checkbox-row')

            if (checkboxRowContainer) {
              // removes existing checkbox row container
              checkboxRowContainer.remove()
            }
          }
        }

        // stop function execution in case of no files
        if (!hasFiles) {
          return
        }

        // creates checkbox components
        for (let i = 0; i < tableRows.length; i++) {
          const tableRow = tableRows.item(i) as HTMLElement
          
          if (tableRow) {
            const fileNameNode = tableRow.querySelector('.be-item-name .be-item-label') as HTMLElement
            const boxEntry = fileNameNode && currentBoxFolder?.item_collection?.entries?.find(file => (typeof file !== 'string') && file?.name === fileNameNode.innerText)
              || currentBoxFolder?.item_collection?.entries?.[i]
            const isCheckboxDisabled = typeof boxEntry === 'string' ? !`${boxEntry}`.startsWith('file_') : boxEntry?.type !== 'file'
            const fileId = typeof boxEntry === 'string' ? `${boxEntry}`.replace('file_', '') : boxEntry?.id

            // creates checkbox row container
            const checkboxRowContainer = document.createElement('div')
            checkboxRowContainer.classList.add('download-checkbox-row')
            checkboxRowContainer.classList.add('ReactVirtualized__Table__headerColumn')

            // appends checkbox row container
            tableRow.prepend(checkboxRowContainer)

            // creates react checkbox row
            ReactDOM.render((
              <Checkbox key={fileId} disabled={isCheckboxDisabled} checked={isFileSelected(fileId)}
                onChange={(event, checked) => onSelectFile(fileId, checked)} className='checkbox-row-component' />
            ), checkboxRowContainer)
          }
        }
      }
    }

    const refreshGridViewContainer = () => {
      const gridViewSlots = contentExplorerElement.querySelectorAll('.bdl-GridViewSlot')

      if (gridViewSlots && gridViewSlots.length) {
        // removes existing checkbox components
        for (let i = 0; i < gridViewSlots.length; i++) {
          const gridViewSlot = gridViewSlots.item(i) as HTMLElement
          
          if (gridViewSlot) {
            const checkboxRowContainer = gridViewSlot.querySelector('.download-checkbox-row')

            if (checkboxRowContainer) {
              // removes existing checkbox row container
              checkboxRowContainer.remove()
            }
          }
        }

        // stop function execution in case of no files
        if (!hasFiles) {
          return
        }

        // creates checkbox components
        for (let i = 0; i < gridViewSlots.length; i++) {
          const gridViewSlot = gridViewSlots.item(i) as HTMLElement
          
          if (gridViewSlot) {
            const itemNameNode = gridViewSlot.querySelector('.be-item-name') as HTMLElement
            const fileNameNode = gridViewSlot.querySelector('.be-item-name .be-item-label') as HTMLElement
            const boxEntry = fileNameNode && currentBoxFolder?.item_collection?.entries?.find(file => (typeof file !== 'string') && file?.name === fileNameNode.innerText)
              || currentBoxFolder?.item_collection?.entries?.[i]
            const isCheckboxDisabled = typeof boxEntry === 'string' ? !`${boxEntry}`.startsWith('file_') : boxEntry?.type !== 'file'
            const fileId = typeof boxEntry === 'string' ? `${boxEntry}`.replace('file_', '') : boxEntry?.id

            // creates checkbox row container
            const checkboxRowContainer = document.createElement('div')
            checkboxRowContainer.classList.add('download-checkbox-row')

            if (itemNameNode) {
              // appends checkbox row container
              itemNameNode.prepend(checkboxRowContainer)
            }

            // creates react checkbox row
            ReactDOM.render((
              <Checkbox key={fileId} disabled={isCheckboxDisabled} checked={isFileSelected(fileId)}
                onChange={(event, checked) => onSelectFile(fileId, checked)} className='checkbox-row-component' />
            ), checkboxRowContainer)
          }
        }
      }
    }

    if (listViewContainer) {
      refreshListViewContainer()
    } else if (gridViewContainer) {
      refreshGridViewContainer()
    }
  }

  const refreshContentExplorerScroll = (contentExplorerElement: HTMLElement) => {
    const scrollElement = contentExplorerElement.querySelector('.ReactVirtualized__Grid') as HTMLElement

    if (scrollElement) {
      // always overrides scroll method
      scrollElement.onscroll = debounce(onContentExplorerScroll, 1000)
    }
  }

  const refreshContentExplorer = (currentBoxFolder: IBoxFolderCollection, asyncDelay = 1000) => {
    // skips content explorer refresh in case of no download permission
    if (!currentBoxFolder?.permissions?.can_download) {
      return
    }

    // skips content explorer refresh in case of no content explorer valid element
    if (!contentExplorerRef?.current?.innerElement) {
      return
    }

    const contentExplorerElement = contentExplorerRef?.current?.innerElement as HTMLElement

    setTimeout(() => {
      refreshContentExplorerButtonGroup(contentExplorerElement, currentBoxFolder)
      refreshContentExplorerHeader(contentExplorerElement, currentBoxFolder)
      refreshContentExplorerFiles(contentExplorerElement, currentBoxFolder)
      refreshContentExplorerScroll(contentExplorerElement)
    }, asyncDelay)
  }

  const onContentExplorerFileDownload = (files: BoxFile[]) => {
    if (files && files.length) {
      for (let i = 0; i < files.length; i++) {
        const file = files[i]
  
        if (file) {
          trackDocumentDownloadFileAction({
            accountId,
            accountName,
            clientId,
            folderId,
            folderName,
            fileId: file.id,
            fileName: file.name,
          })
        }
      }
    }
  }

  const renderContentExplorer = () => {
    if (isError) return <GeneralError title='Failed to get box token' onClick={refetch} />

    if (isLoading || isUninitialized) {
      return <Skeleton height={900} width="100%" />
    }
 
    return (
      <ContentExplorer 
        ref={contentExplorerRef}
        token={data.accessToken}
        contentPreviewProps={{ contentSidebarProps }}          
        canShare={false}
        canPreview={canPreview}
        currentFolderId={folderId}
        onDownload={onContentExplorerFileDownload}
        onNavigate={(file: BoxFile) => {
          setFolderId(file.id === '0' ? null : file.id)
          setFolderName(file.name)
          setBoxState({
            ...boxState,
            boxFolder: {
              ...file as any,
            },
            selectedFiles: {},
          })
          if (file) {
            trackDocumentViewFolderAction({
              accountId,
              accountName,
              clientId,
              folderId: file.id,
              folderName: file.name,
            })
          }
        }}
        onPreview={({ file }: { file: BoxFile }) => {
          if (file.type === 'file') {
            setCanPreview(false)
            history.push(`/documents/${file.id}`)
          }
        }}
        responseInterceptor={(response: AxiosResponse<BoxFile>) => {
          try {
            if (response?.status === 404) {
              setHasPermission(false);
              //@ts-expect-error overriting 
              (response as AxiosError<BoxFile>).code = 'forbidden_by_policy'

              // overrides content explorer built-in error with forbidden icon with a delay of 100ms
              setTimeout(renderContentExplorerForbiddenError, 100)
            } else {
              // sets permission when status is not 404 / forbidden_by_policy
              if (!hasPermission) {
                setHasPermission(true)
              }
            }
            if (response?.config?.url?.match(/folders\/[0-9]+$/)) {
              setBoxState({
                boxResponse: {
                  ...response.data as any
                },
                boxFolder: {
                  ... response.data as any,
                },
                selectedFiles: {},
              })  
            }
          } catch (error) {
            console.error('Error parsing response data.', error)
          }
          return response
        }}
      />
    )
  }

  useEffect(() => {
    if (history.action === 'PUSH' && location.search === '') {
      // reset document page state when navigating using the nav
      setPageKey(location.key)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.key])

  const handleCopy = useCallback(async () => {
    try {
      await navigator.clipboard.writeText(getSharedDocumentLink(accountId, folderId))
      dispatch(createUIMessage({ 
        key: 'boxfoliderclipboard', 
        content: `Successfully copied a link to folder <b>${folderName}</b> to your clipboard`,
        messageBarType: MessageBarType.success
      }))
    } catch (error) {
      console.error('Error while creating share link.', error)
    }
  }, [dispatch, folderName])

  return (
    <IntlProvider locale="en">
      <MainLayout>
        <PageHeader 
          label='Documents' 
          action={folderId && hasPermission ? (
            <DefaultButton text='Copy link to folder' onClick={handleCopy} />
          ) : null}
        />
        <div className='c-documents documents-explorer-container --preview-hidden' key={pageKey}>
          {renderContentExplorer()}
        </div>
      </MainLayout>
    </IntlProvider>
  )
}
