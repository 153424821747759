import { selectCAID } from '@/features/auth/redux/authSelectors'
import { useGetPublishedResearchCookieQuery, useLazyGetApplicationCookiesQuery } from '@/shared/api/services/cookieService'
import { useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'

const CookieFetch = ({ isPR }: { isPR: boolean }) => {
  const CAID = useSelector(selectCAID)
  const [getApplicationCookies, GACResult] = useLazyGetApplicationCookiesQuery()
  useGetPublishedResearchCookieQuery(undefined, { skip: !isPR })
  const GACRef = useRef<ReturnType<typeof getApplicationCookies>>()

  useEffect(() => {
    if (isPR) return
    if (GACResult.isUninitialized) {
      GACRef.current = getApplicationCookies(CAID)
    }
    if (GACRef.current.arg !== CAID) {
      GACRef?.current?.abort?.()
      GACRef?.current?.finally(() => getApplicationCookies(CAID))
    }
  }, [CAID, GACResult.isUninitialized, getApplicationCookies, isPR])

  return null
}

export default CookieFetch