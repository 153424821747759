import { IStackTokens, Stack } from '@fluentui/react'
import { useTranslation } from 'react-i18next'
import { Card, HelpPrompt, MainLayout, TeamCard, TertiaryButton } from '@/shared/components'
import LedeText from '@/shared/components/LedeText'
import './Onboarding.scss'
import PageHeader from '@/shared/components/PageHeader'
import OnboardingOffice from '@/features/onboarding/components/OnboardingOffice'
import ManageRegionalOffice from '@/features/onboarding/components/ManageRegionalOffice'
import DynamicHtmlContainer from '@/shared/components/DynamicHtmlContainer'
import { useGetWelcomeMessagesByAccountIdQuery } from '@/shared/api/services/welcomeMessageService'
import ManageWelcomeMessage from '@/features/onboarding/components/ManageWelcomeMessage'
import ManageOnboardingText from '@/features/onboarding/components/ManageOnboardingText'
import YourTeam from '@/shared/components/YourTeam'
import Loader from '@/shared/components/Loader'
import ManageOnboardingTemplate from '@/features/onboarding/components/ManageOnboardingTemplate/ManageOnboardingTemplate'

const leftStackToken: IStackTokens = { childrenGap: 34 }
const rightStackToken: IStackTokens = { childrenGap: 24 }

export interface OnboardingProps {
  accountId: string;
  isEditable: boolean;
}

const getDocumentsLink = (accountId: string, isEditable: boolean) => {
  if (isEditable) {
    return `/clients/${accountId}?tab=docs`
  }
  return '/documents'
}

const Onboarding = ({ accountId, isEditable }: OnboardingProps) => {
  const { t } = useTranslation(['onboarding', 'nav'])
  const { data: data, isFetching, isLoading, refetch, isError, isUninitialized } = useGetWelcomeMessagesByAccountIdQuery(accountId)
  const inProgress = isUninitialized || isFetching || isLoading
  const documentsLink = getDocumentsLink(accountId, isEditable)

  return (
    <>
      {inProgress && <Loader />}
      {!inProgress &&
        <MainLayout className="c-onboarding-welcome__layout" id='onboarding'>
          <PageHeader label='Dashboard' />
          <div className="c-onboarding-welcome">
            <div className="c-onboarding-welcome__left">
              <Card className='c-onboarding-welcome__left__card' ariaLabel="Welcome">
                <Stack tokens={leftStackToken}>
                  {isEditable ? <ManageOnboardingTemplate accountId={accountId} /> : null}
                  {isEditable ? <ManageWelcomeMessage accountId={accountId} /> : null}
                  <LedeText text={data.messages[0].message} />
                  <TertiaryButton className='c-onboarding-welcome__left__card__button' href={documentsLink}>{t('viewDocuments')}</TertiaryButton>
                  {isEditable ? <ManageOnboardingText accountId={accountId} /> : null}
                  <DynamicHtmlContainer html={data.messages[1].message} />
                </Stack>
              </Card>
            </div>
            <Stack className='c-onboarding-welcome__right' tokens={rightStackToken}>
              {isEditable ? <YourTeam accountId={accountId} /> : null}
              <TeamCard accountId={accountId} />
              {isEditable ? <ManageRegionalOffice accountId={accountId} /> : null}
              <OnboardingOffice accountId={accountId} />
              <Stack.Item tokens={{ padding: '24px 0px 0px' }}>
                <HelpPrompt />
              </Stack.Item>
            </Stack>
          </div>
        </MainLayout>}
    </>
  )
}

export default Onboarding