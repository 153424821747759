import React from 'react'
import icon from './check.svg'
import { ICellRendererParams } from 'ag-grid-community'
import UtilService from '../../../services/util/UtilService'

export const CheckCellRenderer = (props: ICellRendererParams) => {
  const { colDef } = props
  const componentId = UtilService.getId(`${colDef.colId}_check_cell_renderer`)
  
  if (props.value) {
    return (
      <i style={{ width: '1.5em', height: '1.5em', display: 'block' }} id={componentId}>
        <img src={icon} />
      </i>
    )
  }
  return null
}
