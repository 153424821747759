import { IconButton } from "@fluentui/react";
import { IInsightTag } from "../../shared/types";

type Props = {
  searchQuery: string,
  searchCount: number,
  tagsQuery?: string,
  deleteTag?: (tagId) => void
}

export default function ResultsHeader({ searchQuery, searchCount, tagsQuery, deleteTag }: Props) {
  const tags = (tagsQuery && JSON.parse(tagsQuery)) || [];

  const deleteTagFromQuery = (tagId) => {
    deleteTag(tagId)
  }

  if (searchCount === 0) {
    if (searchQuery && tags.length) {
      return (
        <div className="c-results-header">
          <h2>No results for {searchQuery} and {tags.map((tag) => (
            <span key={tag.id} className="result-tags">
              {tag.name}
              <IconButton
                className='tags-delete'
                iconProps={{ iconName: 'Cancel' }}
                onClick={() => { deleteTagFromQuery(tag.id) }}
              />
            </span>
          ))}</h2>
          <p>We were unable to find any results from your search term(s). Feel free to try again using different keywords.</p>
        </div>
      )
    }
    return (
      <div className="c-results-header">
        <h2>No results for {searchQuery}</h2>
        <p>We were unable to find any results from your search term(s). Feel free to try again using different keywords.</p>
      </div>
    )
  }

  if (searchQuery && tags.length) {
    return (<div className="c-results-header">
      <h2>{searchCount} results for {searchQuery} and {tags.map((tag) => (
        <span key={tag.id} className="result-tags">
          {tag.name}
          <IconButton
            className='tags-delete'
            iconProps={{ iconName: 'Cancel' }}
            onClick={() => { deleteTagFromQuery(tag.id) }}
          />
        </span>
      ))}</h2>
    </div>
    )
  } else if (tags.length) {
    return (<div className="c-results-header">
      <h2>{searchCount} results for {tags.map((tag) => (
        <span key={tag.id} className="result-tags">
          {tag.name}
          <IconButton
            className='tags-delete'
            iconProps={{ iconName: 'Cancel' }}
            onClick={() => { deleteTagFromQuery(tag.id) }}
          />
        </span>
      ))}
      </h2>
    </div>
    )
  }

  return (
    <div className="c-results-header">
      <h2>{searchCount} results for {searchQuery}</h2>
    </div>
  )
}
