import { sanitize } from "dompurify";

interface Props {
  text: string;
}

const ledeTextSanitize = (htmlValue: string) => {
  return sanitize(htmlValue || '', {
    ADD_ATTR: [
      'target'
    ]
  })
}

export default function LedeText({ text }: Props) {
  const html = ledeTextSanitize(text)
  return (
    <div className="c-lede">
      <p>
        <span dangerouslySetInnerHTML={{ __html: html }} />
      </p>
    </div>
  )
}
