import { useLayoutEffect, useRef } from 'react'
import { IComboBoxOption, IComboBoxStyles, TooltipHost } from '@fluentui/react'
import { Dropdown, IDropdownOption } from '@fluentui/react/lib/Dropdown'
import { PerformanceHierarchy, SLEEVE_DISPLAY_TYPE, getPerformanceHierarchy, useGetSleevesFilterQuery } from '@/shared/api/services/portfolioService'

import './SleeveDropdown.scss'

export interface SleeveDropdownProps {
  portfolioId: string
  asOfDate: string
  sleeveId?: string,
  previewAccountId?: string,
  onSleeveChange: (sleeveId: string) => void
}

const SleeveDropdown = ({
  portfolioId,
  asOfDate,
  sleeveId,
  previewAccountId,
  onSleeveChange,
}: SleeveDropdownProps) => {
  const dropdownRef = useRef(null)
  const { data } = useGetSleevesFilterQuery({ portfolioId, asOfDate, accountId: previewAccountId })

  useLayoutEffect(() => {
    if (data?.length) {
      setTimeout(() => {
        if (dropdownRef) {
          const container = dropdownRef.current as HTMLElement
          
          if (container) {
            const baseContainer = container.querySelector('.ms-ComboBox') as HTMLElement
            
            if (baseContainer) {
              const input = container.querySelector('input') as HTMLElement
              
              if (input) {
                input.setAttribute('disabled', 'disabled')
              }
            }
          }
        }
      })
    }
  }, [data])

  if (!data?.length) {
    return null
  }

  const convertHierarchyToDropdownOptions = (hierarchy: PerformanceHierarchy): IDropdownOption[] => {
    const options: IDropdownOption[] = []
    const traverseHierarchy = (node: PerformanceHierarchy, level = 0) => {
      options.push({
        key: `${node.element.PLISk}`,
        text: `${node.element.Name}`,
        disabled: node.element.disabled,
        data: {
          level: node.element.Lvl,
          disabled: node.element.disabled,
          noDataDisabled: node.element.noDataDisabled,
          vermilionSettingDisabled: node.element.vermilionSettingDisabled,
        }
      })
      node.children.forEach(child => traverseHierarchy(child, level + 1))
    }
    traverseHierarchy(hierarchy)
    return options
  }

  const firstOption = data.find(p => {
    return (p.Lvl === 0) && (p.DisplayPLIType === SLEEVE_DISPLAY_TYPE)
  })

  let dropdownData: IDropdownOption[] = [
    firstOption,
    ...data.filter(p => {
      return (p.ParentPLISk === firstOption.PLISk) && (p.DisplayPLIType === SLEEVE_DISPLAY_TYPE)
    })
  ].map(p => ({
    key: `${p.PLISk}`,
    text: p.Name,
    disabled: p.disabled,
  }))

  dropdownData = convertHierarchyToDropdownOptions(getPerformanceHierarchy(data))
  const selectedKey = `${sleeveId || firstOption?.PLISk || ''}`

  const comboBoxStyles: Partial<IComboBoxStyles> = { 
    root: {
      width: 250,
      outline: 'none',
    },
    optionsContainerWrapper: {
      maxHeight: 216,
      width: 250
    },
  }

  const handleDropdownChange = (event: React.FormEvent<HTMLDivElement>, option?: IComboBoxOption) => {
    onSleeveChange(`${option.key}`)
  }
  
  return (
    <Dropdown
      className='SleeveDropdown'
      ref={dropdownRef}
      defaultSelectedKey={selectedKey}
      options={dropdownData}
      styles={comboBoxStyles}
      onChange={handleDropdownChange}
      calloutProps={{
        className: 'SleeveDropdownOptions'
      }}
      onRenderOption={(option?: IDropdownOption) => {
        if (!option) {
          return <></>
        } 

        const { disabled, data } = (option || {}) as IDropdownOption
        const { level, noDataDisabled,  vermilionSettingDisabled } = (data || {}) as any
        let content = ''

        if (disabled) {
          if (noDataDisabled) {
            content = 'Data unavailable for the time period selected.'
          }

          if (vermilionSettingDisabled) {
            content = 'Not available due to reporting configuration.'
          }
        }

        return (
          <TooltipHost
            content={content}
            styles={{
              root: {
                display: 'inline-block',
              },
            }}>
            <div style={{
              marginLeft: `${level * 15}px`,
            }}>
              {option.text}
            </div>
          </TooltipHost>
        )
      }}
    />
  )
}

export default SleeveDropdown