import React, { useRef } from 'react'
import { ICellRendererParams } from 'ag-grid-community'
import NumberFormat from 'react-number-format'
import UtilService from '../../../services/util/UtilService'

export interface CurrencyCellRendererParams extends ICellRendererParams {
  getCurrencySymbol?: (data: any) => string
  getCurrency?: (data: any) => string
  decimalScale?: number
  negativeNumberClassName? : string
}

export const CurrencyCellRenderer = (props: CurrencyCellRendererParams) => {
  const { colDef, data, getCurrencySymbol, getCurrency, decimalScale = 1, negativeNumberClassName = '' } = props
  
  const componentId = UtilService.getId(
    `${colDef.colId}_currency_cell_renderer`
  )
  const elementReference = useRef(null)
  const setTitle = (title: string) => {
    const { current } = elementReference
    if (current) {
      const element = elementReference.current as any as HTMLElement
      element.title = title
    }
  }

  const getFormattedValue = (value: any) => {
    const isNegative = `${value}`.trim().startsWith('-')
    const currencySymbol = getCurrencySymbol ? (getCurrencySymbol(data) || ''): ''
    const currency = getCurrency ? (getCurrency(data) || ''): ''
    if (value) {
      return isNegative ? currencySymbol + '(' + (value + '').replace('-', '') + ')' + ` ${currency}` : currencySymbol + value + ` ${currency}`
    }
    return '---'
  }

  if (props.value) {
    if (props.value < 0) {
      return (
        <div
          ref={elementReference}
          className={`${negativeNumberClassName} text-center`} 
          style={{
            textAlign: 'right',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
          id={componentId}
        >
          <NumberFormat
            value={props.value}
            displayType='text'
            thousandSeparator={true}
            decimalSeparator='.'
            decimalScale={decimalScale}
            fixedDecimalScale={true}
            renderText={(value) => {
              const formattedValue = getFormattedValue(value)
              setTitle(formattedValue)
              return formattedValue
            }}
          />
        </div>
      )
    }
    return (
      <div
        ref={elementReference}
        className='text-center text-info'
        style={{
          textAlign: 'right',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }}
        id={componentId}
      >
        <NumberFormat
          value={props.value}
          displayType='text'
          thousandSeparator={true}
          decimalSeparator='.'
          decimalScale={decimalScale}
          fixedDecimalScale={true}
          renderText={(value) => {
            const formattedValue = getFormattedValue(value)
            setTitle(formattedValue)
            return formattedValue
          }}
        />
      </div>
    )
  }
  return (
    <div
      ref={elementReference}
      className='text-center'
      style={{
        textAlign: 'right',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      }}
      id={componentId}
    >
      <NumberFormat
        value={props.value}
        displayType='text'
        thousandSeparator={true}
        decimalSeparator='.'
        decimalScale={decimalScale}
        fixedDecimalScale={true}
        renderText={(value) => {
          const formattedValue = getFormattedValue(value)
          setTitle(formattedValue)
          return formattedValue
        }}
      />
    </div>
  )
}
