import { routes } from '@/bootstrap/router'
import { Permission } from '../types'
import { isClientContactDashboardOnlyPermission } from '../constants';

export default function getHomeRoutePath(permissions: Array<Permission>, securityRoles: {
  isCAEmployee: boolean;
  isImpersonating: boolean;
  roles: string[];
}): string {
  if (!securityRoles.isCAEmployee) {
    if (securityRoles.roles.some(sg => isClientContactDashboardOnlyPermission(sg))) {
      return '/documents'
    }
  }
  const homeRoute = Object.values(routes).find((route) => {
    if (
      // routes without a required permission and
      ((!route.authority) || 
      // where the user has the required permission and
      (permissions.includes(route.authority))) &&
      // where the pathname isnt parameterized
      (!route.path.includes(':'))
    ) {
      // are candidates for the default/home route
      return true
    }

    return false
  })

  return homeRoute.path
}