type ConditionalWrapperProps = {
  condition: boolean,
  /** If condition is true. Render children with this parent */
  wrapper: (children: JSX.Element) => JSX.Element
  /** What to render when condition is false */
  children: JSX.Element 
}

const ConditionalWrapper = ({ condition, wrapper, children }: ConditionalWrapperProps): JSX.Element  => condition ? wrapper(children) : children

export default ConditionalWrapper