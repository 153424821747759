
import { useState } from 'react'
import { DashboardSettings } from '@/features/client/components/ClientSettings/ClientSettings.model'
import Allocation from './Allocation/Allocation'
import MarketValue from './MarketValue/MarketValue'
import PerformanceSummary from './PerformanceSummary/PerformanceSummary'
import RecentTrade from './RecentTrades/RecentTrade'
import TopFiveHolding from './TopFiveHolding/TopFiveHolding'
import { IPortfolioSettings } from '@/shared/api/services/portfolioService'

import './ClientPortfolioHome.scss'

export interface ClientPortfolioHomeProps {
  dashboardSettings: DashboardSettings,
  portfolioSettings: IPortfolioSettings,
  portfolioId: string, 
  asOfDate: string,
  sleeveId?: string,
  previewAccountId?: string,
}

export default function ClientPortfolioHome({ 
  dashboardSettings,
  portfolioSettings,
  portfolioId, 
  asOfDate,
  sleeveId,
  previewAccountId,
}: ClientPortfolioHomeProps) {
  const [showTotalPortfolioButton, setShowTotalPortfolioButton] = useState(false)
  const [totalPortfolioId, setTotalPortfolioId] = useState('')
  return (
    <div>
      <div className='d-flex allocation-line'>
        { (dashboardSettings && dashboardSettings.enablePortfolioDashboardMarketValue) ? (
          <MarketValue portfolioSettings={portfolioSettings} isSingleWidget={!(dashboardSettings && dashboardSettings.enablePortfolioDashboardAllocation)} portfolioId={portfolioId} asOfDate={asOfDate} showTotalPortfolioButton={false}
            totalPortfolioId={totalPortfolioId} previewAccountId={previewAccountId} />
        ) : null } 
        { (dashboardSettings && dashboardSettings.enablePortfolioDashboardAllocation) ? (
          <Allocation portfolioSettings={portfolioSettings} dashboardSettings={dashboardSettings} portfolioId={portfolioId} asOfDate={asOfDate} sleeveId={sleeveId} setShowTotalPortfolioButton={setShowTotalPortfolioButton} setTotalPortfolioId={setTotalPortfolioId}
            previewAccountId={previewAccountId} /> 
        ) : null } 
      </div>
      { (dashboardSettings && dashboardSettings.enablePortfolioDashboardPerformance) ? (
        <PerformanceSummary portfolioSettings={portfolioSettings} portfolioId={portfolioId} asOfDate={asOfDate} sleeveId={sleeveId} showTotalPortfolioButton={false}
          previewAccountId={previewAccountId} />
      ) : null } 
      <div className={`d-flex bottom-row top-5-holding-line ${(dashboardSettings && !dashboardSettings.enableClientDashboardRecentTrades) ? 'no-recent-trades' : ''}`}>
        { (dashboardSettings && dashboardSettings.enableClientDashboardRecentTrades) ? (
          <RecentTrade portfolioSettings={portfolioSettings} portfolioId={portfolioId} asOfDate={asOfDate} previewAccountId={previewAccountId} />
        ) : null } 
        { (dashboardSettings && dashboardSettings.enablePortfolioDashboardTopHoldings) ? (
          <TopFiveHolding portfolioSettings={portfolioSettings} portfolioId={portfolioId} asOfDate={asOfDate} previewAccountId={previewAccountId} />
        ) : null } 
      </div>
    </div>
  )
}
