import { ColumnActionsMode } from '@fluentui/react'
import { TableProps } from '@/shared/components/Table'
import { IYourTeam } from '@/shared/api/services/teamService'
import YourTeamModalActions from './YourTeamModalActions'
import YourTeamDragColumn from './YourTeamDragColumn'

type YourTeamModalColumns = (
  handleEdit: (teamMember: IYourTeam) => void,
  handleDelete: (event: IYourTeam) => void
  ) => TableProps['columns']

const yourTeamModalColumns: YourTeamModalColumns = (handleEdit, handleDelete) => [
  {
    key: 'sortIndex',
    name: '',
    fieldName: 'sortIndex',
    columnActionsMode: ColumnActionsMode.disabled,
    minWidth: 50,
    maxWidth: 50,onRender: (item: IYourTeam) => {
      return (
        <YourTeamDragColumn 
          teamMember={item}
        />
      )
    }
  },
  {
    key: 'sortIndex',
    name: 'Order',
    fieldName: 'sortIndex',
    columnActionsMode: ColumnActionsMode.disabled,
    minWidth: 50,
    maxWidth: 50,
  },
  {
    key: 'fullName',
    name: 'Full Name',
    fieldName: 'fullName',
    columnActionsMode: ColumnActionsMode.disabled,
    minWidth: 100,
  },
  {
    key: 'jobTitle',
    name: 'Job Title',
    fieldName: 'jobTitle',
    columnActionsMode: ColumnActionsMode.disabled,
    minWidth: 200,
  },
  {
    key: 'email',
    name: 'Email',
    fieldName: 'email',
    columnActionsMode: ColumnActionsMode.disabled,
    minWidth: 250,
  },
  {
    key: 'actionsColumn',
    name: 'Actions',
    fieldName: 'actions',
    minWidth: 100,
    columnActionsMode: ColumnActionsMode.disabled,
    onRender: (item: IYourTeam) => {
      return (
        <YourTeamModalActions 
          handleEdit={handleEdit}
          handleDelete={handleDelete}
          teamMember={item}
        />
      )
    }
  },
]

export default yourTeamModalColumns