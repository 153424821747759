import { memo } from 'react'
import { DefaultButton, IStackStyles, Stack } from '@fluentui/react'

const stackStyes: IStackStyles = {
  root: {
    width: '100%'
  }
}

type EventModalFiltersProps = {
  handleNewEvent: () => void
}

const EventModalFilters = ({ handleNewEvent }: EventModalFiltersProps) => {
  const handleAddEventClick = () => { handleNewEvent() }

  return (
    <Stack tokens={{ padding: '24px 0px 0px' }} horizontal horizontalAlign='space-between'>
      <Stack styles={stackStyes} horizontal tokens={{ childrenGap: 16, maxWidth: 616 }}>
      </Stack>
      <DefaultButton onClick={handleAddEventClick}>Add New Event</DefaultButton>
    </Stack>
  )
}
export default memo(EventModalFilters)