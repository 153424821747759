import { appDispatch } from '@/bootstrap/redux';
import { createUIMessage } from '@/features/uimessages/redux/operations';
import { useGetSurveyConfigQuery, usePutSurveyConfigMutation } from '@/shared/api/services/surveyService';
import { Card, ErrorMessage, GeneralError, MainLayout, Section } from '@/shared/components';
import PageHeader from '@/shared/components/PageHeader';
import { ISurveyConfigNew } from '@/shared/types/Links';
import { DefaultButton, MessageBarType, PrimaryButton, Stack, Toggle } from '@fluentui/react';
import { useState, useEffect } from 'react';
import Skeleton from 'react-loading-skeleton';
import { useDispatch } from 'react-redux';
import './surveyLinkCard.scss';

export default function SurveyLinks() {
  const dispatch = useDispatch<appDispatch>()
  const [ isFormDirty, setFormDirty ] = useState(false)
  const { data: surveyConfigData, isUninitialized, isLoading, isError, refetch } = useGetSurveyConfigQuery();
  const [saveSurveyConfigData, { isLoading: isSaveSurveyConfigInProgress }] = usePutSurveyConfigMutation();
  const [surveyLinks, setSurveyLinks] = useState<Array<ISurveyConfigNew>>([]);
  const [hasError, setHasError] = useState(false);

  useEffect(() => {
    if (surveyConfigData) {
      setSurveyLinks([
        ...surveyConfigData
      ])
    }
  }, [surveyConfigData]);

  const handleOnCancelClick = () => {
    // removes form dirty state
    setFormDirty(false)

    // resets survey config data to original state
    setSurveyLinks([
      ...surveyConfigData
    ])
  }

  const handleOnClickSave = () => {
    if (hasError) {
      // disables error message form validation
      setHasError(false)
    }

    saveSurveyConfigData(surveyLinks)
      .unwrap()
      .then(() => {
        // removes form dirty state
        setFormDirty(false)

        // displays save success message notification
        dispatch(createUIMessage({ 
          key: 'surveyconfigsuccess', 
          content: 'Survey link changes saved sucessfully',
          messageBarType: MessageBarType.success,
          autoDismissAfter: 3000
        }))
      })
      .catch((error) => { 
        // displays save error message notification
        dispatch(createUIMessage({ 
          key: 'surveyconfigsuccess', 
          content: 'Failed to save survey link changes',
          messageBarType: MessageBarType.error,
          autoDismissAfter: 3000
        }))

        // enables error message form validation
        setHasError(true)
      })
  }  

  const updateValue = (event: React.FormEvent<HTMLElement | HTMLInputElement>, checked: boolean) => {
    surveyLinks.map((e, i) => {
      if (e.id === event.target['id']) {
        const clone = [...surveyLinks]
        const cloneOf = clone[i]
        clone[i] = {
          ...cloneOf,
          configValue: checked
        }
        setSurveyLinks(clone)
      }
    })

    // sets form dirty state
    setFormDirty(true)
  }

  return (
    <MainLayout className='survey-links-card'>
      <PageHeader label='Survey Links' className='c-survey-header' />
      <Section className='c-survey'>
        <Card ariaLabel='surveyLinkConfiguration' className='survey-configuration-card'>
          {isError && <GeneralError title='Failed to load' onClick={refetch} />}
          {(isLoading || isUninitialized) && <Skeleton width="60%" count={7} />}
          <ErrorMessage message='Failed to save Survey Links Configuration' showAlert={hasError} setShowAlert={setHasError}  />
          <div className='document-sharing'>
            {surveyLinks && surveyLinks.map((link, i) => {
              return (<div className='toggle-controller' key={link.id}>
                <Toggle id={link.id} label={link.labelName} inlineLabel checked={surveyLinks[i].configValue} onChange={updateValue} />
              </div>)
            })}
          </div>
          <div className='action-buttons'>
            <DefaultButton onClick={handleOnCancelClick}>Cancel</DefaultButton>
            <PrimaryButton type="button" className='save-button' onClick={handleOnClickSave} disabled={!isFormDirty || isSaveSurveyConfigInProgress}>Save</PrimaryButton>
          </div>
        </Card>
      </Section>
    </MainLayout>
  )

}
