import dayjs from '@/bootstrap/dayjs'
import usePermission from '@/features/auth/hooks/usePermission'
import { EUserCan } from '@/features/auth/types'
import { useGetMaintenanceDataQuery, useUpdateMaintenanceDataMutation } from '@/shared/api/services/portalService'
import allValuesAreTruthy from '@/shared/helpers/allValuesAreTruthy'
import { useMemo, useState } from 'react'
import './MaintenanceBanner.scss'
import MaintenanceBannerManagement from './components/MaintenanceBannerManagement'
import { Icon, IconButton } from '@fluentui/react'
import { getUTCTZDatetime } from '@/shared/types/date'

export const MAINT_BANNER_LS_KEY = 'show-maint-banner'

export default function MaintenanceBanner() {
  const { data, isSuccess } = useGetMaintenanceDataQuery()
  const [showBanner, setShowBanner] = useState(Boolean(JSON.parse(localStorage.getItem(MAINT_BANNER_LS_KEY)) ?? true))

  function hideBanner() {
    setShowBanner(false)
    localStorage.setItem(MAINT_BANNER_LS_KEY, JSON.stringify(false))
  }
 
  const banner = useMemo(() => {
    if (!isSuccess || !data || !allValuesAreTruthy({ ...data })) return null

    const today = dayjs()

    if (!showBanner) return null
    if (today.isAfter(getUTCTZDatetime(data?.maintenanceBegin)) && today.isBefore(getUTCTZDatetime(data?.maintenanceEnd))) return (
      <section id="maintenance-banner" role='alert' className='c-maint-banner'>
        <Icon className='c-maint-banner__icon' iconName='CRMCustomerInsightsApp' title='Shining lightbulb icon'/>
        <span className='c-maint-banner__text'>{data.bannerText}</span>
        <IconButton 
          iconProps={{ iconName: 'Cancel' }} 
          aria-label='Dismiss message' 
          onClick={hideBanner} 
          className='c-maint-banner__dismiss'
        />
      </section>
    )
    return null
  }, [data, isSuccess, showBanner])

  const userCanManage = usePermission(EUserCan.Edit_Maintenance_Data)
  const adminModal = userCanManage && isSuccess ? <MaintenanceBannerManagement /> : null

  return <>{adminModal}{banner}</>
}