import NumberFormat from 'react-number-format'
import './DetailsData.scss'

export interface IDetailsData {
  data: any
}

const DetailsData = ({
  data
}: IDetailsData) => {
  const percentageFormatter = (percentageValue) => {
    if (parseFloat(percentageValue) < 0) {
      return (
        <NumberFormat
          value={percentageValue}
          displayType='text'
          thousandSeparator={true}
          decimalSeparator='.'
          fixedDecimalScale={true}
          renderText={(value) => {
            const formattedValue = '(' + ((parseFloat(value)).toFixed(1) + '').replace('-', '') + ')'
            return formattedValue
          }}
        />
      )
    }
    if (percentageValue === 0 ) {
      return (
        <NumberFormat
          value={percentageValue}
          displayType='text'
          isNumericString={true}
          renderText={(value) => {
            const formattedValue = value
            return formattedValue
          }}
        />
      )
    }
    if (percentageValue === null || percentageValue === undefined ) {
      return (
        <NumberFormat
          value={percentageValue}
          displayType='text'
          isNumericString={true}
          renderText={(value) => {
            const formattedValue = '---'
            return formattedValue
          }}
        />
      )
    }

    return (
      <NumberFormat
        value={percentageValue}
        displayType='text'
        isNumericString={true}
        renderText={(value) => {
          const formattedValue = (parseFloat(value)).toFixed(0)
          return formattedValue
        }}
      />
    )
  }

  return (
    <div className='details'>
      <table className='detailes-data'>
        <tr>
          <td>Current Market Value:</td>
          <td>
            <NumberFormat
              value={data?.currentMktValue}
              displayType='text'
              thousandSeparator={true}
              decimalSeparator='.'
              decimalScale={data?.currentMktValue && data?.currentMktValue.toString().split('.')[1]?.length === 3 ? 3 : 0}
              fixedDecimalScale={true}
              renderText={(value) => {
                  const isNegative = `${value}`.trim().startsWith('-')
                  const currencySymbol = data?.currencySymbol
                  const formattedValue =  !value || value === '0.00' ? ' --- ' : isNegative ? currencySymbol + '(' + (value + '').replace('-', '') + ')' : currencySymbol + value
                  return formattedValue
              }}
            />
          </td>
        </tr>
        <tr>
          <td>Actual Allocation (%):</td>
          <td>
            {percentageFormatter(data.actualAllocation)}
          </td>
        </tr>
        <tr>
          <td>Long Term Target (%):</td>
          <td>
            {percentageFormatter(data.longTrmTrgt)}
          </td>
        </tr>
        <tr>
          <td>Long Term Target Variance (%):</td>
          <td>
            {percentageFormatter(data.longTrmTrgtVariance)}
          </td>
        </tr>
      </table>
    </div>
  )
}
export default DetailsData