import DonutChart from '@/lib/common/components/charts/donut-chart/DonutChart'
import UtilService from '@/lib/common/services/util/UtilService'
import { Modal } from '@fluentui/react'
import { useBoolean } from '@fluentui/react-hooks'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useState } from 'react'
import DetailsData from './DetailsData/DetailsData'

import './AllocationDetail.scss'

export interface IAllocationDetail {
  onClose: (isDetailed: boolean) => void,
  allocationData: any[],
  asOfDate: any,
  indexSelected: number
}

const AllocationDetail = ({
  onClose,
  allocationData,
  asOfDate,
  indexSelected
}: IAllocationDetail) => {
  const [isModalOpen, { setTrue: showModal, setFalse: hideModal }] = useBoolean(false)
  const [count, setCount] = useState<number>(indexSelected)

  const onDataNavigate = (direction) => {
    let counter = count
    if (direction === 'forward') {
      counter++
      if (counter > (allocationData.length - 1)) {
        counter = 0
      }
    } else {
      counter--
      if (counter < 0) {
        counter = allocationData.length - 1
      }
    }
    setCount(counter)
  }

  return (
    <div>
      <Modal
        className='ModalAllocationDetailed'
        titleAriaId='Settings'
        isOpen={true}
        isBlocking={false}
      >
        <div className='ms-container'>
          <div className='ms-header'>
            <span className='title-header'>Allocation</span>
            <FontAwesomeIcon className='icons-mb' icon={['fas', 'times']} onClick={() => onClose(false)} />
          </div>
          <div className='ms-body'>
            <div className='header-allocation'>
              <FontAwesomeIcon className='icons-mb' icon={['fas', 'chevron-left']} onClick={() => onDataNavigate('previous')} />
              <div className='header-title'>
                <h6 className='tittle-header-allocation'>{allocationData[count]?.name}</h6>
                <h3 className='c-section__header-subtitle'>{asOfDate}</h3>
              </div>
              <FontAwesomeIcon className='icons-mb' icon={['fas', 'chevron-right']} onClick={() => onDataNavigate('forward')} />
            </div>
            <DonutChart
              selectedItem={allocationData[count]}
              series={allocationData}
              legend={{
                position: 'right'
              }}
              responsive={true}
              centerTextOptions={{
                showCenterText: true,
                getCenterText: () => {
                  const maxValue = Math.max(...allocationData.map(la => la.value))
                  return ({
                    top: '105px',
                    text: `$${UtilService.nFormatter(maxValue, 1)}`,
                    font: {
                      size: '30px',
                      family: 'Segoe UI'
                    }
                  })
                }

              }}
              onSeriesClick={(seriesItem) => seriesItem}
            />
            <DetailsData data={allocationData[count]} />
          </div>
        </div>
      </Modal>
    </div>
  )
}
export default AllocationDetail