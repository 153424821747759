import { ReactElement } from 'react'
import ListItem from './components/ListItem'
import ListExpand from './components/ListExpand'

export interface IListItem {
  id: number | string;
  date: string | Date;
  title: string | ReactElement;
  href?: string;
  actionTitle: string;
  actionIconName: string;
  actionHref?: string;
  onActionClick?: (ListItem) => void;
}

interface Props {
  onExpand?: () => void;
  href?: string
  expandTitle: string;
  items: Array<IListItem>
  useBoxIcon?: boolean
}

export default function List(props: Props) {
  const { items, expandTitle, onExpand, href, useBoxIcon = false } = props
  return (
    <div className="c-list-container">
      <ul className="c-list">
        {items.map((item) => (
          <ListItem useBoxIcon={useBoxIcon} item={item} key={item.id} />
        ))}
      </ul>
      
      <ListExpand href={href} expandTitle={expandTitle} onExpand={onExpand} />
    </div>
  )
}
