import { useState } from 'react'
import { IntlProvider } from 'react-intl'
import { useDispatch } from 'react-redux'
import { Checkbox, ChoiceGroup, MessageBarType, PrimaryButton } from '@fluentui/react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { appDispatch } from '@/bootstrap/redux'
import { createUIMessage } from '@/features/uimessages/redux/operations'
import { Card, Section } from '@/shared/components'
import { useRedefineBoxFolderStructureMutation, useRefreshBoxFolderPermissionMutation } from '@/shared/api/services/boxServices'
import BoxFolderPermisionDropdown, { BoxFolderPermissionOption } from './components/BoxFolderPermisionDropdown/BoxFolderPermisionDropdown'
import { useClearStatsCacheMutation } from '@/shared/api/services/portfolioService'

export interface ClientAdminProps {
  accountId: string,
  accountName: string,
  headerLabel: string,
}

export default function ClientAdmin({ accountId, accountName, headerLabel }: ClientAdminProps) {
  const dispatch = useDispatch<appDispatch>()
  const [ selectedKey, setSelectedKey ] = useState('boxFolderPermissions')
  const [ selectedPermission, setSelectedPermission ] = useState(null as BoxFolderPermissionOption)
  const [ refreshBoxFolderPermission ] = useRefreshBoxFolderPermissionMutation()
  const [ redefineBoxFolderStructure ] = useRedefineBoxFolderStructureMutation()
  const isRunJobDisabled = (selectedKey === 'boxFolderPermissions') && !selectedPermission
  const [ isSettingsCacheSelected, setSettingsCacheSelected ] = useState(false)
  const [ isMarketValueCacheSelected, setMarketValueCacheSelected ] = useState(false)
  const [ isAllocationCacheSelected, setAllocationCacheSelected ] = useState(false)
  const [ isPerformanceCacheSelected, setPerformanceCacheSelected ] = useState(false)
  const [ isTradeCacheSelected, setTradeCacheSelected ] = useState(false)
  const [ isClearCacheDisabled, setClearCacheDisabled ] = useState(false)
  const [ clearStats ] = useClearStatsCacheMutation()

  const onRunJobClick = () => {
    if (selectedKey === 'boxFolderPermissions') {
      refreshBoxFolderPermission({
        accountId,
        permissionName: selectedPermission,
      }).unwrap().then(() => {
        dispatch(createUIMessage({ 
          key: 'refreshPermissionSuccess', 
          content: `Removed selected Box Folder permission cache ${accountName}. Box folder permissions will be applied on nightly Box Hangfire job.`,
          messageBarType: MessageBarType.success,
        }));
      }).catch(error => {
        dispatch(createUIMessage({ 
          key: 'refreshPermissionFailure', 
          content: `Failed to remove selected Box Folder permission cache ${accountName}. `,
          messageBarType: MessageBarType.error,
        }));
      })
    } else if (selectedKey === 'boxFolderStructureReset') {
      redefineBoxFolderStructure(accountId).unwrap().then(() => {
        dispatch(createUIMessage({ 
          key: 'boxFolderStructureResetSuccess', 
          content: `Removed Box Folders structure and permission cache for client ${accountName}. Box folder structure will be redefined on nightly Box Hangfire job.`,
          messageBarType: MessageBarType.success,
        }));
      }).catch(error => {
        dispatch(createUIMessage({ 
          key: 'boxFolderStructureResetFailure', 
          content: `Failed to removed Box Folders structure and permission cache for client ${accountName}.`,
          messageBarType: MessageBarType.error,
        }));
      })
    }
  }

  const onSettingsCacheSelect = (event: React.FormEvent<HTMLElement | HTMLInputElement>, checked: boolean) => {
    setSettingsCacheSelected(checked)
  }

  const onMarketValueCacheSelect = (event: React.FormEvent<HTMLElement | HTMLInputElement>, checked: boolean) => {
    setMarketValueCacheSelected(checked)
  }

  const onAllocationCacheSelect = (event: React.FormEvent<HTMLElement | HTMLInputElement>, checked: boolean) => { 
    setAllocationCacheSelected(checked)
  }

  const onPerformanceCacheSelect = (event: React.FormEvent<HTMLElement | HTMLInputElement>, checked: boolean) => {
    setPerformanceCacheSelected(checked)
  }

  const onTradeCacheSelect = (event: React.FormEvent<HTMLElement | HTMLInputElement>, checked: boolean) => {
    setTradeCacheSelected(checked)
  }

  const onClearCacheClick = () => {
    setClearCacheDisabled(true)
    clearStats({
      accountId,
      deleteSettingsData: isSettingsCacheSelected,
      deleteMarketValueData: isMarketValueCacheSelected,
      deleteAllocationData: isAllocationCacheSelected,
      deletePerformanceData: isPerformanceCacheSelected,
      deleteTransactionData: isTradeCacheSelected,
    }).unwrap().then(() => {
      dispatch(createUIMessage({ 
        key: 'clearStatsCacheSuccess', 
        content: `Cleared Portfolio Dashboard cache for ${accountName}.`,
        messageBarType: MessageBarType.success,
      }));
    }).catch(error => {
      dispatch(createUIMessage({ 
        key: 'clearStatsCacheFailure', 
        content: `Failed to clear Portfolio Dashboard cache for ${accountName}.`,
        messageBarType: MessageBarType.error,
      }));
    }).finally(() => {
      setClearCacheDisabled(false)
    })
  }

  return (
    <IntlProvider locale='en'>
      <Section className='client-admin'>
        <Card ariaLabel={headerLabel} heading={headerLabel} className='client-admin-card'>
          <div className='box-folder-jobs'>
            <h3 className='header'>Box Folder Jobs</h3>
            <ChoiceGroup
              className='c-create-event-modal-form__choicegroup'
              selectedKey={selectedKey}
              onChange={(ev, option) => setSelectedKey(option.key) }
              options={[
                {
                  key: 'boxFolderPermissions',
                  text: 'Refresh Box Folder Permissions',
                  onRenderField: (props, render) => {
                    return (
                      <>
                        { render!(props) }
                        <div className='box-folder-permission-dropdown'>
                          <BoxFolderPermisionDropdown
                            disabled={selectedKey !== 'boxFolderPermissions'}
                            selectedOption={selectedPermission}
                            onValueChange={(newPermission) => setSelectedPermission(newPermission)} />
                        </div>
                      </>
                    )
                  }
                },
                { key: 'boxFolderStructureReset', text: 'Redefine Box Folders structure' }
              ]}
            />
            <div className='action-buttons'>
              <div className='warning-box-folder-restructure' style={{ visibility: selectedKey !== 'boxFolderStructureReset' ? 'hidden' : 'visible' }}>
                <FontAwesomeIcon icon='triangle-exclamation' /> Be aware the whole Box folder structure for this account will be redefined.
              </div>
              <PrimaryButton disabled={isRunJobDisabled} className='run-job-button' onClick={onRunJobClick}>Run Job</PrimaryButton>
            </div>
          </div>
          <div className='portfolio-dashboard-jobs'>
            <h3 className='header'>Portfolio Dashboard Cache</h3>
            <div className='cache-selector'>
              <Checkbox checked={isSettingsCacheSelected} onChange={onSettingsCacheSelect} className='selector' />
              <span className='selector-label'>Clear cache for Vermilion settings</span>
            </div>
            <div className='cache-selector'>
              <Checkbox checked={isMarketValueCacheSelected} onChange={onMarketValueCacheSelect} className='selector' />
              <span className='selector-label'>Clear cache for Market Value widgets</span>
            </div>
            <div className='cache-selector'>
              <Checkbox checked={isAllocationCacheSelected} onChange={onAllocationCacheSelect} className='selector' />
              <span className='selector-label'>Clear cache for Allocation widgets</span>
            </div>
            <div className='cache-selector'>
              <Checkbox checked={isPerformanceCacheSelected} onChange={onPerformanceCacheSelect} className='selector' />
              <span className='selector-label'>Clear cache for Performance widgets</span>
            </div>
            <div className='cache-selector'>
              <Checkbox checked={isTradeCacheSelected} onChange={onTradeCacheSelect} className='selector' />
              <span className='selector-label'>Clear cache for Trades widgets</span>
            </div>
            <div className='action-buttons'>
              <PrimaryButton disabled={isClearCacheDisabled || (!isSettingsCacheSelected && !isMarketValueCacheSelected && !isAllocationCacheSelected && !isPerformanceCacheSelected && !isTradeCacheSelected)}
                className='clear-cache-button' onClick={onClearCacheClick}>Clear cache</PrimaryButton>
            </div>
          </div>        
        </Card>
      </Section>
    </IntlProvider>
  )
}
