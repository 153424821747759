import useIsMobile from '@/shared/hooks/useIsMobile'
import { useEffect } from 'react'

import './PerformanceSummaryChartLegend.scss'

export interface PerformanceSummaryChartLegendProps {
  multipleColumns?: boolean, 
  options: any
}

export default function PerformanceSummaryChartLegend({ 
  multipleColumns,
  options
}: PerformanceSummaryChartLegendProps) {
  const isMobile = useIsMobile('xs')
  useEffect(() => {
    options.forEach(opt => {
      const [legendColor] = document.getElementsByClassName(`legend-color-${opt.DisplayPLIType}`)
      legendColor['style'].width = '10px'
      legendColor['style'].height = '10px'
      legendColor['style'].backgroundColor = opt.Color
    })
  })

  return (
    <div className={`performance-legend ${multipleColumns ? 'resizeWideLegend' : ''}`}>
      <ul className={`legend-list ${multipleColumns ? 'resizeWideLegendItems' : ''}`}>
        {
          options.map((opt, id) => {
            return (
              <li className={`legend ${opt.DisplayPLIType}`} key={id}>
                <div className='legend-label'>
                  <div className={`legend-color-${opt.DisplayPLIType}`}></div>
                  <span className='legend-text'>
                    {opt.Name}
                  </span>
                </div>
              </li>
            )
          })
        }
      </ul>
    </div>
  )
}
