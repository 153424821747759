import React from 'react'
import CompassIcon from '../assets/CompassIcon'
import PeopleIcon from '../assets/PeopleIcon'
import TargetIcon from '../assets/TargetIcon'
import PersonClipboardIcon from '../assets/PersonClipboardIcon'
import { ExternalAppsLayoutKey } from './linkContent'

type ExternalAppsIconKeyMap = Record<ExternalAppsLayoutKey, React.FC>

const iconMap: ExternalAppsIconKeyMap = {
  marketData: CompassIcon,
  clientData: PeopleIcon,
  benchmark: TargetIcon,
  managerData: PersonClipboardIcon,
}

export default iconMap