import { useState } from 'react'
import { Callout, DirectionalHint, Icon, VerticalDivider } from '@fluentui/react'
import DateItem from './DateItem'

import './TosHitoryCell.scss'

export interface TosHistoryCellProps {
  dates: Array<string>
}

export default function TosHistoryCell({ dates }: TosHistoryCellProps) {
  const [isCalloutVisible, setIsCalloutVisible] = useState(false);
  const [targetElement, setTargetElement] = useState(null);
  const isDisplayed = dates && dates.length

  return (
    isDisplayed &&
    <div className='tos-history-cell'>
      <Icon
        iconName="Info"
        className='history-column'
        onMouseEnter={(e) => {
          setTargetElement(e.currentTarget);
          setIsCalloutVisible(true);
        }}
        onMouseLeave={() => setIsCalloutVisible(false)}
      />

      {/* Callout for hover effect */}
      {isCalloutVisible && isDisplayed && (
        <Callout
          target={targetElement}
          onDismiss={() => setIsCalloutVisible(false)}
          directionalHint={DirectionalHint.rightTopEdge}
          gapSpace={10}
          setInitialFocus
        >
          <div className='tos-history-cell-callout' style={{ padding: '10px' }}>
            <p style={{ textAlign: 'center' }}>ACCEPTANCE HISTORY</p>
            <ul>
              {dates.map((item, index) => (
                <>
                  <VerticalDivider></VerticalDivider>
                  <li key={index}><DateItem key={index} item={item}></DateItem></li></>
              ))}
            </ul>
          </div>
        </Callout>
      )}
    </div>
  );
}
