import _ from 'lodash'
import { ICellRendererParams } from 'ag-grid-community'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { Button } from 'react-bootstrap'
import { IActionColumn } from '../Columns'
import UtilService from '../../../services/util/UtilService'

export interface ICellActions<TRow> extends ICellRendererParams {
  actions: IActionColumn<TRow>[]
}

export function ActionCellRenderer<TRow>(props: ICellActions<TRow>) {
  const { actions, colDef } = props
  const componentId = UtilService.getId(`${colDef.colId}_action_cell_renderer`)
  return (
    <div id={componentId}>
      {_.map(actions, (action) => {
        const { hidden } = action
        const isHidden =
          typeof hidden === 'function' ? hidden(props.data) : !!hidden
        return (
          <div key={action.label} className='p-1 d-inline'>
            <Button
              variant={action.buttonColor as any}
              onClick={() => action.onClick(props.data)}
              hidden={isHidden}
              title={action.tooltip || action.label}
              disabled={action.disabled && action.disabled(props.data)}
              id={UtilService.getId(
                `${
                  action.label || colDef.headerName || colDef.field
                }_${componentId}_button`
              )}
            >
              {action.label}
              {!action.icon ? null : <FontAwesomeIcon icon={action.icon} />}
            </Button>
          </div>
        )
      })}
    </div>
  )
}
