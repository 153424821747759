import { IconButton, Modal, Stack } from '@fluentui/react'
import { nanoid } from '@reduxjs/toolkit'
import cn from 'classnames'

export interface CambridgeModalProps {
  modalIsOpen: boolean,
  close: () => void,
  modalTitle: string
  closeAriaLabel: string
  children: JSX.Element | JSX.Element[],
  className?: string,
  goBackAriaLabel?: string
  goBack?: () =>  void
}

const CambridgeModal = ({ modalIsOpen, close, modalTitle, closeAriaLabel, children, className, goBackAriaLabel, goBack }: CambridgeModalProps) => {
  const id = nanoid(6)

  return (
    <Modal 
      className={cn('c-cambridge-modal', className)}
      isOpen={modalIsOpen}
      isBlocking={true}
      onDismiss={close}
      titleAriaId={id} 
      isClickableOutsideFocusTrap={true}
      forceFocusInsideTrap={false}
    >
      <div className="c-cambridge-modal__header">
        <p className="c-cambridge-modal__header__title" id={id}>{modalTitle}</p>
        <Stack horizontal tokens={{ childrenGap: 20 }}>
          {
            goBack && (
              <IconButton 
                iconProps={{ iconName: 'Back' }}
                ariaLabel={goBackAriaLabel}
                onClick={goBack}
              />
            )
          }
          <IconButton 
            iconProps={{ iconName: 'Cancel' }}
            ariaLabel={closeAriaLabel}
            onClick={close}
          />
        </Stack>
      </div>
      <div className="c-cambridge-modal__content">
        {children}
      </div>
    </Modal>
  )
}

export default CambridgeModal