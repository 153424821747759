import { InviteStateEnum } from '@/shared/types/swagger'
import { QueryStatus } from '@reduxjs/toolkit/dist/query/react'

export const CONFRIM_TITLE_MAP: Record<InviteStateEnum, Record<Partial<QueryStatus>, string>> = {
  [InviteStateEnum.InviteNotSent]: {
    [QueryStatus.fulfilled]: 'Invite Sent',
    [QueryStatus.rejected]: 'Error Inviting Contact',
    [QueryStatus.uninitialized]: 'Invite Contact',
    [QueryStatus.pending]: 'Invite Contact'
  },
  [InviteStateEnum.InviteSentAndAccepted]: {
    [QueryStatus.fulfilled]: 'Reinvite Sent',
    [QueryStatus.rejected]: 'Error Reinviting Contact',
    [QueryStatus.uninitialized]: 'Reinvite Contact',
    [QueryStatus.pending]: 'Reinvite Contact'
  },
  [InviteStateEnum.InviteSentNotAccepted]: {
    [QueryStatus.fulfilled]: 'Reinvite Sent',
    [QueryStatus.rejected]: 'Error Reinviting Contact',
    [QueryStatus.uninitialized]: 'Reinvite Contact',
    [QueryStatus.pending]: 'Reinvite Contact'
  }
}

const CONFRIM_DESC_MAP: Record<InviteStateEnum, Record<Partial<QueryStatus>, string>> = {
  [InviteStateEnum.InviteNotSent]: {
    [QueryStatus.fulfilled]: 'You\'ve successfully invited <b>{name}</b> via email to Access CA',
    [QueryStatus.rejected]: 'There was an error inviting <b>{name}</b> to Access CA. Please try again',
    [QueryStatus.uninitialized]: 'Press confirm to invite <b>{name}</b> to Access CA',
    [QueryStatus.pending]: 'Press confirm to invite <b>{name}</b> to Access CA'
  },
  [InviteStateEnum.InviteSentAndAccepted]: {
    [QueryStatus.fulfilled]: 'You\'ve successfully reinvited <b>{name}</b> via email to Access CA',
    [QueryStatus.rejected]: 'Error Reinviting Contact',
    [QueryStatus.uninitialized]: 'Press confirm to reinvite <b>{name}</b> to Access CA',
    [QueryStatus.pending]: 'Press confirm to reinvite <b>{name}</b> to Access CA'
  },
  [InviteStateEnum.InviteSentNotAccepted]: {
    [QueryStatus.fulfilled]: 'You\'ve successfully reinvited <b>{name}</b> via email to Access CA',
    [QueryStatus.rejected]: 'There was an error reinviting <b>{name}</b> to Access CA. Please try again',
    [QueryStatus.uninitialized]: 'Press confirm to reinvite <b>{name}</b> to Access CA',
    [QueryStatus.pending]: 'Press confirm to reinvite <b>{name}</b> to Access CA'
  }
}

export const getConfirmDescription = (inviteState: InviteStateEnum, status: QueryStatus, name: string): string => {
  if (inviteState === undefined || inviteState === null || !status) return ''
  return CONFRIM_DESC_MAP[inviteState][status].replace(/{name}/g, name ?? 'this contact')
}