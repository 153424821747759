import { Persona, Stack } from '@fluentui/react'
import dayjs from 'dayjs'
import './Notification.scss'
import { Link } from 'react-router-dom'

type MessageProps = {
  author: string,
  time: string,
  title: string,
  description: string
  cta: string
}

const Message = ({ author, time, title, description, cta }: MessageProps) => {
  return (
    <li className='c-nav-notification--listitem'>
      <Stack className='c-nav-notification' horizontal as='article'>
        <Stack.Item className='c-nav-notification__left' role='presentation'>
          <Persona onRenderPrimaryText={null} text={author} />
        </Stack.Item>
        <Stack.Item className='c-nav-notification__right' >
          <span className='c-nav-notification__right__meta'><address className='author'>{author}</address>{' added a notification '}<time dateTime={time}>{dayjs().to(dayjs(time))}</time></span>
          <Stack horizontalAlign='space-between' horizontal className='c-nav-notification__right__header' as='header'>
            <h2 className='c-nav-notification__right__header__title'>{title}</h2>
          </Stack>
          <section className='c-nav-notification__right__content'>
            <Link to={`/documents/${cta}`} className="c-messages-table__action-cell">View</Link>
            <p className='c-nav-notification__right__content__description'>{description}</p>
          </section>
        </Stack.Item>
      </Stack>
    </li>
  )
}

export default Message