import { createAsyncThunk } from '@reduxjs/toolkit'
import { UIMessage } from '../shared/types'
import { POP_UIMESSAGE, PUSH_UIMESSAGE } from '.'
import { appDispatch } from '@/bootstrap/redux'

export const createUIMessage = createAsyncThunk<UIMessage, UIMessage, { dispatch: appDispatch }>(
  'uimessages/MESSAGE', 
  async (message: UIMessage, { dispatch }) => {
    try {
      dispatch(PUSH_UIMESSAGE(message))
      return new Promise((resolve, reject) => setTimeout(() => {
        resolve(message)
      }, message.autoDismissAfter || 8000))
    } catch (error) {
      return error
    }
  }
)

export const deleteUIMessage = createAsyncThunk<UIMessage, UIMessage, { dispatch: appDispatch }>(
  'uimessages/MESSAGE', 
  async (message: UIMessage, { dispatch }) => {
    try {
      dispatch(POP_UIMESSAGE(message))
    } catch (error) {
      return error
    }
  }
)
