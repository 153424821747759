import { ELinks, ILinkContentWithLink } from '@/shared/types/Links'
import { Link } from '@fluentui/react'
import { Link as RRLink } from 'react-router-dom'
import moreLinksDisplayMap from '../constants/moreLinksDisplay'

const childPages: Array<ELinks> = [ELinks.MPME]

export default function MorePageLink({ title, description, link, id }: ILinkContentWithLink) {
  const descId = link.replace(/\s/g, '-')
  return (
    <div className='c-more-page-detail' aria-details={descId}>
      {childPages.includes(id) ? (
        <Link className='c-more-page-detail__link' as={RRLink} to={`/more/${moreLinksDisplayMap[id]}`}>{title}</Link>
      ) : ( 
        <a className='c-more-page-detail__link' href={link} target="_blank" rel="noreferrer">
          {title}
        </a>
      )}
      <span id={descId} className='c-more-page-detail__description'>{description}</span>
    </div>
  )
}