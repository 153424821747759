import { MORE_LINKS } from '@/environment/environment.constants';
import { InstType } from '@/shared/types/Claims'
import { ELinks, ILinkContent, ISurveyConfig } from '@/shared/types/Links'

type ExternalAppsLayout = {
  marketData: Array<ELinks>;
  clientData: Array<ELinks>;
  benchmark: Array<ELinks>;
  managerData: Array<ELinks>;
}
export type ExternalAppsLayoutKey = keyof ExternalAppsLayout
export const externalAppGroupTitleMap: Record<keyof ExternalAppsLayout, string> = {
  marketData: 'Market Data',
  clientData: 'Client Data',
  benchmark: 'Benchmark Data',
  managerData: 'Manager Data',
}

export const linkContent: Partial<Record<ELinks, ILinkContent>> = {
  [ELinks.PRSnapshot]: {
    'title': 'Pool Returns Snapshot', 
    'description': 'An overview of aggregate performance statistics for peer institutions.',
  },
  [ELinks.AnnualManagerReport]: { 
    'title': 'Annual Manager Report', 
    'description': 'Summary of the research and monitoring activity that CA performs for each product in a client’s portfolio.',
  },
  [ELinks.IndexSnapshots]: { 
    'title': 'Index Snapshots', 
    'description': 'At-a-glance performance data for commonly referenced market indices.',
  },
  [ELinks.ManagerNotices]: { 
    'title': 'Manager Notices', 
    'description': 'Notifications of issues identified with a product in a client portfolio that CA’s ongoing due diligence and monitoring has highlighted for client awareness.',
  },
  [ELinks.ExhibitFinder]: { 
    'title': 'Exhibit Finder', 
    'description': 'Search for over 400 one-page charts or analysis about the financial capital markets for many asset classes and regions.',
  },
  [ELinks.MMOS]: { 
    'title': 'Marketable Manager One-Stop (MMOS)', 
    'description': 'Find and screen on general information about marketing (head fund and long-only) managers, including firms and products, and where available view performance and portfolio characteristics.',
  },
  [ELinks.PreliminaryPerformanceBulletin]: { 
    'title': 'Preliminary Performance Bulletin', 
    'description': 'Allows for previewing performance voluntarily submitted by clients for the current quarter before the Quarterly Investment Pool Survey cycle is completed.',
  },
  [ELinks.PIMOS]: { 
    'title': 'Private Investments Manager One-Stop (PIOS)', 
    'description': 'Find and screen on general information for private investment managers, including firms and funds, and view funds currently fundraising.',
  },
  [ELinks.ProprietaryPIB]: { 
    'title': 'Proprietary Private Investment Benchmarks', 
    'description': 'Learn more about CA’s Private Investment Benchmark reports, including access to our publicly available reports.',
  },
  [ELinks.AnnualInvestmentPRSurvey]: { 
    'title': 'Annual Investment Pool Returns Survey', 
    'description': 'Collects key performance, asset, portfolio, manager, spending, enterprise, and investment office data for comparative peer benchmarking.',
  },
  [ELinks.HistoricalPIB]: { 
    'title': 'Historical Private Investment Benchmarks', 
    'description': 'CA’s historical Private Investment Benchmark reports.',
  },
  [ELinks.InvestmentPRQuarterlySurvey]: { 
    'title': 'Investment Pool Returns Quarterly Survey', 
    'description': 'Collects total performance, asset allocation, and quarterly market values from clients that do not subscribe to CA’s Performance Reporting for use in peer research.',
  },
  [ELinks.ClientSnapshot]: { 
    'title': 'Client Snapshot', 
    'description': 'Summary of client asset allocation and performance over the previous 10 years.',
  },
  [ELinks.MPME]: { 
    'title': 'CA mPME', 
    'description': 'CA’s modified Public Market Equivalent (mPME) methodology.',
  },
}

export const linkLayout: ExternalAppsLayout = {
  marketData: [ELinks.ExhibitFinder, ELinks.IndexSnapshots],
  clientData: [
    ELinks.AnnualInvestmentPRSurvey,
    ELinks.InvestmentPRQuarterlySurvey,
    ELinks.ClientSnapshot,
    ELinks.PRSnapshot,
    ELinks.PreliminaryPerformanceBulletin,
    ELinks.MPME,
  ],
  benchmark: [ELinks.HistoricalPIB, ELinks.ProprietaryPIB,],
  managerData: [
    ELinks.AnnualManagerReport,
    ELinks.ManagerNotices,
    ELinks.MMOS,
    ELinks.PIMOS,
  ]
}

export const InstTypeSurveyKeyMap: Record<InstType, keyof ISurveyConfig> = {
  C: 'cuas',
  M: 'ceas',
  S: 'isas',
  E: 'eas',
  F: 'fas',
  ME: 'meas',
}

export const moreLinks: Record<keyof ELinks, string> = MORE_LINKS
