import { CurrencyColumn, DateColumn } from '@/lib/common/components/grid/Columns'
import DataGrid, { DataGridInitEvent } from '@/lib/common/components/grid/Grid'
import { getFormattedMaxAsOfDate } from '@/lib/common/services/date/DateService'
import RecentTradesMobile from '@/screens/ClientPortfolio/ClientPortfolioHome/RecentTrades/RecentTradesMobile/RecentTradeMobile'
import { ITrade, getGridExcelStyles, useGetAccountRecentTransactions } from '@/shared/api/services/portfolioService'
import { GeneralError } from '@/shared/components'
import useIsMobile from '@/shared/hooks/useIsMobile'
import SkeletonClientRecentTrades from '../SkeletonClientRecentTrades'
import { TradeInvestmentColumn } from './cellRenderer/TradeInvestmentColumn'
import { TradeTypeColumn } from './cellRenderer/TradeTypeColumn'
import { GridApi } from 'ag-grid-community'

export interface RecentTradeProps {
  accountId: string,
  dateFormat: string,
  fullDateFormat: string,
  sendGridApi: (gridApi : GridApi) => void,
}

const RecentTrades = ({
    accountId,
    dateFormat,
    fullDateFormat,
    sendGridApi
}: RecentTradeProps) => {
    const isMobile = useIsMobile('xs')
    const isLargeMobile = useIsMobile('sm')
    const asOfDate = getFormattedMaxAsOfDate()
    const listLimit = 3
    const { data, isLoading, isError, refetch } = useGetAccountRecentTransactions({ accountId, asOfDate, listLimit })

    if (isError) {
      return (
        <GeneralError title='Failed to load Client Recent Trades' onClick={refetch} />
      )
    }

    const getCurrencySymbol = (data: ITrade) => {
      return data?.currency?.symbol
    }
    
    const onGridInit = (event: DataGridInitEvent) => {
      const { gridApi } = event
      sendGridApi(gridApi)
    }
    
    return (
      <>
        {
          isLoading ? <SkeletonClientRecentTrades /> :
          (
            isMobile || isLargeMobile ? <RecentTradesMobile fullDateFormat={fullDateFormat} rows={data}/> :
            <DataGrid
              showToolBar={false}
              rows={data}
              enablePagination={false}
              domLayout='normal'
              onInit={onGridInit}
              excelStyles={getGridExcelStyles()}
            >
              <TradeInvestmentColumn title='INVESTMENT' disableFilterColumn={true} field='name' checkboxSelection={false} />
              <DateColumn dateFormat={dateFormat} title='DATE' disableFilterColumn={true} field='date' width={100} />
              <TradeTypeColumn headerClass='text-left' title='TYPE' disableFilterColumn={true} field='type' width={90} />
              <CurrencyColumn decimalScale={2} negativeNumberClassName='negative-number' title='AMOUNT' disableFilterColumn={true} field='amnt' width={120} getCurrencySymbol={getCurrencySymbol} />
            </DataGrid>
          )
        }
      </>
    )
}

export default RecentTrades