
import NumberFormat from 'react-number-format'
import { formatDate } from '@/lib/common/services/date/DateService'
import { ITrade } from '@/shared/api/services/portfolioService'

import './RecentTradeMobile.scss'

export interface RecentTradeMobileProps {
  rows: ITrade[],
  fullDateFormat: string,
}

export default function RecentTradesMobile({ 
  rows,
  fullDateFormat,
}: RecentTradeMobileProps) {

  if(!rows?.length) {
    return (
      <div className='empty-data'>
        <span><strong>No Rows To Show</strong></span>
      </div>
    )
  }

  return (
    <div className='recent-trade-mobile'>
      {
        rows?.map((data, key) => {
          return (
            <div className='recent-trade-mobile-row' key={key}>
              <div className='recent-trade-mobile-text'>{data?.name}</div>
              <div className='recent-trade-mobile-data'>
                <div className='recent-trade-mobile-asOfDate'>{formatDate(new Date(data?.date), fullDateFormat)}</div>
                <div className='DataTypeCell'><span className={`TypeCell ${(data?.type.replace(/\t|\s|\n/g, '') || '')}`}>{data?.type}</span></div>
                <div className='currency-data'>
                  <span className={`${data?.amnt < 0 ? 'negative-number' : ''}`}>
                    <NumberFormat
                      value={data?.amnt}
                      displayType='text'
                      thousandSeparator={true}
                      decimalSeparator='.'
                      decimalScale={data?.amnt && data?.amnt.toString().split('.')[1]?.length === 3 ? 3 : 2}
                      fixedDecimalScale={true}
                      renderText={(value) => {
                          const isNegative = `${value}`.trim().startsWith('-')
                          const currencySymbol = '$'
                          const formattedValue =  isNegative ? currencySymbol + '(' + (value + '').replace('-', '') + ')' : currencySymbol + value
                          return formattedValue
                      }}
                    />
                  </span>
                </div>
              </div>
            </div>
          )
        })
      }
    </div>
  )
}
