import NotificationTable from '@/features/notifications/components/NotificationTable'
import { MainLayout } from '@/shared/components'
import PageHeader from '@/shared/components/PageHeader'

const Notifications = () => {
  return (
    <MainLayout>
      <PageHeader label='Notifications' />
      <div className='c-notifications'>
        <NotificationTable isReadTable={false} />
        <NotificationTable isReadTable />
      </div>
    </MainLayout>
  )
}

export default Notifications