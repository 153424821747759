import PodcastSideBar from '@/features/events/components/PodcastSideBar'
import InsightList from '@/features/insight/components/InsightList'
import { Card, MainLayout, SplitLayout } from '@/shared/components'
import ManageInsightModal from '@/features/insight/components/ManageInsightModal'
import FeaturedInsight from '@/features/insight/components/FeaturedInsight'
import Breadcrumbs from '@/features/insight/components/Breadcrumbs'
import EventSideSection from '@/features/dashboard/components/EventSideSection'
import KeyResources from '@/features/insight/components/KeyResources'
import { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import useQueryParamSubscription from '@/shared/hooks/useQueryParamSubscription'
import { IInsightTag } from '@/features/insight/shared/types'

const Insights = () => {
  const [searchQuery, setSearchQuery] = useState(new URLSearchParams(window.location.search).get('s') || '')
  const handleSearch = (query: string) => void setSearchQuery(query)

  const [filterTag, setFilterTag] = useState(new URLSearchParams(window.location.search).get('tag') || '[]')
  const handleTag = (query: string) => void setFilterTag(query)
 
  const isTagExists = (tagId) : boolean => {
    const currentTags = JSON.parse(filterTag)
    const isFound: boolean = currentTags.some(t => t.id === tagId)
    return isFound;
  }

  const updateFeaturedInsightTags = (tag: IInsightTag) => {
    const currentTags = JSON.parse(filterTag)
    if (!isTagExists(tag.id)) {
      const manageTags = [...currentTags, tag]
      setFilterTag(JSON.stringify(manageTags))
    }
  }

  const deleteTagFromQuery = (tagId) => {
    const currentTags = JSON.parse(filterTag)
    if (isTagExists(tagId)) {
      const manageTags = currentTags.filter(e => e.id !== tagId)
      setFilterTag(JSON.stringify(manageTags))
    }
  }

  const history = useHistory()
  useEffect(() => {
    const params = new URLSearchParams()
    if (searchQuery !== '') {
      params.append('s', searchQuery)
    } else {
      params.delete('s')
    }

    if (filterTag && JSON.parse(filterTag).length) {
      params.append('tag', filterTag)
    } else {
      params.delete('tag')
    }
    history.push({ search: params.toString() })
  }, [searchQuery, history, filterTag])

  const leftContent = (
    <>
      {searchQuery || (filterTag && JSON.parse(filterTag).length) ?
        <Breadcrumbs handleSearch={handleSearch} handleTag={handleTag}/>
        :
        <>
          <div className='c-insights__manage'>
            <ManageInsightModal isInsightList />
          </div>
          <FeaturedInsight setTags={updateFeaturedInsightTags} />
        </>
      }
      <div className='c-insights__insights'>
        <Card  ariaLabelledBy='Wellcome'>
          <InsightList isDashboard={false} search={searchQuery} setTags={updateFeaturedInsightTags} tags={filterTag} deleteTag={deleteTagFromQuery}/>
        </Card>
      </div>
    </>
  )

  const rightContent = (
    <>
      <KeyResources />
      <EventSideSection cardless />
      <PodcastSideBar />
    </>
  )

  return (
    <MainLayout className='c-insights'>
      <SplitLayout
        headerTitle='Insights'
        hasSearch={true}
        handleSearch={handleSearch}
        leftContent={leftContent}
        rightContent={rightContent}
      />
    </MainLayout>
  )
}

export default Insights