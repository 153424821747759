import { CSSProperties } from 'react'
import { Bar } from 'react-chartjs-2'
import { BarElement, CategoryScale, Chart, Legend, LinearScale, Title, Tooltip } from 'chart.js'
import { SimpleBarChartBarValuePlugin, SimpleBarChartBarValuePluginOptions } from './plugins/SimpleBarChartBarValuePlugin'
import { SimpleBarChartBarInnerLabelPlugin } from './plugins/SimpleBarChartBarInnerLabelPlugin'
import useIsMobile from '@/shared/hooks/useIsMobile'

import './SimpleBarChart.scss'


// register required settings for Bar chart.js
Chart.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  SimpleBarChartBarValuePlugin,
  SimpleBarChartBarInnerLabelPlugin,
)


export interface SimpleBarChartSeries {
  name: string,
  data: {
    value: number,
    currencySymbol?: string,
    innerLabel?: {
      text: string,
      font?: {
        size: string,
        family: string,
        color?: string
      } 
    },
  }[],
  colors: string[],
  selectedValue?: number,
  selectedIndex?: number,
}

export interface SimpleBarChartProps {
  title?: string,
  periods: any,
  series: SimpleBarChartSeries,
  responsive?: boolean,
  legend?: {
    position?: 'left' | 'top' | 'right' | 'bottom' | 'center',
    horizontalAlign?: 'start' | 'center' | 'end',
  },
  fontOptions?: SimpleBarChartBarValuePluginOptions,
  height?: number,
  width?: number,
  xAxis?: {
    labelPadding?: number
  }, 
  onSeriesHover?: (seriesItem: SimpleBarChartSeries) => void,
  onSeriesClick?: (seriesItem: SimpleBarChartSeries) => void,
}


export const SimpleBarChart = (props: SimpleBarChartProps) => {
  const data = {
    labels: props.periods,
    datasets: [{
      label: props.series.name,
      data: props.series.data.map(d => d.value),
      backgroundColor: props.series.colors
    }]
  }
  const isMobile = useIsMobile('xs')
  const getAdditionalPlugins = () => {
    const additionalPlugins = {} as any
    const currencySymbols = props.series.data.reduce((acc, d, index) => {
      if (d.currencySymbol) {
        acc.push({
          [index]: d.currencySymbol
        })
      }
      return acc
    }, [])
    
    const innerLabels = props.series.data.reduce((acc, d, index) => {
      if (d.innerLabel) {
        acc.push({
          [index]: d.innerLabel
        })
      }
      return acc
    }, [])
    
    additionalPlugins.SimpleBarChartBarValuePlugin = {
      ...props.fontOptions,
      currencySymbols: currencySymbols.reduce((acc, value) => {
        Object.keys(value).forEach(k => {
          acc[k] = value[k]
        })
        return acc
      }, {})
    }

    if (innerLabels.length) {
      additionalPlugins.SimpleBarChartBarInnerLabelPlugin = {
        innerLabels: innerLabels.reduce((acc, value) => {
          Object.keys(value).forEach(k => {
            acc[k] = value[k]
          })
          return acc
        }, {})
      }
    }
    return additionalPlugins
  }

  const getStyle = () => {
    const style = {} as CSSProperties
    if (props.height) {
      style.height = `${props.height}px`
    }
    if (props.width) {
      style.width = `${props.width}px`
    }
    return style
  }

  return (
    <div className='SimpleBarChartContainer' style={getStyle()}>
      <Bar data={data} options={ {
        font: {
          size: props?.fontOptions?.font?.size
        },
        plugins: {
          title: {
            display: false,
            text: props.title,
          },
          tooltip: {
            enabled: false,
          },
          legend: {
            display: !!props.legend,
            position: props?.legend?.position,
            align: props?.legend?.horizontalAlign,
            labels: {
              font: {
                  size: props?.fontOptions?.font?.size
              }
            }
          },
          ...getAdditionalPlugins(),
        }, 
        responsive: props.responsive,
        maintainAspectRatio: false,
        interaction: {
          mode: 'index' as const,
          intersect: false,
        },
        layout: {
          padding: {
            left: 0,
            top: isMobile ? 15 : 20,
            bottom: 25,
            right: 0,
          }
        },
        scales: {
          x: {
            border: {
              display: false
            },
            grid: {
              drawTicks: false,
              drawOnChartArea: false,
            },
            ticks: {
              font: {
                size: props?.fontOptions?.font?.size
              },
              padding: props?.xAxis?.labelPadding || 30
            }
          },
          y: {
            border: {
              display: false
            },
            grid: {
              drawTicks: false,
              color: (ctx, options) => {
                const { tick } = ctx
                if (tick.value === 0) {
                  return 'rgba(0, 0, 0, 0.1)'
                } 
                return 'rgb(255, 255, 255)'
              },
            },
            ticks: {
              display: false,
              crossAlign:'near',
              backdropColor: (ctx, options) => {
                return 'rgb(0, 0, 0)'
              },
            }
          }
        },
        onHover(event, elements, chart) {
          if (elements.length) {
            if (props.onSeriesHover) {
              props.onSeriesHover({
                ...props.series,
                selectedValue: props.series.data[elements[0].index].value,
                selectedIndex: elements[0].index,
              })
            }
          } else {
            if (props.onSeriesHover) {
              props.onSeriesHover(undefined)
            }
          }
        },
        onClick(event, elements, chart) {
          if (elements.length) {
            if (props.onSeriesClick) {
              props.onSeriesClick({
                ...props.series,
                selectedValue: props.series.data[elements[0].index].value,
                selectedIndex: elements[0].index,
              })
            }
          } else {
            if (props.onSeriesClick) {
              props.onSeriesClick(undefined)
            }
          }
        },
      }} />
    </div>
  )
}