export enum ELinks {
  /** Pool Return Snapshot */
  PRSnapshot = 1,
  AnnualManagerReport,
  IndexSnapshots,
  ManagerNotices,
  ExhibitFinder,
  /** Marketable Manager One-Stop */
  MMOS,
  PreliminaryPerformanceBulletin,
  /** Private Investments Manager One-Stop */
  PIMOS,
  /** Proprietary Private Investment Benchmarks */
  ProprietaryPIB,
  AnnualInvestmentPRSurvey,
  /** Historical Private Investment Benchmarks */
  HistoricalPIB,
  /** Investment Pool Returns Quarterly Survey */
  InvestmentPRQuarterlySurvey,
  ClientSnapshot,
  MPME,
  OpticaResearchPrivate,
  OpticaResearchPublic,
  OpticaBenchmark,
  OpticaPeers
}

export interface ILinkContent {
  title: string;
  description: string;
}
export interface ILinkContentWithLink extends ILinkContent {
  link: string;
  id: ELinks;
}

export interface ISurveyConfig {
  /** College and University Annual Survey */
  cuas: boolean;
  /** Cultural and Environmental Annual Survey */
  ceas: boolean;
  /** Miscellaneous Endowment Annual Survey */
  eas: boolean;
  /** Foundation Annual Survey */
  fas: boolean;
  /** Investment Pool Returns Quarterly Survey - [] */
  iprqs: boolean;
  /** Independent Schools Annual Survey */
  isas: boolean;
  /** Medical Endowment Annual Survey */
  meas: boolean;
}

export interface ISurveyConfigNew {
  configValue: boolean;
  id: string;
  labelName: string;
  surveyReportName: string;
}