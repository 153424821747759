import { Editor as EditorType } from 'tinymce'
import getFormattedTime, { getDayjsDateFromParts } from '@/features/events/helpers/getFormattedTime'
import { getImageSrcForBucket } from '@/features/events/helpers/getImageSrc'
import { EEventType } from '@/features/events/types/event'
import { useAddEventMutation, useUpdateEventMutation } from '@/shared/api/services/eventServices'
import { ErrorMessage, FormError } from '@/shared/components'
import Editor, { EditorRefHandle } from '@/shared/components/Editor'
import { IEvent } from '@/shared/types/swagger'
import { ChoiceGroup, DatePicker, Dropdown, IStackItemStyles, Stack, TextField, DefaultButton, Label, IconButton, MessageBarType } from '@fluentui/react'
import { Dispatch, SetStateAction, useCallback, useEffect, useRef, useState } from 'react'
import { useForm, Controller } from 'react-hook-form'
import CreateEventModalButtons from './components/CreateEventModalButtons'
import EventImageSelection from './components/EventImageSelection'
import './CreateEventModalForm.scss'
import { zones } from '@/features/events/constants/timezones'
import dayjs from '@/bootstrap/dayjs'
import { timePattern } from '@/features/events/constants/validation'
import validateURL from '@/shared/helpers/validateURL'
import Fieldset from '@/shared/components/Fieldset'
import { appDispatch } from '@/bootstrap/redux'
import { useDispatch } from 'react-redux'
import { createUIMessage } from '@/features/uimessages/redux/operations'
import UIMessages from '@/features/uimessages/components/UIMessages'

const enum eMeridiem {
  AM = 'am',
  PM = 'pm'
}

const stackItemStyles: IStackItemStyles = {
  root: {
    height: 62,
    '.ms-DatePicker': {
      width: 198
    },
    '.ms-Dropdown-container': {
      width: 104
    },
    '.ms-TextField': {
      width: 206
    }
  }
}

type FormData = {
  eventName: string,
  eventType: string,
  eventLocation: string,
  startDate: Date
  startTime: string,
  startMeridiem: string,
  endDate: Date
  endTime: string,
  endMeridiem: string,
  eventTZ: string;
  imageName?: string,
  pageUrl: string,
  body: string,
  imageAlt?: string,
  imageFile?: File
}

type CreateEventModalFormProps = {
  selectedEvent: IEvent;
  goBack: () => void;
  step: number;
  setStep: Dispatch<SetStateAction<number>>;
}

const UPLOAD_ERROR_MESSAGE = 'Image file upload has exceeded the max limit of 5MB'

const CreateEventModalForm = (props: CreateEventModalFormProps) => {
  const dispatch = useDispatch<appDispatch>()
  const { selectedEvent, goBack, step, setStep } = props

  // const hiddenFileBrowserRef = useRef<HTMLInputElement>(null)
  const [addEvent, { reset: resetAdd }] = useAddEventMutation({ fixedCacheKey: 'add-event-post' })
  const [updateEvent, { reset: resetUpdate }] = useUpdateEventMutation({ fixedCacheKey: 'update-event-post' })
  const [showAlert, setShowAlert] = useState(false)
  const editorRef = useRef<EditorRefHandle>(null)
  const { control, handleSubmit, setValue, getValues, formState: { errors }, watch } = useForm<FormData>({
    defaultValues: {
      eventName: '',
      eventType: '',
      eventLocation: '',
      startDate: null,
      startTime: '',
      startMeridiem: '',
      endDate: null,
      endTime: '',
      endMeridiem: '',
      eventTZ: dayjs.tz.guess(),
      imageName: null,
      imageAlt: null,
      body: '',
      pageUrl: '',
      imageFile: null
    }
  })
  const [bodyInitialValue, setBodyInitialValue] = useState('')

  useEffect(() => {
    if (selectedEvent) {
      const { title, eventType, location, description, eventUrl, startEventDate, endEventDate, timezoneName } = selectedEvent

      const startDateUTC = dayjs.utc(startEventDate).tz(timezoneName)
      const startDateIsValid = startDateUTC.isValid()
      const endDateUTC = dayjs.utc(endEventDate).tz(timezoneName)
      const endDateIsValid = endDateUTC.isValid()

      setValue('eventName', title)
      setValue('eventType', EEventType[eventType])
      setValue('eventLocation', location)
      setValue('pageUrl', eventUrl)
      setValue('imageName', selectedEvent.imageUrl)
      setValue('imageAlt', selectedEvent.imageAlt)
      setValue('body', description)
      setBodyInitialValue(description)

      setValue('startDate', startDateIsValid ? startDateUTC.toDate() : new Date())
      setValue('startTime', startDateIsValid ? startDateUTC.format('hh:mm') : '')
      // eslint-disable-next-line no-nested-ternary
      setValue('startMeridiem', startDateIsValid ? startDateUTC.get('hours') < 12 ? eMeridiem.AM : eMeridiem.PM : null)

      setValue('endDate', endDateIsValid ? endDateUTC.toDate() : new Date())
      setValue('endTime', endDateIsValid ? endDateUTC.format('hh:mm') : '')
      // eslint-disable-next-line no-nested-ternary
      setValue('endMeridiem', endDateIsValid ? endDateUTC.get('hours') < 12 ? eMeridiem.AM : eMeridiem.PM : null)
      setValue('eventTZ', timezoneName)
    }
  }, [selectedEvent, setValue])

  const imageName = watch('imageName')
  const imageAlt = watch('imageAlt')
  const startDate = watch('startDate')
  const endDate = watch('endDate')
  const startTime = watch('startTime')
  const startMeridiem = watch('startMeridiem')
  const eventTZ = watch('eventTZ')
  const endTime = watch('endTime')
  const endMeridiem = watch('endMeridiem')
  const start = getDayjsDateFromParts(startDate, startTime, startMeridiem, eventTZ)
  const end = getDayjsDateFromParts(endDate, endTime, endMeridiem, eventTZ)
  const startIsBeforeEnd = !start || !end ? true : start.isBefore(end)
  const imageFile = watch('imageFile')

  const handleFormSubmit = (data: FormData) => {
    if (!startIsBeforeEnd) return
    if (showAlert) setShowAlert(false)
    const { startTime, startDate, startMeridiem, endMeridiem, endDate, endTime, eventName, eventLocation, eventType, pageUrl, body, eventTZ } = data
    // update and add generally have the same body structure
    const bodyData: Partial<IEvent> = {
      ...selectedEvent,
      startEventDate: getFormattedTime(startDate, startTime, startMeridiem, eventTZ),
      endEventDate: getFormattedTime(endDate, endTime, endMeridiem, eventTZ),
      title: eventName,
      location: eventLocation,
      eventType: EEventType[eventType],
      status: '',
      cost: 0,
      eventUrl: pageUrl,
      description: body,
      imageName: data.imageName,
      imageAlt: data.imageAlt,
      timezoneName: eventTZ,
      imageFile: data.imageFile
    }

    if ('isFeatured' in bodyData) {
      bodyData['featured'] = bodyData['isFeatured'];
      delete bodyData['isFeatured'];
    }
    if ('imageName' in bodyData) {
      bodyData['imageUrl'] = bodyData['imageName'];
      delete bodyData['imageName'];
    }

    const formData = new FormData();
    Object.keys(bodyData).forEach((val, property) => {
      if (val === 'imageFile' && bodyData[val] == null) return;
      formData.append(val, bodyData[val]);
    })

    if (selectedEvent) {
      let hasUploadError = false;
      updateEvent(formData)
        .unwrap()
        .catch((error) => {
          const { exceptionType } = error || {} as any
          if (exceptionType === 'MAX_UPLOAD_SIZE_EXCEPTION') {
            hasUploadError = true
          } else {
            setShowAlert(true) 
          }
        })
        .finally(() => {
          resetUpdate()
          if (hasUploadError) {
            dispatch(createUIMessage({ 
              key: 'uploadSizeError', 
              content: UPLOAD_ERROR_MESSAGE,
              messageBarType: MessageBarType.error,
              source: 'modal',
              autoDismissAfter: 3000
            }));
          } else {
            goBack()
          }
        })
    } else {
      let hasUploadError = false;
      addEvent(formData)
        .unwrap()
        .catch((error) => {
          const { exceptionType } = error || {} as any
          if (exceptionType === 'MAX_UPLOAD_SIZE_EXCEPTION') {
            hasUploadError = true
          } else {
            setShowAlert(true) 
          }
        })
        .finally(() => {
          resetAdd()
          if (hasUploadError) {
            dispatch(createUIMessage({ 
              key: 'uploadSizeError', 
              content: UPLOAD_ERROR_MESSAGE,
              messageBarType: MessageBarType.error,
              source: 'modal',
              autoDismissAfter: 3000
            }));
          } else {
            goBack()
          }
        })
    }
  }

  // const handleUploadImage = (e: React.MouseEvent<HTMLButtonElement>) => {
  //   (e.currentTarget.nextElementSibling as HTMLInputElement).click()
  // }

  const handleBodyChange = useCallback((html: string, editor: EditorType) => {
    const htmlValue = (editorRef && editorRef.current && editorRef.current.getHTML())  || (editor && editor.getContent())
    setValue('body', htmlValue) 
  }, [setValue])

  function handleImagePickerTransition() {
    setStep(2)
  }

  return (
    <>
      <ErrorMessage
        showAlert={showAlert}
        setShowAlert={setShowAlert}
        message={selectedEvent?.id ? 'Failed to edit event' : 'Failed to create event'}
      />
      <UIMessages source='modal' />
      <form className='c-create-event-modal-form' noValidate onSubmit={handleSubmit(handleFormSubmit)}>
        <Stack>
          <Stack.Item hidden={step !== 1}>
            <Stack tokens={{ childrenGap: 16, maxWidth: 540, padding: '24px 0px 32px' }}>
              <Fieldset label='Event Name' fieldName='eventName' errors={errors} isRequired>
                <Controller
                  control={control}
                  name='eventName'
                  rules={{
                    required: 'Event name is required!',
                  }}
                  render={({ field }) => (
                    <TextField
                      id='eventName'
                      aria-required={true}
                      invalid={errors?.eventName?.message !== undefined}
                      {...field}
                    />
                  )}
                />
              </Fieldset>
              <Fieldset label='Event Type' fieldName='eventType' errors={errors} isRequired>
                <Controller
                  control={control}
                  name="eventType"
                  rules={{
                    required: 'Event Type is required!'
                  }}
                  render={({ field }) => (
                    <ChoiceGroup
                      className='c-create-event-modal-form__choicegroup'
                      id='eventType'
                      required
                      {...field}
                      selectedKey={field.value}
                      onChange={(ev, option) => { field.onChange(option.key) }}
                      options={[
                        { key: EEventType[0], text: 'In Person' },
                        { key: EEventType[1], text: 'Virtual' }
                      ]}
                    />
                  )}
                />
              </Fieldset>
              <Fieldset label='Event Location' fieldName='eventLocation' errors={errors} isRequired>
                <Controller
                  name='eventLocation'
                  control={control}
                  rules={{
                    required: 'Event Location is required!'
                  }}
                  render={({ field }) => (
                    <TextField invalid={errors?.eventLocation?.type !== undefined} id='eventLocation' aria-required={true} {...field} />
                  )}
                />
              </Fieldset>
              <Fieldset label='Event Timezone' fieldName='eventTZ' errors={errors} isRequired>
                <Controller
                  name='eventTZ'
                  control={control}
                  rules={{
                    required: 'Event Timezone is required!'
                  }}
                  render={({ field }) => (
                    <Dropdown
                      className={errors?.eventTZ ? 'c-create-event-modal-form__dropdown--error' : ''}
                      errorMessage={errors?.eventTZ?.type ? 'error' : ''}
                      id='eventTZ'
                      selectedKey={field.value || dayjs.tz.guess()}
                      defaultSelectedKey={dayjs.tz.guess()}
                      onChange={(ev, option) => field.onChange(option.key)}
                      options={zones}
                    />
                  )}
                />
              </Fieldset>

              <Stack tokens={{ childrenGap: 16 }} horizontal verticalAlign='center'>
                <Stack.Item styles={stackItemStyles}>
                  <Fieldset label='Event Start Date' errors={errors} isRequired fieldName='startDate'>
                    <Controller
                      name="startDate"
                      defaultValue={null}
                      control={control}
                      rules={{
                        required: 'Event start date is required!',
                        validate: () => startIsBeforeEnd
                      }}
                      render={({ field }) => (
                        <DatePicker
                          aria-required={true}
                          id='startDate'
                          {...field}
                          onSelectDate={(date) => field.onChange(date)}
                          maxDate={endDate}
                        />
                      )}
                    />
                  </Fieldset>
                </Stack.Item>
                <Stack.Item styles={stackItemStyles}>
                  <Fieldset label='Event Start Time' errors={errors} isRequired fieldName='startTime'>
                    <Controller
                      name="startTime"
                      control={control}
                      rules={{
                        required: 'Event start time is required!',
                        pattern: {
                          value: timePattern,
                          message: 'Invalid format. HH:MM'
                        },
                        validate: () => startIsBeforeEnd
                      }}
                      render={({ field }) => (
                        <TextField
                          id='startTime'
                          aria-required={true}
                          invalid={errors?.startTime?.message !== undefined || !startIsBeforeEnd}
                          {...field}
                          onChange={(ev, value) => field.onChange(value)}
                        />
                      )}
                    />
                  </Fieldset>
                </Stack.Item>
                <Stack.Item styles={stackItemStyles}>
                  <Fieldset label="Meridiem" errors={errors} isRequired fieldName='startMeridiem'>
                    <Controller
                      name="startMeridiem"
                      control={control}
                      rules={{
                        required: 'Required!',
                        validate: () => startIsBeforeEnd
                      }}
                      render={({ field }) => (
                        <Dropdown
                          className={errors?.endMeridiem?.type ? 'c-create-event-modal-form__dropdown--error' : ''}
                          errorMessage={errors?.endMeridiem?.type || !startIsBeforeEnd ? ' ' : ''}
                          id='startMeridiem'
                          selectedKey={field.value || null}
                          onChange={(ev, option) => field.onChange(option.key)}
                          options={[
                            { key: eMeridiem.AM, text: 'AM' },
                            { key: eMeridiem.PM, text: 'PM' }
                          ]}
                        />
                      )}
                    />
                  </Fieldset>
                </Stack.Item>
              </Stack>
              <Stack tokens={{ childrenGap: 16 }} horizontal verticalAlign='center'>
                <Stack.Item styles={stackItemStyles}>
                  <Fieldset label="Event End Date" errors={errors} isRequired fieldName='endDate'>
                    <Controller
                      name="endDate"
                      control={control}
                      rules={{
                        required: 'Event end date is required!',
                        validate: () => startIsBeforeEnd
                      }}
                      render={({ field }) => (
                        <DatePicker
                          id='endDate'
                          aria-required={true}
                          {...field}
                          onSelectDate={(date) => field.onChange(date)}
                          minDate={startDate}
                          initialPickerDate={startDate ?? new Date()}
                        />
                      )}
                    />
                  </Fieldset>
                </Stack.Item>
                <Stack.Item styles={stackItemStyles}>
                  <Fieldset label="Event End Time" errors={errors} isRequired fieldName='endTime'>
                    <Controller
                      control={control}
                      name="endTime"
                      rules={{
                        required: 'Event end time is required!',
                        pattern: {
                          value: timePattern,
                          message: 'Invalid format. HH:MM'
                        },
                        validate: () => startIsBeforeEnd
                      }}
                      render={({ field }) => (
                        <TextField
                          id='endTime'
                          aria-required={true}
                          invalid={errors?.endTime?.message !== undefined || !startIsBeforeEnd}
                          {...field}
                          onChange={(ev, value) => field.onChange(value)}
                        />
                      )}
                    />
                  </Fieldset>
                </Stack.Item>
                <Stack.Item styles={stackItemStyles}>
                  <Fieldset label="Meridiem" errors={errors} isRequired fieldName='endMeridiem'>
                    <Controller
                      name="endMeridiem"
                      control={control}
                      rules={{
                        required: 'End Meridiem is required',
                        validate: () => startIsBeforeEnd
                      }}
                      render={({ field }) => (
                        <Dropdown
                          className={errors?.endMeridiem?.type ? 'c-create-event-modal-form__dropdown--error' : ''}
                          errorMessage={errors?.endMeridiem?.type || !startIsBeforeEnd ? ' ' : ''}
                          id='endMeridiem'
                          onChange={(ev, option) => field.onChange(option.key)}
                          aria-required={true}
                          selectedKey={field.value || null}
                          options={[
                            { key: eMeridiem.AM, text: 'AM' },
                            { key: eMeridiem.PM, text: 'PM' }
                          ]}
                        />
                      )}
                    />
                  </Fieldset>
                </Stack.Item>
              </Stack>
              {!startIsBeforeEnd ? <div style={{ marginTop: 0 }}><FormError errors={{ dates: { type: 'validate', message: 'Event End Time must occur after the Event Start Time' } }} name='dates' /></div> : null}
              <Fieldset label="Content" errors={errors} isRequired fieldName='body'>
                <Controller
                  control={control}
                  name='body'
                  rules={{
                    required: 'Event Description is required!'
                  }}
                  defaultValue=""
                  render={({ field }) => (
                    <Editor
                      id="body"
                      onChange={handleBodyChange}
                      initialValue={bodyInitialValue}
                      ref={editorRef}
                    />
                  )}
                />
              </Fieldset>
              <div>
                <Label>Choose an Image</Label>
                <Stack tokens={{ childrenGap: 16, maxWidth: 540 }}>
                  <Stack.Item>
                    <Stack horizontal>
                      <div className='c-create-event-modal-form__file'>
                        <DefaultButton onClick={handleImagePickerTransition} aria-label='Upload Image'>Browse</DefaultButton>
                        {/* <input 
                          className='c-create-event-modal-form__file--hidden' 
                          ref={hiddenFileBrowserRef}
                          aria-hidden
                          tabIndex={-1}
                          type="file"
                          aria-label='Upload Image'
                          {...register('image', { onChange: handleImageInputChange })}
                        /> */}
                        {imageName ? (
                          <div className='c-create-event-modal-form__file__text'>
                            {/* <b className='c-create-event-modal-form__file__name'>{image?.name}</b>{' '} */}
                            {/* <span className='c-create-event-modal-form__file__size'>
                              {image?.size ? `(${filesizeToReadable(image?.size)})` : null}
                            </span> */}
                            <figure className='c-create-event-modal-form__file__img'>
                              <img src={getImageSrcForBucket(imageName, 'events/')} alt={imageAlt} />
                              {/* <figcaption>{imageAlt}</figcaption> */}
                            </figure>
                            <IconButton
                              className='c-create-event-modal-form__file__cancel'
                              iconProps={{ iconName: 'Cancel' }}
                              onClick={() => { setValue('imageName', null); setValue('imageAlt', null) }}
                            />
                          </div>
                        ) : null}
                        {imageFile ? (
                          <div className='c-create-event-modal-form__file__text'>
                            <figure className='c-create-event-modal-form__file__img'>
                              <img src={URL.createObjectURL(imageFile)} alt={imageAlt} />
                            </figure>
                            <IconButton
                              className='c-create-event-modal-form__file__cancel'
                              iconProps={{ iconName: 'Cancel' }}
                              onClick={() => { setValue('imageFile', null); setValue('imageAlt', null) }}
                            />
                          </div>
                        ) : null}
                      </div>
                    </Stack>
                  </Stack.Item>
                  {/* {image ? (
                    <Stack.Item>
                      <Fieldset label="Image Alt" errors={errors} isRequired fieldName='imageAlt'>
                        <Controller 
                          control={control}
                          name='imageAlt'
                          rules={{
                            required: image ? 'Image alt is required!' : false
                          }}
                          render={({ field }) => (
                            <TextField
                              id='imageAlt'
                              aria-required={true}
                              invalid={errors?.imageAlt?.message !== undefined}
                              {...field}
                            />
                          )}
                        />
                      </Fieldset>
                    </Stack.Item>
                  ) : null} */}
                </Stack>
              </div>
              <Fieldset label="Event Page URL" errors={errors} isRequired fieldName='pageUrl'>
                <Controller
                  name='pageUrl'
                  control={control}
                  rules={{
                    required: 'Event url is required!',
                    validate: validateURL
                  }}
                  render={({ field }) => (
                    <TextField
                      invalid={errors?.pageUrl?.message !== undefined}
                      id='pageUrl'
                      aria-required={true}
                      {...field}
                    />
                  )}
                />
              </Fieldset>
            </Stack>
          </Stack.Item>
          <Stack.Item hidden={step !== 2}>
            <EventImageSelection
              handleSelection={(selected, isCustomImgSelected) => {
                if (isCustomImgSelected) {
                  setValue('imageFile', selected)
                  setValue('imageName', null)
                } else {
                  setValue('imageName', selected.name)
                  setValue('imageFile', null)
                }
                setStep(1)
              }}
              selection={getValues('imageName')}
            />
          </Stack.Item>
        </Stack>
        {step === 1 ? <CreateEventModalButtons goBack={goBack} isEditing={!!selectedEvent} /> : null}
      </form>
    </>
  )
}

export default CreateEventModalForm