// DEPRECIATED
export interface Message {
  id: number;
  versionid: number;
  createddatetime?: string;
  editeddatetime?: string;
  archiveddatetime?: string;
  sender?: string;
  recipients?: Array<string>;
  subject?: string;
  action: string;
  actionid: number;
  notes?: string;
}

export interface IMessage {
  id: string
  versionId: number
  createdAt: string,
  updatedAt: string;
  sender: string;
  senderId: string;
  recipients: Array<MessageRecipient>
  subject: string,
  actionId: EActionId,
  notes: string,
  documentLink: string,
  isArchived: boolean
}

export interface IArchivedMessage extends Omit<IMessage, 'subject' | 'actionId' | 'notes' | 'isArchived' | 'versionId' | 'documentLink'> {
  versionedContents: Array<MessageVersionedContent>
  subject?: IMessage['subject']
  notes?: IMessage['notes'],
  isArchived?: IMessage['isArchived'],
  versionId?: IMessage['versionId'],
  documentLink?: IMessage['documentLink']
}

export type MessageVersionedContent = {
  subject: string,
  actionId: EActionId
  notes: string,
  isArchived: boolean,
  versionId: number
  documentLink: string
  createdAt: string,
  updatedAt: string;
}

type MessageRecipient = {
  recipientName: string
  recipientId: string,
  recipientType: ERecipientType
}

export enum EActionId {
  'Please Review' = 1,
  'Complete Task' = 2,
  'Comment And Reply' = 3,
  'Signature Required' = 4
}

export enum ERecipientType {
  To = 1,
  CC = 2,
  BCC = 3
}

export type Messages = Record<string, Message>

export interface MessagesState {
  messages: Messages
}