import { Pagination } from '@/shared/components'

type EventModalPaginationProps = {
  onPageChange: (newPage: number) => void
  count: number
}

const EventModalPagination = ({ onPageChange, count }: EventModalPaginationProps) => {
  return (
    <Pagination 
      className='c-event-modal-pagination'
      maxShown={12}
      listCount={count}
      ignoreQueryParams
      onPageChange={onPageChange}
    />
  )
}

export default EventModalPagination