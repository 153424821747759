import React from 'react'
import { ICellRendererParams } from 'ag-grid-community'
import { Link } from 'react-router-dom'
import { Button } from 'react-bootstrap'
import _ from 'lodash'
import styles from './LinkCellFramework.module.scss'
import UtilService from '../../../services/util/UtilService'

export const LinkCellRenderer = (props: ICellRendererParams) => {
  const {
    onClick,
    onGenerateLink,
    tooltip,
    disabled,
  } = props.colDef.cellRendererParams
  const { data, value, colDef } = props
  const componentId = UtilService.getId(`${colDef.colId}_link_cell_renderer`)
  const hideComponent = _.isNil(value) || value === ''

  if (disabled && disabled(data)) {
    return value
  }

  if (onGenerateLink) {
    return (
      <Link
        to={onGenerateLink(data)}
        title={tooltip || value}
        className={`${hideComponent ? 'd-none' : ''}`}
        id={componentId}
      >
        {value}
      </Link>
    )
  } else if (onClick) {
    return (
      <Button
        className={`${styles.button_padding} ${hideComponent ? 'd-none' : ''}`}
        variant='link'
        title={tooltip || value}
        style={{ textTransform: 'none' }}
        onClick={() => onClick(data)}
        id={componentId}
      >
        {value}
      </Button>
    )
  } else {
    throw new Error(
      'LinkCellRenderer has no action handler (onClick | onGenerateLink)'
    )
  }
}
