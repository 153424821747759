import { useState } from 'react'
import { GridApi } from 'ag-grid-community'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { DefaultButton, Stack } from '@fluentui/react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useGetBoxRecentItemsQuery, useGetBoxTokenQuery } from '@/shared/api/services/boxServices'
import PageHeader from '@/shared/components/PageHeader'
import { Card,  MainLayout,  Section, SplitLayout, TeamCard } from '@/shared/components'
import useIsMobile from '@/shared/hooks/useIsMobile'
import { useGetPreviewDashboardSettingsByAccountId, useGetDashboardSettings } from '@/shared/api/services/legacyService'
import { exportGridData,  } from '@/shared/api/services/portfolioService'
import useAccountName from '@/features/auth/hooks/useAccountName'
import DigestMedia from '@/features/dashboard/components/DigestMedia'
import EventSideSection from '@/features/dashboard/components/EventSideSection'
import RecentDocuments from '@/features/dashboard/components/RecentDocuments'
import InsightList from '@/features/insight/components/InsightList'
import { IInsightTag } from '@/features/insight/shared/types'
import useAccessRulesQuery from '@/features/auth/hooks/useAccessRulesQuery'
import { selectAccountId, selectIsCAEmployee } from '@/features/auth/redux/authSelectors'
import { DEFAULT_DATE_FORMAT, DEFAULT_FULL_DATE_FORMAT, getFormattedMaxAsOfDate } from '@/lib/common/services/date/DateService'
import RecentTrades from './RecentTrades/grid/RecentTrades'
import useQueryParamSubscription from '@/shared/hooks/useQueryParamSubscription'
import PortfolioCard from './PortfolioCard/PortfolioCard'

export interface DashboardProps {
  previewAccountId?: string,
}

const Dashboard = (props: DashboardProps) => {
  const reduxAccountId = useSelector(selectAccountId)
  const accountId = props.previewAccountId ? props.previewAccountId : reduxAccountId
  const { data: boxTokenResponse } = useGetBoxTokenQuery(accountId)
  const navAccessState = useAccessRulesQuery()
  const skipRecentFetch = !boxTokenResponse?.accessToken || !navAccessState?.data?.documents
  const recentItemsState = useGetBoxRecentItemsQuery(accountId, { skip: skipRecentFetch })
  const isCAEmployee = useSelector(selectIsCAEmployee)
  const { data: baseDashboardSettings } = useGetDashboardSettings()
  const { data: previewDashboardSettings } = useGetPreviewDashboardSettingsByAccountId(props.previewAccountId)
  const dashboardSettings = props.previewAccountId ? previewDashboardSettings : baseDashboardSettings
  const isMobile = useIsMobile('xs')
  const [asOfDate, setAsOfDate] = useQueryParamSubscription('asOfDate')
  const asOfDateInput = asOfDate || getFormattedMaxAsOfDate()
  const { accountName } = useAccountName()
  const history = useHistory()
  const[tradeApi,setTradeApi]= useState(null as GridApi)

  const updateFeaturedInsightTags = (tag: IInsightTag) => {
    const selectedTag = tag && JSON.stringify([tag])
    history.push(`/insights?tag=${selectedTag}`)
  }

  const handleExcelDownloadTradeClick = () => {
    exportGridData({
      gridApi: tradeApi,
      excelTabName:'Recent Trades',
      fileName: `client-recent-trades-export-client-${accountName}-as-of-date-${asOfDate}`,
    })
  }

  const leftContent = (
    <div className='Dashboard'>
      <>
        { (dashboardSettings && dashboardSettings.enableClientDashboardPortfolioList) ? (
          <PortfolioCard accountId={accountId} asOfDate={asOfDateInput} isDashboardPreview={!!(props && props.previewAccountId)}
            onAsOfDateChange={setAsOfDate}  />
        ) : null }
        <div className="d-flex">
          { !isCAEmployee && (dashboardSettings && dashboardSettings.enableClientDashboardRecentDocuments) && recentItemsState.data?.length  ? (
            <Card className='dashboard-card c-onboarding-welcome__left__card card-left card' ariaLabel="Welcome">
              <RecentDocuments></RecentDocuments>
            </Card>
          ) : null }
          {
            (dashboardSettings && dashboardSettings.enableClientDashboardRecentDocuments && dashboardSettings.enableClientDashboardRecentTrades)
              && recentItemsState.data?.length ? (<div className='separator'></div>) : null
          }
          { (dashboardSettings && dashboardSettings.enableClientDashboardRecentTrades) ? (
            <Card className='dashboard-card  c-onboarding-welcome__left__card card-right card' ariaLabel="Welcome">
              <Section className='c-dashboard-section' title="Recent Trades" actions={!isMobile &&[         
                <div  key='downloadBtn' className='download-button-container'>
                  <DefaultButton className='download-button'  onClick={handleExcelDownloadTradeClick}>
                    <FontAwesomeIcon icon='download' className="c-sidebar-section__heading-icon" />
                  </DefaultButton>
                </div>,]}>
                <RecentTrades fullDateFormat={DEFAULT_FULL_DATE_FORMAT} dateFormat={DEFAULT_DATE_FORMAT} accountId={accountId} sendGridApi={ setTradeApi }/>
              </Section>
            </Card>
          ) : null }
        </div>
      </>
      { (isCAEmployee || (dashboardSettings && dashboardSettings.enableClientDashboardInsightList)) ? (
        <Card className='dashboard-card  c-onboarding-welcome__left__card card-insight' ariaLabel="Welcome">
          <InsightList isDashboard setTags={updateFeaturedInsightTags}/>
        </Card>
      ) : null }
    </div>
  )

  const rightContent = (
    <Stack tokens={{ childrenGap: 24 }}>
      <div className='c-dashboard-items'>
        { (isCAEmployee || (dashboardSettings && dashboardSettings.enableClientDashboardFeaturedMedia)) ? (
          <Stack.Item className='c-first-child-element'>
            <DigestMedia />
          </Stack.Item>
        ) : null }
        {
          (dashboardSettings && dashboardSettings.enableClientDashboardFeaturedMedia && dashboardSettings.enableClientDashboardMyCambridgeAssociatesTeam) ? (
            <Stack.Item>
              <div className='separator'></div>
            </Stack.Item>
          ) : null
        }
        { (dashboardSettings && dashboardSettings.enableClientDashboardMyCambridgeAssociatesTeam) ? (
          <Stack.Item>
            <TeamCard accountId={accountId} label="My Cambridge Associates Team" />
          </Stack.Item>
        ) : null }
      </div>
      
      { (isCAEmployee || (dashboardSettings && dashboardSettings.enableClientDashboardEventList)) ? (
        <Stack.Item>
          <Card className='dashboard-card' ariaLabel="Welcome">
            <EventSideSection />
          </Card>
        </Stack.Item>
      ) : null }
    </Stack>
  )

  return (
    <MainLayout>
      <PageHeader className="dashboard" label={accountName ? `${accountName}'s Dashboard` : 'Dashboard'} />
      <div className='c-dashboard__message-table'>
      </div>
      
      <Section>
        <SplitLayout
          renderHeader={false}
          leftContent={leftContent}
          rightContent={rightContent}
        />
      </Section>
    </MainLayout>
  )
}

export default Dashboard