import { memo, useEffect, useState } from 'react'
import { Icon } from '@fluentui/react'
import { useLazyGetMessagesByRecipientIdQuery } from '@/shared/api/services/messageService'
import { useSelector } from 'react-redux'
import { selectClaims } from '@/features/auth/redux/authSelectors'

function NotificationIcon() {
  const [ data, setData ] = useState([])
  const userClaims = useSelector(selectClaims)
  const contactId = userClaims ? userClaims.contactId : undefined
  const [ getMessages ] = useLazyGetMessagesByRecipientIdQuery()
  
  useEffect(() => {
    if (contactId) {
      getMessages(contactId).unwrap().then(messages => {
        setData(messages)
      })
    }
  }, [contactId]);

  return (
    <span className="c-notification">
      <Icon iconName="Ringer" className="ms-Icon" />
      {data?.length > 0 ? <span className="c-notification-badge">{data.length}</span> : null}
    </span>
  )
}

export default memo(NotificationIcon)
