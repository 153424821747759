import { useGetKeyResourcesQuery } from '@/shared/api/services/insightService'
import { Card, SideBarSection, SideBarSectionItem, SkeletonSideBarSectionItem } from '@/shared/components'
import { EDateFormat } from '@/shared/types/date'
import './KeyResources.scss'

export default function KeyResources() {
  const { data, isLoading, isUninitialized, isSuccess } = useGetKeyResourcesQuery()
  const showSkeleton = isLoading || isUninitialized || !isSuccess

  return (
    <Card size="large" ariaLabelledBy="key-resources" className='key-resources-card'>
      <SideBarSection headingIcon='thumbtack' headingText='Pinned Resources'>
        {showSkeleton ? (
          <>
            <SkeletonSideBarSectionItem />
            <SkeletonSideBarSectionItem />
          </>
        ) : (
          <>
            {data.map((keyResource) => (
              <SideBarSectionItem
                key={keyResource.id}
                datetime={new Date(keyResource.updatedAt)}
                dateFormat={EDateFormat.short}
                title={keyResource.title}
                target="_blank"
                href={keyResource.link}
                renderLHS={() => <img role='presentation' src={keyResource.featuredImageLink} alt={keyResource.featuredImageAlt} />}
              />
            ))}
          </>
        )}

      </SideBarSection>
    </Card>
  )
}