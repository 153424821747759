import isEmpty from 'lodash/isEmpty'
import { useContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import TableContext, { ITableContext } from '../context'
import { ColumnSettings, ITableColumn } from '../types'

export default function useColumnClick(initialSettings: Record<string, ColumnSettings> = {}) {
  const history = useHistory()
  const { setValue, ...tableContext }: ITableContext = useContext(TableContext)
  const [settings, setSettings] = useState<Record<string, ColumnSettings>>(initialSettings)

  useEffect(() => {
    if (!tableContext.sortField.value) {
      setSettings(initialSettings)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableContext.sortField.value])
  
  const handleColumnClick = (ev: React.MouseEvent<HTMLElement>, column: ITableColumn) => {
    let newSettings = {}
    // eslint-disable-next-line no-debugger
    let resetSort = false
    if (settings[column.fieldName]) {
      if (settings[column.fieldName].isSortedDescending === false) {
        // The user has clicked this column header three times. Remove all sorts
        resetSort = true
        newSettings = {
          [column.fieldName]: {
            fieldName: settings[column.fieldName].fieldName,
            isSorted: false
          }
        }
      } else {
        // This setting already exists so we'll just flip the sort variable 
        newSettings = {
          [column.fieldName]: {
            ...settings[column.fieldName],
            isSorted: true,
            isSortedDescending: !settings[column.fieldName].isSortedDescending
          }
        }
      }
    } else {
      // The user hasn't pressed this column before so we'll switch the columnKey
      newSettings = {
        [column.fieldName]: {
          fieldName: column.fieldName,
          sortKey: column.sortKey,
          isSorted: true,
          isSortedDescending: true
        }
      }
    }
    
    setSettings(newSettings)
    if (isEmpty(newSettings) || resetSort) {
      // No settings defined. Remove query parameters
      setValue({ 
        ...tableContext, 
        sortField: { key: tableContext.sortField.key, value: '' },
        sortOrder: { key: tableContext.sortOrder.key, value: '' } 
      })
      history.replace({
        ...history.location,
        search: (() => {
          const _queryParams = new URLSearchParams(history.location.search)
          _queryParams.delete(tableContext.sortField.key)
          _queryParams.delete(tableContext.sortOrder.key)
  
          return `?${_queryParams.toString()}`
        })()
      })
    } else {
      const fieldKV: [string, string] = [tableContext.sortField.key, column.fieldName]
      const orderKV: [string, string] = [tableContext.sortOrder.key, newSettings[column.fieldName].isSortedDescending ? 'desc' : 'asc']
      setValue({ 
        ...tableContext, 
        sortField: { key: fieldKV[0], value: fieldKV[1] },
        sortOrder: { key: orderKV[0], value: orderKV[1] } 
      })
      history.replace({
        ...history.location,
        search: (() => {
          const _queryParams = new URLSearchParams(history.location.search)
          _queryParams.set(...fieldKV)
          _queryParams.set(...orderKV)
  
          return `?${_queryParams.toString()}`
        })()
      })
    }
  }

  return { handleColumnClick, settings }
}