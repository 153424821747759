import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import { LocalizationProvider } from '@/shared/components/ADKLocalization'
import store, { persistor } from '@/bootstrap/redux'
import localizationConfig from '@/bootstrap/i18n'
import { Router, Switch } from 'react-router-dom'
import { routes } from '@/bootstrap/router'
import { ThemeProvider } from '@fluentui/react'
import theme from '@/shared/styles/theme'
import UIMessages from './features/uimessages/components/UIMessages'
import { MsalAuthenticationTemplate } from '@azure/msal-react'
import { InteractionType } from '@azure/msal-browser'
import { SkeletonTheme } from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import 'box-ui-elements/dist/explorer.css'
import { CookieFetch } from './shared/components'
import './App.scss'
import { createBrowserHistory } from 'history'
import useAuthentication from './features/auth/hooks/useAuthentication'
import ProtectedRoute from './features/auth/components/ProtectedRoute'
import MSALErrorRedirect from './features/auth/components/MSALErrorRedirect/MSALErrorRedirect'
import matchRouteState from './shared/helpers/matchRouteState'
import RouteAutoScroll from './utils/components/router/RouteAutoScroll'
import { IClaims } from './shared/types/Claims'
import DatadogSessionProvider from './utils/components/datadog/DatadogSessionProvider'

export const history = createBrowserHistory()
const routeState = matchRouteState()

function App() {
  // skips MSAL redirect if /publishedresearch path
  const isPR = window.location.pathname.startsWith('/publishedresearch')
  useAuthentication({ skipRedirect: isPR })

  return (
    <MsalAuthenticationTemplate interactionType={InteractionType.Redirect} authenticationRequest={{ state: routeState, scopes: [] }} errorComponent={MSALErrorRedirect}>
      <LocalizationProvider localizationConfig={localizationConfig} loadingComponent={<></>}>
        <Provider store={store}>
          <PersistGate persistor={persistor}>
            <ThemeProvider className='theme-provider' theme={theme}>
              <SkeletonTheme baseColor='#D2D0CE' highlightColor='#EEEEEE'>
                <CookieFetch isPR={isPR} />
                <Router history={history}>
                  <DatadogSessionProvider />
                  <RouteAutoScroll />
                  <UIMessages source='main' />
                  {isPR ? null : (
                    <Switch>
                      {Object.entries(routes).map(([key, route]) => (
                        <ProtectedRoute 
                          key={key}
                          path={route.path}
                          exact={route.exact}
                          component={route.screen}
                          authority={route.authority}
                        />
                      ))}
                    </Switch>
                  )}
                </Router>
              </SkeletonTheme>
            </ThemeProvider>
          </PersistGate>
        </Provider>
      </LocalizationProvider>
    </MsalAuthenticationTemplate>
  )
}

export default App
