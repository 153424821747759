import { DatePicker, Dropdown, IconButton, IDatePickerProps, Stack, TextField } from '@fluentui/react'
import Fieldset from '@/shared/components/Fieldset'
import { Controller, useForm } from 'react-hook-form'
import { timePattern } from '@/features/events/constants/validation'
import { useCallback, useEffect } from 'react'
import dayjs from '@/bootstrap/dayjs'
import getFormattedTime, { DTFormattingSharedArgs, getDayjsDateFromParts } from '@/features/events/helpers/getFormattedTime'
import './MaintenanceBannerDateTimeFields.scss'
import dtValueIsNull from '../helpers/dtValueIsNull'

type MaintenanceBannerDateTimeFieldsProps = {
  type: 'start' | 'end';
  otherDatetime: string;
  value: string;
  defaultValue: string;
  onChange: (val: string) => void;
  /** used to clarify labels. 
   * @example title='Event' */
  title?: string;
}

type DateTimeFields = {
  date: Date;
  time: string;
  meridiem: 'am' | 'pm';
}

const meridiemOptions: Array<{key: DateTimeFields['meridiem'], text: string}> = [
  { key: 'am', text: 'AM' },
  { key: 'pm', text: 'PM' }
]
const getDatePickerMinMaxProp = (type: MaintenanceBannerDateTimeFieldsProps['type'], otherDatetime: string): Partial<IDatePickerProps> => ({
  [type === 'end' ? 'minDate' : 'maxDate']: 
    otherDatetime && !dtValueIsNull(otherDatetime) 
      ? dayjs(otherDatetime).toDate() 
      : undefined
})

export default function MaintenanceBannerDateTimeFields(props: MaintenanceBannerDateTimeFieldsProps) {
  const { type, otherDatetime, onChange, defaultValue, title = '' } = props
  const { watch, control, setValue, formState: { errors } } = useForm<DateTimeFields>({ mode: 'onBlur' })
  const capType = `${type[0].toUpperCase()}${type.slice(1)}`

  const date = watch('date')
  const time = watch('time')
  const meridiem = watch('meridiem')
  
  const datetime = getDayjsDateFromParts(date, time, meridiem)
  const otherDTIsValid = dayjs(otherDatetime).isValid()

  useEffect(() => {
    if (defaultValue && !dtValueIsNull(defaultValue)) {
      const dateUTC = dayjs.utc(defaultValue).tz(dayjs.tz.guess())
      const dateIsValid = dateUTC.isValid()
      
      setValue('date',     dateIsValid ? dateUTC.toDate() : new Date())
      setValue('time',     dateIsValid ? dateUTC.format('hh:mm') : '')
      // eslint-disable-next-line no-nested-ternary
      setValue('meridiem', dateIsValid ? dateUTC.get('hours') < 12 ? 'am' : 'pm' : null)
    }
  }, [setValue, defaultValue])

  const isValid = (() => {
    // invalid when:
    //   - date, time, meridiem are only partially filled out
    //   - date is before/after
    if (![date, time, meridiem].every(Boolean) && [date, time, meridiem].some(Boolean)) return false
    if (datetime && !datetime?.isValid?.()) return false
    if (!otherDTIsValid || dtValueIsNull(otherDatetime)) return true
    if (!datetime) return false
    const _otherDatetime = dayjs(otherDatetime)
    if (type === 'end') return datetime.isAfter(_otherDatetime)
    if (type === 'start') return datetime.isBefore(_otherDatetime)
    return true
  })()

  type ChangeCallback = <N extends keyof DateTimeFields>(name: N, value: DateTimeFields[N]) => void
  const handleChange = useCallback<ChangeCallback>((name, value) => {
    // replace one arg. The index changes based on the name
    const args: DTFormattingSharedArgs = [date, time, meridiem]
    const argNameOrder = ['date', 'time', 'meridiem'] as const
    args[argNameOrder.indexOf(name)] = value
    const dtString = getFormattedTime(...args)
    if (dtString) onChange(dtString)
  }, [date, meridiem, onChange, time])
  
  return (
    <Stack tokens={{ childrenGap: 16 }} horizontal verticalAlign='start'>
      <Stack.Item>
        <Fieldset label={`${capType} Date`} errors={errors} fieldName='date'>              
          <Controller
            name="date"
            defaultValue={null}
            control={control}
            rules={{ validate: () => isValid }}
            render={({ field }) => (
              <DatePicker
                id={`${type}-${title}-date`}
                {...field}
                className='c-maint-mng__date'
                onSelectDate={(_date) => {
                  field.onChange(_date)
                  handleChange('date', _date)
                }}
                {...(otherDTIsValid ? getDatePickerMinMaxProp(type, otherDatetime) : {})}
              />
            )}
          />
        </Fieldset>
      </Stack.Item>
      <Stack.Item>
        <Fieldset label={`${capType} Time`} errors={errors} fieldName='time'>
          <Controller 
            name="time"
            control={control}
            rules={{
              pattern: {
                value: timePattern,
                message: 'Invalid format. HH:MM'
              },
              validate: () => isValid
            }}
            render={({ field }) =>  (
              <TextField 
                id={`${type}-${title}-time`}
                {...field}
                invalid={Boolean(errors?.time?.message) || !isValid}
                onChange={(ev, _value) => {
                  field.onChange(_value)
                  handleChange('time', _value)
                }}
                className='c-maint-mng__time'
              />
            )}
          />
        </Fieldset>
      </Stack.Item>
      <Stack.Item>
        <Fieldset label="Meridiem" errors={errors} fieldName='meridiem'>
          <Controller 
            name="meridiem"
            control={control}
            rules={{ validate: () => isValid }}
            render={({ field }) => (
              <Dropdown
                errorMessage={errors?.meridiem?.type || !isValid ? ' ' : ''}
                id={`${type}-${title}-meridiem`}
                selectedKey={field.value || null}
                onChange={(ev, option) => {
                  field.onChange(option.key)
                  handleChange('meridiem', option.key as DateTimeFields['meridiem'])
                }}
                options={meridiemOptions}
              />
            )}
          />
        </Fieldset>
      </Stack.Item>
      <Stack.Item>
        <IconButton 
          className='c-maint-mng__clear'
          iconProps={{ iconName: 'Cancel' }} 
          title={`Clear ${capType} Date`} 
          onClick={() => {
            onChange(undefined)
            setValue('date', undefined)
            setValue('time', undefined)
            setValue('meridiem', undefined)
          }}
        />
      </Stack.Item>
    </Stack>
  )
}