import { Card, TeamMemberDetail } from '@/shared/components'
import { TeamMember } from '@/shared/types/teamMember'

type TeamSection = {
  label: string,
  teamMembers: Array<TeamMember>
}

type SupportTeamProps = {
  sections: Array<TeamSection>
}

const SupportTeam = ({ sections }: SupportTeamProps) => {
  return (
    <Card className='c-support-team' ariaLabel='Team'>
      {
        sections.map((teamSection, index) => (
          <section className='c-support-team__section' key={`supportTeamSection-${index}`} aria-labelledby={`supportTeamHeader-${index}`}>
            <h3 className='c-support-team__section__header' id={`supportTeamHeader-${index}`}>{teamSection.label}</h3>
            <div className='c-support-team__section__members'>
              {
              teamSection.teamMembers.map((teamMember) => (
                <TeamMemberDetail 
                  className='c-support-team__section__members__member'
                  key={teamMember.id}
                  data={teamMember}
                />
              ))
            }
            </div>
          </section>
        ))
      }
    </Card>
  )
}

export default SupportTeam