import { ButtonModal, Digest, DigestSkeleton } from '@/shared/components'
import { useRef } from 'react'
import { useTranslation } from 'react-i18next'
import MediaForm from './components/MediaForm'
import { useGetDigestQuery, usePutDigestMutation } from '@/shared/api/services/sharedServices'
import { EUserCan } from '@/features/auth/types'
import usePermission from '@/features/auth/hooks/usePermission'
import withPermission, { WithPermissionProps } from '@/features/auth/components/withPermission'

export interface DigestMediaProps extends WithPermissionProps {
  mediaId?: string
}

const DigestMedia = ({ mediaId }: DigestMediaProps) => {
  const formRef = useRef<HTMLFormElement>()
  const { t } = useTranslation(['dashboard, common'])
  const { data, isLoading, isUninitialized, isError } = useGetDigestQuery()
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const userCanEditContent = usePermission(EUserCan.Edit_Content)

  const handleSave = () => { 
    if (formRef.current) formRef.current.requestSubmit() 
    // close modal
  }

  const handleModalOpen = () => { formRef?.current?.setFormValues(data) }

  if (isError) return null // Sidebar component that removes itself on error.
  if (isLoading || isUninitialized) return <DigestSkeleton />

  return (
    <>
      {userCanEditContent ? (
        <ButtonModal 
          paramName='mediaModal'
          modalClassName='c-media-modal'
          modalTitle={t('mediaModalTitle', { ns: 'dashboard' })}
          closeAriaLabel={t('ariaCloseModal', { ns: 'dashboard' })}
          openButtonAriaLabel={t('ariaOpenModal', { ns: 'dashboard' })}
          openButtonLabel={t('manageFeatured', { ns: 'dashboard' })}
          primaryLabel={t('save', { ns: 'common' })}
          onPrimary={handleSave}
          onOpen={handleModalOpen}
          className="c-media-modal__btn"
        >
          <MediaForm digest={data} ref={formRef} />
        </ButtonModal>
      ) : null}
      <Digest 
        introTitle={data.sectionName}
        mainTitle={data.title}
        caption={data.caption}
        linkUrl={data.linkUrl}
        content={{
          type: data.contentType,
          src: data.contentLink,
          mediaId, 
        }}
      />
    </>
  )
}

export default withPermission(EUserCan.Access_Additional_Info)(DigestMedia)