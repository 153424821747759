import { RouteComponentProps } from 'react-router'
import MainLayout from '@/shared/components/Layout/MainLayout'
import ClientNav from '@/features/client/components/ClientNav'
import TeamMembers from '@/features/client/components/TeamMembers'

export default function ClientSupport(props: RouteComponentProps<{accountid: string}>) {
  const { match } = props
  const { accountid } = match.params

  return (
    <MainLayout id="clients-support">
      <ClientNav accountid={accountid} />

      <TeamMembers />
    </MainLayout>
  )
}
