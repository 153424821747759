import i18n from 'i18next'
import dayjs from 'dayjs'

import en from './languages/en.json'
import { DEPLOYED_ENV } from '@/environment/environment.constants'

export const resources = {
  en,
}

i18n.on('languageChanged', (lng) => {
  dayjs.locale(lng)
})

const i18nConfig = {
  i18n: {
    useBackend: false,
    debug: DEPLOYED_ENV === 'dev',
    resources,
    lng: 'en-US',
    ns: ['common']
  },
}

export default i18nConfig