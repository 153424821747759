import { DefaultButton, IButtonStyles, getTheme } from '@fluentui/react'
import cn from 'classnames'

type LinkButtonProps = {
  href: string,
  children: string,
  className?: string
}

const theme = getTheme()

const defaultButtonStyles = {
  color: theme.palette.themePrimary,
  borderColor: theme.palette.themePrimary,
}

const styles: Partial<IButtonStyles> = {
  root: { ...defaultButtonStyles },
  rootHovered: { ...defaultButtonStyles },
  rootPressed: { ...defaultButtonStyles }
}

const TertiaryButton = ({ href, children, className }: LinkButtonProps) => (
  <DefaultButton className={cn(className)} styles={styles} href={href}>{children}</DefaultButton>
)

export default TertiaryButton