import { Label } from '@fluentui/react'
import { FormError } from '@/shared/components'
import { FieldError, FieldErrors } from 'react-hook-form'

type FieldsetProps = {
  children: JSX.Element | JSX.Element[],
  fieldName: string
  errors: Record<string, FieldError> | FieldErrors<any>
  isRequired?: boolean,
  label: string
}

const Fieldset = ({ children, fieldName, errors, isRequired = false, label }: FieldsetProps) => (
  <fieldset className='c-fieldset'>
    <Label required={isRequired} htmlFor={fieldName} id={`${fieldName}Label`}>{label}</Label>
    {children}
    <FormError errors={errors} name={fieldName} describedBy={`${fieldName}Label`} />
  </fieldset>
)

export default Fieldset