import { Stack } from '@fluentui/react'
import Skeleton from 'react-loading-skeleton'

const EventModalDescriptionSkeleton = () => {
  return (
    <Stack tokens={{ childrenGap: 24 }}>
      <Stack tokens={{ childrenGap: 8 }}>
        <Skeleton width={300} height={26} />
        <Skeleton width={300} height={26} />
      </Stack>
      <Stack horizontal horizontalAlign='space-between'>
        <Stack horizontal tokens={{ childrenGap: 16 }}>
          <Skeleton width={300} height={32} />
          <Skeleton width={300} height={32} />
        </Stack>
        <Stack horizontal horizontalAlign='end'>
          <Skeleton width={140} height={32} />
        </Stack>
      </Stack>
    </Stack>
  )
}

export default EventModalDescriptionSkeleton