import useDateParts from '../hooks/useDateParts'

type SideBarSectionItemDateLHSProps = {
  datetime: Date
}

export default function SideBarSectionItemDateLHS({ datetime }: SideBarSectionItemDateLHSProps) {
  const { month, year, day } = useDateParts(datetime)

  return (
    <>
      <span className="c-ssi__LHS-month">{month}</span>
      <span className="c-ssi__LHS-day">{day}</span>
      <span className="c-ssi__LHS-year">{year}</span>
    </>
  )
}