import { Chart } from 'chart.js'
import { getChartPlugin } from '../../utils/ChartJS.helper'
import { GroupBarChartSeriesItem } from '../GroupBarChart'

export interface GroupBarChartBlurPluginOptions {
  series: GroupBarChartSeriesItem[],
  onSeriesHover?: (seriesItem: GroupBarChartSeriesItem) => void,
}

export const GroupBarChartBlurPlugin = {
  id: 'GroupBarChartBlurPlugin',
  beforeEvent: (chart: Chart, args, pluginOptions) => {
    const plugin = getChartPlugin(chart, 'GroupBarChartBlurPlugin') as GroupBarChartBlurPluginOptions

    if (!plugin) {
      return
    }

    const _chart = chart as any
    const elements = _chart._active || []
    const event = args.event

    if (event.type === 'mouseout') {
      chart.data.datasets.forEach((ds, datasetIndex) => {
        ds.backgroundColor = plugin.series[datasetIndex].color
      })
      chart.update()

      if (plugin.onSeriesHover) {
        plugin.onSeriesHover(undefined)
      }
    }
  }
}
