import { memo } from 'react'
import { SideBarSectionItem } from '@/shared/components'
import { Building, VirtualEvent } from '@/shared/components/Icons'
import { useTranslation } from 'react-i18next'
import { EEventType } from '@/features/events/types/event'
import './EventSideBarSectionItem.scss'
import { SideBarSectionItemProps } from '@/shared/components/SideBarSectionItem/SideBarSectionItem'
import { EDateFormat } from '@/shared/types/date'

type EventSideBarSectionItemProps = {
  eventType: number;
} & Pick<SideBarSectionItemProps, 'href' | 'title' | 'datetime' | 'endDatetime'>

const EventSideBarSectionItem = (props: EventSideBarSectionItemProps) => {
  const { eventType, ...restProps } = props
  const { t } = useTranslation('event')

  const renderType = () => {
    if (EEventType[eventType] === 'In Person') {
      return (
        <>
          <Building aria-hidden />
          <p>{t('inPersonEvent')}</p>
        </>
      )
    }
    return (
      <>
        <VirtualEvent aria-hidden />
        <p>{t('virtualEvent')}</p>
      </>
    )
  }

  return (
    <li>
      <SideBarSectionItem 
        className='c-event-side-bar-section-item'
        dateFormat={EDateFormat['full-tz']}
        target="_blank"
        {...restProps}
      >
        <div className='c-event-side-bar-section-item__type'>
          {renderType()}
        </div>
      </SideBarSectionItem>
    </li>
  )
}

export default memo(EventSideBarSectionItem)