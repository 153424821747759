import Section, { SectionProps } from '@/shared/components/Section'
import Card from '@/shared/components/Card'
import Pagination from '@/shared/components/Pagination'
import { SelectionMode, ShimmeredDetailsList } from '@fluentui/react'
import cn from 'classnames'
import ConditionalWrapper from '../../ConditionalWrapper'
import ShimmerRow from './ShimmerRow'
import { DetailsListPropsExtra, ISharedProps } from '../types'
import TableSearchBox from './TableSearchBox'
import usePaginatedTableColumns from '../hooks/usePaginatedTableColumns'
import { useContext, useMemo } from 'react'
import TableContext, { ITableContext } from '../context'
import isEmpty from 'lodash/isEmpty'
import detailsListStyles from '../helpers/detailsListStyles'

export type Props = DetailsListPropsExtra & ISharedProps & {
  hideSearch?: boolean;
  listCount?: number;
}

const NetworkPaginatedTable = (props: Props) => {
  const { 
    columns: _columns, 
    data,
    listCount,
    hideSearch = false,
    pageSize = 25,
    sectionProps,
    cardProps,
    useCard = false,
    compact = false,
    additionalTableProps = {},
    shimmerIsEnabled = false,
    shimmerLineCount = 12,
  } = props
  
  const { setValue, ...tableContext }: ITableContext = useContext(TableContext) ?? {}
  if (isEmpty(tableContext)) throw new Error('Must provide TableContext.Provider with value')

  const mergedSectionProps: SectionProps = useMemo(() => {
    const { 
      actions = [], 
      className, 
      ..._sectionProps 
    } = (sectionProps || {})

    const _sectionActions = [...actions]
    return {
      ..._sectionProps,
      className: cn('c-table', className),
      actions: hideSearch ? _sectionActions : _sectionActions.concat([
        <TableSearchBox key="table-box" />
      ])
    }
  }, [sectionProps, hideSearch])

  const [columns] = usePaginatedTableColumns(_columns)

  return (
    <Section {...mergedSectionProps}>
      <ConditionalWrapper
        condition={useCard}
        wrapper={(children) => <Card {...cardProps}>{children}</Card>}
      >
        <>
          <ShimmeredDetailsList 
            compact={compact}
            columns={columns} 
            items={data}
            selectionMode={SelectionMode.none}
            styles={detailsListStyles}
            onRenderCustomPlaceholder={ShimmerRow}
            enableShimmer={shimmerIsEnabled}
            shimmerLines={shimmerLineCount}
            removeFadingOverlay
            {...additionalTableProps}
          />

          <footer className="c-table__footer">
            <Pagination
              usePageParam={true}
              pageParam={tableContext?.page?.value || 0} 
              maxShown={pageSize}
              listCount={listCount || data?.length || 0}
              onPageChange={(newPage) => {
                setValue({ ...tableContext, page: { ...tableContext.page, value: newPage } })
              }}
            />
          </footer>
        </>
      </ConditionalWrapper>
    </Section>
  )
}

export default NetworkPaginatedTable