import { useGetUnseenUpsideQuery } from '@/shared/api/services/rssServices'
import parseRSS from '../../helpers/parseRSS'
import { IFeedData } from '../types'

interface IPodcastsState {
  loading: boolean
  data?: IFeedData
  error: boolean
}

export default function usePodcasts(): IPodcastsState {
  const { isLoading, isUninitialized, data, isError } = useGetUnseenUpsideQuery()
  
  try {
    const parsedData = isLoading ? null : parseRSS(data)

    return {
      loading: isLoading || isUninitialized, 
      data: parsedData, 
      error: isError
    }
  } catch (error) {
    console.error(error)

    return {
      loading: false,
      data: null,
      error: true
    }
  }

}