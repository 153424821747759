import { useDeleteEventMutation } from '@/shared/api/services/eventServices'
import Confirmation from '@/shared/components/Confirmation'
import { IEvent } from '@/shared/types/swagger'
import { DefaultButton, Dialog, DialogFooter, DialogType, IButtonStyles, IconButton, PrimaryButton, Stack } from '@fluentui/react'
import { useState } from 'react'

type EventModalActionsProps = {
  setGeneralError: (status: boolean, message: string) => void,
  handleEdit: (event: IEvent) => void,
  event: IEvent,
}

const iconButtonStyles: IButtonStyles = {
  root: {
    height: 20,
    margin: 0,
  },
  icon: {
    fontSize: 20
  }
}

const EventModalActions = ({ setGeneralError, event, handleEdit }: EventModalActionsProps) => {
  const [isDeleting, setIsDeleting] = useState(false)
  const [isDialogShown, setIsDialogShown] = useState<boolean>(false)
  const [deleteEvent] = useDeleteEventMutation()

  const handleError = (message: string) => {
    if (setGeneralError) setGeneralError(true, message)
  }
  
  const handleEditClick = () => { handleEdit(event) }

  const handleDelete = () => {
    setIsDeleting(true)
    deleteEvent(event.id)
    .unwrap()
    .catch(() => handleError(`Failed to delete ${event.title.slice(0, 50)}`))
    .finally(() =>  setIsDeleting(false))
  }

  return (
    <>
      <Stack tokens={{ childrenGap: 16 }} horizontal>
        <IconButton
          styles={iconButtonStyles}
          iconProps={{ iconName: 'Edit' }}
          ariaLabel="Edit Event"
          onClick={handleEditClick}
        />
        <IconButton 
          styles={iconButtonStyles}
          iconProps={{ iconName: 'Delete' }}
          ariaLabel='Delete Event'
          onClick={() => setIsDialogShown(true)}
          disabled={isDeleting}
        />
      </Stack>
      <Confirmation 
        hidden={!isDialogShown}
        title="Delete Event"
        subText="Are you sure you want to delete this Event?"
        handleDismiss={() => setIsDialogShown(false)}
        handleNo={() => setIsDialogShown(false)}
        handleYes={handleDelete}
      />
    </>
  )
}

export default EventModalActions