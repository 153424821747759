import { IDetailsListStyles } from '@fluentui/react'

const detailsListStyles: Partial<IDetailsListStyles> = {
  headerWrapper: {
    '.ms-DetailsHeader': {
      background: 'transparent',
      paddingTop: '0'
    }
  }
} 

export default detailsListStyles