import { useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import { NavMenuItem } from '../components/NavMenu/types'
import { useSelector } from 'react-redux'
import { selectIsCAEmployee } from '@/features/auth/redux/authSelectors'
import { getNavMenuItemURL } from '../constants/navMenuItems'

export default function useHandleSelectionWithCSR(): (item: Partial<NavMenuItem>) => (e: React.MouseEvent<HTMLElement, MouseEvent>) => void {
  const history = useHistory()
  const isCAEmployee = useSelector(selectIsCAEmployee)

  const handleSelectionWithCSR = useCallback((item: Partial<NavMenuItem>) => (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    e.preventDefault()
    history.push(getNavMenuItemURL(item, isCAEmployee))
  }, [history, isCAEmployee])

  return handleSelectionWithCSR
}