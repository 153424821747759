import { IFeaturedMediaViewModel } from '@/shared/types/swagger'
import { emmaApiService } from '.'

const sharedWithTags = emmaApiService.enhanceEndpoints({ addTagTypes: ['Digest'],  })

const sharedService = sharedWithTags.injectEndpoints({
  endpoints(build) {
    return {
      getDigest: build.query<IFeaturedMediaViewModel, void>({
        query: () => ({ url: '/FeaturedMedia', method: 'GET' }),
        keepUnusedDataFor: 6000,
        providesTags: ['Digest']
      }),
      putDigest: build.mutation<string, IFeaturedMediaViewModel>({
        query: (digest) => ({ url: '/FeaturedMedia', method: 'PUT', data: digest }),
        invalidatesTags: ['Digest']
      }),
      uploadDigest: build.mutation<string, FormData>({
        query: (postData) => (
          {
            url: '/FeaturedMedia',
            method: 'POST',
            data: postData
          }
        ),
        invalidatesTags: ['Digest']
      }),
    }
  },
})

export const { useGetDigestQuery, usePutDigestMutation, useUploadDigestMutation } = sharedService
