import { IAccount, IClientContactViewModel, IContactTermsOfServiceHistory, ISubscription, ITeamMemberModel } from '@/shared/types/swagger'
import { BaseQueryError } from '@reduxjs/toolkit/dist/query/baseQueryTypes'
import { mainService } from '.'

export type InvitePayload = { CAID: IClientContactViewModel['caid'], accountid: string }

const crmService = mainService
  .enhanceEndpoints({ addTagTypes: ['Contacts', 'TermsOfServiceHistory'] })
  .injectEndpoints({
    endpoints(build) {
      return {
        getContactsByAccountId: build.query<Array<IClientContactViewModel>, number | string>({
          query: (accountId) => ({ url: `/crm/contact/getbyaccountid/${accountId}`, method: 'GET' }),
          providesTags: (result: Array<IClientContactViewModel>, error, arg) => (
            result ? [...result.map((contact) => ({ type: 'Contacts' as const, id: contact.caid })), 'Contacts'] : ['Contacts']
          )
        }),
        getAccountById: build.query<IAccount, number | string>({
          query: (accountId) => ({ url: `/crm/account/getbyid/${accountId}`, method: 'GET' })
        }),
        getTeamMembers: build.query<Array<ITeamMemberModel>, void>({
          query: () => ({ url: '/crm/teammembers', method: 'GET' })
        }),
        getSubscriptions: build.query<Array<ISubscription>, number | string>({
          query: (contactId) => ({ url: `/crm/contact/${contactId}/subscriptions` , method: 'GET' })
        }),
        sendInvite: build.mutation<void, IClientContactViewModel['caid']>({
          query: (CAID) => (
            { 
              url: `/User/invite?CAID=${CAID}`,
              method: 'PUT',
            }
          ),
          invalidatesTags: (result, error: BaseQueryError<any>, CAID: IClientContactViewModel['caid']) => {
            return error?.status === 400 ? [] : [{ type: 'Contacts' as const, id: CAID }]
          }
        }),
        getTermsOfServiceHistoryByContactId: build.query<IContactTermsOfServiceHistory, { accountId: string, contactId: string }>({
          query: ({ contactId, accountId }) => ({ url: `/TermsOfService/history/${contactId}/${accountId}`, method: 'GET' }),
          providesTags: (result, error, { contactId }) => {
            return [{
              type: 'TermsOfServiceHistory',
              contactId,
            }]
          },
        }),
        resetTermsOfService: build.mutation<void, { accountId: string, contactId: string }>({
          query: ({ contactId, accountId }) => (
            {
              url: `/TermsOfService/reset/${contactId}/${accountId}`,
              method: 'POST',
            }
          ),
          invalidatesTags: ['Contacts', 'TermsOfServiceHistory']
        })
      }
    },
  })

export const {
  useGetContactsByAccountIdQuery,
  useGetAccountByIdQuery,
  useGetTeamMembersQuery,
  useGetSubscriptionsQuery,
  useSendInviteMutation,
  useGetTermsOfServiceHistoryByContactIdQuery,
  useResetTermsOfServiceMutation,
} = crmService
