import { useEffect } from 'react'
import { SkeletonTheme } from 'react-loading-skeleton'
import { AuthError, ClientAuthErrorMessage } from '@azure/msal-browser'
import { MsalAuthenticationResult } from '@azure/msal-react'
import { ThemeProvider } from '@fluentui/react'
import theme from '@/shared/styles/theme'
import { LocalizationProvider } from '@/shared/components/ADKLocalization'
import { Footer, LogoFull } from '@/shared/components'
import localizationConfig from '@/bootstrap/i18n'
import { msalInstance } from '@/bootstrap/msal'
import { MSALErrorRedirectServerError } from './MSALErrorRedirect.model'

import './MSALErrorRedirect.scss'

const getServerError = (authError: AuthError): MSALErrorRedirectServerError => {
  if (authError) {
    const { errorCode, errorMessage } = authError
    const authErrorMessage = `${errorMessage || ''}`.trim()
    const isClientAuthError = (authError.name === 'ClientAuthError')
    const isClientConfigurationError = (authError.name === 'ClientConfigurationError')
    const isInteractionRequiredAuthError = (authError.name === 'InteractionRequiredAuthError')
    const isServerError = (authError.name === 'ServerError') || (authError.errorCode === 'server_error')
    const redirectServerError = {
      errorCode,
      errorMessage,
      showCustomErrorPage: false,
    } as MSALErrorRedirectServerError

    if (isClientAuthError) {
      const isOpenIdEndpointResolutionError = (errorCode === 'endpointResolutionError') || (errorCode === 'endpoints_resolution_error') 
        || (errorCode === ClientAuthErrorMessage?.endpointResolutionError?.code)
        || (!!ClientAuthErrorMessage?.endpointResolutionError?.desc && (authErrorMessage.includes(`${ClientAuthErrorMessage?.endpointResolutionError?.desc}`)))
      const isUnableToGetOpenidConfigError = (errorCode === 'unableToGetOpenidConfigError') || (errorCode === 'openid_config_error') 
        || (errorCode === ClientAuthErrorMessage?.unableToGetOpenidConfigError?.code)
        || (!!ClientAuthErrorMessage?.unableToGetOpenidConfigError?.desc && (authErrorMessage.includes(`${ClientAuthErrorMessage?.unableToGetOpenidConfigError?.desc}`)))
      const isNetworkError = (errorCode === 'networkError') || (errorCode === 'network_error') 
        || (errorCode === ClientAuthErrorMessage?.networkError?.code)
        || (!!ClientAuthErrorMessage?.networkError?.desc && (authErrorMessage.includes(`${ClientAuthErrorMessage?.networkError?.desc}`)))

      if (isOpenIdEndpointResolutionError) {
        console.error('Error while fetching B2C open id metadata endpoint', {
          errorCode,
          authErrorMessage,
        })
        
        // custom message and display custom error page
        redirectServerError.errorMessage = `We're currently experiencing a temporary issue with our external service provider, which is affecting login capabilities on Access CA`
        redirectServerError.showCustomErrorPage = true
      } else if (isUnableToGetOpenidConfigError) {
        console.error('Error that openid-configuration endpoint cannot be reached or does not contain the required data', {
          errorCode,
          authErrorMessage,
        })
        
        // custom message and display custom error page
        redirectServerError.errorMessage = `We're currently experiencing a temporary issue with our external service provider, which is affecting login capabilities on Access CA`
        redirectServerError.showCustomErrorPage = true
      } else if (isNetworkError) {
        console.error('Network error', {
          errorCode,
          authErrorMessage,
        })
        
        // returns as regular error because this error does not provide additional input on the error type
        redirectServerError.showCustomErrorPage = false
      }
    } else if (isClientConfigurationError) {
      // no scenarios identified yet for custom message on client configuration error
    } else if (isInteractionRequiredAuthError) {
      // no scenarios identified yet for custom message on interaction required auth error
    } else if (isServerError) {
      /**
       * Checks for Azure AD B2C Server errors
       * @link - https://learn.microsoft.com/en-us/azure/active-directory-b2c/error-codes
       */
      const b2cErrorCode = authErrorMessage?.match(/AADB2C\d{5}/)?.[0]

      if (b2cErrorCode) {
        const isMultipleRequestError = ['AADB2C90244', 'AADB2C90229'].includes(b2cErrorCode)
          || authErrorMessage?.toLowerCase()?.includes('too many requests')

        if (isMultipleRequestError) {
          console.error('Error with too many requests response', {
            errorCode,
            authErrorMessage,
          })
        
          // custom message and display custom error page
          redirectServerError.errorMessage = `We're currently experiencing a temporary issue with our external service provider, which is affecting login capabilities on Access CA`
          redirectServerError.showCustomErrorPage = true
        }
      }
    }

    return redirectServerError
  }

  return {
    errorCode: '',
    errorMessage: '',
    showCustomErrorPage: false,
  }
}

export default function MSALErrorRedirect(props: MsalAuthenticationResult) {
  const { error } = props

  useEffect(() => {
    const serverError = getServerError(error)
    if (!serverError?.showCustomErrorPage) {
      msalInstance.loginRedirect()
    }
  }, [error])

  const serverError = getServerError(error)

  if (serverError?.showCustomErrorPage) {
    return (
      <>
        <LocalizationProvider localizationConfig={localizationConfig} loadingComponent={<></>}>
          <ThemeProvider className='theme-provider' theme={theme}>
            <SkeletonTheme baseColor='#D2D0CE' highlightColor='#EEEEEE'>
              <nav className="c-nav">
                <a href='/' className="c-nav-logo">
                  <LogoFull height="40px" fill="#FFFFFF" />
                </a> 
              </nav>
              <main className="msal-error-rdr">
                <h1 className="msal-error-rdr__heading">Temporary Login Issue</h1>
                <div className="msal-error-rdr__container">
                  <p>
                    {serverError?.errorMessage}.&nbsp;Our team is actively working to resolve this as quickly as possible. We apologize for any inconvenience this may cause and appreciate your patience.
                  </p>
                  <p>
                    If you continue to experience issues with logging in, please don&apos;t hesitate to reach out to us at <a href="mailto:AccessCA@cambridgeassociates.com">AccessCA@cambridgeassociates.com</a>. Our support team is here to assist you. Thank you for your understanding.
                  </p>
                </div>
              </main>
              <Footer />
            </SkeletonTheme>
          </ThemeProvider>
        </LocalizationProvider>
      </>
    )
  }
  
  return (null)
}
