import dayjs from '@/bootstrap/dayjs'
import cn from 'classnames'

interface Props {
  dt: string;
  className?: string;
}

export default function InsightDateTime({ dt, className }: Props) {
  const formattedDate = dayjs(dt).format('LL')

  return (
    <time className={cn('c-insight__dt', className)} dateTime={formattedDate}>{formattedDate}</time>
  )
}
