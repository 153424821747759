import { selectIsALaCarte } from '@/features/auth/redux/authSelectors'
import { useGetSurveyConfigQuery } from '@/shared/api/services/surveyService'
import { ISurveyConfig } from '@/shared/types/Links'
import { useSelector } from 'react-redux'

const defaultSurveyConfig: ISurveyConfig = {
  ceas: false,
  cuas: false,
  eas: false,
  fas: false,
  iprqs: false,
  isas: false,
  meas: false,
}

export default function useSurveyConfig(): ISurveyConfig {
  const { data, isUninitialized, isLoading, isError } = useGetSurveyConfigQuery()
  const isALaCarte = useSelector(selectIsALaCarte)
  const useDefaultConfig = isLoading || isUninitialized || isError
  // supplying same json object as it was using earlier
  const changedData = (data || []).reduce((map, obj) => {
    map[obj.surveyReportName] = obj.configValue;
    return map;
  }, {});
  const config: ISurveyConfig = (() => {
    const _data = { ...(changedData || {}) } as ISurveyConfig
    if (isALaCarte) {
      _data.iprqs = false
    }
    return _data
  })()
  
  return useDefaultConfig ? defaultSurveyConfig : config
}