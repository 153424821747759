import { Card, GeneralError, Section } from '@/shared/components'
import TradeGrid from './grid/TradeGrid'
import { exportGridData, IPortfolioSettings, useGetAllTransactionQuery, useGetPortfolioSettingsByIdQuery } from '@/shared/api/services/portfolioService'
import SkeletonClientPortfolioTrades from './SkeletonClientPortfolioTrades'
import useIsMobile from '@/shared/hooks/useIsMobile'
import RecentTradesMobile from '../ClientPortfolioHome/RecentTrades/RecentTradesMobile/RecentTradeMobile'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { DefaultButton } from '@fluentui/react'
import { useState } from 'react'
import { GridApi } from 'ag-grid-community'
import { DashboardSettings } from '@/features/client/components/ClientSettings/ClientSettings.model'

export interface ClientPortfolioTradeProps {
  dashboardSettings: DashboardSettings,
  portfolioSettings: IPortfolioSettings,
  portfolioId: string, 
  asOfDate: string,
  sleeveId?: string,
  previewAccountId?: string,
}

export default function ClientPortfolioTrade({ 
    dashboardSettings,
    portfolioSettings,
    portfolioId, 
    asOfDate,
    sleeveId,
    previewAccountId,
  }: ClientPortfolioTradeProps) {
  const { data, isFetching, isError, refetch } = useGetAllTransactionQuery({ portfolioId, asOfDate, accountId: previewAccountId })
  const isMobile = useIsMobile('xs')
  const [ gridApi, setGridApi ] = useState(null as GridApi)

  const handleDownloadClick = () => {
    exportGridData({
      gridApi,
      excelTabName: 'Trades',
      fileName: `trades-export-investment-pool-${portfolioSettings?.investmentPoolName}-${portfolioSettings?.investmentPoolId}-as-of-date-${asOfDate}`,
    })
  }


  if (isError) {
    return (
      <Card className='card-center card-ag-grid-wrapper' ariaLabel='Welcome'>
        <Section className='c-portfolio-section-chart' title='Trades'>
          <GeneralError title='Failed to load Portfolio Trades' onClick={refetch} />
        </Section>
      </Card>
    )
  }

  return (
    <Card className='card-center card-ag-grid-wrapper card-trades-grid' ariaLabel='Welcome'>
      {
        isFetching ? <SkeletonClientPortfolioTrades /> :
        <Section className='c-portfolio-section-chart' title='Trades'  actions={!isMobile &&[         
          <div  key='downloadBtn' className='download-button-container'>
            <DefaultButton className='download-button'  onClick={handleDownloadClick}>
              <FontAwesomeIcon icon='download' className='c-sidebar-section__heading-icon' />
            </DefaultButton>
          </div>,]}>
          { isMobile ? <RecentTradesMobile fullDateFormat={portfolioSettings?.fullDateFormat} rows={data} /> : <TradeGrid dateFormat={portfolioSettings?.dateFormat} rows={data} sendGridApi={setGridApi} /> }
        </Section>
      }
    </Card>
  )
}
