import { CurrencyColumn } from '@/lib/common/components/grid/Columns'
import DataGrid, { DataGridInitEvent } from '@/lib/common/components/grid/Grid'
import { IPerformanceData, IPortfolioSettings, getGridExcelStyles } from '@/shared/api/services/portfolioService'
import { GridApi } from 'ag-grid-community'
import { PerformanceSleeveColumn } from '@/screens/ClientPortfolio/Components/Grid/Column/PerformanceSleeveColumn'

import './TopFiveHoldingGrid.scss'

export interface TopFiveHoldingGridProps {
  rows: IPerformanceData[],
  portfolioSettings: IPortfolioSettings,
  sendGridApi: (gridApi : GridApi) => void,
}

const TopFiveHoldingGrid = ({
    rows,
    portfolioSettings,
    sendGridApi,
}: TopFiveHoldingGridProps) => {
  const getCurrencySymbol = (data) => {
    return data?.CurrencySymbol
  }
  const ongridInit=(event : DataGridInitEvent) =>{
    const { gridApi } = event
    sendGridApi(gridApi)
  }
    return (
      <DataGrid
        showToolBar={false}
        rows={rows}
        enablePagination={false}
        domLayout='normal'
        className='TopFiveHolding'
        onInit={ongridInit}
        excelStyles={getGridExcelStyles({
          currencySymbol: portfolioSettings?.currency?.symbol,
          dateFormat: portfolioSettings?.dateFormat,
        })}
      >
        <PerformanceSleeveColumn disableFilterColumn={true} title='' field='Name' checkboxSelection={false}  />
        <CurrencyColumn title='MARKET VALUE' disableFilterColumn={true} field='MV'  width={150} getCurrencySymbol={getCurrencySymbol}/>
      </DataGrid>
    )
}

export default TopFiveHoldingGrid