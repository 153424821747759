export const HIERARCHY_PATH = 'agGridHierarchyPath'

export class GridHierarchyService {

    public static addHierarchyPath(rows = [] as any[], columnId: string, parentColumnId: string): any[] {
        const getParentRow = (row: any) => {
            return rows.find((currentRow: any) => currentRow[columnId] === row[parentColumnId])
        }

        rows.forEach(row => {
            const hierarchy = [`${row[columnId]}`]
            let parentRow = getParentRow(row)

            while (!!parentRow) {
                hierarchy.unshift(`${parentRow[columnId]}`)
                parentRow = getParentRow(parentRow)
            }

            row[HIERARCHY_PATH] = hierarchy
        })

        return rows
    }

    public static removeHierarchyPath(rows = [] as any[]): any[] {
        rows.forEach(row => {
            delete row[HIERARCHY_PATH]
        })

        return rows
    }

}
