import { CurrencyColumn, DateColumn, TextColumn } from '@/lib/common/components/grid/Columns'
import DataGrid, { DataGridInitEvent } from '@/lib/common/components/grid/Grid'
import { ITrade } from '@/shared/api/services/portfolioService'
import { TradeTypeColumn } from '@/screens/Dashboard/RecentTrades/grid/cellRenderer/TradeTypeColumn'
import { GridApi } from 'ag-grid-community'

import './TradeGrid.scss'

export interface TradeGridProps {
  rows: ITrade[],
  dateFormat: string,
  sendGridApi: (gridApi : GridApi) => void
}

const TradeGrid = ({
  rows,
  dateFormat,
  sendGridApi
}: TradeGridProps) => {
  const getCurrencySymbol = (data: ITrade) => {
    return data?.currency?.symbol
  }

  const onGridInit = (event: DataGridInitEvent) => {
    const { gridApi } = event
    sendGridApi(gridApi)
  }

  return (
    <DataGrid
      showToolBar={false}
      rows={rows}
      enablePagination={true}
      className='trade-grid'
      domLayout='normal'
      onInit={onGridInit}
    >
      <TextColumn title='INVESTMENT' disableFilterColumn={true} field='name' checkboxSelection={false} />
      <DateColumn dateFormat={dateFormat} title='DATE' disableFilterColumn={true} field='date' width={110} />
      <TradeTypeColumn headerClass='text-left' title='TYPE' disableFilterColumn={true} field='type' />
      <CurrencyColumn decimalScale={2} negativeNumberClassName='negative-number' title='AMOUNT' disableFilterColumn={true} field='amnt' getCurrencySymbol={getCurrencySymbol} width={150}/>
      <TextColumn title='Investing entity' disableFilterColumn={true} field='investmentPoolName' />
    </DataGrid>
  )
}

export default TradeGrid