import storage from 'localforage'
import { createMigrate, PersistConfig } from 'redux-persist'
import { RootState } from '@/bootstrap/redux'
import { migrations } from './migrations'
import { UIMessagesState } from '@/features/uimessages/shared/types'

export const persistConfig: PersistConfig<RootState> = {
  key: 'root',
  storage,
  version: 1,
  whitelist: ['auth', 'uimessages'],
  migrate: createMigrate(migrations),
}

export const uimessagesPersistConfig: PersistConfig<UIMessagesState> = {
  key: 'uimessages',
  storage,
  whitelist: ['archive']
}
