import PostModal, { PostModalProps } from '@/shared/components/PostModal'
import usePaginatedTableColumns from '@/shared/components/Table/hooks/usePaginatedTableColumns'

type InsightModalProps = Omit<PostModalProps, 'paramName' | 'primaryLabel'>

const InsightModal = (props: InsightModalProps) => {
  const [columns] = usePaginatedTableColumns(props.tableProps.columns)
  const modifiedProps: InsightModalProps = {
    ...props,
    tableProps: {
      ...props.tableProps,
      columns
    }
  }

  // TODO: Implement localization on aria-label
  return (
    <PostModal 
      paramName='manageInsight'
      primaryLabel='Save'
      {...modifiedProps}
    />
  )
}

export default InsightModal