import { Link, Icon } from '@fluentui/react'
import { ReactNode } from 'react'

type GeneralErrorProps = {
  title: string;
  description?: string | ReactNode;
  onClick: () => void;
}

const GeneralError = ({ title, description, onClick }: GeneralErrorProps) => {

  const handleClick = () => {
    if (onClick) onClick()
  }
  const descText = description || 'The issue is most likely related to an issue connecting to the database.'

  return (
    <div className='c-general-error'>
      <em className='c-general-error__header'>
        <Icon className='c-general-error__header__icon' iconName='Warning' />
        <strong className='c-general-error__header__text'>{title}</strong>
      </em>
      <p className='c-general-error__description'>{descText}</p>
      <Link onClick={handleClick} className='c-general-error__button'>Click to try reconnecting</Link>
    </div>
  )
}

export default GeneralError