import { IClientContactViewModel, IContactTermsOfServiceHistory } from '@/shared/types/swagger'
import { DEFAULT_FULL_DATE_FORMAT, formatDate } from '@/lib/common/services/date/DateService'
import TosHistoryCell from './TosHitoryCell'
import TosDate from './TosDate'

import './TosCell.scss'

interface Props {
  contact: IClientContactViewModel,
  accountId: string
}

export default function TosCell({ contact, accountId }: Props) {
  const { termsOfServiceHistory } = (contact || {}) as IClientContactViewModel
  const data  = (termsOfServiceHistory || {}) as IContactTermsOfServiceHistory
  const { termsOfServiceAcceptedDate, termsOfServiceDateHistory, termsOfServiceLastResetDate } = data
  const lastResetDate = termsOfServiceLastResetDate && formatDate(new Date(termsOfServiceLastResetDate), DEFAULT_FULL_DATE_FORMAT)

  return (<div className='TosCell'>
    { termsOfServiceAcceptedDate ? (
      <TosDate contact={contact} accountId={accountId} />
    ) : (lastResetDate ? (
      <>
        Last reset on<br />
        {lastResetDate}
      </>) : (
        <>Not Accepted</>
      )
    )}
    { (termsOfServiceDateHistory?.length > 0) && <TosHistoryCell dates={termsOfServiceDateHistory}></TosHistoryCell>}
  </div>
  )
}
