import { SyntheticEvent } from 'react'

type ActivitySidebarProps = unknown
type AdditionalSidebarTab = unknown
type APICache = unknown
type User = {
  avatar_url?: string,
  email?: string,
  enterprise?: {
    id: string,
    name: string,
    type: 'enterprise',
  },
  hostname?: string,
  id: string,
  login?: string,
  name: string,
  status?: string,
  type: 'user',
}
type DetailsSidebarProps = {
  classification?: {     
    definition?: string,
    name: string,
  },
  elementId?: string,
  fileId?: string,
  hasAccessStats?: boolean,
  hasClassification?: boolean,
  hasNotices?: boolean,
  hasProperties?: boolean,
  hasRetentionPolicy?: boolean,
  hasSidebarInitialized?: boolean,
  hasVersions?: boolean,
  onAccessStatsClick?: () => void,
  onClassificationClick?: (e: SyntheticEvent<HTMLButtonElement>) => void,
  onRetentionPolicyExtendClick?: () => void,
  onVersionHistoryClick?: () => void,
  retentionPolicy?: Record<string, unknown>,
}
type MetadataSidebarProps = {

}
type VersionsSidebarProps = {

}

type ContentSidebarProps = {
  activitySidebarProps?: ActivitySidebarProps,
  additionalTabs?: Array<AdditionalSidebarTab>,
  apiHost?: string,
  cache?: APICache,
  className?: string,
  clientName?: string,
  currentUser?: User,
  defaultView?: string,
  detailsSidebarProps?: DetailsSidebarProps,
  features?: {[key:string]: {[key:string]: unknown}},
  fileId?: string,
  getPreview?: () => void,
  getViewer?: () => void,
  hasActivityFeed?: boolean,
  hasAdditionalTabs?: boolean,
  hasMetadata?: boolean,
  hasSkills?: boolean,
  hasVersions?: boolean,
  isDefaultOpen?: boolean,
  language?: string,
  metadataSidebarProps?: MetadataSidebarProps,
  onAnnotationSelect?: () => void,
  onVersionChange?: () => void,
  onVersionHistoryClick?: () => void,
  requestInterceptor?: () => void,
  responseInterceptor?: () => void,
  sharedLink?: string,
  sharedLinkPassword?: string,
  token?: string | (() => string);
  versionsSidebarProps?: VersionsSidebarProps,
}

export const contentSidebarProps: ContentSidebarProps = {
  detailsSidebarProps: {
    hasProperties: true,
    hasNotices: true,
    hasAccessStats: true,
    hasClassification: true,
    hasRetentionPolicy: true,
  },
  hasMetadata: true,
  hasSkills: true,
  hasVersions: true,
}

export const FOLDER_QUERY_KEY = 'folderId'