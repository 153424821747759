import { memo, useEffect, useRef } from 'react'
import { Icon, IconButton } from '@fluentui/react'

type ErrorMessageWithTimeoutProps = {
  message: string,
  showAlert: boolean,
  setShowAlert: (status: boolean) => void,
  shouldCloseAfterTimeout: true,
  timeout: number
}

type ErrorMessageProps = {
  message: string,
  showAlert: boolean,
  setShowAlert: (status: boolean) => void,
  shouldCloseAfterTimeout?: false
  timeout?: never
}

const ErrorMessage = ({ message, showAlert, setShowAlert, shouldCloseAfterTimeout, timeout }: ErrorMessageProps | ErrorMessageWithTimeoutProps) => {
  const timeoutHandler = useRef(null)

  const handleRemoveAlert = () => {
    if (timeoutHandler.current) clearTimeout(timeoutHandler.current)
    if (setShowAlert) setShowAlert(false)
  }

  useEffect(() => {
    if (showAlert && shouldCloseAfterTimeout && !timeoutHandler.current) {
      timeoutHandler.current = setTimeout(() => {
        setShowAlert(false)
      }, timeout)
    }
  }, [timeout, shouldCloseAfterTimeout, setShowAlert, showAlert])

  if (!showAlert) return null
  return (
    <div className='c-error-message'>
      <Icon role="presentation" className='c-error-message__icon' iconName='ErrorBadge' />
      <span className='c-error-message__message' role='alert'>{message}</span>
      <IconButton className='c-error-message__close' iconProps={{ iconName: 'Cancel' }} aria-label='Dismiss message' onClick={handleRemoveAlert} />
    </div>
  )
}

export default memo(ErrorMessage)