import { appDispatch } from "@/bootstrap/redux";
import withPermission, { WithPermissionProps } from "@/features/auth/components/withPermission";
import { EUserCan } from "@/features/auth/types";
import { createUIMessage } from "@/features/uimessages/redux/operations";
import { useGetWelcomeMessagesByAccountIdQuery, useUpdateWelcomeMessageMutation } from "@/shared/api/services/welcomeMessageService";
import { GeneralError, PostModal } from "@/shared/components";
import useIsMobile from "@/shared/hooks/useIsMobile";
import modalSlice from "@/shared/redux/modalSlice";
import { IStackItemStyles, IStackTokens, MessageBarType, Stack } from "@fluentui/react";
import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import Editor, { EditorRefHandle } from '@/shared/components/Editor'

export interface ManageOnboardingTextProps extends WithPermissionProps {
  accountId: string;
}

const MODAL_PARAM_NAME = 'manageOnboardingTextModal'
const UI_MESSAGE_DISMISS_TIMEOUT = 3000

const ManageOnboardingText = ({ accountId }: ManageOnboardingTextProps) => {
  const dispatch = useDispatch<appDispatch>()
  const isMobile = useIsMobile('md')
  const [onboardingText, setOnboardingText] = useState(undefined as string)
  const { data: data, isFetching, isLoading, refetch, isError, isUninitialized } = useGetWelcomeMessagesByAccountIdQuery(accountId)
  const [saveWelcomeMessage] = useUpdateWelcomeMessageMutation()
  const inProgress = isUninitialized || isFetching || isLoading
  const editorRef = useRef<EditorRefHandle>(null)
  const [onboardingTextMessage, setOnboardingTextMessage] = useState(null)
  const editorPlugins = ['link', 'paste', 'image', 'accordion', 'lists', 'table']
  const editorToolbar = 'undo redo | bold italic | formatselect | link | image | accordion | numlist bullist | table tabledelete | tableprops tablerowprops tablecellprops | tableinsertrowbefore tableinsertrowafter tabledeleterow | tableinsertcolbefore tableinsertcolafter tabledeletecol'
  const editorBlockFormats = 'Paragraph=p;Heading 1=h1;Heading 2=h2;Heading 3=h3;'
  const stackToken: IStackTokens = { childrenGap: 16 }

  useEffect(() => {
    if (!isUninitialized && !isFetching && !isLoading && data) {

      setOnboardingTextMessage(data.messages[1])
    }
  }, [isUninitialized, isFetching, isLoading, data]);


  const htmlAreadStyles: IStackItemStyles = {
    root: {
      width: isMobile ? '100%' : '80%'
    },
  }

  const handleOpen = () => {
    setOnboardingText(onboardingTextMessage.message)
  }

  const handleChange = (text: string) => {
    setOnboardingText(editorRef.current.getHTML())
  }

  const handleCloseClick = () => {
    refetch()
  }

  const handleConfirmClick = () => {
    saveWelcomeMessage({
      ...onboardingTextMessage,
      message: onboardingText
    }).unwrap().then(o => {
      setOnboardingText(undefined)
      dispatch(createUIMessage({
        key: 'saveOnboardingTextSuccess',
        content: 'Saved Onboarding Text successfully.',
        messageBarType: MessageBarType.success,
        autoDismissAfter: UI_MESSAGE_DISMISS_TIMEOUT,
      }));
      dispatch(modalSlice.actions.setModalStatus({ paramName: MODAL_PARAM_NAME, value: false }))
    }).catch(error => {
      dispatch(createUIMessage({
        key: 'saveOnboardingTextFailure',
        content: 'Failed to save Onboarding Text.',
        messageBarType: MessageBarType.error,
        autoDismissAfter: UI_MESSAGE_DISMISS_TIMEOUT,
      }));
    })
  }

  if (isError) {
    return (<GeneralError onClick={refetch} title='Manage Onboarding Text.' />)
  }

  const isPrimaryButtonDisabled = !onboardingText

  return (
    <>
      <PostModal
        modalClassName='c-manage-onboarding-text-message-modal'
        className='c-manage-onboarding-text-content'
        onClose={handleCloseClick}
        onOpen={handleOpen}
        onConfirmClick={handleConfirmClick}
        primaryIsDisabled={isPrimaryButtonDisabled}
        primaryLabel="Save"
        openButtonLabel="Manage Onboarding Text"
        openButtonId="manage-welcome-message-button"
        modalTitle="Manage Onboarding Text"
        shimmerIsEnabled={inProgress}
        paramName={MODAL_PARAM_NAME}
        ariaLabels={{
          openButton: 'Open',
          closeButton: 'Close'
        }}
        footer={<div style={{ marginTop: '24px' }}></div>}
        description={
          <>
            <Stack className='ca-office-dropdown-wrapper' style={{ alignItems: 'flex-end' }} tokens={stackToken} horizontal={!isMobile}>
              <Stack.Item styles={htmlAreadStyles}>
                <Editor
                  id="body"
                  onChange={handleChange}
                  initialValue={onboardingTextMessage ? onboardingTextMessage.message : ''}
                  ref={editorRef}
                  plugins={editorPlugins}
                  toolbar={editorToolbar}
                  blockFormats={editorBlockFormats}
                  height={550}
                />
              </Stack.Item>
            </Stack>
          </>
        }
      />
    </>
  )
}

export default withPermission(EUserCan.Edit_OnboardingDashboard)(ManageOnboardingText)