import { b2cCustomPolicyName, msalInstance } from '@/bootstrap/msal'
import { MSAL_CLIENT_ID, MSAL_REDIRECT_URL } from '@/environment/environment.constants'
import { IClientContactViewModel, InviteStateEnum } from '@/shared/types/swagger'
import { Link } from '@fluentui/react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

type ImpersonateCellProps = {
  contact: IClientContactViewModel
}

export default function ImpersonateCell({ contact }: ImpersonateCellProps) {
  const { t } = useTranslation('contacts')
  const params = useParams<{accountid: string}>()
  const { accountid } = params
  const { contactId } = contact
  const disabled = false

  if (contact.inviteState === InviteStateEnum.InviteNotSent) return null

  return (
    <Link 
      disabled={disabled}
      onClick={() => {
        msalInstance.loginRedirect({
          scopes: [],
          prompt: 'none',
          extraQueryParameters: {
            'p': b2cCustomPolicyName,
            'acctID': accountid,
            'contactID': contactId,
            'client_id': MSAL_CLIENT_ID,
            'redirect_uri': MSAL_REDIRECT_URL,
            'scope': 'openid',
            // 'response_type': 'id_token',
          }
        })
      }}
    >{t('impersonate')}</Link>
  )
}
