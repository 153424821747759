import OnboardingLocation from '@/features/onboarding/components/OnboardingLocations'
import OnboardingNav from '@/features/onboarding/components/OnboardingNav'
import OnboardingQuestions from '@/features/onboarding/components/OnboardingQuestions'
import SupportTeam from '@/features/onboarding/components/SupportTeam'
import { useGetAllCAOfficesQuery } from '@/shared/api/services/caOffficeService'
import { MainLayout } from '@/shared/components'
import PageHeader from '@/shared/components/PageHeader'
import { useTranslation } from 'react-i18next'

const OnboardingSupport = () => {
  const { t } = useTranslation()
  const { data: locations = [] } = useGetAllCAOfficesQuery()
  
 return (
   <MainLayout>
     <OnboardingNav />
     <PageHeader label={t('onboardingSupport', { ns: 'nav' })} />
     <SupportTeam
       sections={[
        {
          label: 'Global Business Development',
          teamMembers: [
            {
              id: 1736,
              fullName: 'Mona Kane',
              jobTitle: 'Lead Investment Director',
              email: 'mona.kane@cambridgeassociates.com',
              phone: '+1 (617) 454-5454'
            },
            {
              id: 1632,
              fullName: 'Mona Kane',
              jobTitle: 'Lead Investment Director',
              email: 'mona.kane@cambridgeassociates.com',
              phone: '+1 (617) 454-5454'
            }
          ]
        },
        {
          label: 'Investment Team',
          teamMembers: [
            {
              id: 15312,
              fullName: 'Mona Kane',
              jobTitle: 'Lead Investment Director',
              email: 'mona.kane@cambridgeassociates.com',
              phone: '+1 (617) 454-5454'
            },
            {
              id: 161234,
              fullName: 'Mona Kane',
              jobTitle: 'Lead Investment Director',
              email: 'mona.kane@cambridgeassociates.com',
              phone: '+1 (617) 454-5454'
            }
          ]
        },
        {
          label: 'Client Services',
          teamMembers: [
            {
              id: 1234882,
              fullName: 'Mona Kane',
              jobTitle: 'Lead Investment Director',
              email: 'mona.kane@cambridgeassociates.com',
              phone: '+1 (617) 454-5454'
            },
            {
              id: 1259318,
              fullName: 'Mona Kane',
              jobTitle: 'Lead Investment Director',
              email: 'mona.kane@cambridgeassociates.com',
              phone: '+1 (617) 454-5454'
            }
          ]
        }
       ]}
     />
     <OnboardingQuestions />
     <OnboardingLocation
       locations={locations}
     />
   </MainLayout>
 ) 
}

export default OnboardingSupport