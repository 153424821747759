import { IUserProfileModel } from '@/shared/types/swagger'
import { mainService } from '.'

const profileWithTags = mainService.enhanceEndpoints({ addTagTypes: ['Profile'],  })

const profileServices = profileWithTags.injectEndpoints({
  endpoints(build) {
    return {
      getProfile: build.query<IUserProfileModel, { accountId?: string }>({
        query: ({ accountId } = {}) => ({
          url: `/CRM/currentprofile${accountId ? `?accountId=${accountId}` : ''}`,
          method: 'GET',
        }),
        providesTags: ['Profile'],
        keepUnusedDataFor: 6000,
      }),
    };
  },
});


export const { useGetProfileQuery  } = profileServices
