import { IBasePickerSuggestionsProps, IPersonaProps, NormalPeoplePicker, ValidationState } from '@fluentui/react'
import { useTranslation } from 'react-i18next'
import { IClientContactViewModel } from '@/shared/types/swagger'

function validateInput(input: string): ValidationState {
  if (input.includes('@')) {
    return ValidationState.valid
  } else if (input.length > 1) {
    return ValidationState.warning
  } else {
    return ValidationState.invalid
  }
}
const getContactText = (c: IClientContactViewModel, currentValue?: string) => `${c.firstName} ${c.lastName}`
const getInitials = (c: IClientContactViewModel) => `${c.firstName[0]}${c.lastName[0]}`

interface Props {
  contacts: Array<IClientContactViewModel>;
  id?: string;
  loading: boolean;
  handleChange: (contactIds: Array<string>) => void
  className?: string
  handleBlur?: (event: React.FocusEvent<HTMLInputElement>) => void
}

export default function ContactPicker(props: Props) {
  const { id, loading, contacts, handleChange, className, handleBlur } = props
  const { t } = useTranslation('messages')
  // const [contacts, setContacts] = useState<Array<IClientContactViewModel>>(_contacts)

  const suggestionProps: IBasePickerSuggestionsProps = {
    suggestionsHeaderText: t('pickerSuggestionsHeaderText'),
    mostRecentlyUsedHeaderText: t('pickerMostRecentlyUsedHeaderText'),
    noResultsFoundText: t('pickerNoResultsFoundText'),
    loadingText: t('pickerLoadingText'),
    suggestionsAvailableAlertText: t('pickerSuggestionsAvailableAlertText'),
    suggestionsContainerAriaLabel: t('pickerSuggestionsContainerAriaLabel'),
    showRemoveButtons: true,
  }

  function onFilterChanged(filterText: string, selectedItems: IPersonaProps[]): IPersonaProps[] {
    if (loading || !contacts) return []
    if (filterText) {
      const filteredContacts = contacts.filter((contact) => {
        return getContactText(contact).toLocaleLowerCase().includes(filterText.toLocaleLowerCase()) && contact.inviteState !== 0
      })
      return filteredContacts.map((contact) => ({
        text: getContactText(contact),
        tertiaryText: getInitials(contact),
        onRenderInitials: ({ tertiaryText }) => <>{tertiaryText}</>,
        itemID: contact.contactId
      }))
      // const filteredPersonas = removeDuplicates(filteredContacts, currentPersonas)
      // return limitResults ? filteredPersonas.slice(0, limitResults) : filteredPersonas
    } else {
      return []
    }
  }

  // function onRemoveSuggestion(item: IPersonaProps) {
  //   if (loading) return

  //   const indexPeopleList: number = contacts.indexOf(item)

  //   if (indexPeopleList >= 0) {
  //     const newContacts: IClientContactViewModel[] = contacts
  //       .slice(0, indexPeopleList)
  //       .concat(contacts.slice(indexPeopleList + 1))
  //     setContacts(newContacts)
  //   }
  // }

  const onChange = (persons: IPersonaProps[]) => {
    handleChange(persons.map(p => p.itemID))
  }
  const handleOnBlurChanges = (event: React.FocusEvent<HTMLInputElement>) => {
    if (handleBlur)
      handleBlur(event)
  }

  return (
    <NormalPeoplePicker
      onResolveSuggestions={onFilterChanged}
      pickerSuggestionsProps={suggestionProps}
      onChange={onChange}
      className={className}
      onValidateInput={validateInput}
      selectionAriaLabel={t('pickerSelectedContacts')}
      removeButtonAriaLabel={t('pickerRemove')}
      onBlur={handleOnBlurChanges}
      inputProps={{
        'aria-label': t('pickerPicker'),
        id
      }}
    />
  )
}
