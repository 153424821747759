import faqSlice, { selectQuestionBeingEdited } from '@/features/support/redux/faqSlice'
import { FAQ } from '@/features/support/types/faq'
import { useAddFAQMutation, useDeleteFAQMutation, useUpdateFAQMutation } from '@/shared/api/services/supportServices'
import { IconButton, Stack, TooltipHost } from '@fluentui/react'
import './FAQTableActions.scss'
import { useDispatch, useSelector } from 'react-redux'
import { useState } from 'react'
import getTextFromHTML from '../../../helpers/getTextFromHTML'
import textIsLongerThan from '../../../helpers/textIsLongerThan'
import Confirmation from '@/shared/components/Confirmation'

type Props = {
  faq: FAQ;
  editingId?: string;
  setQuestionBeingAdded: (newValue: FAQ) => void
}

export default function FAQTableActions({ faq, editingId, setQuestionBeingAdded }: Props) {
  const isEditing = editingId === faq.id
  const dispatch = useDispatch()
  const handleEdit = (faq: FAQ) => {
    // eslint-disable-next-line react/prop-types
    dispatch(faqSlice.actions.setQuestion(faq))
  }
  const [deleteFAQ, { isLoading: deleteIsLoading }] = useDeleteFAQMutation()
  const [isDialogShown, setIsDialogShown] = useState<boolean>(false)
  const isNew = !faq.createdAt
  const deleteItem = () => {
    if (isNew) {
      handleEdit(null)
      setIsDialogShown(false)
      dispatch(faqSlice.actions.setQuestionError(null))
      return
    }
    deleteFAQ(faq.id)
      .unwrap()
      .then(() => { 
        setIsDialogShown(false)
        dispatch(faqSlice.actions.setQuestion(null))
      })
      .catch((error) => dispatch(faqSlice.actions.setQuestionError('Failed to delete item. Please try again later.')))
  }
  const [updateFAQ, { isLoading: updateIsLoading }] = useUpdateFAQMutation()
  const [addFAQ, { isLoading: addIsLoading }] = useAddFAQMutation()
  // If there is no createdAt, this is a new local FAQ and we need to disable the delete operation
  const isNewQuestion = !faq?.createdAt
  const faqQuestion = useSelector(selectQuestionBeingEdited)

  const answerHasErrors = (html: string): boolean => {
    const answerWords = getTextFromHTML(html)
    if (answerWords.length === 0) return true
    if (textIsLongerThan(answerWords, 25)) return true
    return false
  }

  const questionHasErrors = (text: string): boolean => {
    return !(text?.replace?.(/\s/g, ''))
  }

  function validateFAQ(hadleSuccess: () => void) {
    if (questionHasErrors(faqQuestion?.questionText) || answerHasErrors(faqQuestion?.questionResponse)) {
      dispatch(faqSlice.actions.setQuestionError('Please fix validation errors and try again.'))
      return
      // When is my team available to contact ?
    }
    dispatch(faqSlice.actions.setQuestionError(null))
    hadleSuccess()
  }

  function saveFAQ() {
    if (isNewQuestion) {
      addFAQ(faqQuestion)
      .unwrap()
      .then(() => {
        setQuestionBeingAdded(null)
        dispatch(faqSlice.actions.setQuestion(null))
      })
      .catch((error) => dispatch(faqSlice.actions.setQuestionError('Failed to create question. Please try again later.')))
    }
    else {
      updateFAQ(faqQuestion)
      .unwrap()
      .then(() => dispatch(faqSlice.actions.setQuestion(null)))
      .catch((error) => dispatch(faqSlice.actions.setQuestionError('Failed to update item. Please try again later.')))
    }
  }

  const handleSave = () => validateFAQ(saveFAQ)

  const disableActions = addIsLoading || deleteIsLoading || updateIsLoading

  return (
    <Stack horizontal>
      {isEditing ? ( 
        <Stack.Item>
          <TooltipHost>
            
          </TooltipHost>
          <IconButton 
            aria-label='Save FAQ'
            disabled={disableActions}
            iconProps={{ iconName: 'Save' }}
            className="faq-ta-btn"
            onClick={handleSave}
          />
        </Stack.Item>
      ) : null}
      <Stack.Item>
        <IconButton 
          aria-label={isEditing ? 'Cancel Edit' : 'Edit Frequently Asked Question'}
          iconProps={{ iconName: isEditing ? 'Cancel' : 'Edit' }}
          disabled={disableActions}
          onClick={() => isNew ? setIsDialogShown(true) : handleEdit(!isEditing ? faq : null)}
          className="faq-ta-btn"
        />
      </Stack.Item>
      <Stack.Item>
        {!isNew ? (
          <IconButton 
            aria-label="Delete FAQ"
            iconProps={{ iconName: 'Delete', className: 'faq-ta-btn__icon--danger' }}
            disabled={disableActions}
            onClick={() => setIsDialogShown(true)}
            className="faq-ta-btn"
          />
        ) : null}
        <Confirmation 
          hidden={!isDialogShown}
          title={`${isNew ? 'Close' : 'Delete'} FAQ`}
          subText={`Are you sure you want to ${isNew ? 'discard' : 'delete'} this FAQ?`}
          handleDismiss={() => setIsDialogShown(false)}
          handleNo={() => setIsDialogShown(false)}
          handleYes={deleteItem}
        />
      </Stack.Item>
    </Stack>
  )
}
