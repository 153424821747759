import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import dayjs from 'dayjs'
import LanguageDetector from 'i18next-browser-languagedetector'
import Backend from 'i18next-chained-backend'
import LocalStorageBackend from 'i18next-localstorage-backend'
import HttpApi from 'i18next-http-backend'

const i18nBootstrap = (localizationConfig) => {
  if (localizationConfig.useBackend) {
    return i18n
      .use(Backend)
      .use(LanguageDetector)
      .use(initReactI18next)
      .init({
        interpolation: {
          escapeValue: false,
          format: (value, format) => {
            if (value instanceof Date) {
              return dayjs(value).format(format)
            }
            return value
          }
        },
        react: {
          useSuspense: false
        },
        defaultNs: 'common',
        ns: ['common', 'content'],
        partialBundledLanguages: true,
        nonExplicitSupportedLngs: true,
        fallbackLng: false,
        backend: {
          backends: [LocalStorageBackend, HttpApi],
          backendOptions: [
            {
              enabled: true,
              prefix: 'i18next_res_',
              expirationTime: 3 * 24 * 60 * 60 * 1000, // cached for 3 days.
              store: window.localStorage
            },
            {
              loadPath: `/api/project/${process.env.REACT_APP_LOCALIZATION_PROJECT_VERSION}/Version/${process.env.REACT_APP_LOCALIZATION_LANGUAGE_VERSION}/Language/{{lng}}`,
              customHeaders: {
                'Content-Type': 'application/json; charset=utf-8',
                ProjectId: process.env.REACT_APP_LOCALIZATION_PROJECT_VERSION,
                SecretKey: process.env.REACT_APP_LOCALIZATION_SECRET_KEY
              },
              parse: (data, language, namespace) => {
                const json = JSON.parse(data)[0]
                if (json && json.fileContent) {
                  const content = json.fileContent[language]
                  const namespaces = Object.keys(content)
                  const { length } = namespaces
                  for (let index = 0; index < length; index++) {
                    i18n.addResources(
                      language,
                      namespaces[index],
                      content[namespaces[index]]
                    )
                  }
                }
                return true
              }
            }
          ]
        },
        ...localizationConfig
      })
      .then(() => {
        return i18n
      })
  } else {
    return i18n
      .use(LanguageDetector)
      .use(initReactI18next)
      .init({
        interpolation: {
          escapeValue: false,
          format: (value, format) => {
            if (value instanceof Date) {
              return dayjs(value).format(format)
            }
            return value
          }
        },
        react: {
          useSuspense: false
        },
        defaultNs: 'common',
        ns: ['common', 'content'],
        partialBundledLanguages: true,
        nonExplicitSupportedLngs: true,
        fallbackLng: false,
        ...localizationConfig
      })
      .then(() => {
        return i18n
      })
  }
}

export default i18nBootstrap