import ReactDOM from 'react-dom'
import { useLayoutEffect, useRef } from 'react';
import { ComboBox, IComboBoxOption, IComboBoxStyles, Icon } from '@fluentui/react'

import './SingleSelectionDropdown.scss'

export interface SingleSelectionDropdownProps {
  options: {
    key: string,
    text: string,
  }[],
  selectedKey: string,
  placeholder?: string,
  showClearButton?: boolean,
  width?: number,
  onValueChange?: (selectedKey?: string) => void
}

const SingleSelectionDropdown = ({
  options = [],
  selectedKey = '',
  placeholder = '',
  showClearButton = false,
  width,
  onValueChange,
}: SingleSelectionDropdownProps) => {
  const dropdownRef = useRef(null)

  useLayoutEffect(() => {
    if (dropdownRef) {
      const container = dropdownRef.current as HTMLElement
      
      if (container) {
        const baseContainer = container.querySelector('.ms-ComboBox') as HTMLElement
        
        if (baseContainer) {
          const input = container.querySelector('input') as HTMLElement
          
          if (input) {
            input.setAttribute('disabled', 'disabled')
          }

          if (showClearButton) {
            let closeIcon = container.querySelector('.close-icon') as HTMLElement

            if (!closeIcon) {
              // creates close icon
              closeIcon = document.createElement('div')
              closeIcon.classList.add('close-icon')
              closeIcon.style.display = selectedKey ? '' : 'none'
              ReactDOM.render(
                (
                  <Icon
                    iconName='Cancel'
                    styles={{
                      root: {
                        color: 'rgb(96, 94, 92)',
                        paddingRight: '.7em',
                        '&:hover': {
                          fontWeight: 800
                        }
                      }
                    }}
                    onClick={(event) => {
                      event.stopPropagation();
                      onSelectedOption()
                    }}
                  />
                ),
                closeIcon,
              )
  
              const caretDownButton =  baseContainer.querySelector('.ms-ComboBox-CaretDown-button') as HTMLElement
  
              if (caretDownButton) {
                const buttonContainer = caretDownButton.querySelector('.ms-Button-flexContainer') as HTMLElement
                
                if (buttonContainer) {
                  caretDownButton.insertBefore(closeIcon, buttonContainer)
                }
              }
            } else {
              closeIcon.style.display = selectedKey ? '' : 'none'
            }
          }
        }
      }
    }
  }, [showClearButton, selectedKey])

  const dropdownStyles: Partial<IComboBoxStyles> = { 
    root: {
      width,
      outline: 'none',
      lineHeight: 'normal',
    },
    optionsContainerWrapper: {
      width,
    },
  };

  const onSelectedOption = (option?: IComboBoxOption) => {
    const selectedOption = (option?.key || '') as string
    if (onValueChange) {
      onValueChange(selectedOption)
    }
  }

  return (
    <ComboBox
      ref={dropdownRef}
      className={`SingleSelectionDropdown ${selectedKey ?  'has-value' : ''}`}
      selectedKey={selectedKey}
      options={options}
      styles={dropdownStyles}
      autoComplete='off'
      allowFreeform={false}
      useComboBoxAsMenuWidth={true}
      placeholder={placeholder}
      onChange={(event, option) => onSelectedOption(option)}
    />
  )
}

export default SingleSelectionDropdown