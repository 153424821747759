import { Link } from '@fluentui/react'

type TableLinkCellProps = {
  pageUrl: string
  label: string
}

const TableLinkCell = ({ pageUrl, label }: TableLinkCellProps) => (
  <Link className="c-table__cell-link-primary" href={pageUrl} data-selection-invoke={true}>
    {label}
  </Link>
)

export default TableLinkCell