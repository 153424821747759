import { emmaApiService } from '.'
import { FAQ } from '@/features/support/types/faq'

const serviceWithTags = emmaApiService.enhanceEndpoints({ addTagTypes: ['Faqs'],  })

const supportServices = serviceWithTags.injectEndpoints({
  endpoints(build) {
    return {
      getFAQ: build.query<Array<FAQ>, void>({
        query: () => ({ url: '/faqs', method: 'GET' }),
        providesTags: ['Faqs'],
        keepUnusedDataFor: 600
      }),
      addFAQ: build.mutation<FAQ, FAQ>({
        query: (faq) => { 
          const data = {
            questionText: faq.questionText,
            questionResponse: faq.questionResponse,
            isVisible: faq.isVisible
          }
          return { url: '/faqs', method: 'POST', data }
        },
        invalidatesTags: ['Faqs'],
      }),
      deleteFAQ: build.mutation<void, string>({
        query: (id) => ({ url: `/faqs/${id}`, method: 'DELETE' }),
        invalidatesTags: ['Faqs']
      }),
      updateFAQ: build.mutation<void, FAQ>({
        query: (faq) => ({ url: `/faqs/${faq.id}`, method: 'PUT', data: faq }),
        invalidatesTags: ['Faqs']
      }),
    }
  },
})

export const { useGetFAQQuery, useUpdateFAQMutation, useDeleteFAQMutation, useAddFAQMutation } = supportServices
