import dayjs from '@/bootstrap/dayjs'
import useGetAllUpcomingEventsQuery from '@/features/events/helpers/useGetAllUpcomingEventsQuery'
import { Card, SideBarSection, SideBarSectionItem, ConditionalWrapper, SkeletonSideBarSectionItem } from '@/shared/components'
import ListExpand from '@/shared/components/List/components/ListExpand'
import useIsMobile from '@/shared/hooks/useIsMobile'
import { EDateFormat } from '@/shared/types/date'
import { nanoid } from '@reduxjs/toolkit'
import { useMemo } from 'react'

const ITEM_AMOUNT = 3

type EventSideSectionProps = {
  cardless?: boolean
}

const EventSideSection = ({ cardless = false }: EventSideSectionProps) => {
  const isMobile = useIsMobile('md')
  const { data, isError, isLoading, isUninitialized } = useGetAllUpcomingEventsQuery()

  const loading = isLoading || isUninitialized || !data
  const events = useMemo(() => (
    // eslint-disable-next-line no-nested-ternary
    [...Array(ITEM_AMOUNT)].map((_, i) => loading 
      ? <SkeletonSideBarSectionItem renderThirdRow key={nanoid(4)} />
      : data?.[i] ? (
        <SideBarSectionItem 
          key={data[i].id}
          datetime={dayjs.utc(data[i].startEventDate).tz(data[i].timezoneName).toDate()}
          dateFormat={EDateFormat['long-tz']}
          title={data[i].title}
          href={data[i].eventUrl}
          target="_blank"
        />
      ) : null
    )
  ), [loading, data])

  return (
    <ConditionalWrapper
      condition={!isMobile || cardless}
      wrapper={(children) => (
        <div aria-label='Events and Recordings'>
          {children}
        </div>
      )}
    >
      <SideBarSection
        headingText='Events and Recordings'
        expandLink={<ListExpand expandTitle="View all Events" href="/events" />}
      >
        {events}
      </SideBarSection>
    </ConditionalWrapper>
  )
}

export default EventSideSection