import { Breadcrumb, IBreadcrumbProps, IBreadcrumbItem } from '@fluentui/react'
import cn from 'classnames'
 
type IBreadcrumbPropsWithoutItems = Omit<IBreadcrumbProps, 'items'>
interface Props extends IBreadcrumbPropsWithoutItems {
  items: Array<IBreadcrumbItem>;
}

export default function DocumentBreadcrumbs(props: Props) {
  const { items, className } = props

  return (
    <Breadcrumb
      items={items}
      maxDisplayedItems={3}
      ariaLabel="Documents Breadcrumbs"
      className={cn('c-doc-breadcrumbs', className)}
      dividerAs={() => <span className='c-doc-breadcrumbs__divider be-breadcrumb-seperator'></span>}
      onRenderItemContent={(bci) => {
        return <span key={bci.key} className={cn('be', 'c-doc-breadcrumbs__content', { '--current': bci.isCurrentItem })}>{bci.text}</span>
      }}
    />
  )
}
