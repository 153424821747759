import { ELinks } from '@/shared/types/Links'
import { ILegacyAppDisplayRuleResult } from '@/shared/types/swagger'

type ExternalAppFile = {
  iconName?: string;
  title: string;
  url: string;
  description?: string;
}

export type ExternalAppChildContent = {
  description: string;
  files: Array<ExternalAppFile>;
}

const moreChildContent: Partial<Record<keyof ILegacyAppDisplayRuleResult, ExternalAppChildContent>> = {
  cAmPME: {
    description: 'CA mPME enables benchmarking of any private investment data set (from fund-level to entire portfolios) against public markets, providing context on what performance would have been had the cash flows been subject to public returns. mPME is patent pending.',
    files: [
      { iconName: 'TextDocument', title: 'CA mPME Introduction', url: 'https://cambridgeassociates.box.com/s/ofi1flm478naib2dfd6ax90cof6o44t8', description: 'This introductory piece provides an overview of some of the challenges in benchmarking private investment portfolios and an introduction to the Modified Public Market Equivalent methogology (CA mPME).' },
      { iconName: 'TextDocument', title: 'CA mPME Worksheet', url: 'https://cambridgeassociates.box.com/s/tg4xlpmyevav92pv5sp1bc9dxr8selpc', description: 'This worksheet enables a private-to-public comparison between any private data set and up to six public indices using a CA-developed public market equivalent methodology denoted mPME. mPME is patent pending.' },
      { iconName: 'TextDocument', title: 'CA mPME FAQ', url: 'https://cambridgeassociates.box.com/s/5gnec1hgeythvnwkdhnzo1sl25u4n5d1', description: 'This guide provides answers to frequently asked questions about CA Modified Public Market Equivalent (mPME).' },
    ]
  }
}

export default moreChildContent