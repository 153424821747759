import { CellClassParams, CellClickedEvent, ColDef, ColGroupDef, ICellRendererParams, ITooltipComp, ITooltipParams } from 'ag-grid-community'
import React from 'react'
import {
  COLUMN_CELL_CLASS,
  CURRENCY_COLUMN_CELL_CLASS,
  DATE_COLUMN_CELL_CLASS,
  DATE_TIME_COLUMN_CELL_CLASS,
  HTML_CELL_CLASS,
  PERCENTAGE_COLUMN_CELL_CLASS,
} from './GridExport'
import { ActionCellRenderer } from './cellFramework/ActionCellFramework'
import { CheckCellRenderer } from './cellFramework/CheckCellFramework'
import { CurrencyCellRenderer } from './cellFramework/CurrencyCellRenderer'
import {
  DateCellEditor,
  DateCellRenderer,
  DateTimeCellEditor,
  DateTimeCellRenderer,
} from './cellFramework/DateAndTimeCellFramework'
import { HtmlCellRenderer } from './cellFramework/HtmlCellRenderer'
import { LinkCellRenderer } from './cellFramework/LinkCellFramework'
import { PercentageCellRenderer } from './cellFramework/PercentageCellRenderer'
import { TextCellRenderer } from './cellFramework/TextCellRenderer'
import { ExpandButtonCellRenderer } from './cellFramework/ExpandButtonCellRenderer'

export interface IDataGridCellStyleParams {
  value: any
  valueFormatted: any
  data: any
  rowIndex: number
}

export interface IDataGridColumnProps {
  field?: string
  valueGetter?: (row: any) => any
  title?: string
  sortAt?: number
  sort?: string
  editable?: boolean
  width?: number
  hide?: boolean
  flex?: number
  headerCheckboxSelection?: boolean
  headerCheckboxSelectionFilteredOnly?: boolean
  checkboxSelection?: boolean
  disableFilterColumn?: boolean
  disableWidth?: boolean
  comparator?: (value1: any, value2: any) => number
  pinned?: 'left' | 'right' | ''
  lockPosition?: boolean
  lockPinned?: boolean
  customFilter?: string[]
  cellClassRules?: CellClassRules
  cellClass?: ((params: any) => string) | string | string[]
  tooltipField?: string
  tooltipComponent?: (new () => ITooltipComp) | string
  cellStyle?: (params: IDataGridCellStyleParams) => any
  headerClass?: string
  onCellClicked?: (event: CellClickedEvent) => void
  headerName?: string
  resizable?: boolean
  tooltipValueGetter?: (params: ITooltipParams) => string | any
}

export interface CellClassRules {
  [cssClassName: string]: (((params: CellClassParams) => boolean) | string)
}

export const DEFAULT_COLUMNDEFS: ColDef = {
  floatingFilter: true,
  suppressMenu: true,
  sortable: true,
  resizable: true,
  autoHeight: true,
  hide: false,
  cellClass: COLUMN_CELL_CLASS,
}

export const PLACEHOLDER = (
  <p>
    ColumnPlaceholder please use within DataGrid.tsx as it will know what to do
    with me
  </p>
)

/////////////////////////////////////////////////////////////////////////////////
////////////////// Text
/////////////////////////////////////////////////////////////////////////////////
export interface ITextColumnProps<TRow> extends IDataGridColumnProps {
  cellClass: string,
  tooltipValueGetter?: (params: ITooltipParams) => string | any
}
export function TextColumn(props: IDataGridColumnProps) {
  return PLACEHOLDER
}
TextColumn.columnDefs = <TRow extends any>(
  props: ITextColumnProps<TRow>,
  row: ICellRendererParams
): ColDef => ({
  ...DEFAULT_COLUMNDEFS,
  filter: !props.disableFilterColumn ? 'agTextColumnFilter' : false,
  floatingFilter: !props.disableFilterColumn,
  cellRendererFramework: TextCellRenderer,
  cellRendererParams: { ...props },
  cellClassRules: props.cellClassRules,
  cellClass: props.cellClass,
  headerClass: props.headerClass,
  onCellClicked: props.onCellClicked,
  resizable: props.resizable,
  headerName: props.headerName,
  tooltipValueGetter: props.tooltipValueGetter,
})

/////////////////////////////////////////////////////////////////////////////////
////////////////// Date
/////////////////////////////////////////////////////////////////////////////////
export interface IDataGridDateColumnProps<TRow> extends IDataGridColumnProps {
  dateFormat?: string
}
export function DateColumn<TRow>(props: IDataGridDateColumnProps<TRow>) {
  return PLACEHOLDER
}
DateColumn.columnDefs = <TRow extends any>(
  props: IDataGridDateColumnProps<TRow>
): ColDef => ({
  ...DEFAULT_COLUMNDEFS,
  filter: !props.disableFilterColumn ? 'agDateColumnFilter' : false,
  cellEditorFramework: DateCellEditor,
  cellRendererFramework: DateCellRenderer,
  cellRendererParams: {
    dateFormat: props.dateFormat,
  },
  floatingFilter: !props.disableFilterColumn,
  floatingFilterComponent: !props.disableFilterColumn
    ? 'dateFloatingFilter'
    : undefined,
  floatingFilterComponentParams: !props.disableFilterColumn
    ? {
        showTimeInput: false,
        showFilterOptions: false,
        suppressFilterButton: true, // ag-grid
      }
    : undefined,
  cellClass: DATE_COLUMN_CELL_CLASS,
  cellClassRules: props.cellClassRules
})

/////////////////////////////////////////////////////////////////////////////////
////////////////// DateTime
/////////////////////////////////////////////////////////////////////////////////
export function DateTimeColumn(props: IDataGridColumnProps) {
  return PLACEHOLDER
}
DateTimeColumn.columnDefs = <TRow extends any>(
  props: IDataGridColumnProps
): ColDef => ({
  ...DEFAULT_COLUMNDEFS,
  filter: !props.disableFilterColumn ? 'agDateColumnFilter' : false,
  cellEditorFramework: DateTimeCellEditor,
  cellRendererFramework: DateTimeCellRenderer,
  floatingFilter: !props.disableFilterColumn,
  floatingFilterComponent: !props.disableFilterColumn
    ? 'dateFloatingFilter'
    : undefined,
  floatingFilterComponentParams: !props.disableFilterColumn
    ? {
        showTimeInput: false,
        showFilterOptions: false,
        suppressFilterButton: true, // ag-grid
      }
    : undefined,
  cellClass: DATE_TIME_COLUMN_CELL_CLASS,
  cellClassRules: props.cellClassRules
})

/////////////////////////////////////////////////////////////////////////////////
////////////////// Check
/////////////////////////////////////////////////////////////////////////////////
export function CheckColumn(props: IDataGridColumnProps) {
  return PLACEHOLDER
}
CheckColumn.columnDefs = <TRow extends any>(
  props: IDataGridColumnProps
): ColDef => ({
  ...DEFAULT_COLUMNDEFS,
  filter: false,
  floatingFilter: !props.disableFilterColumn,
  cellRendererFramework: CheckCellRenderer,
})

/////////////////////////////////////////////////////////////////////////////////
////////////////// Currency
/////////////////////////////////////////////////////////////////////////////////
export interface CurrencyColumnProps extends IDataGridColumnProps {
  getCurrencySymbol?: (data: any) => string
  getCurrency?: (data: any) => string
  decimalScale?: number
  negativeNumberClassName?: string
}
export function CurrencyColumn(props: CurrencyColumnProps) {
  return PLACEHOLDER
}
CurrencyColumn.columnDefs = <TRow extends any>(
  props: CurrencyColumnProps
): ColDef => ({
  ...DEFAULT_COLUMNDEFS,
  filter: 'agNumberColumnFilter',
  floatingFilter: !props.disableFilterColumn,
  cellRendererFramework: CurrencyCellRenderer,
  cellClass: CURRENCY_COLUMN_CELL_CLASS,
  cellClassRules: props.cellClassRules,
  headerClass: 'text-right ' + props.headerClass,
  cellRendererParams: {
    getCurrencySymbol: props.getCurrencySymbol,
    getCurrency: props.getCurrency, 
    decimalScale: props.decimalScale,
    negativeNumberClassName: props.negativeNumberClassName,
  }
})

/////////////////////////////////////////////////////////////////////////////////
////////////////// Percentage
/////////////////////////////////////////////////////////////////////////////////
export function PercentageColumn(props: IDataGridColumnProps) {
  return PLACEHOLDER
}
PercentageColumn.columnDefs = <TRow extends any>(
  props: IDataGridColumnProps
): ColDef => ({
  ...DEFAULT_COLUMNDEFS,
  filter: 'agNumberColumnFilter',
  floatingFilter: !props.disableFilterColumn,
  cellRendererFramework: PercentageCellRenderer,
  cellRendererParams: { medians: props?.customFilter },
  cellClass: PERCENTAGE_COLUMN_CELL_CLASS,
  cellClassRules: props.cellClassRules,
  headerClass: 'text-right ' + props.headerClass
})

/////////////////////////////////////////////////////////////////////////////////
////////////////// Link
/////////////////////////////////////////////////////////////////////////////////
export interface IDataGridLinkColumnProps<TRow> extends IDataGridColumnProps {
  onGenerateLink?: (row: TRow) => string
  onClick?: (row: TRow) => void
  tooltip?: string
  disabled?: (row: TRow) => boolean
}

export function LinkColumn<TRow>(props: IDataGridLinkColumnProps<TRow>) {
  return PLACEHOLDER
}
LinkColumn.columnDefs = <TRow extends any>(
  props: IDataGridLinkColumnProps<TRow>
): ColDef => ({
  ...DEFAULT_COLUMNDEFS,
  filter: !props.disableFilterColumn ? 'agTextColumnFilter' : false,
  floatingFilter: !props.disableFilterColumn,
  cellClass: props.cellClass,
  cellRendererFramework: LinkCellRenderer,
  cellRendererParams: {
    onGenerateLink: props.onGenerateLink,
    onClick: props.onClick,
    tooltip: props.tooltip,
    disabled: props.disabled,
  }
})

/////////////////////////////////////////////////////////////////////////////////
////////////////// Action
/////////////////////////////////////////////////////////////////////////////////
export interface IActionColumn<TRow> {
  label: string
  buttonColor: string
  onClick: (row: TRow) => void
  hidden?: boolean | ((row: TRow) => boolean)
  tooltip?: string
  icon?: any
  disabled?: (row: TRow) => boolean
}

export interface IDataGridActionColumnProps<TRow> extends IDataGridColumnProps {
  actions?: IActionColumn<TRow>[]
}

export function ActionColumn<TRow>(props: IDataGridActionColumnProps<TRow>) {
  return PLACEHOLDER
}
ActionColumn.columnDefs = <TRow extends any>(
  props: IDataGridActionColumnProps<TRow>
): ColDef => ({
  ...DEFAULT_COLUMNDEFS,
  filter: false,
  floatingFilter: false,
  cellRendererFramework: ActionCellRenderer,
  cellRendererParams: {
    actions: props.actions,
  },
})

/////////////////////////////////////////////////////////////////////////////////
////////////////// HTML Column
/////////////////////////////////////////////////////////////////////////////////
export function HtmlColumn(props: IDataGridColumnProps) {
  return PLACEHOLDER
}
HtmlColumn.columnDefs = <TRow extends any>(
  props: IDataGridColumnProps
): ColDef =>
  ({
    ...DEFAULT_COLUMNDEFS,
    autoHeight: true,
    filter: !props.disableFilterColumn ? 'agTextColumnFilter' : false,
    floatingFilter: !props.disableFilterColumn,
    cellRendererFramework: HtmlCellRenderer,
    cellClass: HTML_CELL_CLASS,
    disableWidth: true,
  } as ColDef)

/////////////////////////////////////////////////////////////////////////////////
////////////////// Auto Group
/////////////////////////////////////////////////////////////////////////////////
export interface IDataGridAutoGroupColumnProps<TRow> extends IDataGridColumnProps {
  autoGroupCellRenderer?: any
  cellClass: string,
  frameworkComponent?: (props: ITooltipParams) => any
}
export function AutoGroupColumn<TRow>(props: IDataGridAutoGroupColumnProps<TRow>) {
  return PLACEHOLDER
}
AutoGroupColumn.columnDefs = <TRow extends any>(
  props: IDataGridAutoGroupColumnProps<TRow>
): ColDef => ({
  ...DEFAULT_COLUMNDEFS,
  filter: !props.disableFilterColumn ? 'agTextColumnFilter' : false,
  floatingFilter: !props.disableFilterColumn,
  cellRendererParams: {
    suppressCount: true,
    innerRenderer: props.autoGroupCellRenderer,
  },
  cellClassRules: props.cellClassRules,
  tooltipField: props.tooltipField,
  tooltipComponent: props.tooltipComponent,
  cellClass: props.cellClass
})

/////////////////////////////////////////////////////////////////////////////////
////////////////// MasterDetail
/////////////////////////////////////////////////////////////////////////////////
export interface IDataGridMasterDetailColumnProps<TRow>
  extends IDataGridColumnProps {
  children: any
  id?: string
  fetchMasterData: (row: TRow) => Promise<any[]>
}

export function MasterDetailColumn<TRow>(
  _props: IDataGridMasterDetailColumnProps<TRow>
) {
  return PLACEHOLDER
}
MasterDetailColumn.columnDefs = <TRow extends any>(props: any): ColDef => ({
  ...DEFAULT_COLUMNDEFS,
  cellRenderer: 'agGroupCellRenderer',
  filter: false,
  floatingFilter: false,
})

/////////////////////////////////////////////////////////////////////////////////
////////////////// Group
/////////////////////////////////////////////////////////////////////////////////
export interface IDataGridGroupColumnProps extends IDataGridColumnProps {
  children: any
  marryChildren?: boolean
  openByDefault?: boolean
}
export function GroupColumn(_props: IDataGridGroupColumnProps) {
  return PLACEHOLDER
}
GroupColumn.columnDefs = (props: any): ColGroupDef => ({
  marryChildren: props.marryChildren,
  openByDefault: props.openByDefault,
  children: React.Children.map(props.children, (child: any) => {
    const defaultProps = child.type.columnDefs(child.props)
    return {
        ...defaultProps,
        ...child.props,
        headerName: child.props.title,
        headerClass: defaultProps.headerClass + ' ' + child.props.headerClass
    }
  }),
})


/////////////////////////////////////////////////////////////////////////////////
////////////////// ExpandButtonColumn
/////////////////////////////////////////////////////////////////////////////////
export function ExpandButtonColumn(props: IDataGridColumnProps) {
  return PLACEHOLDER
}
ExpandButtonColumn.columnDefs = <TRow extends any>(
  props: ITextColumnProps<TRow>,
  row: ICellRendererParams
): ColDef => ({
  ...DEFAULT_COLUMNDEFS,
  filter: false,
  floatingFilter: false,
  cellRendererFramework: ExpandButtonCellRenderer,
  cellRendererParams: { ...props },
  cellClassRules: props.cellClassRules,
  cellClass: props.cellClass,
  headerClass: props.headerClass,
  onCellClicked: props.onCellClicked,
  resizable: props.resizable,
  headerName: props.headerName,
  tooltipValueGetter: props.tooltipValueGetter,
})
