import { ICellRendererParams } from 'ag-grid-community'
import React, { forwardRef, useImperativeHandle, useState } from 'react'
import {
  DateFormatter,
  DateTimeFormatter,
  DEFAULT_DATETIME_FORMAT,
  DEFAULT_DATE_FORMAT,
} from '../../../services/date/DateService'
import DatePicker from '../../date/datepicker/DatePicker'
import styles from '../../../components/date/datepicker/DatePicker.module.scss'
import UtilService from '../../../services/util/UtilService'

export const DateCellEditor = forwardRef(function DateCellEditor(props?: ICellRendererParams, ref?) {
    const [value, setValue] = useState(props?.value || undefined)
    const { colDef = {} } = props || {}
    const componentId = UtilService.getId(`${colDef.colId}_date_cell_editor`)
    useImperativeHandle(ref, () => {
      return {
        getValue: () => {
          return value
        },
        isPopup() {
          return true
        },
      }
    })
    return (
      <DatePicker
        className={styles.ag_grid_editor}
        value={value}
        dateFormat={DEFAULT_DATE_FORMAT}
        onValueChange={(newDate) => setValue(newDate)}
        id={componentId}
      />
    )
  }
)

export const DateCellRenderer = ({
  value,
  dateFormat,
}: {
  value: Date
  dateFormat: string
}) => {
  const formattedValue = DateFormatter(value, dateFormat)
  return (
    <div
      title={formattedValue}
      style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}
    >
      {formattedValue}
    </div>
  )
}

export const DateTimeCellEditor = forwardRef(function DateTimeCellEditor(props?: ICellRendererParams, ref?) {
    const [value, setValue] = useState(props?.value || undefined)
    const { colDef = {} } = props || {}
    const componentId = UtilService.getId(
      `${colDef.colId}_date_time_cell_editor`
    )
    useImperativeHandle(ref, () => {
      return {
        getValue: () => {
          return value
        },
        isPopup() {
          return true
        },
      }
    })
    return (
      <DatePicker
        className={styles.ag_grid_editor}
        value={value}
        dateFormat={DEFAULT_DATETIME_FORMAT}
        onValueChange={(newDate) => setValue(newDate)}
        showTimeInput={true}
        id={componentId}
      />
    )
  }
)

export const DateTimeCellRenderer = ({ value }: { value: Date }) => {
  const formattedValue = DateTimeFormatter(value)
  return (
    <div
      title={formattedValue}
      style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}
    >
      {formattedValue}
    </div>
  )
}
