import './PerformanceGridFootNote.scss'

export interface PerformanceGridFootNoteProps {
  hidden: boolean,
  showTitle: boolean,
  additionalClassName?: string,
}

export default function PerformanceGridFootNote({ hidden, showTitle, additionalClassName }: PerformanceGridFootNoteProps) {
  if (hidden) {
    return null
  }

  return (
    <>
      <div className={`ClientPortfolioPerformanceFootNote ${additionalClassName || ''}`}>
        <div className='content'>
          { showTitle ? (<div className='title'>Footnotes:</div>) : null }
          <div className='description'>* This row contains preliminary data.</div>
          <div className='description'>† This row contains proxy data.</div>
        </div>
      </div>
    </>
  )
}
