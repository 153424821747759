import React from 'react'
import { ICellRendererParams } from 'ag-grid-community'

export const HtmlCellRenderer = (props: ICellRendererParams) => {
    const { value } = props
    const formattedValue = value || ''
    return (
      <div style={{ whiteSpace: 'pre-wrap', wordBreak: 'break-all' }} dangerouslySetInnerHTML={{ __html: formattedValue }} />
    )
}
