 import NavMenu from '@/shared/components/NavMenu'
import { clientNavMenuItems, NavMenuItems } from '@/shared/constants/navMenuItems'
 
 interface Props {
   accountid: string;
 }
 
 export default function ClientNav({ accountid }: Props) {
   const menuItems = clientNavMenuItems(accountid)
   const items = [
     menuItems[NavMenuItems.ClientWelcome],
     menuItems[NavMenuItems.ClientTimeline],
     menuItems[NavMenuItems.ClientSupport],
   ]
   
   return (
     <NavMenu 
       className="c-client-mainnav"
       items={items}
     />
   )
 }
