import { CambridgeModal } from '@/shared/components'
import { IEvent } from '@/shared/types/swagger'
import { useCallback, useState } from 'react'
import CreateEventModalForm from './components/CreateEventModalForm'

type CreateEventModalProps = {
  onClose: () => void
  isOpen: boolean,
  goBack: () => void,
  goBackAriaLabel: string,
  /** Will be passed to form. If provided, the form will autofill with the selectedEvent values. */
  selectedEvent: IEvent
}

const CreateEventModal = ({ onClose, isOpen, goBack, goBackAriaLabel, selectedEvent }: CreateEventModalProps) => {
  const [step, setStep] = useState<number>(1)

  const handleBack = useCallback(
    () => {
      if (step > 1) {
        setStep((_step) => _step - 1)
      } else {
        goBack()
      }
    },
    [goBack, step],
  )

  return (
    <CambridgeModal 
      modalIsOpen={isOpen}
      close={onClose}
      goBack={handleBack}
      goBackAriaLabel={goBackAriaLabel}
      modalTitle={selectedEvent ? 'Edit Event' : 'Add a New Event'}
      closeAriaLabel='Close Add Event Modal'
    >
      <CreateEventModalForm 
        goBack={goBack} 
        selectedEvent={selectedEvent} 
        setStep={setStep}
        step={step}
      />
    </CambridgeModal>
  )
}

export default CreateEventModal