import { ReactElement } from 'react'
import { TextField as FluentTextField, ITextFieldProps } from '@fluentui/react'
import TextFieldDescription from './components/TextFieldDescription'

export interface CustomProps {
  description?: string | ReactElement;
}

type Props = Omit<ITextFieldProps, keyof CustomProps> & CustomProps

export default function TextField(props: Props) {
  const { description, ...restProps } = props
  const _props = restProps

  if (description) {
    _props.onRenderDescription = function renderTextFieldDescription() {
      return <TextFieldDescription description={description} />
    }
  }

  return (
    <FluentTextField
      {..._props} 
    />
  )
}
