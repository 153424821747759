import { appDispatch } from "@/bootstrap/redux";
import withPermission, { WithPermissionProps } from "@/features/auth/components/withPermission";
import { EUserCan } from "@/features/auth/types";
import { createUIMessage } from "@/features/uimessages/redux/operations";
import { CaOffice, useGetAllOfficesByAccountIdQuery, useSaveAccountOfficeMutation } from "@/shared/api/services/caOffficeService";
import { GeneralError, PostModal } from "@/shared/components";
import useIsMobile from "@/shared/hooks/useIsMobile";
import modalSlice from "@/shared/redux/modalSlice";
import { ComboBox, IComboBox, IComboBoxOption, IComboBoxStyles, IStackItemStyles, IStackTokens, MessageBarType, Stack } from "@fluentui/react";
import { useState } from "react";
import { useDispatch } from "react-redux";

export interface ManageRegionalOfficeProps extends WithPermissionProps {
  accountId: string;
}

const MODAL_PARAM_NAME = 'manageRegionalOfficeModal'
const UI_MESSAGE_DISMISS_TIMEOUT = 3000

const ManageRegionalOffice = ({ accountId }: ManageRegionalOfficeProps) => {
  const dispatch = useDispatch<appDispatch>()
  const isMobile = useIsMobile('md')
  const [selectedOffice, setSelectedOffice] = useState(undefined as CaOffice)
  const { data: caOffices, isFetching, isLoading, refetch, isError, isUninitialized } = useGetAllOfficesByAccountIdQuery(accountId)
  const [saveAccountOffice] = useSaveAccountOfficeMutation()
  const inProgress = isUninitialized || isFetching || isLoading


  // dropdown models
  const caOfficeList = caOffices || []
  const initialOffice = caOfficeList.find(o => !!o.isSelected) || caOfficeList.find(o => o.city === 'Boston')
  const caOfficeDropdownData = caOfficeList.map(o => ({
    key: `${o.caOfficeId}`,
    text: `${o.city}${o.region ? ', ' + o.region : ''}`
  })).sort((a, b) => {
    const nameA = a.text.toUpperCase();
    const nameB = b.text.toUpperCase();
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }
    return 0;
  })

  const caOfficeDropdownSelectedKey = `${initialOffice && initialOffice.caOfficeId || ''}`

  const stackToken: IStackTokens = { childrenGap: 16 }

  const comboBoxStackStyles: IStackItemStyles = {
    root: {
      width: isMobile ? '100%' : '80%'
    },
  }

  const comboBoxStyles: Partial<IComboBoxStyles> = { 
    root: {
      width: 250,
      outline: 'none',
    },
    optionsContainerWrapper: {
      maxHeight: 216,
      width: 250
    },
  };

  const handleModalOpen = () => {
    setTimeout(() => {
      const dropdownInput = document.querySelector('.c-manage-regional-office-modal .ca-office-dropdown-wrapper input')
      if (dropdownInput) {
        dropdownInput.setAttribute('disabled', 'disabled')
      }
    }, 1000)
  }

  const handleDropdownChange = (event: React.FormEvent<IComboBox>, option?: IComboBoxOption) => {
    setSelectedOffice(caOfficeList.find(o => `${o.caOfficeId}` === `${option.key}`))
  }

  const handleCloseClick = () => {
    setSelectedOffice(undefined)
    refetch()
  }

  const handleConfirmClick = () => {
    saveAccountOffice({
      accountId,
      caOfficeId: selectedOffice.caOfficeId,
    }).unwrap().then(o => {
      setSelectedOffice(undefined)
      dispatch(createUIMessage({ 
        key: 'saveRegionalOfficeSuccess', 
        content: 'Saved Regional Office successfully.',
        messageBarType: MessageBarType.success,
        autoDismissAfter: UI_MESSAGE_DISMISS_TIMEOUT,
      }));
      dispatch(modalSlice.actions.setModalStatus({ paramName: MODAL_PARAM_NAME, value: false }))
    }).catch(error => {
      dispatch(createUIMessage({ 
        key: 'saveRegionalOfficeFailure', 
        content: 'Failed to save Regional Office.',
        messageBarType: MessageBarType.error,
        autoDismissAfter: UI_MESSAGE_DISMISS_TIMEOUT,
      }));
    })
    
  }

  if (isError) {
    return (<GeneralError onClick={refetch} title='Manage Your Regional Office.' />)
  }

  const isSaveButtonDisabled = !selectedOffice || (initialOffice === selectedOffice)

  return (
    <>
      <PostModal
        modalClassName='c-manage-regional-office-modal'
        className='c-manage-regional-office-content'
        onOpen={handleModalOpen}
        onClose={handleCloseClick}
        onConfirmClick={handleConfirmClick} 
        primaryLabel="Save"
        openButtonLabel="Manage Your Regional Office"
        openButtonId="manage-regional-office-button"
        modalTitle="Manage Your Regional Office"
        shimmerIsEnabled={inProgress}
        paramName={MODAL_PARAM_NAME}
        primaryIsDisabled={isSaveButtonDisabled}
        ariaLabels={{
          openButton: 'Open',
          closeButton: 'Close' 
        }}
        footer={<div style={{ marginTop: '24px' }}></div>}
        description={
          <>
            <Stack className='ca-office-dropdown-wrapper' style={{ alignItems: 'flex-end' }} tokens={stackToken} horizontal={!isMobile}>
              <Stack.Item styles={comboBoxStackStyles}>
                <ComboBox
                  label="Select a CA Office"
                  defaultSelectedKey={caOfficeDropdownSelectedKey}
                  options={caOfficeDropdownData}
                  styles={comboBoxStyles}
                  autoComplete='off'
                  allowFreeform={false}
                  useComboBoxAsMenuWidth={true}
                  onChange={handleDropdownChange}
                />
              </Stack.Item>
            </Stack>
          </>
        }
      />
    </>
  )
}

export default withPermission(EUserCan.Edit_OnboardingDashboard)(ManageRegionalOffice)