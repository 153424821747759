import { sanitize } from 'dompurify'
import { tryAppendEllipsis } from '.'
import { DEFAULT_MAX_BODY_CHAR_COUNT } from '../constants'

export default function getHTMLContentWithEllipses(text: string, maxChars?: number): string {
  if (!text) return ''

  const _maxChars = maxChars ?? DEFAULT_MAX_BODY_CHAR_COUNT
  const sanitizedBody = sanitize(text)
  // create DOM for parsing
  const parent = document.createElement('DIV')
  parent.innerHTML = sanitizedBody
  // determine if truncation is necessary
  const shouldTruncate = parent.textContent.length > _maxChars
  if (!shouldTruncate) {
    return text
  }
  const accumulator = 0
  tryAppendEllipsis(accumulator, parent, _maxChars)
  
  return parent.innerHTML
}