import { Chart } from 'chart.js'
import { getChartPlugin } from '../../utils/ChartJS.helper'

export interface DonutChartCenterTextPluginOptions {
  showCenterText: boolean
  getCenterText?: () => {
    text: string,
    font?: {
      size: string,
      family: string,
      color?: string,
    } 
  }
}

export const DonutChartCenterTextPlugin = {
  id: 'DonutChartCenterTextPlugin',
  afterDraw: (chart: Chart, args, options) => {
    const plugin = getChartPlugin(chart, 'DonutChartCenterTextPlugin') as DonutChartCenterTextPluginOptions
    
    if (plugin) {
      const { showCenterText } = plugin

      if (showCenterText) {
        const { chartArea } = chart
        
        if (chartArea) {
          const { getCenterText } = plugin
          if (getCenterText) {
            const centerText = getCenterText()
            const { font } = centerText
            const { top, bottom, left, right } = chartArea

            // calculates position for center text
            const topPosition =  (top || 0) + (((bottom || 0) - (top || 0)) / 2)
            const leftPosition =  (left || 0) + (((right || 0) - (left || 0)) / 2)

            // canvas context settings
            const { ctx } = chart
            const contextFont = ctx.font
            const contextFillStyle = ctx.fillStyle

            // assigns center font style for canvas context
            if (font) {
              ctx.font = `${font.size} ${font.family}`
              if (font.color) {
                ctx.fillStyle = font.color
              }
            }

            // applies center text to Donut Chart
            ctx.textAlign = 'center'
            // ctx.textBaseline = ''
            ctx.fillText(centerText.text, leftPosition, topPosition)

            // restores original context font
            ctx.font = contextFont
            ctx.fillStyle = contextFillStyle
          }
        }
      }
    }
  },
}