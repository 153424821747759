import { PersistState } from 'redux-persist'
import { RootState } from '.'

type PersistedRootState = RootState & { _persist: PersistState; }

export const migrations = {
  '0': (state: PersistedRootState) => {
    return {
      ...state, 
    }
  },
  '1': (state: PersistedRootState) => {
    return {
      ...state, 
    }
  },
}