import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { mainService } from '.'
import { IEmployeeAdminClientNavbarDisplayResult, ILegacyAppDisplayRuleResult, INavbarNavigationDisplayResult } from '@/shared/types/swagger'
import { ClientSettingsModel, DashboardSettings, DashboardView, getDefaultClientSettings } from '@/features/client/components/ClientSettings/ClientSettings.model'
import { selectAccountId, selectIsClientUser, selectIsImpersonating } from '@/features/auth/redux/authSelectors'

const legacyServiceWithTags = mainService.enhanceEndpoints({
  addTagTypes: [
    'DisplayRules',
    'NavbarRules',
    'EmployeeAdminClientNavbarRules',
    'ClientSettings',
    'DashboardView',
    'DashboardSettings',
  ],
})

const legacyService = legacyServiceWithTags.injectEndpoints({
  endpoints(build) {
    return {
      getDisplayRules: build.query<ILegacyAppDisplayRuleResult, void>({
        query: () => ({ url: '/LegacyApplication/DisplayRules', method: 'GET' }),
        providesTags: ['DisplayRules'],
      }),
      getNavbarRules: build.query<INavbarNavigationDisplayResult, string>({
        query: (CAID) => ({ url: `/LegacyApplication/NavbarRules?CAID=${CAID}`, method: 'GET' }),
        providesTags: ['NavbarRules'],
      }),
      getEmployeeClientNavbarRules: build.query<IEmployeeAdminClientNavbarDisplayResult, string>({
        query: (accountId) => ({ url: `/LegacyApplication/EmployeeAdminClientNavbarRules?accountId=${accountId}`, method: 'GET' }),
        providesTags: ['EmployeeAdminClientNavbarRules'],
      }),
      getClientSettingsByAccountId: build.query<ClientSettingsModel, string>({
        query: (accountId) => ({ url: `/LegacyApplication/AccountSettings/GetByAccountId/${accountId}`, method: 'GET' }),
        providesTags: ['ClientSettings'],
      }),
      getDashboardView: build.query<DashboardView, void>({
        query: () => ({ url: `/LegacyApplication/Dashboard/View`, method: 'GET' }),
        providesTags: ['DashboardView'],
      }),
      getDashboardSettings: build.query<DashboardSettings, void>({
        query: () => ({ url: `/LegacyApplication/Dashboard/Settings`, method: 'GET' }),
        providesTags: ['DashboardSettings'],
      }),
      getDashboardSettingsByAccountId: build.query<DashboardSettings, string>({
        query: (accountId) => ({ url: `/LegacyApplication/Dashboard/Settings/${accountId}`, method: 'GET' }),
        providesTags: (result, error, id) => {
          return [{
            type: 'DashboardSettings',
            id,
          }]
        },
      }),
      saveClientSettings: build.mutation<ClientSettingsModel, ClientSettingsModel>({
        query: (data) => ({
          url: '/LegacyApplication/SaveAccountSettings/Save',
          method: 'POST',
          data,
        }),
        invalidatesTags: ['NavbarRules', 'EmployeeAdminClientNavbarRules', 'ClientSettings', 'DashboardView', 'DashboardSettings'],
      }),
    }
  },
})

export const {
  useGetDisplayRulesQuery,
  useGetNavbarRulesQuery,
  useGetEmployeeClientNavbarRulesQuery,
  useGetClientSettingsByAccountIdQuery,
  useGetDashboardViewQuery,
  useGetDashboardSettingsQuery,
  useGetDashboardSettingsByAccountIdQuery,
  useSaveClientSettingsMutation,
} = legacyService

export function useGetDashboardSettings() {
  const isClientUser = useSelector(selectIsClientUser)
  const isImpersonating = useSelector(selectIsImpersonating)
  const accountId = useSelector(selectAccountId)
  const dashboardSettings = useGetDashboardSettingsQuery()
  const defaultSettings = getDefaultClientSettings(accountId, true)
  const dashboardSettingsByAccountId = useGetDashboardSettingsByAccountIdQuery(accountId, {
    skip: isClientUser || !isImpersonating || (isImpersonating && !accountId),
  })

  const combinedData = useMemo(() => {
    if (dashboardSettings?.isError) {
      return defaultSettings
    }

    return dashboardSettingsByAccountId?.data || dashboardSettings?.data || defaultSettings
  }, [dashboardSettingsByAccountId, dashboardSettings, defaultSettings])

  return {
    ...dashboardSettingsByAccountId,
    isFetching: dashboardSettings.isFetching || dashboardSettingsByAccountId.isFetching,
    isLoading: dashboardSettings.isLoading || dashboardSettingsByAccountId.isLoading,
    isError: dashboardSettings.isError || dashboardSettingsByAccountId.isError,
    data: combinedData,
  }
}

export function useGetPreviewDashboardSettingsByAccountId(previewAccountId: string) {
  const defaultSettings = getDefaultClientSettings(previewAccountId, true)
  const dashboardSettingsByAccountId = useGetDashboardSettingsByAccountIdQuery(previewAccountId, {
    skip: !previewAccountId,
  })

  const combinedData = useMemo(() => {
    if (dashboardSettingsByAccountId?.isError) {
      return defaultSettings
    }

    return dashboardSettingsByAccountId?.data || defaultSettings
  }, [dashboardSettingsByAccountId, defaultSettings])

  return {
    ...dashboardSettingsByAccountId,
    isFetching: dashboardSettingsByAccountId.isFetching,
    isLoading: dashboardSettingsByAccountId.isLoading,
    isError: dashboardSettingsByAccountId.isError,
    data: combinedData,
  }
}
