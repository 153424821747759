import { DEFAULT_COLUMNDEFS, IDataGridColumnProps, PLACEHOLDER } from '@/lib/common/components/grid/Columns'
import { ColDef, ICellRendererParams } from 'ag-grid-community'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import './ChangeMktValueColumn.scss'

export const CHANGE_MKT_VALUE_COLUMN_CELL_CLASS = 'ChangeMktValueCell'

export const ChangeMktValueCellrenderer = (props: PerformanceSleeveCellRendererProps) => {
  const { data, value, getTimePeriod } = props
  const timePeriod = getTimePeriod && getTimePeriod(data)

  const roundToOneDecimal = (value: number): string | number => {
    const str = value.toString()
    const decimalIndex = str.indexOf('.')
    
    if (decimalIndex === -1) {
        // If there is no decimal point, just return the number as is
        return str
    }
    
    // Extract the part of the string up to the desired decimal places
    const result = str.substring(0, decimalIndex + 2)
    return result
  }  

  if (value === null || value === undefined) {
    return (
      <div className='ChangeMktValueColumn'> --- </div>
    )
  }

  return (
    <div className='ChangeMktValueColumn'>
      <div className={ `ChangeMktValue ${value > 0 ? 'Positive' : 'Negative'}`  }>
        <FontAwesomeIcon className='market-value-marker' icon={['fas', `${value > 0 ? 'arrow-up' : 'arrow-down'}`]} />
        <span className='market-value-text'>{value > 0 ? roundToOneDecimal(value) : roundToOneDecimal(value).toString().replace('-', '')}%</span>
      </div>
      { timePeriod ? (
        <span className='time-period'>
          { timePeriod } 
        </span>
      ) : null }
    </div>
  )
}

export interface PerformanceSleeveCellRendererProps extends ICellRendererParams {
  getTimePeriod?: (row) => string,
}

export interface IChangeMktValueColumnProps<TRow> extends IDataGridColumnProps {
  getTimePeriod?: (row: any) => string,
}

export function ChangeMktValueColumn<TRow>(props: IChangeMktValueColumnProps<TRow>) {
  return PLACEHOLDER
}

ChangeMktValueColumn.columnDefs = <TRow extends any>(
  props: IChangeMktValueColumnProps<TRow>
): ColDef =>
  ({
    ...DEFAULT_COLUMNDEFS,
    autoHeight: true,
    filter: !props.disableFilterColumn ? 'agTextColumnFilter' : false,
    floatingFilter: !props.disableFilterColumn,
    cellRendererFramework: ChangeMktValueCellrenderer,
    cellClass: CHANGE_MKT_VALUE_COLUMN_CELL_CLASS,
    disableWidth: false,
    headerClass: 'text-right ' + props.headerClass,
    cellRendererParams: {
      getTimePeriod: props.getTimePeriod,
    }
  } as ColDef)
  