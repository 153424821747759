import { Card, MainLayout, SplitLayout } from '@/shared/components'
import EventList from '@/features/events/components/EventList'
import FeaturedEvent from '@/features/events/components/FeaturedEvent'
import EventModal from '@/features/events/components/EventModal'
import RecentInsights from '@/features/insight/components/RecentInsights'
import PodcastSideBar from '@/features/events/components/PodcastSideBar'

const Events = () => {
  const leftContent = (
    <>
      <div className='c-events__manage'>
        <EventModal />
      </div>
      <FeaturedEvent />
      <div className='c-events__events'>
        <Card size="large" ariaLabelledBy="event-list">
          <EventList />
        </Card>
      </div>
    </>
  )

  const rightContent = (
    <>
      <RecentInsights />
      <PodcastSideBar />
    </>
  )

  return (
    <MainLayout className='c-events'>
      <SplitLayout
        headerTitle='Events'
        leftContent={leftContent}
        rightContent={rightContent}
      />
    </MainLayout>
  )
}

export default Events