import { PartialTheme } from '@fluentui/react'

const theme: PartialTheme = {
  palette: {
  },
  defaultFontStyle: {
    fontFamily: 'proxima-nova, "Segoe UI", "Segoe UI Web (West European)", "Segoe UI", -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif', 
    fontWeight: 400
  },
  fonts: {

  },
  semanticColors: {
    bodyBackground: '#f3f2f1',
  }
} 

export default theme