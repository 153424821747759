import { selectAccountId } from '@/features/auth/redux/authSelectors';
import { useGetProfileQuery } from '@/shared/api/services/profileServices'
import { Persona } from '@fluentui/react'
import Skeleton from 'react-loading-skeleton'
import { useSelector } from 'react-redux';

const ProfileAvatar = () => {
  const reduxAccountId = useSelector(selectAccountId);
  const { data, isUninitialized, isLoading } = useGetProfileQuery({ accountId: reduxAccountId })

  if (isUninitialized || isLoading) {
    return (
      <div className='c-profile-persona'>
        <Skeleton width={80} height={80} circle/>
      </div>
    )
  }

  return (
    <div className='c-profile-persona'>
      <Persona className='c-profile-persona__image' imageInitials={`${data?.firstName[0] || ''}${data?.lastName[0] || ''}`} />
      <span className='c-profile-persona__text'>Hello again, {data?.firstName}!</span>
    </div>
  )
}

export default ProfileAvatar