import { ColumnActionsMode } from '@fluentui/react'
import { useContext, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { ColumnSettings, IModifiedColumn } from '../types'
import TableContext, { ITableContext } from '@/shared/components/Table/context'
import useColumnClick from './useColumnClick'

export default function usePaginatedTableColumns(columns: IModifiedColumn[]): [IModifiedColumn[], Record<string, ColumnSettings>] {
  const { t } = useTranslation()
  const sortDescText = t('common:sortDesc')
  const sortAscText = t('common:sortAsc')
  const tableContext: ITableContext = useContext(TableContext)
  const { sortField, sortOrder } = tableContext
  const initialSettings: Record<string, ColumnSettings> = sortField.value ? {
    [sortField.value as string]: {
      fieldName: sortField.value as string,
      sortKey: null,
      isSorted: true,
      isSortedDescending: sortOrder.value === 'desc',
    }
  } : {}
  
  const { handleColumnClick, settings } = useColumnClick(initialSettings)

  const propColumns = useMemo((): Array<IModifiedColumn> => {
    return columns.map((col) => {
      const _col = { ...col }
      if (_col.fieldName === sortField.value) {
        _col.isSorted = true
        _col.isSortedDescending = sortOrder.value === 'desc'
      }
      if (_col.columnActionsMode !== ColumnActionsMode.disabled) {
        _col.sortAscendingAriaLabel = sortAscText
        _col.sortDescendingAriaLabel = sortDescText
        _col.onColumnClick = _col.onColumnClick || handleColumnClick
      }
      return _col
    })
  }, [columns, handleColumnClick, sortAscText, sortDescText, sortField.value, sortOrder.value])

  const modifiedColumns = useMemo((): Array<IModifiedColumn> => {
    return propColumns.map((col) => {
      if (settings[col.fieldName]) {
        return { ...col, ...settings[col.fieldName] }
      }
      return col
    })
  }, [settings, propColumns])

  return [modifiedColumns, settings]
}