import Skeleton from 'react-loading-skeleton'

type SkeletonSideBarSectionItemProps = {
  renderThirdRow?: boolean
}

const SkeletonSideBarSectionItem = ({ renderThirdRow }: SkeletonSideBarSectionItemProps) => {
  return (
    <div className='c-skeleton-section' role='presentation'>
      <Skeleton className='c-skeleton-section__image' width={100} />
      <div className='c-skeleton-section__content'>
        <Skeleton className='c-skeleton-section__content__first' />
        <Skeleton className='c-skeleton-section__content__second'  width={82} height={14} />
        { renderThirdRow ? <Skeleton className='c-skeleton-section__content__third' height={24} /> : null }
      </div>
    </div>
  )
}

export default SkeletonSideBarSectionItem