/**
 * const zones = ['Europe/Andorra', 'Asia/Dubai']
 * zones.map(
 *   zone => ({
 *     name: zone,
 *     offset: Intl.DateTimeFormat(Intl.DateTimeFormat().resolvedOptions().locale, {
 *       timeZoneName: 'short',
 *       timeZone: zone,
 *     }).formatToParts().find(part => part.type === 'timeZoneName').value
 *   })
 * )
 */

export type Zone = {
  key: string;
  text: string
}

export const zones: Array<Zone> = [
  { key: 'Pacific/Kiritimati', text: '(GMT+14:00) Line Islands Time (Pacific/Kiritimati)' },
  { key: 'Pacific/Chatham', text: '(GMT+12:45) Chatham Standard Time (Pacific/Chatham)' },
  { key: 'Pacific/Auckland', text: '(GMT+12:00) New Zealand Standard Time (Pacific/Auckland)' },
  { key: 'Pacific/Enderbury', text: '(GMT+13:00) Phoenix Islands Time (Pacific/Enderbury)' },
  { key: 'Pacific/Fiji', text: '(GMT+12:00) Fiji Standard Time (Pacific/Fiji)' },
  { key: 'Pacific/Tongatapu', text: '(GMT+13:00) Tonga Standard Time (Pacific/Tongatapu)' },
  { key: 'Asia/Kamchatka', text: '(GMT+12:00) Petropavlovsk-Kamchatski Standard Time (Asia/Kamchatka)' },
  { key: 'Australia/Lord_Howe', text: '(GMT+10:30) Lord Howe Standard Time (Australia/Lord_Howe)' },
  { key: 'Australia/Sydney', text: '(GMT+10:00) Australian Eastern Standard Time (Australia/Sydney)' },
  { key: 'Pacific/Guadalcanal', text: '(GMT+11:00) Solomon Islands Time (Pacific/Guadalcanal)' },
  { key: 'Pacific/Norfolk', text: '(GMT+11:00) Norfolk Island Standard Time (Pacific/Norfolk)' },
  { key: 'Australia/Adelaide', text: '(GMT+09:30) Australian Central Standard Time (Australia/Adelaide)' },
  { key: 'Australia/Brisbane', text: '(GMT+10:00) Australian Eastern Standard Time (Australia/Brisbane)' },
  { key: 'Australia/Darwin', text: '(GMT+09:30) Australian Central Standard Time (Australia/Darwin)' },
  { key: 'Asia/Seoul', text: '(GMT+09:00) Korean Standard Time (Asia/Seoul)' },
  { key: 'Asia/Tokyo', text: '(GMT+09:00) Japan Standard Time (Asia/Tokyo)' },
  { key: 'Asia/Hong_Kong', text: '(GMT+08:00) Hong Kong Standard Time (Asia/Hong_Kong)' },
  { key: 'Asia/Kuala_Lumpur', text: '(GMT+08:00) Malaysia Time (Asia/Kuala_Lumpur)' },
  { key: 'Asia/Manila', text: '(GMT+08:00) Philippine Standard Time (Asia/Manila)' },
  { key: 'Asia/Shanghai', text: '(GMT+08:00) China Standard Time (Asia/Shanghai)' },
  { key: 'Asia/Singapore', text: '(GMT+08:00) Singapore Standard Time (Asia/Singapore)' },
  { key: 'Asia/Taipei', text: '(GMT+08:00) Taipei Standard Time (Asia/Taipei)' },
  { key: 'Australia/Perth', text: '(GMT+08:00) Australian Western Standard Time (Australia/Perth)' },
  { key: 'Asia/Bangkok', text: '(GMT+07:00) Indochina Time (Asia/Bangkok)' },
  { key: 'Asia/Ho_Chi_Minh', text: '(GMT+07:00) Indochina Time (Asia/Ho_Chi_Minh)' },
  { key: 'Asia/Jakarta', text: '(GMT+07:00) Western Indonesia Time (Asia/Jakarta)' },
  { key: 'Asia/Rangoon', text: '(GMT+06:30) Myanmar Time (Asia/Rangoon)' },
  { key: 'Asia/Dhaka', text: '(GMT+06:00) Bangladesh Standard Time (Asia/Dhaka)' },
  { key: 'Asia/Kathmandu', text: '(GMT+05:45) Nepal Time (Asia/Kathmandu)' },
  { key: 'Asia/Colombo', text: '(GMT+05:30) India Standard Time (Asia/Colombo)' },
  { key: 'Asia/Kolkata', text: '(GMT+05:30) India Standard Time (Asia/Kolkata)' },
  { key: 'Asia/Karachi', text: '(GMT+05:00) Pakistan Standard Time (Asia/Karachi)' },
  { key: 'Asia/Tashkent', text: '(GMT+05:00) Uzbekistan Standard Time (Asia/Tashkent)' },
  { key: 'Asia/Yekaterinburg', text: '(GMT+05:00) Yekaterinburg Standard Time (Asia/Yekaterinburg)' },
  { key: 'Asia/Kabul', text: '(GMT+04:30) Afghanistan Time (Asia/Kabul)' },
  { key: 'Asia/Baku', text: '(GMT+04:00) Azerbaijan Standard Time (Asia/Baku)' },
  { key: 'Asia/Dubai', text: '(GMT+04:00) Gulf Standard Time (Asia/Dubai)' },
  { key: 'Asia/Tbilisi', text: '(GMT+04:00) Georgia Standard Time (Asia/Tbilisi)' },
  { key: 'Asia/Yerevan', text: '(GMT+04:00) Armenia Standard Time (Asia/Yerevan)' },
  { key: 'Asia/Tehran', text: '(GMT+04:30) Iran Daylight Time (Asia/Tehran)' },
  { key: 'Africa/Nairobi', text: '(GMT+03:00) East Africa Time (Africa/Nairobi)' },
  { key: 'Asia/Baghdad', text: '(GMT+03:00) Arabian Standard Time (Asia/Baghdad)' },
  { key: 'Asia/Kuwait', text: '(GMT+03:00) Arabian Standard Time (Asia/Kuwait)' },
  { key: 'Asia/Riyadh', text: '(GMT+03:00) Arabian Standard Time (Asia/Riyadh)' },
  { key: 'Europe/Istanbul', text: '(GMT+03:00) GMT+03:00 (Europe/Istanbul)' },
  { key: 'Europe/Minsk', text: '(GMT+03:00) Moscow Standard Time (Europe/Minsk)' },
  { key: 'Europe/Moscow', text: '(GMT+03:00) Moscow Standard Time (Europe/Moscow)' },
  { key: 'Africa/Cairo', text: '(GMT+02:00) Eastern European Standard Time (Africa/Cairo)' },
  { key: 'Africa/Johannesburg', text: '(GMT+02:00) South Africa Standard Time (Africa/Johannesburg)' },
  { key: 'Asia/Beirut', text: '(GMT+03:00) Eastern European Summer Time (Asia/Beirut)' },
  { key: 'Asia/Jerusalem', text: '(GMT+03:00) Israel Daylight Time (Asia/Jerusalem)' },
  { key: 'Europe/Athens', text: '(GMT+03:00) Eastern European Summer Time (Europe/Athens)' },
  { key: 'Europe/Bucharest', text: '(GMT+03:00) Eastern European Summer Time (Europe/Bucharest)' },
  { key: 'Europe/Helsinki', text: '(GMT+03:00) Eastern European Summer Time (Europe/Helsinki)' },
  { key: 'Africa/Algiers', text: '(GMT+01:00) Central European Standard Time (Africa/Algiers)' },
  { key: 'Africa/Casablanca', text: '(GMT+01:00) GMT+01:00 (Africa/Casablanca)' },
  { key: 'Europe/Amsterdam', text: '(GMT+02:00) Central European Summer Time (Europe/Amsterdam)' },
  { key: 'Europe/Berlin', text: '(GMT+02:00) Central European Summer Time (Europe/Berlin)' },
  { key: 'Europe/Brussels', text: '(GMT+02:00) Central European Summer Time (Europe/Brussels)' },
  { key: 'Europe/Paris', text: '(GMT+02:00) Central European Summer Time (Europe/Paris)' },
  { key: 'Europe/Prague', text: '(GMT+02:00) Central European Summer Time (Europe/Prague)' },
  { key: 'Europe/Rome', text: '(GMT+02:00) Central European Summer Time (Europe/Rome)' },
  { key: 'Europe/Dublin', text: '(GMT+01:00) Irish Standard Time (Europe/Dublin)' },
  { key: 'Europe/Lisbon', text: '(GMT+01:00) Western European Summer Time (Europe/Lisbon)' },
  { key: 'Europe/London', text: '(GMT+01:00) British Summer Time (Europe/London)' },
  { key: 'GMT', text: '(GMT+00:00) UTC (GMT)' },
  { key: 'America/Scoresbysund', text: '(GMT+00:00) East Greenland Summer Time (America/Scoresbysund)' },
  { key: 'Atlantic/Azores', text: '(GMT+00:00) Azores Summer Time (Atlantic/Azores)' },
  { key: 'Atlantic/Cape_Verde', text: '(GMT-01:00) Cape Verde Standard Time (Atlantic/Cape_Verde)' },
  { key: 'Atlantic/South_Georgia', text: '(GMT-02:00) South Georgia Time (Atlantic/South_Georgia)' },
  { key: 'America/Argentina/Buenos_Aires', text: '(GMT-03:00) Argentina Standard Time (America/Argentina/Buenos_Aires)' },
  { key: 'America/Santiago', text: '(GMT-04:00) Chile Standard Time (America/Santiago)' },
  { key: 'America/Sao_Paulo', text: '(GMT-03:00) Brasilia Standard Time (America/Sao_Paulo)' },
  { key: 'America/St_Johns', text: '(GMT-02:30) Newfoundland Daylight Time (America/St_Johns)' },
  { key: 'America/Caracas', text: '(GMT-04:00) Venezuela Time (America/Caracas)' },
  { key: 'America/Halifax', text: '(GMT-03:00) Atlantic Daylight Time (America/Halifax)' },
  { key: 'America/Puerto_Rico', text: '(GMT-04:00) Atlantic Standard Time (America/Puerto_Rico)' },
  { key: 'Atlantic/Bermuda', text: '(GMT-03:00) Atlantic Daylight Time (Atlantic/Bermuda)' },
  { key: 'America/Bogota', text: '(GMT-05:00) Colombia Standard Time (America/Bogota)' },
  { key: 'America/Indianapolis', text: '(GMT-04:00) Eastern Daylight Time (America/Indianapolis)' },
  { key: 'America/Lima', text: '(GMT-05:00) Peru Standard Time (America/Lima)' },
  { key: 'America/New_York', text: '(GMT-04:00) Eastern Daylight Time (America/New_York)' },
  { key: 'America/Panama', text: '(GMT-05:00) Eastern Standard Time (America/Panama)' },
  { key: 'America/Chicago', text: '(GMT-05:00) Central Daylight Time (America/Chicago)' },
  { key: 'America/El_Salvador', text: '(GMT-06:00) Central Standard Time (America/El_Salvador)' },
  { key: 'America/Mexico_City', text: '(GMT-05:00) Central Daylight Time (America/Mexico_City)' },
  { key: 'America/Denver', text: '(GMT-06:00) Mountain Daylight Time (America/Denver)' },
  { key: 'America/Mazatlan', text: '(GMT-06:00) Mexican Pacific Daylight Time (America/Mazatlan)' },
  { key: 'America/Phoenix', text: '(GMT-07:00) Mountain Standard Time (America/Phoenix)' },
  { key: 'America/Los_Angeles', text: '(GMT-07:00) Pacific Daylight Time (America/Los_Angeles)' },
  { key: 'America/Tijuana', text: '(GMT-07:00) Pacific Daylight Time (America/Tijuana)' },
  { key: 'Pacific/Pitcairn', text: '(GMT-08:00) Pitcairn Time (Pacific/Pitcairn)' },
  { key: 'America/Anchorage', text: '(GMT-08:00) Alaska Daylight Time (America/Anchorage)' },
  { key: 'Pacific/Gambier', text: '(GMT-09:00) Gambier Time (Pacific/Gambier)' },
  { key: 'Pacific/Marquesas', text: '(GMT-09:30) Marquesas Time (Pacific/Marquesas)' },
  { key: 'America/Adak', text: '(GMT-09:00) Hawaii-Aleutian Daylight Time (America/Adak)' },
  { key: 'Pacific/Honolulu', text: '(GMT-10:00) Hawaii-Aleutian Standard Time (Pacific/Honolulu)' },
  { key: 'Pacific/Niue', text: '(GMT-11:00) Niue Time (Pacific/Niue)' },
  { key: 'Pacific/Pago_Pago', text: '(GMT-11:00) Samoa Standard Time (Pacific/Pago_Pago)' },
]
