import cn from 'classnames'
import { memo } from 'react'

type ArchiveTableRowProps = {
  children?: JSX.Element | JSX.Element[],
  className?: string,
  onClick?: () => void
  isHidden?: boolean
}

const ArchiveTableRow = ({ children, className, onClick, isHidden }: ArchiveTableRowProps) => {
  // Used for allowing the row to expand
  return (
    <tr aria-hidden={isHidden} onClick={onClick} data-is-focusable='true' className={cn('c-message-table-archived__table__body__row', className)}>
      {children}
    </tr>
  )
}

export default memo(ArchiveTableRow)