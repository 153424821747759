import { useGetArchivedMessagesByAccountIdQuery, useGetArchivedMessagesByRecipientIdQuery } from '@/shared/api/services/messageService'
import { Card, GeneralError, Section } from '@/shared/components'
import { FocusZone, FocusZoneDirection } from '@fluentui/react'
import Skeleton from 'react-loading-skeleton'
import ArchiveTableBody from '@/features/messages/components/MessagesTable/MessagesTableArchive/components/ArchiveTableBody'
import './ArchivedMessages.scss'

type MessagesTableArchiveProps = {
  accountid?: string
}

const ArchivedMessages = ({ accountid }: MessagesTableArchiveProps) => {
  const { data, isLoading, isUninitialized, isError, refetch } = useGetArchivedMessagesByAccountIdQuery(accountid)

  if (isError) return <GeneralError title='Failed to load recent documents' onClick={refetch} />
  // TODO: add loading to table
  if (isLoading || isUninitialized) return <Skeleton height={200} width="100%" />

  return (
    data.length !== 0 &&
    <Section className='c-message-table-archived' titleId='archivedMessages' title='Archived Messages'>
      <Card className='c-message-table-archived__card' ariaLabelledBy='archivedMessages'>
        <FocusZone
          as="table"
          aria-colcount={7}
          aria-readonly='true'
          aria-rowcount={2}
          role='grid'
          className='c-message-table-archived__table'
          direction={FocusZoneDirection.vertical}
          shouldEnterInnerZone={e => e.code === 'arrowRight'}
        >
          <colgroup>
            <col />
            <col />
            <col />
            <col />
            <col />
            <col />
            <col />
          </colgroup>
          <thead className='c-message-table-archived__table__header'>
            <tr>
              <th scope="col">Date</th>
              <th scope="col">From</th>
              <th scope="col">To</th>
              <th scope="col">Subject</th>
              <th scope="col">Action</th>
              <th scope="col">Notes</th>
              <th />
            </tr>
          </thead>
          <tbody className='c-message-table-archived__table__body'>
            {data.map((message) => (
              <ArchiveTableBody message={message} key={message.id} />
            ))}
          </tbody>
        </FocusZone>
      </Card>
    </Section>
  )
}

export default ArchivedMessages