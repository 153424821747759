import { useGetDisplayRulesQuery } from '@/shared/api/services/legacyService'
import { ILegacyAppDisplayRuleResult } from '@/shared/types/swagger'
import { displayKeyLinkMap } from '../constants/moreLinksDisplay'

type HasAccess = boolean
type IsAccessLoading = boolean
type ExtAppAccessReturn = [HasAccess, IsAccessLoading]

export default function useExtAppAccess(displayKey: keyof ILegacyAppDisplayRuleResult): ExtAppAccessReturn {
  const { data, isUninitialized, isLoading, isError } = useGetDisplayRulesQuery()

  if (!(displayKey in displayKeyLinkMap)) return [false, false]
  if (isLoading || isUninitialized || !data) return [true, true]
  if (isError) return [false, false]

  return [data[displayKey] as boolean, isLoading]
}