import  faqSlice, { selectQuestionBeingEdited } from '@/features/support/redux/faqSlice'
import { FAQ } from '@/features/support/types/faq'
import { TextField } from '@fluentui/react'
import { useDispatch, useSelector } from 'react-redux'

type FAQTableQuestionProps = {
  item: FAQ
  beingEdited: boolean
}

const FAQTableQuestion = ({ item, beingEdited }: FAQTableQuestionProps) => {
  const dispatch = useDispatch()
  const faqQuestion = useSelector(selectQuestionBeingEdited)

  const handleTextFieldChange = (ev, newValue) => {
    dispatch(faqSlice.actions.setQuestionData({
      key: 'questionText',
      newValue
    }))
  }

  const isEmpty = !(faqQuestion?.questionText?.replace?.(/\s/g, ''))

  if (beingEdited) {
    return (
      <TextField 
        onChange={handleTextFieldChange}
        value={faqQuestion.questionText}
        multiline
        rows={4}
        resizable={false}
        errorMessage={isEmpty ? 'Question is required' : undefined}
      />
    )
  }

  return (
    <div style={{ paddingTop: '8px' }} >{item.questionText}</div>
  )
}

export default FAQTableQuestion