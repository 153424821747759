import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { DefaultButton, Link, MessageBarType, PrimaryButton } from '@fluentui/react'
import { appDispatch } from '@/bootstrap/redux'
import { createUIMessage } from '@/features/uimessages/redux/operations'
import { selectPermissions } from '@/features/auth/redux/authSelectors'
import { EUserCan, Permission } from '@/features/auth/types'
import { useResetTermsOfServiceMutation } from '@/shared/api/services/crmService'
import { IClientContactViewModel } from '@/shared/types/swagger'
import Confirmation from '@/shared/components/Confirmation'
import getName from '@/features/client/helpers/getName'
import { DEFAULT_FULL_DATE_FORMAT, formatDate } from '@/lib/common/services/date/DateService'

export interface TosDateProps {
  contact: IClientContactViewModel,
  accountId: string
}

export default function TosDate({ contact, accountId }: TosDateProps) {
  const { termsOfServiceHistory } = contact
  const { contactId, termsOfServiceAcceptedDate } = termsOfServiceHistory
  const permissions: Array<Permission> = useSelector(selectPermissions)
  const canResetContactTermsOfService = permissions.includes(EUserCan.Reset_Contact_TermsOfService)
  const dispatch = useDispatch<appDispatch>()
  const date = termsOfServiceAcceptedDate && new Date(termsOfServiceAcceptedDate)
  const datetime = date && formatDate(date, DEFAULT_FULL_DATE_FORMAT)
  const [isDisplayed, setIsDisplayed] = useState(termsOfServiceAcceptedDate && true)
  const [isDialogShown, setIsDialogShown] = useState(false)
  const [ resetTos ] = useResetTermsOfServiceMutation()
  const handleResetTos = () => {
    resetTos({ accountId: accountId, contactId: contactId }).unwrap().then(() => {
      setIsDisplayed(false)
      setIsDialogShown(false)
      dispatch(createUIMessage({
        key: 'resetTermsOfServiceSuccess',
        content: 'Reset Terms of Service agreement successfully.',
        messageBarType: MessageBarType.success,
        autoDismissAfter: 3000,
      }));
    }).catch(error => {
      dispatch(createUIMessage({
        key: 'resetTermsOfServiceFailure',
        content: 'Failed to reset Terms of Service agreement.',
        messageBarType: MessageBarType.error,
        autoDismissAfter: 3000,
      }));
    })
  }
  const renderDefault = () => (<DefaultButton onClick={() => setIsDialogShown(false)} text='No' />)
  const renderPrimary = () => (<PrimaryButton text='Yes' onClick={handleResetTos}/>)

  return (
    <>
      <div style={{ width: '75px' }}>
        {isDisplayed && (<>
          <p>{datetime}</p>
          {canResetContactTermsOfService && <Link
            className='centre'
            onClick={() => setIsDialogShown(true)}
          >Reset</Link>}
        </>)}
      </div>
      <Confirmation
        hidden={!isDialogShown}
        title='Reset Terms of Service Acceptance'
        subText={(<>{`Are you sure you want to reset the Terms of Service Acceptance for ${getName(contact)}?`}</>)}
        renderDefault={renderDefault}
        renderPrimary={renderPrimary}
        handleDismiss={() => setIsDialogShown(false)}
        modalClassName="c-invite-confirm-modal" />
    </>
  )
}