import { DefaultButton } from '@fluentui/react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import './ExpandCollapseSleeveFilter.scss'

export interface ExpandCollapseSleeveFilterProps {
  isExpanded: boolean,
  onFilter: (isExpanded: boolean) => void
}

const ExpandCollapseSleeveFilter = ({
  isExpanded,
  onFilter: onClick,
}: ExpandCollapseSleeveFilterProps) => {
  return (
    <div className='ExpandButton'>
      <DefaultButton className={`ExpandCollapseSleeveFilter ${isExpanded ? 'selected' : ''}`}
        onClick={() => onClick(!isExpanded) }
      >
        <FontAwesomeIcon className='icon' icon={['fas', `${ isExpanded ? 'angles-up' : 'angles-down'}`]} />
        <span className='button-label'>
          {isExpanded ? 'Collapse All' : 'Expand All'}
        </span>
      </DefaultButton>
    </div>
  )
}

export default ExpandCollapseSleeveFilter