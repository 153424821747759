import { Card, SideBarSection, SideBarSectionItem, SkeletonSideBarSectionItem } from '@/shared/components'
import { EDateFormat } from '@/shared/types/date'
import { Link } from '@fluentui/react'
import usePodcasts from './shared/hooks/usePodcasts'
import { IPodcast } from './shared/types'

export default function PodcastSideBar() {
  const { loading, error, data } = usePodcasts()

  if (error) return null
  if (loading) return (
    <SideBarSection headingText='Podcasts' expandLink={<Link disabled>View All Podcasts</Link>}>
      <SkeletonSideBarSectionItem />
      <SkeletonSideBarSectionItem />
      <SkeletonSideBarSectionItem />
    </SideBarSection>
  )

  return (
    <Card size="large" ariaLabelledBy="event-list">
      <SideBarSection
        expandLink={<Link href={data.url} target="_blank">View All Podcasts</Link>}
        headingText={`${data.title} Podcast`}
      >
        {data.items.map((podcast: IPodcast) => (
          <SideBarSectionItem
            key={podcast.title}
            datetime={podcast.datetime}
            dateFormat={EDateFormat.short}
            title={podcast.title}
            target="_blank"
            href={podcast.href}
            renderLHS={() => <img src={podcast.img.src} alt={podcast.img.alt} />}
          />
        ))}

      </SideBarSection>
    </Card>
  )
}