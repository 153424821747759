import React, { ReactElement } from 'react'
import TranslatedTextProps from './types'
import { useTranslation } from 'react-i18next'

const TranslatedText = ({
  textType = 'p',
  translationKey,
  className,
  namespace,
  additionalProps,
  translationDataVariable
}: TranslatedTextProps): ReactElement => {
  const { t } = useTranslation(namespace)
  if (!translationKey) {
    console.warn('Missing translation key!')
    return null
  }
  return React.createElement(
    textType,
    { className, ...additionalProps },
    t(translationKey, translationDataVariable)
  )
}

export default TranslatedText
