import { Stack } from '@fluentui/react'
import Skeleton from 'react-loading-skeleton'

const getRandomNumberBetween = (num1: number, num2: number) => {
  const diff = num2 - num1
  return Math.round(Math.random() * diff) + num1
}

export default function MoreLoading() {
  return (
    <>
      {[...new Array(3)].map((_, i) => (
        <div key={`more-skelly-section-${i}`}>
          {i !== 0 && <hr />}
          <Stack tokens={{ childrenGap: 12 }} horizontal style={{ marginBottom: 16 }}>
            <Stack.Item><Skeleton circle width={24} height={24} /></Stack.Item>
            <Stack.Item><Skeleton width={getRandomNumberBetween(120, 300)} height={24} /></Stack.Item>
          </Stack>
          <Stack tokens={{ childrenGap: 32 }} horizontal>
            {[...new Array(2)].map((_, i_l) => (
              <Stack.Item style={{ width: '100%' }} key={`more-skelly-section-link-${i_l}`}>
                <Skeleton width={getRandomNumberBetween(100, 220)} height={18} style={{ marginBottom: 8 }} />
                {[...new Array(getRandomNumberBetween(1,2))].map((_, i_p) => (
                  <Skeleton key={`more-skelly-section-paragraph-${i_p}`} width="100%" height={12} style={{ marginBottom: 4 }}/>
                )) }
                <Skeleton width={`${getRandomNumberBetween(10, 99)}%`} height={12} style={{ marginBottom: 4 }}/>
              </Stack.Item>
            ))}
          </Stack>
        </div>
      ))}
    </>
  )
}