import { RouteKey, routes } from '@/bootstrap/router'

export type PathState = RegExpExecArray['groups'] & {
  pathKey?: RouteKey;
  query?: string;
}

export default function matchRouteState() {
  const { pathname, search } = window.location
  let state: PathState = { query: search }

  const routeEntries = Object.entries(routes)
  for (let i = 0; i < routeEntries.length; i++) {
    const [key, routeConfig] = routeEntries[i]
    if (routeConfig.path === pathname) {
      state = {
        ...state,
        pathKey: key as RouteKey
      }
    }
    if ('pattern' in routeConfig) {
      const match = routeConfig.pattern.exec(pathname)
      if (!match) continue

      state = {
        ...state,
        ...match.groups,
      }
    }
  }

  return JSON.stringify(state)
}