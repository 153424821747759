import cn from 'classnames'
import { useLocation } from 'react-router-dom'
import usePagination from './hooks/usePagination'
import PaginationItem from './components/PaginationItem'
import PaginationEllipsis from './components/PaginationEllipsis'
import { useState } from 'react'

interface Props {
  /** default 'page'*/
  usePageParam?: boolean;
  pageParam?: number;
  paramName?: string;
  onPageChange?: (current: number) => void;
  maxShown: number;
  listCount: number;
  className?: string;
  ignoreQueryParams?: boolean;
}

function Pagination(props: Props) {
  const { 
    usePageParam = false,
    pageParam = 0,
    paramName = 'page', 
    onPageChange,
    className,
    listCount,
    maxShown,
    ignoreQueryParams
  } = props

  const loc = useLocation()
  const [current, setCurrent] = useState(ignoreQueryParams ? 1 : Number(new URLSearchParams(loc.search).get(paramName)) || 1)
  const totalPages = Math.ceil(listCount / maxShown)
  const isLastPage = current === totalPages
  const isFirstPage = current === 1
  const items = usePagination(totalPages, current)

  return (
    <nav role="navigation" aria-label="Pagination" className={cn('c-pagination-container', className)}>
      <ul className="c-pagination">
        {items.map((item, i) => {
          const isCurrent = usePageParam ? (item === pageParam) : (item === current)
          const pageSearch = new URLSearchParams(loc.search)
          const isPage = typeof item !== 'string'
          const isEllipsis = isPage ? false : (item as string).includes('ellipsis')

          if (isEllipsis) return <PaginationEllipsis key={item} />
          const newPage: number = (() => {
            if (isPage) return item
            if (item === 'first') return 1
            if (item === 'previous') return ((current - 1) || 1)
            if (item === 'next') return isLastPage ? totalPages : current + 1
            if (item === 'last') return totalPages
            return current
          })()

          return (
            <PaginationItem 
              key={`${paramName}-${i}`}
              isCurrent={isCurrent}
              page={item}
              {...(!isPage ? { 
                disabled: (['first', 'previous'].includes(item) && isFirstPage) || (['last', 'next'].includes(item) && isLastPage) 
              } : {})}
              aria-label={isPage ? `page ${item}` : `Go to ${item} page`}
              onClick={() => {onPageChange(newPage); setCurrent(newPage)}}
              linkTo={{
                ...loc,
                search: (() => {
                  if (!pageSearch || ignoreQueryParams) return ''
                  if (isPage) {
                    if (item === 1) {
                      pageSearch.delete(paramName)
                    } else {
                      pageSearch.set(paramName, item.toString())
                    }
                  }
                  if (!isPage) {
                    pageSearch.set(paramName, newPage.toString())
                  }

                  return `?${pageSearch.toString()}`
                })()
              }}
            />
          )
        })}
      </ul>
    </nav>
  )
}

export default Pagination