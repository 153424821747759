import FAQ from '@/features/support/components/FAQ'
import Locations from '@/features/support/components/Locations'
import ManageFAQ from '@/features/support/components/ManageFAQ'
import { HelpPrompt, MainLayout, Section, SplitLayout } from '@/shared/components'
import { useTranslation } from 'react-i18next'
import { RouteComponentProps } from 'react-router'

export default function Support(props: RouteComponentProps) {
  const { t } = useTranslation('common')
  return (
    <MainLayout>
      <SplitLayout 
        className='c-support'
        headerTitle={t('supportLabel')}
        leftContent={
          <>
            <Section className='c-support__help'>
              <HelpPrompt />
            </Section>
            <ManageFAQ />
            <Section className='c-support__faq' titleId='faq' title='Frequently asked questions'>
              <FAQ />
            </Section>

          </>
      }
        rightContent={
          <>
            {/* <TeamCard /> */}
            {/* <OfficeCard /> */}
          </>
        }
      />
      <Locations />
    </MainLayout>
  )
}
