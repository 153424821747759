import { memo } from 'react'
import cn from 'classnames'

type AnchorPropsWithoutChildren = Omit<React.AnchorHTMLAttributes<HTMLAnchorElement>, 'children'>

interface Props extends AnchorPropsWithoutChildren {
  children: React.ReactNode;
  noUnderline?: boolean;
  size?: 'large' | 'medium' | 'small'
}

function PublicLink(props: Props) {
  const { className, children, noUnderline = false, size = 'large', ...restProps } = props

  return (
    <a 
      tabIndex={0}
      className={cn(
        'c-public-link', 
        className,
        `--${size}`,
        {  '--no-underline': noUnderline }
      )} 
      {...restProps}
    >
      {children}
    </a>
  )
}

export default memo(PublicLink)
