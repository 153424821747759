import { Chart } from 'chart.js'
import { getChartPlugin } from '../../utils/ChartJS.helper'

export interface SimpleBarChartBarInnerLabelPluginOptions {
  innerLabels: {
    [key: number]: {
      text: string,
      font?: {
        size: string,
        family: string,
        color?: string
      } 
    }
  }
}

export const SimpleBarChartBarInnerLabelPlugin = {
  id: 'SimpleBarChartBarInnerLabelPlugin',
  afterDraw: (chart: Chart, args, options) => {
    const plugin = getChartPlugin(chart, 'SimpleBarChartBarInnerLabelPlugin') as SimpleBarChartBarInnerLabelPluginOptions

    if (plugin) {
      const chartInstance = chart
      const ctx = chartInstance.ctx
      const data = chart.config.data
      const contextFont = ctx.font
      const contextFillStyle = ctx.fillStyle

      ctx.textAlign = 'center'
      ctx.textBaseline = 'bottom'

      data.datasets.forEach((dataset, i) => {
        const meta = chartInstance.getDatasetMeta(i)
        
        if (!meta.hidden) {
          meta.data.forEach((bar, index) => {
            const barElement = bar as any
            const value = dataset.data[index] as number
            const innerLabel = plugin.innerLabels[index]

            if (innerLabel) {
              const { text, font } = innerLabel
              const left = bar.x + ctx.measureText(text).width / 2
              let top
              
              if (value > 0) {
                top = bar.y + barElement.height / 2 + 10
              } else {
                top = barElement.y - barElement.height / 2 + 5
              }

              // assigns center font style for canvas context
              if (font) {
                ctx.font = `${font.size} ${font.family}`
                if (font.color) {
                  ctx.fillStyle = font.color
                }
              }
              
              ctx.fillText(text, left - 20, top - 2)
            }
          })
        }
      })

      // restores original context font
      ctx.font = contextFont
      ctx.fillStyle = contextFillStyle
    }
  },
}

