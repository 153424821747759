import { DEFAULT_DATE_FORMAT, formatDate, getMaxAsOfDate, isDateValid } from '@/lib/common/services/date/DateService'
import { Calendar, DatePicker, DateRangeType, getMonthEnd } from '@fluentui/react'
import { useEffect, useState } from 'react'

import './MonthEndSelector.scss'

const QUARTERLY_MONTH_END_NAMES = ['MAR', 'JUN', 'SEP', 'DEC']
const QUARTERLY_MONTH_END_FULLNAMES = ['MARCH', 'JUNE', 'SEPTEMBER', 'DECEMBER']

export interface MonthEndSelectorProps {
  calendarType?: 'MONTHLY' | 'QUARTERLY'
  value?: Date
  minDate?: Date
  maxDate?: Date
  onValueChange?: (date: Date) => void
}

const MonthEndSelector = ({
  calendarType = 'MONTHLY',
  value = getMaxAsOfDate(),
  maxDate = getMaxAsOfDate(),
  minDate,
  onValueChange,
}: MonthEndSelectorProps) => {
  const currentDate = new Date()
  const [ mutationObserver, setMutationObserver ] = useState<MutationObserver | null>(null)
  
  useEffect(() => {
    return () => {
      // clears mutation observer on component unbound
      clearMutationObserver() 
    }
  }, [mutationObserver])

  const clearMutationObserver = () => {
    if (mutationObserver) {
      // unbinds observer event
      mutationObserver.disconnect()

      // clears state
      setMutationObserver(null)
    }
  }

  const enableElement = (element: HTMLElement) => {
    element.removeAttribute('disabled')
    element.style.removeProperty('color')
    element.style.removeProperty('pointer-events')
    element.style.removeProperty('background-color')
  }

  const disableElement = (element: HTMLElement) => {
    element.setAttribute('disabled', 'disabled')
    element.style.setProperty('color','#c8c6c4', 'important')
    element.style.setProperty('pointer-events', 'none', 'important')
    element.style.setProperty('background-color', '#ffffff', 'important')
  }

  const refreshTodayLink = (calloutElement: HTMLElement) => { 
    const goToTodayElement = calloutElement.querySelector('.js-goToday') as HTMLElement

    if (goToTodayElement) {
      if (!isDateValid(currentDate)) {
        disableElement(goToTodayElement)
      }
    }
  }

  const disableQuarterlyIntramonthDates = (calloutElement: HTMLElement) => {
    const disableDates = () => {
      const dateColumns = calloutElement.querySelectorAll('div[role="row"] button[role="gridcell"]')

      if (dateColumns && dateColumns.length) {
        // disable quarterly intramonth dates
        for (let i = 0; i < dateColumns.length; i++) {
          const dateColumn = dateColumns.item(i) as HTMLElement

          if (dateColumn) {
            const hasDisabledClass = `${dateColumn.className || ''}`.toLowerCase().includes('disabled')

            if (!hasDisabledClass) {
              const monthName = `${dateColumn.innerText || ''}`.toUpperCase().trim()
              const fullMonthName = `${dateColumn.getAttribute('aria-label') || ''}`.toUpperCase().trim()
              const isYear = monthName.match(/^[0-9]+$/) || fullMonthName.match(/^[0-9]+$/)

              // removes any old cache
              enableElement(dateColumn)
              
              if (monthName) {
                if (!isYear && !QUARTERLY_MONTH_END_NAMES.includes(monthName)) {
                  disableElement(dateColumn)
                }
              } else if (fullMonthName) {
                if (!isYear && !QUARTERLY_MONTH_END_FULLNAMES.includes(fullMonthName)) {
                  disableElement(dateColumn)
                }
              }
            }
          }
        }
      }
    }
    disableDates()
  }

  const refreshCalendarPopup = (calloutElement: HTMLElement) => {
    setTimeout(() => {
      refreshTodayLink(calloutElement)

      if (calendarType === 'QUARTERLY') {
        disableQuarterlyIntramonthDates(calloutElement)
      }
    })
  }


  const initCalloutObserver = (calloutElement: HTMLElement) => {
    // clears any cached mutation observer
    clearMutationObserver()

    // creates an observer instance linked to the callback function
    const observer = new MutationObserver((mutationList, currentObserver) => {
      refreshCalendarPopup(calloutElement)
    })

    // starts observing on callout element
    observer.observe(calloutElement, {
      childList: true,
      subtree: true,
      attributes: true,
    })

    setMutationObserver(observer)
    refreshCalendarPopup(calloutElement)

    setTimeout(() => {
      const calendarGridElement = calloutElement.querySelector('div[class*="gridContainer"]')

      if (calendarGridElement) {
        // starts observing on calendar grid element
        observer.observe(calendarGridElement, {
          childList: true,
          subtree: true,
          attributes: true,
        })
      }
    }, 1000)
  }

  return (
    <DatePicker
      className='MonthEndSelector'
      minDate={minDate}
      maxDate={maxDate}
      value={value}
      showGoToToday={false}
      calendarAs={(props) =>
        <Calendar
          {...props}
          highlightSelectedMonth
          dateRangeType={DateRangeType.Month}
          isDayPickerVisible={false}
        />
      }
      onSelectDate={(date) => {
        // emits value change
        if (onValueChange) {
          onValueChange(getMonthEnd(date))
        }

        // clears mutation observer on date selection
        clearMutationObserver()
      }}
      calloutProps={{
        className: 'MonthEndSelectorCallout',
        onLayerMounted: () => {
          const calloutElement = document.querySelector('.ms-Callout.ms-DatePicker-callout') as HTMLElement
          if (calloutElement) {
            initCalloutObserver(calloutElement)
          }
        },
        onDismiss(event) {
          clearMutationObserver()
        },
      }}
      formatDate={(date) => formatDate(date, DEFAULT_DATE_FORMAT)}
    />
  )
}

export default MonthEndSelector