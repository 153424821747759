import { DefaultButton, PrimaryButton, Stack } from '@fluentui/react'

import Modal, { ModalProps } from './components/Modal'
import { useDispatch, useSelector } from 'react-redux'
import modalSlice, { selectModalStatuses, ValidModalParamNames } from '@/shared/redux/modalSlice'

export interface ButtonModalProps extends Omit<ModalProps, 'close' | 'modalIsOpen' | 'className'> {
  openButtonLabel: string
  openButtonAriaLabel: string
  onOpen?: () => void
  onClose?: () => void
  hidePrimary?: boolean
  secondaryLabel?: string
  primaryLabel: string
  primaryIsDisabled?: boolean
  /** Function called when the user presses the bottom right primary button */
  onPrimary: () => void
  modalClassName?: string
  // redux state to determine modal status.
  paramName: ValidModalParamNames;
  className?: string;
  primaryButtonId?: string;
  openButtonId?: string;
}

const ButtonModal = ({ 
  openButtonLabel, 
  children, 
  closeAriaLabel, 
  modalTitle, 
  openButtonAriaLabel, 
  onOpen, 
  onClose, 
  onPrimary, 
  primaryLabel, 
  primaryIsDisabled = false,
  secondaryLabel = 'Cancel',
  modalClassName,
  paramName,
  hidePrimary,
  className,
  primaryButtonId,
  openButtonId,
}: ButtonModalProps) => {
  const modalStatuses = useSelector(selectModalStatuses)

  if (modalStatuses[paramName] === undefined) {
    throw new Error(`Expected true / false in redux. Found undefined. Given param: ${paramName}. Expected one of: ${Object.keys(modalStatuses).join(', ')}`)
  }
  const modalIsOpen = modalStatuses[paramName]
  const dispatch = useDispatch()

  const handleModalClose = () => { 
    dispatch(modalSlice.actions.setModalStatus({ paramName, value: false }))
    if (onClose) onClose()
   }

  const handleButtonClick = () => { 
    dispatch(modalSlice.actions.setModalStatus({ paramName, value: true }))
    if (onOpen) onOpen()
   }

  const renderModal = () => {
    if (!modalIsOpen) return null
    return (
      <Modal
        className={modalClassName}
        modalTitle={modalTitle}
        closeAriaLabel={closeAriaLabel}
        modalIsOpen={modalIsOpen}
        close={handleModalClose}
      >
        <>
          {children}
          <Stack tokens={{ childrenGap: 16 }} horizontal horizontalAlign='end' >
            <DefaultButton onClick={handleModalClose}>
              {secondaryLabel}
            </DefaultButton>
            {hidePrimary ? null : (
              <PrimaryButton 
                disabled={primaryIsDisabled}
                onClick={onPrimary}
                id={primaryButtonId}
              >
                {primaryLabel}
              </PrimaryButton>
            )}
          </Stack>
        </>
      </Modal>
    )
  }
  
  return (
    <div className='c-button-modal' id={openButtonId}>
      <PrimaryButton 
        aria-label={openButtonAriaLabel}
        onClick={handleButtonClick}
        className={className}
      >
        {openButtonLabel}
      </PrimaryButton>
      {renderModal()}
    </div>
  )
}

export default ButtonModal