import useHandleSelectionWithCSR from '@/shared/hooks/useHandleSelectionWithCSR'
import { CommandBar, CommandButton, ICommandBarItemProps } from '@fluentui/react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router'
import useCanAccessAdminToolbar from '../hooks/useCanAccessAdminToolbar'
import usePermission from '@/features/auth/hooks/usePermission'
import { EUserCan } from '@/features/auth/types'

export default function NavMenuAdminToolbar() {
  const { pathname } = useLocation()
  const canAccessToolbar = useCanAccessAdminToolbar()
  const { t } = useTranslation('nav')
  const handleSelectionWithCSR = useHandleSelectionWithCSR()

  const canAccessSurveyLinks = usePermission(EUserCan.Access_SurveyLinks)

  if (!canAccessToolbar) return null

  const items = {
    text: t('adminToolbar'),
    className: "",
    menuProps: {
      items: [
        {
          key: "clients",
          text: "Clients",
          onClick: handleSelectionWithCSR({ url: '/clients' })
        }
      ]
    }
  }

  if (canAccessSurveyLinks) {
    items.menuProps.items.push({
      key: "surveyLinks",
      text: "Survey Links",
      onClick: handleSelectionWithCSR({ url: '/surveyLinks' })
    })
  }

  return (
    <div className='c-navmenu__admin'>
      <CommandButton
        {...items}
        className={pathname.includes('/clients') ? 'is-expanded' : ''}
      />
    </div>
  )
}
