import { CSSProperties, ReactNode, useMemo } from 'react'
import { v4 as uuidv4 } from 'uuid'
import cn from 'classnames'

interface BaseProps {
  children?: React.ReactNode;
  size?: 'small' | 'large' | 'extraSmall';
  action?: React.ReactNode;
  style?: CSSProperties;
  className?: string;
  headingClassName?: string;
  renderLHS?: () => ReactNode;
  headerClassName?: string;
}
interface WithHeading extends BaseProps {
  heading: string;
  supheading?: string;
  ariaLabel?: string;
  ariaLabelledBy?: string;
}
interface WithoutHeadingWithLabel extends BaseProps {
  supheading?: never;
  ariaLabel: string;
  ariaLabelledBy?: never;
  heading?: string;
}
interface WithoutHeadingWithLabelledBy extends BaseProps {
  supheading?: never;
  ariaLabel?: never;
  ariaLabelledBy: string;
  heading?: string;
}
export type CardProps = WithHeading | WithoutHeadingWithLabel | WithoutHeadingWithLabelledBy

/**
 * Card with shadow to contains small chunks of content.\
 * Large size is for large areas and has a 24px padding in desktop.\
 * Small size is for side layout and has a 32px padding in desktop.
 * 
 * @description 
 *   `heading` or `ariaLabel` or `ariaLabelledBy` is required. All are allowed.\
 *   `supheading` without `heading` is not allowed.
 * @example
 *   <Card ariaLabel="foo" />
 *   <Card heading="Some Heading" supheading="some super/above heading" />
 */
export default function Card(props: CardProps) {
  const { heading, supheading, action, children, ariaLabel, size = 'small', ariaLabelledBy, className, headingClassName, renderLHS, headerClassName } = props
  const headingId = useMemo(() => uuidv4(), [])

  return (
    <div 
      className={cn('c-card', `--${size}`, className)}
      {...(ariaLabel ? { 'aria-label': ariaLabel } : {})}
      {...((!ariaLabel || ariaLabelledBy) ? { 'aria-labelledby': ariaLabelledBy || headingId } : {})}
    >
      <header className={cn('c-card__header', headerClassName)}>
        {supheading ? <h5 className="c-card__supheading">{supheading}</h5> : null}
        {renderLHS ? <div className='c-card__lhs'>{renderLHS()}</div> : null}
        {heading ? <h4 className={cn('c-card__heading', headingClassName)} id={headingId}>{heading}</h4> : null}
        {action ? <div className="c-card__header-rhs">{action}</div> : null}
      </header>
      {children}
    </div>
  )
}
