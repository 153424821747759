import { memo, SVGAttributes } from 'react'

type Props = SVGAttributes<SVGElement>

function RightSmallArrow(props: Props) {
  return (
    <svg 
      width="16" 
      height="16" 
      viewBox="0 0 16 16" 
      fill="none" 
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M13.8536 7.14645C14.0488 7.34171 14.0488 7.65829 13.8536 7.85355L10.8536 10.8536C10.6583 11.0488 10.3417 11.0488 10.1464 10.8536C9.95118 10.6583 9.95118 10.3417 10.1464 10.1464L12.2929 8L2.5 8C2.22386 8 2 7.77614 2 7.5C2 7.22386 2.22386 7 2.5 7L12.2929 7L10.1464 4.85355C9.95118 4.65829 9.95118 4.34171 10.1464 4.14645C10.3417 3.95118 10.6583 3.95118 10.8536 4.14645L13.8536 7.14645Z" 
        fill="#0078D4"
      />
    </svg>

  )
}

export default memo(RightSmallArrow)
