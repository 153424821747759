const randomString = () => {
  const suffix = Math.floor(Math.random() * 10000)
  return `${new Date().getTime()}${suffix}`
}

const generateId = () => {
  return randomString()
}

const getId = (label?: any, id?: string) => {
  const sanitizeId = (sourceId: string) => {
    let labelId = sourceId.replace(/[^0-9A-Za-z]/g, '_').replace(/\s/g, '_')

    while (labelId.indexOf('__') !== -1) {
      labelId = labelId.replace(/__/g, '_')
    }

    return labelId.toLowerCase().trim()
  }

  if (id) {
    return sanitizeId(id)
  }

  if (label && typeof label === 'string') {
    return sanitizeId(label)
  }

  return generateId()
}

const nFormatter = (num, digits) => {
  const lookup = [
    { value: 1, symbol: '' },
    { value: 1e3, symbol: 'K' },
    { value: 1e6, symbol: 'M' },
    { value: 1e9, symbol: 'B' },
    { value: 1e12, symbol: 'T' },
    { value: 1e15, symbol: 'P' },
    { value: 1e18, symbol: 'E' }
  ]
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/
  const item = lookup.slice().reverse().find(function(item) {
    return num >= item.value
  })
  return item ? (num / item.value).toFixed(digits).replace(rx, '$1') + item.symbol : '0'
}

const isTruthy = (value: any): boolean => {
	return [1, '1', true, 'true', 'True', 'Yes'].includes(value)
}

const isFalsy = (value: any): boolean => {
	return [0, '0', false, 'false', 'False', 'No'].includes(value)
}

export default {
  generateId,
  getId,
  nFormatter,
  isTruthy,
  isFalsy,
}

export class Constants {
  public static readonly NODE_NAME_DELIMITER = ','
}
