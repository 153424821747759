import { memo } from 'react'

function PaginationEllipsis() {
  return (
    <li className="c-pagination__item">
      <span className="c-pagination__item-ellipsis" role="img" aria-label="ellipsis">...</span>
    </li>
  )
}

export default memo(PaginationEllipsis)
