import { CurrencyColumn, DateColumn, TextColumn } from '@/lib/common/components/grid/Columns'
import DataGrid, { DataGridInitEvent } from '@/lib/common/components/grid/Grid'
import { TradeTypeColumn } from '@/screens/Dashboard/RecentTrades/grid/cellRenderer/TradeTypeColumn'
import { IPortfolioSettings, ITrade, getGridExcelStyles } from '@/shared/api/services/portfolioService'
import useIsMobile from '@/shared/hooks/useIsMobile'
import { GridApi } from 'ag-grid-community'

export interface RecentTradeGridProps {
  rows: ITrade[],
  portfolioSettings: IPortfolioSettings,
  sendGridApi: (gridApi : GridApi) => void
}

const RecentTradeGrid = ({
  rows,
  portfolioSettings,
  sendGridApi,
}: RecentTradeGridProps) => {
  const dateFormat = portfolioSettings?.dateFormat
  const isLargeMobile = useIsMobile('mm')
  const isVerticalTablet = useIsMobile('sm')

  const getCurrencySymbol = (data: ITrade) => {
    return data?.currency?.symbol
  }

  const ongridInit=(event : DataGridInitEvent) =>{
    const { gridApi } = event
    sendGridApi(gridApi)
  }

  return (
    <DataGrid
      showToolBar={false}
      rows={rows}
      enablePagination={false}
      domLayout='normal'
      onInit={ongridInit}
      excelStyles={getGridExcelStyles({
        currencySymbol: portfolioSettings?.currency?.symbol,
        dateFormat: portfolioSettings?.dateFormat,
      })}
    >
      <TextColumn title='INVESTMENT' disableFilterColumn={true} field='name' checkboxSelection={false} />
      <DateColumn dateFormat={dateFormat} title='DATE' disableFilterColumn={true} field='date' width={110} />
      <TradeTypeColumn headerClass='tradeTypeColumn text-left' title='TYPE' disableFilterColumn={true} field='type' width={110}/>
      <CurrencyColumn decimalScale={2} negativeNumberClassName='negative-number' title='AMOUNT' disableFilterColumn={true} field='amnt' width={120} getCurrencySymbol={getCurrencySymbol} />
    </DataGrid>
  )
}

export default RecentTradeGrid