import { IMessageViewRecord } from '@/shared/types/swagger'
import { Link } from '@fluentui/react'
import { useTranslation } from 'react-i18next'

type EditCellProps = {
  message: IMessageViewRecord,
  onClick: (message: IMessageViewRecord) => void
}

export default function EditCell(props: EditCellProps) {
  const { t } = useTranslation()

  const handleLinkClick = () => {
    if (props.onClick) props.onClick(props.message)
  }

  return (
    <Link onClick={handleLinkClick}>{t('messages:edit')}</Link>
  )
}
