// You probably don't need to import this directly. Use Table.tsx!
// Used for when FE is handling most functionality of the table.
// Has built in functionality for sorting and searching through the table.

import { useState, MouseEvent, useEffect, useMemo, useCallback } from 'react'
import isEmpty from 'lodash/isEmpty'

import Section from '@/shared/components/Section'
import Card from '@/shared/components/Card'
import { SelectionMode, ColumnActionsMode, ShimmeredDetailsList, IDragDropEvents } from '@fluentui/react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import cn from 'classnames'
import ConditionalWrapper from '../../ConditionalWrapper'
import ShimmerRow from './ShimmerRow'
import { ColumnSettings, DetailsListPropsExtra, ISharedProps, ITableColumn } from '../types'
import copyAndSort from '../helpers/copyAndSort'
import detailsListStyles from '../helpers/detailsListStyles'

export type Props = DetailsListPropsExtra & ISharedProps & {
  searchParamName?: string;
  refetchOnPageChange?: boolean;
  sortFieldParamName?: string;
  sortOrderParamName?: string;
  handleSearchChange?: (text: string) => void;
  sortData?: boolean;
  dragDropEvents?: IDragDropEvents;
}

const UnControlledTable = (props: Props) => {
  const { 
    columns: _columns, 
    data,
    sortFieldParamName = 'sf',
    sortOrderParamName = 'so',
    sectionProps,
    cardProps,
    useCard = false,
    compact = false,
    additionalTableProps = {},
    shimmerIsEnabled = false,
    shimmerLineCount = 12,
    handleSearchChange,
    sortData = false,
    dragDropEvents,
  } = props

  const { t } = useTranslation()
  const history = useHistory()
  const queryParams = new URLSearchParams(history.location.search)
  const sortField = queryParams.get(sortFieldParamName) || ''
  const sortOrder = queryParams.get(sortOrderParamName) || ''

  const [items, setItems] = useState<Props['data']>([])
  const [settings, setSettings] = useState<Record<string, ColumnSettings>>(
    sortField && sortOrder ? {
      [sortField]: {
        isSorted: true,
        isSortedDescending: sortOrder === 'desc',
        fieldName: sortField,
        sortKey: null
      }
    } : {}
  )

  useEffect(() => {
    // anytime data changes we want to make sure our items is up-to-date.
    if (isEmpty(settings)) {
      setItems(data)
    } else {
      const columnId = Object.keys(settings)[0] // Only one key is ever present
      const filteredData = copyAndSort(
        data as Array<Record<string, unknown>>, 
        settings[columnId].fieldName,
        settings[columnId].isSortedDescending,
        settings[columnId].sortKey
      )
      setItems(filteredData)
    }
  }, [data, sortField, sortOrder, settings, handleSearchChange])

  const sortDescText = t('common:sortDesc')
  const sortAscText = t('common:sortAsc')

  const onColumnClick = useCallback((_ev: MouseEvent<HTMLElement>, column: ITableColumn) => {
    let newSettings = {}
    // eslint-disable-next-line no-debugger
    let resetSort = false
    if (settings[column.key]) {
      if (settings[column.key].isSortedDescending === false) {
        // The user has clicked this column header three times. Remove all sorts
        resetSort = true
        newSettings = {
          [column.fieldName]: {
            fieldName: settings[column.key].fieldName,
            isSorted: false
          }
        }
      } else {
        // This setting already exists so we'll just flip the sort variable 
        newSettings = {
          [column.key]: {
            ...settings[column.key],
            isSorted: true,
            isSortedDescending: !settings[column.key].isSortedDescending
          }
        }
      }
    } else {
      // The user hasn't pressed this column before so we'll switch the columnKey
      newSettings = {
        [column.key]: {
          fieldName: column.fieldName,
          sortKey: column.sortKey,
          isSorted: true,
          isSortedDescending: true
        }
      }
    }
    setSettings(newSettings)

    if (resetSort) {
      // No settings defined. Remove query parameters
      history.replace({
        ...history.location,
        search: (() => {
          const _queryParams = new URLSearchParams(history.location.search)
          _queryParams.delete(sortFieldParamName)
          _queryParams.delete(sortOrderParamName)
  
          return `?${_queryParams.toString()}`
        })()
      })
    } else {
      history.replace({
        ...history.location,
        search: (() => {
          const _queryParams = new URLSearchParams(history.location.search)
          _queryParams.set(sortFieldParamName, column.key)
          _queryParams.set(sortOrderParamName, newSettings[column.key].isSortedDescending ? 'desc' : 'asc')
  
          return `?${_queryParams.toString()}`
        })()
      })
    }
  }, [history, settings, sortFieldParamName, sortOrderParamName])

  const propColumns = useMemo((): Array<ITableColumn> => {
    return _columns.map((col) => {
      const _col = { ...col }
      if (_col.fieldName === sortField) {
        _col.isSorted = true
        _col.isSortedDescending = sortOrder === 'desc'
      }
      if (_col.columnActionsMode !== ColumnActionsMode.disabled) {
        _col.sortAscendingAriaLabel = sortAscText
        _col.sortDescendingAriaLabel = sortDescText
        _col.onColumnClick = _col.onColumnClick || onColumnClick
      }
      return _col
    })
  }, [_columns, onColumnClick, sortAscText, sortDescText, sortField, sortOrder])

  const modifiedColumns = useMemo((): Array<ITableColumn> => {
    return propColumns.map((col) => {
      if (settings[col.key]) {
        return { ...col, ...settings[col.key] }
      }
      return col
    })
  }, [propColumns, settings])

  const { actions: sectionActions = [], className, ..._sectionProps } = (sectionProps || {})

  return (
    <Section
      {..._sectionProps}
      actions={sectionActions}
      className={cn('c-table', className)}
    >
      <ConditionalWrapper
        condition={useCard}
        wrapper={(children) => <Card {...cardProps}>{children}</Card>}
      >
        <>
          <ShimmeredDetailsList 
            compact={compact}
            columns={modifiedColumns} 
            items={items}
            selectionMode={SelectionMode.none}
            styles={detailsListStyles}
            onRenderCustomPlaceholder={ShimmerRow}
            enableShimmer={shimmerIsEnabled}
            shimmerLines={shimmerLineCount}
            removeFadingOverlay
            dragDropEvents={dragDropEvents}
            {...additionalTableProps}
          />
        </>
      </ConditionalWrapper>
    </Section>
  )
}

export default UnControlledTable