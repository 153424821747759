import { SimpleBarChart } from '@/lib/common/components/charts/simple-bar-chart/SimpleBarChart'
import { CURRENCY_COLUMN_CELL_CLASS, DATE_COLUMN_CELL_CLASS, DATE_COLUMN_FORMAT, DATE_TIME_COLUMN_CELL_CLASS, DATE_TIME_COLUMN_FORMAT, PERCENTAGE_COLUMN_CELL_CLASS } from '@/lib/common/components/grid/GridExport'
import { formatDate, parseFormattedDate } from '@/lib/common/services/date/DateService'
import { exportGridData, getPerformanceRawValue, IPortfolioSettings, roundMarketValueWidgetValue, useGetPortfolioMarketValue, useGetPortfolioSettingsByIdQuery } from '@/shared/api/services/portfolioService'
import { Card, GeneralError, Section } from '@/shared/components'
import useIsMobile from '@/shared/hooks/useIsMobile'
import { DefaultButton } from '@fluentui/react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ColDef, GridApi, ProcessCellForExportParams } from 'ag-grid-community'
import { useEffect, useState } from 'react'
import SkeletonClientPortfolio from '../Skeletons/SkeletonClientPortfolioMv'
import ReturnToTotalPortfolioButton from '../TotalPortfolioButton/ReturnToTotalPortfolio'
import MarketValueDetails from './MarketValueDetails/MarketValueDetails'

import './MarketValue.scss'

export interface MarketValueProps {
  portfolioSettings: IPortfolioSettings,
  portfolioId: string,
  asOfDate: string,
  showTotalPortfolioButton?: boolean,
  totalPortfolioId?: string,
  isSingleWidget?: boolean,
  previewAccountId?: string,
}

const getWindowDimensions = () => {
  const { innerWidth: width, innerHeight: height } = window
  return {
    width,
    height
  }
}

export default function MarketValue({
  portfolioSettings,
  portfolioId,
  asOfDate,
  showTotalPortfolioButton,
  totalPortfolioId,
  isSingleWidget,
  previewAccountId,
}: MarketValueProps) {
  const isMobile = useIsMobile('xs')
  const { data: mvValue, isFetching, isError, data, refetch } = useGetPortfolioMarketValue({ portfolioId, asOfDate, previewAccountId })
  let marketValueData: any
  let marketValueDataReport: any
  let periods = []
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions())
  const [gridApi, setGridApi] = useState(null as GridApi)

  useEffect(() => {
    const handleResize = () => {
      setWindowDimensions(getWindowDimensions())
    }

    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])


  if (isError) {
    return (
      <Card className={`c-onboarding-welcome__left__card ${isSingleWidget ? 'card-left-min-single' : 'card-left-min'} `} ariaLabel='Welcome'>
        <Section className='c-portfolio-section-chart' title='Market Value'>
          <GeneralError title='Failed to load Portfolio Market Value' onClick={refetch} />
        </Section>
      </Card>
    )
  }

  const handleExcelDownloadClick = () => {
    exportGridData({
      gridApi,
      excelTabName: 'Market Value',
      fileName: `market-value-export-investment-pool-${portfolioSettings?.investmentPoolName}-${portfolioSettings?.investmentPoolId}-as-of-date-${asOfDate}`,
      processCellCallback:  (cellCallbackParams: ProcessCellForExportParams) => {
        const { value, column, node } = cellCallbackParams
        const { colDef: columnDef } = column as any
        const colDef = columnDef as ColDef
        const { originalCellClass } = colDef as any
        const { cellClass } = colDef
        const data = node && node.data
        const field = colDef && colDef.field
        const className = (originalCellClass && (typeof originalCellClass === 'string')) ? originalCellClass : cellClass
    
        if (value && cellClass) {
          const hasCellClass = (cellClassName: any, expectedCellClass: string) => {
            if (Array.isArray(cellClassName)) {
              return cellClassName.includes(expectedCellClass)
            }
            return `${cellClassName || ''}`.includes(expectedCellClass)
          }
    
          if (hasCellClass(className, DATE_COLUMN_CELL_CLASS)) {
            return formatDate(value, DATE_COLUMN_FORMAT)
          } else if (hasCellClass(className, DATE_TIME_COLUMN_CELL_CLASS)) {
            return formatDate(value, DATE_TIME_COLUMN_FORMAT)
          } else if (hasCellClass(className, CURRENCY_COLUMN_CELL_CLASS)) {
            if (data && field) {
              try {
                const currencyValue = Number(data[field] || value)?.toFixed(4)
                return currencyValue
              } catch (error) {
                console.error('Error while parsing currency column value.', error)
              }
            }
          } else if (hasCellClass(className, PERCENTAGE_COLUMN_CELL_CLASS)) {
            try {
              const percentageValue = getPerformanceRawValue(data, field)
              return percentageValue?.toFixed(6)
            } catch (error) {
              console.error('Error while parsing percentage column value.', error)
            }
          } 
        }
    
        return value
      }
    })
  }

  if (!isFetching) {
    periods = isMobile || windowDimensions.width < 1810 ? [['Net Cash', 'Flows'], ['Profit/Loss'], ['Change In', 'Market Value']] : ['Net Cash Flows', 'Profit/Loss', 'Change In Market Value']
    const formatedChagePct = !!mvValue?.ChangePct ? mvValue?.ChangePct?.toLocaleString('en-US', {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }) : ''

    marketValueData = {
      periods: periods,
      data: [
        {
          value: roundMarketValueWidgetValue(mvValue?.NetCashFlows || 0),
          currencySymbol: mvValue?.CurrencySymbol,
        },
        {
          value: roundMarketValueWidgetValue(mvValue?.ProfLoss || 0),
          currencySymbol: mvValue?.CurrencySymbol,
        },
        {
          value: roundMarketValueWidgetValue(mvValue?.ChangeMV || 0),
          currencySymbol: mvValue?.CurrencySymbol,
          innerLabel: {
            text: !!mvValue?.ChangePct ? `${mvValue?.ChangePct > 0 ? '🠝' : '🠟'} ${formatedChagePct}%` : '',
            font: {
              color: '#ffff'
            }
          }
        }
      ]
    }

    marketValueDataReport = [
      {
        title: `${isMobile ? 'A' : 'Market Value a'}s of ${formatDate(parseFormattedDate(mvValue?.StartPeriod), portfolioSettings?.fullDateFormat)}`,
        value: roundMarketValueWidgetValue(mvValue?.StartMv) || 0,
        currencySymbol: mvValue?.CurrencySymbol,
        StatusAggregate: mvValue?.StatusAggregate,
      },
      {
        title: `${isMobile ? 'A' : 'Market Value a'}s of ${formatDate(parseFormattedDate(mvValue?.EndPeriod), portfolioSettings?.fullDateFormat)}`,
        value: roundMarketValueWidgetValue(mvValue?.CurrMv) || 0,
        currencySymbol: mvValue?.CurrencySymbol,
        StatusAggregate: mvValue?.StatusAggregate,
      },
      { title: `Change in ${isMobile ? '' : 'Market Value in'} last period`, value: roundMarketValueWidgetValue(mvValue?.ChangeMV) || 0, currencySymbol: mvValue?.CurrencySymbol },
    ]

  }

  const isFootNoteHidden = !(marketValueDataReport || []).some(r => !!r.StatusAggregate)

  const showTotalAssetsButton = () => {
    if (showTotalPortfolioButton) {
      return (
        <div key={0}>
          <ReturnToTotalPortfolioButton portfolioId={portfolioId} asOfDate={asOfDate} id={totalPortfolioId} />
        </div>
      )
    }
    return null
  }

  return (
    <Card className={`c-onboarding-welcome__left__card card-left-min ${isSingleWidget ? 'mv-single' : 'card-market-value'}`} ariaLabel='Welcome'>
      <Section className='c-portfolio-section-chart c-portfolio-marketvalue'title='Market Value' subtitle='Estimated' actions={[
        <div className='button-container' key={0}>
          {showTotalAssetsButton()}
          <div key='downloadBtn' className='download-button-container'>
            <DefaultButton className='download-button' onClick={handleExcelDownloadClick}>
              <FontAwesomeIcon icon='download' className='c-sidebar-section__heading-icon' />
            </DefaultButton>
          </div>
        </div>]}>
        <>
          {
            isFetching ? <SkeletonClientPortfolio /> :
              (
                <>
                  <MarketValueDetails portfolioSettings={portfolioSettings} marketValueDataReport={marketValueDataReport} sendGridApi={setGridApi} />
                  <SimpleBarChart
                    fontOptions={{
                      font: {
                        size: 12
                      },
                      decimalScale: 0,
                    }}
                    periods={marketValueData.periods}
                    series={{
                      name: 'Dataset 1',
                      data: marketValueData.data,
                      colors: ['#0c3980', '#0c3980', '#3CABC9'],
                    }}
                    xAxis={{ labelPadding: 30 }}
                    onSeriesClick={(seriesItem) => seriesItem}
                  />
                </>
              )
          }
        </>
      </Section>
    </Card>
  )
}
