import getName from '@/features/client/helpers/getName'
import { IClientContactViewModel } from '@/shared/types/swagger'
// import { useParams } from 'react-router-dom'

export default function ContactCell(contact: IClientContactViewModel) {
  // const params = useParams<{accountid: string}>()
  // const { accountid } = params

  return (
    // <Link className="c-table__cell-link-primary" href={`/clients/${accountid}/contacts/${contact.contactId}`} data-selection-invoke={true}>
    <span className="c-table__cell-link-primary">{getName(contact)}</span>
    // </Link>
  )
}
