import { RootState } from '@/bootstrap/redux'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { FAQ } from '../types/faq'

type InitialState = {
  questionBeingEdited: FAQ,
  faqError: string
}

export const initialState: InitialState = {
  questionBeingEdited: null,
  faqError: null
}

export default createSlice({
  name: 'faq',
  initialState,
  reducers: {
    setQuestion: (state, action: PayloadAction<FAQ>) => {
      state.questionBeingEdited = action.payload
    },
    setQuestionData: (state, action: PayloadAction<{ key: string, newValue: string | boolean }>) => {
      if (!state.questionBeingEdited) return
      const { key, newValue } = action.payload
      state.questionBeingEdited[key] = newValue
    },
    setQuestionError: (state, action: PayloadAction<string>) => {
      state.faqError = action.payload
    }
  },
})

export const selectQuestionBeingEdited = (state: RootState) => state.faq.questionBeingEdited
export const selectQuestionError = (state: RootState) => state.faq.faqError
