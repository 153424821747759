import { DEFAULT_COLUMNDEFS, IDataGridColumnProps, PLACEHOLDER } from "@/lib/common/components/grid/Columns";
import { ColDef, ICellRendererParams } from 'ag-grid-community';

// component styles
import './TradeTypeColumn.scss';

export const TRADE_TYPE_COLUMN_CELL_CLASS = "TradeTypeCell";

export const TradeTypeCellrenderer = (props: ICellRendererParams) => {
    const { data, value } = props
    return (
      <div className='TradeTypeColumn'>
        <div className={`TradeTypeValue ${(value || '').trim().replace(/\t|\s|\n/g, '')}`}>
          { value }
        </div>
      </div>
    );
};

export function TradeTypeColumn(props: IDataGridColumnProps) {
  return PLACEHOLDER;
}
TradeTypeColumn.columnDefs = <TRow extends any>(
  props: IDataGridColumnProps
): ColDef =>
  ({
    ...DEFAULT_COLUMNDEFS,
    autoHeight: true,
    filter: !props.disableFilterColumn ? "agTextColumnFilter" : false,
    floatingFilter: !props.disableFilterColumn,
    cellRendererFramework: TradeTypeCellrenderer,
    headerClass: `${props?.headerClass} text-center`, 
    cellClass: TRADE_TYPE_COLUMN_CELL_CLASS,
    disableWidth: false,
    width: props.width
  } as ColDef);