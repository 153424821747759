import { Spinner, SpinnerSize } from '@fluentui/react'
import { memo } from 'react'
import './ManageInsightLoading.scss'

type ManageInsightLoadingProps = {
  isFetching: boolean
}

const ManageInsightLoading = ({ isFetching }: ManageInsightLoadingProps) => {
  if (!isFetching) return null
  return (
    <div role="presentation" className='c-manage-insight-spinner'>
      <Spinner size={SpinnerSize.large} />
    </div>
  )
}

export default memo(ManageInsightLoading)