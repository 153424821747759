import { Chart } from 'chart.js'
import { getChartPlugin } from '../../utils/ChartJS.helper'

export interface SimpleBarChartBarValuePluginOptions {
  currencySymbols?: {
    [key: number]: string,
  },
  font?: {
    size: number,
    family?: string,
    color?: string,
  },
  decimalScale?: number,
}

export const SimpleBarChartBarValuePlugin = {
  id: 'SimpleBarChartBarValuePlugin',
  afterDraw: (chart: Chart, args, options) => {
    const plugin = getChartPlugin(chart, 'SimpleBarChartBarValuePlugin') as SimpleBarChartBarValuePluginOptions

    if (plugin) {
      const chartInstance = chart
      const ctx = chartInstance.ctx
      const data = chart.config.data

      ctx.textAlign = 'center'
      ctx.textBaseline = 'bottom'

      data.datasets.forEach((dataset, i) => {
        const meta = chartInstance.getDatasetMeta(i)
        
        if (!meta.hidden) {
          const hasOnlyNegativeValues = meta.data.every((dt, index) => {
            const value = dataset.data[index] as number
            return !value || value <= 0
          })
          meta.data.forEach((bar, index) => {
            const value = parseInt(dataset.data[index].toString())
            const barElement = bar as any
            const currencySymbol = plugin.currencySymbols[index] || ''
            const fractionDigits = ((plugin.decimalScale !== null) && (plugin.decimalScale !== undefined)) ? plugin.decimalScale : 1
            const formattedValue = `${currencySymbol}${value < 0 ? '(' : ''}${value?.toLocaleString('en-US', {
                minimumFractionDigits: fractionDigits,
                maximumFractionDigits: fractionDigits,
              }).replace('-', '')
            }${value < 0 ? ')' : ''}` 

            const { font } = plugin
            if (font) {
              ctx.font = `${font.size} ${font.family}`
              if (font.color) {
                ctx.fillStyle = font.color
              }
            }
            // adds bar value label to the bar
            if (value) {
              if (hasOnlyNegativeValues) {
                ctx.fillText(`${formattedValue}`, bar.x, (value > 0) ? bar.y - 5 : ((bar.y + barElement.height) / 2) + 25)
              } else {
                ctx.fillText(`${formattedValue}`, bar.x, (value > 0) ? bar.y - 5 : bar.y + 20 )
              }
            }
          })
        }
      })
    }
  },
}

