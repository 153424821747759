import { IYourTeam } from '@/shared/api/services/teamService'
import { IButtonStyles, IconButton, Stack } from '@fluentui/react'

export interface YourTeamDragColumnProps {
  teamMember: IYourTeam,
}

const iconButtonStyles: IButtonStyles = {
  root: {
    height: 20,
    margin: 0,
  },
  icon: {
    fontSize: 20
  }
}

const YourTeamDragColumn = ({ teamMember }: YourTeamDragColumnProps) => {
  return (
    <>
      <Stack tokens={{ childrenGap: 16 }} horizontal>
        <IconButton 
          styles={iconButtonStyles}
          iconProps={{ iconName: 'DragObject' }}
          title='Rearrange Team Member'
          ariaLabel='Rearrange Team Member'
        />
      </Stack>
    </>
  )
}

export default YourTeamDragColumn