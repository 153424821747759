import * as Screens from '@/screens'
import { RouteComponentProps } from "react-router-dom"

const PreviewClientPortfolio = (props : RouteComponentProps<{
  accountId: string,
  portfolioId: string,
  asOfDate: string,
  sleeveId?: string
}>) => {
  const { accountId } = props.match.params
  return (
    <Screens.ClientPortfolio previewAccountId={accountId} {...props} />
  )
}

export default PreviewClientPortfolio