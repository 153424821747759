import Skeleton from 'react-loading-skeleton'
import cn from 'classnames'

type SkeletonFeatureProps = {
  className?: string
}

const SkeletonFeature = ({ className }: SkeletonFeatureProps) => {
  return (
    <div className={cn(className)}  role='presentation'>
      <Skeleton width="100%" height={363} />
    </div>
  )
}

export default SkeletonFeature